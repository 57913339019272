import { useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';

export const useClamped = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [clamped, setClamped] = useState(false);

  useEffectOnce(() => {
    if (ref && ref.current) {
      setClamped(ref.current.scrollHeight > ref.current.clientHeight);
    }
  });

  return { ref, clamped };
};
