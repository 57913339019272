import { Decimal } from 'decimal.js';
import { BigNumber } from 'ethers';

import { IWalletCurrency } from '../models/wallet-currency-response.interface';

import { DEFAULTS } from '../constants/defaults.constant';

import { getIsEmpty } from './get-is-empty.utils';

export const parseToDecimal = (value: string, decimal: number): string => {
  if (!Number.isNaN(+value)) {
    return new Decimal(value)
      .times(10 ** decimal)
      .toFixed(0)
      .valueOf();
  } else return '';
};

export const parseLargeDecimal = (value: number | string) => {
  if (value === null) {
    return '-';
  }

  let precising = DEFAULTS.defaultPricePrecision;

  if (value === null || value === undefined) {
    return '0';
  }

  if (value.toString().includes('-')) {
    const valuePrecision = +value.toString().split('-')[1];

    precising = valuePrecision > precising ? valuePrecision : precising;
  } else if (value.toString().includes('.')) {
    const valuePrecision = value.toString().split('.')[1].length;

    precising = valuePrecision > precising ? valuePrecision : precising;
  }

  return (+value.toString()).toFixed(precising);
};

export const parseDecimal = (value: string, decimal: number, decimalPlaces?: number) =>
  parseDecimalPlaces(parseFromDecimal(value, decimal, decimalPlaces));

export const parseDecimalPlaces = (value: string): string => {
  if (!Number.isNaN(+value)) {
    let int = value.split('.')[0];
    let float = value.split('.')[1];

    while (float.charAt(float.length - 1) === '0') {
      if (float.length === 2) break;

      float = float.substr(0, float.length - 1);
    }

    return int + '.' + float;
  } else return value;
};

export const parseFromDecimal = (value: string, decimal: number = 0, decimalPlaces: number = decimal): string => {
  if (getIsEmpty(decimal)) {
    return (0).toFixed(decimalPlaces || 2);
  }

  if (!Number.isNaN(+value)) {
    return new Decimal(value)
      .div(10 ** decimal)
      .toFixed(decimalPlaces)
      .valueOf();
  } else return value;
};

export const parseTimesDecimal = (
  value: string,
  decimal: number,
  multiplier_value?: number,
  add: boolean = true
): string | undefined => {
  if (!Number.isNaN(+value)) {
    const decimal_value = new Decimal(parseFromDecimal(value, decimal));
    const multiplier = decimal_value.times(multiplier_value || 1);

    return parseDecimalPlaces(
      add ? decimal_value.add(multiplier).toFixed(6).valueOf() : decimal_value.sub(multiplier).toFixed(6).valueOf()
    );
  }
};

export const parsePercentage = (values: string[]) => {
  const base_value = new Decimal(values[0]);

  return new Decimal(values[1]).sub(base_value).div(base_value).times(100).toFixed(2).valueOf();
};

export const parseToString = (value: number): string => value?.toString();

export const parseToDecimalCurrency = (price: number, currency: IWalletCurrency): string =>
  price ? parseToDecimal(price.toString(), currency.decimal) : '';

export const parseFromDecimalCurrency = (price: number, currency: IWalletCurrency): number =>
  price ? +parseFromDecimal(price.toString(), currency.decimal) : 0;

export const getPricePerShare = (price: number, shares: number): number => {
  const precision = 10;

  return Math.round((price / shares) * precision) / precision;
};

export const parseThousand = (value: number, precision?: number) => {
  let decimalValue = new Decimal(value).toFixed(precision);

  const stringValue = decimalValue.toString();
  const [whole, decimal] = stringValue.split('.');
  const formattedWhole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return decimal ? `${formattedWhole}.${decimal}` : formattedWhole;
};

export const parseThousandWithEmpty = (value: number | undefined) => (value ? parseThousand(value) : '-');

export const parseSciNumToString = (value: number, decimal: number, decimalPlaces: number = 2): string => {
  return new Decimal(value)
    .div(10 ** decimal)
    .toFixed(decimalPlaces)
    .valueOf();
};
