import { FETCH_LEADERBOARDS } from '../actions/actionNames/leaderboardsActionNames';
import { ILeaderboardsReducer } from '../models/reducers/i-leaderboards-reducer.interface';

const INITIAL_STATE: ILeaderboardsReducer = {
  leaderboards: {
    most_popular_nfts: [],
    most_profitable_nfts: [],
    top_nft_owners: [],
    most_yield_nfts: []
  },
  loading: false,
  error: '',
};

export const leaderboardsReducer = (state = INITIAL_STATE, action: any): ILeaderboardsReducer => {
  switch (action.type) {
    case FETCH_LEADERBOARDS + '_PENDING':
      return { ...state, loading: true, error: '' };
    case FETCH_LEADERBOARDS + '_REJECTED':
      return {
        ...state,
        loading: false,
        error: `Something went wrong`,
        leaderboards: {
          most_popular_nfts: [],
          most_profitable_nfts: [],
          top_nft_owners: [],
          most_yield_nfts: [],
        },
      };
    case FETCH_LEADERBOARDS + '_FULFILLED':
      return {
        ...state,
        loading: false,
        error: '',
        leaderboards: action.payload.data,
      };
    default:
      return state;
  }
};
