import {
  SET_CHAIN_ID,
  SET_CURRENT_WEB3_PROVIDER,
  SET_IS_CONNECTED,
  SET_IS_ON_CORRECT_CHAIN,
} from '../actions/actionNames/web3ActionNames';
import { IWeb3Reducer } from '../models/reducers/i-web3-reducer.interface';

export enum Web3Providers {
  metamask,
  torus,
  none,
}

const INITIAL_STATE: IWeb3Reducer = {
  isConnected: null,
  chainId: null,
  correctChain: null,
  currentProvider: null,
  validProvider: false,
};

export const web3Reducer = (state = INITIAL_STATE, action: any): IWeb3Reducer => {
  switch (action.type) {
    case SET_IS_CONNECTED:
      return { ...state, isConnected: action.payload };
    case SET_CHAIN_ID:
      return { ...state, chainId: action.payload };
    case SET_IS_ON_CORRECT_CHAIN:
      return { ...state, correctChain: action.payload };
    case SET_CURRENT_WEB3_PROVIDER:
      return { ...state, currentProvider: action.payload, validProvider: action.payload !== Web3Providers.none };
    default:
      return state;
  }
};
