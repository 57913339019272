import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { removeInfoMessageAction } from 'actions/global-actions';
import { Notifications } from 'enums/notifications/notifications.enum';
import Notification from '../notification/Notification';

interface IInfoNotificationProps {
  message: string;
  id: string;
  onClose?: () => void;
}

const InfoNotification = ({ id, message, onClose }: IInfoNotificationProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const removeInfoMessage = () => {
    dispatch(removeInfoMessageAction(id));
  };

  return (
    <Notification
      message={message}
      iconId="info-icon"
      iconAltText={t('infoIconAltText')}
      type={Notifications.info}
      onClose={onClose ? onClose : removeInfoMessage}
    />
  );
};

export default InfoNotification;
