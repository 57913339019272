import { IPropsWithChildrenAndClassName } from 'types/common.interface';

import LootboxBackground from '../lootboxBackground/LootboxBackground';
import LootboxTitleSection from '../lootboxTitleSection/LootboxTitleSection';
import styles from './LootboxCardLarge.module.scss';

interface ILootboxCardLargeProps extends IPropsWithChildrenAndClassName {
  allowAnimation?: boolean;
  autoplay?: boolean;
  background?: string;
  loop?: boolean;
  title?: string;
}

const LootboxCardLarge = ({
  allowAnimation,
  autoplay,
  background,
  children,
  loop,
  title,
  className = '',
}: ILootboxCardLargeProps) => {
  const classNames = [className, styles['lootbox-card']];

  return (
    <div className={classNames.join(' ')}>
      <LootboxBackground
        loop={loop}
        allowAnimation={allowAnimation}
        autoplay={autoplay}
        background={background}
        className={styles['lootbox-card__background']}
      ></LootboxBackground>
      <LootboxTitleSection title={title} />
      <div className={styles['lootbox-card__content']}>{children}</div>
    </div>
  );
};

export default LootboxCardLarge;
