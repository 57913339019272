import { useClamped } from '../../../hooks/useClamped';

import VenueBackground from '../../venueBackground/VenueBackground';
import NftCardSection from '../nftCardSection/NftCardSection';
import NftCategoryIdSection from '../nftCategoryIdSection/NftCategoryIdSection';
import NftSharesSection from '../nftSharesSection/NftSharesSection';
import styles from './NftCardMedium.module.scss';

interface INftCardMediumProps {
  address: string;
  backgroundUrl: string;
  backgroundAnimationUrl: string;
  category: string | null;
  id: number;
  marketUrl: string;
  name: string;
  shares: string;
  className?: string;
}

const NftCardMedium = ({
  address,
  backgroundUrl,
  backgroundAnimationUrl,
  category,
  id,
  name,
  marketUrl,
  shares,
  className = '',
}: INftCardMediumProps) => {
  const classNames = [className, styles['nft-card']];

  const { ref, clamped } = useClamped();

  return (
    <div className={classNames.join(' ')}>
      <NftCategoryIdSection id={id} category={category} />
      <VenueBackground
        backgroundAnimationUrl={backgroundAnimationUrl}
        backgroundImageUrl={backgroundUrl}
        className={styles['nft-card__background']}
      />
      <div className={styles['nft-card__content']}>
        <div className={styles['nft-card__marker-wrapper']}>
          <img alt="" className={styles['nft-card__marker']} src={marketUrl} />
        </div>
        <NftSharesSection className={styles['nft-card__shares']} shares={shares} />
        <NftCardSection className={styles['nft-card__caption']}>
          <div ref={ref} className={styles['nft-card__name-wrapper']}>
            <p title={name} className={clamped ? styles['nft-card__name-small'] : styles['nft-card__name-normal']}>
              {name}
            </p>
          </div>
          <p className={styles['nft-card__address']}>{address}</p>
        </NftCardSection>
      </div>
    </div>
  );
};

export default NftCardMedium;
