import { ReactNode, useRef } from 'react';

import { ScrollAnimationType } from '../../enums/scroll-animation-type.enum';
import { useElementOnScreen } from '../../hooks/useElementOnScreen';

import styles from './ScrollInAnimationItem.module.scss';

interface IScrollInAnimationItemProps {
  children: ReactNode;
  animateOnce?: boolean;
  className?: string;
  delay?: string;
  duration?: string;
  animationType?: ScrollAnimationType;
  threshold?: number;
}

const ScrollInAnimationItem = ({
  children,
  animateOnce = true,
  className = '',
  delay = '0.2s',
  duration = '0.6s',
  threshold = 0.7,
  animationType = ScrollAnimationType.slideInBottom,
}: IScrollInAnimationItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const onScreen = useElementOnScreen(ref, threshold, animateOnce);
  const containerClassNames = [className, styles['container']];
  const animationContainerClassNames = [styles[animationType], onScreen ? styles[`${animationType}--animate-in`] : ''];

  return (
    <div className={containerClassNames.join(' ')}>
      <div
        className={animationContainerClassNames.join(' ')}
        ref={ref}
        style={{
          transitionDelay: delay,
          transitionDuration: duration,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ScrollInAnimationItem;
