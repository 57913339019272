interface IButtonRegular {
  text: string;
  type?: 'submit' | 'button' | 'reset';
  onClick?: (event: React.MouseEvent) => void;
  disabled?: boolean;
  classNames?: string[];
}

const ButtonRegular = ({ text, onClick, disabled = false, classNames = [], type }: IButtonRegular) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`component button button_regular ${classNames.join(' ')}`}
    >
      <p className={'button_regular_text'}>{text}</p>
    </button>
  );
};

export default ButtonRegular;
