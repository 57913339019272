import { FC, useEffect, useState } from 'react';
import { useSwiper } from 'swiper/react';
import { twMerge } from 'tailwind-merge';

import { IPropsWithChildrenAndLabel } from 'types/common.interface';

import { useNftCardSize } from '../hooks';
import { useNftCardContext } from '../nftCardContext/NftCardContext';
import styles from './NftCardBackSlide.module.scss';

const NftCardBackSlide: FC<IPropsWithChildrenAndLabel> = ({ label, children }) => {
  const { height, toggled, onForceToggle } = useNftCardContext();
  const { isCardLarge, isCardMedium, isCardSmall } = useNftCardSize();
  const isNotSmallCard = isCardLarge || isCardMedium;

  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (height) {
      isNotSmallCard && setContentHeight(height - 128);
      isCardSmall && setContentHeight(height - 112);
    }
  }, [height]);

  const swiper = useSwiper();

  useEffect(() => {
    if (toggled && onForceToggle) {
      swiper.slideTo(1);
    }
  }, [toggled, onForceToggle]);

  return (
    <>
      <h2
        className={twMerge(
          'tw-mb-5 tw-text-2xl/none tw-font-bold tw-uppercase tw-text-white',
          isNotSmallCard && 'tw-mr-10 tw-px-1 tw-pt-1',
          isCardSmall && 'tw-mr-10 tw-px-1'
        )}
      >
        {label}
      </h2>
      <div className={styles['scrollbar']} style={{ height: contentHeight }}>
        {children}
      </div>
    </>
  );
};

export default NftCardBackSlide;
