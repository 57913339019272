import { ReactNode, useId } from 'react';
import Select, { GroupBase, StylesConfig } from 'react-select';

import { IValidation } from 'models/i-form-validation.interface';
import { ISelectOption } from 'models/select/i-select-option.interface';

import InputValidation from 'components/inputValidation/InputValidation';

import styles from './SingleSelect.module.scss';

const getCustomStyles = ({
  outlined,
  isClearable,
  customBackgroundColor,
}: {
  outlined: boolean;
  isClearable: boolean;
  customBackgroundColor?: string;
}): StylesConfig<ISelectOption, false, GroupBase<ISelectOption>> => ({
  control: (defaultStyles, state) => ({
    ...defaultStyles,
    backgroundColor: state.isDisabled
      ? styles['disabledBackgroundColor']
      : customBackgroundColor
      ? customBackgroundColor
      : styles['backgroundColor'],
    height: styles['height'],
    minHeight: styles['height'],
    borderRadius: styles['borderRadius'],
    fontSize: styles['fontSize'],
    placeholderColor: styles['placeholderColor'],
    boxShadow: 'none !important',
    border: state.isFocused || outlined ? styles['border'] : 'none',
    '&:hover': {
      border: state.isFocused || outlined ? styles['border'] : 'none',
    },
    borderWidth: 0,
  }),
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: styles['inputColor'],
    backgroundColor: state.isFocused ? styles['optionHoverBackgroundColor'] : styles['backgroundColor'],
    '&:hover': {
      ...defaultStyles,
      color: styles['inputColor'],
      backgroundColor: styles['optionHoverBackgroundColor'],
    },
  }),
  valueContainer: (defaultStyles, state) => ({
    ...defaultStyles,
    paddingLeft: styles['padding'],
    paddingRight: styles['padding'],
    height: 38,
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  indicatorSeparator: () => ({
    width: 0,
  }),
  indicatorsContainer: (defaultStyles, state) => ({
    ...defaultStyles,
    paddingRight: styles['padding'],
    color: state.isDisabled ? styles['inputDisabledColor'] : styles['inputColor'],
    '& svg': {
      height: 24,
      width: 24,
    },
  }),
  clearIndicator: !isClearable
    ? undefined
    : (defaultStyles) => ({
        ...defaultStyles,
        paddingRight: 0,
        paddingLeft: 0,
        color: styles['inputIconColor'],
        '&:hover': {
          color: styles['inputIconHoverColor'],
        },
      }),
  dropdownIndicator: (defaultStyles, state) => ({
    ...defaultStyles,
    paddingRight: 0,
    paddingLeft: 0,
    display: isClearable && state.options?.length && state.hasValue ? 'none' : 'flex',
    color: styles['inputIconColor'],
    '&:hover': {
      color: styles['inputIconHoverColor'],
    },
  }),
  input: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isDisabled ? styles['inputDisabledColor'] : styles['inputColor'],
    margin: 0,
  }),
  singleValue: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isDisabled ? styles['inputDisabledColor'] : styles['inputColor'],
    margin: 0,
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: styles['placeholderColor'],
  }),
  menu: (defaultStyles) => ({
    ...defaultStyles,
    backgroundColor: styles['backgroundColor'],
    zIndex: 11,
  }),
});

interface ISingleSelectProps {
  onChange: (value: ISelectOption | undefined) => void;
  options: ISelectOption[];
  value?: ISelectOption;
  label?: string;
  ariaLabel?: string;
  defaultValue?: ISelectOption;
  className?: string;
  labelClassName?: string;
  customBackgroundColor?: string;
  placeholder?: string;
  disabled?: boolean;
  outlined?: boolean;
  searchable?: boolean;
  clearable?: boolean;
  inputColor?: 'primary' | 'secondary';
  validations?: IValidation<ISelectOption | undefined>[];
  formatOptionLabel?: (option: ISelectOption) => ReactNode;
}

const SingleSelect = ({
  onChange,
  value,
  defaultValue,
  options,
  label,
  className,
  labelClassName,
  customBackgroundColor,
  validations,
  disabled,
  ariaLabel,
  outlined = false,
  searchable = true,
  inputColor = 'primary',
  placeholder = 'Select',
  clearable = false,
  formatOptionLabel,
}: ISingleSelectProps) => {
  const id = useId();
  const isClearable = clearable && value?.value !== '';
  const labelClassNames = [
    styles[`input-form-field__label`],
    !label && ariaLabel ? styles[`input-form-field__label--hidden`] : '',
    labelClassName,
  ];

  return (
    <div
      className={`${className || ''} ${styles[`input-form-field`]} ${
        styles[`input-form-field--${inputColor}`]
      } single-select`}
    >
      {(label || ariaLabel) && (
        <label htmlFor={id} className={labelClassNames.join(' ')}>
          {label || ariaLabel}
        </label>
      )}
      <Select
        id={id}
        styles={getCustomStyles({ outlined, isClearable, customBackgroundColor })}
        placeholder={placeholder}
        value={value}
        isDisabled={disabled}
        isClearable={isClearable}
        isSearchable={searchable}
        defaultValue={defaultValue}
        options={options}
        formatOptionLabel={formatOptionLabel}
        onChange={(value) => onChange(value || undefined)}
        menuPlacement="auto"
      />
      {validations?.map((validation) => (
        <InputValidation key={validation.key} validator={validation.validator} value={value} />
      ))}
    </div>
  );
};

export default SingleSelect;
