import { twMerge } from 'tailwind-merge';

import AnimatedTabs from '../../../components/animatedTabs/AnimatedTabs';
import ScrollInAnimationItem from '../../../components/scrollInAnimationItem/ScrollInAnimationItem';

import styles from './GameOnboardingInstruction.module.scss';
import GameOnboardingInstructionWithoutWallet from './gameOnboardingInstructionWithoutWallet/GameOnboardingInstructionWithoutWallet';
import GameOnboardingInstructionWithWallet from './gameOnboardingInstructionWithWallet/GameOnboardingInstructionWithWallet';

const tabsNames = ['I Own a digital wallet', 'I don’t have a digital wallet'];

const GameOnboardingInstruction = () => {
  return (
    <div className="tw-w-[calc(100%+40px)] tw-bg-darkBlue tw-px-[20px] tw-py-[50px] sm:tw-py-[80px] lg:tw-py-[120px]">
      <ScrollInAnimationItem delay="0.5s" threshold={0.3} className="tw-mx-auto tw-w-[950px] tw-max-w-full">
        <h2 className="tw-mb-[15px] tw-text-center tw-text-[32px] tw-uppercase tw-text-white sm:tw-mb-[20px] sm:tw-text-[44px] lg:tw-text-5xl">
          <span className="tw-text-turquoise">How to claim</span> your NFT?
        </h2>
        <p className="tw-mb-7.5 tw-text-center tw-text-lg tw-text-white sm:tw-mb-15 sm:tw-text-[20px] lg:tw-text-[22px]">
          Ensure you own a digital wallet to claim your first NFT on us!
        </p>
        <AnimatedTabs
          classNameList="tw-mb-7.5 tw-w-full tw-mx-auto lg:tw-mb-15 lg:tw-w-full lg:tw-max-w-[676px] tw-bg-[#02102E]"
          list={tabsNames}
          classNamePanels="tw-flex tw-justify-center tw-relative"
          classNamePanel={twMerge(styles['tab-panel'], 'tw-max-w-full')}
          classNameTab={twMerge(styles['tab'], 'tw-min-h-[48px] lg:tw-min-h-[64px]')}
          panels={[<GameOnboardingInstructionWithWallet />, <GameOnboardingInstructionWithoutWallet />]}
        />
      </ScrollInAnimationItem>
    </div>
  );
};

export default GameOnboardingInstruction;
