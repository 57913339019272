import {
    FILTER_CHANGE, FILTER_GET, SEARCH_BUILDING, SET_SEARCH_VISIBILITY
} from '../constants/actionNames';
import { limit } from '../constants/VenuesRequest';
import { ISearchReducer } from '../models/reducers/i-search-reducer.interface';

const INITIAL_STATE: ISearchReducer = {
  search: '',
  sortBy: undefined,
  category: undefined,
  tier: '',
  country: undefined,
  searchVisibility: true,
  skip: 0,
  limit: limit,
  filters: {
    category: [],
    country: [],
    tier: [],
    sort: [],
  },
};

export const searchBuildingsReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SEARCH_BUILDING:
      return { ...state, search: action.payload };
    case FILTER_CHANGE:
      return { ...state, ...action.payload };
    case FILTER_GET + '_FULFILLED':
      return { ...state, filters: { ...action.payload.data } };
    case SET_SEARCH_VISIBILITY:
      return { ...state, searchVisibility: action.payload };
    default:
      return state;
  }
};
