import { useEffect, useState } from 'react';

import { getNextSpinTime } from './WheelOfFortuneCountdown.utils';

const WheelOfFortuneCountdown = ({ spinTime }: { spinTime: number }) => {
  const [timeLeft, setTimeLeft] = useState(spinTime);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="tw-mx-auto tw-text-center tw-text-2xl tw-font-semibold tw-uppercase tw-text-white md:tw-text-[28px]/[28px]">
      Next spin available in <br />
      <span className="tw-text-green">{getNextSpinTime(timeLeft)}</span>
    </div>
  );
};

export default WheelOfFortuneCountdown;
