import Button from '../../../../components/buttons/Button';

import Metamask from '../../../../assets/logos/metamask.svg';

const metamaskDownloadLink = 'https://metamask.app.link/dapp/realitymeta.io/game-onboarding';

const GameOnboardingInstructionInstallMetamaskMobile = () => {
  return (
    <div className="tw-flex tw-flex-wrap tw-justify-center tw-gap-[50px] lg:tw-gap-[80px]">
      <Button className="tw-px-[16px]" size="medium-small" asLink link={metamaskDownloadLink} target="_blank">
        <div className="tw-flex tw-items-center">
          <img src={Metamask} alt="" className="tw-mr-1 tw-w-[32px]" /> Install Metamask app
        </div>
      </Button>
    </div>
  );
};

export default GameOnboardingInstructionInstallMetamaskMobile;
