import { ReactComponent as Bga } from '../../../assets/landing/partners/bga.svg';
import { ReactComponent as Certic } from '../../../assets/landing/partners/certic.svg';
import { ReactComponent as Cryptocom } from '../../../assets/landing/partners/cryptocom.svg';
import { ReactComponent as Galxe } from '../../../assets/landing/partners/galxe.svg';
import { ReactComponent as Gamerhas } from '../../../assets/landing/partners/gamerhas.svg';
import { ReactComponent as Golem } from '../../../assets/landing/partners/golem.svg';
import { ReactComponent as Nap } from '../../../assets/landing/partners/nap.svg';
import { ReactComponent as OnePlanet } from '../../../assets/landing/partners/onePlanet.svg';
import { ReactComponent as OpenSea } from '../../../assets/landing/partners/openSea.svg';
import { ReactComponent as Polygon } from '../../../assets/landing/partners/polygon.svg';
import { ReactComponent as Reality } from '../../../assets/landing/partners/reality.svg';
import { ReactComponent as Torus } from '../../../assets/landing/partners/torus.svg';

export const LANDING_PARTNERS = [
  {
    ImageComponent: OpenSea,
    link: 'https://opensea.io',
    label: 'OpenSea',
  },
  {
    ImageComponent: Cryptocom,
    link: 'https://crypto.com',
    label: 'Cryptocom',
  },
  {
    ImageComponent: Certic,
    link: 'https://www.certik.com/',
    label: 'Certic',
  },
  {
    ImageComponent: Torus,
    link: 'https://tor.us',
    label: 'Torus',
  },
  {
    ImageComponent: OnePlanet,
    link: 'https://www.oneplanetnft.io',
    label: 'OnePlanet',
  },
  {
    ImageComponent: Reality,
    link: 'https://reality.co',
    label: 'Reality',
  },
  {
    ImageComponent: Bga,
    link: 'https://www.blockchaingamealliance.org/',
    label: 'Bga',
  },
  {
    ImageComponent: Polygon,
    link: 'https://polygon.technology/',
    label: 'Polygon',
  },
  {
    ImageComponent: Golem,
    link: 'https://www.golem.network',
    label: 'Golem',
  },
  {
    ImageComponent: Gamerhas,
    link: 'https://gamerhash.com/pl',
    label: 'Gamerhas',
  },
  {
    ImageComponent: Nap,
    link: 'https://project.oneplanetnft.io/nap',
    label: 'Nap',
  },
  {
    ImageComponent: Galxe,
    link: 'https://galaxe.com/',
    label: 'Galxe',
  },
];
