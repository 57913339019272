import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { ROUTES } from 'constants/routes.constant';

import { mapRotateStart, mapRotateStop } from '../actions/map.action';

export const useMapRotation = (): void => {
  const dispatch = useDispatch();
  const pathName = useLocation().pathname;

  const startRotatate = useCallback(() => {
    dispatch(mapRotateStart());
  }, [dispatch]);

  const stopRotatate = useCallback(() => {
    dispatch(mapRotateStop());
  }, [dispatch]);

  useEffect(() => {
    if (pathName.includes(ROUTES.PORTAL)) {
      startRotatate();

      return;
    }

    stopRotatate();
  }, [pathName, startRotatate, stopRotatate]);
};
