import { ILootboxPurchaseConfig } from '../models/lootbox/i-lootbox-purchase-config.interface';
import { ILootboxOpenConfig } from '../models/lootbox/i-lootbox-open-config.interface';
import {
  RESET_LOOTBOX_DATA,
  RESET_LOOTBOX_LISTING_CONFIG,
  SET_LOOTBOX_LISTING_CONFIG,
  SET_LOOTBOX_OPEN_ANIMATION_FINISHED,
  SET_LOOTBOX_OPEN_CONFIRMATION_BLOCKS,
  SET_LOOTBOX_OPEN_CONFIG,
  SET_LOOTBOX_OPEN_ERROR_MESSAGE,
  SET_LOOTBOX_OPEN_STEP,
  SET_LOOTBOX_PURCHASE_ERROR_MESSAGE,
  SET_LOOTBOX_PURCHASE_FUNDS_REMAINING,
  SET_LOOTBOX_PURCHASE_STEP,
  SET_LOOTBOX_REWARDS,
  REFRESH_LOOTBOX_UPDATE_TOKEN,
} from './actionNames/lootboxActionNames';
import { LootboxPurchaseStep } from '../enums/lootbox/lootbox-purchase-step.enum';
import { LootboxOpenStep } from '../enums/lootbox/lootbox-open-step.enum';
import { ILootboxRewardMetadata } from '../models/lootbox/i-lootbox-reward-metadata.interface';

export const resetLootboxDataAction = () => ({ type: RESET_LOOTBOX_DATA });

export const resetLootboxOpenConfigAction = () => ({ type: RESET_LOOTBOX_LISTING_CONFIG });

export const setLootboxOpenConfigAction = (config: ILootboxOpenConfig) => ({
  type: SET_LOOTBOX_OPEN_CONFIG,
  payload: config,
});

export const resetLootboxPurchaseConfigAction = () => ({ type: RESET_LOOTBOX_LISTING_CONFIG });

export const setLootboxPurchaseConfigAction = (config: ILootboxPurchaseConfig) => ({
  type: SET_LOOTBOX_LISTING_CONFIG,
  payload: config,
});

export const setLootboxOpenAnimationFinishedAction = () => ({ type: SET_LOOTBOX_OPEN_ANIMATION_FINISHED });

export const setLootboxPurchaseFundsRemainingAction = (fundsRemaining: number) => ({
  type: SET_LOOTBOX_PURCHASE_FUNDS_REMAINING,
  payload: fundsRemaining,
});

export const setLootboxPurchaseStepAction = (step: LootboxPurchaseStep | null) => ({
  type: SET_LOOTBOX_PURCHASE_STEP,
  payload: step,
});

export const setLootboxOpenStepAction = (step: LootboxOpenStep | null) => ({
  type: SET_LOOTBOX_OPEN_STEP,
  payload: step,
});

export const setLootboxPurchaseErrorMessageAction = (errorMessage: string) => ({
  type: SET_LOOTBOX_PURCHASE_ERROR_MESSAGE,
  payload: errorMessage,
});

export const setLootboxOpenErrorMessageAction = (errorMessage: string) => ({
  type: SET_LOOTBOX_OPEN_ERROR_MESSAGE,
  payload: errorMessage,
});

export const setLootboxOpenConfirmationBlocksAction = (blocks: number) => ({
  type: SET_LOOTBOX_OPEN_CONFIRMATION_BLOCKS,
  payload: blocks,
});

export const setLootboxOpenRewardsAction = (rewards: ILootboxRewardMetadata[]) => ({
  type: SET_LOOTBOX_REWARDS,
  payload: rewards,
});

export const refreshLootboxUpdateTokenAction = () => ({ type: REFRESH_LOOTBOX_UPDATE_TOKEN });
