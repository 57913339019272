import SharesIcon from 'assets/lootbox/lootbox-icon.svg';

import styles from './LootboxSharesSection.module.scss';

interface ILootboxSharesSectionProps {
  shares: number;
  className?: string;
}

const LootboxSharesSection = ({ shares, className = '' }: ILootboxSharesSectionProps) => {
  const classNames = [className, styles['shares-section']];

  return (
    <div className={classNames.join(' ')}>
      <img alt="Shares" className={styles['shares-icon']} src={SharesIcon} />
      {shares || '-'}
    </div>
  );
};

export default LootboxSharesSection;
