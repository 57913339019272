import { ReactNode } from 'react';

import { ReactComponent as ListCheckmark } from '../../assets/icons/list-checkmark.svg';

import styles from './CheckmarkListItem.module.scss';

interface ICheckmarkListItemProps {
  children: ReactNode;
  className?: string;
}

const CheckmarkListItem = ({ children, className = '' }: ICheckmarkListItemProps) => {
  const classNames = [className, styles['checkmark-list-item']].join(' ');

  return (
    <li className={classNames}>
      <ListCheckmark className={styles['checkmark-list-item__icon']} />
      {children}
    </li>
  );
};

export default CheckmarkListItem;
