import styles from './MenuToggle.module.scss';

interface IMenuToggleProps {
  isActive: boolean;
}

const MenuToggle = ({ isActive }: IMenuToggleProps) => {
  return (
    <div className={`${styles['hamburger']} ${isActive ? styles['hamburger--active'] : ''}`}>
      <span className={styles['line']}></span>
      <span className={styles['line']}></span>
      <span className={styles['line']}></span>
    </div>
  );
};

export default MenuToggle;
