import { TOGGLE_BUILDING_DETAILS } from '../constants/actionNames';
import { IBuildingDetailsReducer } from '../models/reducers/i-building-details-reducer.interface';

const INITIAL_STATE: IBuildingDetailsReducer = {
  showBuildingDetails: true,
};

export const buildingDetailsReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case TOGGLE_BUILDING_DETAILS:
      return { ...state, showBuildingDetails: action.payload };
    default:
      return state;
  }
};
