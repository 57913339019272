import styles from './ListDot.module.scss';

interface IListDotProps {
  className?: string;
  checked?: boolean;
}

const ListDot = ({ className = '', checked = false }: IListDotProps) => {
  const classNames = [className, styles['list-dot'], checked ? styles['list-dot--checked'] : ''].join(' ');

  return <div className={classNames}></div>;
};

export default ListDot;
