import axios, { AxiosResponse } from 'axios';
import Cookies from 'universal-cookie/es6';
import { CookiesKeys } from '../enums/cookies-keys.enum';
import { IClaimCouponRequest } from '../models/claimCoupon/i-claim-coupon-request.interface';
import { IClaimCouponResponse } from '../models/claimCoupon/i-claim-coupon-response.interface';
import { IRegisterCodeRequest } from '../models/claimCoupon/i-register-code-request.interface';
import { IRegisterCodeResponse } from '../models/claimCoupon/i-register-code-response.interface';

export const claimCouponService = (claimRequest: IClaimCouponRequest): Promise<AxiosResponse<IClaimCouponResponse>> => {
  return axios.post(`/api/v1/assets/claim`, claimRequest, {
    headers: {
      'X-CSRFToken': new Cookies().get(CookiesKeys.csrftoken),
    },
  });
};

export const registerCodeService = (
  registerRequest: IRegisterCodeRequest
): Promise<AxiosResponse<IRegisterCodeResponse>> => {
  return axios.post(`/api/v1/assets/giveawayRegistration`, registerRequest, {
    headers: {
      'X-CSRFToken': new Cookies().get(CookiesKeys.csrftoken),
    },
  });
};
