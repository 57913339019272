import { useState } from 'react';

import { IWalletCurrency } from 'models/wallet-currency-response.interface';

import Button from 'components/buttons/Button';
import ValueAndIcon from 'components/generics/ValueAndIcon';
import NumberSlider from 'components/numberSlider/NumberSlider';

import { ScreenSize, useScreenSize } from 'hooks/useScreenSize';

import LootboxSharesSection from '../../lootboxCard/lootboxSharesSection/LootboxSharesSection';
import styles from './LootboxCardBuyControls.module.scss';

interface ILootboxCardBuyControlsProps {
  maxQuantity: number;
  priceDisplayValue: string;
  currency?: Omit<IWalletCurrency, 'balance'>;
  className?: string;
  onPurchase: (value: number) => void;
  isOnSale: boolean;
}

const LootboxCardBuyControls = ({
  currency,
  maxQuantity,
  priceDisplayValue,
  className = '',
  onPurchase,
  isOnSale,
}: ILootboxCardBuyControlsProps) => {
  const minQuantity = +maxQuantity >= 1 ? 1 : 0;
  const [quantity, setQuantity] = useState<number>(minQuantity);
  const classNames = [className, styles['lootbox-buy-controls']];
  const screenSize = useScreenSize();

  const price = Number.isNaN(+priceDisplayValue) ? '-' : +priceDisplayValue * quantity;

  const onPurchaseClick = () => {
    if (!quantity || quantity < minQuantity || quantity > +maxQuantity) {
      return;
    }

    onPurchase(quantity);
  };

  return (
    <>
      <div className={classNames.join(' ')}>
        <NumberSlider
          className={styles['lootbox-buy-controls__section']}
          maxValue={maxQuantity}
          minValue={minQuantity}
          onChange={setQuantity}
        />
        <div className={styles['lootbox-buy-controls__summary']}>
          <LootboxSharesSection className={styles['lootbox-buy-controls__summary-item']} shares={quantity} />
          <ValueAndIcon
            className={[styles['lootbox-buy-controls__summary-item'], styles['lootbox-buy-controls__price']].join(' ')}
            icon={currency?.icon}
            iconAltText={currency?.symbol}
            value={price}
            iconSize={screenSize > ScreenSize.small ? 28 : 24}
            valueSize={screenSize > ScreenSize.small ? 24 : 20}
            valueToFixed={false}
          />
        </div>
        <Button
          requireWeb3
          className={styles['lootbox-buy-controls__section']}
          size="medium"
          text="Buy"
          disabled={!quantity || quantity > maxQuantity || quantity < minQuantity || !isOnSale}
          onClick={onPurchaseClick}
        />
      </div>
    </>
  );
};

export default LootboxCardBuyControls;
