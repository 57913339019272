import { FETCH_PROFILE, PATCH_PROFILE } from '../actions/actionNames/profileActionNames';
import { WALLET_SIGN_OUT } from '../constants/actionNames';
import { IProfileReducer } from '../models/reducers/i-profile-reducer.interface';

const INITIAL_STATE: IProfileReducer = {
  loading: false,
  error: false,
  profile: null,
};

export const profileReducer = (state = INITIAL_STATE, action: any): IProfileReducer => {
  switch (action.type) {
    case FETCH_PROFILE + '_PENDING':
      return { ...state, loading: true, error: false };
    case FETCH_PROFILE + '_REJECTED':
      return { ...state, loading: false, error: true, profile: null };
    case FETCH_PROFILE + '_FULFILLED':
      return {
        ...state,
        loading: false,
        error: false,
        profile: action.payload.data,
      };
    case PATCH_PROFILE + '_PENDING':
      return { ...state, loading: true, error: false };
    case PATCH_PROFILE + '_REJECTED':
      return { ...state, loading: false, error: true };
    case PATCH_PROFILE + '_FULFILLED':
      return {
        ...state,
        loading: false,
        error: false,
        profile: action.payload.data,
      };
    case WALLET_SIGN_OUT + '_FULFILLED':
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
