import parse from 'html-react-parser';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as InfoIcon } from 'assets/icons/info-filled.svg';

import styles from './Tooltip.module.scss';

interface ITooltipProps {
  content?: ReactNode;
  children?: ReactNode;
  text?: string;
  className?: string;
  iconClassName?: string;
  contentClassName?: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
  visible?: boolean;
  wrapperClassName?: string;
}

const Tooltip = ({
  content,
  children,
  text,
  className,
  iconClassName,
  contentClassName,
  position = 'top',
  visible = true,
  wrapperClassName,
}: ITooltipProps) => {
  return visible ? (
    <div className={twMerge(className, styles['tooltip'])}>
      <div className={twMerge(wrapperClassName, 'tw-relative tw-inline-block tw-h-5')}>
        {children || <InfoIcon className={twMerge('tw-w-5 tw-text-skyBlue', iconClassName)} />}
        <div className={twMerge(contentClassName, styles['tooltip-content'], styles[`tooltip-content--${position}`])}>
          {content || (text ? parse(text) : '-')}
        </div>
      </div>
    </div>
  ) : (
    <>{children}</>
  );
};

export default Tooltip;
