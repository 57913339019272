import styles from './LootboxTitleSection.module.scss';

interface ILootboxTitleSectionProps {
  title?: string;
  className?: string;
}

const LootboxTitleSection = ({ title, className = '' }: ILootboxTitleSectionProps) => {
  const classNames = [className, styles['lootbox-title-section']];

  return (
    <div className={classNames.join(' ')}>
      <p className={styles['lootbox-title']}>{title}</p>
      <p className={styles['lootbox-subtitle']}>fNFT</p>
    </div>
  );
};

export default LootboxTitleSection;
