import { AxiosResponse } from 'axios';
import { generateUUID } from 'three/src/math/MathUtils';

import {
  IDailyDeal,
  IDailyDealsAsset,
  IDailyDealsItemResult,
  IDailyDealsResponse,
} from '../models/daily-deals/i-daily-deals';
import { IDispatchAction } from '../models/dispatch-action.interface';
import { IDailyDealsReducer } from '../models/reducers/i-daily-deals-reducer.interface';

import { DEFAULTS } from '../constants/defaults.constant';

import {
  FETCH_DAILY_DEALS,
  FETCH_DAILY_DEALS_ASSETS,
  REFRESH_DAILY_DEALS_UPDATE_TOKEN,
  RESET_DAILY_DEALS_DATA,
} from '../actions/actionNames/dailyDealsActionNames';

const INITIAL_STATE: IDailyDealsReducer = {
  updateToken: generateUUID(),
  deals: [],
  dealsValidFrom: null,
  dealsValidUntil: null,
  dealsLoading: false,
  dealsAssets: [],
  dealsAssetsLoading: false,
};

export const dailyDealsReducer = (state = INITIAL_STATE, action: IDispatchAction): IDailyDealsReducer => {
  switch (action.type) {
    case RESET_DAILY_DEALS_DATA:
      return { ...INITIAL_STATE };
    case REFRESH_DAILY_DEALS_UPDATE_TOKEN:
      return { ...state, updateToken: generateUUID() };
    case FETCH_DAILY_DEALS + '_PENDING':
      return { ...state, dealsLoading: true };
    case FETCH_DAILY_DEALS + '_REJECTED':
      return { ...state, dealsLoading: false };
    case FETCH_DAILY_DEALS + '_FULFILLED': {
      const responseItems = (action as IDispatchAction<AxiosResponse<IDailyDealsResponse>>).payload.data;

      if (!responseItems.length) {
        return { ...state, deals: [], dealsValidFrom: null, dealsValidUntil: null, dealsLoading: false };
      }

      const adaptItems = (items: IDailyDealsItemResult[]) =>
        items
          .sort((a, b) => a.amount - b.amount)
          .map(({ currency, all_sales, amount, price }) => ({
            currency,
            allSales: all_sales,
            meta: { amount, price },
          }));

      return {
        ...state,
        deals: responseItems.map(
          ({ asset, items, package_type }): IDailyDeal => ({
            asset,
            items: adaptItems(items),
            type: package_type,
          })
        ),
        dealsValidFrom: responseItems[0].valid_from * DEFAULTS.tradeTimestampMultiplier,
        dealsValidUntil: responseItems[0].valid_until * DEFAULTS.tradeTimestampMultiplier,
        dealsLoading: false,
      };
    }
    case FETCH_DAILY_DEALS_ASSETS + '_PENDING':
      return { ...state, dealsAssetsLoading: true };
    case FETCH_DAILY_DEALS_ASSETS + '_REJECTED':
      return { ...state, dealsAssetsLoading: false };
    case FETCH_DAILY_DEALS_ASSETS + '_FULFILLED': {
      const dealsAssets = (action as IDispatchAction<AxiosResponse<IDailyDealsAsset[]>>).payload.data;

      return {
        ...state,
        dealsAssets,
        dealsAssetsLoading: false,
      };
    }
    default:
      return state;
  }
};
