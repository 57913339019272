import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IPropsWithChildrenAndClassName } from 'types/common.interface';

const NftCardPanel: FC<IPropsWithChildrenAndClassName> = ({ children, className = '' }) => {
  return (
    <div className={twMerge('tw-rounded-2xl tw-bg-backgroundColorMedium tw-text-center', className)}>{children}</div>
  );
};

export default NftCardPanel;
