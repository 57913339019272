import { generateUUID } from 'three/src/math/MathUtils';

import { IDispatchAction } from '../models/dispatch-action.interface';
import { ILootboxOpenConfig } from '../models/lootbox/i-lootbox-open-config.interface';
import { ILootboxPurchaseConfig } from '../models/lootbox/i-lootbox-purchase-config.interface';
import { ILootboxRewardMetadata } from '../models/lootbox/i-lootbox-reward-metadata.interface';
import { ILootboxReducer } from '../models/reducers/i-lootbox-reducer.interface';

import { LootboxOpenStep } from '../enums/lootbox/lootbox-open-step.enum';
import { LootboxPurchaseStep } from '../enums/lootbox/lootbox-purchase-step.enum';

import {
  REFRESH_LOOTBOX_UPDATE_TOKEN,
  RESET_LOOTBOX_DATA,
  RESET_LOOTBOX_LISTING_CONFIG,
  RESET_LOOTBOX_OPEN_CONFIG,
  SET_LOOTBOX_LISTING_CONFIG,
  SET_LOOTBOX_OPEN_ANIMATION_FINISHED,
  SET_LOOTBOX_OPEN_CONFIG,
  SET_LOOTBOX_OPEN_STEP,
  SET_LOOTBOX_PURCHASE_ERROR_MESSAGE,
  SET_LOOTBOX_PURCHASE_FUNDS_REMAINING,
  SET_LOOTBOX_PURCHASE_STEP,
  SET_LOOTBOX_REWARDS,
} from '../actions/actionNames/lootboxActionNames';

const INITIAL_STATE: ILootboxReducer = {
  openingAnimationFinished: false,
  fundsRemaining: 0,
};

export const lootboxReducer = (state = INITIAL_STATE, action: IDispatchAction): ILootboxReducer => {
  switch (action.type) {
    case RESET_LOOTBOX_DATA:
      return { ...INITIAL_STATE };
    case SET_LOOTBOX_OPEN_CONFIG:
      return { ...state, openConfig: action.payload as ILootboxOpenConfig, openingAnimationFinished: false };
    case RESET_LOOTBOX_OPEN_CONFIG:
      return { ...INITIAL_STATE, openConfig: undefined };
    case SET_LOOTBOX_LISTING_CONFIG:
      return { ...state, purchaseConfig: action.payload as ILootboxPurchaseConfig, openingAnimationFinished: false };
    case RESET_LOOTBOX_LISTING_CONFIG:
      return { ...INITIAL_STATE, purchaseConfig: undefined };
    case SET_LOOTBOX_OPEN_ANIMATION_FINISHED:
      return { ...state, openingAnimationFinished: true };
    case SET_LOOTBOX_PURCHASE_FUNDS_REMAINING:
      return { ...state, fundsRemaining: action.payload as number };
    case SET_LOOTBOX_PURCHASE_STEP:
      return { ...state, purchaseStep: action.payload as LootboxPurchaseStep };
    case SET_LOOTBOX_OPEN_STEP:
      return { ...state, openStep: action.payload as LootboxOpenStep };
    case SET_LOOTBOX_PURCHASE_ERROR_MESSAGE:
      return { ...state, purchaseErrorMessage: action.payload as string };
    case SET_LOOTBOX_REWARDS:
      return { ...state, rewards: action.payload as ILootboxRewardMetadata[] };
    case REFRESH_LOOTBOX_UPDATE_TOKEN:
      return { ...state, updateToken: generateUUID() };
    default:
      return state;
  }
};
