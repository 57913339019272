import { useDispatch } from 'react-redux';

import { setLoading } from '../actions/global-actions';

export const useLoading = (): ((on: boolean) => void) => {
  const dispatch = useDispatch();

  const setScreenLoading = (on: boolean) => {
    dispatch(setLoading(on));
  };

  return setScreenLoading;
};
