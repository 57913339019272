export const RESET_LOOTBOX_DATA = 'RESET_LOOTBOX_DATA';
export const RESET_LOOTBOX_OPEN_CONFIG = 'RESET_LOOTBOX_OPEN_CONFIG';
export const SET_LOOTBOX_OPEN_CONFIG = 'SET_LOOTBOX_OPEN_CONFIG';
export const RESET_LOOTBOX_LISTING_CONFIG = 'RESET_LOOTBOX_LISTING_CONFIG';
export const SET_LOOTBOX_LISTING_CONFIG = 'SET_LOOTBOX_LISTING_CONFIG';
export const SET_LOOTBOX_OPEN_ANIMATION_FINISHED = 'SET_LOOTBOX_OPEN_ANIMATION_FINISHED';
export const SET_LOOTBOX_PURCHASE_FUNDS_REMAINING = 'SET_LOOTBOX_PURCHASE_FUNDS_REMAINING';
export const SET_LOOTBOX_PURCHASE_STEP = 'SET_LOOTBOX_PURCHASE_STEP';
export const SET_LOOTBOX_OPEN_STEP = 'SET_LOOTBOX_OPEN_STEP';
export const SET_LOOTBOX_OPEN_ERROR_MESSAGE = 'SET_LOOTBOX_OPEN_ERROR_MESSAGE';
export const SET_LOOTBOX_OPEN_CONFIRMATION_BLOCKS = 'SET_LOOTBOX_OPEN_CONFIRMATION_BLOCKS';
export const SET_LOOTBOX_PURCHASE_ERROR_MESSAGE = 'SET_LOOTBOX_PURCHASE_ERROR_MESSAGE';
export const SET_LOOTBOX_REWARDS = 'SET_LOOTBOX_REWARDS';
export const REFRESH_LOOTBOX_UPDATE_TOKEN = 'REFRESH_LOOTBOX_UPDATE_TOKEN';
