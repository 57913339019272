import TypingAnimation from '../../../components/typingAnimation/TypingAnimation';

import styles from './LootboxPurchaseProgress.module.scss';

interface ILootboxPurchaseProgressProps {
  text: string;
}

const LootboxPurchaseProgress = ({ text }: ILootboxPurchaseProgressProps) => {
  return (
    <div className={styles['purchase-view']}>
      <p className={styles['purchase-view__text']}>Transaction in progress please wait</p>
      <TypingAnimation className={styles['purchase-view__text-animated']} text={text} />
    </div>
  );
};

export default LootboxPurchaseProgress;
