import { ReactComponent as MoneyBag } from 'assets/wheel-of-fortune/money-bag.svg';

import { getNumericOption } from '../utils';

const MAX_REWARD_VALUE = 1000;

const WheelOfFortuneSafe = ({ value }: { value: number }) => {
  return (
    <div className="tw-flex tw-w-full tw-flex-row tw-items-center tw-gap-5">
      <MoneyBag />
      <div className="tw-relative tw-h-7.5 tw-w-full tw-overflow-hidden tw-rounded-2xl tw-bg-menuLinkColor30">
        <div
          className="tw-absolute tw-h-7.5 tw-rounded-2xl tw-bg-blueCardGradient"
          style={value ? { width: `${(value * 100) / MAX_REWARD_VALUE}%` } : {}}
        />
        <span className="tw-absolute tw-left-4 tw-top-[calc(50%_-_10px)] tw-h-5 tw-w-fit tw-text-lg/[20px] tw-text-white">
          {getNumericOption(value.toString())}/{getNumericOption(MAX_REWARD_VALUE.toString())}
        </span>
      </div>
    </div>
  );
};

export default WheelOfFortuneSafe;
