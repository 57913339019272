import { FC, useEffect, useState } from 'react';
import { useMeasure, useToggle } from 'react-use';
import { twMerge } from 'tailwind-merge';

import NftCardBack from '../nftCardBack/NftCardBack';
import { NftCardContext } from '../nftCardContext/NftCardContext';
import NftCardFront, { INftCardFrontProps } from '../nftCardFront/NftCardFront';
import { INftCardAsset, NftCardSizeType } from '../types';
import styles from './NftCard.module.scss';

export interface INftCardProps extends INftCardFrontProps {
  size?: NftCardSizeType;
  asset: INftCardAsset | undefined;
  onForceToggle?: boolean;
  onCallback?: (slide: number) => void;
  loading?: boolean;
  frontBackgroundClassName?: string;
  backBackgroundClassName?: string;
}

const NftCard: FC<INftCardProps> = ({
  size = 'medium',
  asset,
  toggleable = true,
  onForceToggle,
  children,
  asLinkTo,
  isOverbid,
  isSold,
  onCallback,
  loading = false,
  className,
  frontBackgroundClassName,
  backBackgroundClassName,
}) => {
  const [toggled, onToggle] = useToggle(false);
  const [animationClassName, setAnimationClassName] = useState<string | undefined>();
  const [ref, { height }] = useMeasure<HTMLDivElement>();

  const handleToggle = () => {
    toggled
      ? setAnimationClassName(styles['card--animating-reverse'])
      : setAnimationClassName(styles['card--animating']);

    onToggle();
  };

  useEffect(() => {
    toggled && onForceToggle && handleToggle();
  }, [toggled, onForceToggle]);

  const handleAnimationEnd = () => !loading && setAnimationClassName(undefined);

  return (
    <div
      ref={ref}
      className={twMerge(styles['card'], toggled && styles['card--toggled'], animationClassName, className)}
      onAnimationEnd={handleAnimationEnd}
    >
      <NftCardContext.Provider
        value={{ size, height, asset, toggled, onToggle: handleToggle, onCallback, loading, onForceToggle }}
      >
        <NftCardFront toggleable={toggleable} asLinkTo={asLinkTo} isOverbid={isOverbid} isSold={isSold}>
          {children}
        </NftCardFront>
        <NftCardBack backBackgroundClassName={backBackgroundClassName} />
      </NftCardContext.Provider>
      {/* front view background */}
      <div
        className={twMerge([
          'backface-hidden tw-absolute tw-inset-x-0 tw-inset-y-0 tw-z-[-1] tw-rounded-2xl',
          frontBackgroundClassName,
        ])}
      />
      {/* back view background */}
      <div
        className={twMerge([
          'backface-hidden tw-absolute tw-inset-x-0 tw-inset-y-0 tw-z-[-1] tw-rounded-2xl [transform:rotateY(180deg)]',
          backBackgroundClassName,
        ])}
      />
    </div>
  );
};

export default NftCard;
