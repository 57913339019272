import { ReactNode } from 'react';

import styles from './Tab.module.scss';

interface ITab {
  active?: boolean;
  children: ReactNode;
  className?: string;
  beforeActivate?: () => boolean;
  onActivate: () => void;
  disabled?: boolean;
}

const Tab = ({ active, className = '', children, onActivate, beforeActivate, disabled }: ITab) => {
  const classNames = ['button--styleless', className, styles['tab'], active ? styles['tab--active'] : ''];

  const handleTabClick = () => {
    if (beforeActivate && !beforeActivate()) {
      return;
    }

    onActivate();
  };

  return (
    <button type="button" role="tab" className={classNames.join(' ')} onClick={handleTabClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default Tab;
