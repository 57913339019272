import { useDispatch, useSelector } from 'react-redux';

import { IValidator } from '../../models/i-form-validation.interface';
import { IInputValue } from '../../models/i-input-value.interface';
import { IState } from '../../models/reducers/i-state.interface';

import { setShowSelectWalletModal } from '../../actions/global-actions';

import Button from '../../components/buttons/Button';
import Card from '../../components/card/Card';
import Form from '../../components/form/Form';
import InputFormField from '../../components/InputFormField/InputFormField';
import Page from '../../components/page/Page';
import PageWrapper from '../../components/pageWrapper/PageWrapper';

import { useClaimCoupon } from '../../hooks/useClaimCoupon';
import { useEnv } from '../../hooks/useEnv';

import RedeemCustom from './redeemCustom/RedeemCustom';
import RedeemGleam from './redeemGleam/RedeemGleam';
import styles from './RedeemRoute.module.scss';

const RedeemRoute = () => {
  const {
    asset,
    nftShares,
    couponCode,
    claimingInProgress,
    claimingFinished,
    redeemGleamUrl,
    showGleam,
    transactionHash,
    setCouponCode,
    onClaim,
    onProgressModalClose,
    onSuccessModalClose,
  } = useClaimCoupon();
  const walletAddress = useSelector((state: IState): string => state.wallet.address);
  const dispatch = useDispatch();
  const { hideLogin } = useEnv();
  const pageWrapperClassNames = [styles['page-wrapper'], showGleam ? '' : styles['page-wrapper--wide']];

  const setShowConnectWalletModal = () => {
    dispatch(setShowSelectWalletModal(true));
  };

  const requiredValidator: IValidator<IInputValue> = (value: IInputValue) => {
    return !!value ? { valid: true } : { valid: false, message: `Coupon code is required` };
  };

  return (
    <Page className={styles['page']} pageBackground="dark" withScrollbar>
      <PageWrapper className={pageWrapperClassNames.join(' ')} pageType="minimized">
        {showGleam ? (
          <RedeemGleam
            asset={asset}
            nftShares={nftShares}
            claimingInProgress={claimingInProgress}
            claimingFinished={claimingFinished}
            transactionHash={transactionHash}
            redeemGleamUrl={redeemGleamUrl}
            onProgressModalClose={onProgressModalClose}
            onSuccessModalClose={onSuccessModalClose}
          />
        ) : (
          <RedeemCustom asset={asset} claimingInProgress={claimingInProgress} />
        )}
        <Card>
          {!hideLogin && !walletAddress && (
            <Button
              className={styles['connect-button']}
              size="medium"
              ariaLabel="Connect wallet"
              onClick={setShowConnectWalletModal}
            >
              connect wallet
            </Button>
          )}
          <Form requireWeb3 onSubmit={onClaim}>
            <InputFormField
              classNames={styles['claim-form-field']}
              label={showGleam ? 'Coupon' : 'Code'}
              placeholder={showGleam ? 'Type your coupon code here' : 'Type your code here'}
              value={couponCode}
              onChange={({ target }) => setCouponCode(target.value)}
              validations={[
                {
                  key: 'requiredValidator',
                  validator: requiredValidator,
                },
              ]}
            ></InputFormField>
            <Button className={styles['claim-button']} size="medium">
              {showGleam ? 'Claim your NFT' : 'Get your NFT'}
            </Button>
          </Form>
        </Card>
      </PageWrapper>
    </Page>
  );
};

export default RedeemRoute;
