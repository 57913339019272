import { FETCH_TRADE_OFFERS, RESET_TRADE_OFFERS } from '../actions/actionNames/tradesActionNames';
import { ITradesReducer } from '../models/reducers/i-trades-reducer.interface';

const INITIAL_STATE: ITradesReducer = {
  tradeOffers: [],
};

export const tradesReducer = (state = INITIAL_STATE, action: any): ITradesReducer => {
  switch (action.type) {
    case FETCH_TRADE_OFFERS + '_PENDING':
      return {
        ...state,
        tradeOffers: [],
      };
    case FETCH_TRADE_OFFERS + '_FULFILLED':
      return {
        ...state,
        tradeOffers: [
          ...state.tradeOffers.filter((tradeOffer) => tradeOffer.tradeId !== action.payload.data.tradeId),
          {
            tradeId: action.payload.data.tradeId,
            offers: action.payload.data.results,
          },
        ],
      };
    case RESET_TRADE_OFFERS:
      return {
        ...state,
        tradeOffers: [...state.tradeOffers.filter((tradeOffer) => tradeOffer.tradeId !== action.payload)],
      };
    default:
      return state;
  }
};
