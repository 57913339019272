import { IAssetTrait } from 'models/assets/i-asset-trait.interface';

import { AssetTraitKeys } from 'enums/asset-trait-keys.enum';

export interface IUseTraits {
  id: string;
  type: string;
  category: string;
}

export const useTraits = (traits: IAssetTrait[]): IUseTraits => {
  return {
    id: traits.find(({ type }) => type === AssetTraitKeys.id)?.value ?? '-',
    type: traits.find(({ type }) => type === AssetTraitKeys.type)?.value ?? '-',
    category: traits.find(({ type }) => type === AssetTraitKeys.category)?.value ?? '-',
  };
};
