import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes.constant';

import { getEtherscanUrl } from 'services/network.service';

import { centralEllipsis } from 'utils/ellipsis.utils';

import ButtonDefault from '../../../buttons/ButtonDefault';
import ButtonRegular from '../../../buttons/ButtonRegular';
import VenueDetailsCardMain from '../../../venueDetailsCard/venueDetailsCardMain/VenueDetailsCardMain';
import './CheckoutModalSuccess.scss';

export interface ICheckoutModalSuccessData {
  totalShares: number;
  id: number;
  address: string;
  cardUrl: string;
  category: string;
  name: string;
  iconUrl: string;
  value?: number;
  token_id: string;
  amount: number;
}

interface CheckoutModalSuccessProps {
  transactionHash: string;
  action: 'purchase' | 'bid accept' | 'claim';
  checkoutSuccessData: ICheckoutModalSuccessData;
  showListForSaleButton?: boolean;
  viewItemLink?: string;
}

const CheckoutModalSuccess = ({
  transactionHash,
  action,
  checkoutSuccessData,
  showListForSaleButton = true,
  viewItemLink,
}: CheckoutModalSuccessProps) => {
  const navigate = useNavigate();

  return (
    <div className="checkout-modal-success">
      <div className="checkout-modal-success__venue-container">
        <VenueDetailsCardMain
          scale={0.75}
          id={checkoutSuccessData.id}
          address={checkoutSuccessData.address}
          availableShares={checkoutSuccessData.amount}
          cardUrl={checkoutSuccessData.cardUrl}
          category={checkoutSuccessData.category}
          name={checkoutSuccessData.name}
          iconUrl={checkoutSuccessData.iconUrl}
        ></VenueDetailsCardMain>
      </div>
      <div className="checkout-modal-success__details">
        <p className="checkout-modal-success__details-section">
          Your {action} of {checkoutSuccessData.amount} shares of{' '}
          <b>
            {checkoutSuccessData.name}
            {checkoutSuccessData.address ? ` (${checkoutSuccessData.address}) ` : ' '}
          </b>
          is processing.
          <br />
          It should be confirmed on the blockchain shortly.
        </p>
        <div className="checkout-modal-success__details-section">
          <p>Transaction ID:</p>
          <a
            target="_blank"
            rel="noreferrer"
            className="checkout-modal-success__hash-link"
            href={getEtherscanUrl(transactionHash)}
          >
            {centralEllipsis(transactionHash)}
          </a>
        </div>
      </div>
      <div className="checkout-modal-success__footer">
        {showListForSaleButton && (
          <ButtonDefault
            type="button"
            text="LIST FOR SALE"
            onClick={() => navigate(`${ROUTES.ASSETS}/preview/${checkoutSuccessData.token_id}`)}
          ></ButtonDefault>
        )}
        <ButtonRegular
          type="button"
          text="VIEW ITEM"
          onClick={() => navigate(viewItemLink || `${ROUTES.ASSETS}/preview/${checkoutSuccessData.token_id}`)}
        ></ButtonRegular>
      </div>
    </div>
  );
};

export default CheckoutModalSuccess;
