import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { IState } from '../models/reducers/i-state.interface';

export const useIsWeb3Allowed = (): boolean => {
  const [allowed, setAllowed] = useState(false);
  const { address } = useSelector(({ wallet }: IState) => wallet);
  const { correctChain, validProvider, isConnected } = useSelector(({ web3 }: IState) => web3);

  useEffect(() => {
    if (address && correctChain && validProvider && isConnected) {
      setAllowed(true);

      return;
    }

    setAllowed(false);
  }, [address, correctChain, validProvider, isConnected]);

  return allowed;
};
