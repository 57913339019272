import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';

import { ILootboxOwned } from 'models/lootbox/i-lootbox-owned.interface';
import { IState } from 'models/reducers/i-state.interface';

import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';

import { useWindowSize } from 'hooks/useWindowSize';

import LootboxCardLarge from '../lootboxCard/lootboxCardLarge/LootboxCardLarge';
import LootboxOwnedEmptySection from '../lootboxOwnedEmptySection/LootboxOwnedEmptySection';
import LootboxOwnedItem from '../lootboxOwnedItem/LootboxOwnedItem';
import LootboxSection from '../lootboxSection/LootboxSection';
import styles from './LootboxOwned.module.scss';

interface ILootboxOwnedProps {
  className?: string;
  lootboxes?: ILootboxOwned[];
  loading: boolean;
  fetchLootboxesDetails: () => Promise<void>;
  openBuyLootboxes: () => void;
}

const LootboxOwned = ({
  className,
  loading,
  lootboxes,
  fetchLootboxesDetails,
  openBuyLootboxes,
}: ILootboxOwnedProps) => {
  const wrapperElement = useRef<HTMLDivElement>(null);
  const [width] = useWindowSize();
  const [visibleSlides, setVisibleSlides] = useState(3);
  const walletAddress = useSelector((state: IState): string => state.wallet.address);
  const updateToken = useSelector((state: IState) => state.lootbox.updateToken);
  const defaultCardWidth = 384;

  const updateOwnedLootboxes = async () => await fetchLootboxesDetails();

  useEffect(() => {
    if (!walletAddress) {
      return;
    }

    updateOwnedLootboxes();
  }, [walletAddress, updateToken]);

  useEffect(() => {
    const wrapperVideo = wrapperElement.current?.clientWidth || width;

    if (wrapperVideo <= defaultCardWidth * 2) {
      setVisibleSlides(1);

      return;
    }

    if (wrapperVideo < defaultCardWidth * 3) {
      setVisibleSlides(2);

      return;
    }

    setVisibleSlides(Math.floor(wrapperVideo / defaultCardWidth));
  }, [width]);

  useEffect(() => {
    if (width < defaultCardWidth * 2) {
      setVisibleSlides(1);

      return;
    }

    if (width < defaultCardWidth * 3) {
      setVisibleSlides(2);

      return;
    }

    setVisibleSlides(3);
  }, [width]);

  if (!walletAddress) {
    return <></>;
  }

  if (!loading && !lootboxes?.length) {
    return <LootboxOwnedEmptySection className={className} openBuyLootboxes={openBuyLootboxes} />;
  }

  return (
    <>
      <LootboxSection className={className}>
        {loading ? (
          <div className={styles['lootbox-owned__loading-section']}>
            <LoadingSpinner />
          </div>
        ) : (
          <div ref={wrapperElement}>
            <Slider
              dots
              variableWidth
              speed={300}
              rows={1}
              arrows={false}
              infinite={false}
              dotsClass={`slick-dots ${styles['lootbox-owned__carousel-dots']}`}
              className={styles['lootbox-owned__slider']}
              slidesToScroll={1}
              slidesToShow={visibleSlides}
            >
              {lootboxes?.map(({ id, address, quantityOwned, metadata }) => (
                <div className={styles['lootbox-owned__card-wrapper']} key={`lootbox-${id}`}>
                  <LootboxCardLarge
                    autoplay
                    loop
                    title={metadata?.name}
                    background={metadata?.image}
                    className={styles['lootbox-owned__card']}
                  >
                    <div className={styles['lootbox-owned__card-content']}>
                      <LootboxOwnedItem
                        assetId={id}
                        address={address}
                        quantityOwned={+quantityOwned}
                        metadata={metadata}
                      />
                    </div>
                  </LootboxCardLarge>
                </div>
              ))}
            </Slider>
          </div>
        )}
      </LootboxSection>
    </>
  );
};

export default LootboxOwned;
