import NftCardBackPlaceholder from '../../../nftCardBackPlaceholder/NftCardBackPlaceholder';
import NftCardBackSlide from '../../../nftCardBackSlide/NftCardBackSlide';
import { useNftCardContext } from '../../../nftCardContext/NftCardContext';

const NftCardBackChatSlide = () => {
  const { asset } = useNftCardContext();

  return (
    <NftCardBackSlide label="Chat">
      <NftCardBackPlaceholder text="Dedicated chat" name={asset?.name} />
    </NftCardBackSlide>
  );
};

export default NftCardBackChatSlide;
