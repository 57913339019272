import { ICurrencyFilterOption } from '../models/i-currency-filter-option.interface';
import { IMarketCollectionOption } from '../models/market/i-market-collection-option.interface';
import { IMarketOrderOption } from '../models/market/i-market-order-option.interface';
import { IMarketStatus } from '../models/market/i-market-status.interface';

import { MarketOrdering } from '../enums/market/market-ordering.enum';
import { MarketStatus } from '../enums/market/market-status.enum';
import { OfferTypesEnum } from '../enums/offer-types.enum';

export interface IMarketFiltersSource {
  ordering: IMarketOrderOption[];
  collections: IMarketCollectionOption[];
  statuses: IMarketStatus[];
  priceCurrencies: ICurrencyFilterOption[];
  revenuePeriods: string[];
}

export const MARKET_FILTERS = {
  ordering: [
    { key: MarketOrdering.newestFirst, value: MarketOrdering.newestFirst, label: 'Newest' },
    { key: MarketOrdering.oldestFirst, value: MarketOrdering.oldestFirst, label: 'Oldest' },
    { key: MarketOrdering.highestPrice, value: MarketOrdering.highestPrice, label: 'Highest price' },
    { key: MarketOrdering.lowestPrice, value: MarketOrdering.lowestPrice, label: 'Lowest price' },
    { key: MarketOrdering.closestDue, value: MarketOrdering.closestDue, label: 'Closest Due' },
    { key: MarketOrdering.furthestDue, value: MarketOrdering.furthestDue, label: 'Furthest Due' },
  ],
  statuses: [
    {
      key: MarketStatus.buyNow,
      value: 'buy now',
    },
    {
      key: MarketStatus.onAuction,
      value: 'on auction',
    },
    {
      key: MarketStatus.new,
      value: 'new',
    },
    {
      key: MarketStatus.hasOffers,
      value: 'has offers',
    },
  ],
  revenuePeriods: [
    {
      label: 'Yearly',
      value: 'year',
    },
    {
      label: 'Monthly',
      value: 'month',
    },
    {
      label: 'Daily',
      value: 'day',
    },
  ],
};

const EIP712_DOMAIN = [
  { name: 'name', type: 'string' },
  { name: 'version', type: 'string' },
  { name: 'chainId', type: 'uint256' },
  { name: 'verifyingContract', type: 'address' },
];

const TRADE = [
  { name: 'nonce', type: 'uint32' },
  { name: 'expirationTime', type: 'uint40' },
  { name: 'tradeTokenAddress', type: 'address' },
  { name: 'itemsTokenAddress', type: 'address' },
  { name: 'items', type: 'TradeItem[]' },
];

export const TRADE_OFFER = [...TRADE, { name: 'offerValue', type: 'uint256' }, { name: 'to', type: 'address' }];

export const TRADE_BID = [...TRADE, { name: 'bidValue', type: 'uint256' }];

const TRADE_ITEM = [
  { name: 'tokenId', type: 'uint256' },
  { name: 'amount', type: 'uint256' },
  { name: 'stateHash', type: 'bytes32' },
];

export const OFFER_TYPES = {
  [OfferTypesEnum.EIP712Domain]: EIP712_DOMAIN,
  [OfferTypesEnum.TradeItem]: TRADE_ITEM,
  [OfferTypesEnum.TradeBid]: TRADE_BID,
  [OfferTypesEnum.TradeOffer]: TRADE_OFFER,
};

export const TRADE_PARAMS = {
  signMethod: 'eth_signTypedData_v4',
};

export const MARKET_CATEGORY_DEFAULT_OPTION = { label: 'All', value: '' };

export const MARKET_CATEGORY_FILTERS = [
  MARKET_CATEGORY_DEFAULT_OPTION,
  { value: 'airport', label: 'Airport' },
  { value: 'building', label: 'Building' },
  { value: 'city', label: 'City' },
  { value: 'country', label: 'Country' },
  { value: 'famous building', label: 'Famous Building' },
  { value: 'historic site', label: 'Historic Site' },
  { value: 'hq', label: 'HQ' },
  { value: 'parliament', label: 'Parliament' },
  { value: 'tourist attraction', label: 'Tourist Attraction' },
  { value: 'train station', label: 'Train Station' },
];
