import mapboxgl from 'mapbox-gl';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { MAP_CONSTANT } from '../constants/map.constant';
import { IVenueModelConfiguration } from '../models/i-venue-model-configuration';
import { IVenue } from '../models/i-venue.interface';

interface IBuildLoaderModel {
  radius: number;
  widthSegments: number;
  heightSegments: number;
  color: string;
}

const buildLoaderModal = ({
  radius,
  widthSegments,
  heightSegments,
  color,
}: IBuildLoaderModel): THREE.Object3D<THREE.Event> => {
  const geometry = new THREE.SphereGeometry(radius, widthSegments, heightSegments);
  const material = new THREE.MeshBasicMaterial({
    color,
    transparent: true,
  });

  const dotsNumber = 8;
  const loaderDots = [...Array(dotsNumber)]
    .map(() => new THREE.Mesh(geometry, material))
    .map((loaderDot, index) => {
      const x = 0 + 15 * Math.cos((2 * Math.PI * index) / dotsNumber);
      const y = 0 + 15 * Math.sin((2 * Math.PI * index) / dotsNumber);
      const z = 0;

      loaderDot.position.set(x, y, z);

      return loaderDot;
    });

  const loaderPivot = new THREE.Object3D();
  loaderPivot.name = 'loader-pivot';

  loaderDots.forEach((loaderDot) => loaderPivot.add(loaderDot));

  return loaderPivot;
};

const buildDrcModel = (
  venue: IVenue,
  scale: number,
  geometryDracoLoader: THREE.BufferGeometry
): THREE.Mesh<THREE.BufferGeometry, THREE.MeshStandardMaterial> => {
  const material = new THREE.MeshStandardMaterial({
    color: new THREE.Color('#F83A56'),
    opacity: 0,
    transparent: true,
  });
  const mesh = new THREE.Mesh(geometryDracoLoader, material);
  mesh.scale.set(scale, scale, scale);
  mesh.name = 'venue';
  mesh.rotation.set(0, THREE.MathUtils.degToRad(venue.modelRotationDegrees), 0);

  return mesh;
};

interface IBuildGltfModel {
  venue: IVenue;
  gltf: GLTF;
  scale: number;
  color: string;
}

const buildGltfModel = ({ venue, gltf, scale, color }: IBuildGltfModel): THREE.Group => {
  const material = new THREE.MeshStandardMaterial({
    color,
  });

  gltf.scene.children.forEach((gltfModelChild) => {
    const childAsMesh = gltfModelChild as THREE.Mesh;

    const childWithoutMaterial = !!childAsMesh?.material;

    if (childWithoutMaterial) {
      childAsMesh.material = material;
    } else {
      childAsMesh.children.forEach((meshChild) => {
        const meshChildAsMesh = meshChild as THREE.Mesh;

        if (!!meshChildAsMesh?.material) {
          meshChildAsMesh.material = material;
        }
      });
    }
  });

  gltf.scene.name = 'venue';
  gltf.scene.scale.set(scale, scale, scale);
  gltf.scene.rotation.set(0, THREE.MathUtils.degToRad(venue.modelRotationDegrees), 0);

  return gltf.scene;
};

const buildModelConfiguration = (venue: IVenue): IVenueModelConfiguration => {
  const venueModelConfiguration = MAP_CONSTANT.venueModelConfiguration;
  const modelAltitude = venueModelConfiguration.altitude;
  const modelLocation = [venue.lon, venue.lat] as mapboxgl.LngLatLike;
  const modelRotate = [Math.PI / 2, venue.modelRotationDegrees * venueModelConfiguration.rotationMultiplier, 0];
  const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(modelLocation, modelAltitude);

  const scale = venueModelConfiguration.scaleMultiplier * venue?.modelScale;
  const id = `layer-${venue.id}`;
  const transform = {
    translateX: modelAsMercatorCoordinate.x,
    translateY: modelAsMercatorCoordinate.y,
    translateZ: modelAsMercatorCoordinate.z as number,
    rotateX: modelRotate[0],
    rotateY: modelRotate[1],
    rotateZ: modelRotate[2] as number,
    scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
  };

  return {
    id,
    scale,
    transform,
  };
};

export const map3dModelBuilder = {
  buildLoaderModal,
  buildDrcModel,
  buildGltfModel,
  buildModelConfiguration,
};
