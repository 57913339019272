import { ReactNode } from 'react';

import Tooltip from '../tooltip/Tooltip';
import { ReactComponent as InfoIcon } from '../../assets/icons/info-filled.svg';

import styles from './InfoBox.module.scss';

interface IInfoBoxProps {
  tooltipText?: string;
  tooltipContent?: ReactNode;
  children?: string | ReactNode;
  className?: string;
}

const InfoBox = ({ children, tooltipContent, tooltipText, className = '' }: IInfoBoxProps) => {
  const classNames = [className, styles['info-box']];

  return (
    <Tooltip className={styles['info-tooltip']} text={tooltipText} content={tooltipContent}>
      <div className={classNames.join(' ')}>
        <InfoIcon className={styles['info-icon']} />
        <span>{children}</span>
      </div>
    </Tooltip>
  );
};

export default InfoBox;
