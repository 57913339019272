import { useEffect } from 'react';

import { IAssetDetails } from '../../../models/assets/i-asset-details.interface';

import { DEFAULTS } from '../../../constants/defaults.constant';
import { ROUTES } from 'constants/routes.constant';

import CheckoutModalSuccess from '../../../components/modal/completeCheckoutModal/checkoutModalSuccess/CheckoutModalSuccess';
import ModalPortal from '../../../components/modal/ModalPortal';
import RedeemProgressModal from '../../../components/modal/redeemProgressModal/RedeemProgressModal';

import { useTraits } from '../../../hooks/useTraits';

import styles from './RedeemGleam.module.scss';

interface IRedeemGleamProps {
  asset: IAssetDetails | null;
  claimingFinished: boolean;
  claimingInProgress: boolean;
  nftShares: number;
  transactionHash: string;
  redeemGleamUrl: string;
  onProgressModalClose: () => void;
  onSuccessModalClose: () => void;
}

const RedeemGleam = ({
  asset,
  claimingFinished,
  claimingInProgress,
  nftShares,
  transactionHash,
  redeemGleamUrl,
  onProgressModalClose,
  onSuccessModalClose,
}: IRedeemGleamProps) => {
  const { category, id } = useTraits(asset?.traits || []);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = DEFAULTS.gleamScriptUrl;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      {claimingInProgress && (
        <ModalPortal
          title="your claim is processing..."
          modalContentClassName={styles['progress-modal-content']}
          onClose={onProgressModalClose}
        >
          <RedeemProgressModal
            transactionHash={transactionHash}
            nftName={asset?.name}
            nftAddress={asset?.address}
            nftShares={nftShares}
          ></RedeemProgressModal>
        </ModalPortal>
      )}
      {claimingFinished && asset && (
        <ModalPortal
          title="your claim is complete!"
          modalContentClassName={styles['success-modal-content']}
          onClose={onSuccessModalClose}
        >
          <CheckoutModalSuccess
            action="claim"
            checkoutSuccessData={{
              totalShares: asset.total_shares,
              id: +id,
              address: asset.address,
              cardUrl: asset.asset_original_url!,
              category: category,
              name: asset.name!,
              iconUrl: asset.marker_url,
              value: asset.nft_value,
              token_id: asset.token_id,
              amount: nftShares,
            }}
            showListForSaleButton={false}
            transactionHash={transactionHash!}
            viewItemLink={`${ROUTES.PROFILE}/collected`}
          ></CheckoutModalSuccess>
        </ModalPortal>
      )}
      <a className="e-widget no-button" href={redeemGleamUrl} rel="nofollow">
        Giveaway
      </a>
    </>
  );
};

export default RedeemGleam;
