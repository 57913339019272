import detectEthereumProvider from '@metamask/detect-provider';
import { NFT, ThirdwebSDK } from '@thirdweb-dev/sdk';
import Torus from '@toruslabs/torus-embed';
import { BigNumber, constants as etherConstants, ethers } from 'ethers';
import Cookies from 'universal-cookie/es6';
import Web3 from 'web3';

import { ListingType } from './models/lootbox/i-lootbox-purchase-config.interface';
import { ILootboxListingResult } from './models/lootbox/i-lootbox-trade.interface';
import { ITradeRequest } from './models/trade/i-trade-request.interface';
import { IWalletCurrencyDetails } from './models/wallet-currency.interface';
import { IAuctionHouseTransactionConfiguration } from './models/web3Singleton/i-auction-house-transaction-configuration.interface';
import { ILootboxListingTransactionConfiguration } from './models/web3Singleton/i-lootbox-listing-transaction-configuration.interface';
import { ILootboxPackTransactionConfiguration } from './models/web3Singleton/i-lootbox-pack-transaction-configuration.interface';
import { ITransactionConfiguration } from './models/web3Singleton/i-transaction-configuration.interface';

import { CookiesKeys } from './enums/cookies-keys.enum';
import { OfferTypesEnum } from './enums/offer-types.enum';
import { PriceCurrencySymbols } from './enums/price-currency-symbols.enum';

import { AUCTION_HOUSE_ABI } from './constants/abi/auction-house-abi.constant';
import { CASHIER_PAYMENT_ABI } from './constants/abi/cashier-payment-abi.constant';
import { ERC1155_ABI } from './constants/abi/erc1155-abi.constant';
import { LOOT_BOX_STORE_ABI } from './constants/abi/loot-box-store-abi.constant';
import { TRADE_CONTRACT_ABI } from './constants/abi/trade-contract-abi.constant';
import { DEFAULTS } from './constants/defaults.constant';
import { OFFER_TYPES, TRADE_PARAMS } from './constants/market.constant';
import { NETWORK_SETTINGS } from './constants/network.constant';
import { WEB3 } from './constants/web3.constant';
import { FLEXIBLE_STAKE_CONTRACT_ABI } from 'constants/abi/flexible-stake-contract-abi.constant';
import { NEGATIVE_STAKE_CONTRACT_ABI } from 'constants/abi/negative-stake-contract-abi.constant';
import { PERIODICAL_STAKE_CONTRACT_ABI } from 'constants/abi/periodical-stake-contract-abi.constant';
import { STAKE_CONTRACT_ABI } from 'constants/abi/stake-contract-abi.constant';

import { IEnvironmentAddNetwork } from './hooks/useEnv';

import { removeAuthenticationCookies } from './utils/remove-authentication-cookies.util';

import { Web3Providers } from './reducers/web3.reducer';

interface IDomain {
  name: string;
  version: string;
  chainId: number;
  verifyingContract: string;
}

class Web3Singleton {
  private onProviderChange: (provider: Web3Providers) => void = () => {};
  private static _instance: Web3Singleton = new Web3Singleton();
  private web3: Web3 | null = null;
  private ethereum: any = null;
  private currentProvider: Web3Providers = Web3Providers.none;
  private torus: Torus | null = null;
  private NO_WEB3_MESSAGE = 'No Web3 provider detected';
  private isMetamaskUsed = null;
  private readonly settings = NETWORK_SETTINGS;
  private readonly tradeAbi = TRADE_CONTRACT_ABI;
  private readonly auctionHouseAbi = AUCTION_HOUSE_ABI;
  private readonly cashierPaymentAbi = CASHIER_PAYMENT_ABI;
  private readonly lootboxStoreAbi = LOOT_BOX_STORE_ABI;
  private readonly gasMultiplier = +process.env.REACT_APP_GAS_PRICE_MULTIPLIER!;
  private readonly lootboxOpenGasLimit = '1000000';
  private readonly flexibleStakeAbi = FLEXIBLE_STAKE_CONTRACT_ABI;
  private readonly negativeStakeAbi = NEGATIVE_STAKE_CONTRACT_ABI;
  private readonly stakeAbi = STAKE_CONTRACT_ABI;
  private readonly periodicalStakeAbi = PERIODICAL_STAKE_CONTRACT_ABI;

  constructor() {
    if (Web3Singleton._instance) {
      throw new Error('Error: Instantiation failed: Use SingletonClass.getInstance() instead of new.');
    }

    Web3Singleton._instance = this;
  }

  static getInstance = (): Web3Singleton => {
    return Web3Singleton._instance;
  };

  registerOnProviderChange = (onProviderChange: (provider: Web3Providers) => void): void => {
    this.onProviderChange = onProviderChange;
  };

  getWeb3 = (): Web3 | null => {
    return this.web3;
  };

  getAddress = async (): Promise<string | null> => {
    const address = await this.web3?.eth.getAccounts();

    return address?.length ? address[0] : '';
  };

  getChainId = async (): Promise<number> => {
    if (!this.web3) {
      return Promise.resolve(0);
    }

    return await this.web3.eth.getChainId();
  };

  isWithProvider = async (): Promise<boolean> => {
    if (this.currentProvider === Web3Providers.none) {
      return false;
    }

    return true;
  };

  isOnCorrectNetwork = async (): Promise<boolean> => {
    if (this.currentProvider === Web3Providers.none) {
      return false;
    }

    const chainId = await this.getChainId();
    const currentChainIdHex = this.web3?.utils.toHex(chainId);
    const correctNetworkIdHex = this.web3?.utils.toHex(+process.env.REACT_APP_NETWORK_ID!);

    return correctNetworkIdHex === currentChainIdHex;
  };

  getCurrencyBalance = async (abi: any[], currencyAddress: string, accountAddress: string): Promise<string> => {
    if (!this.web3) {
      throw new Error(this.NO_WEB3_MESSAGE);
    }

    const contract = new this.web3.eth.Contract(abi, currencyAddress);
    const balance = await contract.methods.balanceOf(accountAddress).call();

    return balance as string;
  };

  createStateHashForSale = async (abi: any[], assetAddres: string, tokenId: string): Promise<string> => {
    if (!this.web3) {
      throw new Error(this.NO_WEB3_MESSAGE);
    }

    const gasPrice = await this.getGasPrice();
    const address = this.settings.BUILD.ADDRESS;
    const contract = new this.web3.eth.Contract(abi, address).methods;

    return assetAddres === address ? await contract.stateHash(tokenId).call({ gasPrice }) : WEB3.nullStateHash;
  };

  changeChain = async (addNetworkSettings: IEnvironmentAddNetwork): Promise<void> => {
    if (this.currentProvider === Web3Providers.torus) {
      const network = JSON.parse(process.env.REACT_APP_TORUS!);

      this.torus!.setProvider({
        ...network,
      });

      return;
    }

    try {
      await (window as any).ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [addNetworkSettings],
      });

      await this.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: this.web3?.utils.toHex(process.env.REACT_APP_NETWORK_ID!) }],
      });
    } catch (error) {
      throw new Error((error as any).message);
    }
  };

  // sign
  createSignature = async (toSignature: string, walletAddress: string): Promise<any> => {
    const message = `0x${Buffer.from(toSignature, 'utf8').toString('hex')}`;
    const signature = (await this.ethereum.request({
      method: 'personal_sign',
      params: [message, walletAddress],
    })) as any;

    return signature;
  };

  signMethod = async (walletAddress: string, message: any): Promise<any> => {
    const domain = await this.createDomain();

    return this.ethereum.request({
      method: TRADE_PARAMS.signMethod,
      params: [
        walletAddress,
        JSON.stringify({
          domain,
          message,
          types: {
            [OfferTypesEnum.EIP712Domain]: OFFER_TYPES[OfferTypesEnum.EIP712Domain],
            [OfferTypesEnum.TradeItem]: OFFER_TYPES[OfferTypesEnum.TradeItem],
            [OfferTypesEnum.TradeOffer]: OFFER_TYPES[OfferTypesEnum.TradeOffer],
          },
          primaryType: OfferTypesEnum.TradeOffer,
        }),
      ],
    });
  };

  signMarketOfferBid = async (tradeRequest: ITradeRequest, accountAddress: string) => {
    const domain = await this.createDomain();

    return this.ethereum.request({
      method: TRADE_PARAMS.signMethod,
      params: [
        accountAddress,
        JSON.stringify({
          domain,
          message: tradeRequest,
          types: {
            [OfferTypesEnum.EIP712Domain]: OFFER_TYPES[OfferTypesEnum.EIP712Domain],
            [OfferTypesEnum.TradeItem]: OFFER_TYPES[OfferTypesEnum.TradeItem],
            [OfferTypesEnum.TradeBid]: OFFER_TYPES[OfferTypesEnum.TradeBid],
          },
          primaryType: OfferTypesEnum.TradeBid,
        }),
      ],
    });
  };

  acceptMarketOfferBid = async (configuration: ITransactionConfiguration) => {
    const tradeAddress = this.settings.TRADE.ADDRESS;
    const contract = new this.web3!.eth.Contract(this.tradeAbi, tradeAddress);
    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();
    await contract.methods
      .sellOnBid(configuration.to, configuration.signature, configuration.tradeRequest)
      .send({ from: configuration.walletAddress, gasPrice })
      .on('transactionHash', (hash: string) => configuration.onTransactionHash(hash))
      .on(
        'confirmation',
        (blocks: number) => blocks === DEFAULTS.defaultConfirmationBlocks && configuration.offerAccepted()
      )
      .on('offerCanceled', () => configuration.offerCanceled());
  };

  auctionHouseBid = async (configuration: IAuctionHouseTransactionConfiguration) => {
    const auctionHouseAddress = this.settings.AUCTION_HOUSE.ADDRESS;
    const contract = new this.web3!.eth.Contract(this.auctionHouseAbi, auctionHouseAddress);
    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();
    await contract.methods
      .bid(configuration.auctionId, configuration.amount)
      .send({ from: configuration.walletAddress, gasPrice })
      .on('transactionHash', (hash: string) => configuration.onTransactionHash(hash))
      .on(
        'confirmation',
        (blocks: number) => blocks === DEFAULTS.defaultConfirmationBlocks && configuration.onSuccess()
      );
  };

  auctionHouseStableBid = async (configuration: IAuctionHouseTransactionConfiguration) => {
    const auctionHouseAddress = this.settings.AUCTION_HOUSE.ADDRESS;
    const contract = new this.web3!.eth.Contract(this.auctionHouseAbi, auctionHouseAddress);
    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();
    await contract.methods
      .bidStable(configuration.auctionId, configuration.amount)
      .send({ from: configuration.walletAddress, gasPrice })
      .on('transactionHash', (hash: string) => configuration.onTransactionHash(hash))
      .on(
        'confirmation',
        (blocks: number) => blocks === DEFAULTS.defaultConfirmationBlocks && configuration.onSuccess()
      );
  };

  // permission
  checkPermissionToERC1155 = async (walletAddress: string, assetAddress: string): Promise<boolean> => {
    if (!this.web3) {
      throw new Error(this.NO_WEB3_MESSAGE);
    }

    const gasPrice = await this.getGasPrice();
    const hasPermission = await new this.web3.eth.Contract(ERC1155_ABI, assetAddress).methods
      .isApprovedForAll(walletAddress, this.settings.ERC1155_HANDLER.ADDRESS)
      .call({ gasPrice });

    return hasPermission;
  };

  grantPermissionToAssetERC1155 = async (walletAddress: string, assetAddress: string): Promise<void> => {
    if (!this.web3) {
      throw new Error(this.NO_WEB3_MESSAGE);
    }

    await this.updateNetwork();
    const gasPrice = await this.getGasPrice();
    await new this.web3.eth.Contract(ERC1155_ABI, assetAddress).methods
      .setApprovalForAll(this.settings.ERC1155_HANDLER.ADDRESS, true)
      .send({ from: walletAddress, gasPrice });
  };

  checkPermissionToCurrency = async (
    abi: any[],
    assetAddress: string,
    walletAddress: string,
    currency: Pick<IWalletCurrencyDetails, 'address'>,
    currencyAmount: number
  ) => {
    if (!this.web3) {
      throw new Error(this.NO_WEB3_MESSAGE);
    }

    const gasPrice = await this.getGasPrice();
    const contract = new this.web3.eth.Contract(abi, currency.address);
    const hasPermission = await contract.methods
      .allowance(walletAddress, assetAddress)
      .call({ gasPrice })
      .then((result: string) => +result >= currencyAmount);

    return hasPermission;
  };

  grantCurrencyPermissionService = async (
    abi: any[],
    contractAddress: string,
    currency: Pick<IWalletCurrencyDetails, 'address' | 'decimal'>,
    accountAddress: string
  ) => {
    if (!this.web3) {
      throw new Error(this.NO_WEB3_MESSAGE);
    }

    const recommendedPaymentLimit = BigNumber.from(10)
      .pow(currency.decimal)
      .mul(DEFAULTS.defaultRecommendedPaymentLimit)
      .toString();
    const contract = new this.web3.eth.Contract(abi, currency.address);
    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();
    await contract.methods.approve(contractAddress, recommendedPaymentLimit).send({ from: accountAddress, gasPrice });
  };

  grantCurrencyPermissionServiceWithAmount = async (
    abi: any[],
    contractAddress: string,
    currency: IWalletCurrencyDetails,
    accountAddress: string,
    amount: string
  ) => {
    if (!this.web3) {
      throw new Error(this.NO_WEB3_MESSAGE);
    }

    const contract = new this.web3.eth.Contract(abi, currency.address);
    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();
    await contract.methods.approve(contractAddress, amount).send({ from: accountAddress, gasPrice });
  };

  // initialization
  initializeProviders = async (): Promise<Web3Providers> => {
    this.isMetamaskUsed = await detectEthereumProvider({ mustBeMetaMask: true });
    let isTorusUsed = !!new Cookies().get(CookiesKeys.isTorusUsed);

    if (isTorusUsed) {
      try {
        await this.initializeTorus();
        await this.loginTorus();

        return this.currentProvider;
      } catch (e) {
        isTorusUsed = false;

        removeAuthenticationCookies();
        this.logoutTorus();
      }
    }

    if (this.isMetamaskUsed) {
      await this.initializeMetamask();
      this.onProviderChange(Web3Providers.metamask);

      return this.currentProvider;
    }

    this.onProviderChange(Web3Providers.none);
    return this.currentProvider;
  };

  initializeMetamask = async (): Promise<void> => {
    if (this.torus) {
      this.torus.hideTorusButton();
    }

    const providers = (window as any).ethereum.providers;

    if (Array.isArray(providers)) {
      const metamask = Array.from((window as any).ethereum.providers).find(
        (provider: any) => provider.isMetaMask
      ) as any;

      this.web3 = new Web3(metamask);
      this.ethereum = metamask;
    } else {
      this.web3 = new Web3(Web3.givenProvider);
      this.ethereum = await detectEthereumProvider({ mustBeMetaMask: true });
    }

    this.changeProvider(Web3Providers.metamask);
  };

  initializeTorus = async (): Promise<void> => {
    if (this.torus) {
      this.torus.showTorusButton();
    }

    const torus = new Torus();
    const isProd = process.env.NODE_ENV !== 'development';

    await torus.init({
      buildEnv: isProd ? 'production' : 'testing',
      enableLogging: isProd ? false : true,
      network: JSON.parse(process.env.REACT_APP_TORUS!),
    });

    this.torus = torus;
    this.web3 = new Web3(torus.provider as any);
    this.ethereum = torus.ethereum;
    this.changeProvider(Web3Providers.torus);
  };

  resetProviders(): void {
    if (this.currentProvider !== Web3Providers.torus) {
      return;
    }

    this.web3 = null;
    this.ethereum = null;
    this.torus = null;

    if (this.isMetamaskUsed) {
      this.initializeMetamask();
    }
  }

  // logout
  logout = async (): Promise<void> => {
    switch (this.currentProvider) {
      case Web3Providers.torus:
        this.logoutTorus();
        break;
      case Web3Providers.metamask:
        break;
      default:
        break;
    }
  };

  // TODO
  // torus transaction error workaround
  updateNetwork = async () => {
    if (this.torus) {
      const network = JSON.parse(process.env.REACT_APP_TORUS!);
      await this.torus.setProvider({
        ...network,
      });
    }
  };

  // login
  loginTorus = async (): Promise<void> => {
    await this.torus!.login();
    await this.requestAccounts();
  };

  loginMetamask = async (): Promise<void> => {
    await this.requestAccounts();
  };

  // listeners
  onChainChange = async (handleAccountChange: (chain: string) => void) => {
    this.ethereum?.addListener('chainChanged', handleAccountChange as any);
  };

  removeOnChainChange = async (handleAccountChange: (chain: string) => void) => {
    this.ethereum?.removeListener('chainChanged', handleAccountChange as any);
  };

  onAccountChange = async (handleAccountChange: (walletAddress: string[]) => void) => {
    this.ethereum?.addListener('accountsChanged', handleAccountChange as any);
  };

  removeOnAccountChange = async (handleAccountChange: (walletAddress: string[]) => void) => {
    this.ethereum?.removeListener('accountsChanged', handleAccountChange as any);
  };

  // market
  purchesAsset = async (configuration: ITransactionConfiguration): Promise<void> => {
    const tradeAddress = this.settings.TRADE.ADDRESS;
    const contract = new this.web3!.eth.Contract(this.tradeAbi, tradeAddress);
    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();
    await contract.methods
      .buyOnOffer(configuration.to, configuration.signature, configuration.tradeRequest)
      .send({ from: configuration.walletAddress, gasPrice })
      .on('transactionHash', (hash: string) => configuration.onTransactionHash(hash))
      .on(
        'confirmation',
        (blocks: number) => blocks === DEFAULTS.defaultConfirmationBlocks && configuration.offerAccepted()
      )
      .on('offerCanceled', () => configuration.offerCanceled());
  };

  passiveIncomeWithdrawn = async (walletAddress: string) => {
    const tradeAddress = this.settings.CASHIER_HANDLER.ADDRESS;
    const contract = new this.web3!.eth.Contract(this.cashierPaymentAbi, tradeAddress);
    const gasPrice = await this.getGasPrice();

    return await contract.methods.getWithdrawn(walletAddress).call({ gasPrice });
  };

  passiveIncomeVoucherPayment = async (walletAddress: string, amount: number, voucherPaymentSignature: string) => {
    const cashierAddress = this.settings.CASHIER_HANDLER.ADDRESS;
    const contract = new this.web3!.eth.Contract(this.cashierPaymentAbi, cashierAddress);
    const amountInWei = BigNumber.from(amount).mul(etherConstants.WeiPerEther);
    const gasPrice = await this.getGasPrice();

    return await contract.methods
      .withdraw({ receiver: walletAddress, totalAmount: amountInWei }, voucherPaymentSignature)
      .send({ from: walletAddress, gasPrice });
  };

  getTransactionStatus = async (transactionHash: string): Promise<boolean> => {
    return this.web3!.eth.getTransactionReceipt(transactionHash).then(
      (transactionReceipt) => transactionReceipt?.status
    );
  };

  // lootboxes
  getLootboxesOwned = async (walletAddress: string, packContractAddress: string): Promise<NFT[]> => {
    const ethersProvider = new ethers.providers.Web3Provider(this.web3?.currentProvider as any);
    const sdk = ThirdwebSDK.fromSigner(ethersProvider.getSigner(), this.getChainName());
    const packContract = await sdk.getContract(packContractAddress, 'pack');

    return packContract.erc1155.getOwned(walletAddress);
  };

  getMarketplaceLootboxesListings = async (): Promise<ILootboxListingResult[]> => {
    const ethersProvider = new ethers.providers.Web3Provider(this.web3?.currentProvider as any);
    const sdk = ThirdwebSDK.fromSigner(ethersProvider.getSigner(), this.getChainName());
    const marketplaceContract = await sdk.getContract(this.settings.LOOTBOX_MARKETPLACE.ADDRESS, 'marketplace-v3');

    const listingsData = await marketplaceContract.directListings.getAllValid();

    return listingsData.map((listing) => {
      const value = listing.pricePerToken;
      const displayValue = BigNumber.from(value)
        .div(BigNumber.from(10).pow(listing.currencyValuePerToken.decimals))
        .toString();

      return {
        id: listing.id,
        asset: { id: listing.asset.id },
        quantity: +listing.quantity,
        currencyValuePerToken: {
          symbol:
            listing.currencyValuePerToken.symbol === PriceCurrencySymbols.RMV
              ? PriceCurrencySymbols.RMV
              : PriceCurrencySymbols.USDC,
          value,
          displayValue,
        },
        type: ListingType.MarketplaceV3,
      };
    });
  };

  purchaseMarketplaceLootboxListing = async (configuration: ILootboxListingTransactionConfiguration) => {
    const ethersProvider = new ethers.providers.Web3Provider(this.web3?.currentProvider as any);
    const sdk = ThirdwebSDK.fromSigner(ethersProvider.getSigner(), this.getChainName());
    const marketplaceContract = await sdk.getContract(this.settings.LOOTBOX_MARKETPLACE.ADDRESS, 'marketplace-v3');

    return marketplaceContract.directListings
      .buyFromListing(configuration.listingId, configuration.quantity, configuration.walletAddress)
      .then(({ receipt }) => {
        if (receipt.transactionHash) {
          configuration.onTransactionHash(receipt.transactionHash);
        }
      });
  };

  getLootboxListings = async (): Promise<ILootboxListingResult[]> => {
    const contractAddress = this.settings.LOOTBOX_STORE.ADDRESS;
    const contract = new this.web3!.eth.Contract(this.lootboxStoreAbi, contractAddress);
    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();
    const listingsData = await contract.methods.getAllValidListings().call({ gasPrice });

    return listingsData[0].map((listingId: number, index: number) => {
      const assetId = listingsData[2][index];
      const quantity = listingsData[3][index];
      const value = listingsData[4][index];
      const displayValue = BigNumber.from(value).div(BigNumber.from(10).pow(18)).toString();

      return {
        id: listingId,
        asset: { id: assetId },
        quantity: +quantity,
        currencyValuePerToken: {
          symbol: PriceCurrencySymbols.RMV,
          value,
          displayValue,
        },
        type: ListingType.Contract,
      };
    });
  };

  purchaseLootboxListing = async (configuration: ILootboxListingTransactionConfiguration) => {
    const contractAddress = this.settings.LOOTBOX_STORE.ADDRESS;
    const contract = new this.web3!.eth.Contract(this.lootboxStoreAbi, contractAddress);
    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();
    await contract.methods
      .safePurchase(configuration.listingId, configuration.quantity, configuration.forMaxPriceInQT)
      .send({ from: configuration.walletAddress, gasPrice })
      .on('transactionHash', (hash: string) => configuration.onTransactionHash(hash))
      .on(
        'confirmation',
        (blocks: number) => blocks === DEFAULTS.defaultConfirmationBlocks && configuration.offerAccepted()
      )
      .on('offerCanceled', () => configuration.offerCanceled());
  };

  checkLootboxPrice = async (listingId: string) => {
    const contractAddress = this.settings.LOOTBOX_STORE.ADDRESS;
    const contract = new this.web3!.eth.Contract(this.lootboxStoreAbi, contractAddress);
    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();
    return contract.methods.checkListingQTPrice(listingId).call({ gasPrice });
  };

  openLootbox = async (configuration: ILootboxPackTransactionConfiguration) => {
    const ethersProvider = new ethers.providers.Web3Provider(this.web3?.currentProvider as any);
    const sdk = ThirdwebSDK.fromSigner(ethersProvider.getSigner(), this.getChainName());
    const packContractAddress = configuration.address || this.settings.LOOTBOX_PACK.ADDRESS;
    const packContract = await sdk.getContract(packContractAddress, 'pack');
    const transaction = await packContract.open.prepare(configuration.id, configuration.quantity);

    let gasPriceBase = await this.getGasPrice();
    transaction.setGasPrice(gasPriceBase);
    transaction.setGasLimit(
      BigNumber.from(this.lootboxOpenGasLimit).mul(configuration.quantity > 5 ? 5 : configuration.quantity)
    );

    return await transaction.execute();
  };

  // Staking
  checkAPY = async (poolId: number, negative = false) => {
    const address = negative ? this.settings.NEGATIVE_STAKE.ADDRESS : this.settings.STAKE.ADDRESS;
    const abi = negative ? this.negativeStakeAbi : this.stakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods.checkAPY(poolId).call({ gasPrice });
  };

  checkStakingFee = async (poolId: number, flexible = false) => {
    const address = flexible ? this.settings.FLEXIBLE_STAKE.ADDRESS : this.settings.NEGATIVE_STAKE.ADDRESS;
    const abi = flexible ? this.flexibleStakeAbi : this.negativeStakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods.checkStakingFee(poolId).call({ gasPrice });
  };

  checkDefaultMinimumDeposit = async (negative = false, flexible = false) => {
    const address = flexible
      ? this.settings.FLEXIBLE_STAKE.ADDRESS
      : negative
      ? this.settings.NEGATIVE_STAKE.ADDRESS
      : this.settings.STAKE.ADDRESS;
    const abi = flexible ? this.flexibleStakeAbi : negative ? this.negativeStakeAbi : this.stakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods.checkDefaultMinimumDeposit().call({ gasPrice });
  };

  checkGeneratedInterestLastDayFor = async (walletAddress: string, poolId: number) => {
    const address = this.settings.STAKE.ADDRESS;
    const abi = this.stakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods.checkGeneratedInterestLastDayFor(walletAddress, poolId).call({ gasPrice });
  };

  checkGeneratedRewardLastDayFor = async (walletAddress: string, poolId: number) => {
    const address = this.settings.NEGATIVE_STAKE.ADDRESS;
    const abi = this.negativeStakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods.checkGeneratedRewardLastDayFor(walletAddress, poolId).call({ gasPrice });
  };

  checkIfInterestClaimOpen = async (poolId: number) => {
    const address = this.settings.STAKE.ADDRESS;
    const abi = this.stakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods.checkIfInterestClaimOpen(poolId).call({ gasPrice });
  };

  checkIfRewardClaimOpen = async (poolId: number) => {
    const address = this.settings.NEGATIVE_STAKE.ADDRESS;
    const abi = this.negativeStakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods.checkIfRewardClaimOpen(poolId).call({ gasPrice });
  };

  checkStakedAmountBy = async (walletAddress: string, poolId: number, negative = false, flexible = false) => {
    const address = flexible
      ? this.settings.FLEXIBLE_STAKE.ADDRESS
      : negative
      ? this.settings.NEGATIVE_STAKE.ADDRESS
      : this.settings.STAKE.ADDRESS;
    const abi = flexible ? this.flexibleStakeAbi : negative ? this.negativeStakeAbi : this.stakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods.checkStakedAmountBy(walletAddress, poolId).call({ gasPrice });
  };

  checkIfStakingOpen = async (poolId: number, negative = false, flexible = false) => {
    const address = flexible
      ? this.settings.FLEXIBLE_STAKE.ADDRESS
      : negative
      ? this.settings.NEGATIVE_STAKE.ADDRESS
      : this.settings.STAKE.ADDRESS;
    const abi = flexible ? this.flexibleStakeAbi : negative ? this.negativeStakeAbi : this.stakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods.checkIfStakingOpen(poolId).call({ gasPrice });
  };

  checkIfPoolEnded = async (poolId: number, negative = false, flexible = false) => {
    const address = flexible
      ? this.settings.FLEXIBLE_STAKE.ADDRESS
      : negative
      ? this.settings.NEGATIVE_STAKE.ADDRESS
      : this.settings.STAKE.ADDRESS;
    const abi = flexible ? this.flexibleStakeAbi : negative ? this.negativeStakeAbi : this.stakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods.checkIfPoolEnded(poolId).call({ gasPrice });
  };

  checkPoolType = async (poolId: number, negative = false, flexible = false) => {
    const address = flexible
      ? this.settings.FLEXIBLE_STAKE.ADDRESS
      : negative
      ? this.settings.NEGATIVE_STAKE.ADDRESS
      : this.settings.STAKE.ADDRESS;
    const abi = flexible ? this.flexibleStakeAbi : negative ? this.negativeStakeAbi : this.stakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods.checkPoolType(poolId).call({ gasPrice });
  };

  checkStakingTarget = async (poolId: number, negative = false, flexible = false) => {
    const address = flexible
      ? this.settings.FLEXIBLE_STAKE.ADDRESS
      : negative
      ? this.settings.NEGATIVE_STAKE.ADDRESS
      : this.settings.STAKE.ADDRESS;
    const abi = flexible ? this.flexibleStakeAbi : negative ? this.negativeStakeAbi : this.stakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods.checkStakingTarget(poolId).call({ gasPrice });
  };

  checkTotalClaimableInterestBy = async (walletAddress: string, poolId: number) => {
    const address = this.settings.STAKE.ADDRESS;
    const abi = this.stakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods.checkTotalClaimableInterestBy(walletAddress, poolId).call({ gasPrice });
  };

  checkTotalClaimableRewardBy = async (walletAddress: string, poolId: number) => {
    const address = this.settings.NEGATIVE_STAKE.ADDRESS;
    const abi = this.negativeStakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods.checkTotalClaimableRewardBy(walletAddress, poolId).call({ gasPrice });
  };

  checkTotalStaked = async (poolId: number, negative = false, flexible = false) => {
    const address = flexible
      ? this.settings.FLEXIBLE_STAKE.ADDRESS
      : negative
      ? this.settings.NEGATIVE_STAKE.ADDRESS
      : this.settings.STAKE.ADDRESS;
    const abi = flexible ? this.flexibleStakeAbi : negative ? this.negativeStakeAbi : this.stakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods.checkTotalStaked(poolId).call({ gasPrice });
  };

  claimAllInterest = async (
    walletAddress: string,
    poolId: number,
    callback: { onTransactionHash: (hash: string) => void; onConfirmation: () => void }
  ) => {
    const address = this.settings.STAKE.ADDRESS;
    const abi = this.stakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods
      .claimAllInterest(poolId)
      .send({ from: walletAddress, gasPrice })
      .on('transactionHash', (hash: string) => callback.onTransactionHash(hash))
      .on(
        'confirmation',
        (blocks: number) => blocks === DEFAULTS.defaultConfirmationBlocks && callback.onConfirmation()
      );
  };

  claimAllReward = async (
    walletAddress: string,
    poolId: number,
    callback: { onTransactionHash: (hash: string) => void; onConfirmation: () => void }
  ) => {
    const address = this.settings.NEGATIVE_STAKE.ADDRESS;
    const abi = this.negativeStakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods
      .claimAllReward(poolId)
      .send({ from: walletAddress, gasPrice })
      .on('transactionHash', (hash: string) => callback.onTransactionHash(hash))
      .on(
        'confirmation',
        (blocks: number) => blocks === DEFAULTS.defaultConfirmationBlocks && callback.onConfirmation()
      );
  };

  stakeToken = async (
    walletAddress: string,
    poolId: number,
    tokenAmount: string,
    callback: { onTransactionHash: (hash: string) => void; onConfirmation: () => void }
  ) => {
    const address = this.settings.STAKE.ADDRESS;
    const abi = this.stakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods
      .stakeToken(poolId, tokenAmount)
      .send({ from: walletAddress, gasPrice })
      .on('transactionHash', (hash: string) => callback.onTransactionHash(hash))
      .on(
        'confirmation',
        (blocks: number) => blocks === DEFAULTS.defaultConfirmationBlocks && callback.onConfirmation()
      );
  };

  safeStake = async (
    walletAddress: string,
    poolId: number,
    tokenAmount: string,
    forMaxFeePercentage: string,
    flexible = false,
    callback: { onTransactionHash: (hash: string) => void; onConfirmation: () => void }
  ) => {
    const address = flexible ? this.settings.FLEXIBLE_STAKE.ADDRESS : this.settings.NEGATIVE_STAKE.ADDRESS;
    const abi = flexible ? this.flexibleStakeAbi : this.negativeStakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods
      .safeStake(poolId, tokenAmount, forMaxFeePercentage)
      .send({ from: walletAddress, gasPrice })
      .on('transactionHash', (hash: string) => callback.onTransactionHash(hash))
      .on(
        'confirmation',
        (blocks: number) => blocks === DEFAULTS.defaultConfirmationBlocks && callback.onConfirmation()
      );
  };

  stakingToken = async (negative = false, flexible = false) => {
    const address = flexible
      ? this.settings.FLEXIBLE_STAKE.ADDRESS
      : negative
      ? this.settings.NEGATIVE_STAKE.ADDRESS
      : this.settings.STAKE.ADDRESS;
    const abi = flexible ? this.flexibleStakeAbi : negative ? this.negativeStakeAbi : this.stakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods.STAKING_TOKEN().call({ gasPrice });
  };

  rewardToken = async () => {
    const address = this.settings.NEGATIVE_STAKE.ADDRESS;
    const abi = this.negativeStakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods.REWARD_TOKEN().call({ gasPrice });
  };

  withdrawAll = async (
    walletAddress: string,
    poolId: number,
    negative = false,
    flexible = false,
    callback: { onTransactionHash: (hash: string) => void; onConfirmation: () => void }
  ) => {
    const address = flexible
      ? this.settings.FLEXIBLE_STAKE.ADDRESS
      : negative
      ? this.settings.NEGATIVE_STAKE.ADDRESS
      : this.settings.STAKE.ADDRESS;
    const abi = flexible ? this.flexibleStakeAbi : negative ? this.negativeStakeAbi : this.stakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods
      .withdrawAll(poolId)
      .send({ from: walletAddress, gasPrice })
      .on('transactionHash', (hash: string) => callback.onTransactionHash(hash))
      .on(
        'confirmation',
        (blocks: number) => blocks === DEFAULTS.defaultConfirmationBlocks && callback.onConfirmation()
      );
  };

  // periodical stake
  periodicalStakingToken = async () => {
    const address = this.settings.PERIODICAL_STAKE.ADDRESS;
    const abi = this.periodicalStakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    // const gasPrice = await this.getGasPrice();

    // await this.updateNetwork();

    return await contract.methods.STAKING_TOKEN().call();
  };

  periodicalStakingMinimumDeposit = async () => {
    const address = this.settings.PERIODICAL_STAKE.ADDRESS;
    const abi = this.periodicalStakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    // const gasPrice = await this.getGasPrice();

    // await this.updateNetwork();

    return await contract.methods.minimumDeposit().call();
  };

  periodicalSafeStake = async (
    walletAddress: string,
    stakingPhase: string,
    stakingPeriod: string,
    tokenAmount: string,
    expectedAPY: string,
    callback: { onTransactionHash: (hash: string) => void; onConfirmation: () => void }
  ) => {
    const address = this.settings.PERIODICAL_STAKE.ADDRESS;
    const abi = this.periodicalStakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods
      .safeStake(stakingPhase, stakingPeriod, tokenAmount, expectedAPY)
      .send({ from: walletAddress, gasPrice })
      .on('transactionHash', (hash: string) => callback.onTransactionHash(hash))
      .on(
        'confirmation',
        (blocks: number) => blocks === DEFAULTS.defaultConfirmationBlocks && callback.onConfirmation()
      );
  };

  getPeriodicalStakingData = async () => {
    const address = this.settings.PERIODICAL_STAKE.ADDRESS;
    const abi = this.periodicalStakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    // const gasPrice = await this.getGasPrice();

    // await this.updateNetwork();

    return await contract.methods.getProgramData().call();
  };

  periodicalStakingDepositCount = async (walletAddress: string) => {
    const address = this.settings.PERIODICAL_STAKE.ADDRESS;
    const abi = this.periodicalStakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    // const gasPrice = await this.getGasPrice();

    // await this.updateNetwork();

    return await contract.methods.checkDepositCountOfAddress(walletAddress).call();
  };

  getPeriodicalStakingDepositsInRange = async (walletAddress: string, from: number, to: number) => {
    const address = this.settings.PERIODICAL_STAKE.ADDRESS;
    const abi = this.periodicalStakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    // const gasPrice = await this.getGasPrice();

    // await this.updateNetwork();

    return await contract.methods.getDepositsInRangeBy(walletAddress, from, to).call();
  };

  periodicalStakingGetUserData = async (dataType: number, walletAddress: string) => {
    const address = this.settings.PERIODICAL_STAKE.ADDRESS;
    const abi = this.periodicalStakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    // const gasPrice = await this.getGasPrice();

    // await this.updateNetwork();

    return await contract.methods.getUserData(dataType, walletAddress).call();
  };

  periodicalStakingCheckClaimableDataFor = async (walletAddress: string) => {
    const address = this.settings.PERIODICAL_STAKE.ADDRESS;
    const abi = this.periodicalStakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    // const gasPrice = await this.getGasPrice();

    // await this.updateNetwork();

    return await contract.methods.checkClaimableDataFor(walletAddress).call();
  };

  periodicalStakingClaimAll = async (
    walletAddress: string,
    callback: { onTransactionHash: (hash: string) => void; onConfirmation: () => void }
  ) => {
    const address = this.settings.PERIODICAL_STAKE.ADDRESS;
    const abi = this.periodicalStakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods
      .claimAll()
      .send({ from: walletAddress, gasPrice })
      .on('transactionHash', (hash: string) => callback.onTransactionHash(hash))
      .on(
        'confirmation',
        (blocks: number) => blocks === DEFAULTS.defaultConfirmationBlocks && callback.onConfirmation()
      );
  };

  periodicalStakingClaim = async (
    walletAddress: string,
    depositNumber: number,
    callback: { onTransactionHash: (hash: string) => void; onConfirmation: () => void }
  ) => {
    const address = this.settings.PERIODICAL_STAKE.ADDRESS;
    const abi = this.periodicalStakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods
      .claimDeposit(depositNumber)
      .send({ from: walletAddress, gasPrice })
      .on('transactionHash', (hash: string) => callback.onTransactionHash(hash))
      .on(
        'confirmation',
        (blocks: number) => blocks === DEFAULTS.defaultConfirmationBlocks && callback.onConfirmation()
      );
  };

  periodicalStakingWithdraw = async (
    walletAddress: string,
    depositNumber: number,
    callback: { onTransactionHash: (hash: string) => void; onConfirmation: () => void }
  ) => {
    const address = this.settings.PERIODICAL_STAKE.ADDRESS;
    const abi = this.periodicalStakeAbi;
    const contract = new this.web3!.eth.Contract(abi, address);

    const gasPrice = await this.getGasPrice();

    await this.updateNetwork();

    return await contract.methods
      .withdrawDeposit(depositNumber)
      .send({ from: walletAddress, gasPrice })
      .on('transactionHash', (hash: string) => callback.onTransactionHash(hash))
      .on(
        'confirmation',
        (blocks: number) => blocks === DEFAULTS.defaultConfirmationBlocks && callback.onConfirmation()
      );
  };

  private logoutTorus = async (): Promise<void> => {
    await this.torus?.cleanUp();

    this.resetProviders();
  };

  private createDomain = async (): Promise<IDomain> => {
    return {
      chainId: await this.getChainId(),
      name: this.settings.TRADE.NAME,
      version: this.settings.TRADE.VERSION,
      verifyingContract: this.settings.TRADE.ADDRESS,
    };
  };

  private requestAccounts = async (): Promise<void> => {
    await this.ethereum.request({ method: 'eth_requestAccounts' });
  };

  private changeProvider(provider: Web3Providers): void {
    this.currentProvider = provider;
    this.onProviderChange(this.currentProvider);
  }

  private getGasPriceMultiplier = (gasPriceMultiplier: number = this.gasMultiplier) => {
    if (!gasPriceMultiplier || Number.isNaN(gasPriceMultiplier) || Number.isNaN(+gasPriceMultiplier)) {
      return 1;
    }

    return gasPriceMultiplier;
  };

  private async getGasPrice(gasPriceMultiplier: number = this.gasMultiplier): Promise<string> {
    const gasMultiplier = this.getGasPriceMultiplier(gasPriceMultiplier);
    const gasPrice = await this.web3!.eth.getGasPrice();

    return BigNumber.from(gasPrice).mul(gasMultiplier).toString();
  }

  private getChainName = (): string => {
    const isDevelopment = process.env.REACT_APP_DEVELOPMENT_MODE === 'true';

    return isDevelopment ? DEFAULTS.developmentChainId : DEFAULTS.productionChainId;
  };
}

export default Web3Singleton;
