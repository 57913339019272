import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { IVenue } from 'models/i-venue.interface';
import { IState } from 'models/reducers/i-state.interface';

import { ROUTES } from 'constants/routes.constant';

import { SourceScreenName } from 'hooks/events/useFundsEvents';
import { useEnv } from 'hooks/useEnv';
import { useHomePageNavigate } from 'hooks/useHomePageNavigate';

import NftLogo from 'assets/logos/reality-nft.logo.png';
import { ReactComponent as Deals } from 'assets/menu/icons/deals.svg';
import { ReactComponent as Leaders } from 'assets/menu/icons/leaders.svg';
import { ReactComponent as Lootbox } from 'assets/menu/icons/lootbox.svg';
import { ReactComponent as Market } from 'assets/menu/icons/market.svg';
import { ReactComponent as News } from 'assets/menu/icons/news.svg';
import { ReactComponent as Spotlight } from 'assets/menu/icons/spotlight.svg';
import { ReactComponent as World } from 'assets/menu/icons/world.svg';

import BuyRMVControl from '../../buyRMVControl/BuyRMVControl';
import FreeRMVControl from '../../freeRMVControl/FreeRMVControl';
import MenuElement from '../menuElement/MenuElement';
import MenuLandingLink from '../menuLandingLink/MenuLandingLink';
import MenuWalletButton from '../menuWalletButton/MenuWalletButton';
import styles from './RegularHeader.module.scss';

interface IRegularHeader {
  className?: string;
}

const RegularHeader = ({ className }: IRegularHeader) => {
  const { lootboxOpen } = useEnv();
  const location = useLocation();
  const { homePage, mapPageDisabled } = useHomePageNavigate();
  const activeVenue = useSelector((state: IState) => state.venuesActive.activeVenue) as IVenue;
  const searchData = useSelector((state: IState) => state.search.search);

  const createURL = (): string => {
    if (mapPageDisabled) {
      return homePage;
    }

    let url = ROUTES.PORTAL;

    if (activeVenue) {
      url += `/${activeVenue?.type}/${activeVenue?.id}`;
    }

    if (searchData) {
      url += `?s=${searchData}`;
    }

    return url;
  };

  return (
    <div className={`${className || ''} ${styles['menu']}`}>
      <div className={styles['menu__logo']}>
        <Link to="/" aria-label="Home page" className="button--styleless">
          <span className="avatar">
            <img src={NftLogo} alt="" />
          </span>
        </Link>
      </div>
      <nav className={styles['nav']}>
        <ul className={styles['nav-list']}>
          <li>
            <MenuLandingLink />
          </li>
          <li>
            <MenuElement
              active={location.pathname.includes(ROUTES.PORTAL)}
              title="NFT map"
              link={createURL()}
              icon={<World />}
            />
          </li>
          <li>
            <MenuElement
              active={location.pathname === ROUTES.MARKET}
              title="NFT market"
              link={ROUTES.MARKET}
              icon={<Market />}
            />
          </li>
          <li>
            <MenuElement
              active={location.pathname === ROUTES.DEALS}
              title="Deals"
              link={ROUTES.DEALS}
              icon={<Deals />}
            />
          </li>
          <li>
            <MenuElement
              active={location.pathname === ROUTES.SPOTLIGHT}
              title="Spotlight"
              link={ROUTES.SPOTLIGHT}
              icon={<Spotlight />}
            />
          </li>
          {lootboxOpen && (
            <li>
              <MenuElement
                active={location.pathname === ROUTES.LOOTBOX}
                title="Loot boxes"
                link={ROUTES.LOOTBOX}
                icon={<Lootbox />}
              />
            </li>
          )}
          <li>
            <MenuElement
              active={location.pathname.startsWith(ROUTES.LEADERS)}
              title="Leaders"
              link={ROUTES.LEADERS}
              icon={<Leaders />}
            />
          </li>
          <li>
            <MenuElement
              active={location.pathname === ROUTES.PROMOTIONS}
              title="News"
              link={ROUTES.PROMOTIONS}
              icon={<News />}
            />
          </li>
        </ul>
      </nav>
      <FreeRMVControl />
      <BuyRMVControl sourceScreen={SourceScreenName.RegularHeader} />
      <MenuWalletButton />
    </div>
  );
};

export default RegularHeader;
