export const PERIODICAL_STAKE_CONTRACT_ABI: any[] = [
  {
    type: 'constructor',
    inputs: [
      {
        name: 'tokenAddress',
        type: 'address',
        internalType: 'address',
      },
    ],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'STAKING_TOKEN',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'contract IERC20Metadata',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'addContractAdmin',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'addStakingPeriod',
    inputs: [
      {
        name: 'newStakingPeriod',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'apyForEachStakingPhase',
        type: 'uint256[]',
        internalType: 'uint256[]',
      },
      {
        name: 'targetForEachStakingPhase',
        type: 'uint256[]',
        internalType: 'uint256[]',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'calculateReward',
    inputs: [
      {
        name: 'depositAmount',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'depositAPY',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'stakingPeriod',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'pure',
  },
  {
    type: 'function',
    name: 'changeActionAvailability',
    inputs: [
      {
        name: 'action',
        type: 'uint8',
        internalType: 'enum ProgramManager.DataType',
      },
      {
        name: 'changeTo',
        type: 'bool',
        internalType: 'bool',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'changeStakingPhase',
    inputs: [
      {
        name: 'phaseToSwitch',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'checkActionAvailability',
    inputs: [
      {
        name: 'action',
        type: 'uint8',
        internalType: 'enum ProgramManager.DataType',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'bool',
        internalType: 'bool',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkClaimableDataFor',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkDepositCountOfAddress',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkDepositStatus',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'depositNumber',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint8',
        internalType: 'enum ProgramManager.DepositStatus',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkIfStakingPeriodExists',
    inputs: [
      {
        name: 'stakingPeriod',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'bool',
        internalType: 'bool',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkIfStakingPhaseExists',
    inputs: [
      {
        name: 'stakingPhase',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'bool',
        internalType: 'bool',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkTotalClaimableData',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'claimAll',
    inputs: [],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'claimDeposit',
    inputs: [
      {
        name: 'depositNumber',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'collectReward',
    inputs: [
      {
        name: 'tokenAmount',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'contractAdmins',
    inputs: [
      {
        name: '',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'bool',
        internalType: 'bool',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'contractOwner',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'address',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'currentStakingPhase',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getAllPhasePeriodData',
    inputs: [
      {
        name: 'dataType',
        type: 'uint8',
        internalType: 'enum ProgramManager.PhasePeriodDataType',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256[][]',
        internalType: 'uint256[][]',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getDeposit',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'depositNumber',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'tuple',
        internalType: 'struct ProgramManager.TokenDeposit',
        components: [
          {
            name: 'stakingPhase',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'stakingPeriod',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'stakingStartDate',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'stakingEndDate',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'withdrawalDate',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'amount',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'APY',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'rewardGenerated',
            type: 'uint256',
            internalType: 'uint256',
          },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getDepositsInRangeBy',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'fromIndex',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'toIndex',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'tuple[]',
        internalType: 'struct ProgramManager.TokenDeposit[]',
        components: [
          {
            name: 'stakingPhase',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'stakingPeriod',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'stakingStartDate',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'stakingEndDate',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'withdrawalDate',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'amount',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'APY',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'rewardGenerated',
            type: 'uint256',
            internalType: 'uint256',
          },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getPhasePeriodData',
    inputs: [
      {
        name: 'dataType',
        type: 'uint8',
        internalType: 'enum ProgramManager.PhasePeriodDataType',
      },
      {
        name: 'phase',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'period',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getProgramData',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: '',
        type: 'uint256[]',
        internalType: 'uint256[]',
      },
      {
        name: '',
        type: 'uint256[][]',
        internalType: 'uint256[][]',
      },
      {
        name: '',
        type: 'uint256[][]',
        internalType: 'uint256[][]',
      },
      {
        name: '',
        type: 'uint256[][]',
        internalType: 'uint256[][]',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getStakingPeriods',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint256[]',
        internalType: 'uint256[]',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getTotalData',
    inputs: [
      {
        name: 'dataType',
        type: 'uint8',
        internalType: 'enum ProgramManager.DataType',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getUserData',
    inputs: [
      {
        name: 'dataType',
        type: 'uint8',
        internalType: 'enum ProgramManager.DataType',
      },
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'minimumDeposit',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'phasePeriodDataList',
    inputs: [
      {
        name: '',
        type: 'uint8',
        internalType: 'enum ProgramManager.PhasePeriodDataType',
      },
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'popStakingPhase',
    inputs: [],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'provideReward',
    inputs: [
      {
        name: 'tokenAmount',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'pushStakingPhase',
    inputs: [
      {
        name: 'apyForEachStakingPeriod',
        type: 'uint256[]',
        internalType: 'uint256[]',
      },
      {
        name: 'targetForEachStakingPeriod',
        type: 'uint256[]',
        internalType: 'uint256[]',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'removeContractAdmin',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'removeStakingPeriod',
    inputs: [
      {
        name: 'stakingPeriod',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'rewardPool',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'safeStake',
    inputs: [
      {
        name: 'stakingPhase',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'stakingPeriod',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'tokenAmount',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'expectedAPY',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setMiniumumDeposit',
    inputs: [
      {
        name: 'newMinimumDeposit',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setPhasePeriodData',
    inputs: [
      {
        name: 'dataType',
        type: 'uint8',
        internalType: 'enum ProgramManager.PhasePeriodDataType',
      },
      {
        name: 'stakingPhase',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'stakingPeriod',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'newValue',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'stakerActiveDepositStartIndex',
    inputs: [
      {
        name: '',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'stakingPeriodList',
    inputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'stakingPhaseCount',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'totalDataList',
    inputs: [
      {
        name: '',
        type: 'uint8',
        internalType: 'enum ProgramManager.DataType',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'transferOwnership',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'userDataList',
    inputs: [
      {
        name: '',
        type: 'uint8',
        internalType: 'enum ProgramManager.DataType',
      },
      {
        name: '',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'withdrawDeposit',
    inputs: [
      {
        name: 'depositNumber',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'event',
    name: 'AddContractAdmin',
    inputs: [
      {
        name: 'user',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'AddStakingPeriod',
    inputs: [
      {
        name: 'newStakingPeriod',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'AddStakingPhase',
    inputs: [
      {
        name: 'newStakingPhase',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'ChangeStakingPhase',
    inputs: [
      {
        name: 'to',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'Claim',
    inputs: [
      {
        name: 'by',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'depositNumber',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
      {
        name: 'reward',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'CollectReward',
    inputs: [
      {
        name: 'by',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'tokenAmount',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'ProvideReward',
    inputs: [
      {
        name: 'by',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'tokenAmount',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'RemoveContractAdmin',
    inputs: [
      {
        name: 'user',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'RemoveStakingPeriod',
    inputs: [
      {
        name: 'stakingPeriod',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'RemoveStakingPhase',
    inputs: [
      {
        name: 'stakingPhase',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'Stake',
    inputs: [
      {
        name: 'by',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'stakingPhase',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
      {
        name: 'stakingPeriod',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
      {
        name: 'APY',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'tokenAmount',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'depositNumber',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'TransferOwnership',
    inputs: [
      {
        name: 'from',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'to',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'UpdateActionAvailability',
    inputs: [
      {
        name: 'action',
        type: 'uint8',
        indexed: false,
        internalType: 'enum ProgramManager.DataType',
      },
      {
        name: 'isOpen',
        type: 'bool',
        indexed: false,
        internalType: 'bool',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'UpdateMinimumDeposit',
    inputs: [
      {
        name: 'newMinimumDeposit',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'UpdatePhasePeriodData',
    inputs: [
      {
        name: 'dataType',
        type: 'uint8',
        indexed: true,
        internalType: 'enum ProgramManager.PhasePeriodDataType',
      },
      {
        name: 'stakingPhase',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
      {
        name: 'stakingPeriod',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
      {
        name: 'newValue',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'Withdraw',
    inputs: [
      {
        name: 'by',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'depositNumber',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
      {
        name: 'stakedAmount',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'error',
    name: 'AddressEmptyCode',
    inputs: [
      {
        name: 'target',
        type: 'address',
        internalType: 'address',
      },
    ],
  },
  {
    type: 'error',
    name: 'AddressInsufficientBalance',
    inputs: [
      {
        name: 'account',
        type: 'address',
        internalType: 'address',
      },
    ],
  },
  {
    type: 'error',
    name: 'AmountExceedsTarget',
    inputs: [
      {
        name: 'stakingPhase',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'stakingPeriod',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'stakingTarget',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'ArrayLengthDoesntMatch',
    inputs: [
      {
        name: 'expectedLength',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'DepositDoesNotExist',
    inputs: [
      {
        name: 'depositNumber',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'FailedInnerCall',
    inputs: [],
  },
  {
    type: 'error',
    name: 'IncorrectStakingPhase',
    inputs: [
      {
        name: 'expectedPhase',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'currentPhase',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'InsufficentDeposit',
    inputs: [
      {
        name: '_tokenSent',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: '_requiredAmount',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'InvalidAPY',
    inputs: [
      {
        name: 'providedValue',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'minValue',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'InvalidDataType',
    inputs: [],
  },
  {
    type: 'error',
    name: 'InvalidMinimumDeposit',
    inputs: [
      {
        name: 'providedValue',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'minValue',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'NoRewardToClaim',
    inputs: [
      {
        name: 'depositNumber',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'NoStakingPhasesAddedYet',
    inputs: [],
  },
  {
    type: 'error',
    name: 'NotClaimable',
    inputs: [
      {
        name: 'depositNumber',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'NotEnoughFundsInRewardPool',
    inputs: [
      {
        name: 'requestedAmount',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'availableAmount',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'NotOpen',
    inputs: [
      {
        name: 'action',
        type: 'uint8',
        internalType: 'enum ProgramManager.DataType',
      },
    ],
  },
  {
    type: 'error',
    name: 'NotWithdrawable',
    inputs: [
      {
        name: 'depositNumber',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'PhasePeriodAPYChanged',
    inputs: [
      {
        name: 'stakingPhase',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'stakingPeriod',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'currentAPY',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'ReentrancyGuardReentrantCall',
    inputs: [],
  },
  {
    type: 'error',
    name: 'SafeERC20FailedOperation',
    inputs: [
      {
        name: 'token',
        type: 'address',
        internalType: 'address',
      },
    ],
  },
  {
    type: 'error',
    name: 'StakingPeriodDoesNotExist',
    inputs: [
      {
        name: 'stakingPeriod',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'StakingPeriodExists',
    inputs: [
      {
        name: 'stakingPeriod',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'StakingPhaseDoesNotExist',
    inputs: [
      {
        name: 'stakingPhase',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'UnauthorizedAccess',
    inputs: [
      {
        name: 'requiredAccessTier',
        type: 'uint8',
        internalType: 'enum AccessControl.AccessTier',
      },
    ],
  },
  {
    type: 'error',
    name: 'ZeroAddressProvided',
    inputs: [],
  },
];
