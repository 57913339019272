import {
  AssetDetailsField,
  AssetField,
  AssetTradeField,
  LandingField,
  MarketTradeField,
  ProfileField,
  ProfileTradeField,
  ProfileTradeOfferField,
} from 'enums/params-fields';

export const ASSET_FIELDS = Object.values(AssetField);

export const ASSET_DETAILS_FIELDS = Object.values(AssetDetailsField);

export const ASSET_TRADE_FIELDS = Object.values(AssetTradeField);

export const LANDING_FIELDS = Object.values(LandingField);

export const MARKET_TRADE_FIELDS = Object.values(MarketTradeField);

export const PROFILE_FIELDS = Object.values(ProfileField);

export const PROFILE_TRADE_FIELDS = Object.values(ProfileTradeField);

export const PROFILE_TRADE_OFFERS_FIELDS = Object.values(ProfileTradeOfferField);
