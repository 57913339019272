import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router';

import { CookiesKeys } from '../enums/cookies-keys.enum';

import { setShowSelectWalletModal } from '../actions/global-actions';

import { useCookies } from '../hooks/useCookies';
import { useHomePageNavigate } from 'hooks/useHomePageNavigate';

interface IIsLoggedIn {
  children: JSX.Element;
}

const IsLoggedIn = ({ children }: IIsLoggedIn) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { getCookie } = useCookies();
  const isLogged = getCookie(CookiesKeys.walletAddress);
  const { homePage } = useHomePageNavigate();

  useEffect(() => {
    if (!isLogged) {
      dispatch(setShowSelectWalletModal(true));
    }
  }, [dispatch, isLogged]);

  if (isLogged) {
    return children;
  }
  return <Navigate to={homePage} state={{ from: location }} replace />;
};

export default IsLoggedIn;
