import { IPropsWithChildrenAndClassName } from 'types/common.interface';

import styles from './LootboxSection.module.scss';

const LootboxSection = ({ children, className = '' }: IPropsWithChildrenAndClassName) => {
  const classNames = [className, styles['lootbox-section']];

  return <section className={classNames.join(' ')}>{children}</section>;
};

export default LootboxSection;
