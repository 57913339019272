import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { ScrollAnimationType } from 'enums/scroll-animation-type.enum';

import { DEFAULTS } from 'constants/defaults.constant';
import { ROUTES } from 'constants/routes.constant';

import { ScrollContext } from 'contexts/ScrollContext';

import Button from 'components/buttons/Button';
import ScrollInAnimationItem from 'components/scrollInAnimationItem/ScrollInAnimationItem';

import { useMarketEvents } from 'hooks/events/useMarketEvents';
import { useLandingScrollSectionState } from 'hooks/landing/useLandingScrollSectionState';
import { useEnv } from 'hooks/useEnv';

import RealityGamesPhone1 from 'assets/landing/main/game-1.png';
import RealityGamesPhone2 from 'assets/landing/main/game-2.png';
import RealityGamesPhone3 from 'assets/landing/main/game-3.png';
import RealityGamesPhone4 from 'assets/landing/main/game-4.png';
import RealityGamesPhone5 from 'assets/landing/main/game-5.png';
import RealityGamesBokeh from 'assets/landing/main/reality-games-bokeh.jpg';
import RealityNftBackground from 'assets/landing/main/reality-nft-background.jpg';
import RealityNftCards from 'assets/landing/main/reality-nft-cards.png';
import RMVTokenBackground from 'assets/landing/main/rmv-token-background.jpg';
import RMVTokenTokensBackground from 'assets/landing/main/rmv-token-tokens-background.png';
import RMVTokenTokens from 'assets/landing/main/rmv-token-tokens.png';
import RealityMetaverse from 'assets/logos/reality-metaverse-logo.svg';

import LandingInfoCard from '../landingInfoCard/LandingInfoCard';
import styles from './LandingMainSection.module.scss';

const LandingMainSection = () => {
  const navigate = useNavigate();
  const { sectionRefs } = useContext(ScrollContext);
  const { kucoinExchangeCurrency } = useEnv();
  useLandingScrollSectionState('rmv');

  const { sendMarketDisplayAuction } = useMarketEvents();

  const openCEX = () => {
    window.open(DEFAULTS.CEXLink(kucoinExchangeCurrency), '_blank');
  };

  const handleClickCollect = () => {
    sendMarketDisplayAuction();
    navigate(ROUTES.MARKET);
  };

  return (
    <section ref={sectionRefs.rmv}>
      <div className={['landing-page-container', styles['section']].join(' ')}>
        <ScrollInAnimationItem delay="0.1s">
          <img className={styles['logo']} src={RealityMetaverse} alt="reality metaverse" />
        </ScrollInAnimationItem>
        <ScrollInAnimationItem delay="0.3s">
          <p className={`landing-main-text ${styles['main-text']}`}>
            Reality Metaverse has created a true connection between real life and the Metaverse. Unlock the full
            potential of your digital assets in popular games, such as Landlord GO played by millions. Breaking new
            ground, Reality Metaverse empowers NFT owners to monetize their assets in traditional games for the first
            time!
          </p>
        </ScrollInAnimationItem>
        <div className={styles['content']}>
          <ScrollInAnimationItem className={styles['rmv-token-card']} delay="0.5s" threshold={0.1}>
            <LandingInfoCard
              className={styles['card']}
              contentClassName={styles['rmv-token-card__content']}
              title={
                <div>
                  <b>RMV</b> token
                </div>
              }
              content={
                <div className="landing-main-text">
                  The RMV Token serves as the heart of our ecosystem, unifying all its elements - from games and 3D
                  models to NFT royalties, governance, and DAO.
                </div>
              }
              footer={
                <Button size="medium" color="primary" className={styles['card__button']} onClick={openCEX}>
                  Trade
                </Button>
              }
              graphics={
                <>
                  <img className={styles['card__background']} src={RMVTokenBackground} alt="" />
                  <img className={styles['rmv-token-card__tokens']} src={RMVTokenTokens} alt="" />
                  <img className={styles['rmv-token-card__tokens-background']} src={RMVTokenTokensBackground} alt="" />
                </>
              }
            />
          </ScrollInAnimationItem>
          <ScrollInAnimationItem className={styles['reality-nft-card']} delay="0.5s" threshold={0.1}>
            <LandingInfoCard
              className={styles['card']}
              contentClassName={styles['reality-nft-card__content']}
              title={
                <div>
                  Reality <b>NFT</b>
                </div>
              }
              content={
                <div className="landing-main-text">
                  A collection of over 2000 famous locations minted as NFTs, each divided into fractions. These
                  fractions, known as fNFTs, generate royalties for their respective owners from various sources, such
                  as mobile games revenues and trading fees.
                </div>
              }
              footer={
                <Button size="medium" color="primary" className={styles['card__button']} onClick={handleClickCollect}>
                  Collect
                </Button>
              }
              graphics={
                <>
                  <img className={styles['card__background']} src={RealityNftBackground} alt="" />
                  <img className={styles['reality-nft-card__nft-cards']} src={RealityNftCards} alt="" />
                </>
              }
            />
          </ScrollInAnimationItem>
          <ScrollInAnimationItem className={styles['reality-games-card']} delay="0.5s" threshold={0.1}>
            <LandingInfoCard
              className={styles['card']}
              contentClassName={styles['reality-games-card__content']}
              graphicsClassName={styles['reality-games-card__graphics']}
              title={
                <div>
                  Reality <b>Games</b>
                </div>
              }
              content={
                <>
                  <div className="landing-main-text">
                    Landlord GO, the first game in our ecosystem, boasts a staggering 6 million players, and we have
                    plans to launch more games through community staking!
                  </div>
                  <div className={styles['reality-games-card__games']}>
                    <ScrollInAnimationItem
                      animationType={ScrollAnimationType.slideInTop}
                      className={styles['reality-games-card__game']}
                      delay="0.4s"
                      threshold={0.3}
                    >
                      <img src={RealityGamesPhone1} alt="" />
                    </ScrollInAnimationItem>
                    <ScrollInAnimationItem
                      animationType={ScrollAnimationType.slideInTop}
                      className={styles['reality-games-card__game']}
                      delay="0.5s"
                      threshold={0.3}
                    >
                      <img src={RealityGamesPhone2} alt="" />
                    </ScrollInAnimationItem>
                    <ScrollInAnimationItem
                      animationType={ScrollAnimationType.slideInTop}
                      className={styles['reality-games-card__game']}
                      delay="0.6s"
                      threshold={0.3}
                    >
                      <img src={RealityGamesPhone3} alt="" />
                    </ScrollInAnimationItem>
                    <ScrollInAnimationItem
                      animationType={ScrollAnimationType.slideInTop}
                      className={styles['reality-games-card__game']}
                      delay="0.7s"
                      threshold={0.3}
                    >
                      <img src={RealityGamesPhone4} alt="" />
                    </ScrollInAnimationItem>
                    <ScrollInAnimationItem
                      animationType={ScrollAnimationType.slideInTop}
                      className={styles['reality-games-card__game']}
                      delay="0.8s"
                      threshold={0.3}
                    >
                      <img src={RealityGamesPhone5} alt="" />
                    </ScrollInAnimationItem>
                  </div>
                </>
              }
              footer={
                <Button
                  size="medium"
                  color="primary"
                  className={styles['card__button']}
                  onClick={() => navigate(ROUTES.GAMES)}
                >
                  Play
                </Button>
              }
              graphics={
                <>
                  <img className={styles['card__background']} src={RealityGamesBokeh} alt="" />
                  <div className={styles['reality-games-card__games']}>
                    <ScrollInAnimationItem
                      animationType={ScrollAnimationType.slideInTop}
                      className={styles['reality-games-card__game']}
                      delay="0.4s"
                      threshold={0.3}
                    >
                      <img src={RealityGamesPhone1} alt="" />
                    </ScrollInAnimationItem>
                    <ScrollInAnimationItem
                      animationType={ScrollAnimationType.slideInTop}
                      className={styles['reality-games-card__game']}
                      delay="0.5s"
                      threshold={0.3}
                    >
                      <img src={RealityGamesPhone2} alt="" />
                    </ScrollInAnimationItem>
                    <ScrollInAnimationItem
                      animationType={ScrollAnimationType.slideInTop}
                      className={styles['reality-games-card__game']}
                      delay="0.6s"
                      threshold={0.3}
                    >
                      <img src={RealityGamesPhone3} alt="" />
                    </ScrollInAnimationItem>
                    <ScrollInAnimationItem
                      animationType={ScrollAnimationType.slideInTop}
                      className={styles['reality-games-card__game']}
                      delay="0.7s"
                      threshold={0.3}
                    >
                      <img src={RealityGamesPhone4} alt="" />
                    </ScrollInAnimationItem>
                    <ScrollInAnimationItem
                      animationType={ScrollAnimationType.slideInTop}
                      className={styles['reality-games-card__game']}
                      delay="0.8s"
                      threshold={0.3}
                    >
                      <img src={RealityGamesPhone5} alt="" />
                    </ScrollInAnimationItem>
                  </div>
                </>
              }
            />
          </ScrollInAnimationItem>
        </div>
      </div>
    </section>
  );
};

export default LandingMainSection;
