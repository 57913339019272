import { NavLink } from 'react-router-dom';

import styles from './MenuAccountDropdownProfileLink.module.scss';

interface IMenuAccountDropdownProfileLink {
  to: string;
  children: JSX.Element;
  disabled?: boolean;
}

const MenuAccountDropdownProfileLink = ({ to, disabled = false, children }: IMenuAccountDropdownProfileLink) => {
  return (
    <NavLink
      className={({ isActive }) => (isActive ? styles['navigation-active'] : '')}
      to={to}
      onClick={(e) => {
        if (!disabled) {
          return;
        }

        e.preventDefault();
      }}
    >
      <div
        className={`${styles['profile-navigation-link']} ${
          disabled ? styles['profile-navigation-link--disabled'] : ''
        }`}
      >
        <div className={styles['profile-navigation-link__tile']}>{children}</div>
      </div>
    </NavLink>
  );
};

export default MenuAccountDropdownProfileLink;
