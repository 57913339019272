import { sendGA4Event } from 'services/analytics-events.service';

import { useEventParamsMethods } from './useEventsParams';

enum LandLordGoEventName {
  Clicked = 'landlord_go_link_clicked',
}

export const useLandlordGoEvents = () => {
  const { withWalletAddress } = useEventParamsMethods();

  const sendLandLordGoClickedEvent = () => {
    sendGA4Event(LandLordGoEventName.Clicked, withWalletAddress());
  };

  return {
    sendLandLordGoClickedEvent,
  };
};
