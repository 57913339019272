import { useState } from 'react';
import { useSelector } from 'react-redux';

import { IState } from 'models/reducers/i-state.interface';

import { GAMES, GameStatusTag, IGame } from 'routes/games/constants/games.constants';

import GameModal from 'routes/games/gameModal/GameModal';
import { gameHasPoolId } from 'routes/games/utils';

import { ButtonBadge, ComingSoonBadge } from '../../../badges/Badges';
import NftCardBackCollapse from '../../../nftCardBackCollapse/NftCardBackCollapse';
import NftCardBackSlide from '../../../nftCardBackSlide/NftCardBackSlide';
import { useNftCardContext } from '../../../nftCardContext/NftCardContext';
import NftCardLabelPanel from '../../../nftCardLabelPanel/NftCardLabelPanel';
import NftCardValue from '../../../nftCardValue/NftCardValue';

const NftCardBackStatisticsSlide = () => {
  const { asset } = useNftCardContext();
  const profile = useSelector(({ profile }: IState) => profile.profile);

  const [game, setGame] = useState<IGame | undefined>();

  const isGameLive = (game: IGame) => game.status === GameStatusTag.Live;

  const panels = [
    {
      label: 'Royalties',
      children: (
        <>
          <NftCardLabelPanel label="Last 24h" primary>
            <NftCardValue {...asset?.royalties?.last24hours} />
          </NftCardLabelPanel>
          <NftCardLabelPanel label="Last 7d" primary>
            <NftCardValue {...asset?.royalties?.last7days} />
          </NftCardLabelPanel>
          <NftCardLabelPanel label="Total" primary>
            <NftCardValue {...asset?.royalties?.total} />
          </NftCardLabelPanel>
          {!!profile && (
            <NftCardLabelPanel label="Your Earnings" primary>
              <NftCardValue {...asset?.ownedEarnings} />
            </NftCardLabelPanel>
          )}
          <NftCardLabelPanel label="Estimated Annual Yield" primary>
            <span className="tw-leading-4 tw-text-white">{asset?.expectedAnnualYield ?? '-'}</span>
          </NftCardLabelPanel>
        </>
      ),
    },
    {
      label: 'Sales volume',
      children: (
        <>
          <NftCardLabelPanel label="Last 7d">
            <NftCardValue {...asset?.salesVolume?.last7days} />
          </NftCardLabelPanel>
          <NftCardLabelPanel label="Last 30d">
            <NftCardValue {...asset?.salesVolume?.last30days} />
          </NftCardLabelPanel>
        </>
      ),
    },
    {
      label: 'Game integrations',
      children: GAMES.filter((game) => isGameLive(game) || gameHasPoolId(game)).map((game) => (
        <NftCardLabelPanel key={game.title} label={game.title} className={isGameLive(game) ? 'tw-text-white' : ''}>
          <ButtonBadge
            className={isGameLive(game) ? 'tw-bg-mildRed' : 'tw-bg-greenGradient'}
            onClick={() => setGame(game)}
          >
            {isGameLive(game) ? game.status : 'Staking'}
          </ButtonBadge>
        </NftCardLabelPanel>
      )),
    },
    {
      label: 'Other',
      children: (
        <>
          <NftCardLabelPanel label="Metaverse Renting">
            <ComingSoonBadge />
          </NftCardLabelPanel>
          <NftCardLabelPanel label="Gaming Renting">
            <ComingSoonBadge />
          </NftCardLabelPanel>
          <NftCardLabelPanel label="Film Industry Renting">
            <ComingSoonBadge />
          </NftCardLabelPanel>
        </>
      ),
    },
  ];

  return (
    <>
      <NftCardBackSlide label="Statistics">
        {panels.map(({ label, children }) => (
          <NftCardBackCollapse key={label} label={label}>
            {children}
          </NftCardBackCollapse>
        ))}
      </NftCardBackSlide>
      <GameModal game={game} onClose={() => setGame(undefined)} />
    </>
  );
};

export default NftCardBackStatisticsSlide;
