import { useEffect, useId, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { IProfile } from 'models/profile/i-profile.interface';
import { IState } from 'models/reducers/i-state.interface';

import { ROUTES } from 'constants/routes.constant';

import { centralEllipsis } from 'utils/ellipsis.utils';

import ProfileAvatarDefault from 'assets/profile/icons/profile-avatar-default.svg';

import Icon from '../../icon/Icon';
import MenuAccountDropdown from '../menuAccountDropdown/MenuAccountDropdown';
import styles from './MenuAccountControl.module.scss';

const MenuAccountControl = () => {
  const id = useId();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showNewNfts, setShowNewNfts] = useState(false);
  const profileData = useSelector(({ profile }: IState): IProfile | null => profile.profile);
  const { newNfts, address: walletAddress } = useSelector((state: IState) => state.wallet);
  const location = useLocation();
  const classNames = [showNewNfts ? styles['account-control--new-nfts'] : '', styles['account-control']];

  useEffect(() => {
    setDropdownVisible(false);
  }, [location]);

  useEffect(() => {
    setShowNewNfts(true);

    const timer = setTimeout(() => {
      setShowNewNfts(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [newNfts]);

  useEffect(() => {
    document.addEventListener('keydown', onEscape, false);

    return () => {
      document.removeEventListener('keydown', onEscape, false);
    };
  }, []);

  const onEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setDropdownVisible(false);
    }
  };

  return profileData && walletAddress ? (
    <div className={classNames.join(' ')}>
      {showNewNfts && newNfts ? (
        <div className={styles['account-control__new-nfts']}>
          <span>+{newNfts}</span>
        </div>
      ) : (
        <></>
      )}
      <Link to={`${ROUTES.PROFILE}/collected`} className={styles['profile-link']}>
        <div className={styles['avatar']}>
          <img alt="profile" src={profileData.avatar || ProfileAvatarDefault} />
        </div>
        <div className={styles['user-info']}>
          <p className={styles['user-name']}>{profileData.first_name || 'Unknown'}</p>
          <p className={styles['user-address']} title={profileData.address}>
            {centralEllipsis(profileData.address, 9)}
          </p>
        </div>
      </Link>
      <button
        aria-haspopup="true"
        data-toggle="dropdown"
        aria-label="Profile menu"
        id={`account-control-dropdown-${id}`}
        className={`button--styleless ${styles['collapse-button']}`}
        aria-expanded={dropdownVisible}
        onClick={() => setDropdownVisible(!dropdownVisible)}
      >
        <Icon
          id="collapse-icon"
          className={`${styles['collapse-icon']} ${dropdownVisible ? styles['collapse-icon--visible'] : ''}`}
        />
      </button>
      {dropdownVisible && (
        <div className={styles['account-control__backdrop']} onClick={() => setDropdownVisible(false)}></div>
      )}
      <div
        className={`${styles['account-control__dropdown']} ${
          dropdownVisible ? styles['account-control__dropdown--visible'] : ''
        }`}
      >
        <MenuAccountDropdown aria-labelledby={`account-control-dropdown-${id}`} show={dropdownVisible} />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default MenuAccountControl;
