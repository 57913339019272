import { ethers } from 'ethers';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { IState } from '../../models/reducers/i-state.interface';

import { WEB3 } from '../../constants/web3.constant';

export interface INftParams {
  nftId: string;
}

export interface IRNftParams extends INftParams {
  fnftsAmount: number;
}

export interface ISourceScreen {
  sourceScreen: string;
}

export interface IEvents {
  sendBuyEvent: (params: IRNftParams) => void;
  sendBuySuccessEvent: (params: IRNftParams) => void;
  sendBuyErrorEvent: (params: IRNftParams & IErrorMessageParam) => void;
}

export interface ILoadingTimeParam {
  time: number;
}

export interface IErrorMessageParam {
  message: string;
}

export type IEventParams<T> = Partial<ILoadingTimeParam> & Partial<T>;

export const extractReferrerWallet = (search: string) => {
  const searchParams = new URLSearchParams(search);
  const referrerWallet = searchParams.get('referrerWallet');

  if (referrerWallet) {
    const isAddress = ethers.utils.isAddress(referrerWallet);

    return isAddress ? { referrerWallet } : {};
  } else return {};
};

export const useEventParamsMethods = () => {
  const walletAddress = useSelector((state: IState): string => state.wallet.address);

  const withWalletAddress = <T>(params?: IEventParams<T>) => ({
    walletAddress: !!walletAddress?.length ? walletAddress : WEB3.nullAddress,
    ...params,
  });

  const { search } = useLocation();
  const referrerWallet = extractReferrerWallet(search);

  const withWalletAddressAndReferrer = <T>(params?: IEventParams<T>) =>
    withWalletAddress({ ...referrerWallet, ...params });

  return { withWalletAddress, withWalletAddressAndReferrer };
};
