import { ReactComponent as AppleStore } from 'assets/social-media/apple-store-with-border.svg';
import { ReactComponent as Discord } from 'assets/social-media/discord_with_border.svg';
import { ReactComponent as GooglePlay } from 'assets/social-media/google-play-with-border.svg';
import { ReactComponent as Telegram } from 'assets/social-media/telegram-with-border.svg';
import { ReactComponent as Twitter } from 'assets/social-media/twitter-with-border.svg';

export enum GameLinkType {
  Discord = 'discord',
  AppleStore = 'apple-store',
  GooglePlay = 'google-play',
  Telegram = 'telegram',
  Twitter = 'twitter',
}

export interface IGameLinkProps {
  type: GameLinkType;
  href: string;
  className?: string;
}

const GameLink = ({ href, type, className }: IGameLinkProps) => {
  const renderLinkIcon = (type: GameLinkType) => {
    const adapt = {
      [GameLinkType.Discord]: <Discord className={className} />,
      [GameLinkType.AppleStore]: <AppleStore className={className} />,
      [GameLinkType.GooglePlay]: <GooglePlay className={className} />,
      [GameLinkType.Telegram]: <Telegram className={className} />,
      [GameLinkType.Twitter]: <Twitter className={className} />,
    };

    return adapt[type] ?? null;
  };

  return (
    <a href={href} aria-label={type} className="tw-flex tw-justify-center" target="_blank" rel="noreferrer">
      {renderLinkIcon(type)}
    </a>
  );
};

export default GameLink;
