import { useEffect, useId } from 'react';
import ReactDOM from 'react-dom';

import Backdrop from '../backdrop/Backdrop';
import Icon from '../icon/Icon';
import './ModalPortal.scss';

interface IModalPortal {
  children: JSX.Element;
  onClose?: () => void;
  className?: string;
  closeButtonOutside?: boolean;
  customStyles?: { [key: string]: string };
  modalContentClassName?: string;
  modalTitleClassName?: string;
  closeButtonClassName?: string;
  title?: string;
  onBack?: () => void;
}

const ModalPortal = ({
  title = '',
  className,
  modalContentClassName,
  modalTitleClassName = '',
  closeButtonClassName = '',
  onClose,
  onBack,
  children,
  customStyles = {},
  closeButtonOutside = false,
}: IModalPortal) => {
  const dialogId = useId();
  const closeButtonClasses = [
    closeButtonClassName,
    closeButtonOutside ? 'modal__title-close-button--outside' : '',
    'modal__title-close-button',
    'expand-hoover',
    'button--styleless',
  ];
  const modalTitleClassNames = [modalTitleClassName, 'modal__title'];

  useEffect(() => {
    document.addEventListener('keydown', onEscape, false);

    return () => {
      document.removeEventListener('keydown', onEscape, false);
    };
  }, []);

  const onEscape = (event: KeyboardEvent) => {
    if (onClose && event.key === 'Escape') {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div className={`modal ${className ? className : ''}`} role="dialog" aria-labelledby={`dialog-title-${dialogId}`}>
      <div className="modal__backdrop" onClick={onClose}>
        <Backdrop show={true} />
      </div>
      <div className={`modal__content ${modalContentClassName || ''}`} style={customStyles}>
        <div className={modalTitleClassNames.join(' ')}>
          {onBack && (
            <button className="modal__title-back-button expand-hoover button--styleless" onClick={onBack}>
              <Icon className="modal__title-icon" id="back-icon" altText="Back to previous view" />
            </button>
          )}
          {title && <h2 id={`dialog-title-${dialogId}`}>{title}</h2>}
          {onClose && (
            <button className={closeButtonClasses.join(' ')} onClick={onClose}>
              <Icon className="modal__title-icon" id="close-icon" altText="Close modal" />
            </button>
          )}
        </div>
        {children}
      </div>
    </div>,
    document.querySelector('.modal-container') as Element
  );
};
export default ModalPortal;
