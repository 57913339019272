import { ReactNode } from 'react';
import styles from './PageHeader.module.scss';

interface IPageHeaderProps {
  children: ReactNode;
  className?: string;
}

const PageHeader = ({ children, className = '' }: IPageHeaderProps) => {
  return <h1 className={[styles['page-header'], className].join(' ')}>{children}</h1>;
};

export default PageHeader;
