import { useEffect, useRef } from 'react';
import { positionValues, Scrollbars } from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IVenue } from '../../models/i-venue.interface';
import { IState } from '../../models/reducers/i-state.interface';

import { limit, skip } from '../../constants/VenuesRequest';
import { ROUTES } from 'constants/routes.constant';

import { appendVenuesAction, selectVenueAction } from '../../actions/venues.action';

import ElementLoader from '../element-loader/ElementLoader';
import SearchResultElement from './SearchResultElement';
import './SearchResults.scss';

const SearchResults = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const venues = useSelector((state: IState) => state.venues.allVenues);
  const loading = useSelector((state: IState) => state.venues.loading);
  const stateVenues = useSelector((state: IState) => state.venues);
  const searchData = useSelector((state: IState) => state.search);
  let isRequestpending = useRef(false);

  const NEXT_VENUES_PAGE_SCROLL_TRESHOLD = 300; //distance in pixels from bottom of list to send request for next page of venues

  const selectVenue = (data: any) => {
    if (searchData.search) {
      navigate(`${ROUTES.PORTAL}/${data.type}/${data.id}?s=${searchData.search}`);
    } else {
      navigate(`${ROUTES.PORTAL}/${data.type}/${data.id}`);
    }

    dispatch(selectVenueAction(data));
  };

  const handleOnScrollVenues = (scrollEvent: positionValues) => {
    if (venues !== null && venues.length > 0) {
      const isLastPage = venues.length >= stateVenues.totalCount;
      const fetchMore =
        stateVenues.totalCount >= 30 &&
        !isLastPage &&
        scrollEvent.scrollHeight - scrollEvent.scrollTop - scrollEvent.clientHeight <=
          NEXT_VENUES_PAGE_SCROLL_TRESHOLD &&
        isRequestpending.current === false;

      if (fetchMore) {
        isRequestpending.current = true;

        dispatch(
          appendVenuesAction({
            ...searchData,
            limit,
            skip: (stateVenues.fetchedPage + 1) * skip - 30,
          })
        );
      }
    }
  };

  useEffect(() => {
    isRequestpending.current = false;
  }, [venues]);

  if (venues) {
    return (
      <div className="search_results_wrapper">
        <ElementLoader loading={loading} className="tw-rounded-3xl">
          {venues.length === 0 ? (
            <div className="empty_search">No result found</div>
          ) : (
            <Scrollbars className="search_results_inner_wrapper" onUpdate={handleOnScrollVenues}>
              <div className="search_results_content">
                {venues?.map((item: IVenue) => (
                  <SearchResultElement key={item.id} venue={item} selectedVenue={(data: IVenue) => selectVenue(data)} />
                ))}
              </div>
            </Scrollbars>
          )}
        </ElementLoader>
      </div>
    );
  } else {
    return <></>;
  }
};

export default SearchResults;
