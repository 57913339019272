import { generateUUID } from 'three/src/math/MathUtils';

import { IGlobalReducer } from 'models/reducers/i-global-reducer.interface';

import {
  APPEND_VENUES,
  FETCH_VENUES,
  SET_WALLET_ASSETS,
  SET_WALLET_LISTINGS,
  SET_WALLET_OFFERS,
} from 'constants/actionNames';

import {
  ADD_ERROR_MESSAGE,
  ADD_INFO_MESSAGE,
  ADD_SUCCESS_MESSAGE,
  ADD_WARNING_MESSAGE,
  CLEAR_ERROR_MESSAGES,
  CLEAR_INFO_MESSAGES,
  CLEAR_SUCCESS_MESSAGES,
  CLEAR_WARNING_MESSAGES,
  REMOVE_ERROR_MESSAGE,
  REMOVE_INFO_MESSAGE,
  REMOVE_SUCCESS_MESSAGE,
  REMOVE_WARNING_MESSAGE,
  SET_ERROR_MESSAGES,
  SET_INFO_MESSAGES,
  SET_LOADING,
  SET_SHOW_CURRENCY_LOTTERY_MODAL,
  SET_SHOW_CURRENCY_PURCHASE_MODAL,
  SET_SHOW_SELECT_WALLET_MODAL,
  SET_SHOW_SWITCH_NETWORK_MODAL,
  SET_SUCCESS_MESSAGES,
  SET_WARNING_MESSAGES,
} from 'actions/actionNames/globalActionNames';
import { APPEND_MARKET_ITEMS, FETCH_MARKET_ITEMS, FETCH_MARKET_OFFER } from 'actions/actionNames/marketActionNames';
import {
  APPEND_ASSET_HISTORY,
  FETCH_ASSET_HISTORY,
  FETCH_ASSET_PRICE_HISTORY,
  FETCH_ASSET_STATS,
} from 'actions/actionNames/statsActionNames';

import { getApiErrors } from 'utils/get-api-errors.utils';

const INITIAL_STATE: IGlobalReducer = {
  pendingRequests: [],
  errorMessages: [],
  successMessages: [],
  warningMessages: [],
  infoMessages: [],
  showSelectWalletModal: false,
  showSwitchNetworkModal: false,
  showCurrencyLotteryModal: false,
  showCurrencyPurchaseModal: false,
  currencySymbol: undefined,
  loading: false,
};

const getMessage = (message: string) => ({
  message,
  id: generateUUID(),
});

export const globalReducer = (state = INITIAL_STATE, action: any): IGlobalReducer => {
  const requestName = action.type.replace(/_PENDING|_FULFILLED|_REJECTED/, '');
  const skipLoadingActions = [
    SET_WALLET_LISTINGS,
    SET_WALLET_ASSETS,
    SET_WALLET_OFFERS,
    FETCH_MARKET_ITEMS,
    FETCH_MARKET_OFFER,
    APPEND_MARKET_ITEMS,
    FETCH_ASSET_STATS,
    FETCH_ASSET_PRICE_HISTORY,
    FETCH_ASSET_HISTORY,
    APPEND_ASSET_HISTORY,
    FETCH_VENUES,
    APPEND_VENUES,
  ];

  if (skipLoadingActions.includes(requestName)) {
    return {
      ...state,
      errorMessages: action.payload?.response
        ? [
            ...state.errorMessages,
            ...getApiErrors(action.payload.response).map((errorMessage) => getMessage(errorMessage)),
          ]
        : state.errorMessages,
    };
  }

  if (action.type.indexOf('_PENDING') > 0) {
    const pendingRequests = [...state.pendingRequests, requestName];

    return {
      ...state,
      pendingRequests: pendingRequests,
    };
  }

  if (action.type.indexOf('_REJECTED') > 0) {
    const pendingRequests = state.pendingRequests.filter((pendingRequestName) => pendingRequestName !== requestName);

    return {
      ...state,
      pendingRequests: [...pendingRequests],
      errorMessages: [
        ...state.errorMessages,
        ...getApiErrors(action.payload.response).map((errorMessage) => getMessage(errorMessage)),
      ], // TODO add a error handling service and use translations
    };
  }

  if (action.type.indexOf('_FULFILLED') > 0) {
    const pendingRequests = state.pendingRequests.filter((pendingRequestName) => pendingRequestName !== requestName);

    return {
      ...state,
      pendingRequests: [...pendingRequests],
    };
  }

  switch (action.type) {
    // ERROR
    case CLEAR_ERROR_MESSAGES: {
      return {
        ...state,
        errorMessages: [],
      };
    }
    case REMOVE_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessages: [...state.errorMessages.filter((message) => message.id !== action.payload)],
      };
    }
    case SET_ERROR_MESSAGES: {
      return {
        ...state,
        errorMessages: [...action.payload.map((message: string) => getMessage(message))],
      };
    }
    case ADD_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessages: [...state.errorMessages, getMessage(action.payload)],
      };
    }

    // SUCCESS
    case CLEAR_SUCCESS_MESSAGES: {
      return {
        ...state,
        successMessages: [],
      };
    }
    case REMOVE_SUCCESS_MESSAGE: {
      return {
        ...state,
        successMessages: [...state.successMessages.filter((message) => message.id !== action.payload)],
      };
    }
    case SET_SUCCESS_MESSAGES: {
      return {
        ...state,
        successMessages: [...action.payload.map((message: string) => getMessage(message))],
      };
    }
    case ADD_SUCCESS_MESSAGE: {
      return {
        ...state,
        successMessages: [...state.successMessages, getMessage(action.payload)],
      };
    }

    // WARNING
    case CLEAR_WARNING_MESSAGES: {
      return {
        ...state,
        warningMessages: [],
      };
    }
    case REMOVE_WARNING_MESSAGE: {
      return {
        ...state,
        warningMessages: [...state.warningMessages.filter((message) => message.id !== action.payload)],
      };
    }
    case SET_WARNING_MESSAGES: {
      return {
        ...state,
        warningMessages: [...action.payload.map((message: string) => getMessage(message))],
      };
    }
    case ADD_WARNING_MESSAGE: {
      return {
        ...state,
        warningMessages: [...state.warningMessages, getMessage(action.payload)],
      };
    }

    // INFO
    case CLEAR_INFO_MESSAGES: {
      return {
        ...state,
        infoMessages: [],
      };
    }
    case REMOVE_INFO_MESSAGE: {
      return {
        ...state,
        infoMessages: [...state.infoMessages.filter((message) => message.id !== action.payload)],
      };
    }
    case SET_INFO_MESSAGES: {
      return {
        ...state,
        infoMessages: [...action.payload.map((message: string) => getMessage(message))],
      };
    }
    case ADD_INFO_MESSAGE: {
      return {
        ...state,
        infoMessages: [...state.infoMessages, getMessage(action.payload)],
      };
    }
    case SET_SHOW_SELECT_WALLET_MODAL: {
      return {
        ...state,
        showSelectWalletModal: action.payload,
      };
    }
    case SET_SHOW_CURRENCY_LOTTERY_MODAL: {
      return {
        ...state,
        showCurrencyLotteryModal: action.payload,
      };
    }
    case SET_SHOW_CURRENCY_PURCHASE_MODAL: {
      return {
        ...state,
        showCurrencyPurchaseModal: action.payload.show,
        currencySymbol: action.payload.currencySymbol,
      };
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    // SWITCH NETWORK
    case SET_SHOW_SWITCH_NETWORK_MODAL: {
      return {
        ...state,
        showSwitchNetworkModal: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
