import {
    MAP_ROTATE_START, MAP_ROTATE_STOP, SET_CURRENT_DISPLAYED_VENUE, TOGGLE_MAP_ZOOM
} from '../constants/actionNames';

export const toggleMapZoomAction = () => ({
	type: TOGGLE_MAP_ZOOM,
})

export const mapRotateStart = () => ({
	type: MAP_ROTATE_START,
})

export const mapRotateStop = () => ({
	type: MAP_ROTATE_STOP,
})

export const setCurrentDisplayedVenue = (venue: any) => ({
	type: SET_CURRENT_DISPLAYED_VENUE,
	payload: venue
})