import { IWalletCurrency } from '../models/wallet-currency-response.interface';

import { CurrencyType } from '../enums/currency-type.enum';

import { getIsEmpty } from '../utils/get-is-empty.utils';

import { useCurrency, useRMVCurrency, useUSDCCurrency } from './useCurrency';
import { useDecimalPrice } from './useDecimalPrice';

/**
 * @param price a numeric value or a price object with currency, currency_amount or trade_value or price properties
 * @param currency a currency object or currency id
 * @param currencyType a currency type enum. Currency type will override the currency value if defined
 * @returns the parsed amount and currency object if exists
 */
export const useMappedPrice = (
  price: any,
  currency?: Omit<IWalletCurrency, 'balance' | 'icon'> | number,
  currencyType?: CurrencyType
) => {
  const rmvCurrency = useRMVCurrency();
  const usdcCurrency = useUSDCCurrency();
  const currencyAsWalletCurrency = currency as Omit<IWalletCurrency, 'balance' | 'icon'>;
  let priceValue = 0;
  let priceCurrency;

  if (typeof price === 'number' || !Number.isNaN(+price)) {
    priceValue = +price as number;
  }

  if (price?.currency_amount) {
    priceValue = price.currency_amount;
  } else if (price?.price) {
    priceValue = price.price;
  } else if (price?.trade_value) {
    priceValue = price.trade_value;
  }
  if (typeof currency === 'number') {
    priceCurrency = currency as number;
  }

  if (currencyAsWalletCurrency?.id) {
    priceCurrency = currencyAsWalletCurrency.id;
  }

  if (price?.currency?.id) {
    priceCurrency = price.currency.id;
  }

  if (!getIsEmpty(currencyType) && currencyType === CurrencyType.RMV) {
    priceCurrency = rmvCurrency?.id;
  }

  if (!getIsEmpty(currencyType) && currencyType === CurrencyType.USDC) {
    priceCurrency = usdcCurrency?.id;
  }

  return {
    currencyAmount: useDecimalPrice(priceValue, priceCurrency),
    currency: useCurrency(priceCurrency),
  };
};
