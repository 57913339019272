import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';

import { IState } from 'models/reducers/i-state.interface';
import { IWalletCurrency } from 'models/wallet-currency-response.interface';

import { PriceCurrencySymbols } from 'enums/price-currency-symbols.enum';

import { addSuccessMessageAction, setShowCurrencyPurchaseModal } from 'actions/global-actions';

import Button from 'components/buttons/Button';

import { ISourceScreen } from 'hooks/events/useEventsParams';
import { useFundsEvents } from 'hooks/events/useFundsEvents';

import { parseLargeDecimal } from 'utils/parser.utils';
import { useAppDispatch } from 'utils/useAppDispatch';

import CopyIcon from 'assets/icons/copy-icon.svg';

import ModalPortal from '../../ModalPortal';
import './CheckoutFundsModal.scss';

interface ICheckoutFundsModalProps extends Partial<ISourceScreen> {
  onContinue: () => void;
  onClose: () => void;
  fundsRemaining: number;
  currency?: IWalletCurrency;
}

const CheckoutFundsModal = ({
  fundsRemaining,
  currency,
  onContinue,
  onClose,
  sourceScreen,
}: ICheckoutFundsModalProps) => {
  const dispatch = useAppDispatch();
  const walletAddress = useSelector((state: IState): string => state.wallet.address);

  const { sendFundsBuyClickedEvent } = useFundsEvents();

  const onCopy = () => {
    dispatch(addSuccessMessageAction('Wallet address has been copied!'));
  };

  const currencySymbol = currency?.symbol === PriceCurrencySymbols.USDC ? 'USDC.E' : currency?.symbol;

  const handleClick = () => {
    dispatch(setShowCurrencyPurchaseModal({ show: true, currencySymbol: currency?.symbol }));
    sourceScreen && sendFundsBuyClickedEvent({ sourceScreen });
  };

  return (
    <ModalPortal
      title="Add funds to purchase"
      className={`checkout-funds-modal checkout-funds-modal--no-funds`}
      onBack={onContinue}
      onClose={onClose}
    >
      <div className="checkout-funds-modal">
        <p className="checkout-funds-modal__subtitle">
          You need
          <span>
            {parseLargeDecimal(fundsRemaining)} {currencySymbol} + gas fees (MATIC)
          </span>
        </p>
        <p className="checkout-funds-modal__content">
          Transfer funds to your wallet or buy with a card, on CEX and DEX.
          <br />
          It can take some time for your balance to update.
        </p>
        <div className="form-field">
          <label className="form-field__label">
            <div className="form-field__label-caption">
              <span>Your wallet address:</span>
            </div>
            <CopyToClipboard text={walletAddress} onCopy={onCopy}>
              <button className="form-field__suffix">
                <img src={CopyIcon} alt="Copy to clipboard" />
              </button>
            </CopyToClipboard>
            <input disabled className="input_regular" value={walletAddress} />
          </label>
        </div>
        <div className="checkout-funds-modal__footer">
          <Button color="default" type="button" text="CONTINUE" onClick={onContinue} />
          <Button type="button" text={`Buy ${currencySymbol}`} onClick={handleClick} />
        </div>
      </div>
    </ModalPortal>
  );
};

export default CheckoutFundsModal;
