import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { removeWarningMessageAction } from 'actions/global-actions';
import { Notifications } from 'enums/notifications/notifications.enum';
import Notification from '../notification/Notification';

interface IWarningNotificationProps {
  message: string;
  id: string;
  onClose?: () => void;
}

const WarningNotification = ({ id, message, onClose }: IWarningNotificationProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const removeWarningMessage = () => {
    dispatch(removeWarningMessageAction(id));
  };

  return (
    <Notification
      iconId="accent-icon"
      iconAltText={t('warningIconAltText')}
      message={message}
      type={Notifications.warning}
      onClose={onClose ? onClose : removeWarningMessage}
    />
  );
};

export default WarningNotification;
