import { Link } from 'react-router-dom';

import styles from './MenuElement.module.scss';

interface IMenuElement {
  title: string;
  active?: boolean;
  icon?: JSX.Element;
  link: string;
  href?: string;
  target?: string;
  rel?: string;
  download?: string;
  className?: string;
  textClassName?: string;
  activeTextClassName?: string;
  isExternal?: boolean;
  onClick?: () => void;
}

const MenuElement = ({
  title,
  active,
  icon,
  link,
  href,
  target,
  rel,
  download = 'file.pdf',
  isExternal = false,
  className = '',
  textClassName = '',
  activeTextClassName = '',
  onClick,
}: IMenuElement) => {
  const classNames = [className, styles['menu-element'], active ? styles['menu-element--active'] : ''];
  const textClassNames = [textClassName, active ? activeTextClassName : '', styles['text']];

  return isExternal ? (
    <a onClick={onClick} className={classNames.join(' ')} href={href} target={target} rel={rel} download={download}>
      {icon && <span className={styles['icon']}>{icon}</span>}
      <span className={textClassNames.join(' ')}>{title}</span>
    </a>
  ) : (
    <Link className={classNames.join(' ')} to={link} onClick={onClick}>
      {icon && <span className={styles['icon']}>{icon}</span>}
      <span className={textClassNames.join(' ')}>{title}</span>
    </Link>
  );
};

export default MenuElement;
