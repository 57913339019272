import { IStatsReducer } from '../models/reducers/i-stats-reducer.interface';

import {
  APPEND_ASSET_HISTORY,
  FETCH_ASSET_HISTORY,
  FETCH_ASSET_PRICE_HISTORY,
  FETCH_ASSET_STATS,
  RESET_ASSET_HISTORY,
  RESET_ASSET_PRICE_HISTORY,
  RESET_ASSET_STATS,
  RESET_STATS,
} from '../actions/actionNames/statsActionNames';

const INITIAL_STATE: IStatsReducer = {
  error: false,
  loading: false,
  assetStats: null,
  assetPriceHistory: null,
  assetPriceHistoryLoading: false,
  assetHistory: [],
  assetHistoryLoading: false,
  assetHistoryLastPage: false,
};

export const statsReducer = (state = INITIAL_STATE, action: any): IStatsReducer => {
  switch (action.type) {
    case FETCH_ASSET_STATS + '_PENDING':
      return { ...state, loading: true, error: false };
    case FETCH_ASSET_STATS + '_REJECTED':
      return { ...state, loading: false, error: true };
    case FETCH_ASSET_STATS + '_FULFILLED':
      return { ...state, assetStats: action.payload.data, loading: false, error: false };
    case RESET_ASSET_STATS:
      return { ...state, assetStats: null };
    case FETCH_ASSET_PRICE_HISTORY + '_PENDING':
      return { ...state, assetPriceHistoryLoading: true, error: false };
    case FETCH_ASSET_PRICE_HISTORY + '_REJECTED':
      return { ...state, assetPriceHistoryLoading: false, error: true };
    case FETCH_ASSET_PRICE_HISTORY + '_FULFILLED':
      return {
        ...state,
        assetPriceHistory: action.payload.data
          ? { ...action.payload.data, history: action.payload.data.history ? action.payload.data.history : [] }
          : null,
        assetPriceHistoryLoading: false,
        error: false,
      };
    case RESET_ASSET_PRICE_HISTORY:
      return { ...state, assetPriceHistory: null };
    case FETCH_ASSET_HISTORY + '_PENDING':
      return { ...state, assetHistoryLoading: true, error: false };
    case FETCH_ASSET_HISTORY + '_REJECTED':
      return { ...state, assetHistoryLoading: false, error: true };
    case FETCH_ASSET_HISTORY + '_FULFILLED':
      return {
        ...state,
        assetHistory: action.payload.data.results,
        assetHistoryLastPage: !action.payload.data.next,
        assetHistoryLoading: false,
        error: false,
      };
    case APPEND_ASSET_HISTORY + '_PENDING':
      return { ...state, assetHistoryLoading: true, error: false };
    case APPEND_ASSET_HISTORY + '_REJECTED':
      return { ...state, assetHistoryLoading: false, error: true };
    case APPEND_ASSET_HISTORY + '_FULFILLED':
      return {
        ...state,
        assetHistory: [...state.assetHistory, ...action.payload.data.results],
        assetHistoryLastPage: !action.payload.data.next,
        assetHistoryLoading: false,
        error: false,
      };
    case RESET_ASSET_HISTORY:
      return { ...state, assetHistory: [], assetHistoryLoading: false, assetHistoryLastPage: false };
    case RESET_STATS:
      return { ...state, assetStats: null, assetPriceHistory: null, assetHistory: [] };
    default:
      return state;
  }
};
