import { PropsWithChildren } from 'react';

interface IGameStakingCardProps extends PropsWithChildren {
  title: string;
}

const GameStakingCard = ({ title, children }: IGameStakingCardProps) => {
  return (
    <div className="tw-relative tw-w-full tw-rounded-2xl tw-bg-backgroundColorLight tw-p-5 lg:tw-p-7.5">
      <h3 className="tw-mb-5 tw-text-2xl tw-font-bold tw-uppercase tw-text-turquoise">{title}</h3>
      {children}
    </div>
  );
};

export default GameStakingCard;
