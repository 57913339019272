import { useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

import { useRampPayment } from 'hooks/useRampPayment';

import ModalPortal from '../../modal/ModalPortal';
import styles from './CurrencyPurchaseRamp.module.scss';

interface ICurrencyPurchaseRampProps {
  onClose: () => void;
  onComplete: () => void;
}

const CurrencyPurchaseRamp = ({ onClose, onComplete }: ICurrencyPurchaseRampProps) => {
  const modalFadeInAnimationDuration = 500;
  const [setIsRampOpen, isComplete, _, getRampPaymentContainerStyles] = useRampPayment();

  useEffect(() => {
    setTimeout(() => {
      setIsRampOpen(true);
    }, modalFadeInAnimationDuration);
  });

  useEffect(() => {
    if (isComplete) {
      onComplete();
    }
  }, [isComplete, onComplete]);

  return (
    <ModalPortal
      title="Add funds"
      className={styles['ramp-modal']}
      modalContentClassName={styles['ramp-modal__content']}
      onClose={onClose}
    >
      <Scrollbars style={{ height: '667px', minWidth: '320px' }}>
        <div id="lgo-ramp-container" style={getRampPaymentContainerStyles()}></div>
      </Scrollbars>
    </ModalPortal>
  );
};

export default CurrencyPurchaseRamp;
