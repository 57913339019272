import { IDispatchAction } from 'models/dispatch-action.interface';
import { IProfileAsset } from 'models/profile/i-profile-asset.interface';
import { IWalletReducer } from 'models/reducers/i-wallet-reducer.interface';

import { ProfileAssetOrderingEnum } from 'routes/profile/enums/profile-asset-ordering.enum';

import {
  CANCEL_BID,
  FETCH_WALLET_CURRENCIES,
  FETCH_WALLET_CURRENCIES_WITH_BALANCE,
  REMOVE_WALLET_LISTING,
  RESET_ASSETS,
  RESET_LISTINGS,
  RESET_OFFERS,
  SET_NEW_NFTS,
  SET_PROFILE_ASSETS_FILTERS,
  SET_WALLET_ADDRESS,
  SET_WALLET_ASSETS,
  SET_WALLET_LISTINGS,
  SET_WALLET_OFFERS,
  WALLET_SIGN_OUT,
} from 'constants/actionNames';

import { CANCEL_LISTING } from 'actions/actionNames/venuesActionNames';

const INITIAL_STATE: IWalletReducer = {
  address: '',
  assets: null,
  assetsLoading: false,
  assetsError: '',
  assetsLastPage: false,
  offersAssets: null,
  offersAssetsLoading: false,
  offersAssetsError: '',
  offersAssetsLastPage: false,
  listingsAssets: null,
  listingsAssetsLoading: false,
  listingsAssetsError: '',
  listingsAssetsLastPage: false,
  currencies: null,
  currenciesLoading: false,
  currenciesError: '',
  newNfts: 0,
  filters: {
    search: '',
    ordering: ProfileAssetOrderingEnum.newestFirst,
    country: '',
    category: '',
    created_by_me: '',
  },
};

export const walletReducer = (state = INITIAL_STATE, action: IDispatchAction<any>): IWalletReducer => {
  let loading = false;

  switch (action.type) {
    case WALLET_SIGN_OUT + '_FULFILLED':
      // TODO: move currencies to own reducer
      return {
        ...INITIAL_STATE,
        currencies: state.currencies,
      };
    case RESET_ASSETS:
      return { ...state, assets: null, assetsLastPage: false };
    case RESET_OFFERS:
      return { ...state, offersAssets: null, offersAssetsLastPage: false };
    case RESET_LISTINGS:
      return { ...state, listingsAssets: null, listingsAssetsLastPage: false };
    case SET_WALLET_ADDRESS:
      return { ...state, address: action.payload };
    case SET_PROFILE_ASSETS_FILTERS:
      return { ...state, filters: action.payload };

    // collection
    case SET_WALLET_ASSETS + '_PENDING':
      return { ...state, assetsLoading: true, assetsError: '' };
    case SET_WALLET_ASSETS + '_REJECTED':
      if (action.payload?.message?.toLowerCase() === 'canceled') {
        loading = true;
      }
      return { ...state, assetsLoading: loading, assetsError: 'Api issue' };
    case SET_WALLET_ASSETS + '_FULFILLED':
      const results = action.payload.data.results;
      const assets = state.assets ?? [];

      return {
        ...state,
        assets: [...assets.filter(({ id }) => !results.some((asset: IProfileAsset) => asset.id === id)), ...results],
        assetsLoading: false,
        assetsError: '',
        assetsLastPage: !action.payload.data.next,
      };

    // offers
    case SET_WALLET_OFFERS + '_PENDING':
      return { ...state, offersAssetsLoading: true, offersAssetsError: '' };
    case SET_WALLET_OFFERS + '_REJECTED':
      if (action.payload?.message?.toLowerCase() === 'canceled') {
        loading = true;
      }
      return { ...state, offersAssetsLoading: loading, offersAssetsError: 'Api issue' };
    case SET_WALLET_OFFERS + '_FULFILLED':
      return {
        ...state,
        offersAssets: [...(state?.offersAssets ?? []), ...action.payload.data.results],
        offersAssetsLoading: false,
        offersAssetsError: '',
        offersAssetsLastPage: !action?.payload?.data?.next,
      };
    case CANCEL_BID + '_FULFILLED':
      return {
        ...state,
        offersAssets: state.offersAssets?.filter((offer) => offer.id !== action.payload.data)! ?? state.offersAssets,
      };

    // listing
    case SET_WALLET_LISTINGS + '_PENDING':
      return { ...state, listingsAssetsLoading: true, listingsAssetsError: '' };
    case SET_WALLET_LISTINGS + '_REJECTED':
      if (action.payload?.message?.toLowerCase() === 'canceled') {
        loading = true;
      }
      return { ...state, listingsAssetsLoading: loading, listingsAssetsError: 'Api issue' };
    case SET_WALLET_LISTINGS + '_FULFILLED':
      return {
        ...state,
        listingsAssets: [...(state?.listingsAssets ?? []), ...action.payload.data.results],
        listingsAssetsLoading: false,
        listingsAssetsError: '',
        listingsAssetsLastPage: !action.payload.data.next,
      };
    case CANCEL_LISTING + '_FULFILLED': {
      const canceledIndex = state.listingsAssets?.findIndex((listing) => listing.id === action.payload);
      const listings = state.listingsAssets;

      if (!canceledIndex) {
        return state;
      }

      listings?.splice(canceledIndex, 1);

      return { ...state, listingsAssets: listings };
    }
    case REMOVE_WALLET_LISTING: {
      return {
        ...state,
        listingsAssets:
          state.listingsAssets?.filter((listing) => listing.id !== action.payload)! ?? state.listingsAssets,
      };
    }

    // fetch currencies
    case FETCH_WALLET_CURRENCIES + '_PENDING':
      return { ...state, currenciesLoading: true, currenciesError: '' };
    case FETCH_WALLET_CURRENCIES + '_REJECTED':
      return { ...state, currenciesLoading: false, currenciesError: 'Api issue' };
    case FETCH_WALLET_CURRENCIES + '_FULFILLED':
      return {
        ...state,
        currencies: action.payload.data,
        currenciesLoading: false,
        currenciesError: '',
      };

    // fetch currencies with balance
    case FETCH_WALLET_CURRENCIES_WITH_BALANCE + '_PENDING':
      return { ...state, currenciesLoading: true, currenciesError: '' };
    case FETCH_WALLET_CURRENCIES_WITH_BALANCE + '_REJECTED':
      return { ...state, currenciesLoading: false, currenciesError: 'Api issue' };
    case SET_NEW_NFTS:
      return { ...state, newNfts: action.payload };
    default:
      return state;
  }
};
