import Button from 'components/buttons/Button';

import { ReactComponent as ArrowButtonSvg } from 'assets/arrowButton.svg';
import { ReactComponent as PowerPointSvg } from 'assets/powerPoint.svg';

interface IGameProductButton {
  link: string;
  disabled?: boolean;
}

const GameProductButton = ({ link, disabled }: IGameProductButton) => {
  return (
    <Button
      iconComponentRight={<ArrowButtonSvg />}
      iconComponentLeft={<PowerPointSvg className="tw-h-6" />}
      size="medium-small"
      color="blue"
      disabled={disabled}
      className="tw-round tw-h-[42px] tw-max-w-sm tw-px-6 tw-py-0 lg:tw-h-12"
      contentClassName="tw-flex tw-gap-2.5 tw-items-center"
      asLink
      link={link}
    >
      Product presentation
    </Button>
  );
};

export default GameProductButton;
