export const defaultCardHeight = 550;
export const defaultCardWidth = 360;

export const getMapVenueCardScaleValue = (width: number, height: number): number => {
  const maxYRatio = 0.5;
  const maxXRatio = 0.35;
  const yScale = (height * maxYRatio) / defaultCardHeight;
  const xScale = (width * maxXRatio) / defaultCardWidth;

  let scale = yScale < xScale ? yScale : xScale;

  scale = setMinMaxValues(scale);

  return scale;
};

const setMinMaxValues = (value: number) => {
  if (value < 0.5) {
    return 0.5;
  }

  return value;
};
