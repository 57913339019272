import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Web3Singleton from 'web3Singleton';

import { ILootboxMetadata } from 'models/lootbox/i-lootbox-metadata.interface';
import { ILootboxOwned } from 'models/lootbox/i-lootbox-owned.interface';
import { IState } from 'models/reducers/i-state.interface';

import { NETWORK_SETTINGS } from 'constants/network.constant';

import { addErrorMessageAction } from 'actions/global-actions';

import { getLootboxMetadataService } from 'services/lootbox.service';

import { useLoadingTime } from 'hooks/useLoadingTime';

import { getLootboxTransactionError } from 'utils/get-lootbox-transaction-error.util';
import { mergeArrays } from 'utils/merge-arrays.util';

export const useLootboxesOwned = () => {
  const { t } = useTranslation('notifications');
  const [lootboxesOwned, setLootboxesOwned] = useState<ILootboxOwned[]>();
  const [lootboxesCount, setLootboxesCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const lgoWeb3 = Web3Singleton.getInstance();
  const walletAddress = useSelector((state: IState): string => state.wallet.address);
  const dispatch = useDispatch();

  const { loadingTime, handleStartLoadingTime, handleStopLoadingTime } = useLoadingTime();

  useEffect(() => {
    if (walletAddress) {
      setLootboxesCount(0);
    }
  }, [walletAddress]);

  const fetchLootboxesDetails = async () => {
    setLoading(true);
    handleStartLoadingTime();

    try {
      const contractAddresses = [NETWORK_SETTINGS.LOOTBOX_PACK.ADDRESS];
      const lootboxesResult = await Promise.all(
        contractAddresses.map((contractAddress) => lgoWeb3.getLootboxesOwned(walletAddress, contractAddress))
      );
      const lootboxes = lootboxesResult.map((lootboxesPack, index) =>
        lootboxesPack.map((lootbox) => ({ ...lootbox, address: contractAddresses[index] }))
      );

      const mappedLootboxes = await Promise.all(
        mergeArrays(lootboxes).map(async (lootbox) => {
          let lootboxMetadata: ILootboxMetadata | null;

          try {
            lootboxMetadata = (await getLootboxMetadataService(lootbox.metadata.id)).data;
          } catch (e) {
            lootboxMetadata = {
              id: +lootbox.metadata.id,
              name: lootbox.metadata.name as string | undefined,
              image: lootbox.metadata.image as string | undefined,
              openingAnimation: lootbox.metadata.animation_url as string | undefined,
              chancesInfo: lootbox.metadata.description as string | undefined,
            };
          }

          return {
            ...lootbox,
            id: lootbox.metadata.id,
            address: lootbox.address,
            quantityOwned: lootbox.quantityOwned || '0',
            metadata: lootboxMetadata,
          };
        })
      );

      const count = mappedLootboxes
        ?.map((lootbox) => (lootbox.quantityOwned ? +lootbox.quantityOwned : 0))
        .reduce((accumulator, value) => accumulator + value, 0);

      setLootboxesOwned(mappedLootboxes);
      setLootboxesCount(count || 0);
    } catch (error: any) {
      dispatch(addErrorMessageAction(t(getLootboxTransactionError(error?.message))));
    }

    setLoading(false);
    handleStopLoadingTime();
  };

  return { loading, loadingTime, lootboxes: lootboxesOwned, lootboxesCount, fetchLootboxesDetails };
};
