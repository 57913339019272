import { useSelector } from 'react-redux';

import { Noop } from 'types/common.interface';

import { IState } from 'models/reducers/i-state.interface';
import { IWalletReducer } from 'models/reducers/i-wallet-reducer.interface';

import { ROUTES } from 'constants/routes.constant';

import Button from 'components/buttons/Button';

import rmv from 'assets/wheel-of-fortune/rmv.png';

interface IWheelOfFortuneResultProps {
  reward: string;
  onClose: Noop;
}

const WheelOfFortuneResult = ({ reward, onClose }: IWheelOfFortuneResultProps) => {
  const { assets } = useSelector(({ wallet }: IState): IWalletReducer => wallet);

  return (
    <div className="tw-absolute tw-inset-x-0 tw-top-[calc(50%-91px)] tw-z-50 tw-flex tw-flex-col tw-items-center tw-gap-7.5 tw-bg-overlayGradient tw-p-9">
      <div className="tw-flex tw-items-center tw-justify-center tw-gap-2.5 tw-text-center tw-text-xl tw-font-semibold md:tw-text-2xl">
        <div className="tw-flex tw-flex-col tw-items-center">
          <p className="tw-flex tw-items-center tw-gap-1 tw-uppercase">
            You won <span className="tw-text-green">{parseFloat(reward).toFixed(2)}</span>
            <img src={rmv} alt="rmv coin" className="tw-h-7 tw-w-7" />
          </p>
          {!Boolean(assets?.length) ? (
            <p className="tw-text-sm tw-font-normal">
              <span className="tw-text-green">Buy NFT</span> or collect 1000 $RMV to{' '}
              <span className="tw-text-green">withdraw now!</span>
            </p>
          ) : (
            <span className="tw-text-sm tw-font-normal">The Reward will be available tomorrow with Royalties.</span>
          )}
        </div>
      </div>
      <div className="tw-flex tw-flex-row tw-gap-7.5">
        {!Boolean(assets?.length) && (
          <Button
            size="medium"
            ariaLabel="Buy NFT"
            asLink
            target="_self"
            link={ROUTES.LOOTBOX}
            onClick={onClose}
            twClassName="tw-w-36"
          >
            Buy NFT
          </Button>
        )}
        <Button
          size="medium"
          ariaLabel="Withdraw"
          disabled={!Boolean(assets?.length)}
          asLink
          target="_self"
          link={`${ROUTES.PROFILE}/collected`}
          onClick={onClose}
          twClassName="tw-w-36"
        >
          Withdraw
        </Button>
      </div>
    </div>
  );
};

export default WheelOfFortuneResult;
