import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { IState } from 'models/reducers/i-state.interface';
import { IWalletCurrency } from 'models/wallet-currency-response.interface';

import { PriceCurrencySymbols } from 'enums/price-currency-symbols.enum';

import { ERC20_ABI } from 'constants/abi/erc20-abi.constant';
import { DEFAULTS } from 'constants/defaults.constant';

import { getIconByCurrencySymbol } from 'utils/get-currency-icon-by-symbol';
import { parseDecimal } from 'utils/parser.utils';

import Web3Singleton from '../web3Singleton';

// without balance - for all users

export const useCurrency = (currencyId?: number): Omit<IWalletCurrency, 'balance'> | undefined => {
  const currencies = useSelector((state: IState) => state.wallet.currencies);
  const currency = currencies?.find((currency) => currency.id === currencyId);

  return currency && { ...currency, icon: getIconByCurrencySymbol(currency.symbol) };
};

export const useCurrencyBySymbol = (currencySymbol?: string): Omit<IWalletCurrency, 'balance'> | undefined => {
  const currencies = useSelector((state: IState) => state.wallet.currencies);
  const currency = currencies?.find((currency) => currency.symbol === currencySymbol);

  return currency && { ...currency, icon: getIconByCurrencySymbol(currency.symbol) };
};

export const useRMVCurrency = (): IWalletCurrency | undefined => {
  const currencies = useSelector((state: IState) => state.wallet.currencies);
  const currency = currencies?.find((currency) => currency.symbol === PriceCurrencySymbols.RMV);

  return currency && { ...currency, icon: getIconByCurrencySymbol(currency.symbol) };
};

export const useUSDCCurrency = (): Omit<IWalletCurrency, 'balance'> | undefined => {
  const currencies = useSelector((state: IState) => state.wallet.currencies);
  const currency = currencies?.find((currency) => currency.symbol === PriceCurrencySymbols.USDC);

  return currency && { ...currency, icon: getIconByCurrencySymbol(currency.symbol) };
};

export const useDefaultCurrency = (): Omit<IWalletCurrency, 'balance'> | undefined => {
  const currencies = useSelector((state: IState) => state.wallet.currencies);
  const currency = currencies?.find((currency) => currency.symbol === DEFAULTS.defaultCurrencySymbol);

  return currency && { ...currency, icon: getIconByCurrencySymbol(currency.symbol) };
};

// with balance - for authorized users

export const useCurrencyWithBalance = (currencyId?: number): Required<IWalletCurrency> | undefined => {
  const walletAddress = useSelector((state: IState): string => state.wallet.address);
  const currencies = useSelector((state: IState) => state.wallet.currencies);
  const currency = currencies?.find((currency) => currency.id === currencyId);
  const [balance, setBalance] = useState('0');
  const lgoWeb3 = Web3Singleton.getInstance();

  useEffect(() => {
    if (!currencies?.length || !currency || !walletAddress) {
      setBalance('0');

      return;
    }

    const fetchCurrenciesBalance = async () => {
      const balance = await lgoWeb3.getCurrencyBalance(ERC20_ABI, currency.address, walletAddress);

      setBalance(balance);
    };

    fetchCurrenciesBalance();
  }, [currency, currencies, walletAddress, lgoWeb3, setBalance]);

  return currency && { ...currency, balance, icon: getIconByCurrencySymbol(currency.symbol) };
};

export const useRMVCurrencyWithBalance = (): Required<IWalletCurrency> | undefined => {
  const currencies = useSelector((state: IState) => state.wallet.currencies);
  const currency = currencies?.find((currency) => currency.symbol === PriceCurrencySymbols.RMV);
  const balance = useCurrencyBalance(currency?.id);

  return currency && { ...currency, balance, icon: getIconByCurrencySymbol(currency.symbol) };
};

export const useUSDCCurrencyWithBalance = (): Required<IWalletCurrency> | undefined => {
  const currencies = useSelector((state: IState) => state.wallet.currencies);
  const currency = currencies?.find((currency) => currency.symbol === PriceCurrencySymbols.USDC);

  return useCurrencyWithBalance(currency?.id);
};

// parsed currency balance

export const useCurrencyBalance = (currencyId?: number): string => {
  const walletAddress = useSelector((state: IState): string => state.wallet.address);
  const currencies = useSelector((state: IState) => state.wallet.currencies);
  const currency = currencies?.find((currency) => currency.id === currencyId);
  const [balance, setBalance] = useState('0');
  const lgoWeb3 = Web3Singleton.getInstance();

  useEffect(() => {
    if (!currencies?.length || !currency || !walletAddress) {
      setBalance('0');

      return;
    }

    const fetchCurrenciesBalance = async () => {
      const balance = await lgoWeb3.getCurrencyBalance(ERC20_ABI, currency.address, walletAddress);

      setBalance(balance);
    };

    fetchCurrenciesBalance();
  }, [currency, currencies, walletAddress, lgoWeb3, setBalance]);

  return currency && balance ? parseDecimal(balance, currency.decimal) : '0';
};

export const useRMVCurrencyBalance = (): string => {
  const rmvCurrency = useRMVCurrency();

  return useCurrencyBalance(rmvCurrency?.id);
};

export const useUSDCCurrencyBalance = (): string => {
  const usdcCurrency = useUSDCCurrency();

  return useCurrencyBalance(usdcCurrency?.id);
};
