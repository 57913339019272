import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { IHostConfig, IPurchaseCreatedEvent } from '@ramp-network/ramp-instant-sdk/dist/types/types';

import { IState } from '../models/reducers/i-state.interface';

import { DEFAULTS } from '../constants/defaults.constant';

import { SourceScreenName, useFundsEvents } from 'hooks/events/useFundsEvents';

import logo from '../assets/logos/reality-metaverse-black-logo.png';

import { useEnv } from './useEnv';
import { useWindowSize } from './useWindowSize';

export enum PurchaseStatus {
  INITIALIZED = 'INITIALIZED',
  PAYMENT_STARTED = 'PAYMENT_STARTED',
  PAYMENT_IN_PROGRESS = 'PAYMENT_IN_PROGRESS',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_EXECUTED = 'PAYMENT_EXECUTED',
  FIAT_RECEIVED = 'FIAT_RECEIVED',
  FIAT_SENT = 'FIAT_SENT',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
  RELEASING = 'RELEASING',
  RELEASED = 'RELEASED',
}

export enum WidgetEventTypes {
  WIDGET_CLOSE = 'WIDGET_CLOSE',
  WIDGET_CONFIG_DONE = 'WIDGET_CONFIG_DONE',
  WIDGET_CONFIG_FAILED = 'WIDGET_CONFIG_FAILED',
  PURCHASE_CREATED = 'PURCHASE_CREATED',
}

export function useRampPayment(): [
  (open: boolean) => void,
  boolean,
  boolean,
  () => Record<string, string>,
  () => Record<string, string>
] {
  const SMALL_DEVICE_SCREEN_SIZE = 376;
  const DEVICE_SCREEN_MIN_HEIGHT = 667;
  const [windowInnerWidth] = useWindowSize();
  const [paymentSDK, setPaymentSDK] = useState<RampInstantSDK>();
  const walletAddress = useSelector((state: IState): string => state.wallet.address);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const { rampHostApiKey, rampUrl } = useEnv();

  const isSmallDevice = windowInnerWidth < SMALL_DEVICE_SCREEN_SIZE;

  const { sendFundsAddWithCardEvent, sendFundsAddErrorEvent } = useFundsEvents();
  const sourceScreen = isSmallDevice ? SourceScreenName.MobileHeader : SourceScreenName.RegularHeader;

  const openRampApp = (open: boolean) => {
    setIsOpen(open);
  };

  const getRampPaymentContainerStyles = (): Record<string, string> => {
    return {
      minHeight: `${DEVICE_SCREEN_MIN_HEIGHT}px`,
      minWidth: isSmallDevice ? `${windowInnerWidth - 50}px ` : `${SMALL_DEVICE_SCREEN_SIZE}px`,
    };
  };

  const getRampPaymentModalStyles = (): Record<string, string> => {
    return isOpen && isSmallDevice ? { minHeight: '790px' } : {};
  };

  useEffect(() => {
    if (!paymentSDK && isOpen) {
      const configuration: IHostConfig = {
        hostAppName: 'Reality Metaverse',
        hostLogoUrl: logo,
        userAddress: walletAddress,
        hostApiKey: rampHostApiKey,
        defaultAsset: DEFAULTS.rampDefaultAsset,
        variant: 'embedded-mobile',
        fiatValue: DEFAULTS.rampDefaultFiatValue,
        fiatCurrency: DEFAULTS.rampDefaultFiatCurrency,
        containerNode: document.getElementById('lgo-ramp-container')!,
      };

      if (rampUrl) {
        configuration.url = rampUrl;
      }

      setPaymentSDK(
        new RampInstantSDK(configuration)
          .show()
          .on(WidgetEventTypes.PURCHASE_CREATED, (event: IPurchaseCreatedEvent) => {
            if (event.payload?.purchase.status === PurchaseStatus.PAYMENT_EXECUTED) {
              setIsComplete(true);
              sendFundsAddWithCardEvent({ sourceScreen });
            }
          })
          .on(WidgetEventTypes.WIDGET_CLOSE, () => {
            setIsOpen(false);
            sendFundsAddErrorEvent({ sourceScreen });
          })
      );
    }

    if (!isOpen && paymentSDK) {
      paymentSDK.close();
      setPaymentSDK(undefined);
    }

    const unsubscribeFromRampPayment = () => {
      if (!paymentSDK) {
        return;
      }

      paymentSDK.unsubscribe('*', () => {
        setPaymentSDK(undefined);
      });
    };

    return () => unsubscribeFromRampPayment();
  }, [rampHostApiKey, rampUrl, walletAddress, isOpen, paymentSDK]);

  return [openRampApp, isComplete, isOpen, getRampPaymentContainerStyles, getRampPaymentModalStyles];
}
