import axios, { AxiosResponse } from 'axios';
import Cookies from 'universal-cookie';

import { CookiesKeys } from '../enums/cookies-keys.enum';

export const cancelTradeService = (tradeId: number): Promise<AxiosResponse> => {
  return axios.delete(`/api/v1/trades/${tradeId}`, {
    headers: {
      'X-CSRFToken': new Cookies().get(CookiesKeys.csrftoken),
    },
  });
};
