import ScrollInAnimationItem from '../../../components/scrollInAnimationItem/ScrollInAnimationItem';

import SectionImageProperties from '../../../assets/game-onboarding/royalties.png';

import GameOnboardingInfoCard from '../gameOnboardingInfoCard/GameOnboardingInfoCard';

const GameOnboardingRoyaltiesSection = () => {
  return (
    <div className="tw-mb-[80px] tw-flex tw-w-[1360px] tw-max-w-full tw-max-w-full tw-flex-col tw-flex-wrap tw-items-center tw-justify-between tw-gap-[40px] lg:tw-mb-[120px] lg:tw-flex-row">
      <ScrollInAnimationItem className="tw-w-full lg:tw-order-2 lg:tw-w-[668px] lg:tw-max-w-[55%]" delay="0.1s">
        <img alt="" className="tw-h-auto tw-max-w-full" src={SectionImageProperties} width="815" height="597" />
      </ScrollInAnimationItem>
      <ScrollInAnimationItem className="tw-w-full lg:tw-w-[560px] lg:tw-max-w-[40%]" delay="0.2s">
        <GameOnboardingInfoCard
          title={<b>royalties</b>}
          content={
            <>
              <p>
                Each NFT has expandable utilities that generates royalties. 3D NFT models are used in the mobile game
                Landlord GO, and 30% of the web2 revenue is shared with the NFT owners. Additional games and metaverse
                projects will use the models to increase revenue streams. On top of that, NFT receive 20% of the fees
                generated in primary and secondary markets, NFT trading royalties, NFT auctions, and NFT renting.
              </p>
            </>
          }
        />
      </ScrollInAnimationItem>
    </div>
  );
};

export default GameOnboardingRoyaltiesSection;
