import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import Web3Singleton from 'web3Singleton';

import { IState } from 'models/reducers/i-state.interface';
import { IWalletCurrency } from 'models/wallet-currency-response.interface';

import { IGame } from '../constants/games.constants';
import { ERROR_MESSAGES } from 'constants/error-messages.constant';

import { addErrorMessageAction, setShowSwitchNetworkModal } from 'actions/global-actions';

import { parseFromDecimal } from 'utils/parser.utils';

import { gameHasPoolId } from '../utils';

export const useGameStakingRaised = (game: IGame | undefined) => {
  const { t } = useTranslation('notifications');
  const dispatch = useDispatch();
  const { correctChain } = useSelector(({ web3 }: IState) => web3);
  const currencies = useSelector((state: IState): IWalletCurrency[] | null => state.wallet.currencies);
  const lgoWeb3 = Web3Singleton.getInstance();

  const hasPoolId = gameHasPoolId(game);

  const [currency, setCurrency] = useState<IWalletCurrency | undefined>(undefined);
  const [rewardCurrency, setRewardCurrency] = useState<IWalletCurrency | undefined>(undefined);
  const [disabled, onDisabled] = useToggle(true);
  const [poolOpen, onPoolOpenToggle] = useToggle(false);
  const [poolEnded, onPoolEndedToggle] = useToggle(false);
  const [poolType, setPoolType] = useState<number | null>(null);

  const handleStakingToken = async (negative: boolean | undefined, flexible: boolean | undefined) => {
    try {
      const stakingToken = await lgoWeb3.stakingToken(negative, flexible);
      const walletCurrency = currencies?.find(
        (walletCurrency: IWalletCurrency) => walletCurrency.address === stakingToken
      );

      if (walletCurrency) {
        onDisabled(false);
        setCurrency(walletCurrency);
      }
    } catch (e) {
      console.error(e);
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
    }
  };

  const handleRewardToken = async () => {
    try {
      const rewardToken = await lgoWeb3.rewardToken();

      const rewardCurrency = currencies?.find(
        (walletCurrency: IWalletCurrency) => walletCurrency.address === rewardToken
      );

      if (rewardCurrency) {
        onDisabled(false);
        setRewardCurrency(rewardCurrency);
      }
    } catch (e) {
      console.error(e);
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
    }
  };

  const handleCheckIfStakingOpen = async (
    poolId: number,
    negative: boolean | undefined,
    flexible: boolean | undefined
  ) => {
    try {
      const isStakingOpen = await lgoWeb3.checkIfStakingOpen(poolId, negative, flexible);

      onPoolOpenToggle(isStakingOpen);
    } catch (e) {
      console.error(e);
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
    }
  };

  const handleCheckIfPoolEnded = async (
    poolId: number,
    negative: boolean | undefined,
    flexible: boolean | undefined
  ) => {
    try {
      const isPoolEnded = await lgoWeb3.checkIfPoolEnded(poolId, negative, flexible);

      onPoolEndedToggle(isPoolEnded);
    } catch (e) {
      console.error(e);
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
    }
  };

  const handleCheckPoolType = async (poolId: number, negative: boolean | undefined, flexible: boolean | undefined) => {
    try {
      const poolType = await lgoWeb3.checkPoolType(poolId, negative, flexible);

      setPoolType(poolType);
    } catch (e) {
      console.error(e);
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
    }
  };

  const handleReset = () => {
    onDisabled(true);
    onPoolOpenToggle(false);
    onPoolEndedToggle(false);
    setPoolType(null);
    setStakingRaised({ total: '0', target: '0' });
  };

  useEffect(() => {
    if (!correctChain) {
      dispatch(setShowSwitchNetworkModal(true));
      handleReset();
    } else {
      dispatch(setShowSwitchNetworkModal(false));
    }
  }, [correctChain]);

  useEffect(() => {
    if (!lgoWeb3.getWeb3()) {
      return;
    } else if (correctChain && currencies && hasPoolId) {
      handleStakingToken(game?.negative, game?.flexible);
      game?.negative && handleRewardToken();
      handleCheckIfStakingOpen(game?.poolId!, game?.negative, game?.flexible);
      handleCheckIfPoolEnded(game?.poolId!, game?.negative, game?.flexible);
      handleCheckPoolType(game?.poolId!, game?.negative, game?.flexible);
    }
  }, [lgoWeb3.getWeb3(), correctChain, currencies, hasPoolId, game?.poolId, game?.flexible, game?.negative]);

  const [loading, onLoading] = useToggle(false);
  const [stakingRaised, setStakingRaised] = useState({ total: '0', target: '0' });

  const handleCheckStakingRaised = async (
    poolId: number,
    negative: boolean | undefined,
    flexible: boolean | undefined
  ) => {
    onLoading(true);

    try {
      const isPoolEnded = await lgoWeb3.checkIfPoolEnded(poolId, negative, flexible);
      const target = await lgoWeb3.checkStakingTarget(poolId, negative, flexible);
      const total = await lgoWeb3.checkTotalStaked(poolId, negative, flexible);

      setStakingRaised({
        total: parseFromDecimal(isPoolEnded ? target : total, currency!.decimal),
        target: parseFromDecimal(target, currency!.decimal),
      });
    } catch (e) {
      console.error(e);
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
    } finally {
      onLoading(false);
    }
  };

  useEffect(() => {
    if (correctChain && hasPoolId) {
      !disabled && currency && handleCheckStakingRaised(game?.poolId!, game?.negative, game?.flexible);
    }
  }, [correctChain, disabled, currency, hasPoolId, game?.poolId, game?.negative, game?.flexible]);

  return {
    currency,
    rewardCurrency,
    disabled,
    hasPoolId,
    poolOpen,
    poolEnded,
    poolType,
    loading,
    ...stakingRaised,
    onCallback: handleCheckStakingRaised,
    onReset: handleReset,
  };
};
