import { twMerge } from 'tailwind-merge';

import { useNftCardSize } from '../hooks';
import { useNftCardContext } from '../nftCardContext/NftCardContext';

const NftCategoryIdBadge = () => {
  const { asset } = useNftCardContext();
  const { isCardLarge, isCardMedium, isCardSmall } = useNftCardSize();
  const isNotSmallCard = isCardLarge || isCardMedium;

  return (
    <div
      className={twMerge(
        'tw-absolute tw-z-10 tw-w-max tw-rounded-2xl tw-bg-backgroundColorMedium tw-py-1 tw-capitalize tw-text-white tw-opacity-80',
        isNotSmallCard && 'tw-left-3 tw-top-3.5 tw-px-3 tw-text-base/[20px]',
        isCardSmall && 'tw-left-2 tw-top-2.5 tw-px-2.5 tw-text-sm/[16px]'
      )}
    >
      {asset?.category} #{asset?.id}
    </div>
  );
};

export default NftCategoryIdBadge;
