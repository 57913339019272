import { isMobile } from 'react-device-detect';

import Button from '../../../../components/buttons/Button';

import LandingFaqListItem from '../../../landing/landingFaq/landingFaqItem/FaqListItem';
import GameOnboardingInstructionAddNetwork from '../gameOnboardingInstructionAddNetwork/GameOnboardingInstructionAddNetwork';
import GameOnboardingInstructionCopyWallet from '../gameOnboardingInstructionCopyWallet/GameOnboardingInstructionCopyWallet';
import GameOnboardingInstructionCreateWallet from '../gameOnboardingInstructionCreateWallet/GameOnboardingInstructionCreateWallet';
import GameOnboardingInstructionCreateWalletMobile from '../gameOnboardingInstructionCreateWalletMobile/GameOnboardingInstructionCreateWalletMobile';
import GameOnboardingInstructionInstallMetamask from '../gameOnboardingInstructionInstallMetamask/GameOnboardingInstructionInstallMetamask';
import GameOnboardingInstructionInstallMetamaskMobile from '../gameOnboardingInstructionInstallMetamaskMobile/GameOnboardingInstructionInstallMetamaskMobile';
import GameOnboardingInstructionOpenTicket from '../gameOnboardingInstructionOpenTicket/GameOnboardingInstructionOpenTicket';

const GameOnboardingInstructionWithoutWallet = () => {
  return (
    <div>
      {isMobile ? (
        <LandingFaqListItem title="1. Install the Metamask wallet app on your phone">
          <GameOnboardingInstructionInstallMetamaskMobile />
        </LandingFaqListItem>
      ) : (
        <LandingFaqListItem title="1. Install the Metamask extension in your browser">
          <GameOnboardingInstructionInstallMetamask />
        </LandingFaqListItem>
      )}
      {isMobile ? (
        <LandingFaqListItem title="2. Create a Wallet on MetaMask:">
          <GameOnboardingInstructionCreateWalletMobile />
        </LandingFaqListItem>
      ) : (
        <LandingFaqListItem title="2. Create a Wallet on MetaMask:">
          <GameOnboardingInstructionCreateWallet />
        </LandingFaqListItem>
      )}
      <LandingFaqListItem title="3. Add or Switch to Polygon Network">
        <GameOnboardingInstructionAddNetwork />
      </LandingFaqListItem>
      <LandingFaqListItem title="4. Copy your Wallet Address">
        <GameOnboardingInstructionCopyWallet />
      </LandingFaqListItem>
      <LandingFaqListItem title="5. Open Ticket">
        <GameOnboardingInstructionOpenTicket />
      </LandingFaqListItem>
      <Button className="tw-mx-auto tw-mt-7.5 tw-block lg:tw-mt-15" size="medium" asLink link="https://realitymeta.gitbook.io/lgo-onboarding/">
        Read tutorial
      </Button>
    </div>
  );
};

export default GameOnboardingInstructionWithoutWallet;
