import Button from '../../../components/buttons/Button';
import TypingAnimation from '../../../components/typingAnimation/TypingAnimation';

import FailedIcon from '../../../assets/lootbox/purchase-failed-icon.svg';

import styles from './LootboxPurchaseFailed.module.scss';

interface ILootboxPurchaseFailedProps {
  title: string;
  text?: string;
  skipButtonText?: string;
  onRetry: () => void;
  onSkip: () => void;
}

const LootboxPurchaseFailed = ({ title, text, skipButtonText, onSkip, onRetry }: ILootboxPurchaseFailedProps) => {
  return (
    <div className={styles['purchase-view']}>
      <div className={styles['purchase-view__main']}>
        <img src={FailedIcon} alt="Fail" />
        <p className={styles['purchase-view__main-text']}>{title}</p>
        <TypingAnimation color="red" className={styles['purchase-view__transaction']} text={text} />
      </div>

      <div className={styles['purchase-view__footer']}>
        <Button size="medium-large" color="primary" className={styles['purchase-view__button']} onClick={onRetry}>
          Try again
        </Button>
        <Button size="medium-large" color="default" className={styles['purchase-view__button']} onClick={onSkip}>
          {skipButtonText}
        </Button>
      </div>
    </div>
  );
};

export default LootboxPurchaseFailed;
