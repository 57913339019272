import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { IState } from 'models/reducers/i-state.interface';

interface IVideoBannerProps {
  id: string;
  videoAnimationThreshold?: number;
  className?: string;
  mp4VideoUrl: string;
  oggVideoUrl?: string;
  webMVideoUrl?: string;
  fallbackText?: string;
}

const VideoBanner = ({
  mp4VideoUrl,
  oggVideoUrl,
  webMVideoUrl,
  className = '',
  fallbackText = 'Your browser does not support ',
  id,
}: IVideoBannerProps) => {
  const { activeVideo } = useSelector(({ venuesCardVideo }: IState) => venuesCardVideo);
  const isCurrentVideoActive = activeVideo === id;
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    videoRef.current?.load();
  }, [mp4VideoUrl]);

  useEffect(() => {
    if (isCurrentVideoActive) {
      videoRef.current?.play();

      return;
    }

    videoRef.current?.pause();
  }, [isCurrentVideoActive, videoRef, mp4VideoUrl]);

  return (
    <>
      {isCurrentVideoActive ? (
        <video ref={videoRef} className={className} loop muted playsInline preload="auto">
          <source src={mp4VideoUrl} type="video/mp4" />
          {oggVideoUrl && <source src={oggVideoUrl} type="video/ogg" />}
          {webMVideoUrl && <source src={webMVideoUrl} type="video/webm" />}
          {fallbackText}
        </video>
      ) : (
        <></>
      )}
    </>
  );
};

export default VideoBanner;
