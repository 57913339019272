export const STAKE_CONTRACT_ABI: any[] = [
  {
    inputs: [
      { internalType: 'address', name: 'tokenAddress', type: 'address' },
      { internalType: 'uint256', name: '_defaultStakingTarget', type: 'uint256' },
      { internalType: 'uint256', name: '_defaultMinimumDeposit', type: 'uint256' },
      { internalType: 'uint256', name: '_confirmationCode', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  { inputs: [{ internalType: 'address', name: 'target', type: 'address' }], name: 'AddressEmptyCode', type: 'error' },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'AddressInsufficientBalance',
    type: 'error',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'poolID', type: 'uint256' }],
    name: 'AmountExceedsPoolTarget',
    type: 'error',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { internalType: 'uint256', name: 'depositNumber', type: 'uint256' },
    ],
    name: 'DepositDoesNotExist',
    type: 'error',
  },
  { inputs: [], name: 'FailedInnerCall', type: 'error' },
  {
    inputs: [
      { internalType: 'uint256', name: '_tokenSent', type: 'uint256' },
      { internalType: 'uint256', name: '_requiredAmount', type: 'uint256' },
    ],
    name: 'InsufficentDeposit',
    type: 'error',
  },
  {
    inputs: [
      { internalType: 'string', name: 'argument', type: 'string' },
      { internalType: 'uint256', name: 'minValue', type: 'uint256' },
    ],
    name: 'InvalidArgumentValue',
    type: 'error',
  },
  { inputs: [], name: 'NoFundsStaked', type: 'error' },
  { inputs: [], name: 'NoPoolsCreatedYet', type: 'error' },
  {
    inputs: [
      { internalType: 'uint256', name: 'requestedAmount', type: 'uint256' },
      { internalType: 'uint256', name: 'availableAmount', type: 'uint256' },
    ],
    name: 'NotEnoughFundsInTheInterestPool',
    type: 'error',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { internalType: 'uint256', name: 'requestedAmount', type: 'uint256' },
      { internalType: 'uint256', name: 'availableAmount', type: 'uint256' },
    ],
    name: 'NotEnoughFundsInThePool',
    type: 'error',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { internalType: 'string', name: '_action', type: 'string' },
    ],
    name: 'NotOpen',
    type: 'error',
  },
  { inputs: [{ internalType: 'uint256', name: 'poolID', type: 'uint256' }], name: 'PoolDoesNotExist', type: 'error' },
  { inputs: [{ internalType: 'uint256', name: 'poolID', type: 'uint256' }], name: 'PoolEnded', type: 'error' },
  { inputs: [], name: 'ReentrancyGuardReentrantCall', type: 'error' },
  {
    inputs: [
      { internalType: 'uint256', name: '_tokenSent', type: 'uint256' },
      { internalType: 'uint256', name: '_RemainingAmountToRestore', type: 'uint256' },
    ],
    name: 'RestorationExceedsCollected',
    type: 'error',
  },
  {
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
    name: 'SafeERC20FailedOperation',
    type: 'error',
  },
  {
    inputs: [{ internalType: 'enum AccessControl.AccessTier', name: 'requiredAccessTier', type: 'uint8' }],
    name: 'UnauthorizedAccess',
    type: 'error',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'user', type: 'address' }],
    name: 'AddContractAdmin',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { indexed: true, internalType: 'enum ProgramManager.PoolType', name: 'poolType', type: 'uint8' },
      { indexed: false, internalType: 'uint256', name: 'stakingTarget', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'APY', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'minimumDeposit', type: 'uint256' },
    ],
    name: 'AddStakingPool',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'by', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'depositNumber', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'tokenAmount', type: 'uint256' },
    ],
    name: 'ClaimInterest',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'by', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'tokenAmount', type: 'uint256' },
    ],
    name: 'CollectFunds',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'by', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'tokenAmount', type: 'uint256' },
    ],
    name: 'CollectInterest',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'string', name: 'stakingTokenTicker', type: 'string' },
      { indexed: false, internalType: 'address', name: 'stakingTokenAddress', type: 'address' },
      { indexed: false, internalType: 'uint256', name: '_defaultStakingTarget', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: '_defaultMinimumDeposit', type: 'uint256' },
    ],
    name: 'CreateProgram',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'uint256', name: 'poolID', type: 'uint256' }],
    name: 'EndStakingPool',
    type: 'event',
  },
  { anonymous: false, inputs: [], name: 'PauseProgram', type: 'event' },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'by', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'tokenAmount', type: 'uint256' },
    ],
    name: 'ProvideInterest',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'user', type: 'address' }],
    name: 'RemoveContractAdmin',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'by', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'tokenAmount', type: 'uint256' },
    ],
    name: 'RestoreFunds',
    type: 'event',
  },
  { anonymous: false, inputs: [], name: 'ResumeProgram', type: 'event' },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'by', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { indexed: true, internalType: 'enum ProgramManager.PoolType', name: 'poolType', type: 'uint8' },
      { indexed: false, internalType: 'uint256', name: 'depositNumber', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'tokenAmount', type: 'uint256' },
    ],
    name: 'Stake',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'from', type: 'address' },
      { indexed: false, internalType: 'address', name: 'to', type: 'address' },
    ],
    name: 'TransferOwnership',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'newAPY', type: 'uint256' },
    ],
    name: 'UpdateAPY',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'uint256', name: 'newDefaultMinimumDeposit', type: 'uint256' }],
    name: 'UpdateDefaultMinimumDeposit',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'uint256', name: 'newDefaultStakingTarget', type: 'uint256' }],
    name: 'UpdateDefaultStakingTarget',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'by', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { indexed: false, internalType: 'bool', name: 'isOpen', type: 'bool' },
    ],
    name: 'UpdateInterestClaimStatus',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'newMinimumDeposit', type: 'uint256' },
    ],
    name: 'UpdateMinimumDeposit',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'by', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { indexed: false, internalType: 'bool', name: 'isOpen', type: 'bool' },
    ],
    name: 'UpdateStakingStatus',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'newStakingTarget', type: 'uint256' },
    ],
    name: 'UpdateStakingTarget',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'by', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { indexed: false, internalType: 'bool', name: 'isOpen', type: 'bool' },
    ],
    name: 'UpdateWithdrawalStatus',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'by', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { indexed: true, internalType: 'enum ProgramManager.PoolType', name: 'poolType', type: 'uint8' },
      { indexed: false, internalType: 'uint256', name: 'depositNumber', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'tokenAmount', type: 'uint256' },
    ],
    name: 'Withdraw',
    type: 'event',
  },
  {
    inputs: [],
    name: 'STAKING_TOKEN',
    outputs: [{ internalType: 'contract IERC20Metadata', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'userAddress', type: 'address' }],
    name: 'addContractAdmin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'enum ProgramManager.PoolType', name: 'typeToSet', type: 'uint8' },
      { internalType: 'uint256', name: 'stakingTargetToSet', type: 'uint256' },
      { internalType: 'uint256', name: 'minimumDepositToSet', type: 'uint256' },
      { internalType: 'bool', name: 'stakingAvailabilityStatus', type: 'bool' },
      { internalType: 'uint256', name: 'APYToSet', type: 'uint256' },
    ],
    name: 'addStakingPoolCustom',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'enum ProgramManager.PoolType', name: 'typeToSet', type: 'uint8' },
      { internalType: 'uint256', name: 'APYToSet', type: 'uint256' },
    ],
    name: 'addStakingPoolDefault',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { internalType: 'uint256', name: 'parameterToChange', type: 'uint256' },
      { internalType: 'bool', name: 'valueToAssign', type: 'bool' },
    ],
    name: 'changePoolAvailabilityStatus',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'poolID', type: 'uint256' }],
    name: 'checkAPY',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'userAddress', type: 'address' },
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { internalType: 'uint256', name: 'depositNumber', type: 'uint256' },
    ],
    name: 'checkClaimableInterestBy',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'checkConfirmationCode',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'checkDefaultMinimumDeposit',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'checkDefaultStakingTarget',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'userAddress', type: 'address' },
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
    ],
    name: 'checkDepositCountOfAddress',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'userAddress', type: 'address' },
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { internalType: 'uint256', name: 'depositNumber', type: 'uint256' },
    ],
    name: 'checkDepositStakedAmount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'poolID', type: 'uint256' }],
    name: 'checkEndDate',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { internalType: 'bool', name: 'ifPrecise', type: 'bool' },
    ],
    name: 'checkGeneratedInterestDailyTotal',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'userAddress', type: 'address' },
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
    ],
    name: 'checkGeneratedInterestLastDayFor',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'poolID', type: 'uint256' }],
    name: 'checkIfInterestClaimOpen',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'poolID', type: 'uint256' }],
    name: 'checkIfPoolEnded',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'poolID', type: 'uint256' }],
    name: 'checkIfStakingOpen',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'poolID', type: 'uint256' }],
    name: 'checkIfWithdrawalOpen',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'userAddress', type: 'address' },
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
    ],
    name: 'checkInterestClaimedBy',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'checkInterestPool',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'userAddress', type: 'address' }],
    name: 'checkInterestProvidedBy',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'poolID', type: 'uint256' }],
    name: 'checkMinimumDeposit',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'checkPoolCount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'poolID', type: 'uint256' }],
    name: 'checkPoolType',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'userAddress', type: 'address' },
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
    ],
    name: 'checkRestoredFundsBy',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'userAddress', type: 'address' },
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
    ],
    name: 'checkStakedAmountBy',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'poolID', type: 'uint256' }],
    name: 'checkStakingTarget',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'poolID', type: 'uint256' }],
    name: 'checkTotalClaimableInterest',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'userAddress', type: 'address' },
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
    ],
    name: 'checkTotalClaimableInterestBy',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'poolID', type: 'uint256' }],
    name: 'checkTotalFundCollected',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'poolID', type: 'uint256' }],
    name: 'checkTotalFundRestored',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'poolID', type: 'uint256' }],
    name: 'checkTotalInterestClaimed',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'poolID', type: 'uint256' }],
    name: 'checkTotalStaked',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'poolID', type: 'uint256' }],
    name: 'checkTotalWithdrawn',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'userAddress', type: 'address' },
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
    ],
    name: 'checkWithdrawnAmountBy',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'poolID', type: 'uint256' }],
    name: 'claimAllInterest',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { internalType: 'uint256', name: 'depositNumber', type: 'uint256' },
    ],
    name: 'claimInterest',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenAmount', type: 'uint256' },
    ],
    name: 'collectFunds',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'tokenAmount', type: 'uint256' }],
    name: 'collectInterestPoolFunds',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'contractAdmins',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'contractOwner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { internalType: 'uint256', name: '_confirmationCode', type: 'uint256' },
    ],
    name: 'endStakingPool',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { inputs: [], name: 'pauseProgram', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [{ internalType: 'uint256', name: 'tokenAmount', type: 'uint256' }],
    name: 'provideInterest',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'userAddress', type: 'address' }],
    name: 'removeContractAdmin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenAmount', type: 'uint256' },
    ],
    name: 'restoreFunds',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { inputs: [], name: 'resumeProgram', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [{ internalType: 'uint256', name: 'newDefaultMinimumDeposit', type: 'uint256' }],
    name: 'setDefaultMinimumDeposit',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'newStakingTarget', type: 'uint256' }],
    name: 'setDefaultStakingTarget',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { internalType: 'uint256', name: 'newAPY', type: 'uint256' },
    ],
    name: 'setPoolAPY',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { internalType: 'uint256', name: 'newMinimumDeposit', type: 'uint256' },
    ],
    name: 'setPoolMiniumumDeposit',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { internalType: 'uint256', name: 'newStakingTarget', type: 'uint256' },
    ],
    name: 'setPoolStakingTarget',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenAmount', type: 'uint256' },
    ],
    name: 'stakeToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'userAddress', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'poolID', type: 'uint256' }],
    name: 'withdrawAll',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'poolID', type: 'uint256' },
      { internalType: 'uint256', name: 'depositNumber', type: 'uint256' },
    ],
    name: 'withdrawDeposit',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
