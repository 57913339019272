import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { twMerge } from 'tailwind-merge';
import Web3Singleton from 'web3Singleton';

import { IState } from 'models/reducers/i-state.interface';

import { ERROR_MESSAGES } from 'constants/error-messages.constant';

import { addErrorMessageAction } from 'actions/global-actions';

import Button from 'components/buttons/Button';
import ElementLoader from 'components/element-loader/ElementLoader';
import ModalPortal from 'components/modal/ModalPortal';

import { ReactComponent as HintIcon } from 'assets/icons/hint-icon.svg';

import GameConfirmationModal from '../gameConfirmationModal/GameConfirmationModal';
import GameLabelPanel from '../gameLabelPanel/GameLabelPanel';
import GameStakingCard from '../gameStakingCard/GameStakingCard';
import GameValue from '../gameValue/GameValue';
import { IGameStakingAmount } from '../types';

interface IGameStakingBalanceProps extends IGameStakingAmount {
  loading: boolean;
  fee: string;
  poolOpen: boolean;
  poolEnded: boolean;
  apy: string;
  remaining: string;
}

const GameStakingBalance = ({
  loading,
  staked_amount,
  currency,
  poolId,
  flexible,
  negative,
  fee,
  poolOpen,
  poolEnded,
  apy,
  remaining,
  onCallback,
}: IGameStakingBalanceProps) => {
  const { t } = useTranslation('notifications');
  const dispatch = useDispatch();
  const { correctChain } = useSelector(({ web3 }: IState) => web3);
  const walletAddress = useSelector((state: IState): string => state.wallet.address);
  const lgoWeb3 = Web3Singleton.getInstance();

  const estDailyInterest = (+staked_amount * (+apy / 365)) / 100;
  const feeAmount = +staked_amount / ((100 - +fee) / 100) - +staked_amount;

  const [withdrawAllModal, onToggleWithdrawAllModal] = useToggle(false);
  const [transactionHash, setTransactionHash] = useState<string | undefined>(undefined);

  const handleClose = () => {
    onToggleWithdrawAllModal(false);
    setTransactionHash(undefined);
  };

  const handleWithdrawAll = async () => {
    try {
      await lgoWeb3.withdrawAll(walletAddress, poolId, negative, flexible, {
        onTransactionHash: setTransactionHash,
        onConfirmation: () => {
          onCallback(poolId);
          handleClose();
        },
      });
    } catch (e: any) {
      console.error(e);
      if (e.code === 4001) {
        dispatch(addErrorMessageAction(t(ERROR_MESSAGES.purchaseCanceled)));
      } else {
        dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
      }
      handleClose();
    }
  };

  const handleClick = () => {
    onToggleWithdrawAllModal();
    handleWithdrawAll();
  };

  return (
    <>
      <GameStakingCard title="My staked balance">
        <ElementLoader className="tw-rounded-2xl" loading={loading}>
          {correctChain && Boolean(+staked_amount) ? (
            <div className="tw-flex tw-flex-col tw-gap-5">
              <div
                className={twMerge(
                  'tw-flex tw-flex-col tw-gap-2.5 xl:tw-flex-wrap',
                  flexible ? 'xl:tw-flex-col' : 'xl:tw-flex-row'
                )}
              >
                <GameLabelPanel label="Total staked" className={twMerge(flexible && 'xl:tw-w-full')}>
                  <GameValue currency={currency!} value={staked_amount} />
                </GameLabelPanel>
                {poolOpen &&
                  (negative || flexible ? (
                    <>
                      <GameLabelPanel label="Development pool" className={twMerge(flexible && 'xl:tw-w-full')}>
                        <GameValue currency={currency!} value={feeAmount.toString()} />
                      </GameLabelPanel>
                    </>
                  ) : (
                    <>
                      <GameLabelPanel label="Remaining pool">
                        <GameValue currency={currency!} value={remaining} />
                      </GameLabelPanel>
                      <GameLabelPanel label="APY">
                        <span className="tw-text-2xl/[28px] tw-text-white">{apy}%</span>
                      </GameLabelPanel>
                      <GameLabelPanel label="Est. Interest per day">
                        <GameValue currency={currency!} value={estDailyInterest.toString()} />
                      </GameLabelPanel>
                    </>
                  ))}
              </div>
              {flexible ? (
                <>
                  <div className="tw-flex tw-flex-row tw-gap-2.5">
                    <HintIcon className="tw-w-8 tw-text-orangeLight" />
                    <p className="tw-text-white">
                      If you withdraw, you will only get back your net staking amount. Any tokens sent to the
                      development pool will not be returned.
                    </p>
                  </div>
                  <Button
                    className="tw-ml-auto tw-w-fit"
                    size="medium"
                    ariaLabel="Withdraw"
                    onClick={handleClick}
                    disabled={!poolEnded || !Boolean(+staked_amount)}
                    requireWeb3
                  >
                    Withdraw
                  </Button>
                </>
              ) : poolOpen ? (
                <p className="tw-text-white">You will be able to withdraw deposit only after the game is released.</p>
              ) : (
                <Button
                  className="tw-ml-auto tw-w-fit"
                  size="medium"
                  ariaLabel="Withdraw"
                  onClick={handleClick}
                  disabled={!Boolean(+staked_amount)}
                  requireWeb3
                >
                  Withdraw all
                </Button>
              )}
            </div>
          ) : (
            <p className="tw-text-white">You currently don’t have anything in the staking pool.</p>
          )}
        </ElementLoader>
      </GameStakingCard>
      {withdrawAllModal && (
        <ModalPortal title="Withdraw stake" onClose={onToggleWithdrawAllModal}>
          <GameConfirmationModal title="Confirm withdraw" transactionHash={transactionHash} />
        </ModalPortal>
      )}
    </>
  );
};

export default GameStakingBalance;
