export const DAILY_DEALS_MOCK = [
  {
    asset: {
      name: 'Notre-Dame',
      token_id: '1425',
      id: 133,
      description: 'Notre-Dame',
      country_code: 'FR',
    },
    package_type: 'LANDMARK',
    valid_from: 1695549600,
    valid_until: 1695636000,
    items: [
      {
        amount: 25,
        price: 93000,
        currency: {
          id: 6,
          network: 4,
          name: 'RMV',
          symbol: 'RMV',
          address: '0x183070C90B34A63292cC908Ce1b263Cb56D49A7F',
          decimal: 18,
        },
        all_sales: [
          {
            id: 40401,
            asset_id: 210,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1697,
            expiration_time: 1695636000,
            price: '93000000000000000000000',
            amount: '25',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
        ],
      },
      {
        amount: 5,
        price: 20650,
        currency: {
          id: 6,
          network: 4,
          name: 'RMV',
          symbol: 'RMV',
          address: '0x183070C90B34A63292cC908Ce1b263Cb56D49A7F',
          decimal: 18,
        },
        all_sales: [
          {
            id: 40389,
            asset_id: 210,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1693,
            expiration_time: 1695636000,
            price: '20650000000000000000000',
            amount: '5',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
          {
            id: 40392,
            asset_id: 210,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1694,
            expiration_time: 1695636000,
            price: '20650000000000000000000',
            amount: '5',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
          {
            id: 40395,
            asset_id: 210,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1695,
            expiration_time: 1695636000,
            price: '20650000000000000000000',
            amount: '5',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
        ],
      },
      {
        amount: 10,
        price: 39000,
        currency: {
          id: 6,
          network: 4,
          name: 'RMV',
          symbol: 'RMV',
          address: '0x183070C90B34A63292cC908Ce1b263Cb56D49A7F',
          decimal: 18,
        },
        all_sales: [
          {
            id: 40398,
            asset_id: 210,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1696,
            expiration_time: 1695636000,
            price: '39000000000000000000000',
            amount: '10',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
        ],
      },
      {
        amount: 1,
        price: 4350,
        currency: {
          id: 6,
          network: 4,
          name: 'RMV',
          symbol: 'RMV',
          address: '0x183070C90B34A63292cC908Ce1b263Cb56D49A7F',
          decimal: 18,
        },
        all_sales: [
          {
            id: 40374,
            asset_id: 210,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1688,
            expiration_time: 1695636000,
            price: '4350000000000000000000',
            amount: '1',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
          {
            id: 40377,
            asset_id: 210,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1689,
            expiration_time: 1695636000,
            price: '4350000000000000000000',
            amount: '1',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
          {
            id: 40380,
            asset_id: 210,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1690,
            expiration_time: 1695636000,
            price: '4350000000000000000000',
            amount: '1',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
          {
            id: 40383,
            asset_id: 210,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1691,
            expiration_time: 1695636000,
            price: '4350000000000000000000',
            amount: '1',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
          {
            id: 40386,
            asset_id: 210,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1692,
            expiration_time: 1695636000,
            price: '4350000000000000000000',
            amount: '1',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
        ],
      },
    ],
  },
  {
    asset: {
      name: 'Costa Rica',
      token_id: '501',
      id: 132,
      description: 'Costa Rica',
      country_code: 'CR',
    },
    package_type: 'COUNTRY',
    valid_from: 1695549600,
    valid_until: 1695636000,
    items: [
      {
        amount: 25,
        price: 30500,
        currency: {
          id: 6,
          network: 4,
          name: 'RMV',
          symbol: 'RMV',
          address: '0x183070C90B34A63292cC908Ce1b263Cb56D49A7F',
          decimal: 18,
        },
        all_sales: [
          {
            id: 40371,
            asset_id: 261,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1687,
            expiration_time: 1695636000,
            price: '30500000000000000000000',
            amount: '25',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
        ],
      },
      {
        amount: 10,
        price: 12750,
        currency: {
          id: 6,
          network: 4,
          name: 'RMV',
          symbol: 'RMV',
          address: '0x183070C90B34A63292cC908Ce1b263Cb56D49A7F',
          decimal: 18,
        },
        all_sales: [
          {
            id: 40365,
            asset_id: 261,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1685,
            expiration_time: 1695636000,
            price: '12750000000000000000000',
            amount: '10',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
          {
            id: 40368,
            asset_id: 261,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1686,
            expiration_time: 1695636000,
            price: '12750000000000000000000',
            amount: '10',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
        ],
      },
      {
        amount: 5,
        price: 6750,
        currency: {
          id: 6,
          network: 4,
          name: 'RMV',
          symbol: 'RMV',
          address: '0x183070C90B34A63292cC908Ce1b263Cb56D49A7F',
          decimal: 18,
        },
        all_sales: [
          {
            id: 40359,
            asset_id: 261,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1683,
            expiration_time: 1695636000,
            price: '6750000000000000000000',
            amount: '5',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
          {
            id: 40362,
            asset_id: 261,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1684,
            expiration_time: 1695636000,
            price: '6750000000000000000000',
            amount: '5',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
        ],
      },
      {
        amount: 1,
        price: 1425,
        currency: {
          id: 6,
          network: 4,
          name: 'RMV',
          symbol: 'RMV',
          address: '0x183070C90B34A63292cC908Ce1b263Cb56D49A7F',
          decimal: 18,
        },
        all_sales: [
          {
            id: 40344,
            asset_id: 261,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1678,
            expiration_time: 1695636000,
            price: '1425000000000000000000',
            amount: '1',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
          {
            id: 40347,
            asset_id: 261,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1679,
            expiration_time: 1695636000,
            price: '1425000000000000000000',
            amount: '1',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
          {
            id: 40350,
            asset_id: 261,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1680,
            expiration_time: 1695636000,
            price: '1425000000000000000000',
            amount: '1',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
          {
            id: 40353,
            asset_id: 261,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1681,
            expiration_time: 1695636000,
            price: '1425000000000000000000',
            amount: '1',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
          {
            id: 40356,
            asset_id: 261,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1682,
            expiration_time: 1695636000,
            price: '1425000000000000000000',
            amount: '1',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
        ],
      },
    ],
  },
  {
    asset: {
      name: 'Washington',
      token_id: '2229',
      id: 124,
      description: 'Washington',
      country_code: 'US',
    },
    package_type: 'CITY',
    valid_from: 1695549600,
    valid_until: 1695636000,
    items: [
      {
        amount: 5,
        price: 20750,
        currency: {
          id: 6,
          network: 4,
          name: 'RMV',
          symbol: 'RMV',
          address: '0x183070C90B34A63292cC908Ce1b263Cb56D49A7F',
          decimal: 18,
        },
        all_sales: [
          {
            id: 40329,
            asset_id: 303,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1673,
            expiration_time: 1695636000,
            price: '20750000000000000000000',
            amount: '5',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
          {
            id: 40332,
            asset_id: 303,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1674,
            expiration_time: 1695636000,
            price: '20750000000000000000000',
            amount: '5',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
        ],
      },
      {
        amount: 25,
        price: 93500,
        currency: {
          id: 6,
          network: 4,
          name: 'RMV',
          symbol: 'RMV',
          address: '0x183070C90B34A63292cC908Ce1b263Cb56D49A7F',
          decimal: 18,
        },
        all_sales: [
          {
            id: 40341,
            asset_id: 303,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1677,
            expiration_time: 1695636000,
            price: '93500000000000000000000',
            amount: '25',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
        ],
      },
      {
        amount: 1,
        price: 4400,
        currency: {
          id: 6,
          network: 4,
          name: 'RMV',
          symbol: 'RMV',
          address: '0x183070C90B34A63292cC908Ce1b263Cb56D49A7F',
          decimal: 18,
        },
        all_sales: [
          {
            id: 40314,
            asset_id: 303,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1668,
            expiration_time: 1695636000,
            price: '4400000000000000000000',
            amount: '1',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
          {
            id: 40317,
            asset_id: 303,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1669,
            expiration_time: 1695636000,
            price: '4400000000000000000000',
            amount: '1',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
          {
            id: 40320,
            asset_id: 303,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1670,
            expiration_time: 1695636000,
            price: '4400000000000000000000',
            amount: '1',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
          {
            id: 40323,
            asset_id: 303,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1671,
            expiration_time: 1695636000,
            price: '4400000000000000000000',
            amount: '1',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
          {
            id: 40326,
            asset_id: 303,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1672,
            expiration_time: 1695636000,
            price: '4400000000000000000000',
            amount: '1',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
        ],
      },
      {
        amount: 10,
        price: 39500,
        currency: {
          id: 6,
          network: 4,
          name: 'RMV',
          symbol: 'RMV',
          address: '0x183070C90B34A63292cC908Ce1b263Cb56D49A7F',
          decimal: 18,
        },
        all_sales: [
          {
            id: 40335,
            asset_id: 303,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1675,
            expiration_time: 1695636000,
            price: '39500000000000000000000',
            amount: '10',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
          {
            id: 40338,
            asset_id: 303,
            seller: {
              id: 38067,
              address: '',
              last_login: '2023-09-20T13:25:44.830541Z',
              date_joined: '2023-07-18T12:04:00Z',
            },
            type: 6,
            status: 0,
            contract_type: 0,
            signature: '',
            signature_hash: '',
            tx_hash: null,
            nonce: 1676,
            expiration_time: 1695636000,
            price: '39500000000000000000000',
            amount: '10',
            state_hash: '0x0',
            auction_id: null,
            stable_exchange_rate: null,
            currency_id: 6,
          },
        ],
      },
    ],
  },
];
