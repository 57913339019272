import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

import { useElementOnScreen } from '../hooks/useElementOnScreen';
import { useSectionRefs } from '../hooks/useSectionRefs';

type SectionRefs = {
  [key: string]: React.MutableRefObject<HTMLElement | null>;
};

type ScrollProviderProps = {
  children: ReactNode;
};

type ScrollContextProps = {
  activeScrollSection: string | null;
  sectionRefs: SectionRefs;
  executeScroll: (section: string) => void;
  resetActiveScrollSection: () => void;
  setActiveScrollSection: (section: string) => void;
};

export const ScrollContext = createContext<ScrollContextProps>({
  activeScrollSection: null,
  sectionRefs: {},
  executeScroll: () => {},
  resetActiveScrollSection: () => {},
  setActiveScrollSection: (section: string) => {},
});

export const ScrollProvider = ({ children }: ScrollProviderProps) => {
  const sectionRefs = useSectionRefs();
  const [activeScrollSection, setActiveScrollSection] = useState<string | null>(null);

  const executeScroll = useCallback(
    (section: string) => {
      const ref = sectionRefs[section];

      ref.current?.scrollIntoView({ behavior: 'smooth' });
    },
    [sectionRefs]
  );

  const resetActiveScrollSection = () => {
    setActiveScrollSection(null);
  };

  return (
    <ScrollContext.Provider
      value={{ sectionRefs, activeScrollSection, executeScroll, setActiveScrollSection, resetActiveScrollSection }}
    >
      {children}
    </ScrollContext.Provider>
  );
};

export const useScrollContext = (): ScrollContextProps => useContext(ScrollContext);
