import { useMetamaskAddPolygon } from '../../../../hooks/metamask/useMetamaskAddPolygon';

import Metamask from '../../../../assets/logos/metamask.svg';

const GameOnboardingInstructionAddNetwork = () => {
  const addPolygon = useMetamaskAddPolygon();

  return (
    <div>
      <p>
        In order to add Polygon network to your Metamask make sure that you have already installed Metamask app and
        opened this website in it or you have installed the browser extension on desktop.
      </p>

      <button
        className="tw-mx-auto tw-flex tw-cursor-pointer tw-items-center tw-rounded-md tw-border-0 tw-bg-white tw-px-[16px] tw-py-[8px] tw-font-bold tw-uppercase tw-text-[#091F4E] tw-outline-0"
        onClick={addPolygon}
      >
        <img src={Metamask} alt="Metamask" className="tw-mr-1 tw-w-[32px]" /> add Polygon network
      </button>
    </div>
  );
};

export default GameOnboardingInstructionAddNetwork;
