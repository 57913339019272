import { createContext, useContext } from 'react';

import { Noop } from 'types/common.interface';

import { INftCardProps } from '../nftCard/NftCard';

interface INftCardContextProps extends INftCardProps {
  height: number | undefined;
  toggled: boolean;
  onToggle: Noop;
}

export const NftCardContext = createContext<INftCardContextProps>({
  size: undefined,
  height: undefined,
  asset: undefined,
  toggled: false,
  onToggle: () => {},
  onCallback: () => {},
  loading: undefined,
});

export const useNftCardContext = () => useContext(NftCardContext);
