import { useEffect, useRef, useState } from 'react';
import { useToggle } from 'react-use';

import { Noop } from 'types/common.interface';

import { useElementOnScreen } from 'hooks/useElementOnScreen';

import { ReactComponent as Pause } from 'assets/pause.svg';
import { ReactComponent as Play } from 'assets/play.svg';

import styles from './VideoBanner.module.scss';

interface IVideoBannerProps {
  className?: string;
  videoAnimationThreshold?: number;
  mp4VideoUrl?: string;
  oggVideoUrl?: string;
  webMVideoUrl?: string;
  poster?: string | undefined;
  loop?: boolean;
  muted?: boolean;
  controls?: boolean;
  fallbackText?: string;
  onInitialized?: () => void;
}

const PlayButton = ({ paused, onClick }: { paused?: boolean; onClick: Noop }) => {
  return (
    <div
      className="tw-absolute tw-left-1/2 tw-top-1/2 -tw-z-10 tw-flex tw-h-20 tw-w-20 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full tw-bg-[#2850A599] tw-opacity-0 tw-transition-opacity tw-duration-300 [transform:translate(-50%,-50%)] group-hover:tw-z-10 group-hover:tw-opacity-100"
      onClick={onClick}
    >
      {paused ? <Play /> : <Pause />}
    </div>
  );
};

const VideoBanner = ({
  mp4VideoUrl,
  oggVideoUrl,
  webMVideoUrl,
  poster,
  loop = true,
  muted = true,
  controls = false,
  className = '',
  fallbackText = 'Your browser does not support ',
  videoAnimationThreshold = 0,
  onInitialized,
}: IVideoBannerProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const onScreen = useElementOnScreen(videoRef, videoAnimationThreshold);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const classNames = [className, !controls && styles['video-banner']];

  const handlePlay = (condition: boolean) => {
    const video = videoRef.current;

    if (!video) return;

    if (condition) {
      video.play();
    } else {
      video.pause();
    }
  };

  useEffect(() => {
    videoRef.current?.addEventListener('loadedmetadata', () => handlePlay(onScreen));
    return () => {
      videoRef.current?.removeEventListener('loadedmetadata', () => handlePlay(onScreen));
    };
  }, [onScreen]);

  const hasVideo = !!mp4VideoUrl || !!oggVideoUrl || !!webMVideoUrl;

  useEffect(() => {
    videoRef.current?.load();
  }, [hasVideo]);

  const onVideoLoaded = () => {
    if (isInitialized || !onInitialized) {
      return;
    }

    setIsInitialized(true);
    onInitialized();
  };

  return (
    <>
      {hasVideo ? (
        <video
          ref={videoRef}
          className={classNames.join(' ')}
          loop={loop}
          muted={muted}
          playsInline
          controls={controls}
          preload="auto"
          onLoadedData={onVideoLoaded}
          onLoadedMetadata={onVideoLoaded}
        >
          {mp4VideoUrl && <source src={mp4VideoUrl} type="video/mp4" />}
          {oggVideoUrl && <source src={oggVideoUrl} type="video/ogg" />}
          {webMVideoUrl && <source src={webMVideoUrl} type="video/webm" />}
          {fallbackText}
        </video>
      ) : (
        <img src={poster} alt="poster" className={className} />
      )}
    </>
  );
};

export default VideoBanner;
