import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Noop } from 'types/common.interface';

import button from 'assets/wheel-of-fortune/button.png';
import lamps from 'assets/wheel-of-fortune/lamps.svg';
import pointer from 'assets/wheel-of-fortune/pointer.svg';
import rmv from 'assets/wheel-of-fortune/rmv.png';

import { getQuantity, getRotationDegrees } from '../utils';
import WheelOfFortuneCanvas from '../WheelOfFortuneCanvas/WheelOfFortuneCanvas';
import { RotationContainer, RouletteContainer } from './styles';
import { WheelData } from './types';

interface WheelProps {
  loading: boolean;
  prizeNumber: number | undefined;
  data: WheelData[];
  onSpin: Noop;
  onStopSpinning: Noop;
  disabled: boolean;
}

const FONT_SIZE = 30;
const FONT_WEIGHT = '500';
const FONT_STYLE = 'normal';
const TEXT_DISTANCE = 80;
const SPIN_DURATION = 1.0;

const STARTED_SPINNING = 'started-spinning';

const START_SPINNING_TIME = 2600;
const CONTINUE_SPINNING_TIME = 750;
const STOP_SPINNING_TIME = 8000;

const WheelOfFortune = ({ loading, prizeNumber, data, onSpin, onStopSpinning, disabled }: WheelProps) => {
  const [wheelData, setWheelData] = useState<WheelData[]>([...data]);
  const [prizeMap, setPrizeMap] = useState<number[][]>([[0]]);
  const [startRotationDegrees, setStartRotationDegrees] = useState(15);
  const [finalRotationDegrees, setFinalRotationDegrees] = useState(0);
  const [hasStartedSpinning, setHasStartedSpinning] = useState(false);
  const [hasStoppedSpinning, setHasStoppedSpinning] = useState(false);
  const [isCurrentlySpinning, setIsCurrentlySpinning] = useState(false);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [rouletteUpdater, setRouletteUpdater] = useState(false);
  const [loadedImagesCounter, setLoadedImagesCounter] = useState(0);
  const [totalImages, setTotalImages] = useState(0);
  const mustStopSpinning = useRef<boolean>(false);

  const normalizedSpinDuration = Math.max(0.01, SPIN_DURATION);

  const startSpinningTime = START_SPINNING_TIME * normalizedSpinDuration;
  const continueSpinningTime = CONTINUE_SPINNING_TIME * normalizedSpinDuration;
  const stopSpinningTime = STOP_SPINNING_TIME * normalizedSpinDuration;

  const totalSpinningTime = startSpinningTime + continueSpinningTime + stopSpinningTime;

  useEffect(() => {
    let initialMapNum = 0;
    const auxPrizeMap: number[][] = [];
    const dataLength = data?.length || 0;
    const wheelDataAux = [{ option: '', optionSize: 1 }] as WheelData[];

    for (let i = 0; i < dataLength; i++) {
      wheelDataAux[i] = {
        ...data[i],
        textColor: hasStoppedSpinning && i === prizeNumber ? '#6dfbff' : '#fff',
      };
      auxPrizeMap.push([]);

      for (let j = 0; j < (wheelDataAux[i].optionSize || 1); j++) {
        auxPrizeMap[i][j] = initialMapNum++;
      }

      setTotalImages((prevCounter) => prevCounter + 1);

      const coin = {
        uri: rmv,
        offset: -100,
        size: 80,
      };

      const img = new Image();
      img.src = coin.uri;
      img.onload = () => {
        img.height = coin.size;
        img.width = coin.size;
        wheelDataAux[i].image = { ...coin, _imageHTML: img };
        setLoadedImagesCounter((prevCounter) => prevCounter + 1);
        setRouletteUpdater((prevState) => !prevState);
      };
    }
    setWheelData([...wheelDataAux]);
    setPrizeMap(auxPrizeMap);
    setIsDataUpdated(true);
  }, [data]);

  useEffect(() => {
    if (prizeNumber && !isCurrentlySpinning) {
      setIsCurrentlySpinning(true);
      startSpinning();

      const finalRotationDegreesCalculated = getRotationDegrees(prizeNumber, getQuantity(prizeMap));
      setFinalRotationDegrees(finalRotationDegreesCalculated);
    }
  }, [prizeNumber]);

  useEffect(() => {
    if (hasStoppedSpinning) {
      setIsCurrentlySpinning(false);
      setStartRotationDegrees(finalRotationDegrees);
    }
  }, [hasStoppedSpinning]);

  const startSpinning = () => {
    setHasStartedSpinning(true);
    setHasStoppedSpinning(false);
    mustStopSpinning.current = true;

    setTimeout(() => {
      if (mustStopSpinning.current) {
        mustStopSpinning.current = false;
        setHasStartedSpinning(false);
        setHasStoppedSpinning(true);
        onStopSpinning();
      }
    }, totalSpinningTime);
  };

  const getRouletteClass = () => {
    if (hasStartedSpinning) {
      return STARTED_SPINNING;
    }
    return '';
  };

  if (!isDataUpdated) {
    return null;
  }

  return (
    <>
      <button
        onClick={onSpin}
        className={twMerge(
          'tw-absolute tw-left-[calc(50%_-_40px)] tw-top-[calc(50%_-_40px)] tw-z-30 tw-h-20 tw-w-20 tw-rounded-full tw-border-none tw-bg-[length:110px] tw-bg-center tw-text-2xl tw-font-bold tw-text-white md:tw-left-[calc(50%_-_56px)] md:tw-top-[calc(50%_-_56px)] md:tw-h-28 md:tw-w-28 md:tw-bg-[length:152px] md:tw-text-4xl',
          !disabled && 'tw-cursor-pointer'
        )}
        disabled={disabled || isCurrentlySpinning}
        style={{ backgroundImage: `url(${button})` }}
      >
        GO
      </button>
      <RouletteContainer
        style={loading || (totalImages > 0 && loadedImagesCounter !== totalImages) ? { visibility: 'hidden' } : {}}
      >
        <RotationContainer
          className={getRouletteClass()}
          classKey={crypto.randomUUID()}
          startSpinningTime={startSpinningTime}
          continueSpinningTime={continueSpinningTime}
          stopSpinningTime={stopSpinningTime}
          startRotationDegrees={startRotationDegrees}
          finalRotationDegrees={finalRotationDegrees}
        >
          <WheelOfFortuneCanvas
            width="900"
            height="900"
            data={wheelData}
            fontWeight={FONT_WEIGHT}
            fontStyle={FONT_STYLE}
            fontSize={FONT_SIZE}
            prizeMap={prizeMap}
            rouletteUpdater={rouletteUpdater}
            textDistance={TEXT_DISTANCE}
          />
        </RotationContainer>
        <img
          src={pointer}
          alt="pointer"
          className="tw-absolute tw-left-[calc(50%_+_-16px)] tw-top-[calc(50%_-_62px)] tw-w-8 md:tw-left-[calc(50%_+_-24px)] md:tw-top-[calc(50%_-_94px)] md:tw-w-12"
        />
        <div
          className="tw-absolute tw-inset-0 tw-bg-cover tw-bg-center tw-bg-no-repeat"
          style={{ backgroundImage: `url(${lamps})` }}
        />
      </RouletteContainer>
    </>
  );
};

export default WheelOfFortune;
