import { ROUTES } from 'constants/routes.constant';
import { STORE_URL } from 'constants/store-urls.constant';

import Game1 from 'assets/games/1.png';
import Game2 from 'assets/games/2.png';
import Game3 from 'assets/games/3.png';
import Game4 from 'assets/games/4.png';
import Game5 from 'assets/games/5.png';
import Game6 from 'assets/games/6.png';
import Game7 from 'assets/games/7.png';
import Game8 from 'assets/games/8.png';
import Game9 from 'assets/games/9.png';
import GameBanner1 from 'assets/games/banner/1.png';
import GameBanner4 from 'assets/games/banner/4.png';
import Game1Thumbnail1 from 'assets/games/thumbnails/1/1.png';
import Game2Thumbnail1 from 'assets/games/thumbnails/2/1.png';
import Game2Thumbnail2 from 'assets/games/thumbnails/2/2.png';
import Game2Thumbnail3 from 'assets/games/thumbnails/2/3.png';
import Game2Thumbnail4 from 'assets/games/thumbnails/2/4.png';
import Game2Thumbnail5 from 'assets/games/thumbnails/2/5.png';
import Game3Thumbnail1 from 'assets/games/thumbnails/3/1.png';
import Game3Thumbnail2 from 'assets/games/thumbnails/3/2.png';
import Game3Thumbnail3 from 'assets/games/thumbnails/3/3.png';
import Game3Thumbnail4 from 'assets/games/thumbnails/3/4.png';
import Game3Thumbnail5 from 'assets/games/thumbnails/3/5.png';
import Game4Thumbnail1 from 'assets/games/thumbnails/4/1.png';
import Game5Thumbnail1 from 'assets/games/thumbnails/5/1.png';
import Game7Thumbnail2 from 'assets/games/thumbnails/7/2.png';
import Game7Thumbnail3 from 'assets/games/thumbnails/7/3.png';
import Game7Thumbnail4 from 'assets/games/thumbnails/7/4.png';
import Game7Thumbnail5 from 'assets/games/thumbnails/7/5.png';
import Game7Thumbnail6 from 'assets/games/thumbnails/7/6.png';
import Game7Thumbnail7 from 'assets/games/thumbnails/7/7.png';
import Game9Thumbnail1 from 'assets/games/thumbnails/9/1.png';
import Game9Thumbnail2 from 'assets/games/thumbnails/9/2.png';
import Game9Thumbnail3 from 'assets/games/thumbnails/9/3.png';
import Game9Thumbnail4 from 'assets/games/thumbnails/9/4.png';
import GameVideo1 from 'assets/games/video/1.mp4';
import GameVideo2 from 'assets/games/video/2.mp4';
import GameVideo3 from 'assets/games/video/3.mp4';
import GameVideo7 from 'assets/games/video/7.mp4';

import { GameLinkType, IGameLinkProps } from '../gameLink/GameLink';

enum GameTypeTag {
  Strategy = 'Strategy',
  Tycoon = 'Tycoon',
  Simulation = 'Simulation',
  Web3 = 'Web 3.0',
  Rpg = 'RPG',
  Action = 'Action',
  Shooter = 'Shooter',
  Incubator = 'Incubator',
  Telegram = 'Telegram',
  CityBuilder = 'City Builder',
}

export enum GameStatusTag {
  Live = 'Live',
  ComingSoon = 'Coming Soon',
  StakingOpen = 'Staking Open',
  StakingClosed = 'Game development started',
}

export interface IGame {
  title: string;
  image: string;
  invest: boolean; // means that game has possibility to invest in/stake, which mean it is not live yet
  negative?: boolean; // means that stake type is negative
  flexible?: boolean; // means that stake type is flexible
  multiplier?: number; // new token multiplier
  type: GameTypeTag[];
  status: GameStatusTag; // static tag at this point, later it will be checked if staking is open
  open: boolean; // static value for open/closed staking
  presentation?: string;
  links: IGameLinkProps[];
  href: string;
  media?: { poster?: string; video?: string };
  thumbnails?: string[]; // game poster is also always a 1st thumbnail, but left this way in case it wasn't in the future
  description?: string;
  poolId?: number;
}

export const STAKING_BENEFITS: string[] = [
  'Early Access',
  'Exclusive Features available only for Stake Holders',
  'Limited Edition NFTs',
  'Token Airdrops',
  'Exclusive Community Benefits',
  'VIP Status',
  'Limited Time events',
  'Exclusive tournaments and leaderboards',
  'Access to developer insights',
];

export const X_GAME_FEATURES = [
  { title: 'City Building', description: 'Construct and upgrade buildings using iconic landmarks.' },
  {
    title: 'Service Management',
    description:
      'Enhance your city with services like garbage disposal, sports facilities, and international airports.',
  },
  {
    title: 'Daily Tasks',
    description: 'Complete tasks to earn incredible rewards.',
  },
  {
    title: 'Real Prizes',
    description: 'Participate in daily content to win real prizes and instant rewards.',
  },
  { title: 'Airdrop Eligibility', description: 'All players are eligible for an exclusive airdrop.' },
  { title: 'Massive Rewards', description: 'Earn massive rewards for inviting your friends to join the game!' },
];

export const X_GAME_STAKING_BENEFITS = [
  {
    title: 'Early Access',
    description: 'Gain early access to the game and start building your city before anyone else.',
  },
  {
    title: 'Standard Lootbox',
    description: 'For every 1,500 RMV staked, receive a lootbox with in-game items that boost city development.',
  },
  {
    title: 'Premium Lootbox',
    description:
      'For every 15,000 RMV staked, get a premium lootbox with higher chances of obtaining top-tier in-game items.',
  },
  { title: 'Reality NFT Lootbox', description: 'For 50,000 RMV staked, unlock a special Reality NFT lootbox.' },
  {
    title: 'Sweepstakes Tickets',
    description: 'For every 5,000 RMV staked, receive 3 tickets for in-game sweepstakes.',
  },
  {
    title: 'Genesis Mint Whitelist',
    description: 'For 200,000 RMV staked, secure a spot on the Game X Genesis Mint whitelist.',
  },
];

export const X_GAME_STAKING_REWARDS = [
  { title: '50 Standard In-Game Lootboxes', description: '' },
  { title: '5 Premium In-Game Lootboxes', description: '' },
  { title: '1 Reality NFT Lootbox', description: '' },
  { title: '15x3 Tickets for In-Game Sweepstakes', description: 'that allow for real-value prizes!' },
];

export const FLEXIBLE_STAKING_BENEFITS = [
  { title: 'Monthly Airdrops', description: 'Receive Reality Digital Assets every month.' },
  {
    title: 'Limited Edition Avatars',
    description: 'Unique avatars only for stakeholders.',
  },
  {
    title: 'Minting Airdrop',
    description: 'Get tokens equal to your stake when $StreetVers is minted.',
  },
  {
    title: 'Pre-Launch NFTs',
    description: 'Exclusive StreetVers NFTs before the game launches.',
  },
  { title: 'Early Access', description: 'Play the game before anyone else.' },
  { title: 'VIP Status', description: 'Special in-game privileges and recognition.' },
  { title: 'Community Perks:', description: 'Enjoy exclusive community benefits and events.' },
  { title: 'Developer Insights', description: 'Get behind-the-scenes access and updates.' },
  { title: 'Whitelist Access', description: 'Secure a spot for Genesis NFT minting.' },
];

export const FLEXIBLE_STAKING_REWARDS = [
  { title: 'At least $RMV 10,000', description: 'Receive a lootbox containing T4-T5 NFT.' },
  { title: 'At least $RMV 20,000', description: 'Receive a lootbox containing T2-T4 NFT.' },
  { title: 'At least $RMV 50,000', description: 'Receive a lootbox containing T1-T2 NFT.' },
  { title: 'At least $RMV 100,000', description: 'Receive 2 lootboxes containing S1-S3 NFTs.' },
  { title: 'At least $RMV 200,000', description: 'Receive a lootbox containing T0 NFT.' },
  {
    title: 'At least $RMV 500,000',
    description: 'Receive a guaranteed whitelist spot for Genesis Mint.',
  },
  {
    title: 'At least $RMV 2,000,000',
    description: 'Receive a guaranteed Genesis Mint for Reality Metaverse.',
  },
];

export const NEGATIVE_STAKING_BENEFITS = [
  { title: 'Early Access', description: 'Be among the first to experience new game features and content.' },
  { title: 'Exclusive Game Features', description: 'Enjoy unique features available only to stakeholders.' },
  {
    title: 'Limited Edition NFTs',
    description: 'Acquire rare and exclusive NFTs not available to the general public.',
  },
  {
    title: 'Early Access to New Collection NFTs',
    description: 'Get a head start on collecting the latest NFT releases.',
  },
  { title: 'Token Airdrops', description: 'Receive free token distributions as a reward for your staking.' },
  {
    title: '$Trumpet Coin Access',
    description:
      'Obtain $Trumpet Coin before it becomes available on decentralized and centralized exchanges (DEX and CEX).',
  },
  { title: 'VIP Status', description: 'Gain VIP status within the game, unlocking additional perks and recognition.' },
  { title: 'Exclusive Community Benefits', description: "Access special benefits within the game's community." },
  { title: 'Access to Developer Insights', description: 'Gain insights directly from the development team.' },
  { title: 'NFT Airdrops', description: 'Receive additional NFTs through airdrops.' },
  {
    title: 'Exclusive Tournaments and Leaderboards',
    description: 'Participate in special tournaments and appear on exclusive leaderboards with unique prizes.',
  },
  {
    title: 'Genesis Mint Participation',
    description: 'Join in the initial minting events for new NFTs and game assets.',
  },
];

export const NEGATIVE_STAKING_REWARDS = [
  {
    title: '135% APR in Rewards',
    description:
      'Earn 135% APR in $Trumpet Coin following the token generation event. Afterward, you can claim your earnings daily.',
  },
];

export const NEGATIVE_STAKING_AIRDROPS = [
  {
    title: 'At least $RMV 5,000',
    description: 'Receive a lootbox containing T4-T5 NFTs.',
  },
  { title: 'At least $RMV 10,000', description: 'Receive a lootbox containing T2-T4 NFTs.' },
  { title: 'At least $RMV 20,000', description: 'Receive a lootbox containing T1-T2 NFTs.' },
  { title: 'At least $RMV 50,000', description: 'Receive a lootbox containing T0 NFTs.' },
  { title: 'At least $RMV 500,000', description: 'Receive a guaranteed Genesis Mint for Donut Trumpet.' },
  { title: 'At least $RMV 2,000,000', description: 'Receive a guaranteed Genesis Mint for Reality Metaverse.' },
];

export const NEGATIVE_STAKING_EXAMPLE = [
  '1 Guaranteed Genesis Mint for Donut Trumpet',
  '12 x T0 lootboxes',
  '1 x T1/T2 lootbox',
];

export const NEGATIVE_STAKING_INFORMATION = [
  {
    title: '$Trumpet Total Token Supply',
    description: 'The total supply of $Trumpet tokens will match the current total debt of the United States.',
  },
  { title: '135% APR', description: 'This rate is based on the ratio of the U.S. debt to GDP.' },
  {
    title: 'Token Distribution',
    description:
      'You will receive $Trumpet tokens at least one day before listing on DEX/CEX, and then acquire them daily until the game is completed.',
  },
  { title: 'NFT Airdrops', description: 'NFT airdrops will be distributed once the staking is completed.' },
  {
    title: '$RMV Token Reimbursement',
    description:
      'You will receive 70% of the $RMV tokens back once the game is developed, with the remaining 30% invested in game development. This is a locked staking.',
  },
  { title: 'Genesis Mint', description: 'Genesis Mint NFTs will be sent during the whitelist event.' },
  {
    title: 'Development Milestones',
    description:
      '• At 50% completion, the Genesis NFT Campaign will begin.\n• At 80% completion, the Marketing Campaign will start.\n• At 100% completion, PR and Production will commence.\n',
  },
  {
    title: 'Development Timeline',
    description: 'The game development is expected to take no longer than four months after the staking is completed.',
  },
  {
    title: 'Marketing Strategy',
    description:
      'Separate marketing efforts for the $Trumpet Coin will be initiated, with a release timed to leverage the election schedules. Stake your tokens and be a part of the exciting journey in Donut Trumpet Tycoon!',
  },
];

const adaptPoolId = (poolId: string | undefined) => (poolId ? Number(poolId) : undefined);

export const GAMES: IGame[] = [
  {
    title: 'Donut Trumpet Tycoon',
    image: Game3,
    invest: true,
    negative: true,
    multiplier: 34761,
    type: [GameTypeTag.Strategy, GameTypeTag.Tycoon, GameTypeTag.Web3],
    status: GameStatusTag.ComingSoon,
    open: true,
    presentation: 'https://drive.google.com/file/d/1HC81_kW71BM5nfJ7Lvqb-3nfKcmAQsQl/view',
    links: [{ type: GameLinkType.Discord, href: process.env.REACT_APP_LINK_DISCORD_DONUT_TRUMPET_TYCOON! }],
    href: 'donut-trumpet-tycoon',
    media: { video: GameVideo3, poster: Game3 },
    thumbnails: [Game3Thumbnail1, Game3Thumbnail2, Game3Thumbnail3, Game3Thumbnail4, Game3Thumbnail5],
    description:
      'Donut Trumpet Tycoon is a location based, real world property game that allows you to buy venues you visit and then earn rent as people check in at those properties in real time! The number of places in Donut Trumpet Tycoon is limited only by the number of places available in the world, so the Trumpet Games’ reality can be described as infinite.\n\nTo succeed in the Donut Tycoon Game, you need to be smart, because the rent income may not be enough; if you want to achieve similar success to Donut Trumpet, you will also need to buy and sell places based on a good business plan or simply on your gut feeling.\n\nIf you will be able to achieve this, you can become a true king of Trumpet games!\n\nNFT owners will receive additional royalties for placing Reality NFT 3D models as premium content inside the game!',
    poolId: adaptPoolId(process.env.REACT_APP_NEGATIVE_STAKE_POOL_ID_DONUT_TRUMPET_TYCOON),
  },
  {
    title: 'StreetVers',
    image: Game7,
    invest: true,
    flexible: true,
    type: [GameTypeTag.Action, GameTypeTag.Shooter, GameTypeTag.Web3],
    status: GameStatusTag.ComingSoon,
    open: true,
    presentation:
      'https://docs.google.com/presentation/d/e/2PACX-1vRakS8VKsR5RhRrYYWVP7AaPJQXO6Vf8m9ChN9G-a7eAHR6aBaJo3bvnx5HWbJFtmvWFKSIbLfOojZE/pub?start=false&loop=false&delayms=30000',
    links: [{ type: GameLinkType.Discord, href: process.env.REACT_APP_LINK_DISCORD_STREETVERS! }],
    href: 'streetvers',
    media: { video: GameVideo7, poster: Game7 },
    thumbnails: [Game7Thumbnail2, Game7Thumbnail3, Game7Thumbnail4, Game7Thumbnail5, Game7Thumbnail6, Game7Thumbnail7],
    description:
      'Have you ever dreamt of becoming a legendary figure like Tommy Shelby, Tupac, or Tony Montana? With STREETVERS, you can now embody these iconic characters and build your own criminal empire! Relive the excitement of your favorite series, movies, and music videos by stepping into their shoes and dominating the streets.',
    poolId: adaptPoolId(process.env.REACT_APP_FLEXIBLE_STAKE_POOL_ID_STREETVERS),
  },
  {
    title: 'Reality Rush',
    image: Game9,
    invest: true,
    flexible: true,
    type: [GameTypeTag.Telegram, GameTypeTag.CityBuilder],
    status: GameStatusTag.Live,
    open: false,
    presentation: 'https://city-whitepaper.gitbook.io/reality-rush',
    links: [
      { type: GameLinkType.Discord, href: 'https://t.me/realityrush' },
      { type: GameLinkType.Twitter, href: 'https://x.com/realityrushgame' },
      { type: GameLinkType.Telegram, href: 'https://discord.com/channels/913751549199667271/1257679532933124168' }, // temp hardcoded
    ],
    href: 'reality-rush',
    media: { poster: Game9Thumbnail1 },
    thumbnails: [Game9Thumbnail2, Game9Thumbnail3, Game9Thumbnail4],
    description: 'X game description placeholder',
    poolId: adaptPoolId(process.env.REACT_APP_FLEXIBLE_STAKE_POOL_ID_X_GAME),
  },
  {
    title: 'Weather Challenge',
    image: Game2,
    invest: true,
    type: [GameTypeTag.Simulation, GameTypeTag.Web3],
    status: GameStatusTag.ComingSoon,
    open: false,
    presentation: 'https://drive.google.com/file/d/19whngOlleBqtRRAvFMOnhWr4qO8NRsoU/view?usp=sharing',
    links: [
      { type: GameLinkType.Twitter, href: process.env.REACT_APP_LINK_TWITTER_WEATHER_CHALLENGE! },
      { type: GameLinkType.Discord, href: process.env.REACT_APP_LINK_DISCORD_WEATHER_CHALLENGE! },
      { type: GameLinkType.Telegram, href: process.env.REACT_APP_LINK_TELEGRAM_WEATHER_CHALLENGE! },
    ],
    href: 'weather-challenge',
    media: { video: GameVideo2, poster: Game2 },
    thumbnails: [Game2Thumbnail1, Game2Thumbnail2, Game2Thumbnail3, Game2Thumbnail4, Game2Thumbnail5],
    description:
      "Weather Challenge is the world's first weather app where you can bet on the weather with RMV token, allowing users to be weather forecasters and earn for accurate predictions.\n\nIt won best idea at Start-Up Weekend and the Audience Prize for innovative mobile gameplay.\n\nWith accurate data from 300,000 global weather stations, this app is a game-changer for weather-lovers worldwide.\n\nNFT owners will have an opportunity to earn royalties from geo-location bets!",
    poolId: adaptPoolId(process.env.REACT_APP_STAKE_POOL_ID_WEATHER_CHALLENGE),
  },
  {
    title: 'Landlord GO!',
    image: Game1,
    invest: false,
    type: [GameTypeTag.Strategy, GameTypeTag.Tycoon],
    status: GameStatusTag.Live,
    open: false,
    presentation: ROUTES.LANDLORD_GO,
    links: [
      { type: GameLinkType.AppleStore, href: STORE_URL.ios },
      { type: GameLinkType.GooglePlay, href: STORE_URL.android },
    ],
    href: 'landlord-go',
    media: { video: GameVideo1, poster: Game1 },
    thumbnails: [Game1Thumbnail1],
    description:
      "Landlord GO is the first geo-location game that joined Reality Metaverse ecosystem! Millions of players can't be wrong and that's how many people play to become the richest landlord in the world!",
  },
  {
    title: 'Food Truck Tycoon',
    image: Game4,
    invest: true,
    type: [GameTypeTag.Strategy, GameTypeTag.Tycoon, GameTypeTag.Web3],
    status: GameStatusTag.ComingSoon,
    open: false,
    presentation: 'https://drive.google.com/file/d/17V1bT3vCeMh-gDdLXhqySuOdkwFGXEp-/view?usp=sharing',
    links: [],
    href: 'food-truck-tycoon',
    thumbnails: [Game4Thumbnail1],
    description:
      'A geo-location game where you build and manage your fleet of food trucks!\n\nFind best locations in real cities and park your food truck, adjust your menu to meet local demand and earn RMV tokens from your growing fleet!\n\nChallenge other players to collect unique recipes and upgrade your food truck and your menu to fight the competition!\n\nDo you think you have what it takes to become a master mobile chef?\n\nNFT owners will receive additional RMV royalties when the truck park near their famous landmarks!',
  },
  {
    title: 'Speculate',
    image: Game5,
    invest: true,
    type: [GameTypeTag.Simulation, GameTypeTag.Web3],
    status: GameStatusTag.ComingSoon,
    open: false,
    presentation: 'https://drive.google.com/file/d/13DKHnueQ4gAmjTsYFjAdMJqDyyaDlHC7/view?usp=sharing',
    links: [],
    href: 'speculate',
    thumbnails: [Game5Thumbnail1],
    description:
      'Imagine stock market where you can buy Streamer’s or game’s shares with the RMV token and their performance results will reflect their popularity on Twitch.\n\nNFT owners will be able to earn additional royalties from bets depending on the location of the streamers.',
    poolId: adaptPoolId(process.env.REACT_APP_STAKE_POOL_ID_SPECULATE),
  },
  {
    title: 'Cryptopoly',
    image: Game6,
    invest: true,
    type: [GameTypeTag.Strategy, GameTypeTag.Rpg, GameTypeTag.Web3],
    status: GameStatusTag.ComingSoon,
    open: false,
    links: [],
    href: 'cryptopoly',
  },
  {
    title: 'Railway 3.0',
    image: Game8,
    invest: true,
    type: [GameTypeTag.Incubator, GameTypeTag.Web3],
    status: GameStatusTag.ComingSoon,
    open: false,
    links: [],
    href: 'railway',
  },
];

export interface IGameBanner {
  title: string;
  subtitle: string;
  text: string;
  background: string;
  colWidth?: string;
}

export const game_banners: IGameBanner[] = [
  {
    title: 'Reality Metaverse Game Ecosystem',
    subtitle: 'Team up with friends to battle evil superhero bosses and their army of twisted henchmen.',
    text: 'Learn more',
    background: GameBanner1,
  },
  {
    title: 'Unleash Your Predictive Powers with Staking!',
    subtitle:
      'Join us as we launch the Weather Challenge staking program, offering a thrilling opportunity to earn rewards with a 30% APR.',
    text: 'Stake now!',
    background: GameBanner4,
    colWidth: 'md:tw-w-[45%] xl:tw-w-2/5',
  },
];
