import axios, { AxiosResponse } from 'axios';
import Cookies from 'universal-cookie/es6';

import { IAssetDetails } from 'models/assets/i-asset-details.interface';
import { IAsset } from 'models/assets/i-asset.interface';
import { INonceResponse } from 'models/nonce-response.interface';
import { IProfileAssetPayload, IProfileTradeOffersPayload } from 'models/profile/i-profile-asset.interface';
import { IProfileAssetsFilter } from 'models/profile/i-profile-assets-filter.interface';
import { IProfileTrade, IProfileTradesPayload } from 'models/profile/i-profile-trade.interface';
import { IWalletCurrenciesResponse, IWalletCurrency } from 'models/wallet-currency-response.interface';
import { IWalletCurrencyDetails } from 'models/wallet-currency.interface';

import { CookiesKeys } from 'enums/cookies-keys.enum';

import {
  ASSET_DETAILS_FIELDS,
  ASSET_FIELDS,
  LANDING_FIELDS,
  PROFILE_FIELDS,
  PROFILE_TRADE_FIELDS,
  PROFILE_TRADE_OFFERS_FIELDS,
} from 'constants/params-fields.constant';

import { getIconByCurrencySymbol } from 'utils/get-currency-icon-by-symbol';
import { getQueryParams } from 'utils/get-query-params.utils';
import { removeAuthenticationCookies } from 'utils/remove-authentication-cookies.util';

import Web3Singleton from '../web3Singleton';
import { currencyBalanceContract } from './web3.service';

let cancelToken: any;

export const getCSRF = async (): Promise<AxiosResponse<any, any>> => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }

  cancelToken = axios.CancelToken.source();

  return axios.get<any>(`/api/v1/accounts/csrf/`);
};

export const getLogin = async (): Promise<string> => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }

  cancelToken = axios.CancelToken.source();

  return axios.get<any>(`/api/web3/login/`).then((loginResponse) => loginResponse.data.data);
};

export const postLogin = async (address: string, signature: string): Promise<AxiosResponse<any, any>> => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }

  cancelToken = axios.CancelToken.source();

  return axios.post<any>(`/api/web3/login/`, `address=${address}&signature=${signature}`, {
    headers: {
      'X-CSRFToken': new Cookies().get('csrftoken'),
    },
  });
};

export const postSignOut = async (): Promise<AxiosResponse<{}, any>> => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }

  const cookie = new Cookies();

  cancelToken = axios.CancelToken.source();

  return axios
    .post<any>(`/api/v1/accounts/logout/`, null, {
      headers: {
        'X-CSRFToken': cookie.get(CookiesKeys.csrftoken),
      },
    })
    .then((data) => {
      const lgoWeb3 = Web3Singleton.getInstance();

      removeAuthenticationCookies();

      lgoWeb3.logout();

      return data;
    });
};

export const getAssets = async (
  params: IProfileAssetsFilter,
  signal: AbortSignal
): Promise<AxiosResponse<IProfileAssetPayload>> => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }

  cancelToken = axios.CancelToken.source();

  return axios.get<IProfileAssetPayload>(`/api/v1/assets/`, {
    signal,
    params: {
      ...params,
      page_size: 10,
      fields: [...ASSET_FIELDS, ...LANDING_FIELDS, ...PROFILE_FIELDS].join(','),
    },
    headers: {
      'X-CSRFToken': new Cookies().get('csrftoken'),
    },
  });
};

export const getOffersAssets = async (
  params: IProfileAssetsFilter,
  signal: AbortSignal
): Promise<AxiosResponse<IProfileTradeOffersPayload | null>> => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }

  cancelToken = axios.CancelToken.source();

  const offersActiveParams: IProfileAssetsFilter = {
    disable_aggregation: '',
    created_by_me: 'true',
    ...params,
  };

  const offersPayload = await axios.get<IProfileTradeOffersPayload>(`/api/v1/trades/offers/`, {
    signal,
    params: {
      ...offersActiveParams,
      page_size: 10,
      fields: PROFILE_TRADE_OFFERS_FIELDS.join(','),
    },
  });

  const noDataResponse = {
    count: 0,
    next: null,
    previous: null,
    results: [],
  };

  if (!offersPayload.data?.count) {
    return {
      ...offersPayload,
      data: noDataResponse,
    };
  }

  const assetIds = offersPayload.data.results.map(({ sale: { asset_id } }) => asset_id);
  const ids = [...new Set(assetIds)].join(',');

  const assetsPayload = await axios.get<IAsset[]>(`/api/v1/assets/all_assets`, {
    params: { ids, fields: ASSET_FIELDS.join(',') },
  });

  if (!assetsPayload?.data) {
    return {
      ...assetsPayload,
      data: noDataResponse,
    };
  }

  const mappedResults = offersPayload.data.results.map((offer) => ({
    ...offer,
    sale: {
      ...offer.sale,
      asset: assetsPayload.data.find((asset) => offer.sale.asset_id === asset.id)!,
    },
  }));

  return {
    ...offersPayload,
    data: {
      ...offersPayload.data,
      results: mappedResults,
    },
  };
};

export const getListingAssets = async (
  params: IProfileAssetsFilter,
  signal: AbortSignal
): Promise<AxiosResponse<IProfileTradesPayload | null>> => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }

  cancelToken = axios.CancelToken.source();

  const listingActiveParams: IProfileAssetsFilter = {
    disable_aggregation: '',
    created_by_me: 'true',
    ...params,
  };

  const listingsPayload = await axios.get<IProfileTradesPayload>(`/api/v1/trades/`, {
    signal,
    params: { ...listingActiveParams, page_size: 10, fields: PROFILE_TRADE_FIELDS.join(',') },
  });

  const noDataResponse = {
    count: 0,
    next: null,
    previous: null,
    results: [],
  };

  if (!listingsPayload.data?.count) {
    return {
      ...listingsPayload,
      data: noDataResponse,
    };
  }

  const assetIds = listingsPayload.data.results.map(({ asset_id }) => asset_id);
  const ids = [...new Set(assetIds)].join(',');

  const assetsPayload = await axios.get<IAssetDetails[]>(`/api/v1/assets/all_assets`, {
    params: { ids, fields: [...ASSET_FIELDS, ...ASSET_DETAILS_FIELDS].join(',') },
  });

  if (!assetsPayload?.data?.length) {
    return {
      ...listingsPayload,
      data: noDataResponse,
    };
  }

  const mappedResults = listingsPayload.data.results.map((trade) => ({
    ...trade,
    asset: assetsPayload.data.find((asset) => trade.asset_id === asset.id)!,
  }));

  return {
    ...listingsPayload,
    data: {
      ...listingsPayload.data,
      results: mappedResults,
    },
  };
};

export const fetchCurrenciesService = async (): Promise<AxiosResponse<IWalletCurrency[]>> => {
  const currenciesResponse = await axios.get<IWalletCurrenciesResponse>(`/api/v1/assets/currencies/`, {
    params: getQueryParams({ page_size: '20' }),
    headers: {
      'X-CSRFToken': new Cookies().get(CookiesKeys.csrftoken),
    },
  });

  return {
    ...currenciesResponse,
    data: await Promise.all(
      currenciesResponse.data.results.map(async (currencyResponse) => {
        return {
          ...currencyResponse,
          icon: getIconByCurrencySymbol(currencyResponse.symbol),
        };
      })
    ),
  };
};

export const fetchCurrenciesWithBalanceService = async (
  accountAddress: string
): Promise<AxiosResponse<IWalletCurrencyDetails[]>> => {
  const currenciesResponse = await axios.get<IWalletCurrenciesResponse>(`/api/v1/assets/currencies/`, {
    params: getQueryParams({ page_size: '20' }),
    headers: {
      'X-CSRFToken': new Cookies().get(CookiesKeys.csrftoken),
    },
  });

  return {
    ...currenciesResponse,
    data: await Promise.all(
      currenciesResponse.data.results.map(async (currencyResponse) => {
        const balance = await currencyBalanceContract(currencyResponse.address, accountAddress);

        return {
          ...currencyResponse,
          icon: getIconByCurrencySymbol(currencyResponse.symbol),
          balance,
        };
      })
    ),
  };
};

export const fetchNonce = (): Promise<AxiosResponse<INonceResponse>> => {
  return axios.get(`/api/v1/accounts/nonce/`);
};

export const getSoonFinishedAuction = (): Promise<IProfileTrade> => {
  return axios
    .get(
      `/api/v1/trades/?show_status=0&ordering=expiration_time&created_by_me=true&page=1&page_size=1&disable_aggregation=1`
    )
    .then(({ data }) => data.results?.[0]);
};
