import { MouseEvent } from 'react';
import { twMerge } from 'tailwind-merge';

import ElementLoader from 'components/element-loader/ElementLoader';

import { ReactComponent as CloseSvg } from 'assets/icons/cross.svg';

import { useNftCardSize } from '../hooks';
import NftCardBackSlider from '../nftCardBackSlider/NftCardBackSlider';
import { useNftCardContext } from '../nftCardContext/NftCardContext';

const NftCardBack = ({
  backBackgroundClassName = 'tw-bg-backgroundColorMedium',
}: {
  backBackgroundClassName: string | undefined;
}) => {
  const { onToggle, loading } = useNftCardContext();
  const { isCardLarge, isCardMedium, isCardSmall } = useNftCardSize();
  const isNotSmallCard = isCardLarge || isCardMedium;

  const handleToggle = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onToggle();
  };

  return (
    <div
      className={twMerge(
        'backface-hidden tw-absolute tw-z-10 [transform:rotateY(180deg)]',
        backBackgroundClassName,
        isNotSmallCard && 'tw-inset-0 tw-rounded-2xl tw-p-4',
        isCardSmall && 'tw-inset-3 tw-py-2 tw-pr-1.5'
      )}
    >
      <div
        className={twMerge(
          'tw-absolute tw-z-10 tw-h-6 tw-w-10',
          backBackgroundClassName,
          isNotSmallCard && 'tw-right-1 tw-top-4.5',
          isCardSmall && '-tw-right-3 tw-top-1'
        )}
      >
        <button
          title="Close"
          className="button-reset tw-ml-1 tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center"
          onClick={handleToggle}
        >
          <CloseSvg />
        </button>
      </div>
      <ElementLoader loading={loading} className={twMerge('tw-rounded-2xl', isCardSmall && '!-tw-inset-3')}>
        <NftCardBackSlider />
      </ElementLoader>
    </div>
  );
};

export default NftCardBack;
