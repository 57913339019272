import { Tab } from '@headlessui/react';
import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import styles from './animatedTabs.module.scss';

interface IAnimatedTabsProps {
  list: string[];
  panels: ReactNode[];
  classNameList: string;
  classNamePanels: string;
  classNamePanel: string;
  classNameTab?: string;
  disabled?: boolean;
  controlled?: { selectedIndex: number; onChange: () => void };
}

const AnimatedTabs: FC<IAnimatedTabsProps> = ({
  list,
  panels,
  classNameList,
  classNamePanels,
  classNamePanel,
  classNameTab,
  disabled,
  controlled = null,
}) => {
  const tabs = controlled ?? {};

  return (
    <Tab.Group {...tabs}>
      <Tab.List className={twMerge(styles['tabs'], classNameList)}>
        {list.map((item, idx) => (
          <Tab
            key={idx}
            className={twMerge('button--styleless', styles['tab'], classNameTab)}
            disabled={idx > 0 && disabled}
          >
            {item}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className={classNamePanels}>
        {panels.map((panel, idx) => (
          <Tab.Panel key={idx} unmount={false} as="div" className={classNamePanel}>
            {panel}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default AnimatedTabs;
