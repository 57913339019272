import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchCurrencies, fetchRtlmMultiplier, fetchTranslations } from '../actions/wallet.action';

export const useAppSettings = (): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCurrencies());
    dispatch(fetchRtlmMultiplier());
    dispatch(fetchTranslations());
  }, [dispatch]);
};
