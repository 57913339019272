import {
    MAP_ROTATE_START, MAP_ROTATE_STOP, SET_CURRENT_DISPLAYED_VENUE, TOGGLE_MAP_ZOOM
} from '../constants/actionNames';
import { IMapReducer } from '../models/reducers/i-map-reducer.interface';

const INITIAL_STATE: IMapReducer = {
  zoom: false,
  rotate: true,
  currentDisplayedVenue: null,
};

export const mapReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case TOGGLE_MAP_ZOOM:
      return { ...state, zoom: !state.zoom };
    case MAP_ROTATE_START:
      return { ...state, rotate: true };
    case MAP_ROTATE_STOP:
      return { ...state, rotate: false };
    case SET_CURRENT_DISPLAYED_VENUE:
      return { ...state, currentDisplayedVenue: action.payload };
    default:
      return state;
  }
};
