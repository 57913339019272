import { DEFAULTS } from '../../../constants/defaults.constant';
import { LANDING_FAQ_LIST } from '../../../constants/faq-list.constant';

import Button from '../../../components/buttons/Button';
import ScrollInAnimationItem from '../../../components/scrollInAnimationItem/ScrollInAnimationItem';

import styles from './LandingFaq.module.scss';
import LandingFaqListItem from './landingFaqItem/FaqListItem';

const LandingFaq = () => {
  const faqList = LANDING_FAQ_LIST;

  return (
    <section className={styles['section']}>
      <ScrollInAnimationItem delay="0.5s" threshold={0.3} className={styles['section__content']}>
        <div className={styles['container']}>
          <h2 className={styles['heading']}>FAQ</h2>
          {faqList.map(({ title, details }) => (
            <LandingFaqListItem key={title} title={title}>
              {Array.isArray(details) && details?.map((item, idx) => <p key={idx}>{item}</p>)}
            </LandingFaqListItem>
          ))}
          <Button
            className={styles['faq-button']}
            ariaLabel="Read full FAQ"
            size="medium"
            asLink
            link={DEFAULTS.faqUrl}
          >
            Read full faq
          </Button>
        </div>
      </ScrollInAnimationItem>
    </section>
  );
};

export default LandingFaq;
