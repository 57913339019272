export const getRotationDegrees = (prizeNumber: number, numberOfPrizes: number): number => {
  const degreesPerPrize = 360 / numberOfPrizes;
  const initialRotation = degreesPerPrize / 2;
  const perfectRotation = degreesPerPrize * (numberOfPrizes - prizeNumber) - initialRotation;
  const prizeRotation = perfectRotation - 90;

  return numberOfPrizes - prizeNumber > numberOfPrizes / 2 ? -360 + prizeRotation : prizeRotation;
};

export const clamp = (min: number, max: number, val: number): number => Math.min(Math.max(min, +val), max);

export const getQuantity = (prizeMap: number[][]): number => prizeMap.slice(-1)[0].slice(-1)[0] + 1;

export const getNumericOption = (option: string) => {
  const num = parseFloat(option);

  if (num === 0) {
    return '0';
  }

  if (num < 1) {
    return num.toFixed(2).replace('.', ',');
  }

  return num.toLocaleString('en-US').replace(/,/g, ' ');
};
