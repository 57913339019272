import { MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { ERROR_MESSAGES } from 'constants/error-messages.constant';

import { addErrorMessageAction, setShowSelectWalletModal } from 'actions/global-actions';

import WithTwClassName from 'hoc/WithTwClassName';

import { useIsWeb3Allowed } from 'hooks/useIsWeb3Allowed';

import styles from './Button.module.scss';

export type ButtonSize = 'small' | 'medium-small' | 'medium' | 'medium-large' | 'regular';

interface IButtonProps {
  text?: string;
  ariaLabel?: string;
  type?: 'submit' | 'button' | 'reset';
  size?: ButtonSize;
  color?:
    | 'primary'
    | 'secondary'
    | 'accent'
    | 'warning'
    | 'default'
    | 'blue'
    | 'green'
    | 'green-outline'
    | 'white-outline'
    | 'disabled-outline';
  onClick?: (event: MouseEvent) => void;
  disabled?: boolean;
  className?: string;
  contentClassName?: string;
  children?: ReactNode;
  requireWeb3?: boolean;
  link?: string;
  asLink?: boolean;
  target?: '_self' | '_blank' | '_parent' | '_top';
  iconComponentLeft?: JSX.Element;
  iconComponentRight?: JSX.Element;
}

const Button = ({
  text,
  ariaLabel,
  size = 'regular',
  color = 'primary',
  children,
  onClick,
  type = 'button',
  className,
  contentClassName,
  disabled = false,
  // requireWeb3 rename to requireWalletAddress or requireLogin, check if user is logged
  requireWeb3 = false,
  link,
  target = '_blank',
  asLink = false,
  iconComponentLeft,
  iconComponentRight,
}: IButtonProps) => {
  const { t } = useTranslation('notifications');
  const web3Allowed = useIsWeb3Allowed();
  const dispatch = useDispatch();
  const defaultClassNames = [styles['button'], styles[`button--${color}`], styles[`button--${size}`]];

  if (disabled) {
    defaultClassNames.push(styles[`button--disabled`]);
  }

  const handleClick = (event: MouseEvent): void => {
    if (!onClick) {
      return;
    }

    if (!requireWeb3) {
      onClick(event);

      return;
    }

    if (!web3Allowed) {
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.unauthorized)));
      dispatch(setShowSelectWalletModal(true));

      return;
    }

    onClick(event);
  };

  const ButtonContent = () => {
    const renderButtonContent = (className: string) => (
      <span aria-hidden className={[className, contentClassName].join(' ')}>
        {iconComponentLeft && iconComponentLeft}
        {children ? <>{children}</> : <>{text}</>}
        {iconComponentRight && iconComponentRight}
      </span>
    );

    return (
      <button
        type={type}
        onClick={handleClick}
        disabled={disabled}
        aria-label={ariaLabel}
        className={[...defaultClassNames, className].join(' ')}
      >
        <span className={styles['button-content__wrapper']}>
          {renderButtonContent(styles['button-content'])}
          {renderButtonContent(styles['button-content-copy'])}
        </span>
      </button>
    );
  };

  return asLink && link ? (
    <Link className={styles['link']} to={link} target={target} rel="noopener noreferrer" aria-label={ariaLabel}>
      <ButtonContent />
    </Link>
  ) : (
    <ButtonContent />
  );
};

export default WithTwClassName(Button);
