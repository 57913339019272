import Button from '../../../components/buttons/Button';
import CheckmarkListItem from '../../../components/checkmarkListItem/CheckmarkListItem';
import ScrollInAnimationItem from '../../../components/scrollInAnimationItem/ScrollInAnimationItem';

import AuditedByBackground from '../../../assets/landing/stats/audited-by-background.jpg';
import AuditedByShield from '../../../assets/landing/stats/audited-by-shield.png';
import TokenUtilityBackground from '../../../assets/landing/stats/token-utility-background.jpg';
import TokenUtilityCoin from '../../../assets/landing/stats/token-utility-coin.png';

import LandingInfoCard from '../landingInfoCard/LandingInfoCard';
import styles from './LandingStatsSection.module.scss';

const LandingStatsSection = () => {
  return (
    <section className={['landing-page-container', styles['section']].join(' ')}>
      <ScrollInAnimationItem className={styles['token-utility']} delay="0.1s" threshold={0.3}>
        <LandingInfoCard
          className={styles['card']}
          contentClassName={styles['token-utility__content']}
          title={
            <div>
              token <b>utility</b>
            </div>
          }
          content={
            <div>
              <p className={styles['token-utility__title']}>
                TOTAL SUPPLY: <span>1,000,000,000 RMV</span>
              </p>
              <ul className={styles['token-utility__list']}>
                <CheckmarkListItem className={styles['token-utility__list-item']}>
                  RMV is erc-20 token that holds reality metaverse ecosystem together
                </CheckmarkListItem>
                <CheckmarkListItem className={styles['token-utility__list-item']}>
                  Deflationary mechanisms
                </CheckmarkListItem>
                <CheckmarkListItem className={styles['token-utility__list-item']}>
                  Used for transactions in reality metaverse
                </CheckmarkListItem>
                <CheckmarkListItem className={styles['token-utility__list-item']}>
                  RMV will allow governing actions
                </CheckmarkListItem>
              </ul>
            </div>
          }
          graphics={
            <>
              <img className={styles['card__background']} src={TokenUtilityBackground} alt="" />
              <img className={styles['token-utility__coin']} src={TokenUtilityCoin} alt="" />
            </>
          }
        />
      </ScrollInAnimationItem>
      <ScrollInAnimationItem className={styles['audited-by']} delay="0.2s" threshold={0.3}>
        <LandingInfoCard
          className={styles['card']}
          contentClassName={styles['audited-by__content']}
          title={
            <div>
              audited by <b>certik</b>
            </div>
          }
          content={
            <div>
              Certik, a renowed auditing firm, has conducted a thorough examination of our platform and RMV token,
              ensuring its security and reliability.
            </div>
          }
          footer={
            <Button
              size="medium"
              color="primary"
              onClick={() =>
                window.open('https://drive.google.com/uc?export=download&id=1vdg5VSX6FKbov6G3ED9Mezoh_czz5Zgt')
              }
            >
              read audit report
            </Button>
          }
          graphics={
            <>
              <img className={styles['card__background']} src={AuditedByBackground} alt="" />
              <img className={styles['audited-by__shield']} src={AuditedByShield} alt="" />
            </>
          }
        />
      </ScrollInAnimationItem>
    </section>
  );
};

export default LandingStatsSection;
