import styles from './FaqList.module.scss';

interface IFaqListProps {
  className?: string;
  children: React.ReactNode;
}

const FaqList = ({ children, className = '' }: IFaqListProps) => {
  const classNames = [className, styles['faq-list']];

  return <div className={classNames.join(' ')}>{children}</div>;
};

export default FaqList;
