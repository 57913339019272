import { sendGA4Event } from 'services/analytics-events.service';

import { ILoadingTimeParam, useEventParamsMethods } from './useEventsParams';

enum MarketEventName {
  DisplayAuction = 'market_display_auction',
  GetFirstPage = 'get_marketplace_first_page',
}

export const useMarketEvents = () => {
  const { withWalletAddress } = useEventParamsMethods();

  const sendMarketDisplayAuction = () => {
    sendGA4Event(MarketEventName.DisplayAuction, withWalletAddress());
  };

  const sendMarketGetFirstPageEvent = (params: ILoadingTimeParam) => {
    sendGA4Event(MarketEventName.GetFirstPage, withWalletAddress(params));
  };

  return {
    sendMarketDisplayAuction,
    sendMarketGetFirstPageEvent,
  };
};
