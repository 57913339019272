import axios, { AxiosResponse } from 'axios';

import { ILootboxMetadata } from 'models/lootbox/i-lootbox-metadata.interface';

import { DEFAULTS } from 'constants/defaults.constant';

export const getLootboxMetadataService = (id: string): Promise<AxiosResponse<ILootboxMetadata>> => {
  const isDevelopment = process.env.REACT_APP_DEVELOPMENT_MODE === 'true';

  const lootboxMetadataUrl = isDevelopment
    ? DEFAULTS.developmentLootboxMetadataUrl(id)
    : DEFAULTS.productionLootboxMetadataUrl(id);

  return axios.get<ILootboxMetadata>(lootboxMetadataUrl);
};
