import { ILootboxPurchaseConfig } from 'models/lootbox/i-lootbox-purchase-config.interface';

import { useLootboxPurchase } from '../../hooks/useLootboxPurchase';
import { useCurrencyBySymbol } from 'hooks/useCurrency';

import LootboxCardBuyControls from '../lootboxCardBuyControls/LootboxCardBuyControls';

interface ILootboxPurchaseProps extends ILootboxPurchaseConfig {
  currencySymbol: string;
  maxQuantity: number;
  priceDisplayValue: string;
  isOnSale: boolean;
}

const LootboxPurchase = ({
  listingId,
  assetId,
  openingAnimation,
  currencySymbol,
  maxQuantity,
  name,
  price,
  priceDisplayValue,
  isOnSale,
  type,
}: ILootboxPurchaseProps) => {
  const currency = useCurrencyBySymbol(currencySymbol);
  const { startLootboxPurchase } = useLootboxPurchase();

  const startPurchase = (quantity: number) =>
    startLootboxPurchase({ listingId, assetId, openingAnimation, name, price, quantity, currency, type });

  return (
    <LootboxCardBuyControls
      maxQuantity={maxQuantity}
      currency={currency}
      priceDisplayValue={priceDisplayValue}
      onPurchase={startPurchase}
      isOnSale={isOnSale}
    />
  );
};

export default LootboxPurchase;
