import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { IState } from 'models/reducers/i-state.interface';

import { PriceCurrencySymbols } from 'enums/price-currency-symbols.enum';

import { useRMVBalanceState, useUSDCBalanceState } from 'hooks/useBalanceState';
import { useRMVCurrencyWithBalance, useUSDCCurrencyWithBalance } from 'hooks/useCurrency';

import Button from '../../buttons/Button';
import ElementLoader, { ElementLoaderContext } from '../../element-loader/ElementLoader';
import ValueAndIcon from '../../generics/ValueAndIcon';
import ModalPortal from '../../modal/ModalPortal';
import styles from './CurrencyPurchaseComplete.module.scss';

interface ICurrencyPurchaseCompleteProps {
  onClose: () => void;
  onPurchaseSuccess: () => void;
}

const Balance = ({
  symbol,
  balance,
  loading,
}: {
  symbol: PriceCurrencySymbols | undefined;
  balance: string;
  loading: boolean;
}) => {
  const { enableUi, disableUi } = useContext(ElementLoaderContext);

  useEffect(() => {
    if (loading) {
      disableUi();

      return;
    }

    enableUi();
  }, [loading, enableUi, disableUi]);

  const rmv = useRMVCurrencyWithBalance();
  const usdc = useUSDCCurrencyWithBalance();

  return (
    <>
      <p className={styles['modal-content__balance-title']}>Your Balance</p>
      <ValueAndIcon
        value={balance}
        icon={symbol === PriceCurrencySymbols.USDC ? usdc?.icon : rmv?.icon}
        valueSize={20}
        iconSize={24}
      />
    </>
  );
};

const CurrencyPurchaseComplete = ({ onClose, onPurchaseSuccess }: ICurrencyPurchaseCompleteProps) => {
  const symbol = useSelector((state: IState) => state.global.currencySymbol);
  const isUSDCCurrency = symbol === PriceCurrencySymbols.USDC;

  const rmv = useRMVBalanceState();
  const usdc = useUSDCBalanceState();

  const [refreshClicked, setRefreshClicked] = useState<boolean>(false);
  const balanceRef = useRef(isUSDCCurrency ? usdc.balance : rmv.balance);

  useEffect(() => {
    if (!refreshClicked) {
      balanceRef.current = isUSDCCurrency ? usdc.balance : rmv.balance;

      return;
    }

    if (refreshClicked && balanceRef.current !== (isUSDCCurrency ? usdc.balance : rmv.balance)) {
      onPurchaseSuccess();
    }
  }, [onPurchaseSuccess, rmv.balance, usdc.balance, refreshClicked]);

  return (
    <ModalPortal
      modalContentClassName={styles['modal']}
      modalTitleClassName={styles['modal__title']}
      title="your purchase is complete"
      onClose={onClose}
    >
      <div className={styles['modal-content']}>
        <p className={styles['modal-content__text']}>
          The tokens you have purchased are being transferred to your wallet. Press the refresh button to scan your
          wallet and confirm delivery of tokens.
        </p>
        <div className={styles['modal-content__balance']}>
          <ElementLoader>
            <Balance
              symbol={symbol}
              balance={isUSDCCurrency ? usdc.balance : rmv.balance}
              loading={isUSDCCurrency ? usdc.loading : rmv.loading}
            />
          </ElementLoader>
        </div>
        <div className={styles['modal-content__footer']}>
          <Button
            className={styles['modal-content__footer-button']}
            type="button"
            text="Close"
            color="default"
            onClick={onClose}
          />
          <Button
            className={styles['modal-content__footer-button']}
            type="button"
            text="Refresh"
            onClick={() => {
              setRefreshClicked(true);
              symbol === PriceCurrencySymbols.USDC ? usdc.refreshBalance() : rmv.refreshBalance();
            }}
          />
        </div>
      </div>
    </ModalPortal>
  );
};

export default CurrencyPurchaseComplete;
