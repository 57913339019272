import { useSelector } from 'react-redux';

import { IState } from '../../../models/reducers/i-state.interface';
import ErrorNotification from '../errorNotification/ErrorNotification';
import InfoNotification from '../infoNotification/InfoNotification';
import SuccessNotification from '../successNotification/SuccessNotification';
import WarningNotification from '../warningNotification/WarningNotification';

import styles from './NotificationsPortal.module.scss';

const NotificationsPortal = () => {
  const errorMessages = useSelector((state: IState) => state.global.errorMessages);
  const successMessages = useSelector((state: IState) => state.global.successMessages);
  const warningMessages = useSelector((state: IState) => state.global.warningMessages);
  const infoMessages = useSelector((state: IState) => state.global.infoMessages);

  const messages = [...errorMessages, ...successMessages, ...warningMessages, ...infoMessages];

  return messages?.length ? (
    <div className={styles['notifications-portal']}>
      {errorMessages.map((message) => (
        <div key={message.id} className={styles['notification']}>
          <ErrorNotification id={message.id} message={message.message} />
        </div>
      ))}
      {successMessages.map((message) => (
        <div key={message.id} className={styles['notification']}>
          <SuccessNotification id={message.id} message={message.message} />
        </div>
      ))}
      {warningMessages.map((message) => (
        <div key={message.id} className={styles['notification']}>
          <WarningNotification id={message.id} message={message.message} />
        </div>
      ))}
      {infoMessages.map((message) => (
        <div key={message.id} className={styles['notification']}>
          <InfoNotification id={message.id} message={message.message} />
        </div>
      ))}
    </div>
  ) : (
    <></>
  );
};

export default NotificationsPortal;
