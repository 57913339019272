import { useState } from 'react';
import { DateTime } from 'luxon';

export const useLoadingTime = () => {
  const [start, setStart] = useState<number | undefined>(undefined);
  const [stop, setStop] = useState<number | undefined>(undefined);

  const loadingTime = () => {
    if (!start || !stop) return;

    return DateTime.fromMillis(stop).diff(DateTime.fromMillis(start), 'milliseconds').milliseconds;
  };

  return {
    loadingTime: loadingTime(),
    handleStartLoadingTime: () => setStart(Date.now()),
    handleStopLoadingTime: () => setStop(Date.now()),
  };
};
