import NftCardMedium from '../../nftCard/nftCardMedium/NftCardMedium';
import styles from './VenueDetailsCardMain.module.scss';

interface IVenueDetailsCardMainProps {
  id: number;
  cardUrl: string;
  name: string;
  iconUrl: string;
  availableShares: number;
  address?: string;
  category?: string;
  className?: string;
  totalShares?: number;
  scale?: number;
}

const VenueDetailsCardMain = ({
  id,
  availableShares,
  cardUrl,
  name,
  iconUrl,
  address,
  category,
  totalShares,
  className = '',
  scale = 1,
}: IVenueDetailsCardMainProps) => {
  const classNames = [styles['venue-card-details-main'], className];
  const totalSharesString = totalShares ? `/ ${totalShares ?? '-'}` : '';
  const defaultCardWidth = 255;
  const defaultCardHeight = 370;

  return (
    <div
      className={styles['venue-card-details-main__wrapper']}
      style={{
        transform: `scale(${scale})`,
        width: `${defaultCardWidth * scale}px`,
        height: `${defaultCardHeight * scale}px`,
      }}
    >
      <NftCardMedium
        className={classNames.join(' ')}
        backgroundUrl={cardUrl}
        backgroundAnimationUrl={cardUrl}
        name={name}
        address={address || ''}
        marketUrl={iconUrl}
        category={category || '-'}
        id={id}
        shares={`${availableShares?.toString() || '-'} ${totalSharesString}`}
      />
    </div>
  );
};

export default VenueDetailsCardMain;
