import { ReactNode } from 'react';

import WithTwClassName from '../../hoc/WithTwClassName';

import FullScreenLoadingSpinner from '../fullScreenLoadingSpinner/FullScreenLoadingSpinner';
import TermsOfServiceModal from '../termsOfServiceModal/TermsOfServiceModal';
import styles from './Page.module.scss';

interface IPageProps {
  bannerSticky?: boolean;
  className?: string;
  children: ReactNode;
  hideLoader?: boolean;
  loading?: boolean;
  requireTermsOfServiceConsents?: boolean;
  pageBackground?: 'transparent' | 'dark' | 'regular';
  withScrollbar?: boolean;
}

const Page = ({
  bannerSticky = true,
  children,
  className = '',
  hideLoader = false,
  loading = false,
  pageBackground = 'regular',
  requireTermsOfServiceConsents = false,
  withScrollbar = false,
}: IPageProps) => {
  const pageClasses = [
    styles['page'],
    styles[`page--${pageBackground}`],
    withScrollbar ? styles[`page--scrollable`] : '',
    className,
  ];

  return (
    <>
      {requireTermsOfServiceConsents ? <TermsOfServiceModal /> : <></>}
      {hideLoader ? <></> : <FullScreenLoadingSpinner loading={loading} />}
      <div className={pageClasses.join(' ')}>{children}</div>
    </>
  );
};

export default WithTwClassName(Page);
