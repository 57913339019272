import { AxiosResponse } from 'axios';

import { IDispatchAction } from 'models/dispatch-action.interface';
import { ILandingReducer } from 'models/reducers/i-landing-reducer.interface';

import { FETCH_LANDING_ASSETS } from 'actions/actionNames/landingActionNames';

const INITIAL_STATE: ILandingReducer = {
  loading: false,
  error: false,
  fetched: false,
  assets: null,
};

export const landingReducer = (state = INITIAL_STATE, action: IDispatchAction<AxiosResponse>): ILandingReducer => {
  switch (action.type) {
    case FETCH_LANDING_ASSETS + '_PENDING':
      return { ...state, loading: true, error: false, fetched: false };
    case FETCH_LANDING_ASSETS + '_REJECTED':
      return { ...state, loading: false, error: true, fetched: false };
    case FETCH_LANDING_ASSETS + '_FULFILLED': {
      return {
        ...state,
        loading: false,
        error: false,
        fetched: true,
        assets: action.payload.data,
      };
    }
    default:
      return state;
  }
};
