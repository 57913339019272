import { AxiosResponse } from 'axios';
import { generateUUID } from 'three/src/math/MathUtils';

import { IDispatchAction } from 'models/dispatch-action.interface';
import {
  INftOfTheDayAggregatedSale,
  INftOfTheDayAsset,
  INftOfTheDayOfferSale,
  INftOfTheDayResponse,
} from 'models/nft-of-the-day/i-nft-of-the-day';
import { INftOfTheDayReducer } from 'models/reducers/i-nft-of-the-day-reducer.interface';

import { DEFAULTS } from 'constants/defaults.constant';

import {
  FETCH_NFT_OF_THE_DAY,
  FETCH_NFT_OF_THE_DAY_ASSETS,
  REFRESH_NFT_OF_THE_DAY_UPDATE_TOKEN,
  RESET_NFT_OF_THE_DAY_DATA,
} from 'actions/actionNames/nftOfTheDayActionNames';

const INITIAL_STATE: INftOfTheDayReducer = {
  updateToken: generateUUID(),
  offers: [],
  assets: [],
  loading: false,
  assetsLoading: false,
};

export const nftOfTheDayReducer = (state = INITIAL_STATE, action: IDispatchAction): INftOfTheDayReducer => {
  switch (action.type) {
    case RESET_NFT_OF_THE_DAY_DATA:
      return { ...INITIAL_STATE };
    case REFRESH_NFT_OF_THE_DAY_UPDATE_TOKEN:
      return { ...state, updateToken: generateUUID() };
    case FETCH_NFT_OF_THE_DAY + '_PENDING':
      return { ...state, loading: true };
    case FETCH_NFT_OF_THE_DAY + '_REJECTED':
      return { ...state, loading: false };
    case FETCH_NFT_OF_THE_DAY + '_FULFILLED': {
      const response = (action as IDispatchAction<AxiosResponse<INftOfTheDayResponse[]>>).payload.data;

      if (!response?.length) {
        return { ...state, offers: [], loading: false };
      }

      const adaptItems = (aggregated_sales: INftOfTheDayAggregatedSale[], sales: INftOfTheDayOfferSale[]) =>
        aggregated_sales
          .sort((a, b) => +a.amount - +b.amount)
          .map(({ amount, price, active_sales_ids, currency_id }) => ({
            allSales: active_sales_ids.map((id) => sales.find((sale) => sale.id === id)),
            meta: { amount, price },
            currency_id,
          }));

      return {
        ...state,
        // @ts-ignore
        offers: response.map(({ asset, aggregated_sales, sales, valid_until }) => ({
          asset_id: asset.id,
          items: adaptItems(aggregated_sales, sales),
          valid_until: valid_until * DEFAULTS.tradeTimestampMultiplier,
        })),
        loading: false,
      };
    }
    case FETCH_NFT_OF_THE_DAY_ASSETS + '_PENDING':
      return { ...state, assetsLoading: true };
    case FETCH_NFT_OF_THE_DAY_ASSETS + '_REJECTED':
      return { ...state, assetsLoading: false };
    case FETCH_NFT_OF_THE_DAY_ASSETS + '_FULFILLED': {
      const assets = (action as IDispatchAction<AxiosResponse<INftOfTheDayAsset[]>>).payload.data;

      if (!assets) {
        return { ...state, assets: [], assetsLoading: false };
      }

      return { ...state, assets, assetsLoading: false };
    }
    default:
      return state;
  }
};
