import { DEFAULTS } from 'constants/defaults.constant';

import { getIsEmpty } from 'utils/get-is-empty.utils';

export const getValue = (
  value: string | number,
  valueToFixed: boolean,
  parser?: (value: number, precision?: number) => string | undefined,
  precision?: number
): string => {
  if (parser && typeof value === 'number') {
    return parser(value, precision) ?? '-';
  }

  if (value.toString().includes('<')) {
    return value.toString();
  }

  if (getIsEmpty(value) || Number.isNaN(+value)) {
    return `-`;
  }

  if (Number.isNaN(+value) && typeof value === 'string') {
    return value;
  }

  if (Number.isNaN(+value)) {
    return '-';
  }

  if (!valueToFixed) {
    return value.toString();
  }

  if (+value < DEFAULTS.minPriceDisplayValue && +value !== 0) {
    return `<${DEFAULTS.minPriceDisplayValue}`;
  }

  return `${(+value).toFixed(precision)}`;
};
