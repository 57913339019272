import { FILTER_CHANGE, SEARCH_BUILDING, SET_SEARCH_VISIBILITY } from '../constants/actionNames';
import { ISearchFilters } from '../models/reducers/i-search-reducer.interface';

export const searchBuildingAction = (data: string) => ({
  type: SEARCH_BUILDING,
  payload: data,
});

export const filterChangeAction = (data: ISearchFilters) => ({
  type: FILTER_CHANGE,
  payload: data,
});

export const setSearchVisibilityAction = (data: boolean) => ({
  type: SET_SEARCH_VISIBILITY,
  payload: data,
});
