export const LANDING_ROADMAP = [
  {
    title: 'RMV token',
    items: [
      {
        title: 'Major exchanges',
        checked: true,
      },
      {
        title: 'Additional governance mechanisms',
        checked: true,
      },
    ],
  },
  {
    title: 'Adding new games',
    items: [
      {
        title: 'Games accelerator',
        checked: false,
      },
      {
        title: 'Games partnerships',
        checked: true,
      },
    ],
  },
  {
    title: 'Reality metaverse',
    items: [
      {
        title: 'Expansion to other blockchains',
        checked: false,
      },
      {
        title: 'Reality metaverse - the world',
        checked: false,
      },
    ],
  },
  {
    title: 'NFT',
    items: [
      {
        title: 'AR NFT',
        checked: false,
      },
      {
        title: '3d models will be licensed (game + tv/movie industry)',
        checked: false,
      },
      {
        title: 'Chat & community feed inside NFT + NFT ads space',
        checked: false,
      },
      {
        title: 'Additional NFT unique features & upgrades',
        checked: false,
      },
      {
        title: 'Exclusive club for NFT owners',
        checked: true,
      },
    ],
  },
  {
    title: 'Games production',
    items: [
      {
        title: 'Landlord GO',
        checked: true,
      },
      {
        title: 'Reality Rush',
        checked: true,
      },
      {
        title: 'Weather Challenge',
        checked: false,
      },
      {
        title: 'Speculate game',
        checked: false,
      },
      {
        title: 'Foodtruck Tycoon',
        checked: false,
      },
      {
        title: 'Donut Trumpet Tycoon',
        checked: false,
      },
    ],
  },
  {
    title: 'Railway',
    items: [
      {
        title: 'Web3 Coffee space ',
        checked: false,
      },
      {
        title: 'Web3 Event space',
        checked: true,
      },
      {
        title: 'Web3 Coworking ',
        checked: false,
      },
      {
        title: 'Web3 Accelerator',
        checked: false,
      },
    ],
  },
];
