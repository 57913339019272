import SoldBadge from 'components/soldBadge/SoldBadge';

const NftCardSold = () => {
  return (
    <>
      <div className="tw-absolute -tw-inset-3 tw-z-10 tw-rounded-2xl tw-bg-backgroundColorDark tw-opacity-50" />
      <SoldBadge
        className="tw-absolute tw-left-[calc(50%_-_110px)] tw-top-1/4 tw-z-10 tw-w-max tw-px-2.5 tw-py-px tw-text-3xl [transform:rotate(-30deg)]"
        text="The Listing Sold"
      />
    </>
  );
};

export default NftCardSold;
