import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'en',
    backend: {
      loadPath: '/i18n/{{lng}}/{{ns}}.json',
    },
    // Example of reusable translations - common
    // Example of module translations - leaders
    ns: ['common', 'notifications', 'leaders'],
    fallbackLng: 'en',
    preload: ['en'],
    defaultNS: ['common', 'notifications'],
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
  });

export default i18n;
