import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { ROUTES } from '../constants/routes.constant';

export const useReferrerWallet = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [referrerWallet, setReferrerWallet] = useState<string | null>(null);
  const searchParams = new URLSearchParams(location.search);

  useEffectOnce(() => {
    if (searchParams) setReferrerWallet(searchParams.get('referrerWallet'));
  });

  useEffect(() => {
    if ([ROUTES.DAILY_DEALS, ROUTES.LOOTBOX, ROUTES.TOP_OFFERS].includes(location.pathname) && referrerWallet) {
      navigate({
        pathname: location.pathname,
        search: `?referrerWallet=${referrerWallet}`,
      });
    }
  }, [location.pathname, referrerWallet]);
};
