import { useDispatch } from 'react-redux';

import { setShowSelectWalletModal } from 'actions/global-actions';

import { useConnectWalletEvents } from 'hooks/events/useConnectWalletEvents';
import { useEnv } from 'hooks/useEnv';

import { ReactComponent as Wallet } from 'assets/menu/icons/wallet.svg';

import Button, { ButtonSize } from '../../buttons/Button';
import styles from './MenuWalletConnectControl.module.scss';

const MenuWalletConnectControl = ({ size }: { size?: ButtonSize }) => {
  const dispatch = useDispatch();

  const { sendConnectWalletClickedEvent } = useConnectWalletEvents();

  const { hideLogin } = useEnv();

  const setShowConnectWalletModal = () => {
    dispatch(setShowSelectWalletModal(true));
    sendConnectWalletClickedEvent();
  };

  return (
    <div className={styles['menu-wallet-connect-control']}>
      {!hideLogin && (
        <Button
          className={styles['connect-button']}
          size={size ?? 'medium'}
          ariaLabel="Connect wallet"
          onClick={setShowConnectWalletModal}
        >
          <span className={styles['icon']}>
            <Wallet />
          </span>
          <span className={styles['text']}>Connect wallet</span>
        </Button>
      )}
    </div>
  );
};

export default MenuWalletConnectControl;
