import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Web3Providers } from 'reducers/web3.reducer';
import Web3Singleton from 'web3Singleton';

import { IState } from 'models/reducers/i-state.interface';

import { MARKET } from 'constants/defaults.constant';

import { addErrorMessageAction, setShowSwitchNetworkModal } from 'actions/global-actions';

import { useEnv } from 'hooks/useEnv';
import { useIsProduction } from 'hooks/useIsProduction';

import Button from '../buttons/Button';
import ModalPortal from '../modal/ModalPortal';
import styles from './CheckNetwork.module.scss';

interface ICheckNetwork {
  children: JSX.Element | JSX.Element[];
  onClose?: () => void;
}

export const CheckNetwork = ({ children, onClose }: ICheckNetwork) => {
  const dispatch = useDispatch();
  const isProd = useIsProduction();
  const pathname = useLocation().pathname;
  const lgoWeb3 = Web3Singleton.getInstance();
  const { addNetworkSettings } = useEnv();
  const [alreadyShown, setAlreadyShown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const address = useSelector(({ wallet }: IState) => wallet.address);
  const { correctChain, chainId, currentProvider } = useSelector(({ web3 }: IState) => web3);
  const { showSwitchNetworkModal } = useSelector(({ global }: IState) => global);
  const displaySwitchNetworkModal = (showModal && !alreadyShown) || showSwitchNetworkModal;

  useEffect(() => {
    if (!alreadyShown) {
      setAlreadyShown(true);
    }

    setShowModal(true);
  }, [showSwitchNetworkModal]);

  useEffect(() => {
    if (!alreadyShown) {
      setAlreadyShown(true);
    }

    setShowModal(correctChain === false && !!chainId && !!address);
  }, [pathname, correctChain, chainId, address]);

  const close = () => {
    setShowModal(false);
    dispatch(setShowSwitchNetworkModal(false));

    if (!onClose) {
      return;
    }

    onClose();
  };

  const changeNetwork = async () => {
    try {
      await lgoWeb3.changeChain(addNetworkSettings);
      dispatch(setShowSwitchNetworkModal(false));
    } catch (error) {
      if (typeof error === 'string') {
        dispatch(addErrorMessageAction(error));
      }
    }
  };

  return (
    <>
      {displaySwitchNetworkModal && (
        <ModalPortal
          customStyles={{
            maxWidth: '100%',
            width: '505px',
          }}
          title="PLEASE SWITCH NETWORK"
          children={
            <div className={styles['modal-wrong-network']}>
              <p>
                In order to trade items, please switch to {isProd ? MARKET.production : MARKET.development} network
                within your {Web3Providers.metamask === currentProvider ? 'MetaMask' : 'Torus'} wallet.
              </p>
              <Button
                className="component button-full-width"
                text="SWITCH NETWORK"
                onClick={() => changeNetwork()}
              ></Button>
              <Button
                className="component button-full-width"
                text="CANCEL"
                color="default"
                onClick={() => close()}
              ></Button>
            </div>
          }
          onClose={() => close()}
        />
      )}
      {children}
    </>
  );
};
