import { SET_CURRENT_COORDS } from '../constants/actionNames';
import { ICurrentCoordReducer } from '../models/reducers/i-current-coords-reducer.interface';

const INITIAL_STATE: ICurrentCoordReducer = {
  actualCoords: [2.29464, 48.858347],
  name: 'Paris',
};

export const currentCoordsReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_CURRENT_COORDS:
      return {
        ...state,
        actualCoords: action.payload.coords,
        name: action.payload.name,
      };
    default:
      return state;
  }
};
