import { AnyLayer, AnySourceData } from 'mapbox-gl';
import * as THREE from 'three';

import { IVenueModelConfiguration } from '../models/i-venue-model-configuration';
import { IVenueShape } from '../models/i-venue.interface';

const buildCountryBorderLayerConfig = (name: string): AnyLayer => {
  return {
    id: `country-outline-${name}`,
    type: 'line',
    source: `country-${name}`,
    layout: {},
    paint: {
      'line-color': '#FDEC4E',
      'line-width': 3,
    },
  };
};

const buildCountryFillLayerConfig = (name: string): AnyLayer => {
  return {
    id: `country-boundary-${name}`,
    type: 'fill',
    source: `country-${name}`,
    paint: {
      'fill-color': '#FDEC4E',
      'fill-opacity': 0.15,
    },
    filter: ['==', '$type', 'Polygon'],
  };
};

const buildCityBorderLayerConfig = (name: string): AnyLayer => {
  return {
    id: `city-outline-${name}`,
    type: 'line',
    source: `city-${name}`,
    layout: {},
    paint: {
      'line-color': '#eee',
      'line-width': 3,
    },
  };
};

const buildCityFillLayerConfig = (name: string): AnyLayer => {
  return {
    id: `city-boundary-${name}`,
    type: 'fill',
    source: `city-${name}`,
    paint: {
      'fill-color': '#eee',
      'fill-opacity': 0.15,
    },
    filter: ['==', '$type', 'Polygon'],
  };
};

const buildContinentBorderLayerConfig = (name: string): AnyLayer => {
  return {
    id: `continent-outline-${name}`,
    type: 'line',
    source: `continent-${name}`,
    layout: {},
    paint: {
      'line-color': '#FDEC4E',
      'line-width': 3,
    },
  };
};

const buildContinentFillLayerConfig = (name: string): AnyLayer => {
  return {
    id: `continent-boundary-${name}`,
    type: 'fill',
    source: `continent-${name}`,
    paint: {
      'fill-color': '#FDEC4E',
      'fill-opacity': 0.15,
    },
    filter: ['==', '$type', 'Polygon'],
  };
};

const buildOceanBorderLayerConfig = (name: string): AnyLayer => {
  return {
    id: `ocean-outline-${name}`,
    type: 'line',
    source: `ocean-${name}`,
    layout: {},
    paint: {
      'line-color': '#FDEC4E',
      'line-width': 3,
    },
  };
};

const buildSeaBorderLayerConfig = (name: string): AnyLayer => {
  return {
    id: `sea-outline-${name}`,
    type: 'line',
    source: `sea-${name}`,
    layout: {},
    paint: {
      'line-color': '#FDEC4E',
      'line-width': 3,
    },
  };
};

const buildOceanFillLayerConfig = (name: string): AnyLayer => {
  return {
    id: `ocean-boundary-${name}`,
    type: 'fill',
    source: `ocean-${name}`,
    paint: {
      'fill-color': '#FDEC4E',
      'fill-opacity': 0.15,
    },
    filter: ['==', '$type', 'Polygon'],
  };
};

const buildSeaFillLayerConfig = (name: string): AnyLayer => {
  return {
    id: `sea-boundary-${name}`,
    type: 'fill',
    source: `sea-${name}`,
    paint: {
      'fill-color': '#FDEC4E',
      'fill-opacity': 0.15,
    },
    filter: ['==', '$type', 'Polygon'],
  };
};

const buildCountrySourceConfig = (countryShape: IVenueShape): AnySourceData => {
  return {
    type: 'geojson',
    data: countryShape,
  };
};

const buildCitySourceConfig = (geojsonSource: IVenueShape): AnySourceData => {
  return {
    type: 'geojson',
    data: geojsonSource,
  };
};

const buildContinentSourceConfig = (continentShape: IVenueShape): AnySourceData => {
  return {
    type: 'geojson',
    data: continentShape,
  };
};

const buildOceanSourceConfig = (oceanShape: IVenueShape): AnySourceData => {
  return {
    type: 'geojson',
    data: oceanShape,
  };
};

const buildSeaSourceConfig = (seaShape: IVenueShape): AnySourceData => {
  return {
    type: 'geojson',
    data: seaShape,
  };
};

const buildSceneWithLights = (): THREE.Scene => {
  const scene = new THREE.Scene();
  const pointLight = new THREE.PointLight(new THREE.Color('#FFB9D3'), 0.5, 0, 0);
  const light = new THREE.AmbientLight(new THREE.Color('#FFB9D3'), 0.4);
  const pointLight2 = new THREE.PointLight(new THREE.Color('#FFB9D3'), 0.5, 0, 0);

  scene.add(light);

  pointLight.position.set(-300, 100, 500);
  scene.add(pointLight);

  pointLight2.position.set(300, 200, -500);
  scene.add(pointLight2);

  return scene;
};

const buildTranslationMatrixRender = (venueModelConfiguration: IVenueModelConfiguration): THREE.Matrix4 => {
  const rotationX = new THREE.Matrix4().makeRotationAxis(
    new THREE.Vector3(1, 0, 0),
    venueModelConfiguration.transform.rotateX
  );
  const rotationY = new THREE.Matrix4().makeRotationAxis(
    new THREE.Vector3(0, 1, 0),
    venueModelConfiguration.transform.rotateY
  );
  const rotationZ = new THREE.Matrix4().makeRotationAxis(
    new THREE.Vector3(0, 0, 1),
    venueModelConfiguration.transform.rotateZ
  );

  const translationMatrixRender = new THREE.Matrix4()
    .makeTranslation(
      venueModelConfiguration.transform.translateX,
      venueModelConfiguration.transform.translateY,
      venueModelConfiguration.transform.translateZ as number
    )
    .scale(
      new THREE.Vector3(
        venueModelConfiguration.transform.scale,
        -venueModelConfiguration.transform.scale,
        venueModelConfiguration.transform.scale
      )
    )
    .multiply(rotationX)
    .multiply(rotationY)
    .multiply(rotationZ);

  return translationMatrixRender;
};

export const mapUtils = {
  buildSceneWithLights,
  buildTranslationMatrixRender,
  buildCountryBorderLayerConfig,
  buildCountryFillLayerConfig,
  buildCityBorderLayerConfig,
  buildCityFillLayerConfig,
  buildCountrySourceConfig,
  buildCitySourceConfig,
  buildContinentSourceConfig,
  buildContinentFillLayerConfig,
  buildContinentBorderLayerConfig,
  buildOceanSourceConfig,
  buildOceanFillLayerConfig,
  buildOceanBorderLayerConfig,
  buildSeaFillLayerConfig,
  buildSeaSourceConfig,
  buildSeaBorderLayerConfig,
};
