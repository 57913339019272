import { RefObject } from 'react';

import { clamp, getNumericOption, getQuantity } from '../utils';
import { WheelData } from '../WheelOfFortune/types';

export interface DrawWheelProps {
  fontWeight: number | string;
  fontSize: number;
  fontStyle: string;
  prizeMap: number[][];
  rouletteUpdater: boolean;
  textDistance: number;
}

export const drawWheel = (
  canvasRef: RefObject<HTMLCanvasElement>,
  data: WheelData[],
  drawWheelProps: DrawWheelProps
) => {
  const { fontWeight, fontSize, fontStyle, prizeMap, textDistance } = drawWheelProps;
  const QUANTITY = getQuantity(prizeMap);
  const canvas = canvasRef.current;

  if (canvas?.getContext('2d')) {
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.strokeStyle = 'transparent';
    ctx.lineWidth = 0;

    let startAngle = 0;
    const outsideRadius = canvas.width / 2 - 10;
    const clampedContentDistance = clamp(0, 100, textDistance);
    const contentRadius = (outsideRadius * clampedContentDistance) / 100;
    const clampedInsideRadius = clamp(0, 100, 0);
    const insideRadius = (outsideRadius * clampedInsideRadius) / 100;

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    for (let i = 0; i < data.length; i++) {
      const { optionSize, textColor, option, image } = data[i];
      const arc = (optionSize && (optionSize * (2 * Math.PI)) / QUANTITY) || (2 * Math.PI) / QUANTITY;
      const endAngle = startAngle + arc;

      // Create segment gradient
      const segmentGradient = ctx.createLinearGradient(
        centerX,
        centerY,
        centerX + Math.cos(startAngle) * outsideRadius,
        centerY + Math.sin(startAngle) * outsideRadius
      );

      if (i % 2 === 0) {
        segmentGradient.addColorStop(0, '#0E265933');
        segmentGradient.addColorStop(0.5, '#0E2659CC');
        segmentGradient.addColorStop(1, '#0E2659');
      } else {
        segmentGradient.addColorStop(0, '#14347833');
        segmentGradient.addColorStop(0.5, '#143478CC');
        segmentGradient.addColorStop(1, '#143478');
      }

      ctx.fillStyle = segmentGradient;
      ctx.beginPath();
      ctx.arc(centerX, centerY, outsideRadius, startAngle, endAngle, false);
      ctx.arc(centerX, centerY, insideRadius, endAngle, startAngle, true);
      ctx.stroke();
      ctx.fill();
      ctx.save();

      // CONTENT FILL
      ctx.translate(
        centerX + Math.cos(startAngle + arc / 2) * contentRadius,
        centerY + Math.sin(startAngle + arc / 2) * contentRadius
      );
      let contentRotationAngle = startAngle + arc / 2;

      // DRAW IMAGE
      if (image) {
        contentRotationAngle += Math.PI / 2;
        ctx.rotate(contentRotationAngle);

        const img = image?._imageHTML || new Image();
        ctx.drawImage(img, img.width / -2, -(img.height - 90 + image?.offset!) / 2, img.width, img.height);
        ctx.rotate(-contentRotationAngle);
      }

      // DRAW TEXT
      ctx.rotate(contentRotationAngle);
      const text = getNumericOption(option);
      ctx.font = `${fontStyle} ${fontWeight} ${fontSize * 2}px Rajdhani`;
      ctx.fillStyle = textColor as string;
      ctx.fillText(text || '', -ctx.measureText(text || '').width / 2, fontSize / 2.7);
      ctx.restore();

      startAngle = endAngle;
    }
  }
};
