import { NavLink } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { ROUTES } from 'constants/routes.constant';

import { useMarketEvents } from 'hooks/events/useMarketEvents';

const tabs = [
  { path: ROUTES.MARKET, label: 'NFT Market' },
  { path: ROUTES.DEALS, label: 'Deals' },
  { path: ROUTES.SPOTLIGHT, label: 'Spotlight' },
  { path: ROUTES.LOOTBOX, label: 'Loot Boxes' },
];

const TabsNavigation = () => {
  const baseClassName =
    'tw-flex tw-py-2.5 tw-px-4 tw-justify-center tw-items-center tw-space-x-1.5 tw-self-stretch tw-font-semibold tw-text-center tw-relative';
  const defaultClassName = 'tw-text-menuLinkColor';
  const activeClassName =
    'tw-text-white before:tw-content-[""] before:tw-top-0 before:tw-absolute before:tw-h-[3px] before:tw-inset-x-0 before:tw-bg-menuLinkColor';

  const { sendMarketDisplayAuction } = useMarketEvents();

  const handleEvent = (path: string) => {
    path === ROUTES.MARKET && sendMarketDisplayAuction();
  };

  return (
    <div
      className="
    tw-fixed tw-inset-x-0 tw-bottom-0 tw-z-10 tw-flex tw-w-full tw-flex-grow tw-items-center tw-justify-between tw-self-stretch tw-bg-backgroundColorMedium tw-px-4 tw-shadow-[0_-10px_30px_0_rgba(0,0,0,0.2)] sm:tw-px-10 md:tw-hidden"
    >
      {tabs.map(({ path, label }, index) => (
        <NavLink
          key={index}
          to={path}
          className={({ isActive }) => twMerge(baseClassName, isActive ? activeClassName : defaultClassName)}
          onClick={() => handleEvent(path)}
        >
          {label}
        </NavLink>
      ))}
    </div>
  );
};
export default TabsNavigation;
