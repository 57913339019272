import { useSelector } from 'react-redux';

import { IState } from '../../models/reducers/i-state.interface';

import Page from '../../components/page/Page';
import PageWrapper from '../../components/pageWrapper/PageWrapper';

import { getIsEmpty } from '../../utils/get-is-empty.utils';

import LootboxMainView from './lootboxMainView/LootboxMainView';
import LootboxTransactionView from './lootboxTransactionView/LootboxTransactionView';

const LootboxRoute = () => {
  const { openStep, purchaseStep } = useSelector((state: IState) => state.lootbox);

  const showMainPage = getIsEmpty(purchaseStep) && getIsEmpty(openStep);
  const showProgressView = !showMainPage;

  return (
    <Page
      className="tw-overflow-x-hidden tw-text-center"
      pageBackground="dark"
      hideLoader
      requireTermsOfServiceConsents
      withScrollbar
    >
      <PageWrapper pageType="minimized">
        {showProgressView && <LootboxTransactionView />}
        {showMainPage && <LootboxMainView />}
      </PageWrapper>
    </Page>
  );
};

export default LootboxRoute;
