import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { Notifications } from 'enums/notifications/notifications.enum';

import useTimeout from 'hooks/useTimeout';

import Icon from '../../icon/Icon';
import styles from './Notification.module.scss';

interface INotificationProps {
  message: string;
  type: Notifications;
  iconId: string;
  iconAltText: string;
  onClose: () => void;
}

const Notification = ({ message, iconId, iconAltText, type, onClose }: INotificationProps) => {
  const fadeOutDelay = 5000;
  const animationTimeout = 200;
  const [showNotification, setShowNotification] = useState(false);
  const notificationRef = useRef(null);

  const hideNotification = () => {
    setShowNotification(false);
  };

  useEffect(() => {
    setShowNotification(true);
  }, []);

  useTimeout(hideNotification, fadeOutDelay);

  return (
    <CSSTransition
      unmountOnExit
      className="fade-scale-animation"
      nodeRef={notificationRef}
      in={showNotification}
      timeout={animationTimeout}
      onExited={onClose}
    >
      <div ref={notificationRef}>
        <div className={`${styles['notification']} ${styles[`notification--${type}`]}`} role="alert">
          <div className={styles['icon-wrapper']}>
            <Icon className={styles['type-icon']} id={iconId} altText={iconAltText} />
          </div>
          <p className={styles['message']}>{message}</p>
          <button className={`button--styleless ${styles['close-button']}`} onClick={hideNotification}>
            <Icon className={styles['close-icon']} id="close-icon" altText="Close notification" />
          </button>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Notification;
