import styles from './LootboxProbabilityBox.module.scss';

interface ILootboxProbabilityBoxRow {
  title?: string;
  content?: string;
}

const LootboxProbabilityBoxRow = ({ title, content }: ILootboxProbabilityBoxRow) => {
  return (
    <div className={styles['probability-row']}>
      <p className={styles['probability-row__title']}>{title}</p>
      <div className={styles['probability-row__content']}>{content}</div>
    </div>
  );
};

interface ILootboxProbabilityBoxProps {
  tier1NFTChance?: string;
  tier2NFTChance?: string;
  tier3NFTChance?: string;
  chancesInfo?: string;
}

const LootboxProbabilityBox = ({
  tier1NFTChance,
  tier2NFTChance,
  tier3NFTChance,
  chancesInfo,
}: ILootboxProbabilityBoxProps) => {
  return (
    <div className={styles['probability-box']}>
      <p className={styles['probability-box__title']}>Probability rates</p>

      <div className={styles['probability-box__content']}>
        {tier1NFTChance && <LootboxProbabilityBoxRow title="Tier 1 NFT" content={tier1NFTChance} />}
        {tier2NFTChance && <LootboxProbabilityBoxRow title="Tier 2 NFT" content={tier2NFTChance} />}
        {tier3NFTChance && <LootboxProbabilityBoxRow title="Tier 3 NFT" content={tier3NFTChance} />}
        {!tier1NFTChance && !tier2NFTChance && !tier3NFTChance ? chancesInfo : ''}
      </div>
    </div>
  );
};

export default LootboxProbabilityBox;
