import { AxiosResponse } from 'axios';

import {
  API_ERROR_CODE_MOCK,
  API_ERROR_STATUS_TEXT_MOCK,
  API_SUCCESS_CODE_MOCK,
  API_SUCCESS_STATUS_TEXT_MOCK,
} from '../mocks/abi.mocks';
import { CURRENCIES_RESPONSE_MOCK } from '../mocks/currencies.mocks';
import { MARKET_OFFERS_MOCK, USDC_MULTIPLIER } from '../mocks/market.mocks';
import { MESSAGES } from '../mocks/translation.mocks';

const delayPromise = <T>(data: T, delayTime: number): Promise<T> => {
  return new Promise((resolve) => setTimeout(() => resolve(data), delayTime));
};
const getAxiosErrorResponse = <T>(data: T): AxiosResponse<T> =>
  ({
    data,
    status: API_ERROR_CODE_MOCK,
    statusText: API_ERROR_STATUS_TEXT_MOCK,
  } as any);

const getAxiosSuccessResponse = <T>(data: T): AxiosResponse<T> =>
  ({
    data,
    status: API_SUCCESS_CODE_MOCK,
    statusText: API_SUCCESS_STATUS_TEXT_MOCK,
  } as any);

export const getApiAxiosRequestMock = <T>(
  response: T,
  error: boolean = false,
  delay: number = 0
): Promise<AxiosResponse<T>> => {
  if (error) {
    return delayPromise(getAxiosErrorResponse(response), delay);
  }

  return delayPromise(getAxiosSuccessResponse(response), delay);
};

export const getCurrenciesMock = () => {
  return getApiAxiosRequestMock(CURRENCIES_RESPONSE_MOCK);
};

// Market
export const getMarketOffersMock = () => {
  return getApiAxiosRequestMock(MARKET_OFFERS_MOCK);
};

export const getRMVMultiplierMock = () => {
  return getApiAxiosRequestMock(2.5);
};

export const getUsdcMultiplierMock = () => {
  return getApiAxiosRequestMock(USDC_MULTIPLIER);
};

export const getTranslationMock = () => {
  return getApiAxiosRequestMock(MESSAGES);
};
