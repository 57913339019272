import { IDispatchAxiosAction } from '../models/dispatch-action.interface';
import { IProfile } from 'models/profile/i-profile.interface';
import { getProfile, patchProfile } from '../services/profile-service';
import { FETCH_PROFILE, PATCH_PROFILE } from './actionNames/profileActionNames';

export const fetchProfile = (): IDispatchAxiosAction<IProfile> => ({
  payload: getProfile(),
  type: FETCH_PROFILE,
});

export const updateProfile = (profileData: FormData): IDispatchAxiosAction<IProfile> => ({
  payload: patchProfile(profileData),
  type: PATCH_PROFILE,
});
