import { sendGA4Event } from 'services/analytics-events.service';

import { INftParams, ISourceScreen, useEventParamsMethods } from './useEventsParams';

enum FundsEventName {
  BuyClicked = 'buy_rmv_clicked',
  NotEnough = 'not_enough_rmv',
  AddFundsWithCard = 'add_funds_with_card',
  AddFundsError = 'add_funds_error',
}

export enum SourceScreenName {
  CompleteCheckoutModal = 'complete_checkout_modal',
  MakeOfferModal = 'make_offer_modal',
  MobileHeader = 'mobile_header',
  RegularHeader = 'regular_header',
  UserDropdown = 'user_dropdown',
  LandingBanner = 'landing_banner',
}

export const useFundsEvents = () => {
  const { withWalletAddress } = useEventParamsMethods();

  const sendFundsBuyClickedEvent = (params: ISourceScreen) => {
    sendGA4Event(FundsEventName.BuyClicked, withWalletAddress(params));
  };

  const sendFundsNotEnoughEvent = (params: INftParams & ISourceScreen) => {
    sendGA4Event(FundsEventName.NotEnough, withWalletAddress(params));
  };

  const sendFundsAddWithCardEvent = (params: ISourceScreen) => {
    sendGA4Event(FundsEventName.AddFundsWithCard, withWalletAddress(params));
  };

  const sendFundsAddErrorEvent = (params: ISourceScreen) => {
    sendGA4Event(FundsEventName.AddFundsError, withWalletAddress(params));
  };

  return { sendFundsBuyClickedEvent, sendFundsNotEnoughEvent, sendFundsAddWithCardEvent, sendFundsAddErrorEvent };
};
