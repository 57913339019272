import { createContext, FC, useContext, useState } from 'react';

import { IPropsWithChildrenAndClassName, Noop } from 'types/common.interface';

import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import './ElementLoader.scss';

export interface IElementLoaderContext {
  enableUi: Noop;
  disableUi: Noop;
}

export const ElementLoaderContext = createContext<IElementLoaderContext>({
  enableUi: () => {},
  disableUi: () => {},
});

export const useElementLoaderContext = () => useContext(ElementLoaderContext);

interface IElementLoader extends IPropsWithChildrenAndClassName {
  loading?: boolean;
}

const ElementLoader: FC<IElementLoader> = ({ loading, children, className = '' }) => {
  const [disableUi, setDisableUi] = useState(true);
  const classes = `element-loader ${loading ?? disableUi ? 'disable-ui' : ''} ${className}`;

  return (
    <ElementLoaderContext.Provider
      value={{
        enableUi: () => setDisableUi(false),
        disableUi: () => setDisableUi(true),
      }}
    >
      <span className={classes}>
        <LoadingSpinner />
      </span>
      {children}
    </ElementLoaderContext.Provider>
  );
};

export default ElementLoader;
