import { ChangeEvent, FC, PropsWithChildren, useState } from 'react';

import { IValidation } from 'models/i-form-validation.interface';

import InputValidation from 'components/inputValidation/InputValidation';

import CheckboxIcon from 'assets/icons/checkbox_icon.svg';

import './Checkbox.scss';

interface ICheckboxProps extends PropsWithChildren {
  name: string;
  checked: boolean;
  validations?: IValidation<boolean>[];
  onChange: (checked: boolean) => void;
  className?: string;
}

const Checkbox: FC<ICheckboxProps> = ({ children, name, checked, validations, onChange, className }) => {
  const [checkboxChecked, setCheckboxChecked] = useState(checked);
  const classNames = [`checkbox ${checkboxChecked ? 'checkbox--checked' : ''}`, className];

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const checked = event.target.checked;

    setCheckboxChecked(checked);
    onChange(checked);
  };

  return (
    <>
      <label className={classNames.join(' ')} htmlFor={`checkbox-${name}`}>
        <input type="checkbox" checked={checkboxChecked} onChange={handleChange} id={`checkbox-${name}`} />
        <div className="checkbox__check-mark" aria-hidden>
          <div className="checkbox__check-mark-overlay">
            <img src={CheckboxIcon} alt="" />
          </div>
        </div>
        <div className="checkbox__check-label">{children}</div>
      </label>
      {validations?.map((validation) => (
        <InputValidation key={validation.key} validator={validation.validator} value={checked} />
      ))}
    </>
  );
};

export default Checkbox;
