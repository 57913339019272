export const centralEllipsis = (inputString?: string, maxCharsVisible = 10): string => {
  const ellipsisString = '...';

  if (!inputString) {
    return '';
  }

  if (inputString.length < maxCharsVisible) {
    return inputString;
  }

  const ellipsisStartIndex = Math.floor(maxCharsVisible / 2) + 2;
  const ellipsisEndIndex = inputString.length - Math.floor(maxCharsVisible / 2) - 2;

  return `${inputString.slice(0, ellipsisStartIndex)}${ellipsisString}${inputString.slice(ellipsisEndIndex)}`;
};

export const endEllipsis = (inputString: string, maxCharsVisible = 10): string => {
  if (!inputString) {
    return '';
  }

  const regexp = new RegExp(`(.{${maxCharsVisible}})(.*)`);

  return inputString.replace(regexp, (match, visibleGroup) => {
    return `${visibleGroup}...`;
  });
};
