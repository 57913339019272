import Cookies, { CookieSetOptions } from 'universal-cookie/es6';

import { CookiesKeys } from '../enums/cookies-keys.enum';

interface IUseCookies {
  setCookie: (key: CookiesKeys, value: any, expires?: Date) => void;
  removeCookie: (key: CookiesKeys) => void;
  getCookie: <T = string>(key: CookiesKeys) => T;
}

export const useCookies = (): IUseCookies => {
  const cookies = new Cookies();

  const setCookie = (key: CookiesKeys, value: any, expires?: Date) => {
    const options: CookieSetOptions = { path: '/' };

    if (expires) {
      options.expires = expires;
    }

    cookies.set(key, value, options);
  };

  const removeCookie = (key: CookiesKeys) => {
    cookies.remove(key, { path: '/' });
  };

  const getCookie = (key: CookiesKeys): any => {
    return cookies.get(key);
  };

  return {
    setCookie,
    removeCookie,
    getCookie,
  };
};
