import LandingHeader from 'components/menu/landingHeader/LandingHeader';
import LandingMobileHeader from 'components/menu/landingMobileHeader/LandingMobileHeader';
import MobileHeader from 'components/menu/mobileHeader/MobileHeader';
import RegularHeader from 'components/menu/regularHeader/RegularHeader';
import TabsNavigation from 'components/tabsNavigation/TabsNavigation';

import styles from './Menu.module.scss';

interface IMenuInterface {
  isLanding: boolean;
  isStaking: boolean;
}

const Menu = ({ isLanding, isStaking }: IMenuInterface) => {
  return (
    <header className={styles['header']}>
      {isLanding || isStaking ? (
        <>
          <LandingHeader className={styles['menu--regular']} isStaking={isStaking} />
          <LandingMobileHeader className={styles['menu--mobile']} isStaking={isStaking} />
        </>
      ) : (
        <>
          <MobileHeader className={styles['menu--mobile']} />
          <RegularHeader className={styles['menu--regular']} />
          <TabsNavigation />
        </>
      )}
    </header>
  );
};

export default Menu;
