import SharesIcon from '../../../assets/icons/shares-icon.svg';

import styles from './NftSharesSection.module.scss';

interface INftSharesSectionProps {
  shares: string;
  className?: string;
}

const NftSharesSection = ({ shares, className = '' }: INftSharesSectionProps) => {
  const classNames = [className, styles['shares-section']];

  return (
    <div className={classNames.join(' ')}>
      <img alt="Shares" className={styles['shares-icon']} src={SharesIcon} />
      <p>{shares || '-'}</p>
    </div>
  );
};

export default NftSharesSection;
