import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ERROR_MESSAGES } from '../../constants/error-messages.constant';

import { addErrorMessageAction } from '../../actions/global-actions';

import Web3Singleton from '../../web3Singleton';
import { useEnv } from '../useEnv';

export const useMetamaskAddPolygon = () => {
  const lgoWeb3 = Web3Singleton.getInstance();
  const { t } = useTranslation('notifications');
  const dispatch = useDispatch();
  const { addNetworkSettings } = useEnv();

  const addPolygon = async () => {
    try {
      await lgoWeb3.changeChain(addNetworkSettings);
    } catch (error: any) {
      dispatch(addErrorMessageAction(error?.message || t(ERROR_MESSAGES.default)));
    }
  };

  return addPolygon;
};
