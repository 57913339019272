import ReactGA from 'react-ga4';
import snakecaseKeys from 'snakecase-keys';

export const extractTransactionData = (errorMessage: string) => {
  const exceptionRegex = /missing revert data in call exception/;

  if (exceptionRegex.test(errorMessage)) {
    return { reason: 'missing revert data in call exception' };
  }

  const anotherProcessRegex = /eth_another process in progress/;

  if (anotherProcessRegex.test(errorMessage)) {
    return { reason: 'login already in progress' };
  }

  const metamaskRegex = /MetaMask/;
  const torusRegex = /Torus/;
  const userCancelledRegex = /user has no account connected|user cancelled login|user closed popup/i;

  if (metamaskRegex.test(errorMessage) || torusRegex.test(errorMessage) || userCancelledRegex.test(errorMessage)) {
    return { reason: errorMessage };
  }

  const reasonRegex = /Reason: ([^\n]+)/;
  const chainRegex = /chain:\s+([^\n]+)/;
  const methodRegex = /method:\s+([\s\S]+?)\n/s;
  const methodNameRegex = /(\w+)\(/;

  const reasonMatch = errorMessage.match(reasonRegex);
  const chainMatch = errorMessage.match(chainRegex);
  const methodMatch = errorMessage.match(methodRegex);

  let methodName = '';
  if (methodMatch) {
    const methodNameMatch = methodMatch[1].match(methodNameRegex);
    if (methodNameMatch) {
      methodName = methodNameMatch[1].trim();
    }
  }

  const reason = reasonMatch?.[1]?.trim() ?? '';
  const chain = chainMatch?.[1]?.trim() ?? '';

  return { reason, chain, method: methodName };
};

export const adaptEventParams = <T extends Record<string, any>>(obj: T): T => {
  const result: Record<string, any> = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'undefined') return;
    else if (typeof value === 'object' && value !== null && JSON.stringify(value) !== '{}' && !Array.isArray(value)) {
      result[key] = adaptEventParams(value);
    } else {
      if (key === 'message') {
        result[key] = JSON.stringify(adaptEventParams(extractTransactionData(value)));
      } else if (Array.isArray(value)) {
        result[key] = value.map((item: any) => JSON.stringify(item));
      } else {
        result[key] = JSON.stringify(value);
      }
    }
  });

  return snakecaseKeys(result) as T;
};

export const sendGA4Event = <N extends string, T extends readonly any[] | Record<string, any>>(name: N, params: T) => {
  ReactGA.event(name, adaptEventParams(params));
};
