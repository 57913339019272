import { VENUES_CARD_VIDEO_SET_ACTIVE } from '../actions/actionNames/venuesCardVideoActionNames';
import { IVenuesCardVideoReducer } from '../models/reducers/i-venues-card-video-reducer.interface';

const INITIAL_STATE: IVenuesCardVideoReducer = {
  activeVideo: '',
};

export const venuesCardVideoReducer = (state = INITIAL_STATE, action: any): IVenuesCardVideoReducer => {
  switch (action.type) {
    case VENUES_CARD_VIDEO_SET_ACTIVE:
      return { ...state, activeVideo: action.payload };
    default:
      return state;
  }
};
