import { MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';

import { getFileExtension } from 'utils/get-file-extension.util';
import { getIsVideoFormat } from 'utils/get-is-video-format.util';

import { ReactComponent as PauseButton } from 'assets/icons/pause-icon.svg';
import { ReactComponent as PlayButton } from 'assets/icons/play-icon.svg';

import styles from './LootboxBackground.module.scss';

interface ILootboxBackgroundProps {
  autoplay?: boolean;
  allowAnimation?: boolean;
  background?: string | null;
  className?: string;
  children?: ReactNode;
  loop?: boolean;
  animationFinished?: () => void;
}

const LootboxBackground = ({
  allowAnimation,
  autoplay,
  background,
  children,
  loop,
  className = '',
  animationFinished,
}: ILootboxBackgroundProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoPlaying, setVideoPlaying] = useState<boolean>(false);
  const isVideo = getIsVideoFormat(background);
  const backgroundFormat = getFileExtension(background);

  useEffect(() => {
    if (!isVideo && animationFinished) {
      animationFinished();
    }
  }, [isVideo, animationFinished]);

  useEffect(() => {
    const videRefElement = videoRef.current;

    if (!videRefElement || !animationFinished) {
      return;
    }

    videRefElement.addEventListener('ended', animationFinished);

    return () => {
      videRefElement.removeEventListener('ended', animationFinished);
    };
  }, [videoRef, animationFinished]);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    if (autoplay) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, [autoplay]);

  const playPauseVideo = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (videoPlaying) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }

    setVideoPlaying(!videoPlaying);
  };

  return (
    <div className={className}>
      {isVideo && background && (
        <video
          className={styles['lootbox-background']}
          autoPlay={autoplay}
          ref={videoRef}
          muted
          playsInline
          loop={loop}
          preload="auto"
        >
          <source src={background} type={`video/${backgroundFormat}`} />
        </video>
      )}
      {isVideo && allowAnimation && !autoplay ? (
        <button className={styles['lootbox-background__play-button']} onClick={(event) => playPauseVideo(event)}>
          {videoPlaying ? <PauseButton /> : <PlayButton />}
        </button>
      ) : (
        <></>
      )}
      {!isVideo && background && <img src={background} alt="" className={styles['lootbox-background']} />}
      {children}
    </div>
  );
};

export default LootboxBackground;
