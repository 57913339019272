export const NEGATIVE_STAKE_CONTRACT_ABI: any[] = [
  {
    type: 'constructor',
    inputs: [
      {
        name: 'stakingTokenAddress',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'rewardTokenAddress',
        type: 'address',
        internalType: 'address',
      },
      {
        name: '_defaultStakingTarget',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: '_defaultMinimumDeposit',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: '_confirmationCode',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'REWARD_TOKEN',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'contract IERC20Metadata',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'STAKING_TOKEN',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'contract IERC20Metadata',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'addContractAdmin',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'addStakingPoolCustom',
    inputs: [
      {
        name: 'typeToSet',
        type: 'uint8',
        internalType: 'enum ProgramManager.PoolType',
      },
      {
        name: 'stakingTargetToSet',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'minimumDepositToSet',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'stakingAvailabilityStatus',
        type: 'bool',
        internalType: 'bool',
      },
      {
        name: 'APYToSet',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'stakingFeeToSet',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'addStakingPoolDefault',
    inputs: [
      {
        name: 'typeToSet',
        type: 'uint8',
        internalType: 'enum ProgramManager.PoolType',
      },
      {
        name: 'APYToSet',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'stakingFeeToSet',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'changePoolAvailabilityStatus',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'parameterToChange',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'valueToAssign',
        type: 'bool',
        internalType: 'bool',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'changeTreasuryAddress',
    inputs: [
      {
        name: 'newTreasury',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'checkAPY',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkClaimableRewardBy',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'depositNumber',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkConfirmationCode',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkDefaultMinimumDeposit',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkDefaultStakingTarget',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkDepositCountOfAddress',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkDepositStakedAmount',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'depositNumber',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkEndDate',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkGeneratedRewardDailyTotal',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'ifPrecise',
        type: 'bool',
        internalType: 'bool',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkGeneratedRewardLastDayFor',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkIfPoolEnded',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'bool',
        internalType: 'bool',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkIfRewardClaimOpen',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'bool',
        internalType: 'bool',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkIfStakingOpen',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'bool',
        internalType: 'bool',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkIfWithdrawalOpen',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'bool',
        internalType: 'bool',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkMinimumDeposit',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkPoolCount',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkPoolType',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkRestoredFundsBy',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkRewardClaimedBy',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkRewardPool',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkRewardProvidedBy',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkStakedAmountBy',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkStakingFee',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkStakingTarget',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkTotalClaimableReward',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkTotalClaimableRewardBy',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkTotalFeePaid',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkTotalFundCollected',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkTotalFundRestored',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkTotalRewardClaimed',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkTotalStaked',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkTotalWithdrawn',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkWithdrawnAmountBy',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'claimAllReward',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'claimReward',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'depositNumber',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'collectFunds',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'tokenAmount',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'collectRewardPoolFunds',
    inputs: [
      {
        name: 'tokenAmount',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'contractAdmins',
    inputs: [
      {
        name: '',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'bool',
        internalType: 'bool',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'contractOwner',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'address',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'endStakingPool',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: '_confirmationCode',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'pauseProgram',
    inputs: [],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'provideReward',
    inputs: [
      {
        name: 'tokenAmount',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'removeContractAdmin',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'restoreFunds',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'tokenAmount',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'resumeProgram',
    inputs: [],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'safeStake',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'tokenAmount',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'forMaxFeePercentage',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setDefaultMinimumDeposit',
    inputs: [
      {
        name: 'newDefaultMinimumDeposit',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setDefaultStakingTarget',
    inputs: [
      {
        name: 'newStakingTarget',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setPoolAPY',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'newAPY',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setPoolMiniumumDeposit',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'newMinimumDeposit',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setPoolStakingFee',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'newStakingFee',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setPoolStakingTarget',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'newStakingTarget',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'transferOwnership',
    inputs: [
      {
        name: 'userAddress',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'treasury',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'address',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'withdrawAll',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'withdrawDeposit',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'depositNumber',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'event',
    name: 'AddContractAdmin',
    inputs: [
      {
        name: 'user',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'AddStakingPool',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'poolType',
        type: 'uint8',
        indexed: true,
        internalType: 'enum ProgramManager.PoolType',
      },
      {
        name: 'stakingTarget',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'APY',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'minimumDeposit',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'ChangeTreasuryAddress',
    inputs: [
      {
        name: 'from',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'to',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'ClaimReward',
    inputs: [
      {
        name: 'by',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'poolID',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
      {
        name: 'depositNumber',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'tokenAmount',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'CollectFunds',
    inputs: [
      {
        name: 'by',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'poolID',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
      {
        name: 'tokenAmount',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'CollectReward',
    inputs: [
      {
        name: 'by',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'tokenAmount',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'CreateProgram',
    inputs: [
      {
        name: 'stakingTokenTicker',
        type: 'string',
        indexed: false,
        internalType: 'string',
      },
      {
        name: 'stakingTokenAddress',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: '_defaultStakingTarget',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: '_defaultMinimumDeposit',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'EndStakingPool',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'PauseProgram',
    inputs: [],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'ProvideReward',
    inputs: [
      {
        name: 'by',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'tokenAmount',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'RemoveContractAdmin',
    inputs: [
      {
        name: 'user',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'RestoreFunds',
    inputs: [
      {
        name: 'by',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'poolID',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
      {
        name: 'tokenAmount',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'ResumeProgram',
    inputs: [],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'Stake',
    inputs: [
      {
        name: 'by',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'poolID',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
      {
        name: 'poolType',
        type: 'uint8',
        indexed: true,
        internalType: 'enum ProgramManager.PoolType',
      },
      {
        name: 'depositNumber',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'stakedAmount',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'stakingFeePercentage',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'stakingFeePaid',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'TransferOwnership',
    inputs: [
      {
        name: 'from',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'to',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'UpdateAPY',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
      {
        name: 'newAPY',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'UpdateDefaultMinimumDeposit',
    inputs: [
      {
        name: 'newDefaultMinimumDeposit',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'UpdateDefaultStakingTarget',
    inputs: [
      {
        name: 'newDefaultStakingTarget',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'UpdateMinimumDeposit',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'newMinimumDeposit',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'UpdateRewardClaimStatus',
    inputs: [
      {
        name: 'by',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'poolID',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'isOpen',
        type: 'bool',
        indexed: false,
        internalType: 'bool',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'UpdateStakingFee',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
      {
        name: 'newStakingFee',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'UpdateStakingStatus',
    inputs: [
      {
        name: 'by',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'poolID',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'isOpen',
        type: 'bool',
        indexed: false,
        internalType: 'bool',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'UpdateStakingTarget',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'newStakingTarget',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'UpdateWithdrawalStatus',
    inputs: [
      {
        name: 'by',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'poolID',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'isOpen',
        type: 'bool',
        indexed: false,
        internalType: 'bool',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'Withdraw',
    inputs: [
      {
        name: 'by',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'poolID',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
      {
        name: 'poolType',
        type: 'uint8',
        indexed: true,
        internalType: 'enum ProgramManager.PoolType',
      },
      {
        name: 'depositNumber',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'tokenAmount',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'error',
    name: 'AddressEmptyCode',
    inputs: [
      {
        name: 'target',
        type: 'address',
        internalType: 'address',
      },
    ],
  },
  {
    type: 'error',
    name: 'AddressInsufficientBalance',
    inputs: [
      {
        name: 'account',
        type: 'address',
        internalType: 'address',
      },
    ],
  },
  {
    type: 'error',
    name: 'AmountExceedsPoolTarget',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: '_tokenSent',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'availableAmountToStake',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'DepositDoesNotExist',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'depositNumber',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'FailedInnerCall',
    inputs: [],
  },
  {
    type: 'error',
    name: 'InsufficentDeposit',
    inputs: [
      {
        name: '_tokenSent',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: '_requiredAmount',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'InsufficientAllowance',
    inputs: [
      {
        name: 'intendedAmount',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'approvedAmount',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'InsufficientBalance',
    inputs: [
      {
        name: 'intendedAmount',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'available',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'InvalidArgumentValue',
    inputs: [
      {
        name: 'argument',
        type: 'string',
        internalType: 'string',
      },
      {
        name: 'minValue',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'NoFundsStaked',
    inputs: [],
  },
  {
    type: 'error',
    name: 'NoPoolsCreatedYet',
    inputs: [],
  },
  {
    type: 'error',
    name: 'NotEnoughFundsInThePool',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'requestedAmount',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'availableAmount',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'NotEnoughFundsInTheRewardPool',
    inputs: [
      {
        name: 'requestedAmount',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'availableAmount',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'NotOpen',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: '_action',
        type: 'string',
        internalType: 'string',
      },
    ],
  },
  {
    type: 'error',
    name: 'PoolDoesNotExist',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'PoolEnded',
    inputs: [
      {
        name: 'poolID',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'ReentrancyGuardReentrantCall',
    inputs: [],
  },
  {
    type: 'error',
    name: 'RestorationExceedsCollected',
    inputs: [
      {
        name: '_tokenSent',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: '_RemainingAmountToRestore',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'SafeERC20FailedOperation',
    inputs: [
      {
        name: 'token',
        type: 'address',
        internalType: 'address',
      },
    ],
  },
  {
    type: 'error',
    name: 'StakingFeePercentageOverflow',
    inputs: [
      {
        name: 'input',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'maxPercentage',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
  },
  {
    type: 'error',
    name: 'UnauthorizedAccess',
    inputs: [
      {
        name: 'requiredAccessTier',
        type: 'uint8',
        internalType: 'enum AccessControl.AccessTier',
      },
    ],
  },
];
