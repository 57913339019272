import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IPropsWithChildrenAndClassName, IPropsWithChildrenAndLabel } from 'types/common.interface';

interface INftCardLabelPanel extends IPropsWithChildrenAndClassName, IPropsWithChildrenAndLabel {
  primary?: boolean;
  secondary?: boolean;
  size?: 'lg' | 'base';
}

const NftCardLabelPanel: FC<INftCardLabelPanel> = ({
  primary,
  secondary,
  size = 'base',
  label,
  children,
  className,
}) => {
  return (
    <div
      className={twMerge(
        'tw-flex tw-items-center tw-justify-between tw-rounded-md tw-px-2',
        size === 'lg' && ' tw-py-[7px]',
        size === 'base' && 'tw-py-[5px]',
        primary ? 'tw-bg-backgroundColorDark' : 'tw-bg-[#0e265980]',
        !primary && secondary && 'tw-bg-[#14347880]'
      )}
    >
      <span
        className={twMerge(
          'tw-font-semibold tw-text-menuLinkColor',
          size === 'lg' && 'tw-text-[18px]/[18px]',
          size === 'base' && 'tw-text-base/[18px]',
          className
        )}
      >
        {label}
      </span>
      {children}
    </div>
  );
};

export default NftCardLabelPanel;
