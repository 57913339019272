// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from 'mapbox-gl';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as THREE from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { setCurrentDisplayedVenue } from '../../actions/map.action';
import { MAP_CONSTANT } from '../../constants/map.constant';
import { API_KEY, MAP_STYLE } from '../../constants/MapboxConfig';
import { NO_VENUE } from '../../constants/no-venue.constant';
import { useMapRatioZoom } from '../../hooks/useMapRatioZoom';
import { IVenue, IVenueShape } from '../../models/i-venue.interface';
import { IState } from '../../models/reducers/i-state.interface';
import { map3dModelBuilder } from '../../utils/map-model.utils';
import { mapUtils } from '../../utils/map.utils';
import { useAppDispatch } from '../../utils/useAppDispatch';

mapboxgl.accessToken = API_KEY;

const dracoLoader = new DRACOLoader();

dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.3/');
dracoLoader.setDecoderConfig({ type: 'js' });
dracoLoader.preload();

interface IMainMapComponentProps {
  onFlyAnimationFinished: (flyAnimationFinished: boolean) => void;
}

const MainMapComponent = ({ onFlyAnimationFinished }: IMainMapComponentProps) => {
  const mapConstant = MAP_CONSTANT;
  const zoomedInPitch = 54;

  const getMapRatioZoom = useMapRatioZoom();
  const dispatch = useAppDispatch();
  const speed = 0.1;
  const mapboxglMapContainerId = 'mapboxgl-container';

  const [mapIsReady, setMapIsReady] = useState(false);
  const [currentMap, setCurrentMap] = useState<mapboxgl.Map | null>();

  const currentDisplayedVenue = useSelector((state: IState) => state.map.currentDisplayedVenue);
  const activeVenue = useSelector((state: IState) => state.venuesActive.activeVenue);
  const mapRotate = useSelector((state: IState) => state.map.rotate);

  const mapRotateRef = useRef(mapRotate);

  const animation = useRef<number>();
  const rotateTo = useRef(0);

  const glftLoader = new GLTFLoader();

  const cachedLayerIdSpecialVenues = useRef<string[]>([]);

  // -1 cache for all buildings, 0 no cache
  let maxCachedSpecialVenuesLayersAmount = 0;

  useEffect(() => {
    if (currentMap) return;

    const mapboxGlMap = new mapboxgl.Map({
      style: MAP_STYLE,
      center: [activeVenue.lon, activeVenue.lat],
      zoom: getMapRatioZoom(activeVenue.zoom, activeVenue.lat),
      pitch: zoomedInPitch,
      scrollZoom: false,
      bearing: 0,
      container: mapboxglMapContainerId,
      antialias: false,
      dragPan: false,
      dragRotate: false,
      doubleClickZoom: false,
      keyboard: false,
      projection: {
        name: 'globe'
      }
    });

    mapboxGlMap.on('idle', () => {
      setMapIsReady(true);
    });

    setCurrentMap(mapboxGlMap);
  }, []);

  useEffect(() => {
    if (!mapIsReady) {
      return;
    }

    if (!(activeVenue as IVenue).id) {
      clearVenueLayer(currentDisplayedVenue as IVenue);
      flyToWholeMapView();

      return;
    }

    flyToNewPosition(activeVenue.lat, activeVenue.lon, activeVenue.zoom);
  }, [activeVenue, mapIsReady]);

  useEffect(() => {
    if (!currentMap) {
      return;
    }

    const noVanueToShow = !(activeVenue as IVenue).id || !currentDisplayedVenue;

    mapRotateRef.current = mapRotate;
    currentMap.once('style.load', function () {
      if (mapRotate === false || noVanueToShow) {
        stopRotatingCamera();
      } else {
        startRotatingCamera();
      }

      return;
    });

    if (currentMap.isStyleLoaded()) {
      if (mapRotate === false || noVanueToShow) {
        stopRotatingCamera();
      } else {
        startRotatingCamera();
      }

      return;
    }

    stopRotatingCamera();
  }, [mapRotate, currentMap]);

  const clearVenueLayer = (venue: IVenue): void => {
    if (!venue?.name || !venue?.type) {
      return;
    }

    const venueTypeLowerCase = venue.type.toLowerCase();

    switch (venueTypeLowerCase) {
      case mapConstant.venuesCategory.country || mapConstant.venuesCategory.continent || mapConstant.venuesCategory.ocean:
        clearLayer(`country-boundary-${venue.name}`);
        clearLayer(`country-outline-${venue.name}`);
        clearLayer(`country-icon-${venue.name}`);
        break;
      case mapConstant.venuesCategory.city:
        clearLayer(`city-boundary-${venue.name}`);
        clearLayer(`city-outline-${venue.name}`);
        clearSource(`city-${venue.name}`);
        break;
      case mapConstant.venuesCategory.continent:
        clearLayer(`continent-boundary-${venue.name}`);
        clearLayer(`continent-outline-${venue.name}`);
        clearLayer(`continent-icon-${venue.name}`);
        break;
      case mapConstant.venuesCategory.ocean:
        clearLayer(`ocean-boundary-${venue.name}`);
        clearLayer(`ocean-outline-${venue.name}`);
        clearLayer(`ocean-icon-${venue.name}`);
        break;
      case mapConstant.venuesCategory.sea:
        clearLayer(`sea-boundary-${venue.name}`);
        clearLayer(`sea-outline-${venue.name}`);
        clearLayer(`sea-icon-${venue.name}`);
        break;
      default:
        clearBuildingLayer(venue.id);
    }
  };

  const clearLayer = (layerId: string): void => {
    if (!currentMap) {
      return;
    }

    if (currentMap.getLayer(layerId)) {
      currentMap.removeLayer(layerId);
    }
  };

  const clearSource = (sourceId: string): void => {
    if (!currentMap) {
      return;
    }

    if (currentMap.getSource(sourceId)) {
      currentMap.removeSource(sourceId);
    }
  };

  const clearBuildingLayer = (venueId: string): void => {
    if (!currentMap || maxCachedSpecialVenuesLayersAmount < 0) {
      return;
    }

    const layerName = `layer-${venueId}`;
    const layer = currentMap.getLayer(layerName);

    if (maxCachedSpecialVenuesLayersAmount === 0 && layer) {
      currentMap.removeLayer(layerName);
    } else {
      addToCachedAndRemoveLastIfToMany(layerName);
    }
  };

  const addToCachedAndRemoveLastIfToMany = (layerName: string): void => {
    if (!currentMap) {
      return;
    }

    if (cachedLayerIdSpecialVenues.current.includes(layerName)) {
      cachedLayerIdSpecialVenues.current.push(layerName);

      if (cachedLayerIdSpecialVenues.current.length > maxCachedSpecialVenuesLayersAmount) {
        const layerToRemove = cachedLayerIdSpecialVenues.current.shift() as string;

        currentMap.removeLayer(layerToRemove);
      }
    }
  };

  const drawCountry = (venueShape: IVenueShape, name: string): void => {
    if (!currentMap) {
      return;
    }

    const countrySourceName = `country-${name}`;

    if (!currentMap.getSource(countrySourceName)) {
      currentMap.addSource(countrySourceName, mapUtils.buildCountrySourceConfig(venueShape));
    }

    if (!currentMap.getLayer(`country-boundary-${name}`)) {
      currentMap.addLayer(mapUtils.buildCountryFillLayerConfig(name));
    }

    if (!currentMap.getLayer(`country-outline-${name}`)) {
      currentMap.addLayer(mapUtils.buildCountryBorderLayerConfig(name));
    }
  };

  const drawCity = (venueShape: IVenueShape, name: string): void => {
    if (!currentMap) {
      return;
    }

    const sourceName = `city-${name}`;

    if (!currentMap.getSource(sourceName)) {
      currentMap.addSource(sourceName, mapUtils.buildCitySourceConfig(venueShape));
    }

    if (!currentMap.getLayer(`city-boundary-${name}`)) {
      currentMap.addLayer(mapUtils.buildCityFillLayerConfig(name));
    }

    if (!currentMap.getLayer(`city-outline-${name}`)) {
      currentMap.addLayer(mapUtils.buildCityBorderLayerConfig(name));
    }
  };

  const drawContinent = (venueShape: IVenueShape, name: string): void => {
    if (!currentMap) {
      return;
    }

    const continentSourceName = `continent-${name}`;

    if (!currentMap.getSource(continentSourceName)) {
      currentMap.addSource(continentSourceName, mapUtils.buildContinentSourceConfig(venueShape));
    }

    if (!currentMap.getLayer(`continent-boundary-${name}`)) {
      currentMap.addLayer(mapUtils.buildContinentFillLayerConfig(name));
    }

    if (!currentMap.getLayer(`continent-outline-${name}`)) {
      currentMap.addLayer(mapUtils.buildContinentBorderLayerConfig(name));
    }
  };

  const drawOcean = (venueShape: IVenueShape, name: string): void => {
    if (!currentMap) {
      return;
    }

    const oceanSourceName = `ocean-${name}`;

    if (!currentMap.getSource(oceanSourceName)) {
      currentMap.addSource(oceanSourceName, mapUtils.buildOceanSourceConfig(venueShape));
    }

    if (!currentMap.getLayer(`ocean-boundary-${name}`)) {
      currentMap.addLayer(mapUtils.buildOceanFillLayerConfig(name));
    }

    if (!currentMap.getLayer(`ocean-outline-${name}`)) {
      currentMap.addLayer(mapUtils.buildOceanBorderLayerConfig(name));
    }
  };

  const drawSea = (venueShape: IVenueShape, name: string): void => {
    if (!currentMap) {
      return;
    }

    const seaSourceName = `sea-${name}`;

    if (!currentMap.getSource(seaSourceName)) {
      currentMap.addSource(seaSourceName, mapUtils.buildSeaSourceConfig(venueShape));
    }

    if (!currentMap.getLayer(`sea-boundary-${name}`)) {
      currentMap.addLayer(mapUtils.buildSeaFillLayerConfig(name));
    }

    if (!currentMap.getLayer(`sea-outline-${name}`)) {
      currentMap.addLayer(mapUtils.buildSeaBorderLayerConfig(name));
    }
  };

  const loadModel = (venue: IVenue): void => {
    if (!currentMap) {
      return;
    }

    const modelConfiguration = map3dModelBuilder.buildModelConfiguration(venue);
    const camera: THREE.Camera = new THREE.Camera();
    const scene: THREE.Scene = mapUtils.buildSceneWithLights();
    const translationMatrix = mapUtils.buildTranslationMatrixRender(modelConfiguration);

    let renderer: THREE.WebGLRenderer;

    currentMap.addLayer({
      id: modelConfiguration.id,
      type: 'custom',
      renderingMode: '3d',
      onAdd: (map, gl) => {
        renderer = new THREE.WebGLRenderer({
          canvas: map.getCanvas(),
          context: gl,
          antialias: true,
        });

        addVenueModelToScene(scene, venue, modelConfiguration.scale);

        renderer.autoClear = false;
      },
      render: (_, matrix) => {
        if (!camera || !renderer || !scene) {
          return;
        }

        handleLoaderAndVenueFadeInOutEffect(scene);

        const m = new THREE.Matrix4().fromArray(matrix);

        renderer.resetState();
        renderer.render(scene, camera);

        camera.projectionMatrix = m.multiply(translationMatrix);
        currentMap.triggerRepaint();
      },
    });
  };

  const handleLoaderAndVenueFadeInOutEffect = (scene: THREE.Scene) => {
    const venue = scene.getObjectByName('venue') as THREE.Mesh;
    const loader = scene.getObjectByName('loader-pivot') as THREE.Object3D;

    if (loader) {
      loader.rotation.z += 0.02;
    }

    const opacityDelta = 0.05;
    const venueMaterial = (venue?.material || (venue?.children[0] as THREE.Mesh)?.material) as THREE.Material;
    const loaderMaterial = (loader?.children[0] as THREE.Mesh)?.material as THREE.Material;

    if (venue && loader) {
      loaderMaterial.opacity -= opacityDelta;

      if (venueMaterial && venueMaterial.opacity < 1) {
        venueMaterial.opacity += opacityDelta;
      } else {
        scene.remove(loader);
      }
    }
  };

  const addVenueModelToScene = (scene: THREE.Scene, venue: IVenue, scale: number): void => {
    const color = mapConstant.color;

    scene.add(map3dModelBuilder.buildLoaderModal({ ...mapConstant.loaderModelConfig, color }));

    if (venue.models[0].includes('.drc')) {
      addDrcModel(scene, venue, scale);
    } else {
      addGltfModel(scene, venue, scale, color);
    }
  };

  const addGltfModel = (scene: THREE.Scene, venue: IVenue, scale: number, color: string) => {
    glftLoader.load(venue.models[0], (gltf) => {
      scene.add(map3dModelBuilder.buildGltfModel({ venue, gltf, scale, color }));
    });
  };

  const addDrcModel = (scene: THREE.Scene, venue: IVenue, scale: number) => {
    dracoLoader.load(venue.models[0], (geometryDracoLoader) => {
      scene.add(map3dModelBuilder.buildDrcModel(venue, scale, geometryDracoLoader));
    });
  };

  const drawVenue = (venue: IVenue) => {
    if (!venue || !currentMap) {
      return;
    }

    if (currentDisplayedVenue !== null) {
      clearVenueLayer(currentDisplayedVenue);
    }

    dispatch(setCurrentDisplayedVenue(venue));

    switch (venue.type?.toLowerCase()) {
      case mapConstant.venuesCategory.country:
        drawCountry(venue?.shape, venue.name);
        break;
      case mapConstant.venuesCategory.city:
        drawCity(venue.shape, venue.name);
        break;
      case mapConstant.venuesCategory.continent:
        drawContinent(venue?.shape, venue.name);
        break;
      case mapConstant.venuesCategory.ocean:
        drawOcean(venue?.shape, venue.name);
        break;
      case mapConstant.venuesCategory.sea:
        drawSea(venue?.shape, venue.name);
        break;
      default:
        if (typeof currentMap.getLayer('layer-' + venue.id) === 'undefined') {
          loadModel(venue);
        }
    }
  };

  const isAnimating = useRef(false);

  const startRotatingCamera = () => {
    if (isAnimating.current === false) {
      isAnimating.current = true;
      rotateCamera(rotateTo.current);
    }
  };

  const stopRotatingCamera = () => {
    isAnimating.current = false;
    cancelAnimationFrame(animation.current as number);
  };

  //DO NOT USE THIS METHOD TO START ANIMATION
  const rotateCamera = (timestamp: number) => {
    if (!currentMap || mapRotate === false) {
      stopRotatingCamera();
      return;
    }

    timestamp += speed;
    rotateTo.current = timestamp;
    isAnimating.current = true;
    currentMap.rotateTo(rotateTo.current, { duration: 0 });
    animation.current = requestAnimationFrame(() => {
      rotateCamera(rotateTo.current);
    });
  };

  const resetCameraRotation = () => {
    if (!currentMap || mapRotate === false || rotateTo.current === 0) {
      return;
    }

    rotateTo.current = 0;
    currentMap.rotateTo(45.0, { duration: 500 });
  };

  const flyToWholeMapView = () => {
    if (!currentMap) {
      return;
    }

    resetCameraRotation();

    currentMap
      .flyTo({
        center: [NO_VENUE.lat, NO_VENUE.lon],
        zoom: NO_VENUE.zoom,
        bearing: NO_VENUE.bearing,
        pitch: NO_VENUE.pitch,
      })
      .once('zoomstart', (e) => {
        onFlyAnimationFinished(false);
      })
      .once('zoomend', (e) => {
        stopRotatingCamera();
      });
  };

  const flyToNewPosition = (lat: number, lon: number, zoom: number): void => {
    if (!currentMap) {
      return;
    }

    drawVenue(activeVenue as IVenue);
    resetCameraRotation();
    
    let calculatedZoom = getMapRatioZoom(zoom, lat);
    let pitch = zoomedInPitch;
    if (calculatedZoom < 5) pitch = 0;

    currentMap
    .flyTo({
        center: [lon, lat],
        zoom: calculatedZoom,
        bearing: rotateTo.current,
        pitch: pitch,
        essential: true,
      })
      .once('zoomstart', (e) => {
        stopRotatingCamera();
        onFlyAnimationFinished(false);
      })
      .once('zoomend', (e) => {
        stopRotatingCamera();
        onFlyAnimationFinished(true);

        if (!mapRotate || !mapRotateRef.current) {
          return;
        }

        if (shouldRotateCamera()) {
          startRotatingCamera();
        }
      });
  };

  const shouldRotateCamera = (): boolean => {
    const venue = activeVenue as IVenue;
    const typesToNotRotateCameraFor = [mapConstant.venuesCategory.country,
      mapConstant.venuesCategory.city,
      mapConstant.venuesCategory.continent,
      mapConstant.venuesCategory.ocean,
      mapConstant.venuesCategory.sea
    ];

    return !typesToNotRotateCameraFor.includes(venue.type);
  };

  return (
    <>
      <div id="mapboxgl-container" className="map-container" />
    </>
  );
};

export default MainMapComponent;
