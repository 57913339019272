import { useDispatch } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { setShowCurrencyLotteryModal } from 'actions/global-actions';

import Button, { ButtonSize } from '../buttons/Button';

const FreeRMVControl = ({ size, className }: { size?: ButtonSize; className?: string }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setShowCurrencyLotteryModal(true));
  };

  return (
    <Button
      twClassName={twMerge(
        'tw-text-white tw-border-none min-[1650px]:tw-ml-5 min-[1650px]:tw-min-w-[128px] min-[1650px]:tw-w-[132px] tw-min-w-[147px] tw-bg-greenGradient',
        className
      )}
      color="green"
      text="Free RMV"
      size={size ?? 'medium'}
      onClick={handleClick}
    />
  );
};

export default FreeRMVControl;
