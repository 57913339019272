import { useRef } from 'react';
import { IVenue } from '../../models/i-venue.interface';
import NftCardMini from '../nftCard/nftCardMini/NftCardMini';
import styles from './SearchResultElement.module.scss';

interface ISearchResultElement {
  venue: IVenue;
  selectedVenue: (venue: IVenue) => void;
}

const SearchResultElement = ({ venue, selectedVenue }: ISearchResultElement) => {
  const containerRef = useRef<HTMLButtonElement>(null);
  const wrapperClassNames = ['button--styleless', styles['search-element']];

  return (
    <button ref={containerRef} onClick={() => selectedVenue(venue)} className={wrapperClassNames.join(' ')}>
      <NftCardMini backgroundAnimationUrl={venue.animationUrl} name={venue.name} backgroundUrl={venue.imageUrl} />
    </button>
  );
};

export default SearchResultElement;
