import { useEffect, useRef, useState } from 'react';

import { StorePlatform } from '../../../enums/store-platform.enum';

import DownloadApp from '../../../components/downloadApp/DownloadApp';
import ScrollInAnimationItem from '../../../components/scrollInAnimationItem/ScrollInAnimationItem';

import { useHorizontalScroll } from '../../../hooks/useHorizontalScroll';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { useWindowSize } from '../../../hooks/useWindowSize';

import Banner from '../../../assets/game-onboarding/banner-background.png';
import { ReactComponent as SlideScroll } from '../../../assets/landing/icons/slide-scroll.svg';
import PlayersSectionImage from '../../../assets/landing/players/players-section-game.png';
import PlayersSectionNftCard from '../../../assets/landing/players/players-section-nft-card.png';
import PlayersSectionPercentageLeft from '../../../assets/landing/players/players-section-percentage-left.png';
import PlayersSectionPercentageRight from '../../../assets/landing/players/players-section-percentage-right.png';
import PlayersSectionTradingRoyalties from '../../../assets/landing/players/players-section-trading-royalties.png';
import RealityMetaverseLogo from '../../../assets/logos/reality-metaverse-logo.svg';

import styles from './GameOnboardingBannerSection.module.scss';

const GameOnboardingBannerSection = () => {
  const scrollRef = useHorizontalScroll();
  const screenSize = useScreenSize();
  const [windowWidth] = useWindowSize();
  const [showScrollSection, setShowScrollSection] = useState<boolean>(false);
  const nftCardElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }

    setShowScrollSection(scrollRef.current.clientWidth < scrollRef.current.scrollWidth);
  }, [scrollRef, screenSize]);

  useEffect(() => {
    if (!showScrollSection || !scrollRef.current || !nftCardElementRef.current) {
      return;
    }

    const nftCardWidth = nftCardElementRef.current.getBoundingClientRect().width;
    const offsetLeft = nftCardElementRef.current.offsetLeft - (windowWidth - nftCardWidth) / 2;

    scrollRef.current.scrollTo(offsetLeft, 0);
  }, [scrollRef, nftCardElementRef, showScrollSection, windowWidth]);

  return (
    <div className="tw-relative tw-w-[calc(100%+40px)] tw-bg-[#173169]">
      <div className="tw-relative tw-z-[1] tw-flex tw-max-w-full tw-flex-col tw-items-center tw-px-[20px] tw-py-[40px] tw-py-[50px] sm:tw-pb-[120px] sm:tw-pt-[80px]">
        <img
          src={RealityMetaverseLogo}
          alt="Reality Metaverse"
          className="tw-mb-15 tw-w-[226px] sm:tw-mb-15 sm:tw-w-[302px]"
        />
        <ScrollInAnimationItem delay="0.1s">
          <h2 className="tw-mb-[20px] tw-text-[32px] tw-uppercase tw-text-white sm:tw-text-[44px] lg:tw-text-5xl">
            get your <span className="tw-text-turquoise">free nft</span>
          </h2>
        </ScrollInAnimationItem>
        <ScrollInAnimationItem className="tw-w-[952px] tw-max-w-full" delay="0.2s">
          <p className="tw-mb-[40px] tw-text-center tw-text-[22px] tw-text-white">
            You are a Landlord GO player and we would like to offer you a free premium entry to Reality Metaverse.
            <br />
            Earn real-value currency in the form of Reality Metaverse tokens by holding your NFT.
            <br />
            You can also earn royalties from millions of players!
          </p>
        </ScrollInAnimationItem>
        <div className="tw-flex tw-w-[calc(100%+40px)] tw-items-center">
          <div ref={scrollRef} className={styles['content']}>
            <ScrollInAnimationItem delay="0.3s" threshold={0.1}>
              <img alt="" className={styles['game-stores']} src={PlayersSectionImage} width="456" height="446" />
              <div className={styles['stores']}>
                <DownloadApp
                  className={styles['stores__download']}
                  platform={StorePlatform.googlePlatform}
                ></DownloadApp>
                <DownloadApp className={styles['stores__download']} platform={StorePlatform.appleStore}></DownloadApp>
              </div>
            </ScrollInAnimationItem>
            <ScrollInAnimationItem delay="0.4s" threshold={0.1}>
              <img
                alt=""
                className={styles['percentage-left']}
                src={PlayersSectionPercentageLeft}
                width="248"
                height="504"
              />
            </ScrollInAnimationItem>
            <div ref={nftCardElementRef}>
              <ScrollInAnimationItem delay="0.5s" threshold={0.1}>
                <img alt="" className={styles['nft-card']} src={PlayersSectionNftCard} width="357" height="480" />
              </ScrollInAnimationItem>
            </div>
            <ScrollInAnimationItem delay="0.6s" threshold={0.1}>
              <img
                alt=""
                className={styles['percentage-right']}
                src={PlayersSectionPercentageRight}
                width="192"
                height="504"
              />
            </ScrollInAnimationItem>
            <ScrollInAnimationItem delay="0.7s" threshold={0.1}>
              <img
                alt=""
                className={styles['trading-royalties']}
                src={PlayersSectionTradingRoyalties}
                width="357"
                height="454"
              />
            </ScrollInAnimationItem>
          </div>
        </div>
        {showScrollSection && <SlideScroll className={styles['slide-scroll-icon']} />}
      </div>
      <img alt="" className="centered tw-absolute tw-bottom-0 tw-z-0 tw-w-[1920px] tw-min-w-[100%]" src={Banner} />
    </div>
  );
};

export default GameOnboardingBannerSection;
