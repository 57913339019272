import { CurrencyPurchaseStep } from 'enums/currency-purchase/currency-purchase-step.enum';

import { useCurrencyPurchase } from 'hooks/useCurrencyPurchase';

import CurrencyPurchaseComplete from './currencyPurchaseComplete/CurrencyPurchaseComplete';
import CurrencyPurchaseMethods from './currencyPurchaseMethods/CurrencyPurchaseMethods';
import CurrencyPurchaseRamp from './currencyPurchaseRamp/CurrencyPurchaseRamp';

const CurrencyPurchaseWrapper = () => {
  const { purchaseStep, closePurchaseProcess, openCEX, openDEX, openRamp, openPurchaseComplete, onPurchaseSuccess } =
    useCurrencyPurchase();

  switch (purchaseStep) {
    case CurrencyPurchaseStep.purchaseMethod:
      return (
        <CurrencyPurchaseMethods
          onClose={closePurchaseProcess}
          openCEX={openCEX}
          openDEX={openDEX}
          openRamp={openRamp}
        />
      );
    case CurrencyPurchaseStep.rampInProgress:
      return <CurrencyPurchaseRamp onClose={closePurchaseProcess} onComplete={openPurchaseComplete} />;
    default:
      return <CurrencyPurchaseComplete onClose={closePurchaseProcess} onPurchaseSuccess={onPurchaseSuccess} />;
  }
};

export default CurrencyPurchaseWrapper;
