import { twMerge } from 'tailwind-merge';

import { getEtherscanAddressUrl, getEtherscanUrl } from 'services/network.service';

import { centralEllipsis } from 'utils/ellipsis.utils';

interface IBlockchainExplorerLinkProps {
  address: string;
  type: 'transaction' | 'address';
  text?: string;
  className?: string;
}

const getBlockchainExplorerUrl = ({ address, type }: IBlockchainExplorerLinkProps) => {
  switch (type) {
    case 'transaction':
      return getEtherscanUrl(address);
    default:
      return getEtherscanAddressUrl(address);
  }
};

const BlockchainExplorerLink = ({ address, type, text, className }: IBlockchainExplorerLinkProps) => {
  return (
    <a
      rel="noreferrer"
      target="_blank"
      className={twMerge('tw-text-green tw-underline', className)}
      title={address}
      href={getBlockchainExplorerUrl({ address, type })}
    >
      {text || centralEllipsis(address)}
    </a>
  );
};

export default BlockchainExplorerLink;
