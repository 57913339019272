import Decimal from 'decimal.js';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import Web3Singleton from 'web3Singleton';

import { IState } from 'models/reducers/i-state.interface';
import { IWalletCurrency } from 'models/wallet-currency-response.interface';

import { IGame } from '../constants/games.constants';
import { DEFAULTS } from 'constants/defaults.constant';
import { ERROR_MESSAGES } from 'constants/error-messages.constant';

import { addErrorMessageAction } from 'actions/global-actions';

import { useBalanceState } from 'hooks/useBalanceState';

import { parseFromDecimal } from 'utils/parser.utils';

import { gameHasPoolId } from '../utils';

export const useGameStaking = (
  game: IGame | undefined,
  currency: IWalletCurrency | undefined,
  onCallback: (poolId: number, negative: boolean | undefined, flexible: boolean | undefined) => void
) => {
  const { t } = useTranslation('notifications');
  const dispatch = useDispatch();
  const walletAddress = useSelector((state: IState): string => state.wallet.address);
  const lgoWeb3 = Web3Singleton.getInstance();

  const hasPoolId = gameHasPoolId(game);

  const [loading, onLoading] = useToggle(false);
  const [apy, setApy] = useState('0');
  const [fee, setFee] = useState('0');
  const [remaining, setRemaining] = useState('0');
  const [staked_amount, setStakedAmount] = useState('0');

  const { balance, refreshBalance } = useBalanceState(currency?.id);

  const handleCheckAPY = async (poolId: number, negative: boolean | undefined) => {
    onLoading(true);

    try {
      const apy = await lgoWeb3.checkAPY(poolId!, negative);

      setApy(apy);
    } catch (e) {
      console.error(e);
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
    } finally {
      onLoading(false);
    }
  };

  const handleCheckStakingFee = async (poolId: number, flexible = false) => {
    onLoading(true);

    try {
      const fee = await lgoWeb3.checkStakingFee(poolId!, flexible);

      setFee(fee);
    } catch (e) {
      console.error(e);
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
    } finally {
      onLoading(false);
    }
  };

  const handleCheckRemainingPool = async (
    poolId: number,
    negative: boolean | undefined,
    flexible: boolean | undefined,
    silent = false
  ) => {
    onLoading(!silent);

    try {
      const target = await lgoWeb3.checkStakingTarget(poolId!, negative, flexible);
      const total = await lgoWeb3.checkTotalStaked(poolId!, negative, flexible);

      setRemaining(
        parseFromDecimal(
          new Decimal(target).sub(new Decimal(total)).toFixed(currency?.decimal).toString(),
          currency!.decimal
        )
      );
    } catch (e) {
      console.error(e);
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
    } finally {
      onLoading(false);
    }
  };

  useEffect(() => {
    if (currency && hasPoolId) {
      !game?.flexible && handleCheckAPY(game?.poolId!, game?.negative);
      (game?.negative || game?.flexible) && handleCheckStakingFee(game?.poolId!, game?.flexible);
      handleCheckRemainingPool(game?.poolId!, game?.negative, game?.flexible);
    }
  }, [currency, hasPoolId, game?.poolId, game?.negative, game?.flexible]);

  const handleCheckStakedAmount = async (
    poolId: number,
    negative: boolean | undefined,
    flexible: boolean | undefined,
    silent = false
  ) => {
    onLoading(!silent);

    try {
      const staked_amount = await lgoWeb3.checkStakedAmountBy(walletAddress, poolId!, negative, flexible);

      setStakedAmount(parseFromDecimal(staked_amount, currency!.decimal));
    } catch (e) {
      console.error(e);
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
    } finally {
      onLoading(false);
    }
  };

  useEffect(() => {
    if (currency && walletAddress && hasPoolId) {
      handleCheckStakedAmount(game?.poolId!, game?.negative, game?.flexible);
    } else {
      setStakedAmount('0');
    }
  }, [currency, walletAddress, hasPoolId, game?.poolId, game?.negative, game?.flexible]);

  const handleCallback = (poolId: number) => {
    refreshBalance();
    handleCheckRemainingPool(poolId, game?.negative, game?.flexible, true);
    handleCheckStakedAmount(poolId, game?.negative, game?.flexible, true);
    onCallback(poolId, game?.negative, game?.flexible);
  };

  return {
    balance,
    apy,
    fee,
    remaining,
    staking_closed: hasPoolId ? +remaining < DEFAULTS.minPriceDisplayValue : false,
    staked_amount,
    loading,
    onCallback: handleCallback,
  };
};
