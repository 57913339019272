import { PriceCurrencySymbols } from 'enums/price-currency-symbols.enum';

import RMVCoin from '../assets/Coin.png';
import TRPCoin from '../assets/trp.png';
import USDCCoin from '../assets/usdc.svg';

export const getIconByCurrencySymbol = (currency?: PriceCurrencySymbols): string => {
  switch (currency) {
    case PriceCurrencySymbols.RMV:
    case PriceCurrencySymbols.RLTM:
      return RMVCoin;
    case PriceCurrencySymbols.TRP:
      return TRPCoin;
    case PriceCurrencySymbols.USDC:
      return USDCCoin;
    default:
      return '';
  }
};
