import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { IState } from '../models/reducers/i-state.interface';
import { IWalletCurrency } from '../models/wallet-currency-response.interface';
import { parseDecimal } from '../utils/parser.utils';
import { useDefaultCurrency } from './useCurrency';

export const useDecimalPrice = (price: number, currencyId?: number): string => {
  const currencies = useSelector((state: IState): IWalletCurrency[] | null => state.wallet.currencies);
  const [decimalPrice, setDecimalPrice] = useState<string>('0');
  const defaultCurrency = useDefaultCurrency();

  useEffect(() => {
    const currency = currencies?.find(
      (currency: IWalletCurrency) => currency.id.toString() === (currencyId || defaultCurrency?.id)?.toString()
    );

    if (!currency || !price) {
      setDecimalPrice((0).toFixed(2));

      return;
    }

    setDecimalPrice(parseDecimal(price.toString(), currency.decimal));
  }, [price, currencyId, currencies]);

  return decimalPrice;
};
