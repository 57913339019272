const GameOnboardingInstructionCreateWallet = () => {
  return (
    <ul>
      <li>Open the MetaMask extension.</li>
      <li>If this is your first time using MetaMask, select "Create a New Wallet".</li>
      <li>
        Follow the on-screen instructions. Ensure you write down and safely store your recovery phrase. It's crucial for
        restoring your wallet in case you lose access.
      </li>
      <li>
        Once your wallet is created, you will be directed to the main interface showing your account balance and other
        details.
      </li>
      <li>Return to this website to continue following instructions</li>
    </ul>
  );
};

export default GameOnboardingInstructionCreateWallet;
