import { useRef } from 'react';
import { useSelector } from 'react-redux';

import { IState } from '../../models/reducers/i-state.interface';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';

import './FullScreenLoadingSpinner.scss';

interface IFullScreenLoadingSpinnerProps {
  loading?: boolean;
  opaqueBackground?: boolean;
  noFadeIn?: boolean;
}

const FullScreenLoadingSpinner = ({ loading = false, opaqueBackground = false, noFadeIn = false }: IFullScreenLoadingSpinnerProps) => {
  const isLoading = useSelector(({ global }: IState) => !!global.pendingRequests.length || global.loading || loading);
  const loaderRef = useRef(null);
  const classNames = ['full-screen-loading-spinner', opaqueBackground ? 'full-screen-loading-spinner--opaque' : ''];
  const loadingSpinnerStyle = noFadeIn ? { animation: "none" } : {};

  return isLoading ? (
    <div style={loadingSpinnerStyle} className={classNames.join(' ')} ref={loaderRef}>
      <LoadingSpinner />
    </div>
  ) : (
    <></>
  );
};

export default FullScreenLoadingSpinner;
