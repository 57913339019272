import { useSelector } from 'react-redux';

import { IState } from 'models/reducers/i-state.interface';

import { PriceCurrencySymbols } from 'enums/price-currency-symbols.enum';

import { useEnv } from 'hooks/useEnv';

import Card from 'assets/menu/icons/card-payment.svg';
import Kucoin from 'assets/menu/icons/kucoin.svg';
import Uniswap from 'assets/menu/icons/uniswap.svg';

import Badge from '../../badge/Badge';
import ModalPortal from '../../modal/ModalPortal';
import styles from './CurrencyPurchaseMethods.module.scss';

interface ICurrencyPurchaseMethodsProps {
  onClose: () => void;
  openRamp: () => void;
  openCEX: () => void;
  openDEX: () => void;
}

const CurrencyPurchaseMethods = ({ onClose, openRamp, openCEX, openDEX }: ICurrencyPurchaseMethodsProps) => {
  const symbol = useSelector((state: IState) => state.global.currencySymbol);
  const { enableRampPayment } = useEnv();

  const currencySymbol = symbol === PriceCurrencySymbols.USDC ? 'USDC.E' : symbol;

  return (
    <ModalPortal
      className={styles['modal']}
      modalContentClassName={styles['modal__content']}
      modalTitleClassName={styles['modal__title']}
      customStyles={{ width: '360px', maxWidth: '100vw' }}
      onClose={onClose}
    >
      <div className={styles['purchase-method']}>
        {enableRampPayment && (
          <button className={`button--styleless ${styles['purchase-method__button']}`} onClick={openRamp}>
            <img className={styles['purchase-method__icon']} src={Card} alt="Card payment" />
            <div>
              <p className={styles['purchase-method__title']}>Buy with Card</p>
              <p className={styles['purchase-method__subtitle']}>Buy {currencySymbol} directly with card using fiat</p>
              <Badge className={styles['purchase-method__badge']} text="Easy" />
            </div>
          </button>
        )}
        <button className={`button--styleless ${styles['purchase-method__button']}`} onClick={openCEX}>
          <img className={styles['purchase-method__icon']} src={Kucoin} alt="Kucoin" />
          <div>
            <p className={styles['purchase-method__title']}>Buy on CEX</p>
            <p className={styles['purchase-method__subtitle']}>Create account on Kucoin to buy {currencySymbol}</p>
          </div>
        </button>
        <button className={`button--styleless ${styles['purchase-method__button']}`} onClick={openDEX}>
          <img className={`${styles['purchase-method__icon']}`} src={Uniswap} alt="Uniswap" />
          <div>
            <p className={`${styles['purchase-method__title']}`}>Buy on DEX</p>
            <p className={`${styles['purchase-method__subtitle']}`}>Swap other tokens for {currencySymbol}</p>
            <Badge className={`${styles['purchase-method__badge']}`} text="Advanced" />
          </div>
        </button>
      </div>
    </ModalPortal>
  );
};

export default CurrencyPurchaseMethods;
