import EmptyPageIcon from '../../../assets/icons/empty-page-icon.svg';

import styles from './LootboxOwnedEmptySection.module.scss';

interface ILootboxOwnedEmptySectionProp {
  openBuyLootboxes: () => void;
  className?: string;
}

const LootboxOwnedEmptySection = ({ className, openBuyLootboxes }: ILootboxOwnedEmptySectionProp) => {
  return (
    <div className={`${className} ${styles['wrapper']}`}>
      <div className={styles['content']}>
        <img src={EmptyPageIcon} className={styles['icon']} alt="The data section is empty" />
        <div>
          You do not have any loot boxes to open yet.
          <br />
          Click on{' '}
          <button className={['button--styleless', styles['button']].join(' ')} onClick={openBuyLootboxes}>
            buy loot boxes
          </button>{' '}
          tab above to see loot boxes available to buy!
        </div>
      </div>
    </div>
  );
};

export default LootboxOwnedEmptySection;
