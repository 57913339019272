import { BigNumber } from 'ethers';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Web3Singleton from 'web3Singleton';

import { ILootboxPurchaseConfig, ListingType } from 'models/lootbox/i-lootbox-purchase-config.interface';
import { IState } from 'models/reducers/i-state.interface';
import { IWalletCurrency } from 'models/wallet-currency-response.interface';

import { LootboxPurchaseStep } from 'enums/lootbox/lootbox-purchase-step.enum';

import { ERC20_ABI } from 'constants/abi/erc20-abi.constant';
import { ERROR_MESSAGES } from 'constants/error-messages.constant';
import { LOOTBOX_INSUFFICIENT_ALLOWANCE_ERROR_MESSAGE } from 'constants/lootbox/lootbox-insufficient-allowance-error.constant';

import {
  setLootboxOpenConfirmationBlocksAction,
  setLootboxPurchaseConfigAction,
  setLootboxPurchaseErrorMessageAction,
  setLootboxPurchaseFundsRemainingAction,
  setLootboxPurchaseStepAction,
} from 'actions/lootbox.actions';

import { ILootboxParams, useLootboxEvents } from 'hooks/events/useLootboxEvents';
import { useEnv } from 'hooks/useEnv';

import { getLootboxTransactionError } from 'utils/get-lootbox-transaction-error.util';
import { parseDecimal } from 'utils/parser.utils';

export const useLootboxPurchase = () => {
  const { t } = useTranslation('notifications');
  const lgoWeb3 = Web3Singleton.getInstance();
  const dispatch = useDispatch();
  const { lootboxStoreAddress } = useEnv();

  const walletAddress = useSelector((state: IState): string => state.wallet.address);
  const purchaseConfig = useSelector((state: IState) => state.lootbox.purchaseConfig);

  const {
    sendLootboxBuyEvent,
    sendLootboxPurchaseErrorEvent,
    sendLootboxPurchaseInProgressEvent,
    sendLootboxPurchaseSuccessEvent,
  } = useLootboxEvents();

  const setErrorMessage = (errorMessage: string) => {
    dispatch(setLootboxPurchaseErrorMessageAction(errorMessage));
  };

  const setConfirmationBlocks = (blocks: number) => {
    dispatch(setLootboxOpenConfirmationBlocksAction(blocks));
  };

  const setFundsRemaining = (fundsRemaining: number) => {
    dispatch(setLootboxPurchaseFundsRemainingAction(fundsRemaining));
  };

  const stopLootboxPurchase = () => {
    dispatch(setLootboxPurchaseStepAction(null));
  };

  const setPurchaseStep = (step: LootboxPurchaseStep) => {
    dispatch(setLootboxPurchaseStepAction(step));
  };

  const restartLootboxPurchase = () => {
    if (!purchaseConfig) {
      return;
    }

    startLootboxPurchase(purchaseConfig);
  };

  const getFundsRemaining = async ({
    price,
    quantity,
    currency,
  }: {
    price: string;
    quantity: number;
    currency: Omit<IWalletCurrency, 'balance'>;
  }): Promise<BigNumber> => {
    const balance = await lgoWeb3.getCurrencyBalance(ERC20_ABI, currency.address, walletAddress);
    const totalPrice = BigNumber.from(price).mul(quantity);

    return BigNumber.from(balance).sub(totalPrice);
  };

  const startLootboxPurchase = async ({
    listingId,
    assetId,
    openingAnimation,
    name,
    price,
    quantity,
    currency,
    type,
  }: ILootboxPurchaseConfig): Promise<void> => {
    if (!currency) {
      return;
    }

    dispatch(
      setLootboxPurchaseConfigAction({
        quantity,
        assetId,
        listingId,
        price,
        openingAnimation,
        name,
        currency,
        type,
      })
    );
    setPurchaseStep(LootboxPurchaseStep.inProgress);

    const lootboxParams: ILootboxParams = {
      lootboxId: assetId,
      lootboxAmount: quantity,
    };

    sendLootboxBuyEvent(lootboxParams);

    const remaining = await getFundsRemaining({ price, quantity, currency });

    if (remaining < BigNumber.from(0)) {
      const parsedFundsRemaining = +parseDecimal(remaining.toString(), currency.decimal);

      setFundsRemaining(parsedFundsRemaining * -1);
      setPurchaseStep(LootboxPurchaseStep.noFunds);

      return;
    }

    const hasCurrencyPermission = await lgoWeb3.checkPermissionToCurrency(
      ERC20_ABI,
      lootboxStoreAddress,
      walletAddress,
      currency,
      +price
    );

    if (!hasCurrencyPermission) {
      try {
        await lgoWeb3.grantCurrencyPermissionService(ERC20_ABI, lootboxStoreAddress, currency, walletAddress);

        return startLootboxPurchase({
          listingId,
          assetId,
          openingAnimation,
          name,
          price,
          quantity,
          currency,
          type,
        });
      } catch (error) {
        setPurchaseStep(LootboxPurchaseStep.failed);
        setErrorMessage(t(ERROR_MESSAGES.permissionsRejected));

        return;
      }
    }

    try {
      if (type === ListingType.Contract) {
        const recentPrice = await lgoWeb3.checkLootboxPrice(listingId);

        if (BigNumber.from(price).lt(BigNumber.from(recentPrice))) {
          setErrorMessage(LOOTBOX_INSUFFICIENT_ALLOWANCE_ERROR_MESSAGE);
          setPurchaseStep(LootboxPurchaseStep.failed);
          sendLootboxPurchaseErrorEvent({ ...lootboxParams, message: LOOTBOX_INSUFFICIENT_ALLOWANCE_ERROR_MESSAGE });

          return;
        }
      }
    } catch (error) {}

    try {
      if (type === ListingType.Contract) {
        await lgoWeb3.purchaseLootboxListing({
          listingId,
          quantity,
          walletAddress,
          forMaxPriceInQT: BigNumber.from(price).mul(quantity).toString(),
          onRequestSent: (confirmationBlocks) => {
            setPurchaseStep(LootboxPurchaseStep.blockchainConfirmation);
            setConfirmationBlocks(confirmationBlocks);
          },
          onTransactionHash: () => {},
          offerCanceled: () => {},
          offerAccepted: () => {},
        });
      } else {
        await lgoWeb3.purchaseMarketplaceLootboxListing({
          listingId,
          quantity,
          walletAddress,
          onRequestSent: (confirmationBlocks) => {
            setPurchaseStep(LootboxPurchaseStep.blockchainConfirmation);
            setConfirmationBlocks(confirmationBlocks);
          },
          onTransactionHash: () => {},
          offerCanceled: () => {},
          offerAccepted: () => {},
        });
      }

      setPurchaseStep(LootboxPurchaseStep.success);
      sendLootboxPurchaseSuccessEvent(lootboxParams);
    } catch (error: any) {
      setErrorMessage(t(getLootboxTransactionError(error?.message)));
      setPurchaseStep(LootboxPurchaseStep.failed);
      sendLootboxPurchaseErrorEvent({ ...lootboxParams, message: error?.message });
    }
  };

  return {
    stopLootboxPurchase,
    startLootboxPurchase,
    restartLootboxPurchase,
    setPurchaseStep,
  };
};
