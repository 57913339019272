import axios, { AxiosError } from 'axios';
import { DateTime } from 'luxon';

import { IChartData } from 'models/i-chart-data.interface';
import { IMarketNftDailyRevenue } from 'models/market/i-market-nft-daily-revenue.interface';
import { IMarketNftStats } from 'models/market/i-market-nft-stats.interface';
import { INftPassiveIncomeDaily } from 'models/passive-income/i-nft-passive-income-daily.interface';
import { INftPassiveIncomeMonthly } from 'models/passive-income/i-nft-passive-income-monthly.interface';
import { IPassiveIncome } from 'models/passive-income/i-passive-income.interface';
import { IProfilePassiveIncomeDates } from 'models/passive-income/i-profile-passive-income-monthly.interface';

import { ApiStatusCode } from 'enums/api-status-code.enum';
import { Duration } from 'enums/duration.enum';

import { DEFAULTS } from 'constants/defaults.constant';

const PROXY_BASE_URL = '/express-proxy/insight';
const allowedErrors = [ApiStatusCode.errorNotFound, ApiStatusCode.errorUnprocessableEntity];

enum PassiveIncomePeriod {
  daily,
  monthly,
}

export const getFormattedDates = (duration: Duration): { todayFormatted: string; startDateFormatted: string } => {
  const today = DateTime.now();
  const todayFormatted = today.toFormat(DEFAULTS.passiveIncomeApiDateFormat);
  const startDateFormatted = today.minus({ [duration]: 1 }).toFormat(DEFAULTS.passiveIncomeApiDateFormat);

  return { todayFormatted, startDateFormatted: startDateFormatted };
};

export const formatDate = (date: Date | string | undefined, dateFormat: string): string | null => {
  if (typeof date === 'string') {
    return DateTime.fromISO(date).toFormat(dateFormat);
  }

  if (typeof date === 'object') {
    return DateTime.fromJSDate(date).toFormat(dateFormat);
  }

  return null;
};

const formatDetails = (income: number): { value: string }[] => {
  return [{ value: !income ? '0' : income.toString() }];
};

export const getProfilePassiveIncome = async (walletAddress: string): Promise<IPassiveIncome> => {
  const { todayFormatted } = getFormattedDates(Duration.years);

  try {
    const response = await axios.get<IPassiveIncome>(`${PROXY_BASE_URL}/profile-passive-income/`, {
      params: {
        walletAddress,
        startDate: "2022-01-01",
        endDate: todayFormatted,
      },
    });
    return response.data;
  } catch (error: unknown) {
    const axiosError = error as AxiosError;

    if (allowedErrors.includes(axiosError?.response?.status as ApiStatusCode)) {
      return { income: null };
    }

    throw new Error(axiosError.message || 'Unknown error');
  }
};

const getProfilePassiveIncomeForPeriod = (
  walletAddress: string,
  duration: Duration,
  signal: AbortSignal,
  processFunction: (data: IProfilePassiveIncomeDates[]) => any,
  incomePeriod: PassiveIncomePeriod,
  additionalParams: Record<string, any> = {}
): Promise<IChartData[] | null> => {
  const { todayFormatted, startDateFormatted } = getFormattedDates(duration);

  return axios
    .get(`${PROXY_BASE_URL}/profile-passive-income/period`, {
      signal,
      params: {
        walletAddress,
        startDate: startDateFormatted,
        endDate: todayFormatted,
        period: incomePeriod,
        ...additionalParams,
      },
    })
    .then(({ data }) => processFunction(data))
    .catch((error) => {
      if (error?.message === 'canceled') {
        return null;
      }

      if (allowedErrors.includes(error.response?.status)) {
        return null;
      }

      throw new Error(error);
    });
};

export const getProfilePassiveIncomeLastWeekOrLastMonth = (
  walletAddress: string,
  signal: AbortSignal,
  duration: Duration
): Promise<IChartData[] | null> => {
  return getProfilePassiveIncomeForPeriod(
    walletAddress,
    duration,
    signal,
    (passiveIncome) =>
      passiveIncome.map(({ income, date }) => ({
        value: income,
        label: formatDate(date, DEFAULTS.defaultDateFormat),
        tooltipLabel: formatDate(date, DEFAULTS.defaultDateFormat),
        details: formatDetails(income),
      })),
    PassiveIncomePeriod.daily
  );
};

export const getProfilePassiveIncomeAllTimeOrLastYear = (
  walletAddress: string,
  signal: AbortSignal
): Promise<IChartData[] | null> => {
  return getProfilePassiveIncomeForPeriod(
    walletAddress,
    Duration.years,
    signal,
    (passiveIncome) =>
      passiveIncome.map(({ income, month, year }) => ({
        value: income,
        label: DateTime.fromObject({ year, month }).toFormat(DEFAULTS.defaultMonthFormat),
        tooltipLabel: DateTime.fromObject({ year, month }).toFormat(DEFAULTS.defaultMonthFormat),
        details: formatDetails(income),
        period: PassiveIncomePeriod.monthly,
      })),
    PassiveIncomePeriod.monthly
  );
};

export const getNFTPassiveIncomeLastWeek = (ownerAddress: string, nftId: string): Promise<IChartData[] | null> => {
  const { todayFormatted, startDateFormatted } = getFormattedDates(Duration.weeks);

  return axios
    .get<INftPassiveIncomeDaily[]>(`${PROXY_BASE_URL}/nft-passive-income`, {
      params: {
        ownerAddress,
        nftId,
        startDate: startDateFormatted,
        endDate: todayFormatted,
        period: PassiveIncomePeriod.daily,
      },
    })
    .then((passiveIncome): IChartData[] =>
      passiveIncome.data.map(({ date, income }) => ({
        value: income,
        label: DateTime.fromISO(date).toFormat('EEEE'),
        tooltipLabel: DateTime.fromISO(date).toFormat(DEFAULTS.defaultDateYearFormat),
        details: formatDetails(income),
      }))
    )
    .catch((error) => {
      if (allowedErrors.includes(error.response?.status)) {
        return null;
      }

      throw new Error(error);
    });
};

export const getNFTPassiveIncomeLastMonth = (ownerAddress: string, nftId: string): Promise<IChartData[] | null> => {
  const { todayFormatted, startDateFormatted } = getFormattedDates(Duration.months);

  return axios
    .get<INftPassiveIncomeDaily[]>(`${PROXY_BASE_URL}/nft-passive-income`, {
      params: {
        ownerAddress,
        nftId,
        startDate: startDateFormatted,
        endDate: todayFormatted,
        period: PassiveIncomePeriod.daily,
      },
    })
    .then((passiveIncome): IChartData[] =>
      passiveIncome.data.map(({ date, income }) => ({
        value: income,
        label: DateTime.fromISO(date).toFormat('EEEE'),
        tooltipLabel: DateTime.fromISO(date).toFormat(DEFAULTS.defaultDateYearFormat),
        details: formatDetails(income),
      }))
    )
    .catch((error) => {
      if (allowedErrors.includes(error.response?.status)) {
        return null;
      }

      throw new Error(error);
    });
};

export const getNFTPassiveIncomeLastYear = (ownerAddress: string, nftId: string): Promise<IChartData[] | null> => {
  const { todayFormatted, startDateFormatted } = getFormattedDates(Duration.years);

  return axios
    .get<INftPassiveIncomeMonthly[]>(`${PROXY_BASE_URL}/nft-passive-income`, {
      params: {
        ownerAddress,
        nftId,
        startDate: startDateFormatted,
        endDate: todayFormatted,
        period: PassiveIncomePeriod.monthly,
      },
    })
    .then((passiveIncome): IChartData[] =>
      passiveIncome.data.map(({ income, month, year }) => ({
        value: income,
        label: DateTime.fromObject({ year, month, day: 1 }).toFormat(DEFAULTS.defaultFullMonthYearFormat),
        details: formatDetails(income),
      }))
    )
    .catch((error) => {
      if (allowedErrors.includes(error.response?.status)) {
        return null;
      }

      throw new Error(error);
    });
};

export const getNFTPassiveIncomeAllTime = (
  ownerAddress: string,
  nftId: string,
  joinDate: string
): Promise<IChartData[] | null> => {
  const today = new Date();
  const joinDateDate = new Date(joinDate);
  const todayFormatted = DateTime.fromJSDate(today).toFormat(DEFAULTS.passiveIncomeApiDateFormat);
  const joinDateFormatted = DateTime.fromJSDate(joinDateDate).toFormat(DEFAULTS.passiveIncomeApiDateFormat);

  return axios
    .get<INftPassiveIncomeMonthly[]>(`${PROXY_BASE_URL}/nft-passive-income`, {
      params: {
        ownerAddress,
        nftId,
        startDate: joinDateFormatted,
        endDate: todayFormatted,
        period: PassiveIncomePeriod.monthly,
      },
    })
    .then((passiveIncome): IChartData[] =>
      passiveIncome.data.map(({ income, month, year }) => ({
        value: income,
        label: DateTime.fromObject({ year, month, day: 1 }).toFormat(DEFAULTS.defaultFullMonthYearFormat),
        details: formatDetails(income),
      }))
    )
    .catch((error) => {
      if (allowedErrors.includes(error.response?.status)) {
        return null;
      }

      throw new Error(error);
    });
};

export const getNftTotalPassiveIncomeForOwner = (
  nftId: string,
  ownerAddress: string,
  joinDate: string
): Promise<any | null> => {
  const today = new Date();
  const joinDateDate = new Date(joinDate);
  const todayFormatted = DateTime.fromJSDate(today).toFormat(DEFAULTS.passiveIncomeApiDateFormat);
  const joinDateFormatted = DateTime.fromJSDate(joinDateDate).toFormat(DEFAULTS.passiveIncomeApiDateFormat);

  return axios
    .get<INftPassiveIncomeMonthly[]>(`${PROXY_BASE_URL}/nft-total-passive-income-for-owner`, {
      params: {
        nftId,
        ownerAddress,
        startDate: joinDateFormatted,
        endDate: todayFormatted,
      },
    })
    .then(({ data }) => data)
    .catch((error) => {
      if (allowedErrors.includes(error.response?.status)) {
        return null;
      }

      throw new Error(error);
    });
};

export const getNftStats = (nftId: string): Promise<IMarketNftStats> => {
  return axios
    .get<IMarketNftStats>(`${PROXY_BASE_URL}/nft-stats`, {
      params: {
        nftId,
      },
    })
    .then(({ data }) => data);
};

export const getNftRoyalties = (nftId: string): Promise<IMarketNftDailyRevenue> => {
  const endDate = DateTime.local().toISODate();
  const startDate = '2022-10-01';

  return axios
    .get<IMarketNftDailyRevenue>(`${PROXY_BASE_URL}/nft-daily-market-revenue`, {
      params: {
        nftId,
        startDate,
        endDate,
      },
    })
    .then(({ data }) => data);
};
