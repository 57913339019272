import { twMerge } from 'tailwind-merge';

import { DISCORD_SUPPORT_CHANEL } from '../../../../constants/discord-support-channel.constants';

import styles from './GameOnboardingInstructionOpenTicket.module.scss';

const GameOnboardingInstructionOpenTicket = () => {
  const { alternativeText, link, ImageComponent } = DISCORD_SUPPORT_CHANEL;
  return (
    <div>
      <p>
        To open a ticket with our social media team, please get in touch with us on Discord.
        Ensure you include your wallet address when submitting the ticket, and the NFT will be airdropped to you in the
        next few days!
      </p>

      <div className="tw-flex tw-justify-center tw-gap-[50px] lg:tw-gap-[80px]">
        <a
          key={alternativeText}
          aria-label={alternativeText}
          href={link}
          target="_blank"
          rel="noreferrer"
          className={twMerge(
            styles['link'],
            'tw-relative tw-flex tw-h-[50px] tw-w-[50px] tw-items-center tw-justify-center tw-rounded-full tw-bg-blueCardGradient sm:tw-h-[80px] sm:tw-w-[80px]'
          )}
        >
          <ImageComponent className="tw-h-[23.5px] tw-fill-white" />
        </a>
      </div>
    </div>
  );
};

export default GameOnboardingInstructionOpenTicket;
