import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IState } from 'models/reducers/i-state.interface';

import { setChainId, setIsConnected, setIsOnCorrectChain } from 'actions/web3.action';

import Web3Singleton from '../web3Singleton';

export const useChainChange = () => {
  const dispatch = useDispatch();
  const lgoWeb3 = Web3Singleton.getInstance();
  const web3 = lgoWeb3.getWeb3();
  const { currentProvider, validProvider } = useSelector(({ web3 }: IState) => web3);

  useEffect(() => {
    if (!validProvider || !web3) {
      return;
    }

    const checkCurrentChain = async (): Promise<void> => {
      const chainId = await lgoWeb3.getChainId();

      if (!chainId) {
        dispatch(setIsConnected(false));
        dispatch(setChainId(undefined));
        return;
      }

      const chainIdHex = web3.utils.toHex(chainId);

      dispatch(setIsConnected(true));
      dispatch(setChainId(chainIdHex));

      const correctNetwork = await lgoWeb3.isOnCorrectNetwork();

      dispatch(setIsOnCorrectChain(correctNetwork));
    };

    checkCurrentChain();
  }, [dispatch, currentProvider, validProvider, web3, lgoWeb3]);

  useEffect(() => {
    if (!validProvider || !web3) {
      return;
    }

    dispatch(setIsConnected(!!web3));

    const handleChainChange = async (chainId: string): Promise<void> => {
      const chainIdHex = web3.utils.toHex(chainId);
      const correctNetwork = await lgoWeb3.isOnCorrectNetwork();

      dispatch(setChainId(chainIdHex));
      dispatch(setIsOnCorrectChain(correctNetwork));
    };

    lgoWeb3.onChainChange(handleChainChange);

    return () => {
      if (!web3) {
        return;
      }

      lgoWeb3.removeOnChainChange(handleChainChange);
    };
  }, [dispatch, currentProvider, validProvider, lgoWeb3, web3]);
};
