import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { DEFAULTS } from 'constants/defaults.constant';
import { ROUTES } from 'constants/routes.constant';

import SocialsBar from 'components/socials/SocialsBar';

import { ReactComponent as Arrow } from 'assets/arrow.svg';

import styles from './HomeFooter.module.scss';

interface IHomeFooter {
  className?: string;
}

const HomeFooter = ({ className }: IHomeFooter) => {
  const { t } = useTranslation();

  return (
    <footer className={[styles['footer'], className].join(' ')}>
      <nav className={styles['nav']}>
        <div className={styles['nav__content']}>
          <p>{t('copyright')}</p>
          <ul className={styles['nav-list']}>
            <li className={styles['nav-list__item']}>
              <Link to={DEFAULTS.privacyPolicyLink} target="_blank">
                Privacy Policy
              </Link>
            </li>
            <li className={styles['nav-list__item']}>
              <Link to={DEFAULTS.termsOfServiceLink} target="_blank">
                Terms of Service
              </Link>
            </li>
            <li className={styles['nav-list__item']}>
              <Link to={ROUTES.CONTACT} target="_blank" rel="noreferrer">
                Contact Us
                <Arrow />
              </Link>
            </li>
            <li className={styles['nav-list__item']}>
              <Link to={'/'} target="_blank" rel="noreferrer">
                Main Site
                <Arrow />
              </Link>
            </li>
          </ul>
        </div>
        <SocialsBar
          order={[
            { type: 'discord', icon: 'border' },
            { type: 'instagram', icon: 'border' },
            { type: 'youtube', icon: 'border' },
            { type: 'facebook', icon: 'border' },
            { type: 'twitter', icon: 'border' },
          ]}
          className="tw-m-0 tw-ml-auto tw-flex tw-transform tw-list-none tw-items-center tw-space-x-2 tw-p-0"
          iconClassName="tw-pr-2 tw-fill-current tw-text-white"
        />
      </nav>
    </footer>
  );
};

export default HomeFooter;
