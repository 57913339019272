import LootboxIcon from 'assets/lootbox/lootbox-icon-count.svg';

import styles from './LootboxCounterSection.module.scss';

interface ILootboxCounterSectionProps {
  quantity?: number;
  size?: 'small' | 'medium';
}

const LootboxCounterSection = ({ quantity, size = 'small' }: ILootboxCounterSectionProps) => {
  const classNames = [styles['lootbox-counter'], styles[`lootbox-counter--${size}`]];

  return (
    <div className={classNames.join(' ')}>
      <img src={LootboxIcon} alt="Lootboxes counter icon" className={styles['lootbox-counter__icon']} />
      <span>{quantity}</span>
    </div>
  );
};

export default LootboxCounterSection;
