import LoadingSpinner from '../../../components/loadingSpinner/LoadingSpinner';
import { getEtherscanUrl } from '../../../services/network.service';
import { centralEllipsis } from '../../../utils/ellipsis.utils';

import styles from './RedeemProgressModal.module.scss';

interface IRedeemProgressModalProps {
  nftShares: number;
  nftName?: string;
  nftAddress?: string;
  transactionHash?: string;
}

const RedeemProgressModal = ({ nftShares, nftName, nftAddress, transactionHash }: IRedeemProgressModalProps) => {
  return (
    <div className={styles['redeem-progress-modal']}>
      <div className={styles['redeem-progress-modal__spinner']}>
        <LoadingSpinner />
      </div>
      <div className={styles['redeem-progress-modal__content']}>
        <div className={styles['redeem-progress-modal__details']}>
          <p className={styles['redeem-progress-modal__details-section']}>
            Your claim of {nftShares} shares of{' '}
            <b>
              {nftName}{nftAddress ? ` (${nftAddress}) ` : ' '}
            </b>
            is processing.
            <br />
            It should be confirmed on the blockchain shortly.
          </p>
          {transactionHash && (
            <div className={styles['redeem-progress-modal__details-section']}>
              <p>Transaction ID:</p>
              <a
                target="_blank"
                rel="noreferrer"
                className={styles['redeem-progress-modal__hash-link']}
                href={getEtherscanUrl(transactionHash)}
              >
                {centralEllipsis(transactionHash)}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RedeemProgressModal;
