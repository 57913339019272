import { useState } from 'react';

import Button from 'components/buttons/Button';
import ModalPortal from 'components/modal/ModalPortal';
import NumberSlider from 'components/numberSlider/NumberSlider';

import LootboxSharesSection from '../lootboxCard/lootboxSharesSection/LootboxSharesSection';
import styles from './LootboxOpenAmountSelectionModal.module.scss';

interface ILootboxOpenAmountSelectionModalProps {
  quantityOwned: number;
  onClose: () => void;
  openLootboxes: (quantity: number) => void;
  onContinue: () => void;
}

const LootboxOpenAmountSelectionModal = ({
  quantityOwned,
  onClose,
  openLootboxes,
  onContinue,
}: ILootboxOpenAmountSelectionModalProps) => {
  const minQuantity = 1;
  const defaultMaxQuantity = 20;
  const [quantity, setQuantity] = useState<number>(minQuantity);

  return (
    <ModalPortal
      className={styles['lootbox-amount-section']}
      modalTitleClassName={styles['lootbox-amount-section__title']}
      closeButtonClassName={styles['lootbox-amount-section__close-button']}
      customStyles={{
        width: '462px',
      }}
      title="How many lootboxes would you like to open?"
      onClose={onClose}
    >
      <div>
        <div className={styles['lootbox-amount-section__controls']}>
          <NumberSlider
            className={styles['lootbox-amount-section__section']}
            maxValue={quantityOwned > defaultMaxQuantity ? defaultMaxQuantity : quantityOwned}
            minValue={minQuantity}
            onChange={setQuantity}
          />
          <LootboxSharesSection className={styles['lootbox-amount-section__shares']} shares={quantity} />
        </div>
        <div className={styles['lootbox-amount-section__footer']}>
          <Button
            className={styles['lootbox-amount-section__button']}
            size="medium-large"
            color="primary"
            onClick={() => openLootboxes(quantity)}
          >
            Open {quantity} {quantity > 1 ? 'lootboxes' : 'lootbox'}
          </Button>
          <Button
            className={styles['lootbox-amount-section__button']}
            size="medium-large"
            color="default"
            onClick={onContinue}
          >
            Open later
          </Button>
        </div>
      </div>
    </ModalPortal>
  );
};

export default LootboxOpenAmountSelectionModal;
