export const MESSAGES = {
  messages: {
    error: {
      unauthorized: 'Please sign in first',
      wrongNetwork: 'Wrong network. Please verify the network in MetaMask.',
      permissionsRejected: 'Transaction permission has been rejected',
      transactionRejected: 'Transaction has been rejected',
      backendReject: 'Something went wrong',
    },
  },
};
