import { ReactNode } from 'react';
import styles from './NftCardSection.module.scss';

interface INftCardSectionProps {
  children: ReactNode;
  className?: string;
}

const NftCardSection = ({ children, className = '' }: INftCardSectionProps) => {
  const classNames = [className, styles['card-section']];

  return <div className={classNames.join(' ')}>{children}</div>;
};

export default NftCardSection;
