import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import Cookies from 'universal-cookie';

import { IVenueDetails } from 'models/i-venue-details.interface';
import { IAuctionHouseBidSubmitRequest } from 'models/market/i-auction-house-bid-submit-request.model';
import { IMapMarketOfferToBidRequest } from 'models/market/i-map-market-offer-to-bid-request.interface';
import { IMarketFiltersContract } from 'models/market/i-market-filters-contract.interface';
import { IMarketFilters } from 'models/market/i-market-filters.interface';
import { IMarketTradesData, IMarketTradesPayload } from 'models/market/i-market-trades-payload.interface';
import { ITradeBidSubmitRequest } from 'models/market/i-trade-bid-submit-request.model';
import { ITradeAsset, ITradeDetails } from 'models/trade/i-trade-details.interface';
import { ITradeRequest } from 'models/trade/i-trade-request.interface';

import { CookiesKeys } from 'enums/cookies-keys.enum';
import { MarketShowStatus } from 'enums/market/market-show-status.enum';

import {
  ASSET_FIELDS,
  ASSET_TRADE_FIELDS,
  MARKET_TRADE_FIELDS,
  PROFILE_TRADE_FIELDS,
} from 'constants/params-fields.constant';
import { WEB3 } from 'constants/web3.constant';

import { getBooleanEnvValue } from 'utils/env.utils';
import { getQueryParams } from 'utils/get-query-params.utils';

import { getMarketOffersMock } from './mocks.service';

export const adaptStateHash = (state_hash: string) => (state_hash === '0x0' ? WEB3.nullStateHash : state_hash);
export const isNull = (value: any) => value === null;

let cancelToken: CancelTokenSource;

const mapMarketFilters = (filters: IMarketFilters): IMarketFiltersContract => ({
  search: filters.search,
  show_status: MarketShowStatus.active,
  show_type: filters.statuses?.join(',') || '',
  category: filters.category,
  ordering: filters.ordering,
  currency: filters.priceCurrency ? filters.priceCurrency.toString() : '',
  max_price: filters.pricesMax ? filters.pricesMax.toString() : '',
  min_price: filters.pricesMin ? filters.pricesMin.toString() : '',
  page_size: filters.limit.toString(),
  page: filters.page.toString(),
});

export const mapMarketOfferToBidRequest = (configuration: IMapMarketOfferToBidRequest): ITradeRequest => {
  return {
    nonce: configuration.nonce.toString(),
    expirationTime: configuration.expirationTime.toString(),
    tradeTokenAddress: configuration.currencyAddress,
    itemsTokenAddress: configuration.tradeDetails!.asset.contract.address,
    items: [
      {
        tokenId: configuration.tradeDetails!.asset.token_id,
        amount: +configuration.tradeDetails!.amount,
        stateHash: adaptStateHash(configuration.tradeDetails!.state_hash),
      },
    ],
    bidValue: configuration.bidValue.toString(),
    to: WEB3.nullAddress,
  };
};

export const mapMarketOfferToTradeRequest = (tradeDetails: ITradeDetails, currencyAddress: string): ITradeRequest => {
  return {
    nonce: tradeDetails!.nonce.toString(),
    expirationTime: tradeDetails!.expiration_time.toString(),
    tradeTokenAddress: currencyAddress,
    itemsTokenAddress: tradeDetails!.asset.contract.address,
    items: [
      {
        tokenId: tradeDetails!.asset.token_id,
        amount: +tradeDetails!.amount,
        stateHash: adaptStateHash(tradeDetails!.state_hash),
      },
    ],
    offerValue: tradeDetails!.price,
    to: WEB3.nullAddress,
  };
};

export const fetchMarketOffersService = async (
  filters: IMarketFilters
): Promise<AxiosResponse<IMarketTradesData | null>> => {
  const useMarketMocks = getBooleanEnvValue(process.env.REACT_APP_USE_MARKET_MOCKS);

  if (useMarketMocks) {
    return getMarketOffersMock();
  }

  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }

  cancelToken = axios.CancelToken.source();

  const mappedFilters = mapMarketFilters(filters);
  const params = getQueryParams(mappedFilters);
  const noDataResponse = {
    totalPages: 0,
    totalCount: 0,
    currentPage: 1,
    results: [],
  };

  params.append('fields', [...PROFILE_TRADE_FIELDS, ...MARKET_TRADE_FIELDS].join(','));

  const tradesPayload = await axios.get<IMarketTradesPayload>(`/api/v1/trades/`, {
    params,
  });

  if (!tradesPayload.data?.count) {
    return {
      ...tradesPayload,
      data: noDataResponse,
    };
  }

  const assetIds = tradesPayload.data.results.map(({ asset_id }) => asset_id);
  const totalPages = tradesPayload.data?.count ? Math.ceil(tradesPayload.data.count / filters.limit) : 1;
  const totalCount = tradesPayload.data?.count;
  const ids = [...new Set(assetIds)];
  const assetsPayload = await fetchAllAssets(ids);

  if (!assetsPayload?.data) {
    return {
      ...assetsPayload,
      data: noDataResponse,
    };
  }

  const mappedResults = tradesPayload.data.results.map((trade) => ({
    ...trade,
    asset: assetsPayload.data.find((asset) => trade.asset_id === asset.id)!,
  }));

  return {
    ...tradesPayload,
    data: {
      totalCount,
      totalPages,
      currentPage: filters.page,
      results: mappedResults,
    },
  };
};

export const fetchAllAssets = (ids: number[] | string[]): Promise<AxiosResponse<ITradeAsset[]>> => {
  return axios.get<ITradeAsset[]>(`/api/v1/assets/all_assets`, {
    params: { ids: ids.join(','), filters: [...ASSET_FIELDS, ...ASSET_TRADE_FIELDS].join(',') },
  });
};

export const fetchMarketOfferById = (id: string): Promise<AxiosResponse<IVenueDetails, any>> => {
  return axios.get<IVenueDetails>(`/backend/nft/${id}`);
};

export const submitTradeBidService = (
  id: number,
  tradeBidData: ITradeBidSubmitRequest
): Promise<AxiosResponse<any>> => {
  return axios.post(`/api/v1/trades/${id}/offers/`, tradeBidData, {
    headers: {
      'X-CSRFToken': new Cookies().get(CookiesKeys.csrftoken),
    },
  });
};

export const cancelBid = (id: number): Promise<AxiosResponse<number>> => {
  return axios
    .delete(`/api/v1/trades/offers/${id}`, {
      headers: {
        'X-CSRFToken': new Cookies().get(CookiesKeys.csrftoken),
      },
    })
    .then((data) => {
      return {
        ...data,
        data: id,
      };
    });
};

export const submitAuctionHouseBidService = (
  tradeBidData: IAuctionHouseBidSubmitRequest
): Promise<AxiosResponse<any>> => {
  return axios.post(`/api/v1/trades/bid`, tradeBidData, {
    headers: {
      'X-CSRFToken': new Cookies().get(CookiesKeys.csrftoken),
    },
  });
};

export const postScanTriggerRequestService = (): Promise<AxiosResponse> => {
  return axios.post(`/api/v1/trades/scan`, null, {
    headers: {
      'X-CSRFToken': new Cookies().get(CookiesKeys.csrftoken),
    },
  });
};
