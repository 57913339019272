import { IConfigurationReducer } from './models/i-configuration.interface';

import { FETCH_RMV_MULTIPLIER, FETCH_USDC_MULTIPLIER } from '../constants/actionNames';

const INITIAL_STATE: IConfigurationReducer = {
  rmvMultiplier: 0,
  rmvMultiplierLoading: false,
  rmvMultiplierError: false,
  usdcMultiplier: 0,
  usdcMultiplierLoading: false,
  usdcMultiplierError: false,
};

export const configurationReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case FETCH_RMV_MULTIPLIER + '_PENDING':
      return { ...state, rmvMultiplierLoading: true, rmvMultiplierError: false };
    case FETCH_RMV_MULTIPLIER + '_REJECTED':
      return { ...state, rmvMultiplierLoading: false, rmvMultiplierError: true, rmvMultiplier: null };
    case FETCH_RMV_MULTIPLIER + '_FULFILLED':
      return {
        ...state,
        rmvMultiplierLoading: false,
        rmvMultiplierError: false,
        rmvMultiplier: action.payload.data,
      };
    case FETCH_USDC_MULTIPLIER + '_PENDING':
      return { ...state, usdcMultiplierLoading: true, usdcMultiplierError: false };
    case FETCH_USDC_MULTIPLIER + '_REJECTED':
      return { ...state, usdcMultiplierLoading: false, usdcMultiplierError: true, usdcMultiplier: null };
    case FETCH_USDC_MULTIPLIER + '_FULFILLED':
      return {
        ...state,
        usdcMultiplierLoading: false,
        usdcMultiplierError: false,
        usdcMultiplier: action.payload.data,
      };
    default:
      return state;
  }
};
