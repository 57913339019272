import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Web3Singleton from 'web3Singleton';

import { ILootboxMetadata } from 'models/lootbox/i-lootbox-metadata.interface';
import { ILootboxListing, ILootboxListingResult } from 'models/lootbox/i-lootbox-trade.interface';

import { addErrorMessageAction } from 'actions/global-actions';

import { getLootboxMetadataService } from 'services/lootbox.service';

import { useLoadingTime } from 'hooks/useLoadingTime';

import { getLootboxTransactionError } from 'utils/get-lootbox-transaction-error.util';

export const useLootboxesListings = () => {
  const { t } = useTranslation('notifications');
  const [lootboxesListings, setLootboxesListings] = useState<ILootboxListing[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const lgoWeb3 = Web3Singleton.getInstance();
  const dispatch = useDispatch();

  const { loadingTime, handleStartLoadingTime, handleStopLoadingTime } = useLoadingTime();

  const fetchRewardDetails = async () => {
    setLoading(true);
    handleStartLoadingTime();

    try {
      const listingsResponse = await lgoWeb3.getLootboxListings();
      const marketplaceListingsResponse = await lgoWeb3.getMarketplaceLootboxesListings();

      const mappedListings = await Promise.all(
        [...listingsResponse, ...marketplaceListingsResponse].map(async (listing: ILootboxListingResult) => {
          let lootboxMetadata: ILootboxMetadata;

          try {
            lootboxMetadata = (await getLootboxMetadataService(listing.asset.id)).data;
          } catch (e) {
            return null;
          }

          return {
            listingId: listing.id,
            assetId: listing.asset.id,
            background: lootboxMetadata.image,
            currencySymbol: listing.currencyValuePerToken.symbol,
            chancesInfo: lootboxMetadata.chancesInfo,
            isOnSale: lootboxMetadata.isOnSale,
            name: lootboxMetadata.name,
            price: listing.currencyValuePerToken.value,
            priceDisplayValue: listing.currencyValuePerToken.displayValue,
            quantity: listing.quantity,
            openingAnimation: lootboxMetadata.openingAnimation,
            tier1NFTChance: lootboxMetadata.tier1NFTChance,
            tier2NFTChance: lootboxMetadata.tier2NFTChance,
            tier3NFTChance: lootboxMetadata.tier3NFTChance,
            type: listing.type,
          } as ILootboxListing;
        })
      );

      const filteredListings = mappedListings
        .sort((a, b) => {
          if (a === null || b === null) return 0;
          return parseInt(b.listingId) - parseInt(a.listingId);
        })
        .filter((listing) => listing?.isOnSale) as ILootboxListing[];

      setLootboxesListings(filteredListings);
    } catch (error: any) {
      dispatch(addErrorMessageAction(t(getLootboxTransactionError(error?.message))));
    }

    setLoading(false);
    handleStopLoadingTime();
  };

  return { loading, loadingTime, lootboxesListings, fetchRewardDetails };
};
