import { AxiosResponse } from 'axios';
import { generateUUID } from 'three/src/math/MathUtils';

import { IDispatchAction } from 'models/dispatch-action.interface';
import { INftOfTheWeekAsset, INftOfTheWeekResponse } from 'models/nft-of-the-week/i-nft-of-the-week';
import { INftOfTheWeekReducer } from 'models/reducers/i-nft-of-the-week-reducer.interface';

import { DEFAULTS } from 'constants/defaults.constant';

import {
  FETCH_NFT_OF_THE_WEEK,
  FETCH_NFT_OF_THE_WEEK_ASSET,
  REFRESH_NFT_OF_THE_WEEK_UPDATE_TOKEN,
  RESET_NFT_OF_THE_WEEK_DATA,
} from 'actions/actionNames/nftOfTheWeekActionNames';

const INITIAL_STATE: INftOfTheWeekReducer = {
  updateToken: generateUUID(),
  asset_id: null,
  valid_until: null,
  loading: false,
  asset: null,
  assetLoading: false,
};

export const nftOfTheWeekReducer = (state = INITIAL_STATE, action: IDispatchAction): INftOfTheWeekReducer => {
  switch (action.type) {
    case RESET_NFT_OF_THE_WEEK_DATA:
      return { ...INITIAL_STATE };
    case REFRESH_NFT_OF_THE_WEEK_UPDATE_TOKEN:
      return { ...state, updateToken: generateUUID() };
    case FETCH_NFT_OF_THE_WEEK + '_PENDING':
      return { ...state, loading: true };
    case FETCH_NFT_OF_THE_WEEK + '_REJECTED':
      return { ...state, loading: false };
    case FETCH_NFT_OF_THE_WEEK + '_FULFILLED': {
      const response = (action as IDispatchAction<AxiosResponse<INftOfTheWeekResponse>>).payload.data;

      if (!response?.asset) {
        return { ...state, asset_id: null, loading: false, valid_until: null };
      }

      return {
        ...state,
        asset_id: response.asset.id,
        valid_until: response.valid_until * DEFAULTS.tradeTimestampMultiplier,
        loading: false,
      };
    }
    case FETCH_NFT_OF_THE_WEEK_ASSET + '_PENDING':
      return { ...state, assetLoading: true };
    case FETCH_NFT_OF_THE_WEEK_ASSET + '_REJECTED':
      return { ...state, assetLoading: false };
    case FETCH_NFT_OF_THE_WEEK_ASSET + '_FULFILLED': {
      const assets = (action as IDispatchAction<AxiosResponse<INftOfTheWeekAsset[]>>).payload.data;

      if (!assets) {
        return { ...state, asset: null, assetLoading: false };
      }

      return { ...state, asset: assets[0], assetLoading: false };
    }
    default:
      return state;
  }
};
