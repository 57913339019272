import { Web3Providers } from '../reducers/web3.reducer';
import {
  SET_CHAIN_ID,
  SET_CURRENT_WEB3_PROVIDER,
  SET_IS_CONNECTED,
  SET_IS_ON_CORRECT_CHAIN,
} from './actionNames/web3ActionNames';

export const setIsConnected = (connected: boolean) => ({
  type: SET_IS_CONNECTED,
  payload: connected,
});

export const setChainId = (chainId: string | undefined) => ({
  type: SET_CHAIN_ID,
  payload: chainId,
});

export const setIsOnCorrectChain = (correctChain: boolean) => ({
  type: SET_IS_ON_CORRECT_CHAIN,
  payload: correctChain,
});

export const setCurrentWeb3Provider = (provider: Web3Providers) => ({
  type: SET_CURRENT_WEB3_PROVIDER,
  payload: provider,
});
