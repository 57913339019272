import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setCurrentWeb3Provider } from '../actions/web3.action';
import { Web3Providers } from '../reducers/web3.reducer';
import Web3Singleton from '../web3Singleton';

export const useWeb3ProviderChange = (): void => {
  const dispatch = useDispatch();
  const lgoWeb3 = Web3Singleton.getInstance();

  const onProviderChange = (provider: Web3Providers): void => {
    dispatch(setCurrentWeb3Provider(provider));
  };

  useEffect(() => {
    lgoWeb3.registerOnProviderChange(onProviderChange);
  }, []);
};
