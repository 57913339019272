import { PriceCurrencySymbols } from '../enums/price-currency-symbols.enum';

export const DEFAULTS = {
  defaultBidExpirationOffset: 48 * 60 * 60,
  minWindowWidthForMap: 1300,
  minIncomeWithdrawValue: 1,
  minPriceDisplayValue: 0.01,
  minSalePrice: 0.01,
  minSaleShares: 1,
  minPriceChartYAxisValue: 0.0001,
  defaultPriceChartYAxisValuePrecision: 8,
  tradeTimestampMultiplier: 1000,
  defaultRecommendedPaymentLimit: 1000000,
  defaultStake: '100',
  defaultSalePrice: 1,
  defaultPricePrecision: 2,
  defaultBidIncrementPrecision: 2,
  defaultBidIncrement: 0,
  defaultConfirmationBlocks: 10,
  defaultSearchDebounceTime: 500,
  defaultCurrencySymbol: PriceCurrencySymbols.RMV,
  defaultMonthFormat: 'MMM',
  defaultYearMonthFormat: 'MMM, yyyy',
  defaultFullMonthYearFormat: 'MMMM, yyyy',
  defaultDateFormat: 'MMM dd',
  defaultDateYearFormat: 'MMM dd, yyyy',
  passiveIncomeApiDateFormat: 'yyyy-MM-dd',
  gleamScriptUrl: 'https://widget.gleamjs.io/e.js',
  termsOfServiceLink: '/legal/TERMS OF SERVICE.pdf',
  privacyPolicyLink: '/legal/PRIVACY POLICY.pdf',
  licenseLink: '/legal/TERMS OF LIMITED LICENSE.pdf',
  faqUrl: 'https://realitymeta.notion.site/FAQ-b565c70c93114d228b9de35294418298',
  rampDefaultFiatValue: '20',
  rampDefaultFiatCurrency: 'USD',
  rampDefaultAsset: 'MATIC_RMV',
  DEXLink: (outputCurrency: string) => `https://app.uniswap.org/tokens/polygon/${outputCurrency}?chain=polygon`,
  CEXLink: (exchangeCurrencies: string) => `https://www.mexc.com/register?inviteCode=1bqXg`,
  productionChainId: '137',
  developmentChainId: '80002',
  productionLootboxMetadataUrl: (id: string) => `https://assets.r5y.io/lgo-live/lootbox/${id}.json`,
  developmentLootboxMetadataUrl: (id: string) => `https://assets.r5y.io/lgo-live/lootbox/test/${id}.json`,
  nftVideoRenderCookieExpirationDays: 4,
  whitepaperGoogleUrl: 'https://drive.google.com/uc?export=download&id=1XRpiIByT3LmcjR0-FpLseT-4lM8ojdEW',
};

export const MARKET = {
  production: 'Polygon',
  development: 'Amoy',
};

export const REGEX_PATTERN = {
  email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};
