import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useEnv } from '../../hooks/useEnv';
import { useWindowSize } from '../../hooks/useWindowSize';
import { mapCounterScale } from '../../utils/map-counter-scale.utils';
import './SaleCounter.scss';

interface ICounterSinglePart {
  value: number;
  label: string;
}

const CounterSinglePart = ({ value, label }: ICounterSinglePart) => {
  return (
    <div className="single-date-part">
      <div className="value">{value >= 0 ? value : '-'}</div>
      <div className="label">{label}</div>
    </div>
  );
};

interface ISaleCounterProps {
  endAt: number;
  timerLabel: string;
}

const SaleCounter = ({ endAt, timerLabel }: ISaleCounterProps) => {
  const classNames = ['sale-counter-wrapper', 'sale-counter-wrapper-map'];
  const isOnMarketPage = useLocation().pathname.includes('/market');
  const [width] = useWindowSize();
  const scaleStyle = isOnMarketPage ? {} : { transform: `scale(${mapCounterScale(width)})` };

  if (isOnMarketPage) {
    classNames.pop();
  }

  const { showOpeningCounter } = useEnv();
  const getDateParts = (endAt: number) => {
    let timestampNowUTC = Math.floor(Date.now());
    let timeDiff = endAt - timestampNowUTC;

    let days = timeDiff / (24 * 60 * 60 * 1000);
    let hours = (days % 1) * 24;
    let minutes = (hours % 1) * 60;
    let secs = (minutes % 1) * 60;

    let leftTimeParts = ([days, hours, minutes, secs] = [
      Math.floor(days),
      Math.floor(hours),
      Math.floor(minutes),
      Math.floor(secs),
    ]);
    return leftTimeParts;
  };

  const [leftTime, setLeftTime] = useState(getDateParts(endAt));
  const intervalId = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    startTimer();

    return () => {
      stopTimer();
    };
  }, []);

  const startTimer = () => {
    intervalId.current = setInterval(() => {
      let leftTimeParts = getDateParts(endAt);
      setLeftTime(leftTimeParts);
    }, 1000);
  };

  const stopTimer = () => {
    if (intervalId?.current !== null) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  };
  return showOpeningCounter ? (
    <div className={classNames.join(' ')} style={scaleStyle}>
      <div className="counter-label">{timerLabel}</div>
      <div className="counter">
        <CounterSinglePart value={leftTime[0]} label="days" />
        <CounterSinglePart value={leftTime[1]} label="hrs" />
        <CounterSinglePart value={leftTime[2]} label="min" />
        <CounterSinglePart value={leftTime[3]} label="sec" />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SaleCounter;
