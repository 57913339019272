import axios, { AxiosResponse } from 'axios';

import { IAssetDetails } from 'models/assets/i-asset-details.interface';
import { ISearchVenuesResponseData, IVenue } from 'models/i-venue.interface';
import { IAvailableRenderNft } from 'models/nfts-rendering/i-available-render-nft.interface';
import { IRenderNftVideoProgress } from 'models/nfts-rendering/i-render-nft-video-progress.interface';
import { IRenderNftVideoResponse } from 'models/nfts-rendering/i-render-nft-video-response.interface';
import { ISearchReducer } from 'models/reducers/i-search-reducer.interface';
import { ITradeAsset, ITradeDetails } from 'models/trade/i-trade-details.interface';
import { ITradePreviewDetails } from 'models/trade/i-trade-preview-details.interface';

// TODO: Make it global for all request - how?
let isFetchingVenuesInProgress: boolean;
let controller: AbortController;

const abortApiCall = () => {
  controller.abort();
};

const newAbortController = () => {
  controller = new AbortController();
};

export const fetchVenuesService = ({
  search,
  sortBy,
  category,
  tier,
  country,
  limit,
  skip = 0,
}: ISearchReducer): Promise<ISearchVenuesResponseData> => {
  if (!controller) {
    newAbortController();
  }

  if (isFetchingVenuesInProgress) {
    abortApiCall();
    newAbortController();
  }

  var params = new URLSearchParams();

  if (sortBy?.value) {
    params.append('sortBy', sortBy.value);
  }

  if (category?.value) {
    params.append('category', category.value);
  }

  // TODO: is it used or will be used?
  if (tier) {
    params.append('tier', tier);
  }

  if (country?.value) {
    params.append('country', country.value);
  }

  params.append('q', search);
  params.append('limit', limit.toString());
  params.append('skip', skip.toString());

  isFetchingVenuesInProgress = true;

  return axios
    .get<IVenue[]>(`/backend/nft/search`, {
      signal: controller.signal,
      params,
    })
    .then((data) => {
      isFetchingVenuesInProgress = false;

      return { data: data.data, totalCount: +data.headers.totalcount };
    })
    .catch((error) => {
      isFetchingVenuesInProgress = false;

      return { data: [], totalCount: 0, canceled: true };
    });
};

export const fetchVenueByIdService = (id: string): Promise<AxiosResponse<IVenue, any>> => {
  return axios.get<IVenue>(`/backend/nft/${id}`);
};

export const fetchTradeDetailsService = async (
  token_id: string,
  tradeId?: string | null
): Promise<AxiosResponse<Omit<ITradePreviewDetails, 'tradeDetails'> | null, any>> => {
  const assetsDetailsResponse = await axios.get<IAssetDetails>(`/api/v1/assets/token/${token_id}`);

  if (!assetsDetailsResponse?.data) {
    return {
      ...assetsDetailsResponse,
      data: null,
    };
  }

  if (!tradeId && !assetsDetailsResponse.data.active_trades?.length) {
    return {
      ...assetsDetailsResponse,
      data: {
        assetDetails: assetsDetailsResponse.data,
      },
    };
  }

  let tradeDetailsResponse = await axios.get<ITradeDetails>(
    `/api/v1/trades/${tradeId || assetsDetailsResponse?.data.active_trades[0].id}`
  );

  if (tradeId && !tradeDetailsResponse?.data) {
    tradeDetailsResponse = await axios.get<ITradeDetails>(
      `/api/v1/trades/${assetsDetailsResponse?.data.active_trades[0].id}`
    );
  }

  if (!tradeDetailsResponse?.data) {
    return {
      ...assetsDetailsResponse,
      data: {
        assetDetails: {
          ...assetsDetailsResponse.data,
          active_trades: [],
        },
      },
    };
  }

  const assetId = tradeDetailsResponse.data.asset_id;
  const assetsPayload = await axios.get<ITradeAsset[]>(`/api/v1/assets/all_assets?ids=${assetId}`);

  const assetDetails = assetsPayload?.data?.find((x) => x.id == assetId);
  if (assetDetails !== undefined) {
    tradeDetailsResponse.data = {
      ...tradeDetailsResponse.data,
      asset: assetDetails,
    };
  }

  const details: ITradePreviewDetails = {
    tradeDetails: tradeDetailsResponse.data,
    assetDetails: assetsDetailsResponse.data,
  };

  return {
    ...tradeDetailsResponse,
    data: details,
  };
};

export const fetchAssetPreviewDetailsService = async (token_id: string): Promise<AxiosResponse<IAssetDetails, any>> => {
  return axios.get<IAssetDetails>(`/api/v1/assets/token/${token_id}`);
};

export const fetchAvailableRenderNftsService = async (): Promise<AxiosResponse<IAvailableRenderNft[], any>> => {
  return axios.get<IAvailableRenderNft[]>(`/render-availability`);
};

export const postRenderNftVideoService = async (
  nftId: number,
  walletAddress: string,
  username: string | undefined,
  sharesAmount: number
): Promise<AxiosResponse<IRenderNftVideoResponse, any>> => {
  const date = new Date().toISOString();
  let params: any = { nftId, walletAddress, sharesAmount, date };
  if (username !== undefined)
    params = {
      ...params,
      username,
    };
  return axios.post<IRenderNftVideoResponse>(`/render`, null, {
    params: params,
  });
};

export const fetchRenderNftProgressService = async (
  jobId: string
): Promise<AxiosResponse<IRenderNftVideoProgress, any>> => {
  return axios.get<IRenderNftVideoProgress>(`/render/${jobId}`);
};

export const downloadNftRenderVideoService = async (videUrl: string): Promise<AxiosResponse<void, any>> => {
  return axios
    .get(videUrl, {
      responseType: 'blob',
    })
    .then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');

      link.href = href;
      link.setAttribute('download', videUrl.split('/').pop() || `nft-render.mp4`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      return response;
    });
};
