import { combineReducers } from 'redux';

import { buildingDetailsReducer } from './building-details.reducer';
import { configurationReducer } from './configuration.reducer';
import { currentCoordsReducer } from './current-coords.reducer';
import { dailyDealsReducer } from './daily-deals.reducer';
import { filtersReducer } from './filters.reducer';
import { globalReducer } from './global.reducer';
import { landingReducer } from './landing.reducer';
import { leaderboardsReducer } from './leaderboards.reducer';
import { lootboxReducer } from './lootbox.reducer';
import { mapReducer } from './map.reducer';
import { marketReducer } from './market.reducer';
import { nftOfTheDayReducer } from './nft-of-the-day.reducer';
import { nftOfTheWeekReducer } from './nft-of-the-week.reducer';
import { profileReducer } from './profile.reducer';
import { redeemReducer } from './redeem.reducer';
import { searchBuildingsReducer } from './search.reducer';
import { statsReducer } from './stats.reducer';
import { topOffersReducer } from './top-offers.reducer';
import { tradesReducer } from './trades.reducer';
import { translationReducer } from './translation.reducer';
import { venuesActiveReducer } from './venueActive.reducer';
import { venuesCardVideoReducer } from './venues-card-video.reducer';
import { venuesReducer } from './venues.reducer';
import { walletReducer } from './wallet.reducer';
import { web3Reducer } from './web3.reducer';

export const rootReducer = combineReducers({
  buildingDetails: buildingDetailsReducer,
  configuration: configurationReducer,
  coords: currentCoordsReducer,
  dailyDeals: dailyDealsReducer,
  filters: filtersReducer,
  global: globalReducer,
  landing: landingReducer,
  leaderboards: leaderboardsReducer,
  lootbox: lootboxReducer,
  map: mapReducer,
  market: marketReducer,
  nftOfTheDay: nftOfTheDayReducer,
  nftOfTheWeek: nftOfTheWeekReducer,
  profile: profileReducer,
  redeem: redeemReducer,
  search: searchBuildingsReducer,
  stats: statsReducer,
  topOffers: topOffersReducer,
  trades: tradesReducer,
  translation: translationReducer,
  venuesActive: venuesActiveReducer,
  venuesCardVideo: venuesCardVideoReducer,
  venues: venuesReducer,
  wallet: walletReducer,
  web3: web3Reducer,
});
