import { StorePlatform } from '../../../enums/store-platform.enum';

import DownloadApp from '../../../components/downloadApp/DownloadApp';
import ScrollInAnimationItem from '../../../components/scrollInAnimationItem/ScrollInAnimationItem';

import AboutSectionImage from '../../../assets/about/about-section-4.png';

import GameOnboardingInfoCard from '../gameOnboardingInfoCard/GameOnboardingInfoCard';

const GameOnboardingMillionsOfPlayersSection = () => {
  return (
    <div className="tw-mb-[80px] tw-flex tw-w-[1360px] tw-max-w-full tw-max-w-full tw-flex-col tw-flex-wrap tw-items-center tw-justify-between tw-gap-[40px] lg:tw-mb-[120px] lg:tw-flex-row">
      <ScrollInAnimationItem className="tw-w-full lg:tw-w-[630px] lg:tw-max-w-[calc(50%-20px)]" delay="0.1s">
        <img
          alt=""
          className="tw-mx-auto tw-block tw-h-auto tw-max-w-full"
          src={AboutSectionImage}
          width="761"
          height="462"
        />
        <div className="centered tw-absolute tw-bottom-0 tw-left-[50%] tw-flex tw-max-w-full tw-gap-[7px]">
          <DownloadApp className="tw-flex-1" platform={StorePlatform.googlePlatform}></DownloadApp>
          <DownloadApp className="tw-flex-1" platform={StorePlatform.appleStore}></DownloadApp>
        </div>
      </ScrollInAnimationItem>
      <ScrollInAnimationItem
        className="tw-w-full lg:tw-w-[560px] lg:tw-max-w-[calc(50%-20px)]"
        delay="0.2s"
        threshold={0.3}
      >
        <GameOnboardingInfoCard
          title={
            <>
              from <span className="tw-text-turquoise">millions</span> of players
            </>
          }
          content={
            <>
              <p>
                The first game introduced to the ecosystem and a flagship property associated with the Reality Metaverse
                is a mobile game called Landlord GO. With over 6 million active players and 21 million Landlord Games
                downloads, Landlord GO is the most recognizable geo-location property trading game globally. Landlord GO
                is a first-in-class business simulator game using real-life properties from around the globe. Unlike
                other board games, the game draws real data from real buildings, real people and sets actual prices
                associated with each property.
              </p>
            </>
          }
        />
      </ScrollInAnimationItem>
    </div>
  );
};

export default GameOnboardingMillionsOfPlayersSection;
