import './ProgressModalStep.scss';

interface IProgressModalStepProps {
  step: number;
  activeStep: number;
}

const ProgressModalStep = ({ step, activeStep }: IProgressModalStepProps) => {
  const isActiveStep = step <= activeStep;
  const isActiveLine = step < activeStep;

  return (
    <>
      <div className={`modal-progress-header__step ${isActiveStep ? 'modal-progress-header__step--active' : ''}`}></div>
      <div className={`modal-progress-header__line ${isActiveLine ? 'modal-progress-header__line--active' : ''}`}></div>
    </>
  );
};

export default ProgressModalStep;
