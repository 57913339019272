import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';

import { IState } from 'models/reducers/i-state.interface';
import { IWalletReducer } from 'models/reducers/i-wallet-reducer.interface';

import { ERROR_MESSAGES } from 'constants/error-messages.constant';

import { addErrorMessageAction, setShowCurrencyLotteryModal, setShowSwitchNetworkModal } from 'actions/global-actions';
import { setWalletAssets } from 'actions/wallet.action';

import {
  getWheelOfFortuneDataService,
  getWheelOfFortuneResultsService,
  makeWheelOfFortuneSpinService,
} from 'services/wheel-of-fortune.service';

import ElementLoader from 'components/element-loader/ElementLoader';
import ModalPortal from 'components/modal/ModalPortal';

import WheelOfFortune from '../WheelOfFortune/WheelOfFortune';
import WheelOfFortuneCountdown from '../WheelOfFortuneCountdown/WheelOfFortuneCountdown';
import WheelOfFortuneOverlay from '../WheelOfFortuneOverlay/WheelOfFortuneOverlay';
import WheelOfFortuneResult from '../WheelOfFortuneResult/WheelOfFortuneResult';
import WheelOfFortuneSafe from '../WheelOfFortuneSafe/WheelOfFortuneSafe';

const WheelOfFortuneModal = () => {
  const { t } = useTranslation('notifications');
  const dispatch = useDispatch();
  const { correctChain } = useSelector(({ web3 }: IState) => web3);
  const walletAddress = useSelector((state: IState): string => state.wallet.address);
  const { filters } = useSelector((state: IState): IWalletReducer => state.wallet);
  const { assets } = useSelector(({ wallet }: IState): IWalletReducer => wallet);

  const fetchWalletAssets = (page: number, controller: AbortController) => {
    dispatch(setWalletAssets({ ...filters, page: page.toString() }, controller.signal));
  };

  useEffect(() => {
    correctChain && walletAddress && fetchWalletAssets(1, new AbortController());
  }, [correctChain, walletAddress]);

  const initialData = Array.from({ length: 12 }, () => ({ option: '' }));

  const [data, setData] = useState<{ option: string }[]>(initialData);
  const [spinNumber, setSpinNumber] = useState<number | null>(null);
  const [spinTime, setSpinTime] = useState<number | null>(null);
  const [spinDone, toggleSpinDone] = useToggle(false);
  const [prizeNumber, setPrizeNumber] = useState<number | undefined>(undefined);
  const [lastReward, setLastReward] = useState<string | undefined>(undefined);
  const [safe, setSafe] = useState<number | undefined>(undefined);

  const handleReset = () => {
    setData(initialData);
    setSpinNumber(null);
    setSpinTime(null);
    toggleSpinDone(false);
    setPrizeNumber(0);
    setLastReward(undefined);
    setSafe(undefined);
  };

  useEffect(() => {
    !walletAddress && handleReset();
  }, [walletAddress]);

  useEffect(() => {
    if (!correctChain) {
      dispatch(setShowSwitchNetworkModal(true));
      handleReset();
    } else {
      dispatch(setShowSwitchNetworkModal(false));
    }
  }, [correctChain]);

  const handleClose = () => {
    dispatch(setShowCurrencyLotteryModal(false));
    handleReset();
  };

  const handleFetchWheelOfFortuneData = async () => {
    try {
      const response = await getWheelOfFortuneDataService(new AbortController().signal);

      const data = response.data.rewards.map((reward) => ({ option: reward.rmv_prize }));

      setData(data);
      setSpinNumber(response.data.next_spin_number);
      setSpinTime(response.data.seconds_to_next_spin);
    } catch (error) {
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
    }
  };

  const handleFetchWheelOfFortuneResults = async () => {
    try {
      const response = await getWheelOfFortuneResultsService(new AbortController().signal);
      const rewards = response.data.slice(-1);

      if (rewards[0]) {
        setLastReward(rewards[0].reward.rmv_prize);
      }
      setSafe(response.data.reduce((safe, { reward }) => safe + parseFloat(reward.rmv_prize), 0));
    } catch (error: any) {
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
    }
  };

  useEffect(() => {
    handleFetchWheelOfFortuneData();
  }, [walletAddress]);

  useEffect(() => {
    if (walletAddress) {
      handleFetchWheelOfFortuneResults();
    }
  }, [walletAddress]);

  const handleMakeWheelOfFortuneSpin = async (spinNumber: number) => {
    try {
      const response = await makeWheelOfFortuneSpinService(spinNumber, new AbortController().signal);

      const prize = data?.find(({ option }) => option === response.data.reward.rmv_prize);

      if (prize) {
        setPrizeNumber(data?.indexOf(prize));
      }
    } catch (error) {
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
    }
  };

  const handleSpin = () => {
    if (spinNumber) {
      handleMakeWheelOfFortuneSpin(spinNumber);
    }
  };

  const readyToSpin = spinNumber ? !isNaN(spinNumber) : false;
  const currentSpin = !!prizeNumber && data[prizeNumber] && spinDone;
  const lastSpin = spinTime && !spinDone && lastReward;

  return (
    <ModalPortal
      title="Daily RMV Fortune"
      onClose={handleClose}
      modalContentClassName="tw-overflow-hidden tw-w-full tw-pt-5 tw-px-10 tw-pb-10 md:tw-max-w-screen-md md:tw-px-0 tw-bg-transparent"
    >
      <ElementLoader loading={!Boolean(data[0].option.length)} className="tw-bg-transparent">
        {!walletAddress && <WheelOfFortuneOverlay />}
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-7.5 tw-text-white">
          <p className="-tw-mt-5 tw-text-center">Collect free $RMV every day!</p>
          {currentSpin && <WheelOfFortuneResult reward={data[prizeNumber].option} onClose={handleClose} />}
          <WheelOfFortune
            loading={!Boolean(data[0].option.length)}
            prizeNumber={prizeNumber}
            data={data}
            onSpin={handleSpin}
            onStopSpinning={() => {
              toggleSpinDone(true);
              handleFetchWheelOfFortuneData();
              handleFetchWheelOfFortuneResults();
            }}
            disabled={!readyToSpin}
          />
          <div className="tw-h-[56px]">
            {lastSpin && (
              <>
                <WheelOfFortuneResult reward={lastReward} onClose={handleClose} />
                <WheelOfFortuneCountdown spinTime={spinTime} />
              </>
            )}
          </div>
        </div>
        <div className="tw-absolute tw-left-[calc(50%_-_148px)] tw-my-2 tw-w-[296px]">
          {(currentSpin || lastSpin) && !Boolean(assets?.length) && safe && <WheelOfFortuneSafe value={safe} />}
        </div>
      </ElementLoader>
    </ModalPortal>
  );
};

export default WheelOfFortuneModal;
