import { useEffect } from 'react';

const useTimeout = (callback: () => void, delay: number) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      callback();
    }, delay);
    return () => clearInterval(timer);
  }, [delay, callback]);
};

export default useTimeout;
