import { useWindowSize } from './useWindowSize';

export const useMapRatioZoom = () => {
  const defaultMapScreenHeight = 1080;
  const equatorLength = 40075016.686;
  const mapboxTailsSize = 512;
  const [_, screenHeight] = useWindowSize();

  /**
   * @param zoom map zoom value
   * @param latitude latitude of the current map object
   * @returns number of meters which falls into 1 pixel by the given resolution and latitude
   * See the formula to calculate the meters per pixel value
   * {@link} https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#Resolution_and_Scale
   */
  const getMetersPerPixel = (zoom: number, latitude: number) => (equatorLength / mapboxTailsSize)  * Math.abs((Math.cos(latitude)) / Math.pow(2, zoom));

  /**
   * @param latitude latitude of the current map object
   * @param metersPerPixel number of meters which falls into 1 pixel
   * @returns object zoom based on the meters per pixel and latitude values
   * See the formula to calculate the meters per pixel value
   * {@link} https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#Resolution_and_Scale
   */
  const getZoomFromMetersPerPixel = (latitude: number, metersPerPixel: number) => Math.log2((equatorLength * Math.abs(Math.cos(latitude)) / (mapboxTailsSize * metersPerPixel)))

  const getMapRatioZoom = (zoom: number, latitude: number) => {
    const originalMetersPerPixel = getMetersPerPixel(zoom, latitude);
    const objectBoxOriginalDimensions = originalMetersPerPixel * defaultMapScreenHeight;
    const metersPerPixel = objectBoxOriginalDimensions / screenHeight;

    return getZoomFromMetersPerPixel(latitude, metersPerPixel);
  }

  return getMapRatioZoom;
};
