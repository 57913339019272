import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { DEFAULTS } from 'constants/defaults.constant';

import { getValue } from 'components/generics/getValue';

import { parseThousand } from 'utils/parser.utils';

import { INftCardCurrencyAndValue } from '../types';

interface INftCardValueProps extends INftCardCurrencyAndValue {
  ariaLive?: boolean;
  precision?: number;
  currencyClassName?: string;
  valueClassName?: string;
}

const NftCardValue: FC<INftCardValueProps> = ({
  currency,
  value,
  precision = DEFAULTS.defaultPricePrecision,
  ariaLive = false,
  currencyClassName,
  valueClassName,
}) => {
  const renderValue = (value: string) => {
    if (!value || parseInt(value) === 0) return '-';

    return parseThousand(parseFloat(value), precision);
  };

  return (
    <div className="tw-flex tw-items-center tw-gap-0.5">
      {currency && (
        <img
          className={twMerge('tw-h-4 tw-w-4 tw-p-px', currencyClassName)}
          src={currency.icon}
          alt={currency.symbol}
        />
      )}
      <span aria-live={ariaLive ? 'polite' : 'off'} className={twMerge('tw-leading-4 tw-text-white', valueClassName)}>
        {renderValue(getValue(value ?? 0, false))}
      </span>
    </div>
  );
};

export default NftCardValue;
