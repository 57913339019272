import { IValidator } from '../../models/i-form-validation.interface';

import './InputValidation.scss';

interface IInputValidationProps<T> {
  value: T;
  showError?: boolean;
  validator: IValidator<T>;
}

const InputValidation = <T extends unknown>({ value, validator, showError }: IInputValidationProps<T>) => {
  const validation = validator(value);

  return validation.valid ? (
    <></>
  ) : (
    <div className={`form-field-error ${showError && 'show'}`} data-form-field-invalid="true">
      {validation.message}
    </div>
  );
};

export default InputValidation;
