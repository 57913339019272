import { GET_FILTERS } from '../constants/actionNames';
import { IFilterReducer } from '../models/filters/i-filters.reducer.interface';

const INITIAL_STATE: IFilterReducer = {
  category: null,
  country: null,
  sort: null,
  tier: null,
  filtersLoading: false,
  filtersError: '',
};

export const filtersReducer = (state = INITIAL_STATE, action: any): IFilterReducer => {
  switch (action.type) {
    case GET_FILTERS + '_PENDING':
      return { ...state, filtersLoading: true, filtersError: '' };
    case GET_FILTERS + '_REJECTED':
      return { ...state, filtersLoading: false, filtersError: 'Api issue' };
    case GET_FILTERS + '_FULFILLED':
      return {
        ...state,
        category:
          action.payload.data.category?.map((category: { label: string; value: string }) => {
            const label = category?.label.replace(/\w\S*/g, (text) => {
              return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
            });

            return {
              ...category,
              label: label.length <= 2 ? label.toUpperCase() : label,
            };
          }) ?? [],
        country: action.payload.data.country,
        sort: action.payload.data.sort,
        tier: action.payload.data.tier,
        filtersLoading: false,
        filtersError: '',
      };
    default:
      return state;
  }
};
