import { useNavigate } from 'react-router-dom';

import { DEFAULTS } from '../constants/defaults.constant';
import { ROUTES } from '../constants/routes.constant';

import { useWindowSize } from './useWindowSize';

export const useHomePageNavigate = () => {
  const navigate = useNavigate();
  const [width] = useWindowSize();
  const mapPageDisabled = width < DEFAULTS.minWindowWidthForMap;
  const homePage = ROUTES.MARKET;

  const navigateToHomePage = () => {
    navigate(homePage);
  };

  return {
    homePage,
    mapPageDisabled,
    navigateToHomePage,
  };
};
