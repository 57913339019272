import styles from './LandingInfoCard.module.scss';

interface ILandingInfoCardProps {
  title: JSX.Element;
  content: JSX.Element;
  footer?: JSX.Element;
  graphics?: JSX.Element;
  className?: string;
  contentClassName?: string;
  graphicsClassName?: string;
  mainTextClassName?: string;
}

const LandingInfoCard = ({
  title,
  content,
  footer,
  graphics,
  className = '',
  contentClassName = '',
  graphicsClassName = '',
  mainTextClassName = '',
}: ILandingInfoCardProps) => {
  const classNames = [className, styles['card']].join(' ');
  const contentClassNames = [contentClassName, styles['content']].join(' ');
  const mainTextClassNames = [mainTextClassName, styles['main-text']].join(' ');
  const graphicsClassNames = [graphicsClassName, styles['graphics']].join(' ');

  return (
    <div className={classNames}>
      <div className={contentClassNames}>
        <div className={styles['title']}>{title}</div>
        <div className={mainTextClassNames}>{content}</div>
        {!!footer && <div className={styles['footer']}>{footer}</div>}
      </div>
      <div className={graphicsClassNames}>{graphics}</div>
    </div>
  );
};

export default LandingInfoCard;
