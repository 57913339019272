import { createRef, useEffect } from 'react';

import { WheelData } from '../WheelOfFortune/types';
import { drawWheel, DrawWheelProps } from './WheelOfFortuneCanvas.utils';

interface WheelCanvasProps extends DrawWheelProps {
  width: string;
  height: string;
  data: WheelData[];
}

const WheelOfFortuneCanvas = ({ width, height, data, ...drawWheelProps }: WheelCanvasProps): JSX.Element => {
  const canvasRef = createRef<HTMLCanvasElement>();

  useEffect(() => {
    drawWheel(canvasRef, data, drawWheelProps);
  }, [canvasRef, data, drawWheelProps, drawWheelProps.rouletteUpdater]);

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      className="tw-z-10 tw-h-[94%] tw-w-[94%] tw-rounded-full tw-bg-menuLinkColor"
    />
  );
};

export default WheelOfFortuneCanvas;
