import { sendGA4Event } from 'services/analytics-events.service';

import { ILoadingTimeParam, useEventParamsMethods } from './useEventsParams';

enum ConnectWalletEventName {
  Clicked = 'connect_wallet_clicked',
  Result = 'connect_wallet_result',
}

export const useConnectWalletEvents = () => {
  const { withWalletAddressAndReferrer } = useEventParamsMethods();

  const sendConnectWalletClickedEvent = () => {
    sendGA4Event(ConnectWalletEventName.Clicked, withWalletAddressAndReferrer());
  };

  const sendConnectWalletResultEvent = (params: ILoadingTimeParam, callback: () => void) => {
    sendGA4Event(ConnectWalletEventName.Result, withWalletAddressAndReferrer(params));
    callback();
  };

  return { sendConnectWalletClickedEvent, sendConnectWalletResultEvent };
};
