import { IMarketTradesData } from 'models/market/i-market-trades-payload.interface';
import { ITradeDetails } from 'models/trade/i-trade-details.interface';

import { PriceCurrencySymbols } from 'enums/price-currency-symbols.enum';

const defaultTradeMock: ITradeDetails = {
  id: 355,
  asset_id: 1,
  asset: {
    id: 81,
    contract: {
      id: 12,
      network: 80001,
      name: 'Reality NFT',
      address: '0x2f4bc0574C476eC2839436d0ddCCe4d161eeDC74',
      nft_version: '1.0',
      asset_contract_type: 'non-fungible',
      schema_name: 'ERC1155',
      visible: true,
      collection: 11,
    },
    lowest_possible_price: null,
    last_sale_price: {
      price: 321.0,
      currency: {
        id: 7,
        network: 80001,
        name: 'USDC',
        symbol: PriceCurrencySymbols.USDC,
        address: '0xe6b8a5CF854791412c1f6EFC7CAf629f5Df1c747',
        decimal: 6,
      },
    },
    shares_currently_on_sale: 2,
    last_week_income: null,
    name: 'Kinshasa',
    token_id: '1000',
    address: 'Kinshasa',
    marker_url: 'https://assets.r5y.io/lgo-live/icons/markers/default.png',
    image_original_url: 'https://assets.r5y.io/lgo-live/icons/card_big_default.png',
    animation_url: 'https://assets.r5y.io/lgo-live/animations/613.mp4',
    total_shares: 50000,
    traits: [
      {
        id: 1,
        type: 'category',
        value: 'Park',
      },
    ],
  },
  seller: { id: 47, address: '0xAffA13f5690858a143665483b9F2EDE30b84089e' },
  owned_awaiting_offer: null,
  stats: { owned_offers: 0, others_offers: 0, others_offers_active: 0, owned_offers_active: 0 },
  type: 1,
  status: 0,
  contract_type: 0,
  nonce: 39,
  expiration_time: 1670695056,
  price: '10000000000000000',
  amount: '1',
  state_hash: '0x0000000000000000000000000000000000000000000000000000000000000000',
  auction_id: null,
  stable_exchange_rate: null,
  auction_house_expiration_datetime: null,
  currency_id: 6,
  signature: '00000',
};

const tradeMocks: ITradeDetails[] = [
  defaultTradeMock,
  {
    ...defaultTradeMock,
    asset: {
      ...defaultTradeMock.asset,
      address: 'TH',
      image_original_url: 'https://assets.r5y.io/lgo-live/animations/test/153.mp4',
      marker_url: 'https://assets.r5y.io/lgo-live/icons/markers/default.png',
      name: 'Ayutthaya Historical Park',
    },
  },
  defaultTradeMock,
  {
    ...defaultTradeMock,
    asset: {
      ...defaultTradeMock.asset,
      address: 'TH',
      image_original_url: 'https://assets.r5y.io/lgo-live/animations/test/153.mp4',
      marker_url: 'https://assets.r5y.io/lgo-live/icons/markers/default.png',
      name: 'Ayutthaya Historical Park',
    },
  },
  defaultTradeMock,
  {
    ...defaultTradeMock,
    asset: {
      ...defaultTradeMock.asset,
      address: 'TH',
      image_original_url: 'https://assets.r5y.io/lgo-live/animations/test/153.mp4',
      marker_url: 'https://assets.r5y.io/lgo-live/icons/markers/default.png',
      name: 'Ayutthaya Historical Park',
    },
  },
  defaultTradeMock,
  {
    ...defaultTradeMock,
    asset: {
      ...defaultTradeMock.asset,
      address: 'TH',
      image_original_url: 'https://assets.r5y.io/lgo-live/animations/test/153.mp4',
      marker_url: 'https://assets.r5y.io/lgo-live/icons/markers/default.png',
      name: 'Ayutthaya Historical Park',
    },
  },
  defaultTradeMock,
  {
    ...defaultTradeMock,
    asset: {
      ...defaultTradeMock.asset,
      address: 'TH',
      image_original_url: 'https://assets.r5y.io/lgo-live/animations/test/153.mp4',
      marker_url: 'https://assets.r5y.io/lgo-live/icons/markers/default.png',
      name: 'Ayutthaya Historical Park',
    },
  },
  defaultTradeMock,
  {
    ...defaultTradeMock,
    asset: {
      ...defaultTradeMock.asset,
      address: 'TH',
      image_original_url: 'https://assets.r5y.io/lgo-live/animations/test/153.mp4',
      marker_url: 'https://assets.r5y.io/lgo-live/icons/markers/default.png',
      name: 'Ayutthaya Historical Park',
    },
  },
  defaultTradeMock,
  {
    ...defaultTradeMock,
    asset: {
      ...defaultTradeMock.asset,
      address: 'TH',
      image_original_url: 'https://assets.r5y.io/lgo-live/animations/test/153.mp4',
      marker_url: 'https://assets.r5y.io/lgo-live/icons/markers/default.png',
      name: 'Ayutthaya Historical Park',
    },
  },
];

export const MARKET_OFFERS_MOCK: IMarketTradesData = {
  totalPages: 1,
  currentPage: 1,
  results: tradeMocks,
} as any;

export const RTLM_MULTIPLIER: number = 0.1;

export const USDC_MULTIPLIER: number = 1;
