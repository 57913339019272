import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious, useToggle } from 'react-use';

import { IState } from 'models/reducers/i-state.interface';

import { addErrorMessageAction } from 'actions/global-actions';

import { getNftRoyalties, getNftTotalPassiveIncomeForOwner } from 'services/passive-income.service';
import { fetchSalesVolume } from 'services/sales-volume.service';
import { fetchTopOwners } from 'services/top-owners.service';
import { fetchAssetPreviewDetailsService } from 'services/venues.service';

import { useNftCardExpectedAnnualYield } from 'hooks/shared/useNftCardExpectedAnnualYield';
import { useCurrency, useDefaultCurrency } from 'hooks/useCurrency';

import { getIsEmpty } from 'utils/get-is-empty.utils';
import { parseSciNumToString, parseToString } from 'utils/parser.utils';

import { useNftCardContext } from './nftCardContext/NftCardContext';
import { INftCardCurrencyAndValue, INftCardRoyalties, INftCardSalesVolume, INftCardTopOwners } from './types';

export const useNftCardSize = () => {
  const { size } = useNftCardContext();

  return {
    isCardLarge: size === 'large',
    isCardMedium: size === 'medium',
    isCardSmall: size === 'small',
  };
};

const useNftCardRoyalties = (nftId: string) => {
  const dispatch = useDispatch();
  const adaptCurrencyAndValue = useNftCardCurrencyAndValue();
  const [royalties, setNftRoyalties] = useState<INftCardRoyalties>();
  const [loadingRoyalties, toggleLoading] = useToggle(false);

  const handleReset = () => {
    setNftRoyalties(undefined);
  };

  const handleFetch = () => {
    if (!royalties) {
      toggleLoading(true);
      getNftRoyalties(nftId)
        .then(({ last24hours, last7days, total }) => {
          setNftRoyalties({
            last24hours: adaptCurrencyAndValue(last24hours),
            last7days: adaptCurrencyAndValue(last7days),
            total: adaptCurrencyAndValue(total),
          });
          toggleLoading(false);
        })
        .catch((error) => {
          dispatch(addErrorMessageAction(error.message));
          toggleLoading(false);
        });
    }
  };

  return {
    royalties,
    loadingRoyalties,
    onFetchRoyalties: handleFetch,
    onResetRoyalties: handleReset,
  };
};

const useNftCardOwnedEarnings = (nftId: string) => {
  const dispatch = useDispatch();
  const profile = useSelector(({ profile }: IState) => profile.profile);
  const defaultCurrency = useDefaultCurrency();
  const currency = useCurrency(defaultCurrency?.id);
  const [ownedEarnings, setOwnedEarnings] = useState<INftCardCurrencyAndValue>();
  const [loadingOwnedEarnings, toggleLoading] = useToggle(false);

  const handleReset = () => {
    setOwnedEarnings(undefined);
  };

  const handleFetch = () => {
    if (!ownedEarnings && profile) {
      toggleLoading(true);
      getNftTotalPassiveIncomeForOwner(nftId, profile.address, profile.date_joined)
        .then((data) => {
          !getIsEmpty(data) && setOwnedEarnings({ currency, value: data.income });
          toggleLoading(false);
        })
        .catch((error) => {
          dispatch(addErrorMessageAction(error.message));
          toggleLoading(false);
        });
    }
  };

  return {
    ownedEarnings,
    loadingOwnedEarnings,
    onFetchOwnedEarnings: handleFetch,
    onResetOwnedEarnings: handleReset,
  };
};

const useNftCardSalesVolume = (nftId: string) => {
  const dispatch = useDispatch();
  const defaultCurrency = useDefaultCurrency();
  const adaptCurrencyAndValue = useNftCardCurrencyAndValue();
  const [salesVolume, setSalesVolume] = useState<INftCardSalesVolume>();
  const [loadingSalesVolume, toggleLoading] = useToggle(false);

  const handleReset = () => {
    setSalesVolume(undefined);
  };

  const adaptValue = (value: number) =>
    adaptCurrencyAndValue(parseFloat(parseSciNumToString(value, defaultCurrency!.decimal)));

  const handleFetch = () => {
    if (!salesVolume) {
      toggleLoading(true);
      fetchSalesVolume(nftId)
        .then(({ last_week, last_month }) => {
          setSalesVolume({
            last7days: adaptValue(last_week),
            last30days: adaptValue(last_month),
          });
          toggleLoading(false);
        })
        .catch((error) => {
          dispatch(addErrorMessageAction(error.message));
          toggleLoading(false);
        });
    }
  };

  return {
    salesVolume,
    loadingSalesVolume,
    onFetchSalesVolume: handleFetch,
    onResetSalesVolume: handleReset,
  };
};

const useNftAssetDetails = (nftId: string) => {
  const dispatch = useDispatch();
  const [activeShares, setActiveShares] = useState<number | undefined>();
  const [totalOwners, setTotalOwners] = useState<number | undefined>();
  const [loadingAssetDetails, toggleLoading] = useToggle(false);

  const handleReset = () => {
    setActiveShares(undefined);
    setTotalOwners(undefined);
  };

  const handleFetch = () => {
    if (!activeShares || !totalOwners) {
      toggleLoading(true);
      fetchAssetPreviewDetailsService(nftId)
        .then(({ data }) => data)
        .then((data) => {
          setActiveShares(data.active_shares);
          setTotalOwners(data.owners_count);
          toggleLoading(false);
        })
        .catch((error) => {
          dispatch(addErrorMessageAction(error.message));
          toggleLoading(false);
        });
    }
  };

  return {
    activeShares,
    totalOwners,
    loadingAssetDetails,
    onFetchAssetDetails: handleFetch,
    onResetAssetDetails: handleReset,
  };
};

const useNftCardTopOwners = (nftId: string) => {
  const dispatch = useDispatch();
  const [topOwners, setTopOwners] = useState<INftCardTopOwners[]>();
  const [loadingTopOwners, toggleLoading] = useToggle(false);

  const handleReset = () => {
    setTopOwners(undefined);
  };

  const handleFetch = () => {
    if (!topOwners) {
      toggleLoading(true);
      fetchTopOwners(nftId)
        .then((data) => {
          setTopOwners(data);
          toggleLoading(false);
        })
        .catch((error) => {
          dispatch(addErrorMessageAction(error.message));
          toggleLoading(false);
        });
    }
  };

  return {
    topOwners,
    loadingTopOwners,
    onFetchTopOwners: handleFetch,
    onResetTopOwners: handleReset,
  };
};

export const useNftCardBackData = (nftId: string, preview?: boolean) => {
  const { royalties, loadingRoyalties, onFetchRoyalties, onResetRoyalties } = useNftCardRoyalties(nftId);
  const { ownedEarnings, loadingOwnedEarnings, onFetchOwnedEarnings, onResetOwnedEarnings } =
    useNftCardOwnedEarnings(nftId);
  const { expectedAnnualYield, loadingExpectedAnnualYield, onFetchExpectedAnnualYield, onResetExpectedAnnualYield } =
    useNftCardExpectedAnnualYield(nftId);
  const { salesVolume, loadingSalesVolume, onFetchSalesVolume, onResetSalesVolume } = useNftCardSalesVolume(nftId);
  const { activeShares, totalOwners, loadingAssetDetails, onFetchAssetDetails, onResetAssetDetails } =
    useNftAssetDetails(nftId);
  const { topOwners, loadingTopOwners, onFetchTopOwners, onResetTopOwners } = useNftCardTopOwners(nftId);

  const previousNftId = usePrevious(nftId);

  useEffect(() => {
    if (previousNftId !== nftId) {
      onResetRoyalties();
      onResetOwnedEarnings();
      onResetExpectedAnnualYield();
      onResetSalesVolume();
      onResetAssetDetails();
      onResetTopOwners();
    }
  }, [nftId]);

  const loading =
    loadingRoyalties ||
    loadingOwnedEarnings ||
    loadingExpectedAnnualYield ||
    loadingSalesVolume ||
    loadingAssetDetails ||
    loadingTopOwners;

  const handleCallback = (slide: number) => {
    if (slide === 0) {
      !preview && onFetchRoyalties();
      onFetchOwnedEarnings();
      onFetchExpectedAnnualYield();
      onFetchSalesVolume();
    } else if (slide === 1) {
      !preview && onFetchAssetDetails();
      onFetchTopOwners();
    }
  };

  return {
    data: { royalties, salesVolume, activeShares, totalOwners, topOwners, expectedAnnualYield, ownedEarnings },
    onCallback: handleCallback,
    loading,
  };
};

export const useNftCardCurrencyAndValue = () => {
  const defaultCurrency = useDefaultCurrency();
  const currency = useCurrency(defaultCurrency?.id);

  return (value: number | undefined) => ({
    currency,
    value: value ? parseToString(value) : '0',
  });
};
