import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setWalletAddress } from '../actions/wallet.action';
import { CookiesKeys } from '../enums/cookies-keys.enum';
import { Web3Providers } from '../reducers/web3.reducer';
import Web3Singleton from '../web3Singleton';
import { useCookies } from './useCookies';
import { useLoading } from './useLoading';

export const useInitializeWeb3 = (): void => {
  const dispatch = useDispatch();
  const setLoading = useLoading();
  const lgoWeb3 = Web3Singleton.getInstance();
  const walletAddress = useCookies().getCookie(CookiesKeys.walletAddress);

  useEffect(() => {
    const initializeWeb3 = async (): Promise<void> => {
      setLoading(true);

      try {
        const currentProvider = await lgoWeb3.initializeProviders();
        
        if (currentProvider !== Web3Providers.none) {
          dispatch(setWalletAddress(walletAddress));
        }
      } catch(e) {}

      setLoading(false);
    };

    initializeWeb3();
  }, []);
};
