import { Link } from 'react-router-dom';
import { ReactComponent as ArrowBackSVG } from '../../../assets/arrowBack.svg';
import styles from './MenuLandingLink.module.scss';

const MenuLandingLink = () => {
  return (
    <Link to="/" className={styles['menu__link']}>
      <ArrowBackSVG />
    </Link>
  );
};

export default MenuLandingLink;
