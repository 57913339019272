import Slider from 'rc-slider/lib/Slider';
import { useDispatch, useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { IState } from 'models/reducers/i-state.interface';

import { GameStatusTag } from '../constants/games.constants';

import { setShowSelectWalletModal } from 'actions/global-actions';

import Button from 'components/buttons/Button';
import ElementLoader from 'components/element-loader/ElementLoader';

import { useEnv } from 'hooks/useEnv';

import { parseThousand } from 'utils/parser.utils';

import RMV from 'assets/icons/RMV.png';

import GameLabelPanel from '../gameLabelPanel/GameLabelPanel';
import GameTag from '../gameTag/GameTag';

const RMVCoin = ({ text, completed = false }: { text: string; completed?: boolean }) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-gap-1">
      <img src={RMV} alt="RMV" className="tw-w-5" />
      <span
        className={twMerge(
          'tw-text-sm tw-uppercase tw-leading-7 lg:tw-text-lg',
          completed ? 'tw-text-white' : 'tw-text-backgroundColorMedium'
        )}
      >
        {text}
      </span>
    </div>
  );
};

const renderTick = (value: number, fractionDigits = 0): string => {
  switch (true) {
    case value >= 1000000:
      return (value / 1000000).toFixed(fractionDigits) + 'M';
    case value >= 1000:
      return (value / 1000).toFixed() + 'K';
    default:
      return value.toString();
  }
};

const generateMarks = (target: string, total: string, staking_closed: boolean) => {
  const marks: { [key: number]: JSX.Element } = {};
  const percentages = [2, 22, 42, 62, 82, 98];

  percentages.forEach((percent) => {
    switch (true) {
      case +target === 0:
        marks[percent] = <RMVCoin text="-" />;
        break;
      case percent === 2:
        marks[percent] = <RMVCoin text="0" completed={+total > 0} />;
        break;
      case percent === 98:
        marks[percent] = (
          <RMVCoin text={renderTick(Math.ceil((+target * (percent + 2)) / 100))} completed={staking_closed} />
        );
        break;
      default:
        marks[percent] = (
          <RMVCoin
            text={renderTick((+target * percent - 2) / 100, +target === 2000000 ? 1 : 0)}
            completed={(+total / +target) * 100 > percent - 2}
          />
        );
        break;
    }
  });

  return marks;
};

enum PoolType {
  Locked = 'Locked',
  Flexible = 'Flexible',
}

const renderPoolType = (poolType: number | null) => {
  if (poolType === null) return '';

  const adapt: Record<number, PoolType> = {
    0: PoolType.Locked,
    1: PoolType.Flexible,
  };

  return adapt[poolType] ?? '';
};

interface IGameStakingRaisedProps {
  poolId: number | undefined;
  poolEnded: boolean;
  flexible: boolean | undefined;
  negative: boolean | undefined;
  multiplier: number | undefined;
  fee: string;
  staking_closed: boolean;
  disabled: boolean;
  hasPoolId: boolean;
  poolType: number | null;
  total: string;
  target: string;
  loading: boolean;
}

const GameStakingRaised = ({
  poolId,
  poolEnded,
  flexible,
  negative,
  multiplier = 1,
  fee,
  staking_closed,
  disabled,
  hasPoolId,
  poolType,
  total,
  target,
  loading,
}: IGameStakingRaisedProps) => {
  const dispatch = useDispatch();
  const walletAddress = useSelector((state: IState): string => state.wallet.address);
  const { hideLogin } = useEnv();

  const setShowConnectWalletModal = () => {
    dispatch(setShowSelectWalletModal(true));
  };

  const renderValue = (value: string) => {
    if (disabled && +target === 0) return '0.00';

    return parseThousand(+value, 2);
  };

  const renderSliderValue = () => {
    switch (true) {
      case +target === 0:
        return undefined;
      default:
        return (+total / +target) * 100;
    }
  };

  return (
    <div className="tw-relative tw-rounded-2xl tw-bg-blueCardGradient tw-p-5 lg:tw-p-7.5">
      <ElementLoader className="tw-rounded-2xl" loading={loading}>
        <h3 className="tw-mb-4 tw-text-[32px]/[24px] tw-uppercase tw-text-white">
          {poolEnded || staking_closed ? 'Staking closed' : 'Invest in the game by staking'}
        </h3>
        <div className="tw-flex tw-flex-row tw-gap-x-2">
          {staking_closed && <GameTag variant="blue">{GameStatusTag.StakingClosed}</GameTag>}
          {hasPoolId && (
            <div className="tw-h-[34px]">
              {poolType && <GameTag variant="border">{renderPoolType(poolType)}</GameTag>}
            </div>
          )}
          {flexible && <GameTag variant="border">{poolId === 0 ? 'NFT rewards' : 'In-game rewards'}</GameTag>}
        </div>
        <p className="tw-mt-5 tw-text-[18px]/[20px] tw-uppercase tw-tracking-[3.6px] tw-text-turquoise lg:tw-mt-7.5">
          {hasPoolId ? 'Staking raised' : 'Staking unavailable'}
        </p>
        <div className={hasPoolId ? 'tw-pb-12 lg:tw-pb-14' : 'tw-opacity-50'}>
          <div className="tw-my-2.5 tw-flex tw-items-baseline tw-justify-between tw-self-stretch lg:tw-my-4">
            <p className="tw-flex tw-flex-col tw-text-[22px]/[23px] tw-font-semibold tw-uppercase tw-text-white">
              <>
                <span
                  className={twMerge(
                    'tw-text-[48px]/[38px] lg:tw-text-[48px]/[38px]',
                    +total > 0 && staking_closed && 'tw-text-green'
                  )}
                >
                  {renderValue(+target - +total < 1 ? target : total)}
                </span>{' '}
                / {renderValue(target)}
              </>
            </p>
            <p className="tw-text-center tw-font-semibold tw-uppercase tw-leading-7 tw-text-white">production starts</p>
          </div>
          <Slider
            value={renderSliderValue()}
            marks={hasPoolId ? generateMarks(target, total, poolEnded || staking_closed) : undefined}
            disabled={disabled}
            style={{ height: '14px', padding: '2px 0' }}
            railStyle={{ background: '#0E2659', height: '10px' }}
            trackStyle={{ background: '#00EB8A', height: '10px' }}
            handleStyle={{ display: 'none' }}
            dotStyle={{ display: 'none' }}
            className="tw-bg-[unset]"
          />
        </div>
        {!disabled && !hideLogin && !walletAddress && (
          <div className="tw-flex">
            <Button className="tw-mx-auto" size="medium" ariaLabel="Connect wallet" onClick={setShowConnectWalletModal}>
              connect wallet
            </Button>
          </div>
        )}
        {!disabled && !hideLogin && walletAddress && (negative || flexible) && multiplier && (
          <div className="tw-flex tw-flex-col tw-gap-y-2.5">
            <span className="tw-text-[18px]/[20px] tw-uppercase tw-text-white">General Staking Info</span>
            <GameLabelPanel
              label="Development pool"
              labelClassName="tw-text-[18px]/[20px]"
              className="!tw-flex-row tw-items-center tw-justify-between !tw-p-2.5 xl:tw-w-full"
            >
              <span className="tw-text-[20px]/[20px] tw-text-white">{fee}%</span>
            </GameLabelPanel>
            {!flexible && (
              <GameLabelPanel
                label="New token multiplier"
                labelClassName="tw-text-[18px]/[20px]"
                className="!tw-flex-row tw-items-center tw-justify-between !tw-p-2.5 xl:tw-w-full"
                tooltip="1 $RMV will be worth approx. 35,000 $Trumpet Coins (depending on the current US National Dept)"
              >
                <span className="tw-text-[20px]/[20px] tw-text-white">x {multiplier.toLocaleString()}</span>
              </GameLabelPanel>
            )}
          </div>
        )}
      </ElementLoader>
    </div>
  );
};

export default GameStakingRaised;
