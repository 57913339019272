import { DebounceInput } from 'react-debounce-input';
import { useDispatch, useSelector } from 'react-redux';

import { IState } from '../../models/reducers/i-state.interface';

import { DEFAULTS } from '../../constants/defaults.constant';

import { searchBuildingAction } from '../../actions/search.action';

import CrossIcon from '../../assets/icons/cross.svg';

import styles from './SearchBar.module.scss';

interface ISearchBar {
  outlined?: boolean;
  onChange: (change: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const SearchBar = ({ onChange, disabled = false, outlined }: ISearchBar) => {
  const dispatch = useDispatch();
  const inputValue = useSelector((state: IState) => state.search.search);

  const clearSearch = (): void => {
    dispatch(searchBuildingAction(''));
  };

  return (
    <div className={`search-bar ${styles['search-bar']} ${outlined ? styles['search-bar--outlined'] : ''}`}>
      <DebounceInput
        className={styles['search-bar__input']}
        placeholder="Search..."
        value={inputValue}
        debounceTimeout={DEFAULTS.defaultSearchDebounceTime}
        onChange={(event) => onChange(event)}
      />
      {inputValue && (
        <button onClick={clearSearch} className={styles['search-bar__clear-icon']}>
          <img src={CrossIcon} alt="Reset search input" />
        </button>
      )}
    </div>
  );
};

export default SearchBar;
