import { AxiosResponse } from 'axios';

import { ERROR_MESSAGES } from '../constants/error-messages.constant';
import { ApiStatusCode } from '../enums/api-status-code.enum';
import i18n from '../i18n';

export const getApiErrors = (errorResponse?: AxiosResponse): string[] => {
  if (!errorResponse) {
    return [i18n.t(ERROR_MESSAGES.default)];
  }

  if (typeof errorResponse.data === 'object') {
    return Object.keys(errorResponse.data).map((key: string) => {
      if (Array.isArray(errorResponse.data[key])) {
        return errorResponse.data[key][0];
      }

      if (typeof errorResponse.data[key] === 'string') {
        return errorResponse.data[key];
      }

      return i18n.t(ERROR_MESSAGES.default);
    });
  }

  switch (errorResponse.status) {
    case ApiStatusCode.errorNotFound:
      return [i18n.t(ERROR_MESSAGES.notFound)];
    case ApiStatusCode.errorUnauthorized:
      return [i18n.t(ERROR_MESSAGES.unauthorized)];
    case ApiStatusCode.errorForbidden:
      return [i18n.t(ERROR_MESSAGES.noPermissions)];
  }

  return [i18n.t(ERROR_MESSAGES.default)];
};
