import ScrollInAnimationItem from '../../../components/scrollInAnimationItem/ScrollInAnimationItem';

import Landmarks from '../../../assets/game-onboarding/landmarks.png';

import GameOnboardingInfoCard from '../gameOnboardingInfoCard/GameOnboardingInfoCard';

const GameOnboardingLandmarksSection = () => {
  return (
    <div className="tw-mb-[80px] tw-flex tw-w-[1360px] tw-max-w-full tw-max-w-full tw-flex-col tw-flex-wrap tw-items-center tw-justify-between tw-gap-[40px] lg:tw-mb-[120px] lg:tw-flex-row">
      <ScrollInAnimationItem className="tw-max-w-full lg:tw-w-[758px] lg:tw-max-w-[60%]" delay="0.1s">
        <img src={Landmarks} alt="" className="tw-w-full" />
      </ScrollInAnimationItem>
      <ScrollInAnimationItem className="tw-max-w-full lg:tw-w-[505px] lg:tw-max-w-[calc(40%-40px)]" delay="0.1s">
        <GameOnboardingInfoCard
          title={
            <>
              iconic landmarks as <span className="tw-text-turquoise">nft</span>
            </>
          }
          content={
            <>
              <p>
                2000 most famous landmarks globally tokenized into 3D NFT. Each of the NFTs represents real-world
                buildings, cities, and countries. They are made on a 1:1 scale and are 3D assets compatible to use in
                other games and Metaverses.
              </p>
            </>
          }
        />
      </ScrollInAnimationItem>
    </div>
  );
};

export default GameOnboardingLandmarksSection;
