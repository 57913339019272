import styles from './IconsSprite.module.scss';

const IconsSprite = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={styles['icons-sprite']}>
      <defs>
        <symbol viewBox="0 0 19 17" id="icon-sprite-owners-icon">
          <path
            d="M10.244 9C10.8407 9 11.413 9.23705 11.835 9.65901C12.2569 10.081 12.494 10.6533 12.494 11.25L12.4925 12.372C12.647 15.1275 10.5245 16.5015 6.59 16.5015C2.669 16.5 0.5 15.1455 0.5 12.4125V11.25C0.5 10.6533 0.737053 10.081 1.15901 9.65901C1.58097 9.23705 2.15326 9 2.75 9H10.244ZM16.244 9C16.8407 9 17.413 9.23705 17.835 9.65901C18.2569 10.081 18.494 10.6533 18.494 11.25V12.0405C18.629 14.511 16.754 15.75 13.328 15.75C12.863 15.75 12.425 15.7275 12.017 15.6825C12.821 14.9385 13.2515 13.9305 13.25 12.6555L13.241 12.33L13.244 11.25C13.244 10.353 12.851 9.549 12.227 9H16.244V9ZM6.5 0C7.49456 0 8.44839 0.395088 9.15165 1.09835C9.85491 1.80161 10.25 2.75544 10.25 3.75C10.25 4.74456 9.85491 5.69839 9.15165 6.40165C8.44839 7.10491 7.49456 7.5 6.5 7.5C5.50544 7.5 4.55161 7.10491 3.84835 6.40165C3.14509 5.69839 2.75 4.74456 2.75 3.75C2.75 2.75544 3.14509 1.80161 3.84835 1.09835C4.55161 0.395088 5.50544 0 6.5 0V0ZM14 1.5C14.7956 1.5 15.5587 1.81607 16.1213 2.37868C16.6839 2.94129 17 3.70435 17 4.5C17 5.29565 16.6839 6.05871 16.1213 6.62132C15.5587 7.18393 14.7956 7.5 14 7.5C13.2044 7.5 12.4413 7.18393 11.8787 6.62132C11.3161 6.05871 11 5.29565 11 4.5C11 3.70435 11.3161 2.94129 11.8787 2.37868C12.4413 1.81607 13.2044 1.5 14 1.5Z"
            fill="currentColor"
          />
        </symbol>
        <symbol viewBox="0 0 18 18" id="icon-sprite-share-icon">
          <path
            d="M0.5 9C0.5 10.654 1.846 12 3.5 12C4.294 12 5.012 11.685 5.549 11.18L11.54 14.604C11.522 14.734 11.5 14.864 11.5 15C11.5 16.654 12.846 18 14.5 18C16.154 18 17.5 16.654 17.5 15C17.5 13.346 16.154 12 14.5 12C13.706 12 12.988 12.315 12.451 12.82L6.46 9.397C6.478 9.266 6.5 9.136 6.5 9C6.5 8.864 6.478 8.734 6.46 8.603L12.451 5.18C12.988 5.685 13.706 6 14.5 6C16.154 6 17.5 4.654 17.5 3C17.5 1.346 16.154 0 14.5 0C12.846 0 11.5 1.346 11.5 3C11.5 3.136 11.522 3.266 11.54 3.397L5.549 6.82C4.996 6.29468 4.26273 6.00123 3.5 6C1.846 6 0.5 7.346 0.5 9Z"
            fill="currentColor"
          />
        </symbol>
        <symbol viewBox="0 0 25 24" id="icon-sprite-views-icon">
          <path
            d="M12.5 15C14.1569 15 15.5 13.6569 15.5 12C15.5 10.3431 14.1569 9 12.5 9C10.8431 9 9.5 10.3431 9.5 12C9.5 13.6569 10.8431 15 12.5 15Z"
            fill="currentColor"
          />
          <path
            d="M23.7047 11.745C22.8226 9.46324 21.2912 7.48996 19.2998 6.06906C17.3084 4.64817 14.9443 3.84193 12.4997 3.75C10.0551 3.84193 7.69097 4.64817 5.69958 6.06906C3.70819 7.48996 2.1768 9.46324 1.29468 11.745C1.23511 11.9098 1.23511 12.0902 1.29468 12.255C2.1768 14.5368 3.70819 16.51 5.69958 17.9309C7.69097 19.3518 10.0551 20.1581 12.4997 20.25C14.9443 20.1581 17.3084 19.3518 19.2998 17.9309C21.2912 16.51 22.8226 14.5368 23.7047 12.255C23.7643 12.0902 23.7643 11.9098 23.7047 11.745ZM12.4997 16.875C11.5355 16.875 10.593 16.5891 9.79128 16.0534C8.98959 15.5177 8.36475 14.7564 7.99577 13.8656C7.62679 12.9748 7.53025 11.9946 7.71835 11.0489C7.90646 10.1033 8.37075 9.23464 9.05254 8.55285C9.73432 7.87107 10.603 7.40677 11.5486 7.21867C12.4943 7.03057 13.4745 7.12711 14.3653 7.49609C15.2561 7.86506 16.0174 8.48991 16.5531 9.2916C17.0888 10.0933 17.3747 11.0358 17.3747 12C17.3727 13.2923 16.8584 14.5311 15.9446 15.445C15.0308 16.3588 13.792 16.873 12.4997 16.875Z"
            fill="currentColor"
          />
        </symbol>
        <symbol viewBox="0 0 21 18" id="icon-sprite-heart-icon">
          <path
            d="M11.8497 17.1307C11.0897 17.8207 9.91967 17.8207 9.15967 17.1207L9.04967 17.0207C3.79967 12.2707 0.369665 9.16065 0.499665 5.28065C0.559665 3.58065 1.42966 1.95065 2.83966 0.990654C5.47966 -0.809346 8.73967 0.0306542 10.4997 2.09065C12.2597 0.0306542 15.5197 -0.819346 18.1597 0.990654C19.5697 1.95065 20.4397 3.58065 20.4997 5.28065C20.6397 9.16065 17.1997 12.2707 11.9497 17.0407L11.8497 17.1307Z"
            fill="currentColor"
          />
        </symbol>
        <symbol viewBox="0 0 24 24" id="icon-sprite-report-icon">
          <path
            d="M16.9387 5.07025L18.6026 11.2798C18.6393 11.417 18.6201 11.5632 18.5491 11.6863C18.478 11.8093 18.361 11.8991 18.2238 11.9359L13.5666 13.1838C13.4294 13.2205 13.2832 13.2013 13.1601 13.1303C13.0371 13.0592 12.9473 12.9422 12.9105 12.805L12.6332 11.77L7.97606 13.0179L9.77855 19.7449C9.81533 19.8821 9.79608 20.0284 9.72503 20.1514C9.65399 20.2745 9.53698 20.3642 9.39975 20.401C9.26251 20.4378 9.11628 20.4185 8.99324 20.3475C8.87019 20.2765 8.78041 20.1594 8.74363 20.0222L5.13865 6.56824C5.10188 6.431 5.12113 6.28478 5.19217 6.16173C5.26321 6.03869 5.38022 5.9489 5.51746 5.91213L11.2095 4.38694C11.3468 4.35017 11.493 4.36942 11.616 4.44046C11.7391 4.5115 11.8289 4.62851 11.8656 4.76575L12.1429 5.80067L16.2826 4.69145C16.4199 4.65467 16.5661 4.67393 16.6891 4.74497C16.8122 4.81601 16.902 4.93302 16.9387 5.07025Z"
            fill="currentColor"
          />
        </symbol>
        <symbol viewBox="0 0 18 16" id="icon-sprite-refresh-icon">
          <path
            d="M9.4723 15.5C9.10547 15.5 8.80809 15.1795 8.80809 14.7841C8.80809 14.3888 9.10547 14.0683 9.4723 14.0683C11.9826 14.0683 14.0377 11.9203 14.1193 9.22394C14.1312 8.82878 14.4381 8.51889 14.8048 8.53178C15.1714 8.54467 15.4589 8.87545 15.447 9.2706C15.342 12.7385 12.7 15.5 9.4723 15.5ZM8.88682 0.513826C9.25287 0.539634 9.5302 0.880377 9.50626 1.2749C9.48231 1.66942 9.16616 1.96832 8.80011 1.94251C8.70515 1.93581 8.60972 1.93221 8.51702 1.93173C6.09869 1.93167 4.09773 3.89088 3.86449 6.44509C3.82856 6.83855 3.50348 7.12612 3.13841 7.0874C2.77334 7.04868 2.50652 6.69832 2.54245 6.30486C2.84255 3.01847 5.41477 0.499919 8.52018 0.5C8.64284 0.500625 8.7651 0.505244 8.88682 0.513826ZM8.85817 14.025C9.04637 14.0517 9.23689 14.0662 9.42907 14.0681C9.79589 14.0716 10.0906 14.395 10.0872 14.7904C10.0839 15.1857 9.78383 15.5033 9.41701 15.4997C9.17078 15.4973 8.92633 15.4788 8.68462 15.4445C8.32093 15.3928 8.06495 15.0332 8.11288 14.6412C8.1608 14.2493 8.49448 13.9734 8.85817 14.025ZM15.5714 7.23026L17.2147 9.79622C17.4217 10.1196 17.3439 10.5658 17.0408 10.793C16.7377 11.0201 16.3242 10.9421 16.1171 10.6188L14.848 8.637L13.1147 9.93C12.8112 10.1564 12.3978 10.0775 12.1914 9.75361C11.985 9.42976 12.0637 8.98367 12.3673 8.75725L14.6489 7.05517C14.952 6.82909 15.3647 6.90742 15.5714 7.23026ZM0.910033 5.1557C1.19321 4.8963 1.61295 4.92701 1.84753 5.22429L3.18712 6.92184L4.92593 5.33605C5.20963 5.07732 5.62927 5.10903 5.86323 5.40687C6.09719 5.70472 6.05687 6.15592 5.77317 6.41465L3.52182 8.46789C3.23856 8.72621 2.81967 8.69507 2.58545 8.39826L0.822046 6.16363C0.587459 5.86636 0.626852 5.41509 0.910033 5.1557Z"
            fill="currentColor"
          />
        </symbol>
        <symbol viewBox="0 0 8 8" id="icon-sprite-close-icon">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.7374 0.605887C1.42498 0.293467 0.918446 0.293468 0.606027 0.605887C0.293607 0.918307 0.293607 1.42484 0.606027 1.73726L2.86877 4L0.606026 6.26274C0.293607 6.57516 0.293607 7.08169 0.606027 7.39411C0.918446 7.70653 1.42498 7.70653 1.7374 7.39411L4.00014 5.13137L6.26288 7.39411C6.5753 7.70653 7.08183 7.70653 7.39425 7.39411C7.70667 7.08169 7.70667 6.57516 7.39425 6.26274L5.13151 4L7.39425 1.73726C7.70667 1.42484 7.70667 0.918307 7.39425 0.605887C7.08183 0.293468 6.5753 0.293467 6.26288 0.605886L4.00014 2.86863L1.7374 0.605887Z"
            fill="currentColor"
          />
        </symbol>
        <symbol viewBox="0 0 15 8" id="icon-sprite-collapse-icon">
          <path
            d="M6.36417 0.376718C6.2934 0.444208 6.23357 0.518253 6.18158 0.595345L0.731226 5.79282C0.466333 6.04524 0.333984 6.37613 0.333984 6.70703C0.333984 7.03792 0.466333 7.36863 0.731227 7.62123C1.26082 8.12626 2.11959 8.12626 2.64899 7.62123L7.3462 3.14261L12.0191 7.59871C12.549 8.10373 13.4075 8.10373 13.9369 7.59871C14.4665 7.09368 14.4662 6.27494 13.9372 5.76992L8.5112 0.595436C8.45921 0.518344 8.39947 0.444299 8.3286 0.377086C8.0579 0.118944 7.70132 -0.00560284 7.34629 0.000213146C6.99146 -0.00588036 6.63507 0.118391 6.36417 0.376718Z"
            fill="currentColor"
          />
        </symbol>
        <symbol viewBox="0 0 11 17" id="icon-sprite-back-icon">
          <path
            d="M8.58579 0.24414C9.84572 0.244141 10.4767 1.76745 9.58579 2.65835L4.70711 7.53703C4.31658 7.92756 4.31658 8.56072 4.70711 8.95125L9.58579 13.8299C10.4767 14.7208 9.84572 16.2441 8.58579 16.2441C8.21071 16.2441 7.851 16.0951 7.58579 15.8299L1.41421 9.65835C0.633164 8.8773 0.633165 7.61098 1.41421 6.82993L7.58579 0.658354C7.851 0.393137 8.21071 0.24414 8.58579 0.24414Z"
            fill="currentColor"
          />
        </symbol>
        <symbol viewBox="0 0 17 17" id="icon-sprite-close-icon">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.0673 0.760735C2.37851 0.0719424 1.26176 0.0719424 0.572965 0.760735C-0.115828 1.44953 -0.115828 2.56628 0.572964 3.25507L5.56192 8.24403L0.572747 13.2332C-0.116046 13.922 -0.116046 15.0388 0.572747 15.7275C1.26154 16.4163 2.3783 16.4163 3.06709 15.7275L8.05626 10.7384L13.0447 15.7268C13.7335 16.4156 14.8502 16.4156 15.539 15.7268C16.2278 15.038 16.2278 13.9212 15.539 13.2324L10.5506 8.24403L15.5388 3.25584C16.2276 2.56705 16.2276 1.4503 15.5388 0.761504C14.85 0.072711 13.7332 0.0727122 13.0444 0.761505L8.05626 5.74969L3.0673 0.760735Z"
            fill="currentColor"
            fillOpacity="0.7"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.0673 0.760735C2.37851 0.0719424 1.26176 0.0719424 0.572965 0.760735C-0.115828 1.44953 -0.115828 2.56628 0.572964 3.25507L5.56192 8.24403L0.572747 13.2332C-0.116046 13.922 -0.116046 15.0388 0.572747 15.7275C1.26154 16.4163 2.3783 16.4163 3.06709 15.7275L8.05626 10.7384L13.0447 15.7268C13.7335 16.4156 14.8502 16.4156 15.539 15.7268C16.2278 15.038 16.2278 13.9212 15.539 13.2324L10.5506 8.24403L15.5388 3.25584C16.2276 2.56705 16.2276 1.4503 15.5388 0.761504C14.85 0.072711 13.7332 0.0727122 13.0444 0.761505L8.05626 5.74969L3.0673 0.760735Z"
            fill="currentColor"
          />
        </symbol>
        <symbol viewBox="0 0 4 19" id="icon-sprite-accent-icon">
          <path
            fill="currentColor"
            d="M3.56574 12.0437C3.54803 12.5829 3.10578 13.0109 2.56627 13.0109H1.45962C0.920106 13.0109 0.477858 12.5829 0.460154 12.0437L0.0986352 1.03281C0.0800898 0.467973 0.53295 0 1.0981 0H2.92779C3.49294 0 3.9458 0.467973 3.92725 1.03282L3.56574 12.0437ZM0 17.0251C0 16.44 0.185545 15.9624 0.556634 15.5924C0.927724 15.2138 1.411 15.0245 2.00647 15.0245C2.59331 15.0245 3.07228 15.2138 3.44337 15.5924C3.81446 15.9624 4 16.44 4 17.0251C4 17.6017 3.81446 18.075 3.44337 18.445C3.08091 18.815 2.60194 19 2.00647 19C1.40237 19 0.914779 18.815 0.543689 18.445C0.18123 18.075 0 17.6017 0 17.0251Z"
          />
        </symbol>
        <symbol viewBox="0 0 2 11" id="icon-sprite-info-icon">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="currentColor"
            d="M1 0.587891C0.432836 0.587891 0 1.00743 0 1.60058C0 2.17926 0.432836 2.5988 1 2.5988C1.56716 2.5988 2 2.17926 2 1.60058C2 1.00743 1.56716 0.587891 1 0.587891ZM1 10.5879C1.55228 10.5879 2 10.1358 2 9.57676V4.61539C2 4.05696 1.55614 3.60426 1 3.60426C0.447715 3.60426 0 4.05637 0 4.61539V9.57676C0 10.1352 0.443865 10.5879 1 10.5879Z"
          />
        </symbol>
        <symbol viewBox="0 0 19 15" id="icon-sprite-check-mark-icon">
          <path
            fill="currentColor"
            d="M0.850693 8.62999C1.27422 7.78294 2.36379 7.53008 3.11708 8.10403L5.63009 10.0187C6.03156 10.3246 6.59803 10.2833 6.95097 9.92256L15.326 1.36141C15.9022 0.77237 16.8258 0.701632 17.485 1.19605V1.19605C18.2844 1.79561 18.3675 2.96421 17.6609 3.6708L7.66648 13.6652C6.91768 14.414 5.71501 14.4494 4.92354 13.7458L1.19819 10.4344C0.687736 9.98068 0.54526 9.24086 0.850693 8.62999V8.62999Z"
          />
        </symbol>
        <symbol viewBox="0 0 24 22" id="icon-sprite-logout-icon">
          <path
            d="M23.6658 10.195L19.3744 5.90363C19.2687 5.79795 19.1432 5.71413 19.0052 5.65694C18.8671 5.59975 18.7191 5.57031 18.5697 5.57031C18.4203 5.57031 18.2723 5.59975 18.1342 5.65694C17.9962 5.71413 17.8707 5.79795 17.765 5.90363C17.5516 6.11703 17.4318 6.40646 17.4318 6.70826C17.4318 7.01006 17.5516 7.29949 17.765 7.5129L20.1139 9.86173H7.68871C7.38691 9.86173 7.09748 9.98162 6.88407 10.195C6.67067 10.4084 6.55078 10.6979 6.55078 10.9997C6.55078 11.3015 6.67067 11.5909 6.88407 11.8043C7.09748 12.0177 7.38691 12.1376 7.68871 12.1376H20.1139L17.765 14.4864C17.5516 14.6998 17.4318 14.9893 17.4318 15.2911C17.4318 15.5929 17.5517 15.8823 17.7651 16.0957C17.9785 16.3091 18.2679 16.429 18.5697 16.429C18.8715 16.429 19.161 16.3091 19.3744 16.0957L23.6658 11.8043C23.8792 11.5909 23.9991 11.3014 23.9991 10.9996C23.9991 10.6978 23.8792 10.4084 23.6658 10.195Z"
            fill="currentColor"
          />
          <path
            d="M13.2759 17.4483C12.9741 17.4483 12.6846 17.5682 12.4712 17.7816C12.2578 17.995 12.1379 18.2844 12.1379 18.5862V19.7241H2.27586V2.27586H12.1379V3.41379C12.1379 3.71559 12.2578 4.00503 12.4712 4.21843C12.6846 4.43184 12.9741 4.55172 13.2759 4.55172C13.5777 4.55172 13.8671 4.43184 14.0805 4.21843C14.2939 4.00503 14.4138 3.71559 14.4138 3.41379V1.89655C14.4138 1.64749 14.3647 1.40087 14.2694 1.17077C14.1741 0.940672 14.0344 0.731598 13.8583 0.555487C13.6822 0.379376 13.4731 0.239677 13.243 0.144366C13.0129 0.0490558 12.7663 0 12.5172 0H1.89655C1.39356 0 0.91116 0.199815 0.555487 0.555487C0.199815 0.91116 0 1.39355 0 1.89655V20.1034C0 20.6064 0.199815 21.0888 0.555487 21.4445C0.91116 21.8002 1.39356 22 1.89655 22H12.5172C12.7663 22 13.0129 21.9509 13.243 21.8556C13.4731 21.7603 13.6822 21.6206 13.8583 21.4445C14.0344 21.2684 14.1741 21.0593 14.2694 20.8292C14.3647 20.5991 14.4138 20.3525 14.4138 20.1034V18.5862C14.4138 18.2844 14.2939 17.995 14.0805 17.7816C13.8671 17.5682 13.5777 17.4483 13.2759 17.4483Z"
            fill="currentColor"
          />
        </symbol>
      </defs>
    </svg>
  );
};

export default IconsSprite;
