import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';

import { DEFAULTS } from '../../constants/defaults.constant';

import { addErrorMessageAction } from 'actions/global-actions';

import { fetchNftExpectedAnnualYield } from 'services/yields.service';

import { getIsEmpty } from 'utils/get-is-empty.utils';

export const useNftCardExpectedAnnualYield = (nftId: string) => {
  const dispatch = useDispatch();
  const [expectedAnnualYield, setExpectedAnnualYield] = useState<string | null | undefined>();
  const [loadingExpectedAnnualYield, toggleLoading] = useToggle(false);

  const handleReset = () => {
    setExpectedAnnualYield(undefined);
  };

  const adaptData = (data: { expected_annual_yield: number | null }) =>
    data.expected_annual_yield ? `${(data.expected_annual_yield * 100).toFixed(DEFAULTS.defaultPricePrecision)}%` : '-';

  const handleFetch = () => {
    if (!expectedAnnualYield) {
      toggleLoading(true);
      fetchNftExpectedAnnualYield(nftId)
        .then((data) => {
          !getIsEmpty(data) && setExpectedAnnualYield(adaptData(data));
          toggleLoading(false);
        })
        .catch((error) => {
          dispatch(addErrorMessageAction(error.message));
          toggleLoading(false);
        });
    }
  };

  return {
    expectedAnnualYield,
    loadingExpectedAnnualYield,
    onFetchExpectedAnnualYield: handleFetch,
    onResetExpectedAnnualYield: handleReset,
  };
};
