import { useState } from 'react';
import { Collapse } from 'react-collapse';

import styles from './FaqListItem.module.scss';

interface ILandingFaqListItemProps {
  title: string;
  children: React.ReactNode;
  className?: string;
}

const LandingFaqListItem = ({ children, title }: ILandingFaqListItemProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const triggerClassNames = ['button--styleless', styles['trigger'], isOpen ? styles['trigger--open'] : ''];

  return (
    <div className={styles['wrapper']}>
      <button className={triggerClassNames.join(' ')} onClick={() => setIsOpen(!isOpen)}>
        <span>{title}</span>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20" r="19" stroke="currentColor" strokeWidth="2" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 11.25C19.3096 11.25 18.75 11.8096 18.75 12.5V18.75H12.5C11.8096 18.75 11.25 19.3096 11.25 20C11.25 20.6904 11.8096 21.25 12.5 21.25H18.75V27.5C18.75 28.1904 19.3096 28.75 20 28.75C20.6904 28.75 21.25 28.1904 21.25 27.5V21.25H27.5C28.1904 21.25 28.75 20.6904 28.75 20C28.75 19.3096 28.1904 18.75 27.5 18.75H21.25V12.5C21.25 11.8096 20.6904 11.25 20 11.25Z"
            fill="white"
          />
        </svg>
      </button>
      <Collapse isOpened={isOpen}>
        <div className={styles['content']}>{children}</div>
      </Collapse>
    </div>
  );
};

export default LandingFaqListItem;
