import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { IState } from 'models/reducers/i-state.interface';

import { ERC20_ABI } from 'constants/abi/erc20-abi.constant';

import { parseDecimal } from 'utils/parser.utils';

import Web3Singleton from '../web3Singleton';
import { useRMVCurrency, useUSDCCurrency } from './useCurrency';

export const useBalanceState = (currencyId?: number) => {
  const walletAddress = useSelector((state: IState): string => state.wallet.address);
  const currencies = useSelector((state: IState) => state.wallet.currencies);
  const currency = currencies?.find((currency) => currency.id === currencyId);
  const [balance, setBalance] = useState('0');
  const [loading, setLoading] = useState(!!currencies?.length);
  const lgoWeb3 = Web3Singleton.getInstance();

  useEffect(() => {
    if (!currencies?.length || !currency || !walletAddress) {
      setBalance('0');

      return;
    }

    fetchCurrenciesBalance();
  }, [currency, currencies, walletAddress, lgoWeb3, setBalance]);

  const fetchCurrenciesBalance = async () => {
    if (!currency) {
      return;
    }

    const balance = await lgoWeb3.getCurrencyBalance(ERC20_ABI, currency.address, walletAddress);

    setLoading(false);
    setBalance(balance);
  };

  const refreshBalance = () => {
    fetchCurrenciesBalance();
  };
  const currencyBalance = currency && balance ? parseDecimal(balance, currency.decimal) : '0';

  return { loading, refreshBalance, balance: currencyBalance };
};

export const useRMVBalanceState = () => {
  const rmvCurrency = useRMVCurrency();
  const { loading, balance, refreshBalance } = useBalanceState(rmvCurrency?.id);

  return { loading, balance, refreshBalance };
};

export const useUSDCBalanceState = () => {
  const usdcCurrency = useUSDCCurrency();
  const { loading, balance, refreshBalance } = useBalanceState(usdcCurrency?.id);

  return { loading, balance, refreshBalance };
};
