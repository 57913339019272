import { IDispatchAction, IDispatchAxiosAction } from 'models/dispatch-action.interface';
import { IProfileAssetPayload, IProfileTradeOffersPayload } from 'models/profile/i-profile-asset.interface';
import { IProfileAssetsFilter } from 'models/profile/i-profile-assets-filter.interface';
import { IProfileTradesPayload } from 'models/profile/i-profile-trade.interface';
import { ITranslationReducer } from 'models/reducers/i-translation-reducer.interface';
import { IWalletCurrency } from 'models/wallet-currency-response.interface';
import { IWalletCurrencyDetails } from 'models/wallet-currency.interface';

import {
  CANCEL_BID,
  FETCH_RMV_MULTIPLIER,
  FETCH_TRANSLATIONS,
  FETCH_USDC_MULTIPLIER,
  FETCH_WALLET_CURRENCIES,
  FETCH_WALLET_CURRENCIES_WITH_BALANCE,
  REMOVE_WALLET_LISTING,
  RESET_ASSETS,
  RESET_LISTINGS,
  RESET_OFFERS,
  SET_NEW_NFTS,
  SET_PROFILE_ASSETS_FILTERS,
  SET_WALLET_ADDRESS,
  SET_WALLET_ASSETS,
  SET_WALLET_LISTINGS,
  SET_WALLET_OFFERS,
  WALLET_SIGN_OUT,
} from 'constants/actionNames';

import { getRMVMultiplier, getTranslation, getUsdcMultiplier } from 'services/configuration.service';
import {
  fetchCurrenciesService,
  fetchCurrenciesWithBalanceService,
  getAssets,
  getListingAssets,
  getOffersAssets,
  postSignOut,
} from 'services/hoard.service';
import { cancelBid } from 'services/market.service';

export const walletSignOut = (): IDispatchAction<{}> => ({
  payload: postSignOut(),
  type: WALLET_SIGN_OUT,
});

export const resetAssets = (): IDispatchAction => ({
  payload: null,
  type: RESET_ASSETS,
});

export const resetOffers = (): IDispatchAction => ({
  payload: null,
  type: RESET_OFFERS,
});

export const resetListings = (): IDispatchAction => ({
  payload: null,
  type: RESET_LISTINGS,
});

export const setWalletAddress = (payload: string): IDispatchAction<string> => ({
  payload,
  type: SET_WALLET_ADDRESS,
});

export const setWalletAssets = (
  params: IProfileAssetsFilter,
  abortSignal: AbortSignal
): IDispatchAxiosAction<IProfileAssetPayload> => ({
  payload: getAssets(params, abortSignal),
  type: SET_WALLET_ASSETS,
  skipLoading: true,
});

export const setWalletOffers = (
  params: IProfileAssetsFilter,
  abortSignal: AbortSignal
): IDispatchAxiosAction<IProfileTradeOffersPayload | null> => ({
  payload: getOffersAssets(params, abortSignal),
  type: SET_WALLET_OFFERS,
  skipLoading: true,
});

export const cancelBidWallet = (id: number): IDispatchAxiosAction<any> => ({
  payload: cancelBid(id),
  type: CANCEL_BID,
});

export const setWalletListing = (
  params: IProfileAssetsFilter,
  abortSignal: AbortSignal
): IDispatchAxiosAction<IProfileTradesPayload | null> => ({
  payload: getListingAssets(params, abortSignal),
  type: SET_WALLET_LISTINGS,
  skipLoading: true,
});

export const removeWalletListing = (listingId: number) => ({
  payload: listingId,
  type: REMOVE_WALLET_LISTING,
});

export const fetchCurrencies = (): IDispatchAxiosAction<IWalletCurrency[]> => ({
  payload: fetchCurrenciesService(),
  type: FETCH_WALLET_CURRENCIES,
});

export const fetchCurrenciesWithBalance = (accountAddress: string): IDispatchAxiosAction<IWalletCurrencyDetails[]> => ({
  payload: fetchCurrenciesWithBalanceService(accountAddress),
  type: FETCH_WALLET_CURRENCIES_WITH_BALANCE,
});

export const setProfileAssetsFilters = (filters: IProfileAssetsFilter): IDispatchAction<IProfileAssetsFilter> => ({
  payload: filters,
  type: SET_PROFILE_ASSETS_FILTERS,
});

export const fetchRtlmMultiplier = (): IDispatchAxiosAction<number> => ({
  payload: getRMVMultiplier(),
  type: FETCH_RMV_MULTIPLIER,
});

export const fetchUsdcMultiplier = (): IDispatchAxiosAction<number> => ({
  payload: getUsdcMultiplier(),
  type: FETCH_USDC_MULTIPLIER,
});

export const fetchTranslations = (): IDispatchAxiosAction<Pick<ITranslationReducer, 'messages'>> => ({
  payload: getTranslation(),
  type: FETCH_TRANSLATIONS,
});

export const setNewNftsAction = (nftsCount: number): IDispatchAction => ({
  payload: nftsCount,
  type: SET_NEW_NFTS,
});
