export const MAP_CONSTANT = {
  color: 'rgba(193, 36, 59, 1)',
  loaderModelConfig: {
    radius: 3,
    widthSegments: 64,
    heightSegments: 32,
  },
  venueModelConfiguration: {
    scaleMultiplier: 1,
    altitude: 0,
    rotationMultiplier: 0.0174532925,
  },
  venuesCategory: {
    city: 'city',
    country: 'country',
    building: 'building',
    ocean: 'ocean',
    continent: 'continent',
    sea: 'sea'
  },
};
