import axios, { AxiosResponse } from 'axios';

import { ApiStatusCode } from 'enums/api-status-code.enum';

const PROXY_BASE_URL = '/express-proxy/insight';
const allowedErrors = [ApiStatusCode.errorNotFound, ApiStatusCode.errorUnprocessableEntity];

export const fetchNftExpectedAnnualYield = (nftId: string): Promise<AxiosResponse<any> | any> => {
  return axios
    .get(`${PROXY_BASE_URL}/nft-annual-yield`, {
      params: {
        nftId,
      },
    })
    .then(({ data }) => data)
    .catch((error) => {
      if (error?.message === 'canceled') {
        return null;
      }

      if (allowedErrors.includes(error.response?.status)) {
        return null;
      }

      throw new Error(error);
    });
};
