import React, { useRef } from 'react';

type SectionRefs = {
  [key: string]: React.MutableRefObject<HTMLElement | null>;
};

export const useSectionRefs = () => {
  const rmvRef = useRef<HTMLElement | HTMLDivElement | null>(null);
  const realityNFTRef = useRef<HTMLElement | HTMLDivElement | null>(null);
  const gamesRef = useRef<HTMLElement | HTMLDivElement | null>(null);
  const railwayRef = useRef<HTMLElement | HTMLDivElement | null>(null);
  const partnersRef = useRef<HTMLElement | HTMLDivElement | null>(null);
  const whitepaperRef = useRef<HTMLElement | HTMLDivElement | null>(null);
  const buyRMV = useRef<HTMLElement | HTMLDivElement | null>(null);

  const sectionRefs: SectionRefs = {
    rmv: rmvRef,
    realityNFT: realityNFTRef,
    games: gamesRef,
    railway: railwayRef,
    partners: partnersRef,
    whitepaper: whitepaperRef,
    buyRMV: buyRMV,
  };

  return sectionRefs;
};
