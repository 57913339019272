import ModalPortal from '../ModalPortal';
import ProgressModalStep from './progressModalStep/ProgressModalStep';

import './ProgressModal.scss';

interface IProgressModal {
  onClose: () => void;
  children: JSX.Element;
  stepsCount: number;
  currentStep: number;
  title?: string;
  className?: string;
}

const ProgressModal = ({ stepsCount, currentStep, onClose, children, title, className }: IProgressModal) => {
  const steps = Array.from(Array(stepsCount).keys());

  return (
    <ModalPortal className={className} title={title || 'Progress'} onClose={() => onClose()}>
      <>
        <div className="modal-progress-header">
          {steps.map((step) => (
            <ProgressModalStep key={step} activeStep={currentStep} step={step} />
          ))}
        </div>
        <div className="modal-progress-content">{children}</div>
      </>
    </ModalPortal>
  );
};
export default ProgressModal;
