import LandingGame1 from 'assets/landing/games/1.png';
import LandingGame2 from 'assets/landing/games/2.png';
import LandingGame3 from 'assets/landing/games/3.png';
import LandingGame4 from 'assets/landing/games/4.png';
import LandingGame5 from 'assets/landing/games/5.png';

export interface ILandingGame {
  title: string;
  description: string;
  image: string;
}

export const LANDING_GAMES: ILandingGame[] = [
  {
    title: 'Landlord GO!',
    description:
      "Landlord GO is the first geo-location game that joined Reality Metaverse ecosystem! Millions of players can't be wrong and that's how many people play to become the richest landlord in the world!",
    image: LandingGame1,
  },
  {
    title: 'Donut Trumpet Tycoon',
    description:
      "He is back and he will make a lot of noise again, but don't let it stop you from building your Empire by buying reali-life properties from around the world!",
    image: LandingGame2,
  },
  {
    title: 'Weather Challenge',
    description:
      "Weather Challenge is the world's first weather app with a play button, allowing users to be weather forecasters and earn points for accurate predictions.",
    image: LandingGame3,
  },
  {
    title: 'Food Truck Tycoon',
    description: 'A geo-location game where you build and manage your fleet of food trucks!',
    image: LandingGame4,
  },
  {
    title: 'Speculate',
    description:
      "Imagine stock market where you can buy Streamer's or game's shares with RMV and their performance results will reflect their  popularity on Twitch!",
    image: LandingGame5,
  },
];
