import { IWalletCurrency } from '../wallet-currency-response.interface';

export enum ListingType {
  Contract,
  MarketplaceV3,
}

export interface ILootboxPurchaseConfig {
  listingId: string;
  assetId: string;
  openingAnimation: string;
  name: string;
  price: string;
  quantity: number;
  currency?: Omit<IWalletCurrency, 'balance'>;
  type: ListingType;
}
