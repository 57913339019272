export const SET_ERROR_MESSAGES = 'SET_ERROR_MESSAGES';
export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';
export const REMOVE_ERROR_MESSAGE = 'REMOVE_ERROR_MESSAGE';
export const CLEAR_ERROR_MESSAGES = 'CLEAR_ERROR_MESSAGES';

export const SET_SUCCESS_MESSAGES = 'SET_SUCCESS_MESSAGES';
export const ADD_SUCCESS_MESSAGE = 'ADD_SUCCESS_MESSAGE';
export const REMOVE_SUCCESS_MESSAGE = 'REMOVE_SUCCESS_MESSAGE';
export const CLEAR_SUCCESS_MESSAGES = 'CLEAR_SUCCESS_MESSAGES';

export const SET_WARNING_MESSAGES = 'SET_WARNING_MESSAGES';
export const ADD_WARNING_MESSAGE = 'ADD_WARNING_MESSAGE';
export const REMOVE_WARNING_MESSAGE = 'REMOVE_WARNING_MESSAGE';
export const CLEAR_WARNING_MESSAGES = 'CLEAR_WARNING_MESSAGES';

export const SET_INFO_MESSAGES = 'SET_INFO_MESSAGES';
export const ADD_INFO_MESSAGE = 'ADD_INFO_MESSAGE';
export const REMOVE_INFO_MESSAGE = 'REMOVE_INFO_MESSAGE';
export const CLEAR_INFO_MESSAGES = 'CLEAR_INFO_MESSAGES';

export const SET_SHOW_SELECT_WALLET_MODAL = 'SET_SHOW_SELECT_WALLET_MODAL';

export const SET_SHOW_CURRENCY_LOTTERY_MODAL = 'SET_SHOW_CURRENCY_LOTTERY_MODAL';

export const SET_SHOW_CURRENCY_PURCHASE_MODAL = 'SET_SHOW_CURRENCY_PURCHASE_MODAL';

export const SET_LOADING = 'SET_LOADING';

export const SET_SHOW_SWITCH_NETWORK_MODAL = 'SET_SHOW_SWITCH_NETWORK_MODAL';
