import styles from './BadgeRound.module.scss';

interface IBadgeRoundProps {
  value: number | string;
}

const BadgeRound = ({ value }: IBadgeRoundProps) => {
  return <div className={styles['badge-round']}>{value}</div>;
};

export default BadgeRound;
