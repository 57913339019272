interface IButtonDefault {
  text: string;
  type?: 'submit' | 'button' | 'reset';
  onClick: () => void;
  disabled?: boolean;
  classNames?: string[];
}

const ButtonDefault = ({ text, onClick, disabled = false, classNames = [], type }: IButtonDefault) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`component button button-default ${classNames.join(' ')}`}
    >
      <p className={'button-default__text'}>{text}</p>
    </button>
  );
};

export default ButtonDefault;
