export const SET_CURRENT_COORDS = 'SET_CURRENT_COORDS';
export const SEARCH_BUILDING = 'SEARCH_BUILDING';
export const FILTER_GET = 'FILTER_GET';
export const FILTER_CHANGE = 'FILTER_CHANGE';
export const TOGGLE_BUILDING_DETAILS = 'TOGGLE_BUILDING_DETAILS';
export const SET_SEARCH_VISIBILITY = 'SET_SEARCH_VISIBILITY';
export const FETCH_VENUES = 'FETCH_VENUES';
export const SELECT_VENUE = 'SELECT_VENUE';
export const CLEAR_VENUE = 'CLEAR_VENUE';
export const SELECT_VENUE_SHARE_AMOUNT = 'SELECT_VENUE_SHARE_AMOUNT';
export const TOGGLE_MAP_ZOOM = 'TOGGLE_MAP_ZOOM';
export const MAP_ROTATE_START = 'MAP_ROTATE_START';
export const MAP_ROTATE_STOP = 'MAP_ROTATE_STOP';
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_COUNTRIES_COUNT = 'FETCH_COUNTRIES_COUNT';
export const FETCH_CITIES = 'FETCH_CITIES';
export const FETCH_CITIES_COUNT = 'FETCH_CITIES_COUNT';
export const SET_CURRENT_DISPLAYED_VENUE = 'SET_CURRENT_DISPLAYED_VENUE';
export const APPEND_VENUES = 'APPEND_VENUES';
export const FETCH_VENUE_BY_ID = 'FETCH_VENUE_BY_ID';
export const WALLET_SIGN_OUT = 'WALLET_SIGN_OUT';
export const RESET_ASSETS = 'RESET_ASSETS';
export const RESET_OFFERS = 'RESET_OFFERS';
export const RESET_LISTINGS = 'RESET_LISTINGS';
export const SET_WALLET_ADDRESS = 'SET_WALLET_ADDRESS';
export const SET_WALLET_ASSETS = 'SET_WALLET_ASSETS';
export const SET_WALLET_OFFERS = 'SET_WALLET_OFFERS';
export const CANCEL_BID = 'CANCEL_BID';
export const REMOVE_WALLET_LISTING = 'REMOVE_WALLET_LISTING';
export const SET_WALLET_LISTINGS = 'SET_WALLET_LISTINGS';

export const FETCH_WALLET_CURRENCIES = 'FETCH_WALLET_CURRENCIES';
export const FETCH_WALLET_CURRENCIES_WITH_BALANCE = 'FETCH_WALLET_CURRENCIES_WITH_BALANCE';
export const SET_PROFILE_ASSETS_FILTERS = 'SET_PROFILE_ASSETS_FILTERS';
export const FETCH_VENUE_TRADE_DETAILS = 'FETCH_VENUE_TRADE_DETAILS';
export const FETCH_ASSET_PREVIEW_DETAILS = 'FETCH_ASSET_PREVIEW_DETAILS';
export const RESET_VENUE_OFFER_DETAILS = 'RESET_VENUE_OFFER_DETAILS';
export const RESET_VENUE_ASSET_DETAILS = 'RESET_VENUE_ASSET_DETAILS';
export const FETCH_RMV_MULTIPLIER = 'FETCH_RMV_MULTIPLIER';
export const FETCH_USDC_MULTIPLIER = 'FETCH_USDC_MULTIPLIER';
export const FETCH_TRANSLATIONS = 'FETCH_TRANSLATIONS';
export const GET_FILTERS = 'GET_FILTERS';
export const SET_NEW_NFTS = 'SET_NEW_NFTS';
export const FETCH_AVAILABLE_RENDER_NFTS = 'FETCH_AVAILABLE_RENDER_NFTS';
