import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { DEFAULTS } from 'constants/defaults.constant';
import { ROUTES } from 'constants/routes.constant';
import { RAILWAY_URL } from 'constants/urls';

import RealityGamesLogo from 'assets/logos/reality-metaverse-logo.png';

import SocialsBar from '../socials/SocialsBar';
import styles from './LandingFooter.module.scss';

interface IFooter {
  className?: string;
}

const LandingFooter = ({ className }: IFooter) => {
  const { t } = useTranslation();

  return (
    <footer className={[styles['footer'], className].join(' ')}>
      <div className={styles['footer__container']}>
        <div className={styles['footer__content']}>
          <div className={styles['footer__top']}>
            <Link to="/" aria-label="Landing page">
              <span className="avatar">
                <img src={RealityGamesLogo} alt="Reality Metaverse" />
              </span>
            </Link>
            <SocialsBar
              order={[
                { type: 'medium' },
                { type: 'discord' },
                { type: 'twitter' },
                { type: 'telegramChat' },
                { type: 'telegram' },
                { type: 'polygonscan' },
                { type: 'coinmarketcap' },
              ]}
              className={styles['nav-bar_social__list']}
              iconClassName={styles['nav-bar__social___icon']}
            />
          </div>
          <div className={styles['footer__bottom']}>
            <p className={styles['footer__copyright']}>{t('copyright')}</p>
            <nav>
              <ul className={styles['nav-list']}>
                <li className={styles['nav-list__item']}>
                  <Link to={RAILWAY_URL} target="_blank">
                    RAILWAY 3.0
                  </Link>
                </li>
                <li className={styles['nav-list__item']}>
                  <Link to={ROUTES.CONTACT} target="_blank">
                    Contact
                  </Link>
                </li>
                <li className={styles['nav-list__item']}>
                  <Link to={ROUTES.MEDIA} target="_blank">
                    Media
                  </Link>
                </li>
                <li className={styles['nav-list__item']}>
                  <Link to={ROUTES.REFERRALS} target="_blank">
                    Refferals
                  </Link>
                </li>
                <li className={styles['nav-list__item']}>
                  <Link to={DEFAULTS.privacyPolicyLink} target="_blank">
                    Privacy Policy
                  </Link>
                </li>
                <li className={styles['nav-list__item']}>
                  <Link to={DEFAULTS.termsOfServiceLink} target="_blank">
                    Terms of Service
                  </Link>
                </li>
                <li className={styles['nav-list__item']}>
                  <Link to={DEFAULTS.licenseLink} target="_blank">
                    License
                  </Link>
                </li>
                <li className={styles['nav-list__item']}>
                  <a href={DEFAULTS.faqUrl} target="_blank" rel="noreferrer">
                    FAQ
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default LandingFooter;
