import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterChangeAction, searchBuildingAction } from '../../actions/search.action';
import { ISearchFilters } from '../../models/reducers/i-search-reducer.interface';
import { IState } from '../../models/reducers/i-state.interface';
import { ISelectOption } from '../../models/select/i-select-option.interface';
import SingleSelect from '../singleSelect/SingleSelect';
import SearchBar from './SearchBar';
import { fetchFilters } from '../../actions/filters.action';

const TopNavWrapper = () => {
  const dispatch = useDispatch();
  const searchReduxState = useSelector((state: IState) => state.search);
  const filters = useSelector((state: IState) => state.filters);
  const fixedSort = filters.sort?.map((sort) => ({
    value: sort.label,
    label: sort.value,
  }));

  const [sortBy, setSortBy] = useState<ISelectOption | undefined>(searchReduxState.sortBy);
  const [category, setCategory] = useState<ISelectOption | undefined>(searchReduxState.category);
  const [country, setCountry] = useState<ISelectOption | undefined>(searchReduxState.country);

  useEffect(() => {
    dispatch(fetchFilters());
  }, [dispatch]);

  const getFilterValue = (): ISearchFilters => {
    return {
      sortBy,
      category,
      country,
    };
  };

  const changeSortBy = (option: ISelectOption): void => {
    const searchedParam = option === undefined ? { label: 'Sort by:', value: '' } : option;

    setSortBy(option);
    dispatch(
      filterChangeAction({
        ...getFilterValue(),
        sortBy: searchedParam,
      })
    );
  };

  const changeCategory = (option: ISelectOption): void => {
    const searchedParam = option === undefined ? { label: 'Category:', value: '' } : option;

    setCategory(option);
    dispatch(
      filterChangeAction({
        ...getFilterValue(),
        category: searchedParam,
      })
    );
  };

  const changeCountry = (option: ISelectOption): void => {
    const searchedParam = option === undefined ? { label: 'Country:', value: '' } : option;

    setCountry(option);
    dispatch(
      filterChangeAction({
        ...getFilterValue(),
        country: searchedParam,
      })
    );
  };

  return (
    <nav className="map-filters">
      <SearchBar onChange={(event) => dispatch(searchBuildingAction(event.target.value))} />
      <div className="map-filters__filter-row">
        <SingleSelect
          ariaLabel="Sort by"
          placeholder="Sort by:"
          value={sortBy}
          clearable={true}
          options={fixedSort || []}
          onChange={(option) => changeSortBy(option!)}
        />
        <SingleSelect
          ariaLabel="Category"
          placeholder="Category:"
          value={category}
          clearable={true}
          options={filters.category || []}
          onChange={(option) => changeCategory(option!)}
        />
        <SingleSelect
          ariaLabel="Country"
          placeholder="Country:"
          value={country}
          clearable={true}
          options={filters.country || []}
          onChange={(option) => changeCountry(option!)}
        />
      </div>
    </nav>
  );
};

export default TopNavWrapper;
