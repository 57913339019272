export const mapCounterScale = (width: number): number => {
  const defaultCardWidth = 480;
  const maxXRatio = 0.25;
  const scale = (width * maxXRatio) / defaultCardWidth;

  if (scale > 1) {
    return 1;
  }

  return scale;
};
