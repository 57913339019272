import axios, { AxiosResponse } from 'axios';
import Cookies from 'universal-cookie';

import { IWheelOfFortuneDataResponse, IWheelOfFortuneSpinResponse } from 'models/wheel-of-fortune/i-wheel-of-fortune';

import { CookiesKeys } from 'enums/cookies-keys.enum';

export const getWheelOfFortuneDataService = (
  signal: AbortSignal
): Promise<AxiosResponse<IWheelOfFortuneDataResponse>> => {
  return axios.get(`/api/v1/lottery/wheel-of-fortune/`, {
    signal,
    headers: {
      'X-CSRFToken': new Cookies().get(CookiesKeys.csrftoken),
    },
  });
};

export const getWheelOfFortuneResultsService = (
  signal: AbortSignal
): Promise<AxiosResponse<IWheelOfFortuneSpinResponse[]>> => {
  return axios.get(`/api/v1/lottery/wheel-of-fortune/results/`, {
    signal,
    headers: {
      'X-CSRFToken': new Cookies().get(CookiesKeys.csrftoken),
    },
  });
};

export const makeWheelOfFortuneSpinService = (
  next_spin_number: number,
  signal: AbortSignal
): Promise<AxiosResponse<IWheelOfFortuneSpinResponse>> => {
  return axios.post(`/api/v1/lottery/wheel-of-fortune/spin/`, next_spin_number, {
    signal,
    headers: {
      'X-CSRFToken': new Cookies().get(CookiesKeys.csrftoken),
    },
  });
};
