import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFilters } from '../actions/filters.action';
import { fetchProfile } from '../actions/profile.action';
import { setWalletAddress } from '../actions/wallet.action';
import { CookiesKeys } from '../enums/cookies-keys.enum';
import { IState } from '../models/reducers/i-state.interface';
import Web3Singleton from '../web3Singleton';
import { useCookies } from './useCookies';
import { useIsWeb3Allowed } from './useIsWeb3Allowed';
import { useSignout } from './useSignout';

export const useAccountChange = (): void => {
  const lgoWeb3 = Web3Singleton.getInstance();
  const web3 = lgoWeb3.getWeb3();
  const isWeb3Allowed = useIsWeb3Allowed();
  const walletAddress = useSelector(({ wallet }: IState) => wallet.address);
  const dispatch = useDispatch();
  const signOut = useSignout();
  const { getCookie } = useCookies();

  useEffect(() => {
    if (!isWeb3Allowed) {
      return;
    }

    const handleAccountChange = async (newWalletAddress: string[]): Promise<void> => {
      if (walletAddress !== newWalletAddress[0]) {
        await signOut();

        return;
      }

      dispatch(setWalletAddress(walletAddress[0]));
    };

    lgoWeb3.onAccountChange(handleAccountChange);

    return () => {
      if (!web3) {
        return;
      }

      lgoWeb3.removeOnAccountChange(handleAccountChange);
    };
  }, [dispatch, isWeb3Allowed, web3, lgoWeb3]);

  useEffect(() => {
    if (!isWeb3Allowed || !walletAddress) {
      return;
    }

    dispatch(fetchFilters());

    const token = getCookie(CookiesKeys.csrftoken);

    if (!token) {
      return;
    }

    dispatch(fetchProfile());
  }, [dispatch, isWeb3Allowed, walletAddress]);
};
