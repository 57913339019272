import { StorePlatform } from 'enums/store-platform.enum';

import {
  FLEXIBLE_STAKING_BENEFITS,
  FLEXIBLE_STAKING_REWARDS,
  IGame,
  NEGATIVE_STAKING_AIRDROPS,
  NEGATIVE_STAKING_BENEFITS,
  NEGATIVE_STAKING_EXAMPLE,
  NEGATIVE_STAKING_INFORMATION,
  NEGATIVE_STAKING_REWARDS,
  STAKING_BENEFITS,
  X_GAME_FEATURES,
  X_GAME_STAKING_BENEFITS,
  X_GAME_STAKING_REWARDS,
} from '../constants/games.constants';

import DownloadApp from 'components/downloadApp/DownloadApp';

import { useLandlordGoEvents } from 'hooks/events/useLandlordGoEvents';

import { ReactComponent as TickSvg } from 'assets/tick-to-fill.svg';

import { GameLinkType } from '../gameLink/GameLink';

interface IGameDetailsProps {
  game: IGame;
}

const GameDetails = ({ game }: IGameDetailsProps) => {
  const appleStoreLink = game?.links.find((link) => link.type === GameLinkType.AppleStore);
  const googlePlayLink = game?.links.find((link) => link.type === GameLinkType.GooglePlay);

  const gameHasDownloadLinks = appleStoreLink || googlePlayLink;

  const { sendLandLordGoClickedEvent } = useLandlordGoEvents();

  return (
    <>
      {game.description && (
        <div className={`tw-mb-${gameHasDownloadLinks ? '5' : '10'}`}>
          <h2 className="tw-mb-5 tw-text-[20px]/[22px] tw-uppercase tw-text-turquoise lg:tw-text-2xl">Description</h2>
          <div className="tw-whitespace-pre-wrap tw-text-[18px]/[24px] tw-text-white lg:tw-text-xl">
            {game.flexible ? (
              game.poolId === 0 ? (
                <>
                  {game.description}
                  <br />
                  <br />
                  <span className="tw-font-bold">The Ultimate Gangster Experience</span>
                  <br />
                  <br />
                  Imagine STREETVERS as the FIFA of gangster-themed games. This thrilling TopDown game combines intense
                  match dynamics, strategic gameplay, and the excitement of building your dream team. Just like FIFA,
                  you can collect and embody your favorite characters, with multiple game modes to keep the adrenaline
                  pumping. Aim to achieve the same level of excitement and popularity as the world's most beloved sports
                  games.
                  <br />
                  <br />
                  <span className="tw-font-bold">Economic Dominance</span>
                  <br />
                  <br />
                  Your main objective in STREETVERS is economic success. Maximize your profits by efficiently managing
                  the buying and selling process. The core goal is to generate the highest possible revenue from selling
                  weed. Control territories, outsmart your rivals, and build a fortune. The player or team with the
                  highest profit at the end of the match wins!
                  <br />
                  <br />
                  <span className="tw-font-bold">Game Objectives</span>
                  <ul>
                    <li>
                      <span className="tw-font-bold">Gangster:</span> Expand your territory to reach more customers and
                      sell as much weed as possible. Control neighborhoods, defend your turf, and make strategic
                      decisions to outmaneuver rivals.
                    </li>
                    <li>
                      <span className="tw-font-bold">Police:</span> Manage your heat level to avoid drawing police
                      attention and derailing your operations. Choose evasion over confrontation to minimize risks.
                    </li>
                    <li>
                      <span className="tw-font-bold">Civilian:</span> Trade smart and be aware of the growing police
                      presence. Adapt to unexpected events that can change sales dynamics.
                    </li>
                  </ul>
                  <span className="tw-font-bold">Game Mechanics</span>
                  <ul>
                    <li>
                      <span className="tw-font-bold">Buy and Sell:</span> Purchase supplies, sell weed, and manage your
                      inventory for maximum profit.
                    </li>
                    <li>
                      <span className="tw-font-bold">Territory Expansion and Defense:</span> Expand your empire by
                      taking over new neighborhoods and defending your turf from rivals.
                    </li>
                    <li>
                      <span className="tw-font-bold">Gang Wars:</span> Engage in strategic battles with other gangs to
                      assert dominance.
                    </li>
                    <li>
                      <span className="tw-font-bold">Police Encounters:</span> Navigate interactions with law
                      enforcement carefully to keep your operations running smoothly.
                    </li>
                  </ul>
                  STREETVERS offers a captivating blend of strategy, action, and economic management, creating a unique
                  gaming experience that lets you live out your wildest gangster dreams. Get ready to build your empire
                  and dominate the streets like never before!
                  <br />
                  <br />
                  <h2 className="tw-mb-5 tw-text-[20px]/[22px] tw-uppercase tw-text-turquoise lg:tw-text-2xl">
                    $RMV Token and Reality Digital Assets
                  </h2>
                  <span className="tw-font-bold">$RMV Token: Unlock Premium Experiences</span>
                  <br />
                  <br />
                  The $RMV token serves as the premium hard-currency in STREETVERS, offering players exclusive
                  opportunities to enhance their gameplay. Use $RMV to acquire your ultimate team, embody your favorite
                  characters, and unlock unique skins. Elevate your in-game capabilities and stand out in the
                  competitive world of STREETVERS with the power of $RMV.
                  <br />
                  <br />
                  <span className="tw-font-bold">Reality Digital Assets: Enhance Your Gameplay</span>
                  <br />
                  <br />
                  Reality Metaverse's digital assets play a crucial role in STREETVERS. Each match randomly selects an
                  asset to share in the winnings, providing rewards either in $StreetCoin or $RMV. Holders of these
                  digital assets receive a share of the profits, with higher-tier assets having a greater chance of
                  appearing at the end of matches. Additionally, the most famous 3D assets will be featured within the
                  game maps, adding a layer of immersion and excitement.
                  <br />
                  <br />
                  <span className="tw-font-bold">$StreetCoin and NFTs: Unique In-Game Economy</span>
                  <br />
                  <br />
                  STREETVERS holds the exclusive rights to create $StreetCoin and NFTs for iconic characters, weapons,
                  and accessories. These elements are integral to the game, allowing players to trade, collect, and
                  enhance their in-game experience. Manage territories, strategize your operations, and leverage these
                  digital assets to build your empire.
                  <br />
                  <br />
                  With the integration of $RMV tokens and Reality Digital Assets, STREETVERS offers a dynamic and
                  rewarding gaming experience, blending strategic economic management with immersive gameplay elements.
                  Dive into the world of STREETVERS and make your mark with premium assets and exclusive rewards.
                </>
              ) : (
                <>
                  Welcome to Reality Rush!
                  <br />
                  <br />
                  You are now the mayor of your own city. Start to play and claim your first building. Your city is now
                  earning RP for you. Start tapping the screen, collect points, and maximize your revenue by investing
                  in city services.
                  <br />
                  <br />
                  Develop your strategy and expand your city. Your efforts will be rewarded with real prizes like a PS5,
                  $500, NFTs, and a massive token airdrop. Don't forget about your friends — bring them to the game and
                  earn even more together!
                  <br />
                  <br />
                  <span className="tw-font-bold">Play now:</span>{' '}
                  <a
                    className="tw-text-white tw-underline"
                    href="https://t.me/realityrush_bot"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://t.me/realityrush_bot
                  </a>
                </>
              )
            ) : game.negative ? (
              <>
                <span className="tw-font-bold">Donut Trumpet Tycoon</span> is an innovative geo-location based property
                game that allows you to purchase real-world venues you visit and earn rent from them as people check in.
                The game mirrors the limitless possibilities of the real world, offering an expansive and immersive
                experience.
                <br />
                <br />
                To excel in <span className="tw-font-bold">Donut Trumpet Tycoon</span>, strategic thinking is key. Rent
                income alone may not suffice; achieving success akin to Donut Trumpet requires astute buying and selling
                of properties based on sound business strategies or intuitive decisions. Master these skills, and you
                could become the ultimate king of Trumpet games!
                <br />
                <br />
                The game integrates <span className="tw-font-bold">Reality NFT 3D models</span>, providing royalty
                opportunities for owners. Players can engage in two modes:
                <ol>
                  <li className="tw-mb-2">
                    <span className="tw-font-bold">Free to Play</span>: Test your business acumen and build your empire
                    from scratch.
                  </li>
                  <li>
                    <span className="tw-font-bold">Premium Version</span>: Utilize $Trumpet Coin as premium currency to
                    accelerate progress and unlock exclusive skins. Plus, you can exchange $Trumpet tokens for $RMV
                    tokens!
                  </li>
                </ol>
                Embark on your journey to become the tycoon of the virtual property world with{' '}
                <span className="tw-font-bold">Donut Trumpet Tycoon</span>!
              </>
            ) : (
              game.description
            )}
          </div>
        </div>
      )}
      {gameHasDownloadLinks && (
        <div className="tw-mb-10 tw-flex tw-h-12 tw-gap-3.5">
          {appleStoreLink && (
            <DownloadApp
              platform={StorePlatform.appleStore}
              customStoreUrl={appleStoreLink.href}
              onClick={sendLandLordGoClickedEvent}
            />
          )}
          {googlePlayLink && (
            <DownloadApp
              platform={StorePlatform.googlePlatform}
              customStoreUrl={googlePlayLink.href}
              onClick={sendLandLordGoClickedEvent}
            />
          )}
        </div>
      )}
      {game.flexible && game.poolId === 1 && (
        <div>
          <h2 className="tw-mb-5 tw-text-[20px]/[22px] tw-uppercase tw-text-turquoise lg:tw-text-2xl">Game features</h2>
          <div className="tw-text-lg tw-text-white lg:tw-text-xl">
            <ul className="tw-list-none tw-pl-0">
              {X_GAME_FEATURES.map(({ title, description }, idx) => (
                <li key={idx} className="tw-pb-2 tw-pl-8 tw-font-bold">
                  <TickSvg className="-tw-ml-8 tw-mr-2.5 tw-fill-green" />
                  {title}:
                  <br />
                  <span className="tw-font-normal">{description}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <div>
        <h2 className="tw-mb-5 tw-text-[20px]/[22px] tw-uppercase tw-text-turquoise lg:tw-text-2xl">
          Staking benefits
        </h2>
        {game.flexible ? (
          game.poolId === 0 ? (
            <div className="tw-text-lg tw-text-white lg:tw-text-xl">
              <p>Stake in STREETVERS to unlock exclusive rewards:</p>
              <ul className="tw-list-none tw-pl-0">
                {FLEXIBLE_STAKING_BENEFITS.map(({ title, description }, idx) => (
                  <li key={idx} className="tw-pb-2 tw-pl-8 tw-font-bold">
                    <TickSvg className="-tw-ml-8 tw-mr-2.5 tw-fill-green" />
                    {title}:
                    <br />
                    <span className="tw-font-normal">{description}</span>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="tw-text-lg tw-text-white lg:tw-text-xl">
              <p>Stake your RMV tokens to unlock a range of exclusive rewards and gain a head start in the game:</p>
              <ul className="tw-list-none tw-pl-0">
                {X_GAME_STAKING_BENEFITS.map(({ title, description }, idx) => (
                  <li key={idx} className="tw-pb-2 tw-pl-8 tw-font-bold">
                    <TickSvg className="-tw-ml-8 tw-mr-2.5 tw-fill-green" />
                    {title}:
                    <br />
                    <span className="tw-font-normal">{description}</span>
                  </li>
                ))}
              </ul>
            </div>
          )
        ) : game.negative ? (
          <div className="tw-text-lg tw-text-white lg:tw-text-xl">
            <p>
              By staking in Donut Trumpet Tycoon, you unlock a plethora of exclusive benefits designed to enhance your
              gaming experience and provide valuable rewards:
            </p>
            <ul className="tw-list-none tw-pl-0">
              {NEGATIVE_STAKING_BENEFITS.map(({ title, description }, idx) => (
                <li key={idx} className="tw-pb-2 tw-pl-8 tw-font-bold">
                  <TickSvg className="-tw-ml-8 tw-mr-2.5 tw-fill-green" />
                  {title}:
                  <br />
                  <span className="tw-font-normal">{description}</span>
                </li>
              ))}
            </ul>
            <p>Stake your assets and start reaping these benefits today in Donut Trumpet Tycoon!</p>
          </div>
        ) : (
          <ul className="tw-list-none tw-pl-0">
            {STAKING_BENEFITS.map((item, idx) => (
              <li key={idx}>
                <TickSvg className="tw-mr-2.5 tw-fill-green" />
                <span className="tw-pb-2 tw-text-lg tw-text-white lg:tw-text-xl">{item}</span>
              </li>
            ))}
          </ul>
        )}
        {game.negative && (
          <div className="tw-mt-10 tw-flex tw-flex-col tw-gap-y-5">
            <div>
              <h2 className="tw-mb-5 tw-text-[20px]/[22px] tw-uppercase tw-text-turquoise lg:tw-text-2xl">
                Staking rewards
              </h2>
              <div className="tw-text-lg tw-text-white lg:tw-text-xl">
                <p>
                  By staking in Donut Trumpet Tycoon, you can enjoy significant rewards and benefits. Here's what you
                  will receive:
                </p>
                <ul className="tw-list-none tw-pl-0">
                  {NEGATIVE_STAKING_REWARDS.map(({ title, description }, idx) => (
                    <li key={idx} className="tw-pb-2 tw-pl-8 tw-font-bold">
                      <TickSvg className="-tw-ml-8 tw-mr-2.5 tw-fill-green" />
                      {title}:
                      <br />
                      <span className="tw-font-normal">{description}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="tw-text-lg tw-text-white lg:tw-text-xl">
                <p>
                  Upon completing your staking period, you'll receive airdropped Reality NFTs based on your staked
                  amount:
                </p>
                <ul className="tw-list-none tw-pl-0">
                  {NEGATIVE_STAKING_AIRDROPS.map(({ title, description }, idx) => (
                    <li key={idx} className="tw-pb-2 tw-pl-8 tw-font-bold">
                      <TickSvg className="-tw-ml-8 tw-mr-2.5 tw-fill-green" />
                      {title}:
                      <br />
                      <span className="tw-font-normal">{description}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <h4 className="tw-mb-5 tw-text-[20px]/[22px] tw-uppercase tw-text-turquoise">Example scenario</h4>
              <div className="tw-text-lg tw-text-white lg:tw-text-xl">
                <p>Suppose you stake $RMV 620,000 tokens. You will receive:</p>
                <ul className="tw-list-none tw-pl-0">
                  {NEGATIVE_STAKING_EXAMPLE.map((item, idx) => (
                    <li key={idx} className="tw-pb-2">
                      <TickSvg className="tw-mr-2.5 tw-fill-green" />
                      {item}
                    </li>
                  ))}
                  <p>Stake your tokens and start earning these exciting rewards in Donut Trumpet Tycoon!</p>
                </ul>
              </div>
            </div>
            <div>
              <h2 className="tw-mb-5 tw-text-[20px]/[22px] tw-uppercase tw-text-turquoise lg:tw-text-2xl">
                Additional information
              </h2>
              <div className="tw-text-lg tw-text-white lg:tw-text-xl">
                <p>
                  By staking in Donut Trumpet Tycoon, you unlock a plethora of exclusive benefits designed to enhance
                  your gaming experience and provide valuable rewards:
                </p>
                <ul className="tw-list-none tw-pl-0">
                  {NEGATIVE_STAKING_INFORMATION.map(({ title, description }, idx) => (
                    <li key={idx} className="tw-pb-2 tw-pl-8 tw-font-bold">
                      <TickSvg className="-tw-ml-8 tw-mr-2.5 tw-fill-green" />
                      {title}:
                      <br />
                      <span className="tw-whitespace-break-spaces tw-font-normal">{description}</span>
                    </li>
                  ))}
                </ul>
                <p>Stake your assets and start reaping these benefits today in Donut Trumpet Tycoon!</p>
              </div>
            </div>
          </div>
        )}
        {game.flexible &&
          (game.poolId === 0 ? (
            <div className="tw-mt-10 tw-flex tw-flex-col tw-gap-y-5">
              <h2 className="tw-mb-5 tw-text-[20px]/[22px] tw-uppercase tw-text-turquoise lg:tw-text-2xl">
                Staking rewards
              </h2>
              <div>
                <div className="tw-text-lg tw-text-white lg:tw-text-xl">
                  <p>By staking in STREETVERS, you can earn fantastic rewards each month:</p>
                  <ul className="tw-list-none tw-pl-0">
                    {FLEXIBLE_STAKING_REWARDS.map(({ title, description }, idx) => (
                      <li key={idx} className="tw-pb-2 tw-pl-8 tw-font-bold">
                        <TickSvg className="-tw-ml-8 tw-mr-2.5 tw-fill-green" />
                        {title}:
                        <br />
                        <span className="tw-font-normal">{description}</span>
                      </li>
                    ))}
                  </ul>
                  <p>Maximize your staking to unlock these amazing rewards and enhance your STREETVERS experience!</p>
                </div>
              </div>
              <div>
                <h2 className="tw-mb-5 tw-text-[20px]/[22px] tw-uppercase tw-text-turquoise lg:tw-text-2xl">
                  Additional information
                </h2>
                <div className="tw-text-lg tw-text-white lg:tw-text-xl">
                  <p className="tw-mb-6">
                    StreetVers staking offers <span className="tw-font-bold">flexibility</span>, allowing you to
                    withdraw your $RMV tokens at any time. However, it's important to note that each time you deposit,
                    30% of your staked amount is invested into the game's development. For example, if you deposit $RMV
                    10,000 tokens and withdraw after one month, you will receive $RMV 7,000 tokens back. This flexible
                    system enables you to deposit more or withdraw your tokens as often as you like, adjusting your
                    investment as needed.
                  </p>
                  <p className="tw-mb-6">
                    Staking in StreetVers comes with <span className="tw-font-bold">multiple rewards</span>. You can
                    expect monthly airdrops of Reality digital assets, providing continuous value. These rewards will
                    persist long after the game is developed, ensuring ongoing benefits for stakers. The developer’s
                    roadmap details when different features will be adopted, providing transparency and foresight into
                    the game's evolution.
                  </p>
                  <p className="tw-mb-6">
                    Most importantly,{' '}
                    <span className="tw-font-bold">
                      the game development will start before the staking period is fully complete
                    </span>
                    . Preparations for the Genesis Mint will begin once 50% of the staking target is achieved. At 80%
                    completion, influencers will be onboarded to promote the game, and full-scale marketing efforts will
                    kick off at 100% completion. The detailed development timeline is available in the product
                    presentation, offering a clear view of the project's progress and milestones.
                  </p>
                  <p>
                    With <span className="tw-font-bold">StreetVers staking</span>, you can enjoy the flexibility to
                    manage your investment while benefiting from
                    <span className="tw-font-bold">multiple rewards and supporting the game's development</span>. Start
                    staking today to be part of the exciting future of StreetVers!
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="tw-mt-10 tw-flex tw-flex-col tw-gap-y-5">
              <h2 className="tw-mb-5 tw-text-[20px]/[22px] tw-uppercase tw-text-turquoise lg:tw-text-2xl">
                Example rewards
              </h2>
              <div>
                <div className="tw-text-lg tw-text-white lg:tw-text-xl">
                  <p>
                    Suppose you staked 75,000 RMV. 30% of it goes to game development, and 70% comes back to you. That
                    is, 22,500 RMV is going back to the RM ecosystem, and 52,500 RMV will be returned to you.
                    Additionally, you will receive:
                  </p>
                  <ul className="tw-list-none tw-pl-0">
                    {X_GAME_STAKING_REWARDS.map(({ title, description }, idx) => (
                      <li key={idx} className="tw-pb-2 tw-pl-8 tw-font-bold">
                        <TickSvg className="-tw-ml-8 tw-mr-2.5 tw-fill-green" />
                        {title} <span className="tw-font-normal">{description}</span>
                      </li>
                    ))}
                  </ul>
                  <p>Get ready to stake your RMV tokens and gain access to these exclusive benefits!</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default GameDetails;
