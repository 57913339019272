import { Noop } from 'types/common.interface';

import WithTwClassName from '../../hoc/WithTwClassName';

import styles from './Badge.module.scss';

interface IBadgeProps {
  text: string;
  className?: string;
  onClick?: Noop;
}

const Badge = ({ text, className = '', onClick }: IBadgeProps) => {
  const classNames = [className, styles['badge']];

  return (
    <div className={classNames.join(' ')} onClick={onClick}>
      {text}
    </div>
  );
};

export default WithTwClassName(Badge);
