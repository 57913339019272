import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IState } from 'models/reducers/i-state.interface';

import { CurrencyPurchaseStep } from 'enums/currency-purchase/currency-purchase-step.enum';

import { DEFAULTS } from 'constants/defaults.constant';

import { addSuccessMessageAction, setShowCurrencyPurchaseModal } from 'actions/global-actions';

import { useEnv } from './useEnv';

export const useCurrencyPurchase = () => {
  const dispatch = useDispatch();
  const symbol = useSelector((state: IState) => state.global.currencySymbol);
  const currencies = useSelector((state: IState) => state.wallet.currencies);

  const currency = currencies?.find((currency) => currency.symbol === symbol);

  const { kucoinExchangeCurrency, uniswapOutputCurrency } = useEnv();
  const [purchaseStep, setPurchaseStep] = useState<CurrencyPurchaseStep>(CurrencyPurchaseStep.purchaseMethod);

  const openRamp = () => {
    setPurchaseStep(CurrencyPurchaseStep.rampInProgress);
  };

  const openCEX = () => {
    window.open(DEFAULTS.CEXLink(kucoinExchangeCurrency), '_blank');
    openPurchaseComplete();
  };

  const openDEX = () => {
    window.open(DEFAULTS.DEXLink(currency ? currency.address : uniswapOutputCurrency), '_blank');
    openPurchaseComplete();
  };

  const openPurchaseComplete = () => {
    setPurchaseStep(CurrencyPurchaseStep.purchaseComplete);
  };

  const onPurchaseSuccess = () => {
    dispatch(addSuccessMessageAction('Currency balance updated!'));
    dispatch(setShowCurrencyPurchaseModal({ show: false, currencySymbol: undefined }));
  };

  const closePurchaseProcess = () => {
    dispatch(setShowCurrencyPurchaseModal({ show: false, currencySymbol: undefined }));
  };

  return {
    purchaseStep,
    openCEX,
    openDEX,
    openRamp,
    openPurchaseComplete,
    closePurchaseProcess,
    onPurchaseSuccess,
  };
};
