import { DEFAULTS } from 'constants/defaults.constant';

import { getValue } from './getValue';

interface IValueAndIcon {
  value: string | number;
  icon?: string | JSX.Element;
  radius?: string;
  className?: string;
  valueToFixed?: boolean;
  ariaLive?: boolean;
  valueSize?: number;
  iconSize?: number;
  iconAltText?: string;
  showIfZeroOrEmpty?: boolean;
  precision?: number;
  parser?: (value: number, precision?: number) => string | undefined;
}

const ValueAndIcon = ({
  value,
  icon,
  className = '',
  radius = '0',
  valueToFixed = true,
  ariaLive = false,
  valueSize = 14,
  iconSize = 14,
  iconAltText = '',
  showIfZeroOrEmpty = true,
  precision = DEFAULTS.defaultPricePrecision,
  parser,
}: IValueAndIcon) => {
  const iconElement =
    typeof icon === 'string' ? (
      <img
        style={{ height: iconSize, width: iconSize, borderRadius: radius }}
        className="value_and_icon_icon"
        src={icon}
        alt={iconAltText}
      />
    ) : (
      icon
    );

  return (
    <div className={`text_and_value_wrap ${className}`}>
      {showIfZeroOrEmpty || +value ? (
        <>
          {iconElement}
          <p aria-live={ariaLive ? 'polite' : 'off'} style={{ fontSize: valueSize }} className="value_and_icon_value">
            {getValue(value, valueToFixed, parser, precision)}
          </p>
        </>
      ) : (
        <p aria-live={ariaLive ? 'polite' : 'off'} style={{ fontSize: valueSize }} className="value_and_icon_value">
          -
        </p>
      )}
    </div>
  );
};

export default ValueAndIcon;
