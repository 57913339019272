import { ROUTES } from 'constants/routes.constant';

import { ReactComponent as ProfileActivity } from '../../../../assets/profile/icons/profile-activity.svg';
import { ReactComponent as ProfileColletions } from '../../../../assets/profile/icons/profile-collections.svg';
import { ReactComponent as ProfileMarket } from '../../../../assets/profile/icons/profile-market.svg';
import { ReactComponent as ProfileOffers } from '../../../../assets/profile/icons/profile-offers.svg';

import MenuAccountDropdownProfileLink from '../menuAccountDropdownProfileLink/MenuAccountDropdownProfileLink';
import styles from './MenuAccountDropdownProfileSection.module.scss';

const MenuAccountDropdownProfileSection = () => {
  return (
    <div className={styles['profile-section']}>
      <ul className={styles['profile-section__list']}>
        <li>
          <MenuAccountDropdownProfileLink to={`${ROUTES.PROFILE}/collected`}>
            <>
              <ProfileColletions className={styles['profile-section__link-icon']} />
              <p>Collected</p>
            </>
          </MenuAccountDropdownProfileLink>
        </li>
        <li>
          <MenuAccountDropdownProfileLink disabled={true} to={`${ROUTES.PROFILE}/activity`}>
            <>
              <ProfileActivity className={styles['profile-section__link-icon']} />
              <p>Activity</p>
            </>
          </MenuAccountDropdownProfileLink>
        </li>
        <li>
          <MenuAccountDropdownProfileLink to={`${ROUTES.PROFILE}/offers`}>
            <>
              <ProfileOffers className={styles['profile-section__link-icon']} />
              <p>Offers</p>
            </>
          </MenuAccountDropdownProfileLink>
        </li>
        <li>
          <MenuAccountDropdownProfileLink to={`${ROUTES.PROFILE}/listings`}>
            <>
              <ProfileMarket className={styles['profile-section__link-icon']} />
              <p>Listings</p>
            </>
          </MenuAccountDropdownProfileLink>
        </li>
      </ul>
    </div>
  );
};

export default MenuAccountDropdownProfileSection;
