import { twMerge } from 'tailwind-merge';

import { IWalletCurrency } from 'models/wallet-currency-response.interface';

import { parseThousand } from 'utils/parser.utils';

interface IGameValueProps {
  currency: IWalletCurrency;
  value: string;
  iconClassName?: string;
  className?: string;
}

const GameValue = ({ currency, value, iconClassName, className }: IGameValueProps) => {
  const renderValue = (value: string) => {
    if (!value || parseFloat(value) === 0) return '-';

    return parseThousand(parseFloat(value), 2);
  };

  return (
    <div className="tw-flex tw-items-center tw-gap-1">
      {currency && (
        <img className={twMerge('tw-h-7 tw-w-7 tw-p-px', iconClassName)} src={currency.icon} alt={currency.symbol} />
      )}
      <span className={twMerge('tw-line-clamp-1 tw-text-2xl/[28px] tw-text-white', className)}>
        {renderValue(value)}
      </span>
    </div>
  );
};

export default GameValue;
