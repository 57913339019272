// src/mocks/browser.js
import { setupWorker } from 'msw';

import { handlers } from './mocks/handlers';

export const setupMockServiceWorker = () => {
  const isLocalEnvironment = process.env.REACT_APP_LOCAL_ENVIRONMENT === 'true';
  const isMockEnabled = localStorage.getItem('msw') === 'true';

  if (isLocalEnvironment && isMockEnabled) {
    setupWorker(...handlers).start();
  }
};
