import styles from './GraphPointer.module.scss';

interface IGraphPointerProps {
  direction: 'left' | 'right';
  animate?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const GraphPointer = ({ animate, direction, children, className = '' }: IGraphPointerProps) => {
  const classNames = [
    className,
    styles['graph-pointer'],
    styles[`graph-pointer--${direction}`],
    animate ? styles[`graph-pointer--animate`] : '',
  ];

  return (
    <div className={classNames.join(' ')}>
      <div className={styles['graph-pointer__content']}>{children}</div>
      <div className={styles['graph-pointer__circle']}></div>
    </div>
  );
};

export default GraphPointer;
