import Button from '../../../components/buttons/Button';

import SuccessIcon from '../../../assets/lootbox/purchase-success-icon.svg';

import styles from './LootboxPurchaseSuccess.module.scss';

interface ILootboxPurchaseSuccessProps {
  amount?: number;
  openLootboxes: () => void;
  onContinue: () => void;
}

const LootboxPurchaseSuccess = ({ amount, onContinue, openLootboxes }: ILootboxPurchaseSuccessProps) => {
  return (
    <div className={styles['purchase-view']}>
      <div className={styles['purchase-view__main']}>
        <img src={SuccessIcon} alt="Success" />
        <p className={styles['purchase-view__main-text']}>Thank you</p>
        <p className={styles['purchase-view__transaction']}>
          Transaction successful! {amount} {amount !== undefined && amount > 1 ? 'lootboxes' : 'lootbox'} bought
        </p>
      </div>

      {amount !== undefined && amount > 1 ? (
        <div className={styles['purchase-view__comment']}>
          Opening multiple lootboxes at once may require large amount of gas, which can be expensive during peak hours
          of blockchain utilization. If you see high transaction cost, navigate to Owned Lootboxes section and open
          lootboxes one by one now or wait for lower transaction costs on the blockchain.
        </div>
      ) : (
        <></>
      )}

      <div className={styles['purchase-view__footer']}>
        <Button className={styles['purchase-view__button']} size="medium-large" color="primary" onClick={openLootboxes}>
          Open {amount} {amount !== undefined && amount > 1 ? 'lootboxes' : 'lootbox'}
        </Button>
        <Button className={styles['purchase-view__button']} size="medium-large" color="default" onClick={onContinue}>
          Open later
        </Button>
      </div>
    </div>
  );
};

export default LootboxPurchaseSuccess;
