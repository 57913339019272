import { useEffect, useState } from 'react';

import styles from './TypingAnimation.module.scss';

interface ITypingAnimationProps {
  text?: string;
  color?: 'red' | 'green';
  className?: string;
}

const TypingAnimation = ({ color = 'green', text = '', className = '' }: ITypingAnimationProps) => {
  const [typedText, setTypedText] = useState<string>('');
  const [textIndex, setTextIndex] = useState<number>(0);
  const classNames = [
    className,
    styles[`typing-text--${color}`],
    styles['typing-text'],
    textIndex >= text.length ? styles['typing-text--animated'] : '',
  ];

  useEffect(() => {
    if (textIndex < text.length) {
      setTimeout(() => {
        setTypedText(typedText + text[textIndex]);
        setTextIndex(textIndex + 1);
      }, 40);
    }
  }, [textIndex]);

  useEffect(() => {
    setTextIndex(0);
  }, [text]);

  return <div className={classNames.join(' ')}>{typedText}</div>;
};

export default TypingAnimation;
