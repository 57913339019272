import axios, { AxiosResponse } from 'axios';

import { ILandingAsset } from 'models/landing/i-landing-asset.interface';

import { ASSET_FIELDS, LANDING_FIELDS } from 'constants/params-fields.constant';

export const fetchLandingAssets = (tokenIds: number[] | string[]): Promise<AxiosResponse<ILandingAsset[]>> => {
  return axios.get<ILandingAsset[]>(`/api/v1/assets/all_assets`, {
    params: { token_ids: tokenIds.join(','), fields: [...ASSET_FIELDS, ...LANDING_FIELDS].join(',') },
  });
};
