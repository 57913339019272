import { sendGA4Event } from 'services/analytics-events.service';

import { INftParams, useEventParamsMethods } from './useEventsParams';

enum MapEventName {
  CardDisplayed = 'nft_card_displayed',
  CardBuy = 'buy_button_clicked',
  NotFound = 'content_not_found',
}

export const useMapEvents = () => {
  const { withWalletAddress } = useEventParamsMethods();

  const sendMapCardDisplayedEvent = (params: INftParams) => {
    sendGA4Event(MapEventName.CardDisplayed, withWalletAddress(params));
  };

  const sendMapCardBuyEvent = (params: INftParams) => {
    sendGA4Event(MapEventName.CardBuy, withWalletAddress(params));
  };

  const sendMapNotFoundEvent = (params: INftParams) => {
    sendGA4Event(MapEventName.NotFound, withWalletAddress(params));
  };

  return {
    sendMapCardDisplayedEvent,
    sendMapCardBuyEvent,
    sendMapNotFoundEvent,
  };
};
