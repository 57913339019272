import { useNavigate } from 'react-router-dom';

import { IValidator } from 'models/i-form-validation.interface';

import { DEFAULTS } from 'constants/defaults.constant';

import { useTermsOfServiceConsents } from 'hooks/useTermsOfServiceConsents';

import Button from '../buttons/Button';
import Checkbox from '../checkbox/Checkbox';
import Form from '../form/Form';
import ModalPortal from '../modal/ModalPortal';
import styles from './TermsOfServiceModal.module.scss';

export enum ConsentsState {
  Accepted,
  Opened,
  Rejected,
}

interface ITermsOfServiceModalProps {
  text?: string;
  onChange?: (value: ConsentsState) => void;
}

const TermsOfServiceModal = ({
  text = 'Otherwise the profile page would be not available for you.',
  onChange,
}: ITermsOfServiceModalProps) => {
  const {
    consentsAccepted,
    legalAgeConsentChecked,
    privacyPolicyChecked,
    termsOfServiceChecked,
    newsletterAgreementChecked,
    onAccept,
    setPrivacyPolicyChecked,
    setLegalAgeConsentChecked,
    setTermsOfServiceChecked,
    setNewsletterAgreementChecked,
  } = useTermsOfServiceConsents();
  const navigate = useNavigate();

  const requiredTrueValidator: IValidator<boolean> = (value: boolean) => {
    return value ? { valid: true } : { valid: false, message: `Consent is required` };
  };

  const onConsentsModalClose = () => {
    onChange ? onChange(ConsentsState.Rejected) : navigate('/');
  };

  const handleAccept = () => {
    onChange && onChange(ConsentsState.Accepted);
    onAccept();
  };

  return consentsAccepted ? (
    <></>
  ) : (
    <ModalPortal
      title="Terms of Service"
      className={styles['consents-modal']}
      modalContentClassName={styles['consents-modal-container']}
      onClose={onConsentsModalClose}
    >
      <Form className={styles['consents-form']} onSubmit={handleAccept}>
        <p className={styles['consents-paragraph']}>
          To further continue you need to accept the Terms of Service. {text}
        </p>
        <div className={styles['consents-form__content']}>
          <div className={styles['consents-checkbox']}>
            <Checkbox
              name="age"
              checked={legalAgeConsentChecked}
              onChange={setLegalAgeConsentChecked}
              validations={[
                {
                  key: 'requiredTrueValidator',
                  validator: requiredTrueValidator,
                },
              ]}
            >
              <>I am at least 18 years old and have full legal capacity.</>
            </Checkbox>
          </div>
          <div className={styles['consents-checkbox']}>
            <Checkbox
              name="terms"
              checked={termsOfServiceChecked}
              onChange={setTermsOfServiceChecked}
              validations={[
                {
                  key: 'requiredTrueValidator',
                  validator: requiredTrueValidator,
                },
              ]}
            >
              <>
                <span>I have read the Reality NFT’s </span>
                <a
                  className={styles['consents-link']}
                  href={DEFAULTS.termsOfServiceLink}
                  rel="noreferrer"
                  target="_blank"
                >
                  Terms of Service
                </a>
                <span>, I understand it and agree with its provisions.</span>
              </>
            </Checkbox>
          </div>

          <div className={styles['consents-checkbox']}>
            <Checkbox
              name="privacy"
              checked={privacyPolicyChecked}
              onChange={setPrivacyPolicyChecked}
              validations={[
                {
                  key: 'requiredTrueValidator',
                  validator: requiredTrueValidator,
                },
              ]}
            >
              <>
                <span>I have read the Reality NFT’s </span>
                <a
                  className={styles['consents-link']}
                  href={DEFAULTS.privacyPolicyLink}
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                <span>, I understand it and agree with its provisions.</span>
              </>
            </Checkbox>
          </div>

          <div className={styles['consents-checkbox']}>
            <Checkbox
              name="newsletterAgreement"
              checked={newsletterAgreementChecked}
              onChange={setNewsletterAgreementChecked}
            >
              I agree to receive Newsletter and mailing updates about the NFT marketplace.
            </Checkbox>
          </div>
        </div>
        <Button className={styles['consents-submit']} type="submit" text="i accept" />
      </Form>
    </ModalPortal>
  );
};

export default TermsOfServiceModal;
