import { useEffect, useState } from 'react';
import Slider from 'react-slick';

import { ListingType } from 'models/lootbox/i-lootbox-purchase-config.interface';
import { ILootboxListing } from 'models/lootbox/i-lootbox-trade.interface';

import Badge from 'components/badge/Badge';
import InfoBox from 'components/infoBox/InfoBox';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';

import { useWindowSize } from 'hooks/useWindowSize';

import LootboxCardLarge from '../lootboxCard/lootboxCardLarge/LootboxCardLarge';
import LootboxProbabilityBox from '../lootboxCard/lootboxProbabilityBox/LootboxProbabilityBox';
import LootboxPurchase from '../lootboxCard/lootboxPurchase/LootboxPurchase';
import LootboxSection from '../lootboxSection/LootboxSection';
import styles from './LootboxMarket.module.scss';

interface ILootboxMarketProps {
  className?: string;
  updateToken?: string;
  lootboxesListings?: ILootboxListing[];
  loading: boolean;
  fetchRewardDetails: () => Promise<void>;
}

const lootboxTypes: { name: string; background: string }[] = [
  // {
  //   name: 'Great Voyage',
  //   background: 'https://assets.r5y.io/lgo-live/lootbox/animations/uncommon_closed.mp4',
  // },
];

const LootboxMarket = ({
  className,
  updateToken,
  loading,
  lootboxesListings,
  fetchRewardDetails,
}: ILootboxMarketProps) => {
  const [width] = useWindowSize();
  const [visibleSlides, setVisibleSlides] = useState(3);
  const defaultCardWidth = 384;
  const defaultMaxQuantity = 20;

  const updateOwnedLootboxes = async () => await fetchRewardDetails();

  useEffect(() => {
    updateOwnedLootboxes();
  }, [updateToken]);

  useEffect(() => {
    if (width < defaultCardWidth * 2) {
      setVisibleSlides(1);

      return;
    }

    if (width < defaultCardWidth * 3) {
      setVisibleSlides(2);

      return;
    }

    setVisibleSlides(3);
  }, [width]);

  const existingLootboxes = lootboxesListings?.map(({ name }) => name);
  const lootboxesSoldOut = lootboxTypes
    .filter(({ name }) => !existingLootboxes?.includes(name))
    .map((listing) => ({
      ...listing,
      listingId: '',
      assetId: '',
      openingAnimation: '',
      price: '0',
      priceDisplayValue: '',
      currencySymbol: '',
      quantity: 0,
      isOnSale: false,
      chancesInfo: 1,
      tier1NFTChance: '',
      tier2NFTChance: '',
      tier3NFTChance: '',
      type: ListingType.Contract,
    }));

  const lootboxesAll = [...lootboxesSoldOut, ...(lootboxesListings ?? [])];

  return (
    <LootboxSection className={className}>
      {loading ? (
        <div className={styles['lootbox-market__loading-section']}>
          <LoadingSpinner />
        </div>
      ) : (
        <></>
      )}
      {!loading && lootboxesAll ? (
        <div className={styles['lootbox-market']}>
          <Slider
            dots
            variableWidth
            speed={300}
            rows={1}
            arrows={false}
            infinite={false}
            dotsClass={`slick-dots ${styles['lootbox-market__carousel-dots']}`}
            className={styles['lootbox-market__slider']}
            slidesToScroll={1}
            slidesToShow={visibleSlides}
          >
            {lootboxesAll?.map((listing) => (
              <div
                className={[
                  styles['lootbox-market__card-wrapper'],
                  listing.isOnSale ? '' : styles['lootbox-market__card-wrapper--disabled'],
                ].join(' ')}
                key={`lootbox-${listing.name}`}
              >
                {!listing.isOnSale && (
                  <Badge className={styles['lootbox-market__badge-soldout']} text="loot box sold out" />
                )}
                <LootboxCardLarge
                  autoplay
                  loop
                  title={listing.name}
                  background={listing.background}
                  className={styles['lootbox-market__card']}
                >
                  <div className={styles['lootbox-market__content']}>
                    <div className={styles['lootbox-market__purchase']}>
                      <LootboxPurchase
                        listingId={listing.listingId}
                        assetId={listing.assetId}
                        openingAnimation={listing.openingAnimation}
                        name={listing.name}
                        price={listing.price}
                        priceDisplayValue={listing.priceDisplayValue}
                        currencySymbol={listing.currencySymbol}
                        quantity={listing.quantity}
                        maxQuantity={listing.quantity > defaultMaxQuantity ? defaultMaxQuantity : listing.quantity}
                        isOnSale={listing.isOnSale}
                        type={listing.type}
                      />
                    </div>
                    {listing.chancesInfo && (
                      <InfoBox
                        className={styles['lootbox-market__info']}
                        tooltipContent={
                          <LootboxProbabilityBox
                            tier1NFTChance={listing.tier1NFTChance}
                            tier2NFTChance={listing.tier2NFTChance}
                            tier3NFTChance={listing.tier3NFTChance}
                          />
                        }
                      >
                        Probability Rates
                      </InfoBox>
                    )}
                  </div>
                </LootboxCardLarge>
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <></>
      )}
    </LootboxSection>
  );
};

export default LootboxMarket;
