import { Disclosure } from '@headlessui/react';
import { FC } from 'react';

import { IPropsWithChildrenAndLabel } from 'types/common.interface';

import { ReactComponent as ArrowSvg } from 'assets/chevron.svg';

const NftCardBackCollapse: FC<IPropsWithChildrenAndLabel> = ({ label, children }) => {
  return (
    <Disclosure as="div" className="tw-mb-5 last-of-type:tw-mb-0" defaultOpen>
      {!!label.length && (
        <Disclosure.Button className="button-reset tw-mb-2.5 tw-flex tw-w-full tw-items-center tw-justify-between tw-text-left tw-text-base/[18px] tw-font-semibold tw-uppercase tw-text-white">
          {label}
          <ArrowSvg className="ui-not-open:[transform:rotate(180deg)]" />
        </Disclosure.Button>
      )}
      <Disclosure.Panel className="tw-grid tw-gap-y-1">{children}</Disclosure.Panel>
    </Disclosure>
  );
};

export default NftCardBackCollapse;
