import { sendGA4Event } from 'services/analytics-events.service';

import { IErrorMessageParam, ILoadingTimeParam, useEventParamsMethods } from './useEventsParams';

export interface ILootboxParams {
  lootboxId: string;
  lootboxAmount: number;
}

enum LootboxEventName {
  GetMarketplace = 'get_LOOTBOX_STORE',
  GetOwned = 'get_lootbox_owned',
  Buy = 'buy_lootbox',
  PurchaseInProgress = 'lootbox_purchase_in_progress',
  PurchaseSuccess = 'lootbox_purchase_success',
  PurchaseError = 'lootbox_purchase_error',
  Open = 'open_lootbox',
  OpenSuccess = 'open_lootbox_success',
  OpenError = 'open_lootbox_error',
}

export const useLootboxEvents = () => {
  const { withWalletAddress, withWalletAddressAndReferrer } = useEventParamsMethods();

  const sendLootboxGetMarketplaceEvent = (params: ILoadingTimeParam) => {
    sendGA4Event(LootboxEventName.GetMarketplace, withWalletAddressAndReferrer(params));
  };

  const sendLootboxGetOwnedEvent = (params: ILootboxParams & ILoadingTimeParam) => {
    sendGA4Event(LootboxEventName.GetOwned, withWalletAddress(params));
  };

  const sendLootboxBuyEvent = (params: ILootboxParams) => {
    sendGA4Event(LootboxEventName.Buy, withWalletAddressAndReferrer(params));
  };

  const sendLootboxPurchaseInProgressEvent = (params: ILootboxParams) => {
    sendGA4Event(LootboxEventName.PurchaseInProgress, withWalletAddress(params));
  };

  const sendLootboxPurchaseSuccessEvent = (params: ILootboxParams) => {
    sendGA4Event(LootboxEventName.PurchaseSuccess, withWalletAddress(params));
  };

  const sendLootboxPurchaseErrorEvent = (params: ILootboxParams & IErrorMessageParam) => {
    sendGA4Event(LootboxEventName.PurchaseError, withWalletAddress(params));
  };

  const sendLootboxOpenEvent = (params: ILootboxParams) => {
    sendGA4Event(LootboxEventName.Open, withWalletAddressAndReferrer(params));
  };

  const sendLootboxOpenSuccessEvent = (params: ILootboxParams & { drawnRewards: string[] }) => {
    sendGA4Event(LootboxEventName.OpenSuccess, withWalletAddress(params));
  };

  const sendLootboxOpenErrorEvent = (params: ILootboxParams & IErrorMessageParam) => {
    sendGA4Event(LootboxEventName.OpenError, withWalletAddress(params));
  };

  return {
    sendLootboxGetMarketplaceEvent,
    sendLootboxGetOwnedEvent,
    sendLootboxBuyEvent,
    sendLootboxPurchaseInProgressEvent,
    sendLootboxPurchaseSuccessEvent,
    sendLootboxPurchaseErrorEvent,
    sendLootboxOpenEvent,
    sendLootboxOpenSuccessEvent,
    sendLootboxOpenErrorEvent,
  };
};
