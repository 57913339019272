import { MouseEvent, ReactNode, useEffect, useId } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setVenueVideoActive } from '../../actions/venues-card-video';
import { ReactComponent as PauseButton } from '../../assets/icons/pause-icon.svg';
import { ReactComponent as PlayButton } from '../../assets/icons/play-icon.svg';
import { IState } from '../../models/reducers/i-state.interface';
import styles from './VenueBackground.module.scss';
import VideoBanner from './VideoBanner';

interface IVenueBackgroundProps {
  backgroundImageUrl?: string | null;
  backgroundAnimationUrl?: string | null;
  className?: string;
  objectFit?: 'cover' | 'contain';
  children?: ReactNode;
  onMini?: boolean;
  autoPlay?: boolean;
}

const VenueBackground = ({
  backgroundImageUrl,
  backgroundAnimationUrl,
  children,
  className = '',
  objectFit = 'cover',
  onMini = false,
  autoPlay = false,
}: IVenueBackgroundProps) => {
  const id = useId();
  const dispatch = useDispatch();
  const { activeVideo } = useSelector(({ venuesCardVideo }: IState) => venuesCardVideo);
  const buttonClassNames = [styles['video-button'], onMini ? styles['video-button-mini'] : ''].join(' ').trim();
  const classNames = [
    className,
    styles['venue-background-container'],
    styles[`venue-background-container--${objectFit}`],
  ];
  const isBackgroundVideo = backgroundAnimationUrl?.endsWith('.mp4');
  const backgroundStyle = {
    backgroundImage: `url("${backgroundImageUrl}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
  };

  useEffect(() => {
    if (!autoPlay) {
      return;
    }

    dispatch(setVenueVideoActive(id));
  }, [autoPlay, backgroundAnimationUrl]);

  const playPauseVideo = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (id === activeVideo) {
      dispatch(setVenueVideoActive(''));

      return;
    }

    dispatch(setVenueVideoActive(id));
  };

  return (
    <div style={backgroundStyle} className={classNames.join(' ')}>
      {isBackgroundVideo && backgroundImageUrl && backgroundAnimationUrl && (
        <>
          <VideoBanner id={id} mp4VideoUrl={backgroundAnimationUrl} className={styles['venue-background-video']} />
          <button className={buttonClassNames} onClick={(event) => playPauseVideo(event)}>
            {id === activeVideo ? <PauseButton /> : <PlayButton />}
          </button>
        </>
      )}
      {children}
    </div>
  );
};

export default VenueBackground;
