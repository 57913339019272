import React from 'react';

import styles from './ChipTab.module.scss';

interface IChipTab {
  active?: boolean;
  children: React.ReactNode;
  className?: string;
  beforeActivate?: () => boolean;
  onActivate: () => void;
}

const ChipTab = ({ active, className = '', children, onActivate, beforeActivate }: IChipTab) => {
  const classNames = ['button--styleless', className, styles['tab'], active ? styles['tab--active'] : ''];

  const handleChipTabClick = () => {
    if (beforeActivate && !beforeActivate()) {
      return;
    }

    onActivate();
  };

  return (
    <button type="button" role="tab" className={classNames.join(' ')} onClick={handleChipTabClick}>
      {children}
    </button>
  );
};

export default ChipTab;
