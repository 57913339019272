import { ERROR_MESSAGES } from '../constants/error-messages.constant';

export const getLootboxTransactionError = (errorMessage?: string): string => {
  console.error(errorMessage);

  if (!errorMessage) {
    return ERROR_MESSAGES.default;
  }

  if (errorMessage.toLowerCase().includes('user rejected')) {
    return ERROR_MESSAGES.errorTransactionRejected;
  }

  if (errorMessage.toLowerCase().includes('bal20')) {
    return ERROR_MESSAGES.errorBalance;
  }

  return ERROR_MESSAGES.default;
};
