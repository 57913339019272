import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { removeErrorMessageAction } from 'actions/global-actions';
import { Notifications } from 'enums/notifications/notifications.enum';
import Notification from '../notification/Notification';

interface IErrorNotificationProps {
  message: string;
  id: string;
  onClose?: () => void;
}

const ErrorNotification = ({ id, message, onClose }: IErrorNotificationProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const removeErrorMessage = () => {
    dispatch(removeErrorMessageAction(id));
  };

  return (
    <Notification
      iconId="accent-icon"
      iconAltText={t('errorIconAltText')}
      message={message}
      type={Notifications.error}
      onClose={onClose ? onClose : removeErrorMessage}
    />
  );
};

export default ErrorNotification;
