import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { removeSuccessMessageAction } from 'actions/global-actions';
import { Notifications } from 'enums/notifications/notifications.enum';
import Notification from '../notification/Notification';

interface ISuccessNotificationProps {
  message: string;
  id: string;
  onClose?: () => void;
}

const SuccessNotification = ({ id, message, onClose }: ISuccessNotificationProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const removeSuccessMessage = () => {
    dispatch(removeSuccessMessageAction(id));
  };

  return (
    <Notification
      iconId="check-mark-icon"
      iconAltText={t('successIconAltText')}
      message={message}
      type={Notifications.success}
      onClose={onClose ? onClose : removeSuccessMessage}
    />
  );
};

export default SuccessNotification;
