import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import Web3Singleton from 'web3Singleton';

import { IState } from 'models/reducers/i-state.interface';
import { IWalletCurrency } from 'models/wallet-currency-response.interface';

import { ERC20_ABI } from 'constants/abi/erc20-abi.constant';
import { ERROR_MESSAGES } from 'constants/error-messages.constant';
import { NETWORK_SETTINGS } from 'constants/network.constant';

import { addErrorMessageAction } from 'actions/global-actions';

import ModalPortal from 'components/modal/ModalPortal';
import TermsOfServiceModal, { ConsentsState } from 'components/termsOfServiceModal/TermsOfServiceModal';

import { parseFromDecimal, parseToDecimal } from 'utils/parser.utils';

import GameConfirmationModal from '../gameConfirmationModal/GameConfirmationModal';
import GameStakingCard from '../gameStakingCard/GameStakingCard';
import GameStakingForm from '../gameStakingForm/GameStakingForm';
import { IGameStaking } from '../types';

interface IGameStakingProps extends IGameStaking {
  multiplier: number | undefined;
  balance: string;
  apy: string;
  fee: string;
  remaining: string;
  staking_closed: boolean;
  rewardCurrency: IWalletCurrency | undefined;
}

const GameStaking = ({
  balance,
  apy,
  fee,
  remaining,
  staking_closed,
  currency,
  rewardCurrency,
  poolId,
  flexible,
  negative,
  multiplier,
  onCallback,
}: IGameStakingProps) => {
  const { t } = useTranslation('notifications');
  const dispatch = useDispatch();
  const walletAddress = useSelector((state: IState): string => state.wallet.address);
  const lgoWeb3 = Web3Singleton.getInstance();

  const [minDeposit, setMinDeposit] = useState('0');

  const handleCheckMinDeposit = async (negative: boolean | undefined, flexible: boolean | undefined) => {
    try {
      const minDeposit = await lgoWeb3.checkDefaultMinimumDeposit(negative, flexible);

      setMinDeposit(parseFromDecimal(minDeposit, currency!.decimal));
    } catch (e) {
      console.error(e);
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
    }
  };

  useEffect(() => {
    if (currency) {
      handleCheckMinDeposit(negative, flexible);
    }
  }, [currency, negative, flexible]);

  const [stakeTokenModal, onToggleStakeTokenModal] = useToggle(false);
  const [transactionHash, setTransactionHash] = useState<string | undefined>(undefined);

  const handleClose = () => {
    onToggleStakeTokenModal(false);
    setTransactionHash(undefined);
  };

  const handleStakeToken = async (amount: string) => {
    const stakeAddress = flexible
      ? NETWORK_SETTINGS.FLEXIBLE_STAKE.ADDRESS
      : negative
      ? NETWORK_SETTINGS.NEGATIVE_STAKE.ADDRESS
      : NETWORK_SETTINGS.STAKE.ADDRESS;

    if (!currency) {
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));

      return;
    }

    const currencyStakeValue = parseToDecimal(amount, currency!.decimal);

    const hasCurrencyPermission = await lgoWeb3.checkPermissionToCurrency(
      ERC20_ABI,
      stakeAddress,
      walletAddress,
      currency as Required<IWalletCurrency>,
      +currencyStakeValue
    );

    if (!hasCurrencyPermission) {
      try {
        await lgoWeb3.grantCurrencyPermissionServiceWithAmount(
          ERC20_ABI,
          stakeAddress,
          currency as Required<IWalletCurrency>,
          walletAddress,
          currencyStakeValue
        );
      } catch (e) {
        console.error(e);
        dispatch(addErrorMessageAction(t(ERROR_MESSAGES.permissionsRejected)));
        handleClose();

        return;
      }
    }

    const callback = {
      onTransactionHash: setTransactionHash,
      onConfirmation: () => {
        onCallback(poolId);
        handleClose();
      },
    };

    try {
      if (negative || flexible)
        await lgoWeb3.safeStake(
          walletAddress,
          poolId,
          currencyStakeValue.toString(),
          fee,
          negative ? false : flexible,
          callback
        );
      else await lgoWeb3.stakeToken(walletAddress, poolId, currencyStakeValue.toString(), callback);
    } catch (e: any) {
      console.error(e);
      if (e.code === 4001) {
        dispatch(addErrorMessageAction(t(ERROR_MESSAGES.purchaseCanceled)));
      } else {
        dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
      }
      handleClose();
    }
  };

  const [consents, setConsents] = useState<ConsentsState | undefined>(undefined);

  const handleSubmit = (amount: string) => {
    onToggleStakeTokenModal();
    handleStakeToken(amount);
  };

  return (
    <>
      <GameStakingCard title="Stake your RMV">
        {consents === ConsentsState.Opened && (
          <TermsOfServiceModal text="Otherwise staking would be not available for you." onChange={setConsents} />
        )}
        {walletAddress ? (
          <GameStakingForm
            balance={balance}
            currency={currency}
            rewardCurrency={rewardCurrency}
            minDeposit={minDeposit}
            apy={apy}
            poolId={poolId}
            flexible={flexible}
            negative={negative}
            multiplier={multiplier}
            fee={fee}
            remaining={remaining}
            staking_closed={staking_closed}
            consents={consents}
            onChange={setConsents}
            onSubmit={handleSubmit}
          />
        ) : (
          <p className="tw-text-white">Connect your wallet to stake your RMV</p>
        )}
      </GameStakingCard>
      {stakeTokenModal && (
        <ModalPortal title="Stake" onClose={handleClose}>
          <GameConfirmationModal title="Confirm stake token" transactionHash={transactionHash} />
        </ModalPortal>
      )}
    </>
  );
};

export default GameStaking;
