import Slider from 'rc-slider/lib/Slider';
import { useState } from 'react';

import { getIsEmpty } from '../../utils/get-is-empty.utils';

import { ReactComponent as MinusSvg } from '../../assets/slider/slider-minus-sign.svg';
import { ReactComponent as PlusSvg } from '../../assets/slider/slider-plus-sign.svg';
import 'rc-slider/assets/index.css';

import styles from './NumberSlider.module.scss';

const handleSize = styles['handleSize'];
const trackBackground = styles['trackBackground'];
const trackHeight = styles['trackHeight'];
const handleMargin = styles['handleMargin'];
const railBackground = styles['railBackground'];

interface INumberSliderProps {
  className: string;
  maxValue?: number;
  minValue?: number;
  ariaLabel?: string;
  onChange: (value: number) => void;
}

const NumberSlider = ({ ariaLabel, className = '', maxValue = 0, minValue = 0, onChange }: INumberSliderProps) => {
  const [value, setValue] = useState<number>(minValue);
  const classNames = [className, styles['slide-wrapper']];
  const startPoint = maxValue > 1 ? 1 : 0;

  const updateValue = (calculatedValue: number | null) => {
    const numericValue = getIsEmpty(calculatedValue) ? 0 : calculatedValue!;

    if (onChange) {
      onChange(numericValue!);
    }

    setValue(numericValue);
  };

  const decrementValue = () => {
    const inputValue = value ? +value : 0;
    const calculatedValue = inputValue - 1;

    if (calculatedValue <= minValue) {
      updateValue(minValue);

      return;
    }

    updateValue(calculatedValue);
  };

  const incrementValue = () => {
    const inputValue = value ? +value : 0;
    const calculatedValue = inputValue + 1;

    if (calculatedValue >= maxValue) {
      updateValue(maxValue);

      return;
    }

    updateValue(calculatedValue);
  };

  const updateInputValue = (sliderValue: number) => {
    if (sliderValue > maxValue) {
      return;
    }

    if (sliderValue < minValue) {
      return;
    }

    updateValue(sliderValue);
  };

  return (
    <div className={classNames.join(' ')}>
      <button
        onClick={decrementValue}
        className={`slider_btn button--styleless ${styles['slider__button']}`}
        type="button"
      >
        <MinusSvg />
      </button>
      <Slider
        value={value}
        startPoint={startPoint}
        min={startPoint}
        max={maxValue}
        ariaLabelForHandle={ariaLabel}
        onChange={(sliderValue) => updateInputValue(sliderValue as number)}
        style={{
          height: '14px',
          padding: '2px 0',
        }}
        trackStyle={{
          background: trackBackground,
          height: trackHeight,
        }}
        railStyle={{ backgroundColor: railBackground, height: trackHeight }}
        handleStyle={{
          height: handleSize,
          width: handleSize,
          border: 0,
          boxShadow: 'none',
          marginTop: handleMargin,
        }}
      />
      <button
        onClick={incrementValue}
        className={`slider_btn button--styleless ${styles['slider__button']}`}
        type="button"
      >
        <PlusSvg />
      </button>
    </div>
  );
};

export default NumberSlider;
