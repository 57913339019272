import { useEffect, useRef, useState } from 'react';

import ScrollInAnimationItem from '../../../components/scrollInAnimationItem/ScrollInAnimationItem';

import { useHorizontalScroll } from '../../../hooks/useHorizontalScroll';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { useWindowSize } from '../../../hooks/useWindowSize';

import Banner from '../../../assets/game-onboarding/banner-background-mobile.jpg';
import RealityMetaverseLogo from '../../../assets/logos/reality-metaverse-logo.svg';

const GameOnboardingMobileBannerSection = () => {
  const scrollRef = useHorizontalScroll();
  const screenSize = useScreenSize();
  const [windowWidth] = useWindowSize();
  const [showScrollSection, setShowScrollSection] = useState<boolean>(false);
  const nftCardElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }

    setShowScrollSection(scrollRef.current.clientWidth < scrollRef.current.scrollWidth);
  }, [scrollRef, screenSize]);

  useEffect(() => {
    if (!showScrollSection || !scrollRef.current || !nftCardElementRef.current) {
      return;
    }

    const nftCardWidth = nftCardElementRef.current.getBoundingClientRect().width;
    const offsetLeft = nftCardElementRef.current.offsetLeft - (windowWidth - nftCardWidth) / 2;

    scrollRef.current.scrollTo(offsetLeft, 0);
  }, [scrollRef, nftCardElementRef, showScrollSection, windowWidth]);

  return (
    <div className="tw-relative tw-h-[100vh] tw-w-[calc(100%+40px)] tw-bg-[#173169]">
      <div className="tw-relative tw-z-[1] tw-flex tw-max-w-full tw-flex-col tw-items-center tw-px-[20px] tw-py-[40px] tw-py-[50px]">
        <img src={RealityMetaverseLogo} alt="Reality Metaverse" className="tw-mb-20 tw-w-[226px]" />
        <ScrollInAnimationItem delay="0.1s">
          <h2 className="tw-mb-[15px] tw-text-center tw-text-[32px] tw-uppercase tw-leading-7 tw-text-white">
            <span className="tw-text-turquoise">How to claim&nbsp;</span>
            <br />
            your NFT?
          </h2>
          <p className="tw-mb-7.5 tw-text-center tw-text-lg tw-leading-6 tw-text-white">
            To claim your free NFT please open this url
            <br />
            on your desktop computer:
          </p>
        </ScrollInAnimationItem>
        <ScrollInAnimationItem className="tw-w-full" delay="0.2s">
          <div className="tw-w-full tw-rounded-[64px] tw-bg-darkBlue tw-px-3 tw-py-4.5 tw-text-center tw-text-xl tw-text-turquoise">
            https://bit.ly/lgonft
          </div>
        </ScrollInAnimationItem>
      </div>
      <img alt="" className="centered tw-absolute tw-bottom-0 tw-z-0 tw-h-[650px]" src={Banner} />
    </div>
  );
};

export default GameOnboardingMobileBannerSection;
