type RouteKeys =
  | 'HOME'
  | 'PORTAL'
  | 'MARKET'
  | 'ABOUT'
  | 'DAILY_DEALS'
  | 'LOOTBOX'
  | 'TOP_OFFERS'
  | 'LEADERS'
  | 'REFERRALS'
  | 'PROFILE'
  | 'TRADES'
  | 'LANDLORD_GO'
  | 'WHITEPAPER'
  | 'ASSETS'
  | 'LIST_FOR_SALE'
  | 'REDEEM'
  | 'MEDIA'
  | 'CONTACT'
  | 'GAMES'
  | 'PERIODICAL_STAKING'
  | 'DEALS'
  | 'SPOTLIGHT'
  | 'PROMOTIONS';

export const ROUTES: Record<RouteKeys, string> = {
  HOME: '/home',
  PORTAL: '/home/portal',
  MARKET: '/home/market',
  ABOUT: '/home/about',
  DAILY_DEALS: '/home/daily-deals',
  LOOTBOX: '/home/lootbox',
  TOP_OFFERS: '/home/top-offers',
  LEADERS: '/home/leaders',
  REFERRALS: '/home/referrals',
  PROFILE: '/home/profile',
  TRADES: '/home/trades',
  LANDLORD_GO: '/home/landlordgo',
  WHITEPAPER: '/home/white-paper',
  ASSETS: '/home/assets',
  LIST_FOR_SALE: '/home/list-for-sale',
  REDEEM: '/home/redeem',
  MEDIA: '/media',
  CONTACT: '/contact',
  GAMES: '/games',
  PERIODICAL_STAKING: '/staking',
  DEALS: '/home/deals',
  SPOTLIGHT: '/home/spotlight',
  PROMOTIONS: '/news',
};
