import { rest } from 'msw';

import { DAILY_DEALS_MOCK } from './daily-deals.mock';
import { TOP_OFFERS_MOCK } from './top-offers.mock';

export const handlers = [
  rest.get('/api/v1/trades/daily_deals', (req, res, ctx) => {
    return res(ctx.delay(350), ctx.status(200), ctx.json(DAILY_DEALS_MOCK));
  }),

  rest.get('/api/v1/trades/top_offers', (req, res, ctx) => {
    return res(ctx.delay(350), ctx.status(200), ctx.json(TOP_OFFERS_MOCK));
  }),
];
