import { ReactNode, useRef } from 'react';

import WithTwClassName from 'hoc/WithTwClassName';

import styles from './Tabs.module.scss';

interface ITabs {
  children: ReactNode;
  className?: string;
}

const Tabs = ({ children, className = '' }: ITabs) => {
  const typeSectionRef = useRef<HTMLDivElement>(null);
  const classNames = [className, styles['tabs']];

  return (
    <div ref={typeSectionRef} className={classNames.join(' ')}>
      {children}
    </div>
  );
};

export default WithTwClassName(Tabs);
