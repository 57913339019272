import { useDispatch, useSelector } from 'react-redux';

import { IState } from '../../../models/reducers/i-state.interface';

import { LootboxOpenStep } from '../../../enums/lootbox/lootbox-open-step.enum';
import { LootboxPurchaseStep } from '../../../enums/lootbox/lootbox-purchase-step.enum';

import {
  refreshLootboxUpdateTokenAction,
  resetLootboxOpenConfigAction,
  resetLootboxPurchaseConfigAction,
  setLootboxOpenAnimationFinishedAction,
} from 'actions/lootbox.actions';

import CheckoutFundsModal from 'components/modal/completeCheckoutModal/checkoutFundsModal/CheckoutFundsModal';

import { useLootboxOpen } from '../hooks/useLootboxOpen';
import { useLootboxPurchase } from '../hooks/useLootboxPurchase';

import LootboxOpenSuccess from '../lootboxOpenSuccess/LootboxOpenSuccess';
import LootboxPurchaseFailed from '../lootboxPurchaseFailed/LootboxPurchaseFailed';
import LootboxPurchaseProgress from '../lootboxPurchaseProgress/LootboxPurchaseProgress';
import LootboxPurchaseSuccess from '../lootboxPurchaseSuccess/LootboxPurchaseSuccess';
import LootboxTransactionProgress from '../lootboxTransactionProgress/LootboxTransactionProgress';
import styles from './LootboxTransactionView.module.scss';

const LootboxTransactionView = () => {
  const dispatch = useDispatch();
  const {
    fundsRemaining,
    listingConfirmationBlocks,
    openingAnimationFinished,
    openConfig,
    openErrorMessage,
    openStep,
    purchaseConfig,
    purchaseStep,
    purchaseErrorMessage,
    rewards,
  } = useSelector((state: IState) => state.lootbox);

  const { stopLootboxPurchase, restartLootboxPurchase } = useLootboxPurchase();
  const { startLootboxOpen, stopLootboxOpen, restartLootboxOpen } = useLootboxOpen();

  const resetState = () => {
    dispatch(resetLootboxOpenConfigAction());
    dispatch(resetLootboxPurchaseConfigAction());
    stopLootboxPurchase();
    stopLootboxOpen();
  };

  const onAnimationFinished = () => {
    dispatch(setLootboxOpenAnimationFinishedAction());
  };

  const refreshData = () => {
    dispatch(refreshLootboxUpdateTokenAction());
  };

  return (
    <div className={styles['transaction-view']}>
      {!!fundsRemaining && purchaseStep === LootboxPurchaseStep.noFunds && (
        <CheckoutFundsModal
          fundsRemaining={fundsRemaining}
          currency={purchaseConfig?.currency}
          onContinue={restartLootboxPurchase}
          onClose={resetState}
        />
      )}
      <LootboxTransactionProgress
        fadeOut={openStep === LootboxOpenStep.finished}
        background={(openConfig || purchaseConfig)?.openingAnimation}
        title={(openConfig || purchaseConfig)?.name}
        animationFinished={onAnimationFinished}
      >
        {(purchaseStep === LootboxPurchaseStep.inProgress || purchaseStep === LootboxPurchaseStep.noFunds) && (
          <LootboxPurchaseProgress text="Interacting with Loot box contract on the blockchain..." />
        )}
        {purchaseStep === LootboxPurchaseStep.blockchainConfirmation && (
          <LootboxPurchaseProgress
            text={`Waiting for ${listingConfirmationBlocks} confirmation blocks on the blockchain...`}
          />
        )}
        {!!purchaseConfig && purchaseStep === LootboxPurchaseStep.success && (
          <LootboxPurchaseSuccess
            openLootboxes={() => {
              stopLootboxPurchase();
              startLootboxOpen({ ...purchaseConfig, address: null });
            }}
            onContinue={() => {
              resetState();
              refreshData();
            }}
          />
        )}
        {purchaseStep === LootboxPurchaseStep.failed && (
          <LootboxPurchaseFailed
            text={purchaseErrorMessage || ''}
            title="Failed to buy"
            skipButtonText="Cancel"
            onSkip={resetState}
            onRetry={restartLootboxPurchase}
          />
        )}
        {openStep === LootboxOpenStep.inProgress && (
          <LootboxPurchaseProgress text="Interacting with Loot box contract on the blockchain..." />
        )}
        {openConfig && openStep === LootboxOpenStep.failed && (
          <LootboxPurchaseFailed
            text={openErrorMessage || ''}
            title="Failed to open"
            skipButtonText="Open later"
            onSkip={() => {
              resetState();
              refreshData();
            }}
            onRetry={restartLootboxOpen}
          />
        )}
        {openStep === LootboxOpenStep.finished && (
          <LootboxOpenSuccess
            openingAnimationFinished={openingAnimationFinished}
            quantity={openConfig?.quantity}
            rewards={rewards}
            onClaim={() => {
              resetState();
              refreshData();
            }}
          />
        )}
      </LootboxTransactionProgress>
    </div>
  );
};

export default LootboxTransactionView;
