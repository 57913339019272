import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { Noop } from 'types/common.interface';

import { IState } from 'models/reducers/i-state.interface';

import { IGame } from '../constants/games.constants';

import AnimatedTabs from 'components/animatedTabs/AnimatedTabs';
import ModalPortal from 'components/modal/ModalPortal';
import VideoBanner from 'components/videoBanner/VideoBanner';

import { useGameStaking } from '../hooks/useGameStaking';
import { useGameStakingRaised } from '../hooks/useGameStakingRaised';
import { useEnv } from 'hooks/useEnv';

import { ReactComponent as Play } from 'assets/play.svg';

import GameDetails from '../gameDetails/GameDetails';
import GameEarnings from '../gameEarnings/GameEarnings';
import GameLink from '../gameLink/GameLink';
import GameLive from '../gameLive/GameLive';
import GameProductButton from '../gameProductButton/GameProductButton';
import GameStaking from '../gameStaking/GameStaking';
import GameStakingBalance from '../gameStakingBalance/GameStakingBalance';
import GameStakingRaised from '../gameStakingRaised/GameStakingRaised';
import GameTag from '../gameTag/GameTag';

interface IGameModalProps {
  game: IGame | undefined;
  onClose: Noop;
}

const tabsNames = ['Description', 'Staking'];

const GameModal = ({ game, onClose }: IGameModalProps) => {
  const walletAddress = useSelector((state: IState): string => state.wallet.address);
  const { hideLogin } = useEnv();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleChange = () => setSelectedIndex(selectedIndex === 0 ? 1 : 0);

  const { currency, rewardCurrency, disabled, poolOpen, poolEnded, onReset, ...gameStakingRaised } =
    useGameStakingRaised(game);
  const gameStaking = useGameStaking(game, currency, gameStakingRaised.onCallback);

  const renderStaking =
    !!game && game.invest ? (
      <div className="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-7.5">
        {!disabled && !hideLogin && (
          <GameStakingRaised
            poolId={game.poolId}
            poolEnded={poolEnded}
            flexible={game.flexible}
            negative={game.negative}
            multiplier={game.multiplier}
            fee={gameStaking.fee}
            staking_closed={gameStaking.staking_closed}
            disabled={disabled}
            {...gameStakingRaised}
          />
        )}
        {!disabled && !hideLogin && !!walletAddress && (
          <>
            {poolOpen && (
              <GameStaking
                currency={currency}
                rewardCurrency={rewardCurrency}
                poolId={game?.poolId!}
                flexible={game?.flexible}
                negative={game?.negative}
                multiplier={game?.multiplier}
                {...gameStaking}
              />
            )}
            <GameStakingBalance
              poolOpen={poolOpen}
              poolEnded={poolEnded}
              currency={currency}
              poolId={game?.poolId!}
              flexible={game?.flexible}
              negative={game?.negative}
              {...gameStaking}
            />
            {!game?.flexible && (
              <GameEarnings
                currency={currency}
                rewardCurrency={rewardCurrency}
                poolId={game?.poolId!}
                flexible={game?.flexible}
                negative={game?.negative}
                multiplier={game?.multiplier}
                {...gameStaking}
              />
            )}
          </>
        )}
      </div>
    ) : null;

  const handleClose = () => {
    onClose();
    onReset();
  };

  const gameMedia = { id: 0, poster: game?.media?.poster, video: game?.media?.video };

  const [media, setMedia] = useState(gameMedia);

  useEffect(() => {
    setMedia(gameMedia);
  }, [game]);

  return (
    <>
      {game && (
        <ModalPortal
          onClose={handleClose}
          modalContentClassName="tw-pt-5 tw-pb-7.5 tw-px-5 lg:tw-py-14 lg:tw-px-7.5 md:tw-max-w-2xl lg:tw-max-w-5xl xl:tw-max-w-screen-xl"
          closeButtonClassName="tw-right-0 lg:tw-right-7.5"
          modalTitleClassName="lg:tw-mb-0"
        >
          <div className="tw-relative tw-flex tw-flex-col tw-gap-10 lg:-tw-mt-7.5 ">
            <div className="tw-flex tw-flex-col tw-gap-5 tw-self-stretch lg:tw-gap-10">
              <div>
                <div className="tw-mb-1.5 tw-flex tw-items-center tw-gap-5 lg:tw-mb-2.5">
                  <h2 className="tw-line-clamp-1 tw-text-[32px]/[34px] tw-uppercase tw-text-white lg:tw-text-[48px]/[52px]">
                    {game.title}
                  </h2>
                </div>
                <div className="tw-flex tw-gap-2">
                  {game.type.map((typeTag) => (
                    <GameTag key={typeTag}>{typeTag}</GameTag>
                  ))}
                </div>
              </div>
              <div className="tw-grid lg:tw-grid-cols-[404px,520px] lg:tw-gap-10 xl:tw-grid-cols-[520px,520px]">
                <div>
                  {game.media && (
                    <div className="tw-group tw-relative tw-mb-2.5 tw-aspect-[720/405] tw-overflow-hidden">
                      <VideoBanner
                        className="tw-w-full tw-rounded-2xl xl:tw-absolute xl:tw-inset-0 xl:tw-left-1/2 xl:tw-h-full xl:tw-object-cover xl:[transform:translateX(-50%)]"
                        mp4VideoUrl={media.video}
                        poster={media.poster}
                        loop={false}
                        controls
                      />
                    </div>
                  )}
                  {game.thumbnails && (
                    <div className="tw-mb-7.5 tw-flex tw-h-22 tw-max-w-[calc(100vw-40px)] tw-gap-2 tw-overflow-x-auto md:tw-max-w-[calc(42rem-40px)] lg:tw-mb-10 lg:tw-max-w-[calc(100vw-40px-40px-40px-520px)] xl:tw-h-28 xl:tw-max-w-[720px]">
                      {[game.media?.poster, ...game.thumbnails]
                        .map((thumbnail, i) => ({
                          id: i,
                          poster: thumbnail,
                          video: i === 0 ? game.media?.video : undefined,
                        }))
                        .map(({ id, poster, video }) => (
                          <div
                            key={id}
                            className={twMerge(
                              'tw-relative tw-aspect-[720/405] tw-h-18 tw-cursor-pointer tw-rounded-lg tw-border-2 tw-border-solid xl:tw-h-24',
                              id === media.id ? 'tw-border-turquoise' : 'tw-border-backgroundColorMedium'
                            )}
                            onClick={() => setMedia({ id, poster, video })}
                          >
                            {id === 0 && game.media?.video && (
                              <div className="tw-absolute tw-left-1/2 tw-top-1/2 tw-flex tw-h-8 tw-w-8 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full tw-bg-[#2850A599] [transform:translate(-50%,-50%)]">
                                <Play className="tw-w-2.5" />
                              </div>
                            )}
                            <div
                              className={twMerge(
                                'tw-absolute tw-inset-0 -tw-z-10 tw-overflow-hidden tw-bg-cover tw-bg-no-repeat tw-transition-opacity tw-duration-300',
                                id === media.id ? 'tw-opacity-100' : 'tw-opacity-30'
                              )}
                              style={{ backgroundImage: `url(${poster})` }}
                            />
                          </div>
                        ))}
                    </div>
                  )}
                  {(game.presentation || game.links.length) && (
                    <div className="tw-mb-7.5 tw-flex tw-flex-col tw-justify-between tw-gap-5 lg:tw-mb-15 lg:tw-flex-row lg:tw-items-center">
                      {game.presentation && <GameProductButton link={game.presentation} />}
                      <div className="tw-flex tw-h-[40px] tw-gap-4">
                        {game.links.map((link) => (
                          <GameLink
                            key={link.type}
                            {...link}
                            className="tw-h-[40px] tw-w-[40px] tw-fill-white hover:tw-fill-turquoise"
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  {!game.invest && (
                    <div className="tw-flex tw-flex-col tw-gap-7.5 lg:tw-hidden">
                      <GameLive />
                      <GameDetails game={game} />
                    </div>
                  )}
                  {game.invest && (
                    <AnimatedTabs
                      classNameList="tw-w-full tw-mx-auto tw-bg-darkBlue tw-mb-10 lg:tw-hidden"
                      list={tabsNames}
                      classNamePanels="tw-w-full lg:tw-hidden"
                      classNamePanel="tw-min-h-[300px]"
                      classNameTab="tw-min-h-[48px]"
                      disabled={disabled}
                      controlled={{ selectedIndex, onChange: handleChange }}
                      panels={[<GameDetails game={game} />, renderStaking]}
                    />
                  )}
                  <div className="tw-hidden lg:tw-block">
                    <GameDetails game={game} />
                  </div>
                </div>
                <div className="tw-hidden lg:tw-block">{game.invest ? renderStaking : <GameLive />}</div>
              </div>
            </div>
          </div>
        </ModalPortal>
      )}
    </>
  );
};

export default GameModal;
