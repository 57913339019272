import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSetState } from 'react-use';

import { IState } from '../../models/reducers/i-state.interface';

import { CookiesKeys } from '../../enums/cookies-keys.enum';
import { LoginProgress } from '../../enums/login-progress.enum';

import { ERROR_MESSAGES } from '../../constants/error-messages.constant';

import {
  addErrorMessageAction,
  setShowSelectWalletModal,
  setShowSwitchNetworkModal,
} from '../../actions/global-actions';
import { setWalletAddress } from '../../actions/wallet.action';

import { useConnectWalletEvents } from '../../hooks/events/useConnectWalletEvents';
import { useCookies } from '../../hooks/useCookies';

import { Web3Providers } from '../../reducers/web3.reducer';
import { getCSRF, getLogin, postLogin } from '../../services/hoard.service';
import Web3Singleton from '../../web3Singleton';
import ProgressModal from '../modal/progressModal/ProgressModal';
import SelectWalletModal from '../modal/selectWalletModal/SelectWalletModal';

interface IParamsState {
  signUpService: 'metamask' | 'torus' | undefined;
  success: boolean | undefined;
  message?: string;
  time: number;
}

export const Login = () => {
  const { setCookie, getCookie } = useCookies();
  const lgoWeb3 = Web3Singleton.getInstance();
  const { t } = useTranslation('notifications');
  const dispatch = useDispatch();
  const showSelectWalletModal = useSelector(({ global }: IState) => global.showSelectWalletModal);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isTorusLogin, setIsTorusLogin] = useState(false);
  const [loginProgress, setLoginProgress] = useState<LoginProgress>(LoginProgress.initialization);
  const { correctChain, currentProvider } = useSelector(({ web3 }: IState) => web3);
  const usingMetamask = currentProvider === Web3Providers.metamask;

  const [start, setStart] = useState(0);
  const [running, setRunning] = useState(false);

  const initialParams = {
    signUpService: undefined,
    success: undefined,
    message: undefined,
    time: 0,
  };
  const [params, setParams] = useSetState<IParamsState>(initialParams);

  const handleReset = () => {
    setStart(0);
    setParams(initialParams);
  };

  const { sendConnectWalletResultEvent } = useConnectWalletEvents();

  useEffect(() => {
    if (running && start === 0) {
      setStart(Date.now());
    } else if (!running && start > 0) {
      setParams({
        time: DateTime.fromMillis(Date.now()).diff(DateTime.fromMillis(start), 'milliseconds').milliseconds,
      });
    }
  }, [running, start, setParams]);

  useEffect(() => {
    if (params.time) {
      sendConnectWalletResultEvent(params, handleReset);
    }
  }, [params]);

  const loginMetamask = async () => {
    if (usingMetamask && !correctChain) {
      dispatch(setShowSwitchNetworkModal(true));
      return;
    }

    setIsTorusLogin(false);
    setLoginProgress(LoginProgress.initialization);
    setShowLoginModal(true);
    setRunning(true);

    try {
      await lgoWeb3.initializeMetamask();
      await lgoWeb3.loginMetamask();

      const address = await lgoWeb3.getAddress();

      if (!address) {
        setRunning(false);
        setParams({
          signUpService: 'metamask',
          success: false,
          message: 'user has no account connected',
        });
        throw new Error(t(ERROR_MESSAGES.noAccountConnected));
      }

      await handleCSRFToken();

      const loginSignatureText = await getLogin();
      const signature = await lgoWeb3.createSignature(loginSignatureText, address);

      setLoginProgress(LoginProgress.transactionPending);

      await completeLogin(signature, address);
      setRunning(false);
      setParams({
        signUpService: 'metamask',
        success: true,
      });
    } catch (error: any) {
      onLoginError(error);
      setRunning(false);
      setParams({
        signUpService: 'metamask',
        success: false,
        message: error?.message,
      });
    }
  };

  const loginTorus = async () => {
    setIsTorusLogin(true);
    setLoginProgress(LoginProgress.initialization);
    setShowLoginModal(true);
    setRunning(true);

    try {
      await lgoWeb3.initializeTorus();
      await lgoWeb3.loginTorus();
      const web3 = lgoWeb3.getWeb3();

      if (!web3) {
        throw new Error(t(ERROR_MESSAGES.noWeb3Provider));
      }

      const address = await lgoWeb3.getAddress();

      if (!address) {
        setRunning(false);
        setParams({
          signUpService: 'torus',
          success: false,
          message: 'user has no account connected',
        });
        throw new Error(t(ERROR_MESSAGES.noAccountConnected));
      }

      await handleCSRFToken();

      const loginSignatureText = await getLogin();
      const signature = await lgoWeb3.createSignature(loginSignatureText, address);

      setLoginProgress(LoginProgress.transactionPending);

      await completeLogin(signature, address);
      setRunning(false);
      setParams({
        signUpService: 'torus',
        success: true,
      });
      setCookieIsTorusUsed();
    } catch (error: any) {
      onLoginError(error);
      setRunning(false);
      setParams({
        signUpService: 'torus',
        success: false,
        message: error?.message,
      });
      lgoWeb3.resetProviders();
    }
  };

  const onLoginError = (error: any) => {
    setLoginProgress(LoginProgress.apiIssue);

    if (typeof error?.message === 'string') {
      dispatch(addErrorMessageAction(error.message));

      return;
    }

    dispatch(addErrorMessageAction(t(ERROR_MESSAGES.loginFaild)));
  };

  const currentStep = (): number => {
    switch (loginProgress) {
      case LoginProgress.transactionPending:
        return 1;
      case LoginProgress.transactionSuccess:
        return 2;
      case LoginProgress.apiIssue:
      case LoginProgress.metamaskNotDetected:
        return 0;
      default:
        return 1;
    }
  };

  const getProgressTitle = (): string => {
    switch (loginProgress) {
      case LoginProgress.transactionPending:
        return 'Pending transaction';
      case LoginProgress.transactionSuccess:
        return 'Success';
      case LoginProgress.apiIssue:
      case LoginProgress.metamaskNotDetected:
        return 'Failed';
      default:
        return 'Approve login.';
    }
  };

  const getProgressSubTitle = (): string => {
    const currentProvider = !isTorusLogin ? 'Metamask' : 'Torus';

    switch (loginProgress) {
      case LoginProgress.transactionPending:
        return 'Please wait for transaction to be saved.';
      case LoginProgress.transactionSuccess:
        return 'You are now logged in.';
      case LoginProgress.apiIssue:
        return 'Try again.';
      case LoginProgress.metamaskNotDetected:
        return 'Please install Metamask extension.';
      default:
        return `Please confirm in Your ${currentProvider} wallet.`;
    }
  };

  const handleCSRFToken = async () => {
    if (getCookie(CookiesKeys.csrftoken)) {
      return;
    }

    try {
      await getCSRF();
    } catch (error) {
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
    }
  };

  const completeLogin = async (signature: string, address: string) => {
    await postLogin(address, signature);

    setCookie(CookiesKeys.walletAddress, address, getTommorowDate());
    setLoginProgress(LoginProgress.transactionSuccess);
    dispatch(setWalletAddress(address));
    setShowLoginModal(false);
    dispatch(setShowSelectWalletModal(false));
  };

  const setCookieIsTorusUsed = () => {
    setCookie(CookiesKeys.isTorusUsed, true, getTommorowDate());
  };

  const getTommorowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);

    return tomorrow;
  };

  const handleCloseWalletModal = () => {
    dispatch(setShowSelectWalletModal(false));
    handleReset();
  };

  const handleCloseShowLoginModal = () => {
    setShowLoginModal(false);
    handleReset();
  };

  return (
    <>
      {showSelectWalletModal && (
        <SelectWalletModal
          onMetaMaskSelect={loginMetamask}
          onTorusSelect={loginTorus}
          onClose={handleCloseWalletModal}
        ></SelectWalletModal>
      )}
      {showLoginModal && (
        <>
          <ProgressModal stepsCount={3} currentStep={currentStep()} onClose={handleCloseShowLoginModal}>
            <div>
              <h1>{getProgressTitle()}</h1>
              <p>{getProgressSubTitle()}</p>
            </div>
          </ProgressModal>
        </>
      )}
    </>
  );
};
