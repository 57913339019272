import { CLEAR_VENUE, FETCH_VENUE_BY_ID, SELECT_VENUE } from '../constants/actionNames';
import { NO_VENUE } from '../constants/no-venue.constant';
import { IVenueActiveReducer } from '../models/reducers/i-venus-active-reducer.interface';

const INITIAL_STATE: IVenueActiveReducer = {
  activeVenue: NO_VENUE,
  loading: false,
  error: false,
  fetched: false,
};

export const venuesActiveReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CLEAR_VENUE:
      return { ...state, activeVenue: NO_VENUE };
    case SELECT_VENUE:
      return { ...state, activeVenue: action.payload };
    case FETCH_VENUE_BY_ID + '_PENDING':
      return { ...state, loading: true, error: false, fetched: false };
    case FETCH_VENUE_BY_ID + '_REJECTED':
      return { ...state, loading: false, error: true, fetched: false };
    case FETCH_VENUE_BY_ID + '_FULFILLED':
      return { ...state, activeVenue: { ...action.payload.data }, loading: false, error: false };
    default:
      return state;
  }
};
