import { twMerge } from 'tailwind-merge';

import styles from './GameOnboardingInfoCard.module.scss';

interface IGameOnboardingInfoCardProps {
  title: JSX.Element;
  content: JSX.Element;
  className?: string;
  contentClassName?: string;
  mainTextClassName?: string;
}

const GameOnboardingInfoCard = ({
  title,
  content,
  className = '',
  contentClassName = '',
  mainTextClassName = '',
}: IGameOnboardingInfoCardProps) => {
  return (
    <div
      className={twMerge(
        className,
        styles['card'],
        'tw-relative tw-rounded-[15px] tw-bg-blueCardGradient tw-p-[25px] sm:tw-p-[40px]'
      )}
    >
      <div className={twMerge(contentClassName, 'tw-flex tw-flex-col tw-gap-[15px] sm:tw-gap-[20px]')}>
        <div className="tw-text-[32px] tw-font-bold tw-uppercase tw-text-white sm:tw-text-[44px] lg:tw-text-[48px]">
          {title}
        </div>
        <div
          className={twMerge(
            mainTextClassName,
            'tw-mb-7.5 tw-text-lg tw-text-white sm:tw-text-[20px] md:tw-text-[22px]'
          )}
        >
          {content}
        </div>
      </div>
    </div>
  );
};

export default GameOnboardingInfoCard;
