import { useId } from 'react';
import { twMerge } from 'tailwind-merge';

import { IValidation } from 'models/i-form-validation.interface';
import { IInputValue } from 'models/i-input-value.interface';

import InputValidation from 'components/inputValidation/InputValidation';

import WithTwClassName from 'hoc/WithTwClassName';

import styles from './InputFormField.module.scss';

interface IInputProps {
  value: IInputValue;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  type?: string;
  step?: string | number;
  disabled?: boolean;
  label?: string;
  ariaLabel?: string;
  classNames?: string;
  labelClassName?: string;
  inputClassName?: string;
  appendixClassName?: string;
  helperClassName?: string;
  placeholder?: string;
  prefix?: JSX.Element;
  suffix?: JSX.Element;
  appendix?: JSX.Element;
  helper?: string;
  validations?: IValidation<IInputValue>[];
  inputColor?: 'primary' | 'secondary';
}

const InputFormField = ({
  type = 'text',
  step = 'any',
  inputColor = 'primary',
  ariaLabel,
  classNames,
  labelClassName,
  inputClassName,
  appendixClassName,
  helperClassName,
  label,
  placeholder,
  value,
  onChange,
  prefix,
  suffix,
  appendix,
  helper,
  disabled,
  validations,
}: IInputProps) => {
  const id = useId();
  const labelClassNames = [
    styles[`input-form-field__label`],
    !label && ariaLabel ? styles[`input-form-field__label--hidden`] : '',
    labelClassName,
  ];

  const inputClassNames = [inputClassName ? inputClassName : styles[`input-form-field__input`]];

  return (
    <div className={`${classNames} ${styles[`input-form-field`]} ${styles[`input-form-field--${inputColor}`]}`}>
      {(label || ariaLabel) && (
        <label htmlFor={id} className={labelClassNames.join(' ')}>
          {label || ariaLabel}
        </label>
      )}
      <div className={`form-field__wrapper ${styles[`input-form-field__wrapper`]}`}>
        {prefix && <span className="form-field__prefix">{prefix}</span>}
        {suffix && <span className="form-field__suffix">{suffix}</span>}
        {appendix && <div className={appendixClassName}>{appendix}</div>}
        {helper && <div className={helperClassName}>{helper}</div>}
        <input
          step={step}
          className={twMerge(inputClassNames.join(' '), appendix && '!tw-pr-32', helper && '!tw-h-[64px] !tw-pb-8')}
          id={id}
          type={type}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
        {validations?.map((validation) => (
          <InputValidation key={validation.key} validator={validation.validator} value={value} />
        ))}
      </div>
    </div>
  );
};

export default WithTwClassName(InputFormField);
