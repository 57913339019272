export const getQueryParams = (queryParams: Record<string, string | string[] | number[]>): URLSearchParams => {
  const params = new URLSearchParams();
  const queryParamsKeys = Object.keys(queryParams);

  queryParamsKeys.forEach((queryParamsKey) => {
    const queryParamsValue = queryParams[queryParamsKey];

    if (!queryParamsValue?.length) {
      return;
    }

    if (Array.isArray(queryParamsValue)) {
      params.append(queryParamsKey, (queryParams[queryParamsKey] as string[]).join(','));

      return;
    }

    params.append(queryParamsKey, queryParams[queryParamsKey] as string);
  });

  return params;
};
