import { useId } from 'react';

import './LoadingSpinner.scss';

interface ILoadingSpinnerProps {
  className?: string;
}

const LoadingSpinner = ({ className = '' }: ILoadingSpinnerProps) => {
  const id = useId();

  return (
    <svg className={`loading-spinner ${className}`} xmlns="http://www.w3.org/2000/svg" width="49" height="54">
      <defs>
        <linearGradient id={id} className="loading-spinner__gradient" x1="1" x2="0" y1="1" y2="0">
          <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
          <stop offset="100%" stopOpacity="0" stopColor="currentColor" />
        </linearGradient>
      </defs>
      <path
        className="loading-spinner__background"
        d="M24,2 47,15 47,39 24.52,51.64 2,39 2,15Z"
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        className="loading-spinner__spinner"
        d="M24,2 47,15 47,39 24.52,51.64 2,39 2,15Z"
        stroke={`url(#${id})`}
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LoadingSpinner;
