import { AxiosResponse } from 'axios';

import { IVenueGameStats } from '../models/i-venue-game-stats.interface';
import { getApiAxiosRequestMock } from './mocks.service';

export const fetchVenueGameStatsService = (token_id: string): Promise<AxiosResponse<IVenueGameStats>> => {
    return getApiAxiosRequestMock<IVenueGameStats>({
        numberOfOpens: 0,
        numberOfTransactions: 0,
        numberOfShareholders: 0
    })
}