import Slider from 'rc-slider/lib/Slider';

const GameLive = () => {
  return (
    <div className="tw-relative tw-rounded-2xl tw-bg-blueCardGradient tw-p-5 lg:tw-p-7.5">
      <h3 className="tw-mb-4 tw-text-[32px]/[24px] tw-uppercase tw-text-white">The game is currently live!</h3>
      <p className="tw-mt-5 tw-text-[18px]/[20px] tw-uppercase tw-tracking-[3.6px] tw-text-turquoise lg:tw-mt-7.5">
        Staking unavailable
      </p>
      <div className="tw-my-2.5 tw-flex tw-items-baseline tw-justify-between tw-self-stretch lg:tw-my-4">
        <p className="tw-flex tw-flex-col tw-text-center tw-text-[22px]/[23px] tw-font-semibold tw-uppercase tw-text-white lg:tw-flex-row lg:tw-items-end">
          <span>100% funded</span>
        </p>
      </div>
      <Slider
        value={100}
        marks={undefined}
        style={{ height: '14px', padding: '2px 0' }}
        railStyle={{ background: '#0E2659', height: '10px' }}
        trackStyle={{ background: '#00EB8A', height: '10px' }}
        handleStyle={{ display: 'none' }}
        dotStyle={{ display: 'none' }}
        className="tw-bg-[unset]"
      />
    </div>
  );
};

export default GameLive;
