import './Backdrop.scss';

export interface IBackdrop {
  show: boolean;
  onClick?: () => void;
}

const Backdrop = ({ show, onClick }: IBackdrop) => {
  return (
    <div
      className={`backdrop ${show ? 'visible' : ''}`}
      onClick={() => {
        if (!onClick) {
          return;
        }

        onClick();
      }}
    ></div>
  );
};

export default Backdrop;
