import { useState, useRef } from 'react';

import CompanyTreasureGraph from '../../../assets/landing/token-distribution/company-treasure-graph.png';
import TokenDistributionGraph from '../../../assets/landing/token-distribution/token-distribution-graph.png';
import TokenSaleGraph from '../../../assets/landing/token-distribution/token-sale-graph.png';
import UseOfProceedsGraph from '../../../assets/landing/token-distribution/use-of-proceeds-graph.png';
import VestingGraph from '../../../assets/landing/token-distribution/vesting-graph.png';
import ChipTab from '../../../components/chipTab/ChipTab';
import GraphPointer from '../../../components/graphPointer/GraphPointer';
import ScrollInAnimationItem from '../../../components/scrollInAnimationItem/ScrollInAnimationItem';
import { LandingTokenDistribution } from '../enums/landing-token-distribution.enum';

import styles from './LandingTokenDistributionSection.module.scss';
import NumberCountdown from '../../../components/numberCountodown/NumberCountdown';
import { useElementOnScreen } from '../../../hooks/useElementOnScreen';

interface IGraphSectionProps {
  active: boolean;
  animate: boolean;
}

const TokenDistributionSection = ({ active, animate }: IGraphSectionProps) => {
  const classNames = [
    styles['token-distribution-graph'],
    styles['graph'],
    active ? styles['graph--visible'] : styles['graph--hidden'],
  ].join(' ');

  return (
    <div className={classNames}>
      <div className={styles['graph-wrapper__content']}>
        <GraphPointer
          animate={animate && active}
          direction="left"
          className={styles['token-distribution-graph__pointer-1']}
        >
          <div className={`landing-main-text ${styles['graph__pointer-content']}`}>
            <div className={styles['graph__pointer-title']}>
              company reserves & liquidity
              <br />& strategic partnerships
            </div>
            <div className={styles['graph__pointer-value']}>
              {active && animate && (
                <>
                  <NumberCountdown value={45.5} step={2} />%
                </>
              )}
            </div>
          </div>
        </GraphPointer>
        <GraphPointer
          animate={animate && active}
          direction="left"
          className={styles['token-distribution-graph__pointer-2']}
        >
          <div className={`landing-main-text ${styles['graph__pointer-content']}`}>
            <div className={styles['graph__pointer-title']}>creators & advisors</div>
            <div className={styles['graph__pointer-value']}>
              {animate && active && (
                <>
                  <NumberCountdown value={17} step={2} />%
                </>
              )}
            </div>
          </div>
        </GraphPointer>
        <GraphPointer
          animate={animate && active}
          direction="right"
          className={styles['token-distribution-graph__pointer-3']}
        >
          <div className={`landing-main-text ${styles['graph__pointer-content']}`}>
            <div className={styles['graph__pointer-title']}>token sale</div>
            <div className={styles['graph__pointer-value']}>
              {animate && active && (
                <>
                  <NumberCountdown value={24} />%
                </>
              )}
            </div>
          </div>
        </GraphPointer>
        <GraphPointer
          animate={animate && active}
          direction="right"
          className={styles['token-distribution-graph__pointer-4']}
        >
          <div className={`landing-main-text ${styles['graph__pointer-content']}`}>
            <div className={styles['graph__pointer-title']}>community & marketing</div>
            <div className={styles['graph__pointer-value']}>
              {animate && active && (
                <>
                  <NumberCountdown value={13.5} />%
                </>
              )}
            </div>
          </div>
        </GraphPointer>
        <img
          className={styles['token-distribution-graph__graph']}
          src={TokenDistributionGraph}
          alt=""
          width={427}
          height={361}
        />
      </div>
    </div>
  );
};

const UseOfProceedsSection = ({ active, animate }: IGraphSectionProps) => {
  const classNames = [
    styles['use-of-proceeds-graph'],
    styles['graph'],
    active ? styles['graph--visible'] : styles['graph--hidden'],
  ].join(' ');

  return (
    <div className={classNames}>
      <div className={styles['graph-wrapper__content']}>
        <GraphPointer
          animate={animate && active}
          direction="left"
          className={styles['use-of-proceeds-graph__pointer-1']}
        >
          <div className={`landing-main-text ${styles['graph__pointer-content']}`}>
            <div className={styles['graph__pointer-title']}>liquidity</div>
            <div className={styles['graph__pointer-value']}>
              {animate && active && (
                <>
                  <NumberCountdown value={15} />%
                </>
              )}
            </div>
          </div>
        </GraphPointer>
        <GraphPointer
          animate={animate && active}
          direction="left"
          className={styles['use-of-proceeds-graph__pointer-2']}
        >
          <div className={`landing-main-text ${styles['graph__pointer-content']}`}>
            <div className={styles['graph__pointer-title']}>marketing</div>
            <div className={styles['graph__pointer-value']}>
              {animate && active && (
                <>
                  <NumberCountdown value={25} />%
                </>
              )}
            </div>
          </div>
        </GraphPointer>
        <GraphPointer
          animate={animate && active}
          direction="left"
          className={styles['use-of-proceeds-graph__pointer-3']}
        >
          <div className={`landing-main-text ${styles['graph__pointer-content']}`}>
            <div className={styles['graph__pointer-title']}>listing</div>
            <div className={styles['graph__pointer-value']}>
              {animate && active && (
                <>
                  <NumberCountdown value={35} />%
                </>
              )}
            </div>
          </div>
        </GraphPointer>
        <GraphPointer
          animate={animate && active}
          direction="right"
          className={styles['use-of-proceeds-graph__pointer-4']}
        >
          <div className={`landing-main-text ${styles['graph__pointer-content']}`}>
            <div className={styles['graph__pointer-title']}>reserve</div>
            <div className={styles['graph__pointer-value']}>
              {animate && active && (
                <>
                  <NumberCountdown value={15} />%
                </>
              )}
            </div>
          </div>
        </GraphPointer>
        <GraphPointer
          animate={animate && active}
          direction="right"
          className={styles['use-of-proceeds-graph__pointer-5']}
        >
          <div className={`landing-main-text ${styles['graph__pointer-content']}`}>
            <div className={styles['graph__pointer-title']}>token management</div>
            <div className={styles['graph__pointer-value']}>
              {animate && active && (
                <>
                  <NumberCountdown value={10} />%
                </>
              )}
            </div>
          </div>
        </GraphPointer>
        <img src={UseOfProceedsGraph} alt="" width={486} height={360} />
      </div>
    </div>
  );
};

const CompanyTreasureSection = ({ animate, active }: IGraphSectionProps) => {
  const classNames = [
    styles['company-treasure-graph'],
    styles['graph'],
    active ? styles['graph--visible'] : styles['graph--hidden'],
  ].join(' ');

  return (
    <div className={classNames}>
      <div className={styles['graph-wrapper__content']}>
        <GraphPointer
          animate={animate && active}
          direction="left"
          className={styles['company-treasure-graph__pointer-1']}
        >
          <div className={`landing-main-text ${styles['graph__pointer-content']}`}>
            <div className={styles['graph__pointer-title']}>liquidity</div>
            <div className={styles['graph__pointer-value']}>
              {animate && active && (
                <>
                  <NumberCountdown value={6} />%
                </>
              )}
            </div>
          </div>
        </GraphPointer>
        <GraphPointer
          animate={animate && active}
          direction="left"
          className={styles['company-treasure-graph__pointer-2']}
        >
          <div className={`landing-main-text ${styles['graph__pointer-content']}`}>
            <div className={styles['graph__pointer-title']}>
              future metaverse
              <br />
              content production
            </div>
            <div className={styles['graph__pointer-value']}>
              {animate && active && (
                <>
                  <NumberCountdown value={10.5} />%
                </>
              )}
            </div>
          </div>
        </GraphPointer>
        <GraphPointer
          animate={animate && active}
          direction="right"
          className={styles['company-treasure-graph__pointer-3']}
        >
          <div className={`landing-main-text ${styles['graph__pointer-content']}`}>
            <div className={styles['graph__pointer-title']}>
              strategic
              <br />
              pertnership
            </div>
            <div className={styles['graph__pointer-value']}>
              {animate && active && (
                <>
                  <NumberCountdown value={10} />%
                </>
              )}
            </div>
          </div>
        </GraphPointer>
        <GraphPointer
          animate={animate && active}
          direction="right"
          className={styles['company-treasure-graph__pointer-4']}
        >
          <div className={`landing-main-text ${styles['graph__pointer-content']}`}>
            <div className={styles['graph__pointer-title']}>reserve</div>
            <div className={styles['graph__pointer-value']}>
              {animate && active && (
                <>
                  <NumberCountdown value={19} />%
                </>
              )}
            </div>
          </div>
        </GraphPointer>
        <img src={CompanyTreasureGraph} alt="" width={472} height={360} />
      </div>
    </div>
  );
};

const TokenSaleSection = ({ active, animate }: IGraphSectionProps) => {
  const classNames = [
    styles['token-sale-graph'],
    styles['graph'],
    active ? styles['graph--visible'] : styles['graph--hidden'],
  ].join(' ');

  return (
    <div className={classNames}>
      <div className={styles['graph-wrapper__content']}>
        <GraphPointer animate={animate && active} direction="left" className={styles['token-sale-graph__pointer-1']}>
          <div className={`landing-main-text ${styles['graph__pointer-content']}`}>
            <div className={styles['graph__pointer-title']}>launchpad</div>
            <div className={styles['graph__pointer-value']}>
              {animate && active && (
                <>
                  <NumberCountdown value={2} />%
                </>
              )}
            </div>
          </div>
        </GraphPointer>
        <GraphPointer animate={animate && active} direction="right" className={styles['token-sale-graph__pointer-2']}>
          <div className={`landing-main-text ${styles['graph__pointer-content']}`}>
            <div className={styles['graph__pointer-title']}>seed</div>
            <div className={styles['graph__pointer-value']}>
              {animate && active && (
                <>
                  <NumberCountdown value={8} />%
                </>
              )}
            </div>
          </div>
        </GraphPointer>
        <GraphPointer animate={animate && active} direction="right" className={styles['token-sale-graph__pointer-3']}>
          <div className={`landing-main-text ${styles['graph__pointer-content']}`}>
            <div className={styles['graph__pointer-title']}>private</div>
            <div className={styles['graph__pointer-value']}>
              {animate && active && (
                <>
                  <NumberCountdown value={8} />%
                </>
              )}
            </div>
          </div>
        </GraphPointer>
        <GraphPointer animate={animate && active} direction="right" className={styles['token-sale-graph__pointer-4']}>
          <div className={`landing-main-text ${styles['graph__pointer-content']}`}>
            <div className={styles['graph__pointer-title']}>public</div>
            <div className={styles['graph__pointer-value']}>
              {animate && active && (
                <>
                  <NumberCountdown value={6} />%
                </>
              )}
            </div>
          </div>
        </GraphPointer>
        <img src={TokenSaleGraph} alt="" width={290} height={360} />
      </div>
    </div>
  );
};

const VestingSection = ({ active }: IGraphSectionProps) => {
  const classNames = [
    styles['vesting-graph'],
    styles['graph'],
    active ? styles['graph--visible'] : styles['graph--hidden'],
  ].join(' ');

  return (
    <div className={classNames}>
      <div className={styles['graph-wrapper__content']}>
        <img src={VestingGraph} alt="" width={740} height={360} />
      </div>
    </div>
  );
};

interface ILandingTokenDistributionGraphWrapperProps {
  active: boolean;
  children: React.ReactNode;
}

const LandingTokenDistributionGraphWrapper = ({ active, children }: ILandingTokenDistributionGraphWrapperProps) => {
  const classNames = [
    styles['graph-wrapper'],
    active ? styles['graph-wrapper--visible'] : styles['graph-wrapper--hidden'],
  ].join(' ');

  return <div className={classNames}>{children}</div>;
};

const LandingTokenDistributionSection = () => {
  const ref = useRef<HTMLDivElement>(null);
  const onScreen = useElementOnScreen(ref, 0.3, true);
  const [activeTab, setActiveTab] = useState<LandingTokenDistribution>(LandingTokenDistribution.tokenDistribution);
  const classNames = [styles['section']].join(' ');

  return (
    <section ref={ref} className={classNames}>
      <ScrollInAnimationItem className={styles['summary']} delay="0.1s" threshold={0.3}>
        <h2 className={styles['title']}>
          Token <b>Distribution</b>
        </h2>
      </ScrollInAnimationItem>
      <div className={styles['tabs-wrapper']}>
        <div className={styles['tabs']}>
          <ChipTab
            active={activeTab === LandingTokenDistribution.tokenDistribution}
            onActivate={() => setActiveTab(LandingTokenDistribution.tokenDistribution)}
          >
            Token Distribution
          </ChipTab>
          <ChipTab
            active={activeTab === LandingTokenDistribution.useOfProceeds}
            onActivate={() => setActiveTab(LandingTokenDistribution.useOfProceeds)}
          >
            Use of Proceeds
          </ChipTab>
          <ChipTab
            active={activeTab === LandingTokenDistribution.companyTreasure}
            onActivate={() => setActiveTab(LandingTokenDistribution.companyTreasure)}
          >
            Company Treasury
          </ChipTab>
          <ChipTab
            active={activeTab === LandingTokenDistribution.tokenSale}
            onActivate={() => setActiveTab(LandingTokenDistribution.tokenSale)}
          >
            Token Sale
          </ChipTab>
          <ChipTab
            active={activeTab === LandingTokenDistribution.vesting}
            onActivate={() => setActiveTab(LandingTokenDistribution.vesting)}
          >
            Vesting
          </ChipTab>
        </div>
      </div>

      <LandingTokenDistributionGraphWrapper active={activeTab === LandingTokenDistribution.tokenDistribution}>
        <TokenDistributionSection
          animate={onScreen}
          active={activeTab === LandingTokenDistribution.tokenDistribution}
        />
      </LandingTokenDistributionGraphWrapper>

      <LandingTokenDistributionGraphWrapper active={activeTab === LandingTokenDistribution.useOfProceeds}>
        <UseOfProceedsSection animate={onScreen} active={activeTab === LandingTokenDistribution.useOfProceeds} />
      </LandingTokenDistributionGraphWrapper>

      <LandingTokenDistributionGraphWrapper active={activeTab === LandingTokenDistribution.companyTreasure}>
        <CompanyTreasureSection animate={onScreen} active={activeTab === LandingTokenDistribution.companyTreasure} />
      </LandingTokenDistributionGraphWrapper>

      <LandingTokenDistributionGraphWrapper active={activeTab === LandingTokenDistribution.tokenSale}>
        <TokenSaleSection animate={onScreen} active={activeTab === LandingTokenDistribution.tokenSale} />
      </LandingTokenDistributionGraphWrapper>

      <LandingTokenDistributionGraphWrapper active={activeTab === LandingTokenDistribution.vesting}>
        <VestingSection animate={onScreen} active={activeTab === LandingTokenDistribution.vesting} />
      </LandingTokenDistributionGraphWrapper>
    </section>
  );
};

export default LandingTokenDistributionSection;
