import { useDispatch } from 'react-redux';

import { setShowCurrencyPurchaseModal } from 'actions/global-actions';

import { ISourceScreen } from 'hooks/events/useEventsParams';
import { useFundsEvents } from 'hooks/events/useFundsEvents';

import Button from '../buttons/Button';
import styles from './BuyRMVControl.module.scss';

interface IBuyRMVControlProps extends ISourceScreen {
  className?: string;
}

const BuyRMVControl = ({ className, sourceScreen }: IBuyRMVControlProps) => {
  const dispatch = useDispatch();
  const classNames = [styles['button'], className];

  const { sendFundsBuyClickedEvent } = useFundsEvents();

  const handleClick = () => {
    dispatch(setShowCurrencyPurchaseModal({ show: true }));
    sendFundsBuyClickedEvent({ sourceScreen });
  };

  return (
    <Button
      requireWeb3
      className={classNames.join(' ')}
      color="blue"
      text="Buy RMV"
      size="medium"
      onClick={handleClick}
    />
  );
};

export default BuyRMVControl;
