import styles from './NftCategoryIdSection.module.scss';

interface INftCategoryIdSectionProps {
  id: number;
  category: string | null;
  className?: string;
}

const NftCategoryIdSection = ({ id, category, className = '' }: INftCategoryIdSectionProps) => {
  const classNames = [className, styles['category-id-section']];

  return (
    <div className={classNames.join(' ')}>
      <span>{category}</span>
      <span>#{id}</span>
    </div>
  );
};

export default NftCategoryIdSection;
