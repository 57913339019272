import { useEffect, useState } from 'react';

import { useWindowSize } from './useWindowSize';

export const SCREEN_SIZES = {
  extraLarge: { from: 1920, to: 9999 },
  large: { from: 1500, to: 1919 },
  mediumLarge: { from: 1360, to: 1499 },
  medium: { from: 1170, to: 1359 },
  mediumSmall: { from: 960, to: 1169 },
  small: { from: 765, to: 959 },
  extraSmall: { from: 0, to: 764 },
};

export enum ScreenSize {
  extraSmall = 1,
  small,
  mediumSmall,
  medium,
  mediumLarge,
  large,
  extraLarge,
}

const isBetween = (from: number, to: number, screenWidth: number): boolean => {
  return screenWidth >= from && screenWidth <= to;
};

const getScreenSize = (screenWidth: number): ScreenSize => {
  if (isBetween(SCREEN_SIZES.extraSmall.from, SCREEN_SIZES.extraSmall.to, screenWidth)) {
    return ScreenSize.extraSmall;
  }

  if (isBetween(SCREEN_SIZES.small.from, SCREEN_SIZES.small.to, screenWidth)) {
    return ScreenSize.small;
  }

  if (isBetween(SCREEN_SIZES.mediumSmall.from, SCREEN_SIZES.mediumSmall.to, screenWidth)) {
    return ScreenSize.mediumSmall;
  }

  if (isBetween(SCREEN_SIZES.medium.from, SCREEN_SIZES.medium.to, screenWidth)) {
    return ScreenSize.medium;
  }

  if (isBetween(SCREEN_SIZES.mediumLarge.from, SCREEN_SIZES.mediumLarge.to, screenWidth)) {
    return ScreenSize.mediumLarge;
  }

  if (isBetween(SCREEN_SIZES.large.from, SCREEN_SIZES.large.to, screenWidth)) {
    return ScreenSize.large;
  }

  return ScreenSize.extraLarge;
};

export const useScreenSize = (): ScreenSize => {
  const [width] = useWindowSize();
  const [screenSize, setScreenSize] = useState<ScreenSize>(getScreenSize(width));

  useEffect(() => {
    setScreenSize(getScreenSize(width));
  }, [width]);

  return screenSize;
};
