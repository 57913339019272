export const TOP_OFFERS_MOCK = {
  count: 10,
  next: null,
  previous: null,
  results: [
    {
      valid_from: 1695549600,
      valid_until: 1695636000,
      assets: [
        {
          sale: {
            asset_id: 133,
            amount: 1,
            currency_id: 6,
            expiration_time: 1695636000,
            nonce: 1,
            price: '93000000000000000000000',
            seller: {
              id: 1,
              address: '',
            },
            signature: '',
            state_hash: '',
            status: 2,
          },
        },
        {
          sale: {
            asset_id: 132,
            amount: 1,
            currency_id: 6,
            expiration_time: 1695636000,
            nonce: 1,
            price: '93000000000000000000000',
            seller: {
              id: 1,
              address: '',
            },
            signature: '',
            state_hash: '',
            status: 2,
          },
        },
      ],
    },
  ],
};
