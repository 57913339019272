import Metamask from '../../../../assets/logos/metamask.svg';

const GameOnboardingInstructionCopyWallet = () => {
  return (
    <ul>
      <li>In the MetaMask app or extension, click on your account name at the top.</li>
      <li>
        Your wallet address will be displayed below your account name. It's a string of characters starting with "0x".
      </li>
      <li>Click on the wallet address or the copy icon next to it. Your address will be copied to your clipboard.</li>
      <li>Paste the address wherever required to claim your NFT.</li>
    </ul>
  );
};

export default GameOnboardingInstructionCopyWallet;
