import { ReactNode } from 'react';

import styles from './Card.module.scss';

interface ICardProps {
  children: ReactNode;
  className?: string;
  theme?: 'normal' | 'medium' | 'dark';
}

const Card = ({ children, className, theme = 'normal' }: ICardProps) => {
  return <div className={`${styles['card']} ${className} ${styles[`card--${theme}`]}`}>{children}</div>;
};

export default Card;
