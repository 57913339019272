export const TRADE_CONTRACT_ABI: any[] = [
  {
    inputs: [],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'buyer',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'seller',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'bytes32',
        name: 'signatureHash',
        type: 'bytes32',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256[]',
        name: 'tokenIds',
        type: 'uint256[]',
      },
    ],
    name: 'BidAccepted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'buyer',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'bytes32',
        name: 'signatureHash',
        type: 'bytes32',
      },
    ],
    name: 'BidCanceled',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'feeRecipient',
        type: 'address',
      },
    ],
    name: 'FeeRecipientSet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
    ],
    name: 'ItemSupportStopped',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
    ],
    name: 'ItemSupported',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'buyer',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'seller',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'bytes32',
        name: 'signatureHash',
        type: 'bytes32',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256[]',
        name: 'tokenIds',
        type: 'uint256[]',
      },
    ],
    name: 'OfferAccepted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'seller',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'bytes32',
        name: 'signatureHash',
        type: 'bytes32',
      },
    ],
    name: 'OfferCanceled',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'tradeTokenAddress',
        type: 'address',
      },
      {
        components: [
          {
            internalType: 'uint48',
            name: 'numerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'denominator',
            type: 'uint48',
          },
        ],
        indexed: false,
        internalType: 'struct FractionMath.Fraction',
        name: '_tradeFee',
        type: 'tuple',
      },
    ],
    name: 'TradeFeesSet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
    ],
    name: 'TradeTokenSupportStopped',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
    ],
    name: 'TradeTokenSupported',
    type: 'event',
  },
  {
    inputs: [],
    name: 'CVERSION',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'NAME',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'VERSION',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'afterUpgrade',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'sellerAddress',
        type: 'address',
      },
      {
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
      {
        components: [
          {
            internalType: 'uint32',
            name: 'nonce',
            type: 'uint32',
          },
          {
            internalType: 'uint40',
            name: 'expirationTime',
            type: 'uint40',
          },
          {
            internalType: 'address',
            name: 'tradeTokenAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'itemsTokenAddress',
            type: 'address',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
              {
                internalType: 'bytes32',
                name: 'stateHash',
                type: 'bytes32',
              },
            ],
            internalType: 'struct TradeModel.TradeItem[]',
            name: 'items',
            type: 'tuple[]',
          },
          {
            internalType: 'uint256',
            name: 'offerValue',
            type: 'uint256',
          },
          {
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
        ],
        internalType: 'struct TradeModel.TradeOffer',
        name: 'offer',
        type: 'tuple',
      },
    ],
    name: 'buyOnOffer',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
      {
        components: [
          {
            internalType: 'uint32',
            name: 'nonce',
            type: 'uint32',
          },
          {
            internalType: 'uint40',
            name: 'expirationTime',
            type: 'uint40',
          },
          {
            internalType: 'address',
            name: 'tradeTokenAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'itemsTokenAddress',
            type: 'address',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
              {
                internalType: 'bytes32',
                name: 'stateHash',
                type: 'bytes32',
              },
            ],
            internalType: 'struct TradeModel.TradeItem[]',
            name: 'items',
            type: 'tuple[]',
          },
          {
            internalType: 'uint256',
            name: 'bidValue',
            type: 'uint256',
          },
        ],
        internalType: 'struct TradeModel.TradeBid',
        name: 'bid',
        type: 'tuple',
      },
    ],
    name: 'cancelBid',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
      {
        components: [
          {
            internalType: 'uint32',
            name: 'nonce',
            type: 'uint32',
          },
          {
            internalType: 'uint40',
            name: 'expirationTime',
            type: 'uint40',
          },
          {
            internalType: 'address',
            name: 'tradeTokenAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'itemsTokenAddress',
            type: 'address',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
              {
                internalType: 'bytes32',
                name: 'stateHash',
                type: 'bytes32',
              },
            ],
            internalType: 'struct TradeModel.TradeItem[]',
            name: 'items',
            type: 'tuple[]',
          },
          {
            internalType: 'uint256',
            name: 'offerValue',
            type: 'uint256',
          },
          {
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
        ],
        internalType: 'struct TradeModel.TradeOffer',
        name: 'offer',
        type: 'tuple',
      },
    ],
    name: 'cancelOffer',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'recipient',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'emergencyNFTWithdrawal',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tradeTokenAddress',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'recipient',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'emergencyTradeTokenWithdrawal',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'feeRecipient',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'feeRecipient',
        type: 'address',
      },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
    ],
    name: 'isItemTokenSupported',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'signatureHash',
        type: 'bytes32',
      },
    ],
    name: 'isSignatureUsed',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
    ],
    name: 'isTradeTokenSupported',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'itemTokenAddress',
        type: 'address',
      },
    ],
    name: 'itemHandler',
    outputs: [
      {
        internalType: 'contract IHandler',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'index',
        type: 'uint256',
      },
    ],
    name: 'itemTokenByIndex',
    outputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'handlerAddress',
        type: 'address',
      },
      {
        internalType: 'bool',
        name: 'isCurrentlySupported',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'buyerAddress',
        type: 'address',
      },
      {
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
      {
        components: [
          {
            internalType: 'uint32',
            name: 'nonce',
            type: 'uint32',
          },
          {
            internalType: 'uint40',
            name: 'expirationTime',
            type: 'uint40',
          },
          {
            internalType: 'address',
            name: 'tradeTokenAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'itemsTokenAddress',
            type: 'address',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
              {
                internalType: 'bytes32',
                name: 'stateHash',
                type: 'bytes32',
              },
            ],
            internalType: 'struct TradeModel.TradeItem[]',
            name: 'items',
            type: 'tuple[]',
          },
          {
            internalType: 'uint256',
            name: 'bidValue',
            type: 'uint256',
          },
        ],
        internalType: 'struct TradeModel.TradeBid',
        name: 'bid',
        type: 'tuple',
      },
    ],
    name: 'sellOnBid',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'buyerAddress',
        type: 'address',
      },
      {
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
      {
        components: [
          {
            internalType: 'uint32',
            name: 'nonce',
            type: 'uint32',
          },
          {
            internalType: 'uint40',
            name: 'expirationTime',
            type: 'uint40',
          },
          {
            internalType: 'address',
            name: 'tradeTokenAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'itemsTokenAddress',
            type: 'address',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
              {
                internalType: 'bytes32',
                name: 'stateHash',
                type: 'bytes32',
              },
            ],
            internalType: 'struct TradeModel.TradeItem[]',
            name: 'items',
            type: 'tuple[]',
          },
          {
            internalType: 'uint256',
            name: 'bidValue',
            type: 'uint256',
          },
        ],
        internalType: 'struct TradeModel.TradeBid',
        name: 'bid',
        type: 'tuple',
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
    ],
    name: 'sellOnBidTo',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '__feeRecipient',
        type: 'address',
      },
    ],
    name: 'setFeeRecipient',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tradeTokenAddress',
        type: 'address',
      },
      {
        components: [
          {
            internalType: 'uint48',
            name: 'numerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'denominator',
            type: 'uint48',
          },
        ],
        internalType: 'struct FractionMath.Fraction',
        name: '_tradeFee',
        type: 'tuple',
      },
    ],
    name: 'setTradeFees',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
    ],
    name: 'stopSupportingItem',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
    ],
    name: 'stopSupportingTradeToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IHandler',
        name: 'handler',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
    ],
    name: 'supportItem',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
      {
        components: [
          {
            internalType: 'uint48',
            name: 'numerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'denominator',
            type: 'uint48',
          },
        ],
        internalType: 'struct FractionMath.Fraction',
        name: '_tradeFee',
        type: 'tuple',
      },
    ],
    name: 'supportTradeToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes4',
        name: 'interfaceId',
        type: 'bytes4',
      },
    ],
    name: 'supportsInterface',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalItemTokens',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalTradeTokens',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tradeTokenAddress',
        type: 'address',
      },
    ],
    name: 'tradeFee',
    outputs: [
      {
        components: [
          {
            internalType: 'uint48',
            name: 'numerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'denominator',
            type: 'uint48',
          },
        ],
        internalType: 'struct FractionMath.Fraction',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'index',
        type: 'uint256',
      },
    ],
    name: 'tradeTokenByIndex',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'enum EnumerableMap.SupportState',
        name: '',
        type: 'uint8',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
    ],
    name: 'wasTradeTokenEverSupported',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
];
