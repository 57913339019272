import { PropsWithChildren, useState } from 'react';

import Card from 'components/card/Card';

import LootboxBackground from '../lootboxCard/lootboxBackground/LootboxBackground';
import styles from './LootboxTransactionProgress.module.scss';

interface ILootboxTransactionProgressProps extends PropsWithChildren {
  background?: string;
  fadeOut?: boolean;
  title?: string;
  animationFinished?: () => void;
}

const LootboxTransactionProgress = ({
  background,
  children,
  fadeOut,
  title,
  animationFinished,
}: ILootboxTransactionProgressProps) => {
  const [openingAnimationFinished, setOpeningAnimationFinished] = useState<boolean>(false);

  return (
    <Card theme="medium" className={styles['transaction-view']}>
      <div className={openingAnimationFinished ? styles['transaction-view__content--fade-out'] : ''}>
        <div className={styles['transaction-view__caption']}>
          <p className={styles['transaction-view__title']}>{title}</p>
          <p className={styles['transaction-view__subtitle']}>fNFT</p>
        </div>
        <div className={styles['transaction-view__content']}>
          <LootboxBackground
            className={styles['transaction-view__lootbox']}
            allowAnimation
            autoplay={fadeOut}
            background={background}
            animationFinished={() => {
              if (animationFinished) {
                animationFinished();
                setOpeningAnimationFinished(true);
              }
            }}
          ></LootboxBackground>
        </div>
      </div>
      <div className={styles['transaction-view__content']}>{children}</div>
    </Card>
  );
};

export default LootboxTransactionProgress;
