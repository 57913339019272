import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

import Tooltip from 'components/tooltip/Tooltip';

interface IGameLabelPanel extends PropsWithChildren {
  label: string;
  labelClassName?: string;
  className?: string;
  tooltip?: string;
}

const GameLabelPanel = ({ label, labelClassName, className, tooltip, children }: IGameLabelPanel) => {
  return (
    <div
      className={twMerge(
        'tw-flex tw-flex-col tw-gap-1.5 tw-rounded-xl tw-bg-backgroundColorMedium tw-p-4 xl:tw-w-[calc(50%-5px)]',
        className
      )}
    >
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-x-2">
        <span
          className={twMerge(
            'tw-line-clamp-1 tw-text-base/[18px] tw-font-semibold tw-text-menuLinkColor',
            labelClassName
          )}
        >
          {label}
        </span>
        {tooltip && (
          <Tooltip
            className="tw-h-5"
            iconClassName="!tw-text-menuLinkColor"
            contentClassName="tw-text-xs sm:tw-text-sm tw-p-3 tw-rounded-lg tw-max-w-[120px] sm:tw-max-w-[280px]"
            text={tooltip}
            position="right"
          />
        )}
      </div>
      {children}
    </div>
  );
};

export default GameLabelPanel;
