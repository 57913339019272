import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addErrorMessageAction, addSuccessMessageAction } from '../actions/global-actions';
import { appendRedeemAssetAction } from '../actions/redeem.action';
import { ERROR_MESSAGES } from '../constants/error-messages.constant';
import { SUCCESS_MESSAGES } from '../constants/success-messages.constant';
import { IState } from '../models/reducers/i-state.interface';
import { claimCouponService, registerCodeService } from '../services/claim-coupon.service';
import { getApiErrors } from '../utils/get-api-errors.utils';
import Web3Singleton from '../web3Singleton';
import { useEnv } from './useEnv';
import { useHomePageNavigate } from './useHomePageNavigate';
import { useQuery } from './useQuery';

export function useClaimCoupon() {
  const codeQueryKey = 'code';
  const { t } = useTranslation('notifications');
  const { redeemCampaignId, redeemNftId, redeemNftShares, redeemGleamUrl } = useEnv();
  const asset = useSelector(({ redeem }: IState) => redeem.asset);
  const address = useSelector(({ wallet }: IState): string => wallet.address);
  const dispatch = useDispatch();
  const query = useQuery();
  const { navigateToHomePage } = useHomePageNavigate();
  const [couponCode, setCouponCode] = useState<string>('');
  const [claimingInProgress, setClaimingInProgress] = useState<boolean>(false);
  const [claimingFinished, setClaimingFinished] = useState<boolean>(false);
  const [transactionHash, setTransactionHash] = useState<string>('');
  const lgoWeb3 = Web3Singleton.getInstance();
  const showGleam = redeemGleamUrl && !query.get(codeQueryKey);

  useEffect(() => {
    const codeQuery = query.get(codeQueryKey);

    if (codeQuery) {
      setCouponCode(codeQuery);
    }
  }, [redeemGleamUrl, query]);

  useEffect(() => {
    dispatch(appendRedeemAssetAction(redeemNftId));
  }, []);

  const registerCode = async () => {
    setClaimingInProgress(true);
    setClaimingFinished(false);

    try {
      await registerCodeService({
        wallet_address: address,
        giveaway_id: redeemCampaignId,
        code: couponCode,
      });

      setClaimingInProgress(false);
      setClaimingFinished(true);
      dispatch(addSuccessMessageAction(t(SUCCESS_MESSAGES.codeRegistrationSuccess)));
      navigateToHomePage();
    } catch (error: any) {
      if (error && Object.hasOwn(error, 'response')) {
        getApiErrors(error.response).forEach((responseError) => dispatch(addErrorMessageAction(responseError)));
        setClaimingInProgress(false);

        return;
      }

      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
      setClaimingInProgress(false);
    }
  };

  const onClaim = async () => {
    if (!showGleam) {
      registerCode();

      return;
    }

    setTransactionHash('');

    try {
      setClaimingInProgress(true);
      setClaimingFinished(false);

      const claimResponse = await claimCouponService({
        address,
        code: couponCode!,
      });
      const transactionHash = claimResponse.data.transaction_hash;

      setTransactionHash(transactionHash);

      await lgoWeb3.getTransactionStatus(transactionHash);

      setClaimingInProgress(false);
      setClaimingFinished(true);
    } catch (error: any) {
      if (typeof error === 'string') {
        dispatch(addErrorMessageAction(error));
        setClaimingInProgress(false);

        return;
      }

      if (error && Object.hasOwn(error, 'response')) {
        getApiErrors(error.response).forEach((responseError) => dispatch(addErrorMessageAction(responseError)));
        setClaimingInProgress(false);

        return;
      }

      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
      setClaimingInProgress(false);
    }
  };

  const onProgressModalClose = () => {
    setClaimingInProgress(false);
  };

  const onSuccessModalClose = () => {
    setClaimingFinished(false);
  };

  return {
    asset,
    couponCode,
    claimingInProgress,
    claimingFinished,
    nftShares: redeemNftShares,
    redeemGleamUrl,
    showGleam,
    transactionHash,
    setCouponCode,
    onClaim,
    onProgressModalClose,
    onSuccessModalClose,
  };
}
