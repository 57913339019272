import React, { ReactElement, Suspense, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { IVenue } from 'models/i-venue.interface';
import { IState } from 'models/reducers/i-state.interface';

import { publicSaleIn } from 'constants/Counters';
import { ROUTES } from 'constants/routes.constant';

import { searchBuildingAction } from 'actions/search.action';
import { clearVenueAction, fetchVenuesAction, selectVenueAction } from 'actions/venues.action';

import Button from 'components/buttons/Button';
import FullScreenLoadingSpinner from 'components/fullScreenLoadingSpinner/FullScreenLoadingSpinner';
import MainMapComponent from 'components/map/MainMapComponent';
import TopNavWrapper from 'components/nav/TopNavWrapper';
import { useNftCardBackData } from 'components/nftCard/_new/hooks';
import NftCard from 'components/nftCard/_new/nftCard/NftCard';
import NftCardPanel from 'components/nftCard/_new/nftCardPanel/NftCardPanel';
import { INftCardAsset } from 'components/nftCard/_new/types';
import Page from 'components/page/Page';
import PageWrapper from 'components/pageWrapper/PageWrapper';
import SaleCounter from 'components/saleCounter/SaleCounter';
import SearchResults from 'components/searchResults/SearchResults';
import SocialsBar from 'components/socials/SocialsBar';

import IsLoggedIn from 'hoc/IsLoggedIn';

import { useMapEvents } from 'hooks/events/useMapEvents';
import { useMarketEvents } from 'hooks/events/useMarketEvents';
import { useEnv } from 'hooks/useEnv';
import { useHomePageNavigate } from 'hooks/useHomePageNavigate';
import { useQuery } from 'hooks/useQuery';
import { useWindowSize } from 'hooks/useWindowSize';

import { defaultCardWidth, getMapVenueCardScaleValue } from 'utils/get-map-venue-card-scale.utils';
import { useAppDispatch } from 'utils/useAppDispatch';

import RealityMetaverseWhiteLogo from 'assets/logos/reality-metaverse-white.logo.png';
import PoweredByGolemImage from 'assets/powered-by-golem.svg';

import LootboxRoute from './lootbox/LootboxRoute';

const About = React.lazy(() => import('./about/About'));
const AssetPreview = React.lazy(() => import('./assetPreview/AssetPreview'));
const LandLordGo = React.lazy(() => import('./landLordGo/LandLordGo'));
const LeadersRoute = React.lazy(() => import('./leaders/LeadersRoute'));
const ListForSaleRoute = React.lazy(() => import('./listForSale/ListForSaleRoute'));
const MarketRoute = React.lazy(() => import('./market/MarketRoute'));
const ProfileCollected = React.lazy(() => import('./profile/profileCollected/ProfileCollected'));
const ProfileListings = React.lazy(() => import('./profile/profileListings/ProfileListings'));
const ProfileOffers = React.lazy(() => import('./profile/profileOffers/ProfileOffers'));
const ProfileRoute = React.lazy(() => import('./profile/ProfileRoute'));
const ReservationRoute = React.lazy(() => import('./reservation/ReservationRoute'));
const Deals = React.lazy(() => import('./deals/Deals'));
const Spotlight = React.lazy(() => import('./spotlight/Spotlight'));
const TradePreview = React.lazy(() => import('./tradePreview/TradePreview'));
const WhitePaperRoute = React.lazy(() => import('./whitePaper/WhitePaperRoute'));
const ReferralsRoute = React.lazy(() => import('./referrals/ReferralsRoute'));

const MapRoute = () => {
  const location = useLocation();
  const [isOnMapRoute, setIsOnMapRoute] = useState<boolean>();
  const [mapFlyAnimationFinished, setMapFlyAnimationFinished] = useState<boolean>();
  const { lootboxOpen, useMarketMocks } = useEnv();
  const navigate = useNavigate();
  const [width, height] = useWindowSize();
  const dispatch = useAppDispatch();
  const { homePage, mapPageDisabled } = useHomePageNavigate();
  const mapNotAllowed = mapPageDisabled && location.pathname.includes(ROUTES.PORTAL);
  const activeVenue = useSelector((state: IState) => state.venuesActive.activeVenue as IVenue);
  const allVenues = useSelector((state: IState) => state.venues.allVenues);
  const searchData = useSelector((state: IState) => state.search);
  const query = useQuery();
  const selectedVenueFromUrl = useRef(false);

  const { sendMapCardDisplayedEvent, sendMapCardBuyEvent } = useMapEvents();
  const { sendMarketDisplayAuction } = useMarketEvents();

  useEffect(() => {
    if (activeVenue.id) {
      sendMapCardDisplayedEvent({ nftId: activeVenue.id });
    }
  }, [activeVenue.id]);

  useEffect(() => {
    if (mapNotAllowed) {
      navigate(homePage);
    }
  }, [location, width]);

  useEffect(() => {
    const querySearchValue = query.get('s');
    if (querySearchValue) {
      dispatch(searchBuildingAction(querySearchValue));
    }
  }, []);

  useEffect(() => {
    setIsOnMapRoute(location.pathname.includes(ROUTES.PORTAL) || location.pathname.endsWith(ROUTES.HOME));
  }, [location]);

  useEffect(() => {
    if (!allVenues) {
      return;
    }

    if (isOnPortalPage() && !isMobile) {
      const path = location.pathname.split('/');
      const id = path[path.length - 1];
      const selectedVenue = allVenues.find((venue: IVenue) => venue.id.toString() === id);

      if (selectedVenue) {
        dispatch(selectVenueAction(selectedVenue));
        selectedVenueFromUrl.current = true;

        return;
      }

      if (!selectedVenueFromUrl.current && allVenues.length) {
        const firstVenue = allVenues[0];

        navigate(`${ROUTES.PORTAL}/${firstVenue.type}/${firstVenue.id}`);
        dispatch(selectVenueAction(firstVenue));

        return;
      }

      if (allVenues.length) {
        dispatch(selectVenueAction(allVenues[0]));

        return;
      }

      dispatch(clearVenueAction());
      navigate(ROUTES.PORTAL);
    }
  }, [allVenues]);

  useEffect(() => {
    if (mapNotAllowed) {
      return;
    }

    const fetchAssetDebounce = setTimeout(() => {
      dispatch(fetchVenuesAction(searchData));
    }, 250);

    return () => {
      clearTimeout(fetchAssetDebounce);
    };
  }, [searchData, dispatch]);

  const dataIsFetched = () => activeVenue;

  const isOnPortalPage = (): boolean => location.pathname.includes('portal');

  const { data, loading, onCallback } = useNftCardBackData(activeVenue.id);

  const buildVenueBuyCard = (): ReactElement | null => {
    if (!activeVenue?.name) {
      return null;
    }

    const handleClickBuy = () => {
      sendMapCardBuyEvent({ nftId: activeVenue.id });
      sendMarketDisplayAuction();
      navigate(ROUTES.MARKET);
    };

    const style = {
      width: defaultCardWidth * getMapVenueCardScaleValue(width, height),
      transform: mapFlyAnimationFinished ? 'translateX(0)' : 'translateX(140%)',
      transition: 'transform 0.5s ease',
      perspective: 800,
    };

    const adaptAsset = (asset: IVenue): INftCardAsset => ({
      id: asset.id,
      category: asset.category,
      animationUrl: asset.animationUrl,
      imageUrl: asset.imageUrl,
      markerUrl: asset.iconUrl,
      name: asset.name,
      address: asset.address,
      mintedShares: asset.totalShares,
      ...data,
    });

    return (
      <div style={style}>
        <NftCard
          asset={adaptAsset(activeVenue)}
          onForceToggle={!mapFlyAnimationFinished}
          onCallback={onCallback}
          loading={loading}
        >
          <NftCardPanel className="tw-p-2.5">
            <Button size="medium" onClick={handleClickBuy}>
              Buy
            </Button>
          </NftCardPanel>
        </NftCard>
      </div>
    );
  };

  return (
    <div className="map-route-container">
      {dataIsFetched() && <MainMapComponent onFlyAnimationFinished={setMapFlyAnimationFinished} />}
      <Suspense fallback={<FullScreenLoadingSpinner loading opaqueBackground noFadeIn />}>
        <Routes>
          <Route path="/portal/*" element={<></>} />
          <Route path="/about" element={<About />} />
          <Route path="/landlordgo" element={<LandLordGo />} />
          <Route
            path="/profile"
            element={
              <IsLoggedIn>
                <ProfileRoute />
              </IsLoggedIn>
            }
          >
            <Route path="collected" element={<ProfileCollected />} />
            <Route
              path="offers"
              element={useMarketMocks ? <Navigate to="/" state={{ from: location }} replace /> : <ProfileOffers />}
            />
            <Route
              path="listings"
              element={useMarketMocks ? <Navigate to="/" state={{ from: location }} replace /> : <ProfileListings />}
            />
          </Route>
          <Route path="/market" element={<MarketRoute />} />
          {lootboxOpen && <Route path="/lootbox" element={<LootboxRoute />} />}
          <Route path="/white-paper/*" element={<WhitePaperRoute />} />
          <Route path="/leaders/*" element={<LeadersRoute />} />
          <Route path="/deals" element={<Deals />} />
          <Route path="/spotlight" element={<Spotlight />} />
          <Route path="/referrals" element={<ReferralsRoute />} />
          <Route
            path="/list-for-sale/:token_id/:saleType"
            element={
              useMarketMocks ? (
                <Navigate to={ROUTES.PORTAL} state={{ from: location }} replace />
              ) : (
                <IsLoggedIn>
                  <ListForSaleRoute />
                </IsLoggedIn>
              )
            }
          />
          <Route
            path="/assets/preview/:token_id"
            element={
              <IsLoggedIn>
                <AssetPreview />
              </IsLoggedIn>
            }
          />
          <Route
            path="/trades/preview/:token_id"
            element={useMarketMocks ? <Navigate to="/" state={{ from: location }} replace /> : <TradePreview />}
          />
          <Route path="reservation/:id" element={<ReservationRoute />} />
          <Route path="*" element={<Navigate to={homePage} />} />
        </Routes>
      </Suspense>
      {isOnMapRoute && !isMobile && (
        <Page bannerSticky={false} pageBackground="transparent">
          <PageWrapper className="map-route-page-wrapper" homeFooter={false}>
            <div className="map-route-container__top">
              <TopNavWrapper />
            </div>
            <div className="map-route-container__middle">
              <SearchResults />
              {buildVenueBuyCard()}
            </div>
            <div className="map-route-container__bottom">
              <img
                className="map-route-container__bottom-logo"
                src={RealityMetaverseWhiteLogo}
                alt="reality metaverse logo"
              />
              <img className="map-route-container__bottom-golem-logo" src={PoweredByGolemImage} alt="golem logo" />
              <SaleCounter endAt={publicSaleIn} timerLabel="Private open, Public sale in" />
              <SocialsBar
                order={[
                  { type: 'discord', icon: 'border' },
                  { type: 'instagram', icon: 'border' },
                  { type: 'youtube', icon: 'border' },
                  { type: 'facebook', icon: 'border' },
                  { type: 'twitter', icon: 'border' },
                ]}
                className="tw-ml-12 tw-mr-2 tw-flex tw-list-none tw-items-center"
                iconClassName="tw-pr-4"
              />
            </div>
          </PageWrapper>
        </Page>
      )}
    </div>
  );
};

export default MapRoute;
