import { IFaqItem } from '../types/i-faq-item.interface';

export const LOOTBOX_FAQ_LIST: IFaqItem[] = [
  {
    title: 'What are Reality NFT loot boxes?',
    details:
      'Loot boxes are special packages that contain random Reality fNFTs. These fNFTs can be collected, traded, or held for potential earnings.',
  },
  {
    title: 'Why should I purchase a Reality NFT loot box instead of an individual NFT?',
    details:
      'Buying a loot box gives you a higher chance of getting a rare and valuable fNFT at a lower cost than purchasing on the marketplace.',
  },
  {
    title: 'Can I sell or trade the NFTs I get from loot boxes?',
    details:
      'You can sell or trade the fNFTs you gather from loot boxes as a regular Reality fNFT on compatible NFT marketplaces.',
  },
  {
    title: 'How does the probability system work?',
    details:
      'The probability system determines how rare each fNFT is within a loot box. Each fNFT has a specific chance of being obtained based on its assigned probability percentage.',
  },
  {
    title: 'Are the items in Reality NFT loot boxes randomly generated?',
    details:
      'Yes, the items in Reality NFT loot boxes are randomly generated. The probability rates determine the chances of receiving different fNFTs, making it unpredictable and exciting at the same time.',
  },
  {
    title: 'Are there different versions of loot boxes?',
    details:
      'Reality NFT loot boxes come in two different categories: Silver and Gold. Each version contains fNFTs related to its respective category.',
  },
  {
    title: 'What can I expect to find inside a Reality NFT loot box?',
    details:
      'Inside a Reality NFT loot box, you will discover a unique fNFT related to your selected category. For example, if you choose the Gold loot box, you might receive fNFTs of higher tier like the Eiffel Tower or the Statue of Liberty.',
  },
  {
    title: 'Are the probability rates the same for all NFTs?',
    details: (
      <div>
        No, the rates vary for each fNFT. Some fNFTs have higher chances of being obtained, while others are rare and
        harder to find, more details here:{' '}
        <a
          target="_blank"
          href="https://medium.com/@Realitymeta/the-new-winter-themed-loot-box-ab671efda44d"
          rel="noreferrer"
        >
          https://medium.com/@Realitymeta/the-new-winter-themed-loot-box-ab671efda44d
        </a>
      </div>
    ),
  },
  {
    title: 'Can I collect all the items in a specific category?',
    details:
      'Collecting all the items in a specific category is possible, but collecting the entire set may take multiple purchases.',
  },
  {
    title: 'Can I purchase multiple Reality NFT loot boxes?',
    details:
      'You can purchase multiple Reality NFT loot boxes as long as you have enough RMV tokens to cover the cost and transaction fees.',
  },
  {
    title: 'Is there a guarantee that I will receive a specific item from a loot box?',
    details: (
      <div>
        There is no guarantee of receiving a specific item from a loot box. Assets are randomly generated based on the
        probability rates assigned to each fNFT check full list here:
        <a
          target="_blank"
          href="https://medium.com/@Realitymeta/the-new-winter-themed-loot-box-ab671efda44d"
          rel="noreferrer"
        >
          https://medium.com/@Realitymeta/the-new-winter-themed-loot-box-ab671efda44d
        </a>
      </div>
    ),
  },
  {
    title: 'How can I purchase Reality NFT loot boxes?',
    details: (
      <div>
        To purchase Reality NFT loot boxes: Connect your crypto wallet to our website. Acquire RMV Polygon chain tokens
        from the available sources to cover the loot box price and transaction fees. Choose your loot box version
        (Landmarks, Cities, or Countries). Complete the transaction following the on-screen instructions.
        <br />
        <br />
        Your random fractional NFT from the selected loot box will be added to your account. You can find it on your
        profile page.
      </div>
    ),
  },
  {
    title: 'How can I acquire RMV tokens?',
    details:
      'RMV tokens can be bought directly from our official website and various CEX/DEXs, please make sure to purchase RMV token on Polygon chain.',
  },
  {
    title: 'Where can I find more information about Reality NFT loot boxes?',
    details: (
      <div>
        Additional information about Reality NFT loot boxes can be found on our Medium page. Check the article dedicated
        to the loot boxes that provides further details, and guidelines:
        <a
          target="_blank"
          href="https://medium.com/@Realitymeta/the-new-winter-themed-loot-box-ab671efda44d"
          rel="noreferrer"
        >
          https://medium.com/@Realitymeta/the-new-winter-themed-loot-box-ab671efda44d
        </a>
      </div>
    ),
  },
];

export const LANDING_FAQ_LIST: IFaqItem[] = [
  {
    title: 'What is Reality Metaverse?',
    details: [
      'Reality Metaverse has created the true connection between real life and Metaverse, building a sustainable ecosystem where they allow web3 player-investors to earn royalties from their web2 games through expanding NFT utilities. Reality Metaverse is creating a collection of 2 000+ NFTs, which are divided into fractions with royalties for their owners.',
    ],
  },
  {
    title: 'Who is behind Reality Metaverse?',
    details: [
      'Reality Metaverse is backed by Reality Games, a leading games studio with a track record of developing top-performing games across multiple platforms. Our technical partner is Golem, a respected provider of decentralized computing power.',
      'Our team consists of experienced specialists in the field of Web3, including experts in blockchain technology, NFTs, Metaverse development, and more. Together, we are dedicated to creating a revolutionary new ecosystem that empowers players, developers, and investors alike to participate in and benefit from the growth of the Metaverse.',
    ],
  },
  {
    title: 'How does Reality Metaverse differ from other Metaverse projects?',
    details: [
      'At Reality Metaverse, we are committed to building a sustainable ecosystem that offers long-term value for our community of player-investors. We are dedicated to creating a platform that can support growth and innovation for years to come.',
      'Our background as a leading games studio and our partnerships with industry experts like Golem provide us with a unique advantage in the space. We have a proven track record of success in developing high-quality games and leveraging cutting-edge technologies, and we bring that expertise to everything we do at Reality Metaverse.',
      'Ultimately, our goal is to create an ecosystem that offers real utility and value for our community, both in terms of the games and experiences we offer and the long-term potential of our RMV token. We believe that by focusing on sustainability and innovation, we can differentiate ourselves from other Metaverse projects and create a platform that truly stands the test of time.',
    ],
  },
  {
    title: 'How to buy $RMV and where?',
    details: [
      'RMV token can be bought on various Centralised and Decentralised exchanges, such as: KuCoin, ByBit, MexC, Gate, UniSwap, and also directly via our website using onramp solution - you can simply pay by your credit/debit card!',
    ],
  },
];
