import { FC } from 'react';

import { ReactComponent as HourglassSvg } from 'assets/hourglass.svg';

import { ComingSoonBadge } from '../badges/Badges';

interface INftCardBackPlaceholderProps {
  text: string;
  name?: string | null;
}

const NftCardBackPlaceholder: FC<INftCardBackPlaceholderProps> = ({ text, name }) => {
  return (
    <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center">
      <HourglassSvg className="tw-mb-2.5" />
      <ComingSoonBadge />
      <span className="tw-mt-5 tw-text-center tw-text-lg tw-font-semibold tw-text-white">
        {text} <br />
        {name && `for ${name} NFT`}
      </span>
    </div>
  );
};

export default NftCardBackPlaceholder;
