import { AxiosResponse } from 'axios';

import { ITranslationReducer } from '../models/reducers/i-translation-reducer.interface';

import { RTLM_MULTIPLIER, USDC_MULTIPLIER } from '../mocks/market.mocks';
import { MESSAGES } from '../mocks/translation.mocks';
import { getApiAxiosRequestMock } from './mocks.service';

export const getRMVMultiplier = async (): Promise<AxiosResponse<number, any>> => {
  return getApiAxiosRequestMock(RTLM_MULTIPLIER);
};

export const getUsdcMultiplier = async (): Promise<AxiosResponse<number, any>> => {
  return getApiAxiosRequestMock(USDC_MULTIPLIER);
};

export const getTranslation = async (): Promise<AxiosResponse<Pick<ITranslationReducer, 'messages'>, any>> => {
  return getApiAxiosRequestMock(MESSAGES);
};
