import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

interface IGameChipProps extends PropsWithChildren {
  className?: string;
  variant?: 'default' | 'yellow' | 'green' | 'blue' | 'border';
}

const GameTag = ({ variant = 'default', className, children }: IGameChipProps) => {
  return (
    <div
      className={twMerge(
        'tw-max-w-fit tw-rounded-md tw-border-[1px] tw-border-solid tw-p-2 tw-text-center tw-text-sm tw-uppercase !tw-leading-none lg:tw-text-base',
        variant === 'default' &&
          'tw-border-backgroundColorLight tw-bg-backgroundColorLight tw-font-semibold tw-text-white ',
        variant === 'yellow' && 'tw-border-orangeLight tw-bg-orangeLight tw-font-bold',
        variant === 'green' && 'tw-border-green tw-bg-green tw-font-bold',
        variant === 'blue' && ' tw-border-skyBlue tw-bg-skyBlue tw-font-semibold tw-text-white',
        variant === 'border' && 'tw-border-white tw-font-semibold tw-text-white',
        className
      )}
    >
      {children}
    </div>
  );
};

export default GameTag;
