import { FormEvent, ReactNode, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { addErrorMessageAction, setShowSelectWalletModal } from '../../actions/global-actions';
import { ERROR_MESSAGES } from '../../constants/error-messages.constant';
import { useIsWeb3Allowed } from '../../hooks/useIsWeb3Allowed';

interface IFormPops {
  children: ReactNode;
  className?: string;
  onSubmit: () => void;
  requireWeb3?: boolean;
  triggerValidation?: boolean;
}

const Form = ({ children, className, onSubmit, triggerValidation = false, requireWeb3 = false }: IFormPops) => {
  const { t } = useTranslation('notifications');
  const formRef = useRef<HTMLFormElement>(null);
  const dispatch = useDispatch();
  const web3Allowed = useIsWeb3Allowed();

  useEffect(() => {
    if (triggerValidation) {
      validateForm();
    }
  }, [triggerValidation]);

  const formSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (!requireWeb3) {
      onSubmit();

      return;
    }

    if (!web3Allowed) {
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.unauthorized)));
      dispatch(setShowSelectWalletModal(true));

      return;
    }

    onSubmit();
  };

  const validateForm = (): boolean => {
    const errors = formRef.current?.querySelectorAll('[data-form-field-invalid="true"]');

    if (errors?.length) {
      errors.forEach((errorContainer) => errorContainer.classList.add('show'));

      return false;
    }

    return true;
  };

  return (
    <form className={className || ''} ref={formRef} onSubmit={formSubmit}>
      {children}
    </form>
  );
};

export default Form;
