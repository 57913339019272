import { AxiosResponse } from 'axios';

import { FETCH_REDEEM_ASSET } from '../actions/actionNames/redeemActionNames';
import { IDispatchAction } from '../models/dispatch-action.interface';
import { IRedeemReducer } from '../models/reducers/i-redeem-reducer.interface';

const INITIAL_STATE: IRedeemReducer = {
  loading: false,
  error: false,
  fetched: false,
  asset: null,
};

export const redeemReducer = (state = INITIAL_STATE, action: IDispatchAction<AxiosResponse>): IRedeemReducer => {
  switch (action.type) {
    case FETCH_REDEEM_ASSET + '_PENDING':
      return { ...state, loading: true, error: false, fetched: false };
    case FETCH_REDEEM_ASSET + '_REJECTED':
      return { ...state, loading: false, error: true, fetched: false };
    case FETCH_REDEEM_ASSET + '_FULFILLED': {
      return {
        ...state,
        loading: false,
        error: false,
        fetched: true,
        asset: action.payload.data,
      };
    }
    default:
      return state;
  }
};
