import { MouseEvent } from 'react';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as NftCardFlipSvg } from 'assets/nft-card/nft-card-flip.svg';

import { useNftCardSize } from '../hooks';
import { useNftCardContext } from '../nftCardContext/NftCardContext';

const NftCardToggleButton = () => {
  const { onToggle } = useNftCardContext();
  const { isCardLarge, isCardMedium, isCardSmall } = useNftCardSize();
  const isNotLargeCard = isCardMedium || isCardSmall;

  const handleToggle = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onToggle();
  };

  return (
    <button
      title="Click me!"
      className={twMerge(
        'button-reset tw-absolute tw-z-10',
        isNotLargeCard && 'tw-right-2.5 tw-top-2',
        isCardLarge && 'tw-right-3 tw-top-3'
      )}
      onClick={handleToggle}
    >
      <NftCardFlipSvg />
    </button>
  );
};

export default NftCardToggleButton;
