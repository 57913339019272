import { AxiosResponse } from 'axios';
import { generateUUID } from 'three/src/math/MathUtils';

import { IDispatchAction } from '../models/dispatch-action.interface';
import { ITopOffersReducer } from '../models/reducers/i-top-offers-reducer.interface';
import { ITopOffersAsset, ITopOffersResponse } from '../models/top-offers/i-top-offers';

import { DEFAULTS } from '../constants/defaults.constant';

import {
  FETCH_TOP_OFFERS,
  FETCH_TOP_OFFERS_ASSETS,
  REFRESH_TOP_OFFERS_UPDATE_TOKEN,
  RESET_TOP_OFFERS_DATA,
} from '../actions/actionNames/topOffersActionNames';

const INITIAL_STATE: ITopOffersReducer = {
  updateToken: generateUUID(),
  offers: [],
  offersAssets: [],
  offersLoading: false,
  offersAssetsLoading: false,
  offersValidFrom: null,
  offersValidUntil: null,
};

export const topOffersReducer = (state = INITIAL_STATE, action: IDispatchAction): ITopOffersReducer => {
  switch (action.type) {
    case RESET_TOP_OFFERS_DATA:
      return { ...INITIAL_STATE };
    case REFRESH_TOP_OFFERS_UPDATE_TOKEN:
      return { ...state, updateToken: generateUUID() };
    case FETCH_TOP_OFFERS + '_PENDING':
      return { ...state, offersLoading: true };
    case FETCH_TOP_OFFERS + '_REJECTED':
      return { ...state, offersLoading: false };
    case FETCH_TOP_OFFERS + '_FULFILLED': {
      const responseItems = (action as IDispatchAction<AxiosResponse<ITopOffersResponse>>).payload.data.results;

      if (!responseItems?.length) {
        return { ...state, offers: [], offersValidFrom: null, offersValidUntil: null, offersLoading: false };
      }

      return {
        ...state,
        offers: responseItems[0].assets.map(({ sale }) => sale),
        offersValidFrom: responseItems[0].valid_from * DEFAULTS.tradeTimestampMultiplier,
        offersValidUntil: responseItems[0].valid_until * DEFAULTS.tradeTimestampMultiplier,
        offersLoading: false,
      };
    }
    case FETCH_TOP_OFFERS_ASSETS + '_PENDING':
      return { ...state, offersAssetsLoading: true };
    case FETCH_TOP_OFFERS_ASSETS + '_REJECTED':
      return { ...state, offersAssetsLoading: false };
    case FETCH_TOP_OFFERS_ASSETS + '_FULFILLED': {
      const offersAssets = (action as IDispatchAction<AxiosResponse<ITopOffersAsset[]>>).payload.data;

      return {
        ...state,
        offersAssets,
        offersAssetsLoading: false,
      };
    }
    default:
      return state;
  }
};
