import { ILootboxRewardMetadata } from 'models/lootbox/i-lootbox-reward-metadata.interface';

import NftCardMedium from 'components/nftCard/nftCardMedium/NftCardMedium';
import NftCardWrapper from 'components/nftCard/nftCardWrapper/NftCardWrapper';

import styles from './LootboxOpenSuccessReward.module.scss';

interface ILootboxOpenSuccessRewardProps {
  reward: ILootboxRewardMetadata;
  className?: string;
}

const LootboxOpenSuccessReward = ({ reward, className = '' }: ILootboxOpenSuccessRewardProps) => {
  return (
    <div className={className}>
      <NftCardWrapper className={styles['lootbox-reward-card__wrapper']}>
        <NftCardMedium
          className={styles['lootbox-reward-card']}
          backgroundUrl={reward.background || ''}
          backgroundAnimationUrl={reward.backgroundAnimation || ''}
          name={reward.name || '-'}
          address={reward.address || '-'}
          marketUrl={reward.marketUrl || ''}
          category={reward.category || '-'}
          id={+reward.tokenId}
          shares={reward.quantityPerReward}
        />
      </NftCardWrapper>
    </div>
  );
};

export default LootboxOpenSuccessReward;
