interface IIconProps {
  id: string;
  className?: string;
  altText?: string;
}

const Icon = ({ id, className, altText }: IIconProps) => {
  return (
    <svg className={className}>
      {altText && <title>{altText}</title>}
      <use xlinkHref={`#icon-sprite-${id}`} />
    </svg>
  );
}

export default Icon;
