import { getEtherscanUrl } from 'services/network.service';

import Button from 'components/buttons/Button';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';

import { centralEllipsis } from 'utils/ellipsis.utils';

export interface IGameConfirmationModalProps {
  title: string;
  transactionHash?: string;
}

const CheckoutProgressSection = ({ title, transactionHash }: IGameConfirmationModalProps) => {
  return (
    <div className="tw-flex tw-gap-4">
      <LoadingSpinner />
      <div>
        <p className="tw-text-2xl tw-text-white">{transactionHash ? 'Transaction in progress' : title}</p>
        {transactionHash ? (
          <div className="tw-flex tw-items-center tw-gap-1">
            <p className="tw-text-base tw-text-menuLinkColor">Transaction ID:</p>
            <a
              target="_blank"
              rel="noreferrer"
              className="tw-text-menuLinkColor tw-underline"
              href={getEtherscanUrl(transactionHash)}
            >
              {centralEllipsis(transactionHash)}
            </a>
          </div>
        ) : (
          <p className="tw-text-base tw-text-menuLinkColor">
            You'll be asked to approve this transaction from your wallet.
          </p>
        )}
      </div>
    </div>
  );
};

const GameConfirmationModal = ({ title, transactionHash }: IGameConfirmationModalProps) => {
  return (
    <div className="tw-w-[465px] tw-max-w-[90vw]">
      <div className="tw-flex tw-flex-grow tw-flex-col tw-gap-8">
        <CheckoutProgressSection title={title} transactionHash={transactionHash} />
        <Button
          type="button"
          color="primary"
          className="tw-w-full"
          size="medium"
          text={transactionHash ? 'Waiting for confirmation...' : 'Waiting for approval...'}
          disabled
        />
      </div>
    </div>
  );
};

export default GameConfirmationModal;
