import axios, { AxiosResponse } from 'axios';
import Cookies from 'universal-cookie/es6';

import { IProfile } from 'models/profile/i-profile.interface';

import { CookiesKeys } from 'enums/cookies-keys.enum';

export const getProfile = async (): Promise<AxiosResponse<IProfile>> => {
  return axios.get(`/api/v1/userprofile/`, {
    headers: {
      'X-CSRFToken': new Cookies().get(CookiesKeys.csrftoken),
    },
  });
};

export const patchProfile = async (profileData: FormData): Promise<AxiosResponse<IProfile>> => {
  return axios.patch(`/api/v1/userprofile/`, profileData, {
    headers: {
      'X-CSRFToken': new Cookies().get(CookiesKeys.csrftoken),
    },
  });
};
