import { ReactNode } from 'react';

import ScrollInAnimationItem from '../../../components/scrollInAnimationItem/ScrollInAnimationItem';

import FlyMoney from '../../../assets/about/about-fly-money.png';
import ArrowLeft from '../../../assets/about/arrow-left.png';
import ArrowRight from '../../../assets/about/arrow-right.png';
import MoneyPile from '../../../assets/game-onboarding/money-pile.png';
import NFTCard from '../../../assets/game-onboarding/nft-card.png';

import styles from './GameOnboardingRoyaltiesDistributionSection.module.scss';

interface IRoyaltiesItemProps {
  value: string;
  children: ReactNode;
}

const RoyaltiesItem = ({ value, children }: IRoyaltiesItemProps) => {
  return (
    <li className={styles['royalties-item']}>
      <div className={styles['royalties-item__value']}>{value}</div>
      <div className={styles['royalties-item__title']}>{children}</div>
    </li>
  );
};

const GameOnboardingRoyaltiesDistributionSection = () => {
  return (
    <div className="tw-relative tw-flex tw-w-full tw-flex-col tw-items-center tw-pt-[50px] sm:tw-pt-[80px] lg:tw-pt-[120px]">
      <ScrollInAnimationItem delay="0.2s">
        <h2 className="tw-mb-[20px] tw-text-center tw-text-[32px] tw-uppercase tw-text-white sm:tw-text-[44px] lg:tw-text-5xl">
          earn <span className="tw-text-turquoise">royalties</span>
        </h2>
      </ScrollInAnimationItem>
      <ScrollInAnimationItem delay="0.3s" className="tw-w-[952px] tw-max-w-full">
        <p className="tw-mb-[40px] tw-text-center tw-text-lg tw-text-white sm:tw-text-[20px] lg:tw-text-[22px]">
          Connect your wallet to{' '}
          <a className="tw-text-turquoise" href="http://realitymeta.io/" target="_blank" rel="noopener noreferrer">
            Realitymeta.io
          </a>{' '}
          and earn real-money royalties to exchange for cash or purchase other famous locations as NFTs!
        </p>
      </ScrollInAnimationItem>
      <ScrollInAnimationItem className="sm:tw-hidden">
        <img src={ArrowLeft} alt="" />
      </ScrollInAnimationItem>
      <div className="tw-flex tw-w-[1370px] tw-max-w-full tw-flex-wrap tw-justify-center tw-gap-[20px] lg:tw-justify-between lg:tw-gap-7.5">
        <div className="tw-flex tw-w-[100%] tw-flex-1 tw-flex-col">
          <ScrollInAnimationItem className="lg:tw--pr-15 tw-mb-[20px] tw-ml-auto tw-hidden sm:tw-block sm:tw-pr-7.5">
            <img src={ArrowLeft} alt="" />
          </ScrollInAnimationItem>
          <ScrollInAnimationItem delay="0.3s" threshold={0.3}>
            <div className={styles['section-left']}>
              <h3 className="tw-mb-[10px] tw-text-[32px] tw-uppercase tw-text-white sm:tw-mb-7.5 sm:tw-text-[38px]">
                <span className="tw-text-turquoise">direct</span> royalties
              </h3>
              <ul className="tw-pl-0">
                <RoyaltiesItem value="20%">
                  Reality USD
                  <br />
                  Stream
                </RoyaltiesItem>
              </ul>
            </div>
          </ScrollInAnimationItem>
        </div>
        <div className="tw-order-3 tw-flex tw-flex-1 tw-flex-col tw-items-center md:tw-mr-7.5 lg:tw-order-2">
          <ScrollInAnimationItem threshold={0.4}>
            <img
              className="tw-h-auto tw-w-[274px] tw-max-w-full sm:tw-w-[513px]"
              src={FlyMoney}
              alt=""
              width="513"
              height="494"
            />
          </ScrollInAnimationItem>
          <ScrollInAnimationItem className={styles['nft-card-wrapper']} threshold={0.4}>
            <img
              className="tw-mt-[-100px] tw-w-[347px] sm:tw-mt-[-140px]"
              src={NFTCard}
              alt="Reality NFT venue card"
              width="347"
              height="431"
            />
          </ScrollInAnimationItem>
        </div>
        <div className="tw-order-2 tw-flex tw-w-[100%] tw-flex-1 tw-flex-col lg:tw-order-3">
          <ScrollInAnimationItem className="tw-mb-[20px] tw-hidden sm:tw-ml-7.5 sm:tw-block lg:tw--ml-15">
            <img src={ArrowRight} alt="" />
          </ScrollInAnimationItem>
          <ScrollInAnimationItem delay="0.3s" threshold={0.3}>
            <h3 className="tw-mb-[10px] tw-text-[32px] tw-uppercase tw-text-white sm:tw-mb-7.5 sm:tw-text-[38px]">
              <span className="tw-text-turquoise">indirect</span> royalties
            </h3>
            <ul className="tw-pl-0">
              <RoyaltiesItem value="20%">
                NFT trading
                <br />
                royalties
              </RoyaltiesItem>
              <RoyaltiesItem value="20%">
                NFT
                <br />
                auctions
              </RoyaltiesItem>
              <RoyaltiesItem value="30%">
                <span className="tw-uppercase">Loot Box</span>
                <br />
                <span className="tw-uppercase">sales in App</span>
                <br />
                <span className="tw-uppercase">purchases</span>
              </RoyaltiesItem>
            </ul>
          </ScrollInAnimationItem>
        </div>
      </div>
      <img
        className="tw-absolute tw-bottom-0 tw-h-[210px] tw-overflow-hidden"
        src={MoneyPile}
        alt="Pile of money"
        width="1920"
        height="210"
      />
    </div>
  );
};

export default GameOnboardingRoyaltiesDistributionSection;
