import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Web3Singleton from 'web3Singleton';

import { IAssetDetails } from 'models/assets/i-asset-details.interface';
import { ILootboxOpenConfig } from 'models/lootbox/i-lootbox-open-config.interface';
import { ILootboxRewardMetadata } from 'models/lootbox/i-lootbox-reward-metadata.interface';
import { IState } from 'models/reducers/i-state.interface';

import { LootboxOpenStep } from 'enums/lootbox/lootbox-open-step.enum';

import {
  setLootboxOpenConfigAction,
  setLootboxOpenErrorMessageAction,
  setLootboxOpenRewardsAction,
  setLootboxOpenStepAction,
} from 'actions/lootbox.actions';

import { fetchAssetPreviewDetailsService } from 'services/venues.service';

import { ILootboxParams, useLootboxEvents } from 'hooks/events/useLootboxEvents';

import { getLootboxTransactionError } from 'utils/get-lootbox-transaction-error.util';

export const useLootboxOpen = () => {
  const { t } = useTranslation('notifications');
  const lgoWeb3 = Web3Singleton.getInstance();
  const dispatch = useDispatch();
  const openConfig = useSelector((state: IState) => state.lootbox.openConfig);

  const { sendLootboxOpenErrorEvent, sendLootboxOpenEvent, sendLootboxOpenSuccessEvent } = useLootboxEvents();

  const setRewards = (rewards: ILootboxRewardMetadata[]) => {
    dispatch(setLootboxOpenRewardsAction(rewards));
  };

  const setErrorMessage = (errorMessage: string) => {
    dispatch(setLootboxOpenErrorMessageAction(errorMessage));
  };

  const setOpenStep = (step: LootboxOpenStep) => {
    dispatch(setLootboxOpenStepAction(step));
  };

  const stopLootboxOpen = () => {
    dispatch(setLootboxOpenStepAction(null));
  };

  const restartLootboxOpen = () => {
    if (!openConfig) {
      return;
    }

    startLootboxOpen(openConfig);
  };

  const startLootboxOpen = async ({ assetId, address, openingAnimation, name, quantity }: ILootboxOpenConfig) => {
    const lootboxParams: ILootboxParams = {
      lootboxId: assetId,
      lootboxAmount: quantity,
    };

    sendLootboxOpenEvent(lootboxParams);
    setOpenStep(LootboxOpenStep.inProgress);
    dispatch(
      setLootboxOpenConfigAction({
        assetId,
        address,
        openingAnimation,
        name,
        quantity,
      })
    );

    try {
      const transaction = await lgoWeb3.openLootbox({
        id: assetId,
        quantity,
        address,
      });

      if (!transaction.erc1155Rewards) {
        setOpenStep(LootboxOpenStep.finished);
        sendLootboxOpenSuccessEvent({ ...lootboxParams, drawnRewards: [] });

        return;
      }

      const mappedRewards = await Promise.all(
        transaction.erc1155Rewards.map(async (reward) => {
          let asset: IAssetDetails | null = null;

          try {
            asset = (await fetchAssetPreviewDetailsService(reward.tokenId.toString())).data;
          } catch (error: any) {
            setErrorMessage(t(getLootboxTransactionError(error?.message)));
            setOpenStep(LootboxOpenStep.failed);
            sendLootboxOpenErrorEvent({ ...lootboxParams, message: error?.message });
          }

          const metadata: ILootboxRewardMetadata = {
            tokenId: reward.tokenId as string,
            contractAddress: reward.contractAddress as string,
            quantityPerReward: reward.quantityPerReward as string,
            backgroundAnimation: asset?.animation_url,
            background: asset?.asset_original_url,
            address: asset?.address,
            marketUrl: asset?.marker_url,
            category: asset?.traits.find(({ type }) => type === 'category')?.value,
            name: asset?.name,
          };

          return metadata;
        })
      );

      setRewards(mappedRewards);
      setOpenStep(LootboxOpenStep.finished);
      sendLootboxOpenSuccessEvent({ ...lootboxParams, drawnRewards: mappedRewards.map(({ tokenId }) => tokenId) });
    } catch (error: any) {
      setErrorMessage(t(getLootboxTransactionError(error?.message)));
      setOpenStep(LootboxOpenStep.failed);
      sendLootboxOpenErrorEvent({ ...lootboxParams, message: error?.message });
    }
  };

  return {
    stopLootboxOpen,
    startLootboxOpen,
    restartLootboxOpen,
  };
};
