import { useDispatch } from 'react-redux';

import { walletSignOut } from '../actions/wallet.action';

export const useSignout = (): (() => Promise<void>) => {
  const dispatch = useDispatch();

  const signOut = async () => {
    dispatch(walletSignOut());
  };

  return signOut;
};
