import { useSelector } from 'react-redux';

import { ReactComponent as Apple } from '../../../assets/login-accounts/apple.svg';
import { ReactComponent as Discord } from '../../../assets/login-accounts/discord.svg';
import { ReactComponent as Facebook } from '../../../assets/login-accounts/facebook.svg';
import { ReactComponent as Github } from '../../../assets/login-accounts/github.svg';
import { ReactComponent as Google } from '../../../assets/login-accounts/google.svg';
import { ReactComponent as Line } from '../../../assets/login-accounts/line.svg';
import { ReactComponent as Linkein } from '../../../assets/login-accounts/linkedin.svg';
import { ReactComponent as Reddit } from '../../../assets/login-accounts/reddit.svg';
import { ReactComponent as Talk } from '../../../assets/login-accounts/talk.svg';
import { ReactComponent as Twitch } from '../../../assets/login-accounts/twitch.svg';
import { ReactComponent as Twitter } from '../../../assets/login-accounts/twitter.svg';
import { ReactComponent as Wechat } from '../../../assets/login-accounts/wechat.svg';
import MetaMask from '../../../assets/menu/icons/metamask.svg';
import Torus from '../../../assets/menu/icons/torus.svg';
import { IState } from '../../../models/reducers/i-state.interface';
import { Web3Providers } from '../../../reducers/web3.reducer';
import ModalPortal from '../ModalPortal';

import styles from './SelectWalletModal.module.scss';

interface ISelectWalletModal {
  onClose: () => void;
  onMetaMaskSelect: () => void;
  onTorusSelect: () => void;
}

const SelectWalletModal = ({ onClose, onMetaMaskSelect, onTorusSelect }: ISelectWalletModal) => {
  const currentProvider = useSelector(({ web3 }: IState) => web3.currentProvider);
  const isMetaMaskCurrentProvider = currentProvider === Web3Providers.metamask;

  return (
    <ModalPortal customStyles={{ maxWidth: '360px', padding: '20px' }} onClose={() => onClose()}>
      <div className={`${styles['login-providers']}`}>
        <div
          className={`${!isMetaMaskCurrentProvider ? styles['login-providers--disabled'] : ''}`}
          onClick={() => isMetaMaskCurrentProvider && onMetaMaskSelect()}
        >
          <img className={`${styles['login-providers__icon']}`} src={MetaMask} alt="" />
          <div className={`${styles['login-providers__badge']}`}>Popular</div>
          <p className={`${styles['login-providers__title']}`}>Metamask</p>
          <p className={`${styles['login-providers__subtitle']}`}>Connect to your MetaMask wallet</p>
        </div>
        <div onClick={() => onTorusSelect()}>
          <img className={`${styles['login-providers__icon']}`} src={Torus} alt="" />
          <div className={`${styles['login-providers__badge']}`}>Recommended</div>
          <p className={`${styles['login-providers__title']}`}>Torus</p>
          <p className={`${styles['login-providers__subtitle']}`}>
            Sign in using social account in using social account
          </p>
          <div className={`${styles['login-providers__accounts-icon']}`}>
            <Google />
            <Facebook />
            <Twitter />
            <Discord />
            <Line />
            <Reddit />
            <Apple />
            <Github />
            <Twitch />
            <Linkein />
            <Wechat />
            <Talk />
          </div>
        </div>
      </div>
    </ModalPortal>
  );
};
export default SelectWalletModal;
