import { NETWORK_SETTINGS } from '../constants/network.constant';

import Web3Singleton from '../web3Singleton';

export const networkSettingsService = () => {
  return NETWORK_SETTINGS;
};

export const getEtherscanUrl = (transactionHash: string) => {
  const etherscanUrl = process.env.REACT_APP_ETHERSCAN_URL;

  if (!etherscanUrl || !transactionHash) {
    return '#';
  }

  return `${etherscanUrl}/tx/${transactionHash}`;
};

export const getEtherscanAddressUrl = (transactionHash: string) => {
  const etherscanUrl = process.env.REACT_APP_ETHERSCAN_URL;

  if (!etherscanUrl || !transactionHash) {
    return '#';
  }

  return `${etherscanUrl}/address/${transactionHash}`;
};

export const currentNetworkSettingsService = async () => {
  const lgoWeb3 = Web3Singleton.getInstance();
  const network = await lgoWeb3.getChainId();

  if (!network && network !== 0) {
    return null;
  }

  return networkSettingsService();
};

export const isCorrectNetwork = async () => {
  const lgoWeb3 = Web3Singleton.getInstance();
  const network = await lgoWeb3.getChainId();
  const appNetworkId = +process.env.REACT_APP_NETWORK_ID!;

  return appNetworkId === network;
};
