import { Web3ReactProvider } from '@web3-react/core';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Web3 from 'web3';

import App from './App';
import { ScrollProvider } from './contexts/ScrollContext';
import './i18n';
import reportWebVitals from './reportWebVitals';
import { setupMockServiceWorker } from './setupMockServiceWorker';
import store from './store/store';

function getLibrary(provider) {
  return new Web3(provider);
}

const container = document.getElementById('root');
const root = createRoot(container);

setupMockServiceWorker();

root.render(
  <Provider store={store}>
    <ScrollProvider>
      <Web3ReactProvider getLibrary={getLibrary}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Web3ReactProvider>
    </ScrollProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
