import { AxiosResponse } from 'axios';

import { IDispatchAction } from 'models/dispatch-action.interface';
import { IMarketReducer } from 'models/reducers/i-market-reducer.interface';

import {
  APPEND_MARKET_ITEMS,
  FETCH_MARKET_ITEMS,
  FETCH_MARKET_OFFER,
  RESET_MARKET_DATA,
} from 'actions/actionNames/marketActionNames';

const firstPageIndex = 1;

const INITIAL_STATE: IMarketReducer = {
  loading: false,
  error: false,
  fetched: false,
  isLastPage: false,
  firstPage: firstPageIndex,
  currentPage: firstPageIndex,
  totalCount: 0,
  errorMessage: '',
  marketItems: null,
  current: null,
};

export const marketReducer = (state = INITIAL_STATE, action: IDispatchAction<AxiosResponse>) => {
  switch (action.type) {
    case RESET_MARKET_DATA:
      return { ...INITIAL_STATE };
    case FETCH_MARKET_ITEMS + '_PENDING':
    case FETCH_MARKET_OFFER + '_PENDING':
    case APPEND_MARKET_ITEMS + '_PENDING': {
      return { ...state, loading: true, error: false, fetched: false };
    }
    case FETCH_MARKET_ITEMS + '_REJECTED':
    case APPEND_MARKET_ITEMS + '_REJECTED':
    case FETCH_MARKET_OFFER + '_REJECTED':
      return { ...state, loading: false, error: true, fetched: false };
    case FETCH_MARKET_ITEMS + '_FULFILLED': {
      return {
        ...state,
        loading: false,
        error: false,
        fetched: true,
        currentPage: action.payload.data.currentPage,
        totalCount: action.payload.data.totalCount,
        isLastPage: action.payload.data.totalPages === action.payload.data.currentPage,
        marketItems: [...action.payload.data.results],
      };
    }
    case APPEND_MARKET_ITEMS + '_FULFILLED':
      return {
        ...state,
        loading: false,
        error: false,
        fetched: true,
        currentPage: action.payload.data.currentPage,
        isLastPage: action.payload.data.totalPages === action.payload.data.currentPage,
        marketItems: [...(state.marketItems ? state.marketItems : []), ...action.payload.data.results],
      };
    case FETCH_MARKET_OFFER + '_FULFILLED':
      return {
        ...state,
        loading: false,
        error: false,
        fetched: true,
        current: action.payload.data,
      };
    default:
      return state;
  }
};
