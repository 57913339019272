import { useClamped } from '../../../hooks/useClamped';

import VenueBackground from '../../venueBackground/VenueBackground';
import styles from './NftCardMini.module.scss';

interface INftCardMiniProps {
  backgroundUrl: string;
  backgroundAnimationUrl: string;
  name: string;
  className?: string;
}

const NftCardMini = ({ backgroundUrl, backgroundAnimationUrl, name, className = '' }: INftCardMiniProps) => {
  const classNames = [className, styles['nft-card']];

  const { ref, clamped } = useClamped();

  return (
    <div className={classNames.join(' ')}>
      <VenueBackground
        onMini={true}
        backgroundAnimationUrl={backgroundAnimationUrl}
        backgroundImageUrl={backgroundUrl}
        className={styles['nft-card__background']}
      />
      <div className={styles['nft-card__content']}>
        <p ref={ref} title={name} className={clamped ? styles['nft-card__name-small'] : styles['nft-card__name-normal']}>
          {name}
        </p>
      </div>
    </div>
  );
};

export default NftCardMini;
