import { useEffect, useState } from 'react';

import styles from './NumberCountdown.module.scss';

interface INumberCountdownProps {
  value: number;
  step?: number;
  className?: string;
}

const NumberCountdown = ({ value, step = 1, className = '' }: INumberCountdownProps) => {
  const [currentNumber, setCurrentNumber] = useState<number>(0);
  const classNames = [
    className,
    styles['number-countdown'],
    currentNumber >= value ? styles['number-countdown--animated'] : '',
  ];

  useEffect(() => {
    if (currentNumber + step > value) {
      setTimeout(() => {
        setCurrentNumber(value);
      }, 40);
    }

    if (currentNumber < value) {
      setTimeout(() => {
        setCurrentNumber(currentNumber + step);
      }, 40);
    }
  }, [currentNumber]);

  useEffect(() => {
    setCurrentNumber(0);
  }, [value]);

  return <div className={classNames.join(' ')}>{currentNumber}</div>;
};

export default NumberCountdown;
