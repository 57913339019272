export const SOCIAL_MEDIA_LINKS = {
  twitter: 'https://twitter.com/Realitymeta',
  youtube: 'https://www.youtube.com/channel/UChHhktjclrF4wQjZs72UKQg',
  facebook: 'https://www.facebook.com/realitymetaverse',
  instagram: 'http://www.instagram.com/reality__metaverse',
  discord: 'https://discord.gg/reality-meta',
  telegram: 'https://t.me/RealityMeta',
  telegramChat: 'https://t.me/+fB6p7b2Ay2IxZDA0',
  coinmarketcap: 'https://coinmarketcap.com/currencies/reality-metaverse/',
  medium: 'https://medium.com/@Realitymeta',
  polygonscan: 'https://polygonscan.com/token/0xf0949dd87d2531d665010d6274f06a357669457a',
};
