import { useContext, useEffect } from 'react';

import { ScrollContext } from '../../contexts/ScrollContext';
import GameOnboardingBannerSection from './gameOnboardingBannerSection/GameOnboardingBannerSection';
import GameOnboardingInstruction from './gameOnboardingInstruction/GameOnboardingInstruction';
import GameOnboardingLandmarksSection from './gameOnboardingLandmarksSection/GameOnboardingLandmarksSection';
import GameOnboardingMainSection from './gameOnboardingMainSection/GameOnboardingMainSection';
import GameOnboardingMillionsOfPlayersSection from './gameOnboardingMillionsOfPlayersSection/GameOnboardingMillionsOfPlayersSection';
import GameOnboardingRoyaltiesDistributionSection from './gameOnboardingRoyaltiesDistributionSection/GameOnboardingRoyaltiesDistributionSection';
import GameOnboardingRoyaltiesSection from './gameOnboardingRoyaltiesSection/GameOnboardingRoyaltiesSection';

const GameOnboarding = () => {
  const { resetActiveScrollSection } = useContext(ScrollContext);

  useEffect(() => {
    return () => {
      resetActiveScrollSection();
    };
  }, []);

  return (
    <div className="tw-max-h-[100vh] tw-overflow-y-auto tw-overflow-x-hidden tw-px-[20px]">
      <div className="tw-flex tw-max-w-full tw-flex-col tw-items-center">
        <GameOnboardingBannerSection />
        <GameOnboardingMainSection />
        <GameOnboardingLandmarksSection />
        <GameOnboardingRoyaltiesSection />
        <GameOnboardingMillionsOfPlayersSection />
        <GameOnboardingInstruction />
        <GameOnboardingRoyaltiesDistributionSection />
      </div>
    </div>
  );
};

export default GameOnboarding;
