import { useState } from 'react';

import { CookiesKeys } from '../enums/cookies-keys.enum';

import { useEventParamsMethods } from '../hooks/events/useEventsParams';

import { sendGA4Event } from '../services/analytics-events.service';
import { useCookies } from './useCookies';

export function useTermsOfServiceConsents() {
  const { setCookie, getCookie } = useCookies();

  const termsOfServiceConsentsCookieAccepted = getCookie(CookiesKeys.termsOfServiceConsentsAccepted) === 'true';
  const newsletterAgreementCookieAccepted = getCookie(CookiesKeys.newsletterAgreement) === 'true';

  const [consentsAccepted, setConsentsAccepted] = useState(true);
  const [termsOfServiceChecked, setTermsOfServiceChecked] = useState(termsOfServiceConsentsCookieAccepted);
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(termsOfServiceConsentsCookieAccepted);
  const [legalAgeConsentChecked, setLegalAgeConsentChecked] = useState(termsOfServiceConsentsCookieAccepted);
  const [newsletterAgreementChecked, setNewsletterAgreementChecked] = useState(newsletterAgreementCookieAccepted);

  const { withWalletAddress } = useEventParamsMethods();

  const onAccept = () => {
    setConsentsCookie();
    setNewsletterCookie();
    setConsentsAcceptedState();
    sendGA4Event('tos_checkbox_agree', withWalletAddress());
  };

  const setConsentsAcceptedState = () => {
    const termsOfServiceConsentsAccepted = getCookie(CookiesKeys.termsOfServiceConsentsAccepted) === 'true';
    const newsletterAgreement = typeof getCookie(CookiesKeys.newsletterAgreement) !== 'undefined';
    const consentsAccepted = termsOfServiceConsentsAccepted && newsletterAgreement;

    setConsentsAccepted(consentsAccepted);

    if (consentsAccepted) {
      setConsentsCookie();
    }
  };

  const setConsentsCookie = () => {
    const expirationDate = new Date();

    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    setCookie(CookiesKeys.termsOfServiceConsentsAccepted, 'true', expirationDate);
  };

  const setNewsletterCookie = () => {
    const expirationDate = new Date();

    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    setCookie(CookiesKeys.newsletterAgreement, newsletterAgreementChecked.toString(), expirationDate);
  };

  setTimeout(setConsentsAcceptedState, 0);

  return {
    consentsAccepted,
    legalAgeConsentChecked,
    privacyPolicyChecked,
    termsOfServiceChecked,
    newsletterAgreementChecked,
    onAccept,
    setPrivacyPolicyChecked,
    setLegalAgeConsentChecked,
    setTermsOfServiceChecked,
    setNewsletterAgreementChecked,
  };
}
