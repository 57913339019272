export enum AssetTraitKeys {
  id = '_id',
  locationLat = 'location_lat',
  locationLon = 'location_lon',
  type = 'type',
  category = 'category',
  categoryId = 'category_id',
  postalCode = 'postal_code',
  countryCode = 'country_code',
  address = 'address',
  settlement = 'settlement',
  popularity = 'popularity',
  valuationLevel = 'valuationLevel',
}
