import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';

import { IProfile } from 'models/profile/i-profile.interface';
import { IState } from 'models/reducers/i-state.interface';

import { addSuccessMessageAction } from 'actions/global-actions';

import { centralEllipsis } from 'utils/ellipsis.utils';

import ProfileAvatarDefault from 'assets/profile/icons/profile-avatar-default.svg';

import styles from './MenuAccountDropdownAccountBadge.module.scss';

const MenuAccountDropdownAccountBadge = () => {
  const dispatch = useDispatch();
  const profileData = useSelector(({ profile }: IState): IProfile | null => profile.profile);
  const walletAddress = useSelector((state: IState): string => state.wallet.address);

  const onCopy = () => {
    dispatch(addSuccessMessageAction('Wallet address has been copied!'));
  };

  return profileData ? (
    <div className={styles['account-badge']}>
      <div className={styles['avatar']}>
        <img alt="profile" src={profileData.avatar || ProfileAvatarDefault} />
      </div>
      <div className={styles['user-info']}>
        <p className={styles['user-name']}>{profileData.first_name || 'Unknown'}</p>
        <div className={styles['user-address__container']}>
          <p className={styles['user-address']} title={profileData.address}>
            {centralEllipsis(profileData.address, 9)}
          </p>
          <CopyToClipboard text={walletAddress} onCopy={onCopy}>
            <button className={`button--styleless ${styles['address-button']}`}>Copy</button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default MenuAccountDropdownAccountBadge;
