import { FETCH_TRANSLATIONS } from '../constants/actionNames';

import { ITranslationReducer } from '../models/reducers/i-translation-reducer.interface';

const INITIAL_STATE: ITranslationReducer = {
  error: false,
  loading: false,
  messages: {
    error: {
      unauthorized: '',
      wrongNetwork: '',
      permissionsRejected: '',
      transactionRejected: '',
      backendReject: '',
    },
  },
};

export const translationReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case FETCH_TRANSLATIONS + '_PENDING':
      return { ...state, loading: true, error: false };
    case FETCH_TRANSLATIONS + '_REJECTED':
      return { ...state, loading: false, error: true };
    case FETCH_TRANSLATIONS + '_FULFILLED':
      return { ...state, messages: action.payload.data.messages, loading: false, error: false };
    default:
      return state;
  }
};
