import { memo, useEffect, useState } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import { useNftCardContext } from '../nftCardContext/NftCardContext';
import styles from './NftCardBackSlider.module.scss';
import NftCardBackChatSlide from './slides/nftCardBackChatSlide/NftCardBackChatSlide';
import NftCardBackDaoSlide from './slides/nftCardBackDaoSlide/NftCardBackDaoSlide';
import NftCardBackOwnersSlide from './slides/nftCardBackOwnersSlide/NftCardBackOwnersSlide';
import NftCardBackStatisticsSlide from './slides/nftCardBackStatisticsSlide/NftCardBackStatisticsSlide';

const slides = [
  <SwiperSlide key={0}>
    <NftCardBackStatisticsSlide />
  </SwiperSlide>,
  <SwiperSlide key={1}>
    <NftCardBackOwnersSlide />
  </SwiperSlide>,
  <SwiperSlide key={2}>
    <NftCardBackChatSlide />
  </SwiperSlide>,
  <SwiperSlide key={3}>
    <NftCardBackDaoSlide />
  </SwiperSlide>,
];

const NftCardBackSlider = () => {
  const { toggled, onCallback } = useNftCardContext();

  const [slide, setSlide] = useState<number>(0);

  useEffect(() => {
    if (toggled && onCallback) {
      onCallback(slide);
    }
  }, [toggled, slide]);

  return (
    <Swiper
      className={styles['slider']}
      // @ts-ignore
      modules={[Navigation, Pagination]}
      navigation
      pagination={{ clickable: true }}
      loop
      cssMode
      onBeforeSlideChangeStart={({ activeIndex }) => setSlide(activeIndex)}
    >
      {slides}
    </Swiper>
  );
};

export default memo(NftCardBackSlider);
