import { Suspense, useEffect, useState } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { IState } from 'models/reducers/i-state.interface';

import { ROUTES } from 'constants/routes.constant';

import { InitializeReactGA } from 'services/analytics.service';
import { listenToViewportChanges } from 'services/viewport-height.service';

import GameOnboarding from './routes/gameOnboarding/GameOnboarding';
import GameOnboardingMobile from './routes/gameOnboardingMobile/GameOnboardingMobile';
import Landing from 'routes/landing/Landing';
import MapRoute from 'routes/MapRoute';
import RedeemRoute from 'routes/redeem/RedeemRoute';

import { CheckNetwork } from 'components/checkNetwork/CheckNetwork';
import CurrencyPurchaseWrapper from 'components/currencyPurchaseWrapper/CurrencyPurchaseWrapper';
import FullScreenLoadingSpinner from 'components/fullScreenLoadingSpinner/FullScreenLoadingSpinner';
import IconsSprite from 'components/iconsSprite/IconsSprite';
import { Login } from 'components/login/Login';
import Menu from 'components/menu/Menu';
import NotificationsPortal from 'components/notification/notificationsPortal/NotificationsPortal';
import WheelOfFortuneModal from 'components/wheelOfFortune/WheelOfFortuneModal/WheelOfFortuneModal';

import { useAccountChange } from 'hooks/useAccountChange';
import { useAppSettings } from 'hooks/useAppSettings';
import { useChainChange } from 'hooks/useChainChange';
import { useEnv } from 'hooks/useEnv';
import { useHomePageNavigate } from 'hooks/useHomePageNavigate';
import { useInitializeWeb3 } from 'hooks/useInitializeWeb3';
import { useMapRotation } from 'hooks/useMapRotation';
import { useReferrerWallet } from 'hooks/useReferrerWallet';
import { useWeb3ProviderChange } from 'hooks/useWeb3ProviderChange';

import { setDefaultBaseUrl } from 'utils/axios.utils';

import 'styles/App.scss';

const ContactRoute = React.lazy(() => import('routes/contact/ContactRoute'));
const GameRoute = React.lazy(() => import('routes/games/GameRoute'));
const GamesRoute = React.lazy(() => import('routes/games/GamesRoute'));
const PeriodicalStakingRoute = React.lazy(() => import('routes/periodicalStaking/PeriodicalStakingRoute'));
const MediaRoute = React.lazy(() => import('routes/media/MediaRoute'));
const PromotionsRoute = React.lazy(() => import('routes/promotions/PromotionsRoute'));

const App = () => {
  // web3
  useInitializeWeb3();
  useAccountChange();
  useChainChange();
  useWeb3ProviderChange();
  useReferrerWallet();

  // general
  const location = useLocation();

  const [appLoaded, setAppLoaded] = useState<boolean>(false);
  const [isLanding, setIsLanding] = useState<boolean>(false);
  const [isStaking, setIsStaking] = useState<boolean>(false);
  const [isGameOnboarding, setIsGameOnboarding] = useState<boolean>(false);
  const showWheelOfFortuneModal = useSelector((state: IState) => state.global.showCurrencyLotteryModal);
  const showCurrencyPurchaseModal = useSelector((state: IState) => state.global.showCurrencyPurchaseModal);

  useMapRotation();
  useAppSettings();

  const { redeemActive, hideLogin } = useEnv();
  const { homePage, navigateToHomePage } = useHomePageNavigate();

  useEffect(() => {
    const preventDefault = (event: Event) => event.preventDefault();

    document.addEventListener('contextmenu', preventDefault);

    return () => {
      document.removeEventListener('contextmenu', preventDefault);
    };
  });

  useEffect(() => {
    setDefaultBaseUrl();
    listenToViewportChanges();
    InitializeReactGA();
    // Initialization of Google Tag Manager has been commented out because all of the custom events were only sent to GTM and no events got to GA
    // This should be fixed to make sure that events are routed to both preferably, or custom events should be routed to GA only while GTM is initialized
    //InitializeReactGTM();
    setAppLoaded(true);

    if (location.pathname === ROUTES.HOME || location.pathname === `${ROUTES.HOME}/`) {
      navigateToHomePage();
    }
  }, []);

  useEffect(() => {
    const rootPaths = ['/', ROUTES.MEDIA, ROUTES.CONTACT, ROUTES.PROMOTIONS];
    const stakingPaths = [ROUTES.GAMES, ROUTES.PERIODICAL_STAKING];
    const pathname = `/${location.pathname.split('/')[1]}`;

    setIsLanding(rootPaths.includes(pathname));
    setIsStaking(stakingPaths.includes(pathname));
    setIsGameOnboarding(['/game-onboarding', '/game-onboarding-mobile'].includes(pathname));
  }, [location.pathname]);

  return (
    <Suspense fallback={<FullScreenLoadingSpinner />}>
      <div className="app">
        <NotificationsPortal />
        {!hideLogin && <Login />}
        {showCurrencyPurchaseModal && <CurrencyPurchaseWrapper />}
        {showWheelOfFortuneModal && <WheelOfFortuneModal />}
        <IconsSprite />
        {appLoaded && (
          <>
            <CheckNetwork>
              {isGameOnboarding ? <></> : <Menu isLanding={isLanding} isStaking={isStaking} />}
              <Routes>
                <Route
                  path={`${ROUTES.REDEEM}`}
                  element={redeemActive ? <RedeemRoute /> : <Navigate to={homePage} />}
                />
                <Route path="/" element={<Landing />} />
                <Route path={`${ROUTES.GAMES}/:game`} element={<GameRoute />} />
                <Route path={ROUTES.GAMES} element={<GamesRoute />} />
                <Route path={ROUTES.PERIODICAL_STAKING} element={<PeriodicalStakingRoute />} />
                <Route path={ROUTES.MEDIA} element={<MediaRoute />} />
                <Route path={ROUTES.CONTACT} element={<ContactRoute />} />
                <Route path={ROUTES.PROMOTIONS} element={<PromotionsRoute />} />
                <Route path={`${ROUTES.HOME}/*`} element={<MapRoute />} />
                <Route path="*" element={<Navigate to={homePage} />} />
                <Route path="/game-onboarding" element={<GameOnboarding />} />
                <Route path="/game-onboarding-mobile" element={<GameOnboardingMobile />} />
              </Routes>
            </CheckNetwork>
          </>
        )}
      </div>
      <div className="modal-container"></div>
    </Suspense>
  );
};

export default App;
