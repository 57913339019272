import { ILandingGame } from '../constants/landing-games.constants';

import styles from './LandingGameCard.module.scss';

interface ILandingGameCardProps {
  game: ILandingGame;
  className?: string;
}

const LandingGameCard = ({ game, className = '' }: ILandingGameCardProps) => {
  const classNames = [className, styles['game-card']].join(' ');

  return (
    <div className={classNames}>
      <img className={styles['game-card__image']} src={game.image} alt="" />
      <div className={styles['game-card__content']}>
        <p className={styles['game-card__title']}>{game.title}</p>
        <div className={styles['game-card__details']}>
          <div className={styles['game-card__description']}>
            <p className={styles['game-card__description-title']}>{game.title}</p>
            {game.description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingGameCard;
