import {
  APPEND_VENUES,
  FETCH_ASSET_PREVIEW_DETAILS,
  FETCH_AVAILABLE_RENDER_NFTS,
  FETCH_VENUES,
  FETCH_VENUE_BY_ID,
  FETCH_VENUE_TRADE_DETAILS,
  RESET_VENUE_ASSET_DETAILS,
  RESET_VENUE_OFFER_DETAILS,
  SELECT_VENUE,
} from '../constants/actionNames';
import { NO_VENUE } from '../constants/no-venue.constant';
import { ApiStatusCode } from '../enums/api-status-code.enum';
import { IAssetDetails } from '../models/assets/i-asset-details.interface';
import {
  IDispatchAction,
  IDispatchActionAsync,
  IDispatchAxiosAction,
  IDispatchMessageAction,
} from '../models/dispatch-action.interface';
import { IVenueGameStats } from '../models/i-venue-game-stats.interface';
import { ISearchVenuesResponseData, IVenue } from '../models/i-venue.interface';
import { IAvailableRenderNft } from '../models/nfts-rendering/i-available-render-nft.interface';
import { INoVenue } from '../models/no-venue.interface';
import { ISearchReducer } from '../models/reducers/i-search-reducer.interface';
import { ITradePreviewDetails } from '../models/trade/i-trade-preview-details.interface';
import { fetchVenueGameStatsService } from '../services/game-stats.service';
import { cancelTradeService } from '../services/trade-contract.service';
import {
  fetchAssetPreviewDetailsService,
  fetchAvailableRenderNftsService,
  fetchTradeDetailsService,
  fetchVenueByIdService,
  fetchVenuesService,
} from '../services/venues.service';
import {
  CANCEL_LISTING,
  FETCH_VENUE_GAME_STATS,
  RESET_LISTINGS,
  RESET_VENUE_GAME_STATS,
} from './actionNames/venuesActionNames';

export const fetchVenuesAction = (search: ISearchReducer): IDispatchActionAsync<ISearchVenuesResponseData> => ({
  type: FETCH_VENUES,
  payload: fetchVenuesService(search),
});

export const appendVenuesAction = (search: ISearchReducer): IDispatchActionAsync<ISearchVenuesResponseData> => ({
  type: APPEND_VENUES,
  payload: fetchVenuesService(search),
});

export const fetchVenueByIdAction = (id: string): IDispatchAxiosAction<IVenue> => ({
  type: FETCH_VENUE_BY_ID,
  payload: fetchVenueByIdService(id),
});

export const selectVenueAction = (data: IVenue): IDispatchAction<IVenue> => ({
  type: SELECT_VENUE,
  payload: data,
});

export const clearVenueAction = (): IDispatchAction<INoVenue> => ({
  type: SELECT_VENUE,
  payload: NO_VENUE,
});

export const fetchTradeDetailsAction = (
  id: string,
  tradeId?: string | null
): IDispatchAxiosAction<Omit<ITradePreviewDetails, 'tradeDetails'> | null> => ({
  type: FETCH_VENUE_TRADE_DETAILS,
  payload: fetchTradeDetailsService(id, tradeId),
});

export const fetchAssetPreviewDetailsAction = (tokenId: string): IDispatchAxiosAction<IAssetDetails> => ({
  type: FETCH_ASSET_PREVIEW_DETAILS,
  payload: fetchAssetPreviewDetailsService(tokenId),
});

export const resetVenueAssetDetails = (): IDispatchMessageAction => ({
  type: RESET_VENUE_ASSET_DETAILS,
});

export const resetVenueOfferDetails = (): IDispatchMessageAction => ({
  type: RESET_VENUE_OFFER_DETAILS,
});

export const fetchVenueGameStats = (token_id: string): IDispatchAxiosAction<IVenueGameStats> => ({
  type: FETCH_VENUE_GAME_STATS,
  payload: fetchVenueGameStatsService(token_id),
});

export const resetVenueGameStats = (): IDispatchMessageAction => ({
  type: RESET_VENUE_GAME_STATS,
});

export const resetListings = (): IDispatchMessageAction => ({
  type: RESET_LISTINGS,
});

export const cancelListingAction = (tradeId: number): IDispatchAxiosAction => ({
  payload: cancelTradeService(tradeId).then((response) =>
    response.status === ApiStatusCode.successAccepted ? { ...response, data: { id: tradeId } } : { ...response }
  ),
  type: CANCEL_LISTING,
});

export const fetchAvailableRenderNftsAction = (): IDispatchAxiosAction<IAvailableRenderNft[]> => ({
  type: FETCH_AVAILABLE_RENDER_NFTS,
  payload: fetchAvailableRenderNftsService(),
});
