import { useRef, useEffect } from 'react';

export function useHorizontalScroll() {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      const onMouseWheel = (event: WheelEvent) => {
        if (event.deltaY === 0) {
          return;
        }

        if (event.deltaY < 0 && element.scrollLeft <= 0) {
          return;
        }

        if (event.deltaY > 0 && element.clientWidth + element.scrollLeft >= element.scrollWidth) {
          return;
        }

        event.preventDefault();

        element.scrollTo({
          left: element.scrollLeft + event.deltaY,
          behavior: 'smooth',
        });
      };

      element.addEventListener('wheel', onMouseWheel);

      return () => element.removeEventListener('wheel', onMouseWheel);
    }
  }, []);
  return elementRef;
}
