import Button from '../../../../components/buttons/Button';

import LandingFaqListItem from '../../../landing/landingFaq/landingFaqItem/FaqListItem';
import GameOnboardingInstructionAddNetwork from '../gameOnboardingInstructionAddNetwork/GameOnboardingInstructionAddNetwork';
import GameOnboardingInstructionCopyWallet from '../gameOnboardingInstructionCopyWallet/GameOnboardingInstructionCopyWallet';
import GameOnboardingInstructionOpenTicket from '../gameOnboardingInstructionOpenTicket/GameOnboardingInstructionOpenTicket';

const GameOnboardingInstructionWithWallet = () => {
  return (
    <div>
      <LandingFaqListItem title="1. Add or Switch to Polygon Network">
        <GameOnboardingInstructionAddNetwork />
      </LandingFaqListItem>
      <LandingFaqListItem title="2. Copy your Wallet Address">
        <GameOnboardingInstructionCopyWallet />
      </LandingFaqListItem>
      <LandingFaqListItem title="3. Open Ticket">
        <GameOnboardingInstructionOpenTicket />
      </LandingFaqListItem>
      <Button className="tw-mx-auto tw-mt-7.5 tw-block lg:tw-mt-15" size="medium" asLink link="https://realitymeta.gitbook.io/lgo-onboarding/">
        Read tutorial
      </Button>
    </div>
  );
};

export default GameOnboardingInstructionWithWallet;
