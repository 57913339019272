import NftCardBackPlaceholder from '../../../nftCardBackPlaceholder/NftCardBackPlaceholder';
import NftCardBackSlide from '../../../nftCardBackSlide/NftCardBackSlide';
import { useNftCardContext } from '../../../nftCardContext/NftCardContext';

const NftCardBackDaoSlide = () => {
  const { asset } = useNftCardContext();

  return (
    <NftCardBackSlide label="DAO">
      <NftCardBackPlaceholder text="Governance and upgrades" name={asset?.name} />
    </NftCardBackSlide>
  );
};

export default NftCardBackDaoSlide;
