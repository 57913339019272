import { twMerge } from 'tailwind-merge';

import { ReactComponent as MarkerBgSvg } from 'assets/nft-card/nft-card-market-background.svg';

import { useNftCardSize } from '../hooks';
import { useNftCardContext } from '../nftCardContext/NftCardContext';

const NftCardMarker = () => {
  const { asset } = useNftCardContext();
  const { isCardLarge, isCardMedium, isCardSmall } = useNftCardSize();
  const isNotLargeCard = isCardMedium || isCardSmall;

  return (
    <div className="tw-absolute tw-inset-x-0 -tw-top-15 tw-flex tw-justify-center">
      <MarkerBgSvg className={twMerge(isNotLargeCard && 'tw-h-20 tw-w-20', isCardLarge && 'tw-h-22 tw-w-22')} />
      <img
        className={twMerge(
          'tw-absolute tw-top-1',
          isNotLargeCard && 'tw-h-18 tw-w-18',
          isCardLarge && 'tw-h-20 tw-w-20'
        )}
        src={asset?.markerUrl}
        alt=""
      />
    </div>
  );
};

export default NftCardMarker;
