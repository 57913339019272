import { useState } from 'react';
import YouTube from 'react-youtube';

import VideoBackground from '../../../assets/landing/videos/background.png';
import { ReactComponent as RealityMetaverse } from '../../../assets/logos/reality-metaverse-logo.svg';
import ModalPortal from '../../../components/modal/ModalPortal';
import ScrollInAnimationItem from '../../../components/scrollInAnimationItem/ScrollInAnimationItem';
import { ScrollAnimationType } from '../../../enums/scroll-animation-type.enum';

import styles from './LandingVideosSection.module.scss';

const videoItems = [
  {
    title: 'our projects',
    link: 'https://www.youtube.com/watch?v=GUtYBvKPzYg',
    id: 'GUtYBvKPzYg',
  },
  {
    title: 'our company',
    link: 'https://www.youtube.com/watch?v=lQQUKr8e7T8',
    id: 'lQQUKr8e7T8',
  },
  {
    title: 'marketing launch',
    link: 'https://www.youtube.com/watch?v=22WfGaErE1M',
    id: '22WfGaErE1M',
  },
  {
    title: 'games',
    link: 'https://www.youtube.com/watch?v=R7MoIQeH7Gc',
    id: 'R7MoIQeH7Gc',
  },
];

interface IVideCardProps {
  videoId: string;
  videoTitle: string;
}

const VideCard = ({ videoId, videoTitle }: IVideCardProps) => {
  const [showModal, setShowModal] = useState(false);
  const openVideo = () => setShowModal(true);
  const onClose = () => setShowModal(false);

  return (
    <>
      {showModal ? (
        <ModalPortal
          customStyles={{
            width: 'min(1020px, 100%)',
            height: 'min(100vh, 680px)',
            overflow: 'visible',
          }}
          className={styles['video-modal']}
          onClose={onClose}
          closeButtonOutside={true}
        >
          <div className={styles['video-modal__content']}>
            <YouTube
              videoId={videoId}
              containerClassName={styles['youtube-video']}
              opts={{
                width: '100%',
                height: '100%',
                playerVars: {
                  showinfo: 0,
                  enablejsapi: 0,
                  rel: 0,
                  modestbranding: 1,
                },
              }}
            />
          </div>
        </ModalPortal>
      ) : (
        <></>
      )}
      <button className={`button--styleless scale-in-hover ${styles['video-container']}`} onClick={() => openVideo()}>
        <img src={VideoBackground} alt="" className={styles['video-background']} />
        <RealityMetaverse className={styles['video-logo']} />
        {videoTitle ? <p className={styles['video-title']}>{videoTitle}</p> : <></>}
      </button>
    </>
  );
};

const LandingVideosSection = () => {
  return (
    <>
      <div className={styles['section']}>
        <div className={styles['content']}>
          <ScrollInAnimationItem animationType={ScrollAnimationType.slideInTop} delay="0.1s">
            <h2 className={styles['title']}>Get more knowledge</h2>
          </ScrollInAnimationItem>
          <div className={styles['videos-wrapper']}>
            {videoItems.map(({ title, id }, index) => (
              <ScrollInAnimationItem
                key={id}
                className={styles['video-animation-container']}
                delay={`${0.3 + 0.1 * index}s`}
                threshold={0.3}
              >
                <VideCard videoId={id} videoTitle={title} />
              </ScrollInAnimationItem>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingVideosSection;
