import { ReactNode } from 'react';

import WithTwClassName from '../../../hoc/WithTwClassName';

import styles from './NftCardWrapper.module.scss';

interface INftCardWrapperProps {
  children: ReactNode;
  className?: string;
}

const NftCardWrapper = ({ children, className = '' }: INftCardWrapperProps) => {
  const classNames = [className, styles['nft-card-wrapper']];

  return <div className={classNames.join(' ')}>{children}</div>;
};

export default WithTwClassName(NftCardWrapper);
