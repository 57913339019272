import {
  CANCEL_LISTING,
  FETCH_VENUE_GAME_STATS,
  RESET_LISTINGS,
  RESET_VENUE_GAME_STATS,
} from '../actions/actionNames/venuesActionNames';
import {
  APPEND_VENUES,
  FETCH_ASSET_PREVIEW_DETAILS,
  FETCH_AVAILABLE_RENDER_NFTS,
  FETCH_VENUE_TRADE_DETAILS,
  FETCH_VENUES,
  RESET_VENUE_ASSET_DETAILS,
  RESET_VENUE_OFFER_DETAILS,
} from '../constants/actionNames';
import { limit } from '../constants/VenuesRequest';
import { IAvailableRenderNft } from '../models/nfts-rendering/i-available-render-nft.interface';
import { IVenuesReducer } from '../models/reducers/i-venues-reducer.interface';

const INITIAL_STATE: IVenuesReducer = {
  loading: false,
  error: false,
  allVenues: [],
  fetchedPage: 0,
  totalCount: 0,
  currentVenueListings: [],
  currentAssetPreviewDetails: null,
  currentAssetPreviewDetailsLoading: false,
  currentAssetPreviewDetailsError: false,
  currentTradePreviewDetailsLoading: false,
  currentTradePreviewDetails: null,
  currentVenue: null,
  currentVenueGameStats: null,
  availableRenderNfts: null,
  availableRenderNftsFetched: false,
  availableRenderNftsLoading: false,
};

export const venuesReducer = (state = INITIAL_STATE, action: any): IVenuesReducer => {
  switch (action.type) {
    case FETCH_VENUES + '_PENDING':
      return {
        ...state,
        allVenues: [],
        loading: true,
        error: false,
      };
    case FETCH_VENUES + '_REJECTED':
      return { ...state, loading: false, error: true };
    case FETCH_VENUES + '_FULFILLED':
      if (action.payload.canceled) {
        return state;
      }

      return {
        ...state,
        loading: false,
        error: false,
        allVenues: [...action.payload.data],
        fetchedPage: 1,
        totalCount: action.payload.totalCount,
      };

    case APPEND_VENUES + '_PENDING':
      return { ...state, loading: true, error: false };
    case APPEND_VENUES + '_REJECTED':
      return { ...state, loading: false, error: true };
    case APPEND_VENUES + '_FULFILLED':
      if (action.payload.canceled) {
        return state;
      }

      const venues = action.payload.data;
      let fetchedVenuesCount = venues.length + state.allVenues.length;
      let fetchedPages = Math.ceil(fetchedVenuesCount / limit);

      return {
        ...state,
        loading: false,
        error: false,
        allVenues: [...state.allVenues, ...venues],
        fetchedPage: fetchedPages,
        totalCount: action.payload.totalCount,
      };

    case FETCH_VENUE_TRADE_DETAILS + '_PENDING':
      return { ...state, loading: true, error: false, currentTradePreviewDetailsLoading: true };
    case FETCH_VENUE_TRADE_DETAILS + '_REJECTED':
      return { ...state, loading: false, error: true, currentTradePreviewDetailsLoading: false };
    case FETCH_VENUE_TRADE_DETAILS + '_FULFILLED':
      return {
        ...state,
        currentTradePreviewDetailsLoading: false,
        currentTradePreviewDetails: action.payload.data,
        currentVenueListings: action.payload.data.assetDetails?.active_trades || [],
      };
    case CANCEL_LISTING + '_FULFILLED':
      return {
        ...state,
        currentVenueListings: state.currentVenueListings.filter((listing) => listing.id !== action.payload.data.id),
      };
    case RESET_LISTINGS:
      return {
        ...state,
        currentVenueListings: [],
      };
    case FETCH_ASSET_PREVIEW_DETAILS + '_PENDING':
      return { ...state, currentAssetPreviewDetailsLoading: true, currentAssetPreviewDetailsError: false };
    case FETCH_ASSET_PREVIEW_DETAILS + '_REJECTED':
      return { ...state, currentAssetPreviewDetailsLoading: false, currentAssetPreviewDetailsError: true };
    case FETCH_ASSET_PREVIEW_DETAILS + '_FULFILLED':
      return {
        ...state,
        currentAssetPreviewDetailsLoading: false,
        currentAssetPreviewDetailsError: false,
        currentAssetPreviewDetails: action.payload.data,
      };
    case RESET_VENUE_ASSET_DETAILS:
      return {
        ...state,
        currentAssetPreviewDetailsLoading: false,
        currentAssetPreviewDetailsError: false,
        currentAssetPreviewDetails: null,
      };
    case RESET_VENUE_OFFER_DETAILS:
      return {
        ...state,
        error: false,
        currentTradePreviewDetails: null,
      };
    case FETCH_VENUE_GAME_STATS + '_FULFILLED':
      return {
        ...state,
        currentVenueGameStats: action.payload.data,
      };
    case RESET_VENUE_GAME_STATS:
      return {
        ...state,
        currentVenueGameStats: null,
      };
    case FETCH_AVAILABLE_RENDER_NFTS + '_PENDING':
      return {
        ...state,
        availableRenderNftsLoading: true,
      };
    case FETCH_AVAILABLE_RENDER_NFTS + '_FULFILLED': {
      return {
        ...state,
        availableRenderNftsLoading: false,
        availableRenderNfts: (Array.isArray(action.payload.data) ? action.payload.data : []).map(
          (availableRenderNft: IAvailableRenderNft) => availableRenderNft.id
        ),
        availableRenderNftsFetched: true,
      };
    }
    case FETCH_AVAILABLE_RENDER_NFTS + '_REJECTED':
      return {
        ...state,
        availableRenderNftsLoading: false,
        availableRenderNftsFetched: true,
      };
    default:
      return state;
  }
};
