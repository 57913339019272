import { Link } from 'react-router-dom';

import { ILandingAsset } from 'models/landing/i-landing-asset.interface';

import { ROUTES } from 'constants/routes.constant';

import Button from 'components/buttons/Button';
import ValueAndIcon from 'components/generics/ValueAndIcon';
import NftCardMedium from 'components/nftCard/nftCardMedium/NftCardMedium';
import NftCardWrapper from 'components/nftCard/nftCardWrapper/NftCardWrapper';

import { useDefaultCurrency } from 'hooks/useCurrency';
import { useMappedPrice } from 'hooks/useMappedPrice';
import { useTraits } from 'hooks/useTraits';

import styles from './LandingNftCard.module.scss';

interface ILandingNftCardProps {
  asset: ILandingAsset;
  className?: string;
}

const LandingNftCard = ({ asset, className = '' }: ILandingNftCardProps) => {
  const defaultCurrency = useDefaultCurrency();
  const { currencyAmount: lowestPrice, currency: lowestPriceCurrency } = useMappedPrice(asset.lowest_possible_price);
  const { id, category } = useTraits(asset.traits);

  const classNames = [className, styles['landing-nft']].join(' ');

  return (
    <Link type="link" to={`${ROUTES.TRADES}/preview/${asset.token_id}`} className={classNames}>
      <NftCardWrapper className={styles['landing-nft__wrapper']}>
        <NftCardMedium
          id={+id}
          backgroundUrl={asset.image_original_url}
          name={asset.name!}
          address={asset.address}
          marketUrl={asset.marker_url}
          backgroundAnimationUrl={asset.animation_url!}
          category={category}
          shares={asset.total_shares?.toString() || '-'}
        />
        <ValueAndIcon
          className={styles['landing-nft__price']}
          value={asset.lowest_possible_price ? lowestPrice : '-'}
          icon={(lowestPriceCurrency || defaultCurrency)?.icon}
          iconAltText={(lowestPriceCurrency || defaultCurrency)?.symbol}
          iconSize={21}
          valueSize={20}
        />
        <Button color="primary" size="medium" className={styles['landing-nft__button']}>
          Buy now
        </Button>
      </NftCardWrapper>
    </Link>
  );
};

export default LandingNftCard;
