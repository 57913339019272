import { useContext, useEffect } from 'react';

import { ScrollContext } from '../../contexts/ScrollContext';
import { useElementOnScreen } from '../useElementOnScreen';

export const useLandingScrollSectionState = (section: string) => {
  const { activeScrollSection, sectionRefs, setActiveScrollSection, resetActiveScrollSection } =
    useContext(ScrollContext);
  const onScreen = useElementOnScreen(sectionRefs[section]);

  useEffect(() => {
    if (onScreen && activeScrollSection !== section) {
      setActiveScrollSection(section);
    }

    if (!onScreen && activeScrollSection === section) {
      resetActiveScrollSection();
    }
  }, [section, onScreen, activeScrollSection, resetActiveScrollSection, setActiveScrollSection]);
};
