import { FC, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as SharesSvg } from 'assets/icons/shares-icon.svg';

const NftCardShares: FC<PropsWithChildren & { size?: 'xl' | 'base' | 'sm' }> = ({ children, size = 'base' }) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-gap-1 tw-text-white">
      <SharesSvg
        className={twMerge(
          size === 'xl' && 'tw-h-5 tw-w-5',
          size === 'base' && 'tw-h-4 tw-w-4',
          size === 'sm' && 'tw-h-3.5 tw-w-3.5'
        )}
      />
      <span
        className={twMerge(
          size === 'xl' && 'tw-text-[20px]/[20px]',
          size === 'base' && 'tw-text-base/[18px]',
          size === 'sm' && 'tw-text-[14px]/[14px]'
        )}
      >
        {children}
      </span>
    </div>
  );
};

export default NftCardShares;
