import { Disclosure } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';
import { twMerge } from 'tailwind-merge';

import { IInputValue } from 'models/i-input-value.interface';
import { IWalletCurrency } from 'models/wallet-currency-response.interface';

import { CookiesKeys } from 'enums/cookies-keys.enum';

import { FLEXIBLE_STAKING_REWARDS, X_GAME_STAKING_BENEFITS } from '../constants/games.constants';
import { DEFAULTS } from 'constants/defaults.constant';
import { ERROR_MESSAGES } from 'constants/error-messages.constant';

import { addErrorMessageAction } from 'actions/global-actions';

import Button from 'components/buttons/Button';
import Form from 'components/form/Form';
import InputFormField from 'components/InputFormField/InputFormField';
import { ConsentsState } from 'components/termsOfServiceModal/TermsOfServiceModal';

import { useCookies } from 'hooks/useCookies';

import { parseFromDecimal, parseThousand, parseToDecimal } from 'utils/parser.utils';

import { ReactComponent as ArrowSvg } from 'assets/chevron.svg';
import { ReactComponent as TickSvg } from 'assets/tick-to-fill.svg';

import GameLabelPanel from '../gameLabelPanel/GameLabelPanel';
import GameValue from '../gameValue/GameValue';

interface IGameStakingForm {
  balance: string;
  currency: IWalletCurrency | undefined;
  rewardCurrency: IWalletCurrency | undefined;
  minDeposit: string;
  apy: string;
  poolId: number | undefined;
  flexible: boolean | undefined;
  negative: boolean | undefined;
  multiplier: number | undefined;
  fee: string;
  remaining: string;
  staking_closed: boolean;
  consents: ConsentsState | undefined;
  onChange: (value: ConsentsState) => void;
  onSubmit: (amount: string) => void;
}

const GameStakingForm = ({
  balance,
  currency,
  rewardCurrency,
  minDeposit,
  apy,
  poolId,
  flexible,
  negative,
  multiplier,
  fee,
  remaining,
  staking_closed,
  consents,
  onChange,
  onSubmit,
}: IGameStakingForm) => {
  const { t } = useTranslation('notifications');
  const dispatch = useDispatch();

  const { getCookie } = useCookies();

  const termsOfServiceConsentsCookieAccepted = getCookie(CookiesKeys.termsOfServiceConsentsAccepted) === 'true';

  const [amount, setAmount] = useState(DEFAULTS.defaultStake);
  const [inProgress, onToggleInProgress] = useToggle(false);

  const feeAmount = (+amount * +fee) / 100;
  const estDailyReward = ((+amount - feeAmount) * (multiplier ?? 1) * (+apy / 365)) / 100;

  const handleSubmit = () => {
    if (staking_closed) return;

    onToggleInProgress(true);

    if (!termsOfServiceConsentsCookieAccepted) {
      onChange(ConsentsState.Opened);
    } else {
      onSubmit(amount);
      setAmount(DEFAULTS.defaultStake);
      onToggleInProgress(false);
    }
  };

  useEffect(() => {
    if (inProgress && consents === ConsentsState.Accepted) {
      onSubmit(amount);
      setAmount(DEFAULTS.defaultStake);
    } else if (inProgress && consents === ConsentsState.Rejected) {
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.permissionsRejected)));
    }
  }, [consents, inProgress]);

  const minValueValidator = (value: IInputValue) => {
    if (+value! >= +minDeposit) {
      return { valid: true };
    }

    return { valid: false, message: `Your price must be higher or equal to ${minDeposit}` };
  };

  const maxValueValidator = (value: IInputValue) => {
    const remainingDecimalValue = parseToDecimal(remaining, currency!.decimal);
    const remainingValue = parseFromDecimal(remainingDecimalValue, currency!.decimal, DEFAULTS.defaultPricePrecision);

    if (+value! <= +remainingValue) {
      return { valid: true };
    }

    return { valid: false, message: `Your price cannot be higher than ${remainingValue}` };
  };

  const balanceValidator = (value: IInputValue) => {
    if (+value! <= +balance) {
      return { valid: true };
    }

    return { valid: false, message: 'Not enough funds' };
  };

  const handleSetMaxValue = () => {
    const remainingDecimalValue = parseToDecimal(remaining, currency!.decimal);
    const remainingValue = parseFromDecimal(remainingDecimalValue, currency!.decimal, DEFAULTS.defaultPricePrecision);
    const balanceDecimalValue = parseToDecimal(balance, currency!.decimal);
    const balanceValue = parseFromDecimal(balanceDecimalValue, currency!.decimal, DEFAULTS.defaultPricePrecision);

    setAmount(+remainingValue <= +balanceValue ? remainingValue : balanceValue);
  };

  return (
    <Form onSubmit={handleSubmit} className={staking_closed ? 'tw-pointer-events-none tw-opacity-50' : ''}>
      <InputFormField
        label="Staking amount"
        type="number"
        value={amount}
        appendixClassName="tw-absolute tw-z-10 tw-right-0 tw-inset-y-0 tw-flex tw-items-center tw-justify-center tw-h-[46px]"
        appendix={
          <div className="tw-flex tw-w-[6.5rem] tw-items-center tw-gap-4">
            <span className="tw-relative tw-text-[18px]/[20px] tw-text-white after:tw-absolute after:-tw-right-2 after:tw-h-5 after:tw-w-px after:tw-bg-menuLinkColor after:tw-content-['']">
              {currency?.symbol}
            </span>
            <button
              className="tw-cursor-pointer tw-border-transparent tw-bg-transparent tw-p-0 tw-text-[18px]/[20px] tw-font-normal tw-uppercase tw-text-turquoise"
              type="button"
              onClick={handleSetMaxValue}
            >
              max
            </button>
          </div>
        }
        helperClassName={twMerge(
          (negative || flexible) &&
            'tw-line-clamp-1 tw-absolute tw-text-menuLinkColor tw-text-sm tw-inset-x-4 tw-pr-20 tw-top-[34px]'
        )}
        helper={negative || flexible ? `${parseThousand(+balance, 2)} available` : undefined}
        validations={[
          { key: 'minValueValidator', validator: minValueValidator },
          { key: 'maxValueValidator', validator: maxValueValidator },
          { key: 'balanceValidator', validator: balanceValidator },
        ]}
        onChange={({ target }) => setAmount(target.value)}
        labelClassName="tw-uppercase tw-text-[18px]/[20px]"
      />
      <div className="tw-mb-7.5 tw-mt-5 tw-flex tw-flex-col tw-gap-1.5">
        <GameLabelPanel
          label={negative || flexible ? 'Staking' : 'Available amount'}
          labelClassName="tw-text-[18px]/[20px]"
          className="!tw-flex-row tw-items-center tw-justify-between !tw-p-2.5 xl:tw-w-full"
          tooltip={negative || flexible ? 'Total net staking amount excludes Development pool' : undefined}
        >
          <GameValue
            currency={currency!}
            value={negative || flexible ? (+amount - feeAmount).toString() : balance}
            iconClassName="!tw-h-6 !tw-w-6"
            className="tw-text-[20px]/[20px]"
          />
        </GameLabelPanel>
        <GameLabelPanel
          label={negative || flexible ? 'Development pool' : 'Available to stake'}
          labelClassName="tw-text-[18px]/[20px]"
          className="!tw-flex-row tw-items-center tw-justify-between !tw-p-2.5 xl:tw-w-full"
          tooltip={
            flexible
              ? poolId === 0
                ? '$RMV sent to the development pool will not be returned. You will be rewarded with $StreetCoin in a value equal to $RMV sent to the Development pool.'
                : `${fee}% invested in Game development`
              : negative
              ? `${fee}% invested in Game development`
              : undefined
          }
        >
          <GameValue
            currency={currency!}
            value={negative || flexible ? feeAmount.toString() : +remaining > +balance ? balance : remaining}
            iconClassName="!tw-h-6 !tw-w-6"
            className="tw-text-[20px]/[20px]"
          />
        </GameLabelPanel>
        {flexible ? (
          poolId === 0 ? (
            <Disclosure
              as="div"
              className="!tw-flex-row tw-items-center tw-justify-between tw-rounded-xl tw-bg-backgroundColorMedium tw-p-2.5 tw-pr-4 tw-text-white"
            >
              {({ open }) => (
                <>
                  <Disclosure.Button className="button-reset tw-flex tw-w-full tw-items-center tw-justify-between">
                    <div
                      className={twMerge(
                        'tw-line-clamp-1 tw-text-base/[18px] tw-font-semibold tw-text-menuLinkColor',
                        open && 'tw-text-white'
                      )}
                    >
                      NFT earnings explained
                    </div>
                    <ArrowSvg className="ui-not-open:[transform:rotate(180deg)]" />
                  </Disclosure.Button>
                  <Disclosure.Panel className="tw-mt-2.5 tw-flex tw-flex-col tw-gap-2.5 tw-p-2.5">
                    <p>By staking in STREETVERS, you can earn fantastic rewards each month:</p>
                    <ul className="tw-my-0 tw-ml-4 tw-list-none tw-pl-0">
                      {FLEXIBLE_STAKING_REWARDS.map(({ title, description }, idx) => (
                        <li
                          key={idx}
                          className={twMerge('tw-pb-2 tw-pl-8 tw-font-bold', idx === 4 && 'tw-text-turquoise')}
                        >
                          <TickSvg className="-tw-ml-8 tw-mr-2.5 tw-fill-green" />
                          {title}:
                          <br />
                          <span className="tw-font-normal">{description}</span>
                        </li>
                      ))}
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ) : (
            <Disclosure
              as="div"
              className="!tw-flex-row tw-items-center tw-justify-between tw-rounded-xl tw-bg-backgroundColorMedium tw-p-2.5 tw-pr-4 tw-text-white"
            >
              {({ open }) => (
                <>
                  <Disclosure.Button className="button-reset tw-flex tw-w-full tw-items-center tw-justify-between">
                    <div
                      className={twMerge(
                        'tw-line-clamp-1 tw-text-base/[18px] tw-font-semibold tw-text-menuLinkColor',
                        open && 'tw-text-white'
                      )}
                    >
                      Rewards
                    </div>
                    <ArrowSvg className="ui-not-open:[transform:rotate(180deg)]" />
                  </Disclosure.Button>
                  <Disclosure.Panel className="tw-mt-2.5 tw-flex tw-flex-col tw-gap-2.5 tw-p-2.5">
                    <p>
                      Stake your RMV tokens to unlock a range of exclusive rewards and gain a head start in the game:
                    </p>
                    <ul className="tw-my-0 tw-ml-4 tw-list-none tw-pl-0">
                      {X_GAME_STAKING_BENEFITS.map(({ title, description }, idx) => (
                        <li
                          key={idx}
                          className={twMerge('tw-pb-2 tw-pl-8 tw-font-bold', idx === 5 && 'tw-text-turquoise')}
                        >
                          <TickSvg className="-tw-ml-8 tw-mr-2.5 tw-fill-green" />
                          {title}:
                          <br />
                          <span className="tw-font-normal">{description}</span>
                        </li>
                      ))}
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          )
        ) : (
          <GameLabelPanel
            label={negative ? 'APY in $Trumpet Coin' : 'APY'}
            labelClassName="tw-text-[18px]/[20px]"
            className="!tw-flex-row tw-items-center tw-justify-between !tw-p-2.5 xl:tw-w-full"
          >
            <span className="tw-text-[20px]/[20px] tw-text-white">{apy}%</span>
          </GameLabelPanel>
        )}
        {negative && multiplier && (
          <GameLabelPanel
            label="Estimated daily yield"
            labelClassName="tw-text-[18px]/[20px]"
            className="!tw-flex-row tw-items-center tw-justify-between !tw-p-2.5 xl:tw-w-full"
            tooltip="For each day of staking you will receive $Trumpet coins when the token is released"
          >
            <GameValue
              currency={rewardCurrency!}
              value={estDailyReward.toString()}
              iconClassName="!tw-h-6 !tw-w-6"
              className="tw-text-[20px]/[20px]"
            />
          </GameLabelPanel>
        )}
      </div>
      <Button type="submit" className="tw-w-full" size="medium" requireWeb3>
        Approve
      </Button>
    </Form>
  );
};

export default GameStakingForm;
