export enum ApiStatusCode {
  errorBadRequest = 400,
  errorForbidden = 403,
  errorNotFound = 404,
  errorUnprocessableEntity = 422,
  errorUnauthorized = 401,
  internalServerError = 500,
  successAccepted = 202,
  successCreated = 201,
  successNoContent = 204,
  successOK = 200,
}
