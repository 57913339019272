import { IWalletCurrency } from '../models/wallet-currency-response.interface';

import { PriceCurrencySymbols } from '../enums/price-currency-symbols.enum';

import { getIconByCurrencySymbol } from '../utils/get-currency-icon-by-symbol';

export const CURRENCIES_RESPONSE_MOCK: IWalletCurrency[] = [
  {
    id: 6,
    network: 80001,
    name: PriceCurrencySymbols.RMV,
    symbol: PriceCurrencySymbols.RMV,
    address: '0xCC6e58F33938ee86B81df83c4688F30cE521b15A',
    decimal: 18,
    icon: getIconByCurrencySymbol(PriceCurrencySymbols.RMV),
  },
  {
    id: 7,
    network: 80001,
    name: PriceCurrencySymbols.USDC,
    symbol: PriceCurrencySymbols.USDC,
    address: '0xe6b8a5CF854791412c1f6EFC7CAf629f5Df1c747',
    decimal: 6,
    icon: getIconByCurrencySymbol(PriceCurrencySymbols.USDC),
  },
];
