import { useContext, useEffect } from 'react';

import { SourceScreenName } from '../../../../hooks/events/useFundsEvents';
import { useRMVBalanceState } from '../../../../hooks/useBalanceState';
import { useRMVCurrency } from '../../../../hooks/useCurrency';

import BuyRMVControl from '../../../buyRMVControl/BuyRMVControl';
import ElementLoader, { ElementLoaderContext } from '../../../element-loader/ElementLoader';
import ValueAndIcon from '../../../generics/ValueAndIcon';
import MenuAccountDropdownAccountBadge from '../menuAccountDropdownAccountBadge/MenuAccountDropdownAccountBadge';
import styles from './MenuAccountDropdownBalanceSection.module.scss';

const MenuAccountDropdownBalanceSectionBalance = () => {
  const currencyRMV = useRMVCurrency();
  const { balance: rmvCurrencyBalance, loading: rmvCurrencyBalanceLoading } = useRMVBalanceState();
  const { enableUi, disableUi } = useContext(ElementLoaderContext);

  useEffect(() => {
    if (rmvCurrencyBalanceLoading) {
      disableUi();

      return;
    }

    enableUi();
  }, [rmvCurrencyBalanceLoading, enableUi, disableUi]);

  return (
    <>
      <p className={styles['balance-section__caption']}>Your Balance</p>
      <ul className={styles['balance-section__list']}>
        {currencyRMV && (
          <li>
            <ValueAndIcon
              iconSize={28}
              valueSize={20}
              value={rmvCurrencyBalance || 0}
              icon={currencyRMV?.icon}
              iconAltText={currencyRMV?.symbol}
            />
          </li>
        )}
      </ul>
    </>
  );
};

const MenuAccountDropdownBalanceSection = () => {
  return (
    <>
      <div className={styles['account-badge']}>
        <MenuAccountDropdownAccountBadge />
      </div>
      <div className={styles['balance-section']}>
        <ElementLoader>
          <MenuAccountDropdownBalanceSectionBalance />
        </ElementLoader>
        <BuyRMVControl className={styles['buy-rmv-control']} sourceScreen={SourceScreenName.UserDropdown} />
      </div>
    </>
  );
};

export default MenuAccountDropdownBalanceSection;
