export const listenToViewportChanges = () => {
  setViewportHeightParam();
  window.onresize = setViewportHeightParam;
};

const setViewportHeightParam = () => {
  setTimeout(() => {
    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, 100);
};
