import { Noop } from 'types/common.interface';

import { StorePlatform } from 'enums/store-platform.enum';

import { STORE_URL } from 'constants/store-urls.constant';

import AppleStore from 'assets/icons/apple-store.png';
import GooglePlay from 'assets/icons/google-play.png';

import styles from './DownloadApp.module.scss';

interface IDownloadApp {
  platform: StorePlatform;
  customStoreUrl?: string;
  onClick?: Noop;
  className?: string;
}

const DownloadApp = ({ platform, customStoreUrl, onClick, className = '' }: IDownloadApp) => {
  const ios = platform === StorePlatform.appleStore;
  const href = ios ? STORE_URL.ios : STORE_URL.android;
  const imgSrc = ios ? AppleStore : GooglePlay;

  return (
    <a
      className={`scale-in-hover ${className} ${styles['download-button']}`}
      target="_blank"
      rel="noreferrer"
      href={customStoreUrl || href}
      onClick={onClick}
    >
      <img src={imgSrc} alt={platform}></img>
    </a>
  );
};

export default DownloadApp;
