import { useSelector } from 'react-redux';

import { IProfile } from 'models/profile/i-profile.interface';
import { IState } from 'models/reducers/i-state.interface';

import { ButtonSize } from 'components/buttons/Button';

import MenuAccountControl from '../menuAccountControl/MenuAccountControl';
import MenuWalletConnectControl from '../menuWalletConnectControl/MenuWalletConnectControl';

const MenuWalletButton = ({ size }: { size?: ButtonSize }) => {
  const profileData = useSelector(({ profile }: IState): IProfile | null => profile.profile);
  const walletAddress = useSelector((state: IState): string => state.wallet.address);

  return profileData && walletAddress ? <MenuAccountControl /> : <MenuWalletConnectControl size={size} />;
};

export default MenuWalletButton;
