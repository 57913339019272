import { ReactComponent as Brave } from '../../../../assets/game-onboarding/brave.svg';
import { ReactComponent as Chrome } from '../../../../assets/game-onboarding/chrome.svg';
import { ReactComponent as Egde } from '../../../../assets/game-onboarding/edge.svg';
import { ReactComponent as Firefox } from '../../../../assets/game-onboarding/firefox.svg';
import { ReactComponent as Opera } from '../../../../assets/game-onboarding/opera.svg';

const browsers = [
  {
    title: 'Chrome',
    LogoComponent: Chrome,
    link: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
  },
  {
    title: 'Firefox',
    LogoComponent: Firefox,
    link: 'https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/',
  },
  {
    title: 'Brave',
    LogoComponent: Brave,
    link: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
  },
  {
    title: 'Egde',
    LogoComponent: Egde,
    link: 'https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US',
  },
  {
    title: 'Opera',
    LogoComponent: Opera,
    link: 'https://addons.opera.com/en-gb/extensions/details/metamask-10/',
  },
];

const GameOnboardingInstructionInstallMetamask = () => {
  return (
    <div>
      <p className="tw-mb-[20px] tw-text-center tw-text-white">Supported browsers</p>
      <div className="tw-flex tw-flex-wrap tw-justify-center tw-gap-[50px] lg:tw-gap-[80px]">
        {browsers.map(({ LogoComponent, title, link }) => (
          <a key={title} href={link} target="_blank" rel="noreferrer" className="tw-flex tw-flex-col tw-gap-[10px]">
            <LogoComponent className="tw-h-[68px]" />
            <span className="tw-text-center tw-text-white">{title}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default GameOnboardingInstructionInstallMetamask;
