import { useContext, useEffect } from 'react';

import { ScrollContext } from '../../contexts/ScrollContext';
import GameOnboardingMobileBannerSection from './gameOnboardingMobileBannerSection/GameOnboardingMobileBannerSection';

const GameOnboardingMobile = () => {
  const { resetActiveScrollSection } = useContext(ScrollContext);

  useEffect(() => {
    return () => {
      resetActiveScrollSection();
    };
  }, []);

  return (
    <div className="tw-max-h-[100vh] tw-overflow-y-auto tw-overflow-x-hidden tw-px-[20px]">
      <div className="tw-flex tw-max-w-full tw-flex-col tw-items-center">
        <GameOnboardingMobileBannerSection />
      </div>
    </div>
  );
};

export default GameOnboardingMobile;
