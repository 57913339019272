import { ComponentType, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

interface IWithTwClassNameProps {
  twClassName?: string;
  className?: string;
}

const WithTwClassName = <P extends object>(Component: ComponentType<P>): ComponentType<P & IWithTwClassNameProps> => {
  return ({ twClassName, ...props }: PropsWithChildren<P & IWithTwClassNameProps>) => {
    const { className = '', ...rest } = props;
    const mergedClassName = twMerge(className, twClassName);

    return <Component className={mergedClassName} {...(rest as P)} />;
  };
};

export default WithTwClassName;
