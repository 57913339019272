import { ScrollAnimationType } from '../../../enums/scroll-animation-type.enum';

import ScrollInAnimationItem from '../../../components/scrollInAnimationItem/ScrollInAnimationItem';

import BusinessModelSectionEcosystem from '../../../assets/landing/business-model/business-model-ecosystem.png';

import styles from './LandingBusinessModelSection.module.scss';

const LandingBusinessModelSection = ({ mainAnimationLoaded }: { mainAnimationLoaded: boolean }) => {
  return (
    <section className={['landing-page-container', styles['section']].join(' ')}>
      <ScrollInAnimationItem
        className={styles['ecosystem']}
        animationType={ScrollAnimationType.slideInTop}
        delay="0.1s"
        threshold={0.3}
      >
        <img src={mainAnimationLoaded ? BusinessModelSectionEcosystem : ''} alt="" width="750" height="627" />
      </ScrollInAnimationItem>
      <ScrollInAnimationItem className={styles['summary']} delay="0.2s">
        <h2>
          <b>sustainable business model</b> with constant demand
        </h2>
        <p className={`landing-main-text ${styles['paragraph']}`}>
          We expect a steady demand for our RMV token due to its utility in acquiring NFTs, partnering with other
          projects, collaborating on 3D models, and earning royalties from other games.
        </p>
        <p className={`landing-main-text ${styles['paragraph']}`}>
          Additionally, other games may use fiat currency to make profits and purchase RMV tokens, which we can then
          share with NFT owners, creating a continuous demand for our token.
        </p>
      </ScrollInAnimationItem>
    </section>
  );
};

export default LandingBusinessModelSection;
