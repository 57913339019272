import { LazyLoadImage } from 'react-lazy-load-image-component';

import { ScrollAnimationType } from '../../../enums/scroll-animation-type.enum';

import ScrollInAnimationItem from '../../../components/scrollInAnimationItem/ScrollInAnimationItem';

import Arrow from '../../../assets/about/about-arrow.svg';
import ArrowMobile from '../../../assets/game-onboarding/arrow-mobile.svg';

const GameOnboardingMainSection = () => {
  return (
    <div className="tw-mb-[50px] tw-flex tw-max-w-full tw-flex-col tw-items-center sm:tw-mb-[80px] md:tw-mb-[120px]">
      <ScrollInAnimationItem delay="0.1s" threshold={0.3}>
        <h2 className="tw-mb-[20px] tw-text-center tw-text-[48px] tw-text-white sm:tw-text-[68px] md:tw-text-[98px] md:tw-leading-[70px]">
          What is <br />
          <span className="tw-uppercase">reality nft?</span>
        </h2>
      </ScrollInAnimationItem>
      <ScrollInAnimationItem
        delay="0.2s"
        threshold={0.3}
        animationType={ScrollAnimationType.slideInLeft}
        className="tw-mb-15 tw-hidden tw-max-w-[80%] sm:tw-block"
      >
        <LazyLoadImage alt="" className="tw-max-w-full" src={Arrow} />
      </ScrollInAnimationItem>
      <ScrollInAnimationItem
        delay="0.2s"
        threshold={0.3}
        animationType={ScrollAnimationType.slideInLeft}
        className="tw-mb-15 tw-max-w-[80%] sm:tw-hidden"
      >
        <LazyLoadImage alt="" className="tw-max-w-full" src={ArrowMobile} />
      </ScrollInAnimationItem>
      <ScrollInAnimationItem delay="0.3s" className="tw-w-[1038px] tw-max-w-full" threshold={0.3}>
        <p className="tw-mb-7.5 tw-text-center tw-text-lg tw-text-white sm:tw-text-[20px] md:tw-text-[24px]">
          Reality NFT has created the true connection between real life and the Metaverse, where web2 and web3 users can
          co-exist. We are allowing users true ownership of their assets in games played by millions of players in the
          flagship mobile game Landlord GO. Each NFT corresponds to real-world locations, famous landmarks, most
          prominent cities, countries and generates utilities in royalties.
        </p>
      </ScrollInAnimationItem>
      <ScrollInAnimationItem delay="0.4s" threshold={0.1}>
        <p className="tw-text-center tw-text-3xl tw-font-bold tw-uppercase tw-text-turquoise">
          Be a part of the digital revolution!
        </p>
      </ScrollInAnimationItem>
    </div>
  );
};

export default GameOnboardingMainSection;
