import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ILootboxRewardMetadata } from 'models/lootbox/i-lootbox-reward-metadata.interface';

import { setNewNftsAction } from 'actions/wallet.action';

import Button from 'components/buttons/Button';

import styles from './LootboxOpenSuccess.module.scss';
import LootboxOpenSuccessRewards from './lootboxOpenSuccessRewards/LootboxOpenSuccessRewards';

interface ILootboxOpenSuccessProps {
  openingAnimationFinished: boolean;
  quantity?: number;
  rewards?: ILootboxRewardMetadata[];
  onClaim: () => void;
}

const LootboxOpenSuccess = ({ openingAnimationFinished, quantity, rewards, onClaim }: ILootboxOpenSuccessProps) => {
  const [fadeOut, setFadeOut] = useState(false);
  const dispatch = useDispatch();

  const listClassNames = [
    openingAnimationFinished ? styles['lootbox-success--visible'] : '',
    styles['lootbox-success'],
  ];
  const rewardsQuantity = rewards
    ?.map(({ quantityPerReward }) => (quantityPerReward ? +quantityPerReward : 0))
    .reduce((accumulator, value) => accumulator + value, 0);
  const onClaimClick = () => {
    setFadeOut(true);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (fadeOut) {
      timer = setTimeout(() => {
        if (rewardsQuantity) {
          dispatch(setNewNftsAction(rewardsQuantity));
        }

        onClaim();
      }, 1800);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [fadeOut]);

  return (
    <div className={listClassNames.join(' ')}>
      <div className={styles['lootbox-success__caption']}>
        <p className={styles['lootbox-success__title']}>Great Purchase!</p>
        <p className={styles['lootbox-success__subtitle']}>
          You have received {rewardsQuantity} {rewardsQuantity !== undefined && rewardsQuantity > 1 ? 'fNFTs' : 'fNFT'}{' '}
          in {quantity} {quantity !== undefined && quantity > 1 ? 'lootboxes' : 'lootbox'}.
        </p>
      </div>
      <LootboxOpenSuccessRewards fadeIn={openingAnimationFinished} fadeOut={fadeOut} rewards={rewards} />
      <p>Click “Claim All” to add your new fNFTs into your portfolio! It may take up to a minute.</p>
      <Button
        size="medium-large"
        className={styles['lootbox-success__button']}
        text="Claim all"
        onClick={onClaimClick}
      />
    </div>
  );
};

export default LootboxOpenSuccess;
