import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import { ILootboxRewardMetadata } from 'models/lootbox/i-lootbox-reward-metadata.interface';

import { useWindowSize } from 'hooks/useWindowSize';

import Caret from 'assets/lootbox/lootbox-rewards-arrow.svg';

import LootboxOpenSuccessReward from '../lootboxOpenSuccessReward/LootboxOpenSuccessReward';
import styles from './LootboxOpenSuccessRewards.module.scss';

interface ILootboxOpenSuccessRewardsProps {
  fadeIn: boolean;
  fadeOut: boolean;
  rewards?: ILootboxRewardMetadata[];
}

const LootboxOpenSuccessRewards = ({ fadeIn, fadeOut, rewards }: ILootboxOpenSuccessRewardsProps) => {
  const [visibleSlides, setVisibleSlides] = useState(3);
  const carouselRef = useRef<Slider>(null);
  const [width] = useWindowSize();
  const defaultCardWidth = 280;
  const rewardsClassNames = [
    styles['lootbox-success__rewards'],
    fadeOut ? styles['lootbox-success__rewards--fade-out'] : '',
  ];
  const rewardClassNames = [
    fadeIn ? styles['lootbox-success__reward--fade-in'] : '',
    fadeOut ? styles['lootbox-success__reward--fade-out'] : '',
    styles['lootbox-success__reward'],
  ];

  useEffect(() => {
    if (width < defaultCardWidth * 2) {
      setVisibleSlides(1);

      return;
    }

    if (width < defaultCardWidth * 3) {
      setVisibleSlides(2);

      return;
    }

    setVisibleSlides(3);
  }, [width]);

  if (!rewards?.length) {
    return <></>;
  }

  return (
    <div className={rewardsClassNames.join(' ')}>
      {rewards.length > visibleSlides && (
        <button
          className={`button--styleless ${styles['carousel-button']} ${styles['carousel-button--left']}`}
          onClick={() => carouselRef.current?.slickPrev()}
        >
          <img src={Caret} alt="arrow left" className="scale-in-hover" />
        </button>
      )}
      <div className={styles['lootbox-success__slider-container']}>
        <Slider
          dots
          variableWidth
          speed={300}
          rows={1}
          arrows={false}
          infinite={false}
          centerPadding="0px"
          dotsClass={`slick-dots ${styles['lootbox-success__carousel-dots']}`}
          className={styles['lootbox-success__slider']}
          slidesToScroll={1}
          slidesToShow={visibleSlides}
          useTransform={false}
          ref={carouselRef}
        >
          {rewards.map((reward, index) => (
            <div
              className={styles['lootbox-success__card-wrapper']}
              key={`${reward.tokenId}-${reward.quantityPerReward}-${index}`}
            >
              <LootboxOpenSuccessReward
                className={rewardClassNames.join(' ')}
                key={`${reward.tokenId}-${reward.quantityPerReward}`}
                reward={reward}
              />
            </div>
          ))}
        </Slider>
      </div>
      {rewards.length > visibleSlides && (
        <button
          className={`button--styleless ${styles['carousel-button']} ${styles['carousel-button--right']}`}
          onClick={() => carouselRef.current?.slickNext()}
        >
          <img src={Caret} alt="arrow right" className="scale-in-hover" />
        </button>
      )}
    </div>
  );
};

export default LootboxOpenSuccessRewards;
