export const LOOT_BOX_STORE_ABI: any[] = [
  {
    inputs: [],
    name: 'getAllValidListings',
    outputs: [
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'address[]', name: '', type: 'address[]' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        name: 'listingID',
        type: 'uint256',
      },
      {
        name: 'quantity',
        type: 'uint256',
      },
      {
        name: 'forMaxPriceInQT',
        type: 'uint256',
      },
    ],
    name: 'safePurchase',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [{ indexed: false, internalType: 'uint256', name: 'listingID', type: 'uint256' }],
    name: 'checkListingQTPrice',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
];
