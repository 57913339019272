import { ReactNode } from 'react';

import HomeFooter from 'components/homeFooter/HomeFooter';
import LandingFooter from 'components/landingFooter/LandingFooter';

import WithTwClassName from 'hoc/WithTwClassName';

import styles from './PageWrapper.module.scss';

interface IPageWrapperProps {
  pageType?: 'minimized' | 'full-page';
  className?: string;
  children: ReactNode;
  homeFooter?: boolean;
  landingFooter?: boolean;
}

const PageWrapper = ({
  children,
  pageType = 'full-page',
  className = '',
  homeFooter = true,
  landingFooter = false,
}: IPageWrapperProps) => {
  const wrapperClasses = [
    styles['page-wrapper'],
    styles[`page-wrapper--${pageType}`],
    homeFooter ? '' : styles['page-wrapper--without-footer'],
    className,
  ];

  return (
    <div className={wrapperClasses.join(' ')}>
      {children}
      {homeFooter && <HomeFooter className={styles['footer']} />}
      {landingFooter && <LandingFooter />}
    </div>
  );
};

export default WithTwClassName(PageWrapper);
