import { PriceCurrencySymbols } from 'enums/price-currency-symbols.enum';

import {
  ADD_ERROR_MESSAGE,
  ADD_INFO_MESSAGE,
  ADD_SUCCESS_MESSAGE,
  ADD_WARNING_MESSAGE,
  REMOVE_ERROR_MESSAGE,
  REMOVE_INFO_MESSAGE,
  REMOVE_SUCCESS_MESSAGE,
  REMOVE_WARNING_MESSAGE,
  SET_ERROR_MESSAGES,
  SET_INFO_MESSAGES,
  SET_LOADING,
  SET_SHOW_CURRENCY_LOTTERY_MODAL,
  SET_SHOW_CURRENCY_PURCHASE_MODAL,
  SET_SHOW_SELECT_WALLET_MODAL,
  SET_SHOW_SWITCH_NETWORK_MODAL,
  SET_SUCCESS_MESSAGES,
  SET_WARNING_MESSAGES,
} from './actionNames/globalActionNames';

// ERROR MESSAGES
export const setErrorMessagesAction = (errorMessages: string[]) => ({
  type: SET_ERROR_MESSAGES,
  payload: errorMessages,
});

export const addErrorMessageAction = (errorMessage: string) => ({
  type: ADD_ERROR_MESSAGE,
  payload: errorMessage,
});

export const removeErrorMessageAction = (id: string) => ({
  type: REMOVE_ERROR_MESSAGE,
  payload: id,
});

// SUCCESS MESSAGES
export const setSuccessMessagesAction = (successMessages: string[]) => ({
  type: SET_SUCCESS_MESSAGES,
  payload: successMessages,
});

export const addSuccessMessageAction = (successMessage: string) => ({
  type: ADD_SUCCESS_MESSAGE,
  payload: successMessage,
});

export const removeSuccessMessageAction = (id: string) => ({
  type: REMOVE_SUCCESS_MESSAGE,
  payload: id,
});

// WARNING MESSAGES
export const setWarningMessagesAction = (successMessages: string[]) => ({
  type: SET_WARNING_MESSAGES,
  payload: successMessages,
});

export const addWarningMessageAction = (successMessage: string) => ({
  type: ADD_WARNING_MESSAGE,
  payload: successMessage,
});

export const removeWarningMessageAction = (id: string) => ({
  type: REMOVE_WARNING_MESSAGE,
  payload: id,
});

// INFO MESSAGES
export const setInfoMessagesAction = (successMessages: string[]) => ({
  type: SET_INFO_MESSAGES,
  payload: successMessages,
});

export const addInfoMessageAction = (successMessage: string) => ({
  type: ADD_INFO_MESSAGE,
  payload: successMessage,
});

export const removeInfoMessageAction = (id: string) => ({
  type: REMOVE_INFO_MESSAGE,
  payload: id,
});

export const setShowSelectWalletModal = (show: boolean) => ({
  type: SET_SHOW_SELECT_WALLET_MODAL,
  payload: show,
});

export const setShowCurrencyLotteryModal = (show: boolean) => ({
  type: SET_SHOW_CURRENCY_LOTTERY_MODAL,
  payload: show,
});

export const setShowCurrencyPurchaseModal = ({
  show,
  currencySymbol,
}: {
  show: boolean;
  currencySymbol?: PriceCurrencySymbols;
}) => ({
  type: SET_SHOW_CURRENCY_PURCHASE_MODAL,
  payload: { show, currencySymbol: currencySymbol ?? PriceCurrencySymbols.RMV },
});

export const setLoading = (loading: boolean) => ({
  type: SET_LOADING,
  payload: loading,
});

// SWITCH NETWORK
export const setShowSwitchNetworkModal = (loading: boolean) => ({
  type: SET_SHOW_SWITCH_NETWORK_MODAL,
  payload: loading,
});
