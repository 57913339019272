import { useState } from 'react';

import { ILootboxMetadata } from 'models/lootbox/i-lootbox-metadata.interface';

import Button from 'components/buttons/Button';
import InfoBox from 'components/infoBox/InfoBox';

import { useLootboxOpen } from '../hooks/useLootboxOpen';

import LootboxCounterSection from '../lootboxCard/lootboxCounterSection/LootboxCounterSection';
import LootboxProbabilityBox from '../lootboxCard/lootboxProbabilityBox/LootboxProbabilityBox';
import LootboxOpenAmountSelectionModal from '../lootboxOpenAmountSelectionModal/LootboxOpenAmountSelectionModal';
import styles from './LootboxOwnedItem.module.scss';

interface ILootboxOwnedItemProps {
  assetId: string;
  address: string;
  quantityOwned: number;
  metadata?: ILootboxMetadata | null;
}

const LootboxOwnedItem = ({ assetId, quantityOwned, metadata, address }: ILootboxOwnedItemProps) => {
  const [showAmountSelection, setShowAmountSelection] = useState(false);
  const defaultLootboxOpenCount = 1;
  const { startLootboxOpen } = useLootboxOpen();

  return (
    <>
      {showAmountSelection && (
        <LootboxOpenAmountSelectionModal
          quantityOwned={quantityOwned}
          onClose={() => setShowAmountSelection(false)}
          openLootboxes={(quantity) => {
            setShowAmountSelection(false);
            startLootboxOpen({
              assetId,
              address,
              openingAnimation: metadata?.openingAnimation,
              name: metadata?.name,
              quantity,
            });
          }}
          onContinue={() => setShowAmountSelection(false)}
        />
      )}
      <LootboxCounterSection size="medium" quantity={quantityOwned} />
      <Button
        color="primary"
        size="medium"
        onClick={() => {
          if (quantityOwned > defaultLootboxOpenCount) {
            setShowAmountSelection(true);

            return;
          }

          startLootboxOpen({
            assetId,
            address,
            openingAnimation: metadata?.openingAnimation,
            name: metadata?.name,
            quantity: defaultLootboxOpenCount,
          });
        }}
        className={styles['lootbox-owned__button']}
      >
        open loot box
      </Button>
      <InfoBox
        className={styles['lootbox-owned__info']}
        tooltipContent={
          <LootboxProbabilityBox
            tier1NFTChance={metadata?.tier1NFTChance}
            tier2NFTChance={metadata?.tier2NFTChance}
            tier3NFTChance={metadata?.tier3NFTChance}
          />
        }
      >
        Probability Rates
      </InfoBox>
    </>
  );
};

export default LootboxOwnedItem;
