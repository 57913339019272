import { ReactComponent as ProfileLogout } from '../../../assets/profile/icons/profile-logout.svg';
import { useSignout } from '../../../hooks/useSignout';
import MenuAccountDropdownBalanceSection from './menuAccountDropdownBalanceSection/MenuAccountDropdownBalanceSection';
import MenuAccountDropdownProfileSection from './menuAccountDropdownProfileSection/MenuAccountDropdownProfileSection';

import styles from './MenuAccountDropdown.module.scss';

const MenuAccountDropdown = ({ show }: { show: boolean }) => {
  const signOut = useSignout();

  return show ? (
    <div className={styles['account-dropdown']}>
      <MenuAccountDropdownBalanceSection />
      <MenuAccountDropdownProfileSection />
      <ul className={styles['dropdown-list']}>
        <li className={styles['dropdown-item']}>
          <button className={`${styles['dropdown-item__button']} button--styleless`} onClick={signOut}>
            <ProfileLogout />
            <span>Log out</span>
          </button>
        </li>
      </ul>
    </div>
  ) : (
    <></>
  );
};

export default MenuAccountDropdown;
