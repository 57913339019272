import { useDispatch } from 'react-redux';

import { setShowSelectWalletModal } from 'actions/global-actions';

import Button from 'components/buttons/Button';

const WheelOfFortuneOverlay = () => {
  const dispatch = useDispatch();

  const setShowConnectWalletModal = () => {
    dispatch(setShowSelectWalletModal(true));
  };

  return (
    <div className="tw-absolute tw-inset-x-0 tw-top-[calc(50%-91px)] tw-z-50 tw-flex tw-flex-col tw-items-center tw-gap-7.5 tw-bg-overlayGradient tw-p-9">
      <p className="tw-text-center tw-text-xl tw-font-semibold tw-uppercase tw-text-white md:tw-text-2xl">
        to <span className="tw-text-green">get free $rmv</span> connect wallet
      </p>
      <Button size="medium" ariaLabel="Connect wallet" onClick={setShowConnectWalletModal}>
        connect wallet
      </Button>
    </div>
  );
};

export default WheelOfFortuneOverlay;
