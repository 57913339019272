import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import Web3Singleton from 'web3Singleton';

import { IState } from 'models/reducers/i-state.interface';
import { IWalletCurrency } from 'models/wallet-currency-response.interface';

import { ERROR_MESSAGES } from 'constants/error-messages.constant';

import { addErrorMessageAction } from 'actions/global-actions';

import Button from 'components/buttons/Button';
import ElementLoader from 'components/element-loader/ElementLoader';
import ModalPortal from 'components/modal/ModalPortal';

import { parseFromDecimal } from 'utils/parser.utils';

import GameConfirmationModal from '../gameConfirmationModal/GameConfirmationModal';
import GameLabelPanel from '../gameLabelPanel/GameLabelPanel';
import GameStakingCard from '../gameStakingCard/GameStakingCard';
import GameValue from '../gameValue/GameValue';
import { IGameStakingAmount } from '../types';

interface IGameEarningsProps extends IGameStakingAmount {
  multiplier: number | undefined;
  rewardCurrency: IWalletCurrency | undefined;
}

const GameEarnings = ({ currency, rewardCurrency, poolId, negative, multiplier, onCallback }: IGameEarningsProps) => {
  const { t } = useTranslation('notifications');
  const dispatch = useDispatch();
  const { correctChain } = useSelector(({ web3 }: IState) => web3);
  const walletAddress = useSelector((state: IState): string => state.wallet.address);
  const lgoWeb3 = Web3Singleton.getInstance();

  const [loading, onLoading] = useToggle(false);
  const [claimOpen, onClaimToggle] = useToggle(false);

  const handleCheckClaimOpen = async (negative: boolean | undefined) => {
    onLoading(true);

    try {
      const open = await lgoWeb3[negative ? 'checkIfRewardClaimOpen' : 'checkIfInterestClaimOpen'](poolId);

      onClaimToggle(open);
    } catch (e) {
      console.error(e);
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
    } finally {
      onLoading(false);
    }
  };

  useEffect(() => {
    if (negative ? rewardCurrency : currency) {
      handleCheckClaimOpen(negative);
    }
  }, [currency, rewardCurrency, poolId, negative]);

  const [earnings, setEarnings] = useState({ last_day: '0', total: '0' });

  const handleCheckEarnings = async (negative: boolean | undefined, silent = false) => {
    onLoading(!silent);

    try {
      const last_day = await lgoWeb3[negative ? 'checkGeneratedRewardLastDayFor' : 'checkGeneratedInterestLastDayFor'](
        walletAddress,
        poolId
      );
      const total = await lgoWeb3[negative ? 'checkTotalClaimableRewardBy' : 'checkTotalClaimableInterestBy'](
        walletAddress,
        poolId
      );

      setEarnings({
        last_day: parseFromDecimal(last_day, negative ? rewardCurrency!.decimal : currency!.decimal),
        total: parseFromDecimal(total, negative ? rewardCurrency!.decimal : currency!.decimal),
      });
    } catch (e) {
      console.error(e);
      dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
    } finally {
      onLoading(false);
    }
  };

  useEffect(() => {
    if (negative ? rewardCurrency : currency) {
      handleCheckEarnings(negative);
    } else {
      setEarnings({ last_day: '0', total: '0' });
    }
  }, [currency, rewardCurrency, walletAddress, poolId, negative]);

  const [claimEarningsModal, onToggleClaimEarningsModal] = useToggle(false);
  const [transactionHash, setTransactionHash] = useState<string | undefined>(undefined);

  const handleClose = () => {
    onToggleClaimEarningsModal(false);
    setTransactionHash(undefined);
  };

  const handleClaimEarnings = async () => {
    try {
      await lgoWeb3[negative ? 'claimAllReward' : 'claimAllInterest'](walletAddress, poolId, {
        onTransactionHash: setTransactionHash,
        onConfirmation: () => {
          handleCheckEarnings(negative, true);
          onCallback(poolId);
          handleClose();
        },
      });
    } catch (e: any) {
      console.error(e);
      if (e.code === 4001) {
        dispatch(addErrorMessageAction(t(ERROR_MESSAGES.purchaseCanceled)));
      } else {
        dispatch(addErrorMessageAction(t(ERROR_MESSAGES.default)));
      }
      handleClose();
    }
  };

  const handleClick = () => {
    onToggleClaimEarningsModal();
    handleClaimEarnings();
  };

  return (
    <>
      {correctChain && Boolean(+earnings.total) && (
        <GameStakingCard title="Claim earnings">
          <ElementLoader className="tw-rounded-2xl" loading={loading}>
            <div className="tw-flex tw-flex-col tw-gap-5">
              <div className="tw-flex tw-flex-col tw-gap-2.5 xl:tw-flex-row xl:tw-flex-wrap">
                <GameLabelPanel label="Yesterday earnings">
                  <GameValue
                    currency={negative ? rewardCurrency! : currency!}
                    value={negative && multiplier ? (+earnings.last_day * multiplier).toString() : earnings.last_day}
                  />
                </GameLabelPanel>
                <GameLabelPanel label="Total available earnings">
                  <GameValue
                    currency={negative ? rewardCurrency! : currency!}
                    value={negative && multiplier ? (+earnings.total * multiplier).toString() : earnings.total}
                  />
                </GameLabelPanel>
              </div>
              <Button
                className="tw-ml-auto tw-w-fit"
                size="medium"
                ariaLabel="Claim earnings"
                onClick={handleClick}
                disabled={negative ?? !claimOpen}
                requireWeb3
              >
                Claim earnings
              </Button>
              {negative && (
                <p className="tw-text-white">
                  You will be able to claim your token and NFT earnings when $TRUMPET token is publicly minted.
                </p>
              )}
            </div>
          </ElementLoader>
        </GameStakingCard>
      )}
      {claimEarningsModal && (
        <ModalPortal title="Claim earnings" onClose={onToggleClaimEarningsModal}>
          <GameConfirmationModal title="Confirm claim" transactionHash={transactionHash} />
        </ModalPortal>
      )}
    </>
  );
};

export default GameEarnings;
