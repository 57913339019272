import Card from '../../../components/card/Card';
import FullScreenLoadingSpinner from '../../../components/fullScreenLoadingSpinner/FullScreenLoadingSpinner';
import VenueDetailsCardMain from '../../../components/venueDetailsCard/venueDetailsCardMain/VenueDetailsCardMain';
import { useTraits } from '../../../hooks/useTraits';
import { IAssetDetails } from '../../../models/assets/i-asset-details.interface';

import styles from './RedeemCustom.module.scss';

interface IRedeemCustomProps {
  asset: IAssetDetails | null;
  claimingInProgress: boolean;
}

const RedeemCustom = ({ asset, claimingInProgress }: IRedeemCustomProps) => {
  const { category, id } = useTraits(asset?.traits || []);

  return asset ? (
    <>
      {claimingInProgress && <FullScreenLoadingSpinner loading />}
      <Card className={styles['wrapper']}>
        <h1>{asset.description} giveaway</h1>
        <div className={styles['nft-card-wrapper']}>
          <VenueDetailsCardMain
            scale={0.75}
            className={styles['nft-card']}
            id={+id}
            address={asset.address}
            availableShares={asset.total_shares}
            cardUrl={asset.image_original_url}
            category={category}
            name={asset.name}
            iconUrl={asset.marker_url}
          ></VenueDetailsCardMain>
        </div>
        <p className={styles['info']}>
          Hey Landlord! Thank you for being a part of our community. To reward you the Eiffel Tower NFT is yours! Please
          connect your wallet or create one using your favourite Social Media login. The NFT will appear visible on your
          profile on Reality NFT Marketplace after 11th of April.
        </p>
      </Card>
    </>
  ) : (
    <></>
  );
};

export default RedeemCustom;
