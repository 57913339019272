export const ERROR_MESSAGES = {
  default: 'defaultErrorMessage',
  noWeb3Provider: 'noWeb3ProviderErrorMessage',
  unauthorized: 'unauthorizedErrorMessage',
  noPermissions: 'noPermissionsErrorMessage',
  notFound: 'notFoundErrorMessage',
  purchaseCanceled: 'purchaseCanceledErrorMessage',
  purchaseDefault: 'purchaseDefaultErrorMessage',
  permissionsRejected: 'permissionsRejectedErrorMessage',
  signatureRejected: 'signatureRejectedErrorMessage',
  listingFailed: 'listingFailedErrorMessage',
  wrongNetwork: 'wrongNetworkErrorMessage',
  loginFaild: 'loginFaild',
  noAccountConnected: 'noAccountConnected',
  errorOcurredWhileFetchingPassiveIncome: 'errorOcurredWhileFetchingPassiveIncome',
  errorCouldNotSendEmail: 'errorCouldNotSendEmail',
  errorBalanceFetching: 'errorBalanceFetching',
  errorTransactionRejected: 'errorTransactionRejected',
  errorBalance: 'errorBalance',
};
