import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

export const InitializeReactGA = () => {
  const measurementId = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;

  if (!measurementId) {
    return;
  }

  ReactGA.initialize(measurementId);
};

export const InitializeReactGTM = () => {
  const key = process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY;

  if (!key) {
    return;
  }

  const CONFIG_OPTIONS = {
    gtmId: key,
  };

  TagManager.initialize(CONFIG_OPTIONS);
};
