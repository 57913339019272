import { INetworkSettings } from 'models/i-network-settings.interface';

import { WEB3 } from './web3.constant';

export const NETWORK_SETTINGS: INetworkSettings = {
  BUILD: {
    NAME: 'Trade',
    VERSION: '2.2.0',
    ADDRESS: process.env.REACT_APP_TRADE_ADDRESS || WEB3.nullAddress,
  },
  TRADE: {
    NAME: 'Trade',
    VERSION: '2.2.0',
    ADDRESS: process.env.REACT_APP_TRADE_ADDRESS || WEB3.nullAddress,
  },
  ERC1155_HANDLER: {
    NAME: 'ERC1155',
    VERSION: '2.2.0',
    ADDRESS: process.env.REACT_APP_ERC1155_HANDLER_ADDRESS || WEB3.nullAddress,
  },
  CASHIER_HANDLER: {
    NAME: 'Cashier',
    VERSION: '2.2.0',
    ADDRESS: process.env.REACT_APP_CASHIER_PAYMENT_ADDRESS || WEB3.nullAddress,
  },
  AUCTION_HOUSE: {
    NAME: 'AuctionHouse',
    VERSION: '2.2.0',
    ADDRESS: process.env.REACT_APP_AUCTION_HOUSE_ADDRESS || WEB3.nullAddress,
  },
  LOOTBOX_MARKETPLACE: {
    ADDRESS: process.env.REACT_APP_LOOTBOX_MARKETPLACE_ADDRESS || WEB3.nullAddress,
  },
  LOOTBOX_PACK: {
    ADDRESS: process.env.REACT_APP_LOOTBOX_PACK_ADDRESS || WEB3.nullAddress,
  },
  LOOTBOX_STORE: {
    ADDRESS: process.env.REACT_APP_LOOTBOX_STORE_ADDRESS || WEB3.nullAddress,
  },
  FLEXIBLE_STAKE: {
    ADDRESS: process.env.REACT_APP_FLEXIBLE_STAKE_ADDRESS || WEB3.nullAddress,
  },
  NEGATIVE_STAKE: {
    ADDRESS: process.env.REACT_APP_NEGATIVE_STAKE_ADDRESS || WEB3.nullAddress,
  },
  STAKE: {
    ADDRESS: process.env.REACT_APP_STAKE_ADDRESS || WEB3.nullAddress,
  },
  PERIODICAL_STAKE: {
    ADDRESS: process.env.REACT_APP_PERIODICAL_STAKE_ADDRESS || WEB3.nullAddress,
  },
};
