import { FC, MouseEvent } from 'react';
import { twMerge } from 'tailwind-merge';

import { IPropsWithChildrenAndClassName, Noop } from 'types/common.interface';

import Badge from 'components/badge/Badge';

interface IButtonBadgeProps extends IPropsWithChildrenAndClassName {
  onClick: Noop;
}

export const ButtonBadge: FC<IButtonBadgeProps> = ({ className, onClick, children }) => {
  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    onClick();
  };

  return (
    <button
      className={twMerge(
        'tw-w-fit tw-cursor-pointer tw-rounded-xl tw-border-0 tw-px-2 tw-py-px tw-text-[16px]/[21px] tw-font-bold tw-uppercase tw-tracking-wide tw-text-white',
        className
      )}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export const ComingSoonBadge = () => (
  <Badge
    text="Coming Soon"
    className="tw-min-w-[111px] tw-bg-backgroundColorLight tw-py-px tw-text-[16px]/[21px] tw-tracking-wide"
  />
);
