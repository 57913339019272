import { twMerge } from 'tailwind-merge';

import BlockchainExplorerLink from 'components/blockchainExplorerLink/BlockchainExplorerLink';
import { useNftCardSize } from 'components/nftCard/_new/hooks';

import { parseThousandWithEmpty } from 'utils/parser.utils';

import { ReactComponent as ProfileAvatarDefault } from 'assets/profile/icons/profile-avatar-default.svg';

import NftCardBackCollapse from '../../../nftCardBackCollapse/NftCardBackCollapse';
import NftCardBackSlide from '../../../nftCardBackSlide/NftCardBackSlide';
import { useNftCardContext } from '../../../nftCardContext/NftCardContext';
import NftCardLabelPanel from '../../../nftCardLabelPanel/NftCardLabelPanel';
import NftCardShares from '../../../nftCardShares/NftCardShares';

const NftCardBackOwnersSlide = () => {
  const { asset } = useNftCardContext();
  const { isCardLarge, isCardMedium, isCardSmall } = useNftCardSize();
  const isNotLargeCard = isCardMedium || isCardSmall;

  const TopOwnerPanel = ({ address, amount, idx }: { address: string; amount?: number; idx: number }) => (
    <div
      className={twMerge(
        'tw-flex tw-items-center tw-justify-between tw-rounded-md tw-bg-[#0e265980] tw-px-2',
        isCardLarge && ' tw-py-2',
        isNotLargeCard && 'tw-py-[5px]'
      )}
    >
      <div className="tw-flex tw-items-center">
        <div className="tw-flex tw-min-h-[18px] tw-min-w-[18px] tw-items-center tw-justify-center tw-text-menuLinkColor">
          {idx + 1}
        </div>
        <ProfileAvatarDefault
          className={twMerge(
            'tw-ml-3 tw-mr-4 tw-rounded-full',
            isCardLarge && 'tw-h-8 tw-w-8',
            isNotLargeCard && 'tw-h-5 tw-w-5'
          )}
        />
        <BlockchainExplorerLink
          address={address}
          type="address"
          className={twMerge('tw-text-turquoise', isNotLargeCard && 'tw-text-sm')}
        />
      </div>
      <span className="tw-text-white">{parseThousandWithEmpty(amount)}</span>
    </div>
  );

  return (
    <NftCardBackSlide label="Owners">
      <NftCardBackCollapse label="">
        <NftCardLabelPanel label="Total Owners" primary>
          <span className="tw-leading-4 tw-text-white">{parseThousandWithEmpty(asset?.totalOwners)}</span>
        </NftCardLabelPanel>
        <NftCardLabelPanel label="Active Shares" primary>
          <NftCardShares>{parseThousandWithEmpty(asset?.activeShares)}</NftCardShares>
        </NftCardLabelPanel>
        <NftCardLabelPanel label="Minted Shares" primary>
          <NftCardShares>{parseThousandWithEmpty(asset?.mintedShares)}</NftCardShares>
        </NftCardLabelPanel>
      </NftCardBackCollapse>
      <NftCardBackCollapse label="Top Owners">
        {asset?.topOwners?.map(({ address, amount }, idx) => (
          <TopOwnerPanel key={address} address={address} amount={amount} idx={idx} />
        ))}
      </NftCardBackCollapse>
    </NftCardBackSlide>
  );
};

export default NftCardBackOwnersSlide;
